/** @module components/ItemsMenu */
import { connect } from 'react-redux';
import { ItemDetails } from 'services/metadata';
import { AppDispatch, GlobalState } from 'store/types';
import { selectRepository } from 'store/repositories/selectors';
import { selectChildRepositoryItem } from 'store/repositoryItems/selectors';
import { getRepositoryItems, deleteRepositoryItem } from 'store/repositoryItems/actions';
import { selectLoading } from 'store/loading/selectors';
import ItemsMenu from './component';
import { OwnProps, StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState, ownProps: OwnProps): StateProps => ({
  me: state.me,
  item: selectChildRepositoryItem(state, ownProps.itemId, ownProps.parentId),
  repository: selectRepository(state.repositories, ownProps.repositoryId),
  loading: selectLoading(state.loading, `REPOSITORY_ITEMS_GET_${ownProps.parentId}`),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getRepositoryItems: (id: string): void => { dispatch(getRepositoryItems(id)); },
  deleteRepositoryItem: (itemDetails: ItemDetails, callback?: () => void): void => {
    dispatch(deleteRepositoryItem(itemDetails, callback));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemsMenu);
