/** @module components/LanguagePicker */
import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { ReactComponent as GlobeIcon } from 'assets/icons/globe-filled.svg';
import i18next from 'i18next';
import './styles.scss';

export default function LanguagePicker(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { Option } = Select;

  const options = [
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'zh',
      label: '中文 (简体)',
    },
    {
      value: 'es',
      label: 'Español',
    },
    {
      value: 'de',
      label: 'Deutsch',
    },
    {
      value: 'fr',
      label: 'Français',
    },
  ];

  const onChange = (value: string): void => {
    setLoading(true);
    i18next.changeLanguage(value).then(() => setLoading(false));
  };

  const defaultLanguage = options.find((lang) => i18next.language === lang.value);
  return (
    <span className="LanguagePicker">
      <GlobeIcon className="globeIcon" />
      <Select
        defaultValue={defaultLanguage ? defaultLanguage.value : 'en'}
        size="small"
        dropdownStyle={{ minWidth: 100 }}
        onSelect={onChange}
        optionLabelProp="value"
        data-test-id="select"
      >
        {options.map((lang) => (
          <Option value={lang.value} key={lang.value} title={lang.value}>
            {lang.label}
          </Option>
        ))}
      </Select>
      {loading && <Spin size="small" className="loader" data-test-id="loader" />}
    </span>
  );
}
