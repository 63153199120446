/** @module store/loading */
import { LoadingState } from './types';

/**
 * Selects the given requests loading state from the given state.
 *
 * @param state The loading state
 * @param requestName The name of the request
 * @return true if the given request is loading
 */
export function selectLoading(
  state: LoadingState,
  requestName: string,
): boolean {
  const loading = state[requestName];
  if (loading === undefined) {
    return false;
  }
  return loading;
}

/**
 * Selects the loading state for the given request or
 * keyword which matches with the request names in loading state.
 *
 * @param state The loading state
 * @param requestName The name of the request or the Keyword to match
 * @return filtered LoadingState
 */
export function selectLoadingsForKeyword(
  state: LoadingState,
  requestName: string,
): LoadingState {
  const loadings: LoadingState = {};
  Object.keys(state).forEach((loading) => {
    if (loading.includes(requestName)) {
      loadings[loading] = state[loading];
    }
  });
  return loadings;
}
