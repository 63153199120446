/** @module store/notifications */
import { MetadataError } from 'services/metadata';
import createErrorNotification from './createErrorNotification';
import {
  Notification,
  NotificationsActionType,
  NotificationsAddAction,
  NotificationsRemoveAllAction,
} from './types';

export const addNotification = (notification: Notification): NotificationsAddAction => ({
  type: NotificationsActionType.NOTIFICATIONS_ADD,
  notification,
});

export const removeAllNotifications = (): NotificationsRemoveAllAction => ({
  type: NotificationsActionType.NOTIFICATIONS_REMOVE_ALL,
});

export function addErrorNotification(error: Error | MetadataError, title = ''): NotificationsAddAction {
  return addNotification(createErrorNotification(error, title));
}
