/** @module store/search */
import SearchService, { SearchResponse } from 'services/search';
import { AppDispatch } from 'store/types';
import {
  SearchActionType,
  SearchGetRequestAction,
  SearchGetSuccessAction,
  SearchGetErrorAction,
  SearchTrackClickRequestAction,
  SearchTrackClickSuccessAction,
  SearchTrackClickErrorAction,
} from './types';

export const getSearchRequest = (): SearchGetRequestAction => ({
  type: SearchActionType.SEARCH_GET_REQUEST,
});

export const getSearchSuccess = (
  searchResponse: SearchResponse,
): SearchGetSuccessAction => ({
  type: SearchActionType.SEARCH_GET_SUCCESS,
  payload: {
    searchResponse,
  },
});

export const getSearchError = (error: Error): SearchGetErrorAction => ({
  type: SearchActionType.SEARCH_GET_ERROR,
  payload: {
    error,
  },
});

export const trackClickRequest = (): SearchTrackClickRequestAction => ({
  type: SearchActionType.SEARCH_TRACK_CLICK_REQUEST,
});

export const trackClickSuccess = (): SearchTrackClickSuccessAction => ({
  type: SearchActionType.SEARCH_TRACK_CLICK_SUCCESS,
});

export const trackClickError = (error: Error): SearchTrackClickErrorAction => ({
  type: SearchActionType.SEARCH_TRACK_CLICK_ERROR,
  payload: {
    error,
  },
});

/**
 * Searches for the given query with the given constraints.
 * @param query The query
 * @param pageNumber The page number
 * @param pageSize Results per page
 * @param userId The user's ID
 * @param repoIds The IDs of repositories to search in
 * @return A Promise
 */
export function search(
  query: string,
  pageNumber: number,
  pageSize: number,
  userId: string,
  repoIds: string[],
  isSearchAcrossRepos?: boolean,
  includeFileContent?: boolean,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getSearchRequest());
    try {
      const searchResponse = await new SearchService().search(
        query,
        pageNumber,
        pageSize,
        userId,
        repoIds,
        isSearchAcrossRepos,
        includeFileContent,
      );
      dispatch(getSearchSuccess(searchResponse));
    } catch (error) {
      dispatch(getSearchError(error));
    }
  };
}

/**
 * Tracks a click on a search result.
 * @param query The search query
 * @param nodeId The ID of the node that was clicked
 * @return A Promise
 */
export function trackClick(query: string, nodeId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(trackClickRequest());
    try {
      await new SearchService().click(query, nodeId);
      dispatch(trackClickSuccess());
    } catch (error) {
      dispatch(trackClickError(error));
    }
  };
}
