/** @module store/tags */
import { AppDispatch } from 'store/types';
import MetadataService, { Tag } from 'services/metadata';
import {
  TagsActionType,
  TagsGetRequestAction,
  TagsGetSuccessAction,
  TagsGetErrorAction,
  TagsCreateRequestAction,
  TagsCreateSuccessAction,
  TagsCreateErrorAction,
  TagsUpdateRequestAction,
  TagsUpdateSuccessAction,
  TagsUpdateErrorAction,
  RepositoryTagMappingCreateRequestAction,
  RepositoryTagMappingCreateSuccessAction,
  RepositoryTagMappingCreateErrorAction,
  RepositoryTagsMappingCreateRequestAction,
  RepositoryTagsMappingCreateSuccessAction,
  RepositoryTagsMappingCreateErrorAction,
  RepositoryTagsMappingDeleteSuccessAction,
  RepositoryTagsMappingDeleteErrorAction,
  RepositoryTagsMappingDeleteRequestAction,
  RepositoryTagMappingDeleteErrorAction,
  RepositoryTagMappingDeleteRequestAction,
  RepositoryTagMappingDeleteSuccessAction,
  MultipleTagsCreateRequest,
  MultipleTagsCreateSuccess,
  MultipleTagsCreateError,
} from './types';

export const getTagsRequest = (businessId?: string): TagsGetRequestAction => ({
  type: TagsActionType.TAGS_GET_REQUEST,
  payload: {
    businessId,
  },
});

export const getTagsSuccess = (tags: Tag[], businessId?: string): TagsGetSuccessAction => ({
  type: TagsActionType.TAGS_GET_SUCCESS,
  payload: {
    tags,
    businessId,
  },
});

export const getTagsError = (error: Error): TagsGetErrorAction => ({
  type: TagsActionType.TAGS_GET_ERROR,
  payload: {
    error,
  },
});

export const createTagRequest = (businessId: string, name: string): TagsCreateRequestAction => ({
  type: TagsActionType.TAGS_CREATE_REQUEST,
  payload: {
    businessId,
    name,
  },
});

export const createTagSuccess = (tag: Tag): TagsCreateSuccessAction => ({
  type: TagsActionType.TAGS_CREATE_SUCCESS,
  payload: {
    tag,
  },
});

export const createTagError = (error: Error): TagsCreateErrorAction => ({
  type: TagsActionType.TAGS_CREATE_ERROR,
  payload: {
    error,
  },
});

export const updateTagRequest = (id: string, name: string): TagsUpdateRequestAction => ({
  type: TagsActionType.TAGS_UPDATE_REQUEST,
  payload: {
    id,
    name,
  },
});

export const updateTagsSuccess = (id: string, name: string): TagsUpdateSuccessAction => ({
  type: TagsActionType.TAGS_UPDATE_SUCCESS,
  payload: {
    id,
    name,
  },
});

export const updateTagError = (error: Error): TagsUpdateErrorAction => ({
  type: TagsActionType.TAGS_UPDATE_ERROR,
  payload: {
    error,
  },
});

export const createRepositoryTagMappingRequest = (): RepositoryTagMappingCreateRequestAction => ({
  type: TagsActionType.REPOSITORY_TAG_MAPPING_CREATE_REQUEST,
});

export const createRepositoryTagMappingSuccess = (tag: Tag, repoId: string):
RepositoryTagMappingCreateSuccessAction => ({
  type: TagsActionType.REPOSITORY_TAG_MAPPING_CREATE_SUCCESS,
  payload: {
    tag,
    repoId,
  },
});

export const createRepositoryTagMappingError = (error: Error):
RepositoryTagMappingCreateErrorAction => ({
  type: TagsActionType.REPOSITORY_TAG_MAPPING_CREATE_ERROR,
  payload: {
    error,
  },
});

export const createRepositoryTagsMappingRequest = (): RepositoryTagsMappingCreateRequestAction => ({
  type: TagsActionType.REPOSITORY_TAGS_MAPPING_CREATE_REQUEST,
});

export const createRepositoryTagsMappingSuccess = (showNotification?: boolean):
RepositoryTagsMappingCreateSuccessAction => ({
  type: TagsActionType.REPOSITORY_TAGS_MAPPING_CREATE_SUCCESS,
  payload: {
    showNotification,
  },
});

export const createRepositoryTagsMappingError = (errors: Error[], tags: Tag[]):
RepositoryTagsMappingCreateErrorAction => ({
  type: TagsActionType.REPOSITORY_TAGS_MAPPING_CREATE_ERROR,
  payload: {
    errors,
    tags,
  },
});


export const deleteRepositoryTagMappingRequest = (): RepositoryTagMappingDeleteRequestAction => ({
  type: TagsActionType.REPOSITORY_TAG_MAPPING_DELETE_REQUEST,
});

export const deleteRepositoryTagMappingSuccess = (
  tag: Tag,
  repoId: string,
):
RepositoryTagMappingDeleteSuccessAction => ({
  type: TagsActionType.REPOSITORY_TAG_MAPPING_DELETE_SUCCESS,
  payload: {
    tag,
    repoId,
  },
});

export const deleteRepositoryTagMappingError = (error: Error):
RepositoryTagMappingDeleteErrorAction => ({
  type: TagsActionType.REPOSITORY_TAG_MAPPING_DELETE_ERROR,
  payload: {
    error,
  },
});

export const deleteRepositoryTagsMappingRequest = (): RepositoryTagsMappingDeleteRequestAction => ({
  type: TagsActionType.REPOSITORY_TAGS_MAPPING_DELETE_REQUEST,
});

export const deleteRepositoryTagsMappingSuccess = (showNotification?: boolean):
RepositoryTagsMappingDeleteSuccessAction => ({
  type: TagsActionType.REPOSITORY_TAGS_MAPPING_DELETE_SUCCESS,
  payload: {
    showNotification,
  },
});

export const deleteRepositoryTagsMappingError = (errors: Error[], tags: Tag[]):
RepositoryTagsMappingDeleteErrorAction => ({
  type: TagsActionType.REPOSITORY_TAGS_MAPPING_DELETE_ERROR,
  payload: {
    errors,
    tags,
  },
});

export const createMultipleTagsRequest = (): MultipleTagsCreateRequest => ({
  type: TagsActionType.MULTIPLE_TAGS_CREATE_REQUEST,
});

export const createMultipleTagsSuccess = ():
MultipleTagsCreateSuccess => ({
  type: TagsActionType.MULTIPLE_TAGS_CREATE_SUCCESS,
});

export const createMultipleTagsError = (errors: Error[], tags: string[]):
MultipleTagsCreateError => ({
  type: TagsActionType.MULTIPLE_TAGS_CREATE_ERROR,
  payload: {
    errors,
    tags,
  },
});

/**
 * Gets all the tags, or a tag for a given business id.
 * @param businessId The id of the business
 * @return A Promise
 */
export function getTags(businessId?: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getTagsRequest(businessId));
    try {
      const tags = await new MetadataService().getTags(businessId);
      dispatch(getTagsSuccess(tags, businessId));
    } catch (error) {
      dispatch(getTagsError(error));
    }
  };
}

/**
 * Creates a tag with the given name and business id
 * @param businessId The id of the business
 * @param name The tag name
 * @return A Promise
 */
export function createTag(businessId: string, name: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(createTagRequest(businessId, name));
    try {
      const tag = await new MetadataService().createTag(name, businessId);
      dispatch(createTagSuccess(tag));
    } catch (error) {
      dispatch(createTagError(error));
    }
  };
}


/**
 * Compound thunk that creates tags with the given names and business Id
 * @param businessId The id of the business
 * @param tagName Array of tag names
 * @return A Promise
 */
export function createMultipleTags(businessId: string, tagNames: string[]) {
  return async (dispatch: AppDispatch): Promise<void> => {
    const metadataService = new MetadataService();
    const promises: Promise<void>[] = [];
    const failed: string[] = [];
    const errors: Error[] = [];
    dispatch(createMultipleTagsRequest());
    tagNames.forEach((tagName: string) => {
      dispatch(createTagRequest(businessId, tagName));
      promises.push(
        metadataService.createTag(tagName, businessId)
          .then((response: Tag) => {
            dispatch(createTagSuccess(response));
          })
          .catch((error: Error) => {
            dispatch(createTagError(error));
            failed.push(tagName);
            errors.push(error);
          }),
      );
    });

    return Promise.all(promises).then(() => {
      if (failed.length === 0) {
        dispatch(createMultipleTagsSuccess());
      } else {
        dispatch(createMultipleTagsError(errors, tagNames));
      }
    });
  };
}

/**
 * Updates a tag with the given id.
 * @param id The id of the tag
 * @param name The new tag name
 * @return A Promise
 */
export function updateTag(id: string, name: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(updateTagRequest(id, name));
    try {
      await new MetadataService().updateTag(id, name);
      dispatch(updateTagsSuccess(id, name));
    } catch (error) {
      dispatch(updateTagError(error));
    }
  };
}

/**
 * A compound thunk that creates a repository and tags mapping
 * @param repoId id of the repository
 * @param tags array of tag
 * @returns A promise
 */
export function createRepositoryTagsMapping(
  repoId: string,
  tags: Tag[],
  showNotification?: boolean,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    const metadataService = new MetadataService();
    const promises: Promise<void>[] = [];
    const failedMapping: string[] = [];
    const errors: Error[] = [];
    dispatch(createRepositoryTagsMappingRequest());
    tags.forEach((tag: Tag) => {
      dispatch(createRepositoryTagMappingRequest());
      promises.push(
        metadataService.createRepositoryTagMapping(repoId, tag.id)
          .then((response: Tag) => {
            dispatch(createRepositoryTagMappingSuccess(response, repoId));
          })
          .catch((error: Error) => {
            dispatch(createRepositoryTagMappingError(error));
            failedMapping.push(tag.name);
            errors.push(error);
          }),
      );
    });

    return Promise.all(promises).then(() => {
      if (failedMapping.length === 0) {
        dispatch(createRepositoryTagsMappingSuccess(showNotification));
      } else {
        dispatch(createRepositoryTagsMappingError(errors, tags));
      }
    });
  };
}

/**
 * A compound thunk that deletes a repository and tags mapping
 * @param repoId id of the repository
 * @param tagsId array of tag ids
 * @returns A promise
 */
export function deleteRepositoryTagsMapping(
  repoId: string,
  tags: Tag[],
  showNotification?: boolean,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    const metadataService = new MetadataService();
    const promises: Promise<void>[] = [];
    const failedDelete: string[] = [];
    const errors: Error[] = [];
    dispatch(deleteRepositoryTagsMappingRequest());
    tags.forEach((tag: Tag) => {
      dispatch(deleteRepositoryTagMappingRequest());
      promises.push(
        metadataService.deleteRepositoryTagMapping(repoId, tag.id)
          .then(() => {
            dispatch(deleteRepositoryTagMappingSuccess(tag, repoId));
          })
          .catch((error: Error) => {
            dispatch(deleteRepositoryTagMappingError(error));
            failedDelete.push(tag.id);
            errors.push(error);
          }),
      );
    });

    return Promise.all(promises).then(() => {
      if (failedDelete.length === 0) {
        dispatch(deleteRepositoryTagsMappingSuccess(showNotification));
      } else {
        dispatch(deleteRepositoryTagsMappingError(errors, tags));
      }
    });
  };
}
