/* @modules components/GridListToggle */
import React from 'react';
import { Radio } from 'antd';
import GridOnRounded from '@material-ui/icons/GridOnRounded';
import Reorder from '@material-ui/icons/Reorder';
import { Props } from './types';
import './styles.scss';

function GridListToggle(props: Props): JSX.Element {
  const { onChange, value } = props;
  return (
    <Radio.Group
      value={value}
      onChange={(e): void => onChange(e.target.value)}
      className="GridListToggle"
      data-test-id="radio-group"
    >
      <Radio.Button
        value
        className="radio-button first"
      >
        <GridOnRounded />
      </Radio.Button>
      <Radio.Button
        value={false}
        className="radio-button last"
      >
        <Reorder />
      </Radio.Button>
    </Radio.Group>
  );
}

export default GridListToggle;
