/** @module components/ManageCollaboratorsPage */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardPage from 'components/DashboardPage';
import './styles.scss';
import RepositoryCard from 'components/RepositoryCard';
import DashboardPageError from 'components/DashboardPageError';
import { ErrorType } from 'components/DashboardPageError/types';
import { ReactComponent as CollaboratorsIcon } from 'assets/icons/user-group.svg';
import Loader from 'components/Loader';
import { Divider } from 'antd';
import AddCollaborators from 'components/AddCollaboratorsSection';
import { history } from 'utilities/history';
import ExistingCollaboratorsTable from 'components/ExistingCollaboratorsTable';
import { hasBusinessPermission, hasGlobalPermission } from 'utilities/admin';
import { BusinessPermissions, GlobalPermissions } from 'services/metadata/types/Permissions';
import { appPaths } from 'utilities/routes';
import { Props } from './types';

function ManageCollaboratorsPage(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    repository,
    getRepositories,
    loading,
    getCollaborators,
    isAdmin,
    me,
  } = props;

  const { permissions } = me;
  useEffect(() => {
    if (!repository) getRepositories();
  }, [getRepositories, repository]);

  useEffect(() => {
    getCollaborators();
  }, [getCollaborators]);


  if (loading) {
    return <Loader loading={loading} data-test-id="loader" />;
  }

  let canAddCollabs = false;
  let canViewCollabs = false;
  let canRemoveCollabs = false;
  let canUpdateCollabs = false;
  let canUpdateRepoOwner = false;

  if (isAdmin) {
    canAddCollabs = hasGlobalPermission(permissions, GlobalPermissions.GLOBAL_INVITE_COLLABORATOR);
    canViewCollabs = hasGlobalPermission(
      permissions,
      GlobalPermissions.GLOBAL_LIST_REPOSITORY_COLLABORATORS,
    );
    canRemoveCollabs = hasGlobalPermission(
      permissions,
      GlobalPermissions.GLOBAL_REMOVE_COLLABORATOR,
    );
    canUpdateCollabs = hasGlobalPermission(
      permissions,
      GlobalPermissions.GLOBAL_UPDATE_COLLABORATOR,
    );

    // Admin with Update collab permission can update repo owner
    canUpdateRepoOwner = canUpdateCollabs;

    const repoBusinessId = (repository && repository.business && repository.business.id) || '';
    if (!canAddCollabs) {
      canAddCollabs = hasBusinessPermission(
        permissions,
        repoBusinessId,
        BusinessPermissions.BUSINESS_INVITE_COLLABORATOR,
      );
    }

    if (!canViewCollabs) {
      canViewCollabs = hasBusinessPermission(
        permissions,
        repoBusinessId,
        BusinessPermissions.BUSINESS_LIST_REPOSITORY_COLLABORATORS,
      );
    }

    if (!canRemoveCollabs) {
      canRemoveCollabs = hasBusinessPermission(
        permissions,
        repoBusinessId,
        BusinessPermissions.BUSINESS_REMOVE_COLLABORATOR,
      );
    }

    if (!canUpdateCollabs) {
      canUpdateCollabs = hasBusinessPermission(
        permissions,
        repoBusinessId,
        BusinessPermissions.BUSINESS_UPDATE_COLLABORATOR,
      );
      canUpdateRepoOwner = canUpdateCollabs;
    }
  } else if (repository) {
    const { actions } = repository;
    if (actions.view_collaborators) canViewCollabs = true;
    if (actions.add_collaborators) {
      canAddCollabs = true;
      canUpdateCollabs = true;
    }
    if (actions.remove_collaborators) canRemoveCollabs = true;
    if (actions.update_repository_owner) canUpdateRepoOwner = true;
  }

  const onClose = (): void => {
    if (isAdmin) {
      history.push(appPaths.adminUserSearch);
    } else if (repository) {
      history.push(`${appPaths.myRepositories}/${repository.id}`);
    }
  };

  return (
    <div className="ManageCollaboratorsPage">
      <DashboardPage
        title={(
          <>
            <CollaboratorsIcon className="icon--primary icon" />
            {t('ManageCollaboratorsPage.title', 'Manage Collaborators')}
          </>
        )}
        description={t('ManageCollaboratorsPage.description', 'Add additional collaborators, check the status of a collaborator or edit/delete a collaborator.')}
      >
        {repository
          ? (
            <div className="card">
              <RepositoryCard
                repository={repository}
                showTagsInHeader
                showProtectionsInHeader
                showBusinessInHeader
                cardSize="large"
                showClose
                onClose={onClose}
                data-test-id="repository-card"
                isNameClickable={false}
                expandable={false}
              >
                <div>
                  {canAddCollabs && (
                    <div className="add-collab-section" data-test-id="add-collab">
                      <div className="title">{t('ManageCollaboratorsPage.addCollab', 'Add Collaborators')}</div>
                      <AddCollaborators
                        repository={repository}
                        canGetCollaborators={canViewCollabs}
                        canViewCollab={canViewCollabs}
                      />
                    </div>
                  )}
                  {canAddCollabs && <Divider />}
                  {canViewCollabs && (
                    <div className="existing-collab-section" data-test-id="existing-collab">
                      <div className="title">{t('ManageCollaboratorsPage.existingCollab', 'Existing Collaborators')}</div>
                      <ExistingCollaboratorsTable
                        repository={repository}
                        canRemoveCollabs={canRemoveCollabs}
                        canUpdateCollabs={canUpdateCollabs}
                        canUpdateRepoOwner={canUpdateRepoOwner}
                        showExportToCsvButton
                        data-test-id="existing-collab-table"
                      />
                    </div>
                  )}
                </div>
              </RepositoryCard>
            </div>
          )
          : <DashboardPageError errorType={ErrorType.NOT_FOUND} />}
      </DashboardPage>
    </div>
  );
}
export default ManageCollaboratorsPage;
