/** @module services/metadata */
/**
 * Repository license types.
 */
export enum LicenseType {
  US = 'USONLY',
  NLR = 'NLR',
  LR = 'LR',
  GENE = 'GENE',
  CDI = 'CDI',
  US_CITIZEN = 'USCITIZEN',
  DOC = 'DOC',
  GPLR = 'GPLR',
}

export enum ProtectionType {
  LIMITED = 'LIMITED'
}
