/** @module store/snapshots */
import { AnyAction } from 'redux';
import { currentDateToISOString } from 'utilities/date';
import {
  GetSnapshotExportSegmentsSuccessAction,
  GetSnapshotExportsSuccessAction,
  GetSnapshotsSuccessAction,
  SnapshotsActionType,
  State,
} from './types';

export const DEFAULT_STATE: State = {
  snapshots: {
    byId: {},
    byRepoId: {},
  },
  exports: {
    byId: {},
    bySnapshotId: {},
  },
  segments: {
    byId: {},
    byExportId: {},
  },
  downloadStatuses: {
  },
};

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
function reducer(
  state: State = DEFAULT_STATE,
  action: AnyAction,
): State {
  const { type, payload } = action;

  switch (type) {
    case SnapshotsActionType.SNAPSHOTS_GET_SUCCESS: {
      const { byId, byRepoId, repositoryId } = payload as GetSnapshotsSuccessAction['payload'];
      if (!byId || !byRepoId || typeof byId !== 'object' || typeof byRepoId !== 'object') {
        return state;
      }
      let existingRepoIds: string[] = [];
      if (state.snapshots.byRepoId
        && Object.prototype.hasOwnProperty.call(state.snapshots.byRepoId, repositoryId)) {
        existingRepoIds = state.snapshots.byRepoId[repositoryId];
      }
      const mergedRepoIds = Array.from(new Set([...existingRepoIds, ...byRepoId[repositoryId]]));
      const newState = {
        ...state,
        snapshots: {
          ...state.snapshots,
          byId: { ...state.snapshots.byId, ...byId },
          byRepoId: { ...state.snapshots.byRepoId, [repositoryId]: mergedRepoIds },
        },
      };
      return newState;
    }
    case SnapshotsActionType.SNAPSHOT_EXPORTS_GET_SUCCESS: {
      const { byId, bySnapshotId } = payload as GetSnapshotExportsSuccessAction['payload'];
      return {
        ...state,
        exports: {
          byId: {
            ...state.exports.byId,
            ...byId,
          },
          bySnapshotId: {
            ...state.exports.bySnapshotId,
            [bySnapshotId]: Object.keys(byId),
          },
        },
      };
    }
    case SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENTS_GET_SUCCESS: {
      const { byId, byExportId } = payload as GetSnapshotExportSegmentsSuccessAction['payload'];
      return {
        ...state,
        segments: {
          byId: {
            ...state.segments.byId,
            ...byId,
          },
          byExportId: {
            ...state.segments.byExportId,
            [byExportId]: Object.keys(byId),
          },
        },
      };
    }
    case SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_REQUEST: {
      const { exportId, segmentNumber } = payload;
      const currentDownloadStatuses = state.downloadStatuses[exportId] || {};
      return {
        ...state,
        downloadStatuses: {
          ...state.downloadStatuses,
          [exportId]: {
            ...currentDownloadStatuses,
            [segmentNumber]: { isDownloading: true },
          },
        },
      };
    }
    case SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_SUCCESS: {
      const { exportId, segmentNumber } = payload;
      const currentDownloadStatuses = state.downloadStatuses[exportId] || {};
      const currentDatetime = currentDateToISOString();

      return {
        ...state,
        downloadStatuses: {
          ...state.downloadStatuses,
          [exportId]: {
            ...currentDownloadStatuses,
            [segmentNumber]: {
              isDownloading: false,
              succeeded: true,
              downloadedAt: currentDatetime,
            },
          },
        },
      };
    }
    case SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_FAILURE: {
      const { exportId, segmentNumber, error } = payload;
      const currentDownloadStatuses = state.downloadStatuses[exportId] || {};
      return {
        ...state,
        downloadStatuses: {
          ...state.downloadStatuses,
          [exportId]: {
            ...currentDownloadStatuses,
            [segmentNumber]: { isDownloading: false, error },
          },
        },
      };
    }
    default: {
      return state;
    }
  }
}
export default reducer;
