/** @module components/CreateFolderForm */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { createFolder } from 'store/repositoryItems/actions';
import { selectLoading } from 'store/loading/selectors';
import CreateFolderForm from './component';
import { DispatchProps, StateProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'REPOSITORY_ITEMS_CREATE'),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  createFolder: (name: string, parentId: string): void => {
    dispatch(createFolder(name, parentId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateFolderForm);
