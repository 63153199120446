/** @module components/RepositoryCard */
import React, { useState } from 'react';
import {
  Button, Card, Checkbox, Popover,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Placement } from 'utilities/antd';
import { history } from 'utilities/history';
import { LicenseType, ProtectionType } from 'services/metadata';
import DownArrow from '@material-ui/icons/ExpandMore';
import UpArrow from '@material-ui/icons/ExpandLess';
import { ReactComponent as RepositoryIcon } from 'assets/icons/repository.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrow_forward_ios.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as NoEditIcon } from 'assets/icons/no-edit.svg';
import ProtectionTag from '../ProtectionTag';
import RepositoryMenu from '../RepositoryMenu';
import { Props } from './types';
import './styles.scss';

function RepositoryCard(props: Props): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    repository,
    children,
    showTagsInHeader,
    showTagsBelowHeader,
    cardSize,
    showMenu = false,
    showClose = false,
    isNameClickable = true,
    showProtectionsInHeader,
    showBusinessInHeader,
    showOwnerInHeader,
    showManageSnapshotsButtonInHeader,
    showProtectionsBelowHeader,
    showBusinessBelowHeader,
    showOwnerBelowHeader,
    showManageSnapshotsButtonBelowHeader,
    expandable,
    extraIcon,
    cardIndex = 0,
    onClose,
    showSelectCheckbox = false,
    isSelected = false,
    onSelect,
    repoReassignmentData = undefined,
    onClick,
  } = props;
  const { t } = useTranslation();

  const renderBusiness = (business: string): JSX.Element => (
    <div className="business" data-test-id="business">
      <div className="heading">
        {t('RepositoryCard.business', 'Business')}
      </div>
      <span data-test-id="business-name" className="business-name">
        {business}
      </span>
    </div>
  );

  const renderProtections = (protections: LicenseType[], isLimited?: boolean): JSX.Element => (
    <div className="protections" data-test-id="protections">
      <div className="heading">
        {t('RepositoryCard.protections', 'Protections')}
      </div>
      {protections.map((protection) => (
        <span className="protection" key={protection}>
          <ProtectionTag
            protection={protection}
            key={protection}
            data-test-id="protection"
          />
        </span>
      ))}
      {isLimited && (
        <span className="protection" key={ProtectionType.LIMITED}>
          <ProtectionTag
            protection={ProtectionType.LIMITED}
            key={ProtectionType.LIMITED}
            data-test-id="protection-limited"
          />
        </span>
      )}
    </div>
  );

  const renderTags = (tags: string[]): JSX.Element => (
    <div className="tags" data-test-id="tags">
      <div className="heading">
        {t('RepositoryCard.tags', 'Tags')}
      </div>
      {tags.length === 0 && (
        <span data-test-id="no-tags-text" className="no-tags-text">
          {t('RepositoryCard.noTags', 'No tags')}
        </span>
      )}
      {tags.map((tag, index): JSX.Element | null => {
        if (index > 4 && (cardSize === 'small' || showTagsBelowHeader)) {
          return null;
        } return (
          <span className="tag" key={tag} data-test-id="tag">
            <TagIcon className="tag-icon" width={12} height={12} />
            {tag}
          </span>
        );
      })}
      {tags.length > 5 && (cardSize === 'small' || showTagsBelowHeader) && <span className="tag" data-test-id="more-tags">...more</span>}
    </div>
  );

  const renderOwner = (owner: string): JSX.Element => (
    <div className="owner" data-test-id="owner">
      <div className="heading">
        {t('RepositoryCard.owner', 'Owner')}
      </div>
      <span data-test-id="owner-name" className="owner-name">
        {owner}
      </span>
    </div>
  );

  const renderManageDataSnapshots = (): JSX.Element | null => {
    if (!repository.actions.view_snapshots) return null;
    return (
      <Button
        data-test-id="mng-snapshots-button"
        className="primary-inverse icon-right"
        onClick={(): void => history.push(`/manage-repository/${repository.id}/manage-snapshots`)}
        icon={<ArrowForwardIcon />}
      >
        {t('RepositoryCard.manageDataSnapshots', 'MANAGE DATA SNAPSHOTS')}
      </Button>
    );
  };

  const elementBeforeTitle = (): JSX.Element => {
    let element = (
      <div className={`repo-icon-${cardSize}`}>
        <RepositoryIcon data-test-id="repo-icon" width={cardSize === 'small' ? 16 : 24} height={cardSize === 'small' ? 16 : 24} />
      </div>
    );
    /* If showSelectCheckbox is true, show checkbox */
    if (showSelectCheckbox) {
      element = (
        <Checkbox
          className="check-box"
          data-test-id="check-box"
          onChange={(e): void => onSelect && onSelect(e.target.checked)}
          onClick={(e): void => e.stopPropagation()}
          checked={isSelected}
        />
      );
      /* If is a Delete User reassigned repo card */
    } else if (repoReassignmentData) {
      element = (<></>
      );
    }
    return element;
  };

  const onClickCardTitle = (): void => {
    if (expandable && !repoReassignmentData) {
      setIsExpanded((prevState) => !prevState);
    }
  };

  const height = cardSize === 'small' ? '250px' : 'auto';
  const headerHeight = cardSize === 'small' ? '55px' : 'auto';
  const bodyHeight = cardSize === 'small' ? '195px' : 'auto';

  // !isExpanded means the card is collapsed
  return (
    <Card
      className="RepositoryCard"
      style={{
        height: (expandable && !isExpanded) || repoReassignmentData
          ? headerHeight : height,
      }}
      bodyStyle={{ height: (expandable && !isExpanded) || repoReassignmentData ? '0px' : bodyHeight }}
      headStyle={{ height: headerHeight }}
      data-test-id="card"
      onClick={onClick}
      title={(
        <div
          className="card-title"
          data-test-id="card-title"
          onClick={onClickCardTitle}
          role="button"
          onKeyDown={onClickCardTitle}
          tabIndex={cardIndex}
        >
          {elementBeforeTitle()}
          <span className={`repo-name ${cardSize}`} data-test-id="repo-name">
            {isNameClickable ? (
              <Link to={`${window.location.pathname}/${repository.id}`}>
                {repository.name}
              </Link>
            ) : repository.name}
          </span>
          { (showTagsInHeader
            || showProtectionsInHeader
            || showBusinessInHeader
            || showOwnerInHeader
            || showManageSnapshotsButtonInHeader
          ) && (
            <div className="header-elements">
              <div className="tags-container" data-test-id="tags-container">
                {showBusinessInHeader
                && repository.business && renderBusiness(repository.business.displayName)}
                {showProtectionsInHeader
                && renderProtections(repository.protections, repository.isLimited)}
                {showTagsInHeader && repository.tags && renderTags(repository.tags)}
                {showOwnerInHeader && renderOwner(repository.owner.name)}

              </div>
              <div className="buttons-container" data-test-id="buttons-container">
                {showManageSnapshotsButtonInHeader && renderManageDataSnapshots()}
              </div>
            </div>
          )}

          {/* If card is expandable, show arrows on hover */}
          {expandable && isExpanded && !extraIcon && <UpArrow data-test-id="up-arrow" className="arrow" />}
          {expandable && !isExpanded && !extraIcon && <DownArrow data-test-id="down-arrow" className="arrow" />}
          {extraIcon && (
            <div className="extra-icon" data-test-id="extra-icon">
              {extraIcon}
            </div>
          )}
          { repoReassignmentData ? (
            <span className="reasigned-to" data-test-id="reasigned-to">
              {repoReassignmentData.newOwnerName}
            </span>
          ) : null}
          {showMenu && (
            <div className="repo-menu">
              {repository.isEvicted
                ? (
                  <Popover
                    placement="left"
                    content={(
                      <div className="no-edit-pop-over">
                        <div className="info-icon">
                          <InfoOutlinedIcon />
                        </div>
                        <span>{t('RepositoryCard.no-edit.popover', 'Repository Extracted. No further changes may be made to this repository.')}</span>
                      </div>
                    )}
                  >
                    <span className="no-edit-icon" data-test-id="no-edit-icon"><NoEditIcon height="21" width="22" /></span>
                  </Popover>
                ) : (
                  <RepositoryMenu
                    repositoryId={repository.id}
                    iconSize={cardSize}
                    placement={Placement.BOTTOM_RIGHT}
                    data-test-id="repository-menu"
                  />
                )}
            </div>
          )}
          {showClose && (
            <div className="extra close-button" data-test-id="close-button">
              <CloseIcon
                width="24"
                height="24"
                className="close-icon"
                onClick={onClose}
              />
            </div>
          )}
        </div>
      )}
    >
      {(
        showTagsBelowHeader
        || showProtectionsBelowHeader
        || showBusinessBelowHeader
        || showOwnerBelowHeader
        || showManageSnapshotsButtonBelowHeader
      ) && (
        <div className="header-elements">
          <div className="tags-container-below" data-test-id="tags-container">
            {showBusinessBelowHeader
            && repository.business && renderBusiness(repository.business.displayName)}
            {showProtectionsBelowHeader
            && renderProtections(repository.protections, repository.isLimited)}
            {showTagsBelowHeader && repository.tags && renderTags(repository.tags)}
            {showOwnerBelowHeader && renderOwner(repository.owner.name)}
          </div>
        </div>
      )}

      {/* if the card is expandable and is expanded, should render children or repo details
          else render nothing
      */}
      {(expandable && !isExpanded) || repoReassignmentData ? null : children || (
        <div className="card-body" data-test-id="default-card-body">
          <div className="body-tags-container">
            {renderBusiness(repository.business ? repository.business.displayName : '')}
            {renderProtections(repository.protections, repository.isLimited)}
            <div className="space" />
            {repository.tags && renderTags(repository.tags)}
          </div>
          <div className="updated-on">
            {t('RepositoryCard.updatedOn', 'Updated On:')}
            {' '}
            {new Date(repository.lastUpdatedOn).toLocaleString(navigator.language, {
              year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit',
            }).toLocaleUpperCase()}
          </div>
        </div>
      )}
    </Card>
  );
}

export default RepositoryCard;
