/** @module store/repositoryExports */
import { Action } from 'redux';
import { MetadataErrorResponse } from 'services/metadata';

export interface RepositoryState {
  isLoading: boolean;
  error: Error | null;
  downloadUrl: string | null;
}

export interface State {
  [repoId: string]: RepositoryState;
}

export enum RepositoryExportZipActionType {
  REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_REQUEST = 'REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_REQUEST',
  REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_SUCCESS = 'REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_SUCCESS',
  REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_FAILURE = 'REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_FAILURE',
}

export interface RepositoryZipDownloadSelectedRequestAction extends Action<
RepositoryExportZipActionType.REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_REQUEST> {
  payload: {
    repoId: string;
  };
}

export interface RepositoryZipDownloadSelectedSuccessAction extends Action<
RepositoryExportZipActionType.REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_SUCCESS> {
  payload: {
    repoId: string;
    url: string;
  };
}

export interface RepositoryZipDownloadSelectedFailureAction extends Action<
RepositoryExportZipActionType.REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_FAILURE> {
  payload: {
    repoId: string;
    error: MetadataErrorResponse;
  };
}

export type RepositoryExportAction =
  | RepositoryZipDownloadSelectedRequestAction
  | RepositoryZipDownloadSelectedSuccessAction
  | RepositoryZipDownloadSelectedFailureAction;
