/** @module components/ReassignOwnerSection */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { selectErrorMessage } from 'store/error/selectors';
import { getUsers } from 'store/users/actions';
import { getCollaboratorsMultipleRepos } from 'store/collaborators/actions';
import ChooseUserTypeForm from './component';
import {
  DispatchProps,
  StateProps,
} from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  me: state.me,
  loading: selectLoading(state.loading, 'COLLABORATORS_GET'),
  getMultiCollaboratorLoading: selectLoading(state.loading, 'COLLABORATORS_MULTI_GET'),
  searchUserLoading: selectLoading(state.loading, 'USERS_GET'),
  searchedUsers: state.users.searchedUsers,
  updateCollaboratorError: selectErrorMessage(state.error, 'COLLABORATORS_UPDATE'),
  addCollaboratorError: selectErrorMessage(state.error, 'COLLABORATORS_ADD'),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  searchUsers: (searchTerm: string): void => { dispatch(getUsers(searchTerm)); },
  getCollaboratorsMultipleRepos: (repoIds: string[]): void => {
    dispatch(getCollaboratorsMultipleRepos(repoIds, false));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChooseUserTypeForm);
