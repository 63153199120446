/** @module components/MeMenu */
import { connect } from 'react-redux';
import { GlobalState } from 'store/types';
import { selectFeature } from 'store/features/selectors';
import { Feature } from 'store/features/types';
import MeMenu from './component';
import { StateProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  me: state.me,
  adminConsoleEnabled: selectFeature(state.features, Feature.ADMIN_CONSOLE),
});

export default connect(mapStateToProps, {})(MeMenu);
