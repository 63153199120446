/** @module components/AssignRolesModal */
import React, { useEffect, useState } from 'react';
import { ReactComponent as AvatarIcon } from 'assets/icons/user-male-lg.svg';
import { BusinessPermissions, GlobalPermissions } from 'services/metadata/types/Permissions';
import { hasBusinessPermission, hasGlobalPermission } from 'utilities/admin';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import DropdownWithCheckbox from 'components/DropdownWithCheckbox';
import { Role } from 'services/metadata';
import Loader from 'components/Loader';
import ModalAction from '../ModalAction';
import TitleWithIcon from '../TitleWithIcon';
import { Props } from './types';
import './styles.scss';

/**
 * A modal action button that allows the admin to assign roles to other users.
 */
function AssignRolesModal(props: Props): JSX.Element {
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [removedRoles, setRemovedRoles] = useState<Role[]>([]);
  const { t } = useTranslation();
  const {
    getAllRoles,
    allRoles,
    userRoles,
    user,
    loading,
    assignRoles,
    removeRoles,
    me,
  } = props;

  let canGetRoles = hasGlobalPermission(me.permissions, GlobalPermissions.GLOBAL_LIST_USER_ROLES);
  // if global permissions are not present, check for business permissions
  if (!canGetRoles) {
    canGetRoles = hasBusinessPermission(
      me.permissions,
      user.business.id,
      BusinessPermissions.BUSINESS_LIST_USER_ROLES,
    );
  }

  useEffect(() => {
    getAllRoles();
  }, [getAllRoles]);

  useEffect(() => {
    setSelectedRoles(userRoles);
  }, [userRoles]);

  const userName = `${user.name.split('(')[0].trim()}'s`;
  const options = allRoles.map((r) => ({ value: r.id, title: r.name }));

  const onSelectRole = (selectedRoleId: string, checked: boolean): void => {
    const selectedRole = allRoles.find((r) => r.id === selectedRoleId);
    if (selectedRole) {
      if (checked) {
        setSelectedRoles((oldRoles) => [...oldRoles, selectedRole]);
      } else {
        setSelectedRoles((oldRoles) => oldRoles.filter((role) => role.id !== selectedRoleId));
        const removedRole = userRoles.find((r) => r.id === selectedRoleId);
        if (removedRole) {
          setRemovedRoles((oldRoles) => [...oldRoles, removedRole]);
        }
      }
    }
  };

  const clearSelection = (): void => {
    setSelectedRoles(userRoles);
    setRemovedRoles([]);
  };

  const onSave = (): void => {
    const roles = selectedRoles.filter((r) => !userRoles.find((userRole) => userRole.id === r.id));
    if (roles.length) assignRoles(roles);
    if (removedRoles.length) removeRoles(removedRoles);
    clearSelection();
  };

  return (
    <ModalAction
      className="AssignRolesModal"
      afterClose={(): void => clearSelection()}
      footer={null}
      closable
      maskClosable
      actionIcon={AvatarIcon}
      title={(
        <TitleWithIcon iconSVG={AvatarIcon}>
          {t(
            'AssignRolesModal.title',
            'Give Admin Status',
          )}
        </TitleWithIcon>
      )}
      actionRender={(toggleVisible: Function): JSX.Element => (
        <Button
          type="link"
          onClick={(): void => toggleVisible(true)}
          data-test-id="open-button"
        >
          {t(
            'AssignRolesModal.title',
            'Give Admin Status',
          )}
        </Button>
      )}
      data-test-id="modal"
    >
      <div className="content" data-test-id="content">
        {canGetRoles && (
          <div data-test-id="user-roles-container">
            <div className="title" data-test-id="title">
              { `${userName} ${t('AssignRolesModal.roles', 'roles')}`}
            </div>
            <Loader loading={loading} data-test-id="loader" />
            {selectedRoles.length > 0 && !loading && (
            <div className="user-roles" data-test-id="user-roles">
              <ul>
                {selectedRoles.map((selectedRole) => (
                  <li key={selectedRole.id} data-test-id={`role-list-${selectedRole.id}`}>
                    <span className="role-name">
                      {selectedRole.name}
                    </span>
                    {' '}
                  -
                    {' '}
                    {selectedRole.description}
                  </li>
                ))}
              </ul>
            </div>
            )}
            {selectedRoles.length === 0 && !loading && (
              <div data-test-id="no-roles">
                { t('AssignRolesModal.noRoles', 'No roles assigned')}
              </div>
            )}
          </div>
        )}
        {!canGetRoles && selectedRoles.length > 0 && (
          <div data-test-id="selected-roles-container">
            <div className="title" data-test-id="title">
              {t('AssignRolesModal.selectedRoles', 'Selected Role(s)')}
            </div>
            {selectedRoles.length > 0 && (
            <div className="user-roles" data-test-id="user-roles">
              <ul>
                {selectedRoles.map((selectedRole) => (
                  <li key={selectedRole.id} data-test-id={`role-list-${selectedRole.id}`}>
                    <span className="role-name">
                      {selectedRole.name}
                    </span>
                    {' '}
                  -
                    {' '}
                    {selectedRole.description}
                  </li>
                ))}
              </ul>
            </div>
            )}
          </div>
        )}
        <div className="select-roles-section">
          <div className="title">
            {t('AssignRolesModal.selectRoles', 'Select Role(s)')}
          </div>
          <div className="dropdown">
            <DropdownWithCheckbox
              data-test-id="dropdown"
              disabled={loading}
              showArrow
              options={options}
              onChange={(value, checked): void => onSelectRole(value, checked)}
              selected={selectedRoles.map((r) => r.id)}
              placeholder={t('AssignRolesModal.placeholder', 'Select from Available Roles')}
            />
          </div>
        </div>
        <div className="save">
          <Button
            className="save-button"
            data-test-id="save-button"
            type="primary"
            loading={loading}
            onClick={onSave}
          >
            {t('AssignRolesModal.save', 'Save')}
          </Button>
        </div>
      </div>
    </ModalAction>
  );
}

export default AssignRolesModal;
