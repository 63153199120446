/** @module services/metadata */

/**
 * Represents repository different eviction states.
 */
export enum EvictionState {
  NOT_EVICTED='NOT_EVICTED',
  IN_PROGRESS='IN_PROGRESS',
  EVICTED='EVICTED',
  FAILED='FAILED'
  }
