/** @module store/collaborators */
import { AnyAction } from 'redux';
import lodash from 'lodash';
import {
  CollaboratorsActionType,
  CollaboratorsState,
  MULTI_REASSIGNMENT_KEY,
} from './types';

export const DEFAULT_STATE: CollaboratorsState = {};

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
export default function reducer(
  state: CollaboratorsState = DEFAULT_STATE,
  action: AnyAction,
): CollaboratorsState {
  switch (action.type) {
    case CollaboratorsActionType.COLLABORATORS_GET_SUCCESS: {
      const {
        collaborators,
        repositoryId,
      } = action.payload;
      return {
        ...state,
        [repositoryId]: collaborators,
      };
    }
    case CollaboratorsActionType.COLLABORATORS_MULTI_REASSIGN_REQUEST: {
      const { multiRequestId } = action.payload;
      return {
        ...state,
        [MULTI_REASSIGNMENT_KEY]: {
          ...(state[MULTI_REASSIGNMENT_KEY] || {}),
          [multiRequestId]: { successCount: 0 },
        },
      };
    }
    case CollaboratorsActionType.COLLABORATORS_UPDATE_SUCCESS: {
      const {
        repositoryId,
        collaborator,
        role,
        multiRequestId,
      } = action.payload;
      const newState = lodash.cloneDeep(state);
      const collaborators = newState[repositoryId];
      if (collaborators) {
        const existingCollaborator = collaborators.find((x) => x.id === collaborator.id);
        if (role === 'OWNER') {
          const oldOwner = collaborators.find((x) => x.role === 'OWNER');
          if (oldOwner) {
            oldOwner.role = 'COOWNER';
          }
        }
        if (existingCollaborator) {
          const index = collaborators.indexOf(existingCollaborator);
          collaborators[index].role = role;
        }
      }
      if (multiRequestId) {
        const multiReassignProgress = newState[MULTI_REASSIGNMENT_KEY] || {};
        const reassignData = multiReassignProgress[multiRequestId] || { successCount: 0 };
        reassignData.successCount += 1;
        multiReassignProgress[multiRequestId] = reassignData;
        newState[MULTI_REASSIGNMENT_KEY] = multiReassignProgress;
      }
      return newState;
    }
    case CollaboratorsActionType.COLLABORATORS_ADD_SUCCESS: {
      const { multiRequestId } = action.payload;
      const newState = lodash.cloneDeep(state);
      if (multiRequestId) {
        const multiReassignProgress = newState[MULTI_REASSIGNMENT_KEY] || {};
        const reassignData = multiReassignProgress[multiRequestId] || { successCount: 0 };
        reassignData.successCount += 1;
        multiReassignProgress[multiRequestId] = reassignData;
        newState[MULTI_REASSIGNMENT_KEY] = multiReassignProgress;
      }
      return newState;
    }
    case CollaboratorsActionType.COLLABORATORS_REMOVE_SUCCESS: {
      const {
        repositoryId,
        collaboratorId,
      } = action.payload;
      const newState = lodash.cloneDeep(state);
      const collaborators = newState[repositoryId];
      if (collaborators) {
        const collaborator = collaborators.find((c) => c.id === collaboratorId);
        if (collaborator) {
          const index = collaborators.indexOf(collaborator);
          collaborators.splice(index, 1);
        }
      }
      return newState;
    }
    case CollaboratorsActionType.COLLABORATORS_RENEW_SUCCESS: {
      const {
        repositoryId,
        collaborator,
      } = action.payload;
      const newState = lodash.cloneDeep(state);
      const collaborators = newState[repositoryId];
      if (collaborators) {
        const c = collaborators.find((x) => x.id === collaborator.id);
        if (c) {
          const index = collaborators.indexOf(c);
          const now = new Date();
          collaborators[index].expiration = new Date(now.getTime() + 180 * 24 * 60 * 60 * 1000)
            .toString();
        }
      }
      return newState;
    }
    default:
      return state;
  }
}
