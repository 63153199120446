/** @module store/roles */
import MetadataService from 'services/metadata';
import { Role } from 'services/metadata/types/Role';
import { AppDispatch } from 'store/types';
import {
  AllRolesGetError,
  AllRolesGetRequest,
  AllRolesGetSuccess,
  RoleCreateRequest,
  RoleCreateSuccess,
  RoleCreateError,
  RoleGetError,
  RoleGetRequest,
  RoleGetSuccess,
  RolesActionTypes,
  RoleUpdateRequest,
  RoleUpdateSuccess,
  RoleUpdateError,
  RoleAssignRequest,
  RoleAssignSuccess,
  RoleAssignError,
  RoleRemoveRequest,
  RoleRemoveSuccess,
  RoleRemoveError,
  UserRolesGetRequest,
  UserRolesGetSuccess,
  UserRolesGetError,
  RolesAssignRequest,
  RolesAssignSuccess,
  RolesAssignError,
  RolesRemoveRequest,
  RolesRemoveSuccess,
  RolesRemoveError,
} from './types';

export const getAllRolesRequest = (): AllRolesGetRequest => ({
  type: RolesActionTypes.ALL_ROLES_GET_REQUEST,
});

export const getAllRolesSuccess = (
  roles: Role[],
): AllRolesGetSuccess => ({
  type: RolesActionTypes.ALL_ROLES_GET_SUCCESS,
  payload: {
    roles,
  },
});

export const getAllRolesError = (error: Error): AllRolesGetError => ({
  type: RolesActionTypes.ALL_ROLES_GET_ERROR,
  payload: {
    error,
  },
});

export const getRoleRequest = (): RoleGetRequest => ({
  type: RolesActionTypes.ROLE_GET_REQUEST,
});

export const getRoleSuccess = (
  role: Role,
): RoleGetSuccess => ({
  type: RolesActionTypes.ROLE_GET_SUCCESS,
  payload: {
    role,
  },
});

export const getRoleError = (error: Error): RoleGetError => ({
  type: RolesActionTypes.ROLE_GET_ERROR,
  payload: {
    error,
  },
});

export const createRoleRequest = (): RoleCreateRequest => ({
  type: RolesActionTypes.ROLE_CREATE_REQUEST,
});

export const createRoleSuccess = (
  role: Role,
): RoleCreateSuccess => ({
  type: RolesActionTypes.ROLE_CREATE_SUCCESS,
  payload: {
    role,
  },
});

export const createRoleError = (error: Error): RoleCreateError => ({
  type: RolesActionTypes.ROLE_CREATE_ERROR,
  payload: {
    error,
  },
});

export const updateRoleRequest = (): RoleUpdateRequest => ({
  type: RolesActionTypes.ROLE_UPDATE_REQUEST,
});

export const updateRoleSuccess = (
  role: Role,
): RoleUpdateSuccess => ({
  type: RolesActionTypes.ROLE_UPDATE_SUCCESS,
  payload: {
    role,
  },
});

export const updateRoleError = (error: Error): RoleUpdateError => ({
  type: RolesActionTypes.ROLE_UPDATE_ERROR,
  payload: {
    error,
  },
});

export const assignRoleRequest = (): RoleAssignRequest => ({
  type: RolesActionTypes.ROLE_ASSIGN_REQUEST,
});

export const assignRoleSuccess = (
  roleId: string,
  userId: string,
): RoleAssignSuccess => ({
  type: RolesActionTypes.ROLE_ASSIGN_SUCCESS,
  payload: {
    roleId,
    userId,
  },
});

export const assignRoleError = (error: Error): RoleAssignError => ({
  type: RolesActionTypes.ROLE_ASSIGN_ERROR,
  payload: {
    error,
  },
});

export const removeRoleRequest = (): RoleRemoveRequest => ({
  type: RolesActionTypes.ROLE_REMOVE_REQUEST,
});

export const removeRoleSuccess = (
  roleId: string,
  userId: string,
): RoleRemoveSuccess => ({
  type: RolesActionTypes.ROLE_REMOVE_SUCCESS,
  payload: {
    roleId,
    userId,
  },
});

export const removeRoleError = (error: Error): RoleRemoveError => ({
  type: RolesActionTypes.ROLE_REMOVE_ERROR,
  payload: {
    error,
  },
});

export const getUserRolesRequest = (): UserRolesGetRequest => ({
  type: RolesActionTypes.USER_ROLES_GET_REQUEST,
});

export const getUserRolesSuccess = (roles: Role[], userId: string): UserRolesGetSuccess => ({
  type: RolesActionTypes.USER_ROLES_GET_SUCCESS,
  payload: {
    roles,
    userId,
  },
});

export const getUserRolesError = (error: Error): UserRolesGetError => ({
  type: RolesActionTypes.USER_ROLES_GET_ERROR,
  payload: {
    error,
  },
});

export const assignRolesRequest = (): RolesAssignRequest => ({
  type: RolesActionTypes.ROLES_ASSIGN_REQUEST,
});

export const assignRolesSuccess = (
  userId: string,
  roles: Role[],
): RolesAssignSuccess => ({
  type: RolesActionTypes.ROLES_ASSIGN_SUCCESS,
  payload: {
    userId,
    roles,
  },
});

export const assignRolesError = (error: Error[], failed: Role[]): RolesAssignError => ({
  type: RolesActionTypes.ROLES_ASSIGN_ERROR,
  payload: {
    error,
    failed,
  },
});

export const removeRolesRequest = (): RolesRemoveRequest => ({
  type: RolesActionTypes.ROLES_REMOVE_REQUEST,
});

export const removeRolesSuccess = (
  userId: string,
  roles: Role[],
): RolesRemoveSuccess => ({
  type: RolesActionTypes.ROLES_REMOVE_SUCCESS,
  payload: {
    userId,
    roles,
  },
});

export const removeRolesError = (error: Error[], failed: Role[]): RolesRemoveError => ({
  type: RolesActionTypes.ROLES_REMOVE_ERROR,
  payload: {
    error,
    failed,
  },
});

/**
 * Gets all the roles
 * @return A Promise
 */
export function getAllRoles() {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getAllRolesRequest());
    try {
      const roles = await new MetadataService().getRoles();
      dispatch(getAllRolesSuccess(roles));
    } catch (error) {
      dispatch(getAllRolesError(error));
    }
  };
}

/**
 * Get role by role id
 * @param roleId role id
 * @return A Promise
 */
export function getRole(roleId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getRoleRequest());
    try {
      const role = await new MetadataService().getRole(roleId);
      dispatch(getRoleSuccess(role));
    } catch (error) {
      dispatch(getRoleError(error));
    }
  };
}

/**
 * Create role
 * @param name name of the role
 * @param description description of the role
 * @param systemDefined is the role system defined
 * @param active is the role active
 * @param businessId id of the bussiness to which the role belongs
 * @param permissions string[]
 * @return A Promise
 */
export function createRole(
  name: string,
  description: string,
  systemDefined: boolean,
  restricted: boolean,
  active: boolean,
  businessId: string,
  permissions: string[],
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(createRoleRequest());
    try {
      const role = await new MetadataService().createRole(
        name,
        description,
        systemDefined,
        restricted,
        active,
        businessId,
        permissions,
      );
      dispatch(createRoleSuccess(role));
    } catch (error) {
      dispatch(createRoleError(error));
    }
  };
}

/**
 * Update role
 * @param role the updated role
 * @return A Promise
 */
export function updateRole(role: Role) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(updateRoleRequest());
    try {
      await new MetadataService().updateRole(role);
      dispatch(updateRoleSuccess(role));
    } catch (error) {
      dispatch(updateRoleError(error));
    }
  };
}

/**
 * Assign role to a user
 * @param userId user id
 * @param roleId role id
 * @return A Promise
 */
export function assignRole(userId: string, roleId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(assignRoleRequest());
    try {
      await new MetadataService().assignRole(userId, roleId);
      dispatch(assignRoleSuccess(roleId, userId));
    } catch (error) {
      dispatch(assignRoleError(error));
    }
  };
}

/**
 * A compound thunk to assign roles to a user
 * @param userId user id
 * @param roles roles array
 * @return A Promise
 */
export function assignRoles(userId: string, roles: Role[]) {
  return async (dispatch: AppDispatch): Promise<void> => {
    const metadataService = new MetadataService();
    const promises: Promise<void>[] = [];
    const failed: Role[] = [];
    const errors: Error[] = [];
    dispatch(assignRolesRequest());
    roles.forEach((role: Role) => {
      dispatch(assignRoleRequest());
      promises.push(
        metadataService.assignRole(userId, role.id)
          .then(() => {
            dispatch(assignRoleSuccess(role.id, userId));
          })
          .catch((error: Error) => {
            dispatch(assignRoleError(error));
            failed.push(role);
            errors.push(error);
          }),
      );
    });
    return Promise.all(promises).then(() => {
      if (failed.length === 0) {
        dispatch(assignRolesSuccess(userId, roles));
      } else {
        dispatch(assignRolesError(errors, failed));
      }
    });
  };
}

/**
 * Unassign role from a user
 * @param userId user id
 * @param roleId role id
 * @return A Promise
 */
export function removeRole(userId: string, roleId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(removeRoleRequest());
    try {
      await new MetadataService().removeRole(userId, roleId);
      dispatch(removeRoleSuccess(roleId, userId));
    } catch (error) {
      dispatch(removeRoleError(error));
    }
  };
}

/**
 * A compound thunk to unassign roles to a user
 * @param userId user id
 * @param roles roles array
 * @return A Promise
 */
export function removeRoles(userId: string, roles: Role[]) {
  return async (dispatch: AppDispatch): Promise<void> => {
    const metadataService = new MetadataService();
    const promises: Promise<void>[] = [];
    const failed: Role[] = [];
    const errors: Error[] = [];
    dispatch(removeRolesRequest());
    roles.forEach((role: Role) => {
      dispatch(removeRoleRequest());
      promises.push(
        metadataService.removeRole(userId, role.id)
          .then(() => {
            dispatch(removeRoleSuccess(role.id, userId));
          })
          .catch((error: Error) => {
            dispatch(removeRoleError(error));
            failed.push(role);
            errors.push(error);
          }),
      );
    });
    return Promise.all(promises).then(() => {
      if (failed.length === 0) {
        dispatch(removeRolesSuccess(userId, roles));
      } else {
        dispatch(removeRolesError(errors, failed));
      }
    });
  };
}

/**
 * Gets user's roles
 * @param userId user id
 * @return A Promise
 */
export function getUserRoles(userId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getUserRolesRequest());
    try {
      const roles = await new MetadataService().getUserRoles(userId);
      dispatch(getUserRolesSuccess(roles, userId));
    } catch (error) {
      dispatch(getUserRolesError(error));
    }
  };
}
