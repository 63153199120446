/** @module store/search */
/* eslint-disable @typescript-eslint/camelcase */
import { AnyAction } from 'redux';
import { SearchActionType, State } from './types';
import { RepositoryItemsActionType } from '../repositoryItems/types';
import { RepositoriesActionType } from '../repositories/types';

export const DEFAULT_STATE: State = null;

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
function reducer(state: State = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case SearchActionType.SEARCH_GET_SUCCESS:
      return action.payload.searchResponse;
    case RepositoryItemsActionType.REPOSITORY_ITEMS_UPDATE_SUCCESS: {
      const {
        id,
        newParentId,
        name,
      } = action.payload;
      if (state) {
        const updatedItem = state.results.find((x) => x.id.raw === id);
        if (updatedItem) {
          const updatedItemIndex = state.results.indexOf(updatedItem);
          const newUpdatedItem = {
            ...updatedItem,
            name: { raw: name },
            parent_id: { raw: newParentId },
          };
          const otherItems = state.results.filter((x) => x.id.raw !== id);
          const newResults = [...otherItems];
          newResults.splice(updatedItemIndex, 0, newUpdatedItem);
          return {
            ...state,
            results: newResults,
          };
        }
      }
      return state;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SUCCESS: {
      const { itemDetails } = action.payload;
      if (state) {
        return {
          ...state,
          results: state.results.filter((x) => x.id.raw !== itemDetails.id),
        };
      }
      return state;
    }
    case RepositoriesActionType.REPOSITORY_UPDATE_SUCCESS: {
      const {
        id,
        name,
      } = action.payload;
      if (state) {
        const updatedRepository = state.results.find((x) => x.id.raw === id);
        if (updatedRepository) {
          const updatedRepositoryIndex = state.results.indexOf(updatedRepository);
          const newUpdatedRepository = {
            ...updatedRepository,
            name: { raw: name },
          };
          const otherItems = state.results.filter((x) => x.id.raw !== id);
          const newResults = [...otherItems];
          newResults.splice(updatedRepositoryIndex, 0, newUpdatedRepository);
          return {
            ...state,
            results: newResults,
          };
        }
      }
      return state;
    }
    case RepositoriesActionType.REPOSITORY_DELETE_SUCCESS: {
      const { repository } = action.payload;
      if (state) {
        return {
          ...state,
          results: state.results.filter((x) => x.id.raw !== repository.id),
        };
      }
      return state;
    }
    case SearchActionType.SEARCH_GET_ERROR:
      return null;

    default:
      return state;
  }
}

export default reducer;
