/** @module utilities/asyncUtilities/promiseAllSettled */
/**
 * Provides a utility function that returns a promise which is
 * fulfilled with an array of promise state objects when all provided
 * promises have settled (either fulfilled or rejected).
 *
 * @param {Promise<any>[]} promises - An array of promises.
 * @returns {Promise<PromiseSettledResult<any>[]>}
 * A promise that resolves after all the provided promises have either fulfilled or rejected,
 * with an array of objects that each describe the outcome of each promise.
 */
function promiseAllSettled(promises: Promise<any>[]): Promise<PromiseSettledResult<any>[]> {
  return Promise.all(promises.map((p) => p
    .then<any>((value) => ({ status: 'fulfilled', value } as PromiseFulfilledResult<any>))
    .catch<any>((reason) => ({ status: 'rejected', reason } as PromiseRejectedResult))));
}

export default promiseAllSettled;
