/** @module components/Banner */
import React from 'react';
import { ReactComponent as AlertIcon } from 'assets/icons/warning-amber.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/clear.svg';
import './styles.scss';
import { Props } from './types';

export default function Banner(props: Props): JSX.Element {
  const {
    children,
    onClose,
    hasAlertIcon,
    className,
  } = props;
  return (
    <div className={`Banner ${className || ''}`}>
      {hasAlertIcon && (
        <AlertIcon
          width={24}
          height={24}
          data-test-id="alert-icon"
          className="alert-icon"
        />
      )}
      {children}
      <CloseIcon
        className="close"
        data-test-id="close"
        onClick={(): void => onClose()}
        width={14}
        height={14}
      />
    </div>
  );
}
