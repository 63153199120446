/** @module components/Drawer */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RepositoryIcon } from 'assets/icons/repository.svg';
import { ReactComponent as SharedWithMeIcon } from 'assets/icons/share-lg.svg';
import { ReactComponent as AdminConsole } from 'assets/icons/admin-console.svg';
import { isAdmin } from 'utilities/admin';
import { appPaths } from 'utilities/routes';
import {
  NavLinkObject,
  Props,
} from './types';
import './styles.scss';

function Drawer(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    myRepositories,
    sharedRepositories,
    me,
    collapsed,
    onCollapse,
  } = props;

  const myRepoIds = myRepositories.map((repo) => repo.id);
  const sharedRepoIds = sharedRepositories.map((repo) => repo.id);

  const myReposText = {
    svg: RepositoryIcon,
    to: '/my-repositories',
    text: t('Drawer.menu.myRepositories', 'My Repositories'),
  };
  const sharedReposText = {
    svg: SharedWithMeIcon,
    to: '/shared-repositories',
    text: t('Drawer.menu.sharedRepositories', 'Shared With Me'),
  };
  const adminConsoleText = {
    svg: AdminConsole,
    to: '/admin',
    text: t('Drawer.menu.adminConsole', 'Admin Console'),
  };
  const navLinks = [
    myReposText,
    sharedReposText,
  ];
  const externalNavLinks = [
    sharedReposText,
  ];

  if (isAdmin(me)) {
    navLinks.push(
      adminConsoleText,
    );
  }

  const links = (me.user && me.user.external) ? externalNavLinks : navLinks;
  return (
    <Layout.Sider
      defaultCollapsed={collapsed}
      className="Drawer"
      collapsible
      data-test-id="drawer"
      onCollapse={(isCollapsed: boolean, type: string): void => onCollapse(isCollapsed, type)}
    >
      {links.map(({ svg, to, text }: NavLinkObject) => {
        const SVG = svg;
        return (
          <NavLink
            className="nav-link"
            to={to}
            key={text}
            data-test-id={to}
            isActive={(match, location): boolean => {
              if (match) {
                return location.pathname.includes(match.url);
              }
              if (text === myReposText.text) {
                const currentRepo = location.pathname.split('/').pop() || '';
                return (location.pathname.includes('manage-repository')
                    || location.pathname.includes('manage-collaborators'))
                    && myRepoIds.includes(currentRepo);
              }
              if (text === sharedReposText.text) {
                const currentRepo = location.pathname.split('/').pop() || '';
                return (location.pathname.includes('manage-repository')
                    || location.pathname.includes('manage-collaborators'))
                    && sharedRepoIds.includes(currentRepo);
              }
              if (text === adminConsoleText.text) {
                return location.pathname.includes(appPaths.adminUserSearch)
                    || location.pathname.includes(appPaths.adminManageAdminRoles);
              }
              return false;
            }}
          >
            <SVG className="anticon" />
            <span className="ant-layout-sider-collapsible-text">{text}</span>
          </NavLink>
        );
      })}
    </Layout.Sider>
  );
}

export default Drawer;
