/** @module components/RepositoryTable */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import { generatePaginationOptions } from 'utilities/antd';
import { LicenseType, ProtectionType, Repository } from 'services/metadata';
import { ReactComponent as RepositoryIcon } from 'assets/icons/repository.svg';
import { ReactComponent as RestoreIcon } from 'assets/icons/reload.svg';
import { sortAlphabetical, sortDate } from 'utilities/sort';
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import ProtectionTag from '../ProtectionTag';
import ButtonsField from '../ButtonsField';
import NameField from '../NameField';
import SearchFilter from '../SearchFilter';
import { Props, State } from './types';
import './styles.scss';

class RepositoriesTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editingName: undefined,
    };
  }

  componentDidUpdate(newProps: Props): void {
    const {
      setSelectedRepositories,
      repositories,
      selectedRepositories,
    } = this.props;
    if (JSON.stringify(repositories) !== JSON.stringify(newProps.repositories)) {
      // Deselect repositories that do not exist
      const idSet: { [key: string]: boolean } = {};
      if (repositories) {
        repositories.forEach((repository) => { idSet[repository.id] = true; });
      }
      const newSelectedRepositories = selectedRepositories.filter(
        (repository) => (idSet[repository.id] === true),
      );
      setSelectedRepositories(newSelectedRepositories);
    }
  }

  componentWillUnmount(): void {
    if (localStorage.getItem('maintainSelectedRepositories') !== 'true') {
      const { setSelectedRepositories } = this.props;
      setSelectedRepositories([]);
    } else {
      localStorage.removeItem('maintainSelectedRepositories');
    }
  }

  onSelectChange(selectedRowKeys: React.ReactText[], selectedRows: object[]): void {
    const { setSelectedRepositories } = this.props;
    setSelectedRepositories(selectedRows);
  }

  setEditName = (item: Repository): void => {
    this.setState({ editingName: item });
  };

  cancelEdit = (): void => {
    this.setState({ editingName: undefined });
  };

  confirmEdit = (id: string, name: string): void => {
    const { updateName } = this.props;
    updateName(id, name);
    this.setState({ editingName: undefined });
  };

  showTags = (repositories: Repository[]): boolean => {
    const repoContainingTags = repositories.filter(
      (repository: Repository) => repository.tags && repository.tags.length > 0,
    );
    return repoContainingTags && repoContainingTags.length > 0;
  }

  filterBusinesses(): { text: string; value: string }[] {
    const { repositories } = this.props;
    const businesses = new Set<string>();
    repositories.forEach((repository) => {
      if (repository.business) {
        businesses.add(repository.business.displayName);
      }
    });
    const businessFilters = Array.from(businesses).map((businessName) => ({
      text: businessName,
      value: businessName,
    }));
    return businessFilters.filter((business) => business.text);
  }

  filterProtections(): { text: string; value: string }[] {
    const { repositories } = this.props;
    const protections = new Set<string>();
    repositories.forEach((repository) => {
      repository.protections.forEach((protection) => {
        protections.add(protection);
      });
      if (repository.isLimited) {
        protections.add(ProtectionType.LIMITED);
      }
    });
    const protectionFilters = Array.from(protections).map((protection) => ({
      text: protection,
      value: protection,
    }));
    return protectionFilters;
  }

  filterTags(): { text: string; value: string }[] {
    const { repositories } = this.props;
    const tags = new Set<string>();
    repositories.forEach((repository) => {
      if (repository.tags) {
        repository.tags.forEach((tag) => {
          tags.add(tag);
        });
      }
    });
    return Array.from(tags).map((tag) => ({
      text: tag,
      value: tag,
    }));
  }

  render(): JSX.Element {
    const {
      ownerColumn,
      repositories,
      loading,
      trash,
      t,
      restoreRepository,
      selectedRepositories,
      pageNumber,
      onChange,
    } = this.props;
    const rowSelection = {
      selectedRowKeys: selectedRepositories.map((r) => r.id),
      onChange: (
        selectedRowKeys: React.ReactText[],
        selectedRows: object[],
      ): void => this.onSelectChange(selectedRowKeys, selectedRows),
    };
    const { editingName } = this.state;
    const showTags = this.showTags(repositories);
    return (
      <Table
        dataSource={repositories}
        rowSelection={rowSelection}
        pagination={
          generatePaginationOptions(repositories, undefined,
            undefined, pageNumber, onChange)
}
        loading={loading}
        rowKey="id"
        size="middle"
        className="RepositoryTable"
        data-test-id="table"
      >
        <Table.Column
          title={t('RepositoryTable.column.name', 'Name')}
          dataIndex="name"
          className="name-column"
          render={(name: string, repository: Repository): JSX.Element => (
            <NameField
              text={name}
              link={trash ? undefined : `${window.location.pathname}/${repository.id}`}
              icon={(
                <RepositoryIcon
                  width={10}
                  height={10}
                  className="icon icon-indicator repository-icon"
                />
              )}
              editing={editingName === repository}
              loading={loading}
              onConfirm={(n: string): void => this.confirmEdit(repository.id, n)}
              onCancel={(): void => this.cancelEdit()}
            />
          )}
          sorter={(a: Repository, b: Repository): number => sortAlphabetical(a.name, b.name)}
          filterDropdown={({ setSelectedKeys, confirm, clearFilters }): JSX.Element => (
            <SearchFilter
              onSearch={(input): void => {
                if (setSelectedKeys) {
                  setSelectedKeys([input]);
                }
                if (confirm) {
                  confirm();
                }
              }}
              onReset={(): void => {
                if (clearFilters) {
                  clearFilters();
                }
              }}
            />
          )}
          onFilter={(value, record): boolean => {
            if (typeof value === 'string') {
              return record.name.toLowerCase().includes(value.toLowerCase());
            }
            return false;
          }}
          data-test-id="column-name"
        />
        <Table.Column
          title={t('RepositoryTable.column.business', 'Business')}
          key="business"
          className="business-column"
          render={(repository: Repository): string => (repository.business ? repository.business.displayName : '')}
          sorter={(a: Repository, b: Repository): number => {
            const nameA = a.business ? a.business.displayName : '';
            const nameB = b.business ? b.business.displayName : '';
            return nameA.localeCompare(nameB);
          }}
          filters={this.filterBusinesses()}
          filterMultiple
          onFilter={(value, record): boolean => {
            if (typeof value === 'string' && record.business) {
              return record.business.displayName.toLowerCase().includes(value.toLowerCase());
            }
            return false;
          }}
          data-test-id="column-business"
        />
        <Table.Column
          title={t('RepositoryTable.column.protections', 'Protections')}
          dataIndex="protections"
          className="protections-column"
          render={(protections: LicenseType[], repository: Repository): JSX.Element => (
            <span>
              {protections.map((protection: LicenseType) => (
                <span className="protection-tag" key={protection}>
                  <ProtectionTag
                    protection={protection}
                    key={protection}
                    data-test-id="protection-tag"
                  />
                </span>
              ))}
              {repository.isLimited && (
                <span className="protection-tag" key={ProtectionType.LIMITED}>
                  <ProtectionTag
                    protection={ProtectionType.LIMITED}
                    key={ProtectionType.LIMITED}
                    data-test-id="protection-tag-limited"
                  />
                </span>
              )}
            </span>
          )}
          filters={this.filterProtections()}
          filterMultiple
          onFilter={(value, record: Repository): boolean => {
            if (typeof value === 'string') {
              return record.protections.includes(value as LicenseType)
              || !!(value === ProtectionType.LIMITED && record.isLimited);
            }
            return false;
          }}
          data-test-id="column-protections"
        />
        {showTags && (
          <Table.Column
            title={t('RepositoryTable.column.tags', 'Tags')}
            dataIndex="tags"
            className="tags-column"
            render={(tags: string[]): JSX.Element => (
              <span>
                {tags && tags.map((tag: string) => (
                  <span className="tag-container" key={tag}>
                    <div key={tag} data-test-id="tag" className="tag">
                      <TagIcon className="tag-icon" />
                      {tag}
                    </div>
                  </span>
                ))}
              </span>
            )}
            filters={this.filterTags()}
            filterMultiple
            onFilter={(value, record: Repository): boolean => {
              if (typeof value === 'string') {
                return record.tags ? record.tags.includes(value) : false;
              }
              return false;
            }}
            data-test-id="column-tags"
          />
        )}
        {ownerColumn && (
          <Table.Column
            title={t('RepositoryTable.column.Owner', 'Owner')}
            key="owner"
            render={(repository: Repository): string => repository.owner.name}
            sorter={(a: Repository, b: Repository): number => (
              (a.owner.name.codePointAt(0) || 0)
              - (b.owner.name.codePointAt(0) || 0)
            )}
            filterDropdown={({ setSelectedKeys, confirm, clearFilters }): JSX.Element => (
              <SearchFilter
                onSearch={(input): void => {
                  if (setSelectedKeys) {
                    setSelectedKeys([input]);
                  }
                  if (confirm) {
                    confirm();
                  }
                }}
                onReset={(): void => {
                  if (clearFilters) {
                    clearFilters();
                  }
                }}
              />
            )}
            onFilter={(value, record): boolean => {
              if (typeof value === 'string') {
                return record.owner.name.toLowerCase().includes(value.toLowerCase());
              }
              return false;
            }}
            data-test-id="column-owner"
          />
        )}
        <Table.Column
          title={trash
            ? t('RepositoryTable.column.deletedOn', 'Deleted On')
            : t('RepositoryTable.column.updatedOn', 'Updated On')}
          key="updatedOn"
          className="updated-on-column"
          render={(repository: Repository): JSX.Element => (
            <span>
              {new Date(repository.lastUpdatedOn).toLocaleString(navigator.language)}
            </span>
          )}
          sorter={(a: Repository, b: Repository): number => (
            sortDate(a.lastUpdatedOn, b.lastUpdatedOn)
          )}
          data-test-id="column-updated-on"
        />
        {
          !trash
          && (
            <Table.Column
              title=""
              render={(repository: Repository): JSX.Element => (
                <ButtonsField
                  repository={repository}
                  setEditName={this.setEditName}
                />
              )}
              data-test-id="column-menu"
            />
          )
        }
        {trash
          && (
            <Table.Column
              align="center"
              title="Restore"
              render={(repository: Repository): JSX.Element => (
                <Button
                  type="link"
                  data-test-id="restore-button"
                  className="button"
                  onClick={(): void => restoreRepository(repository.id, repository.name)}
                  disabled={selectedRepositories.length > 0}
                >
                  <RestoreIcon
                    data-test-id="restore-icon"
                    className={`icon--primary restore-icon ${selectedRepositories.length > 0 ? 'disabled' : ''}`}
                  />
                </Button>
              )}
              data-test-id="column-restore"
            />
          )}
      </Table>
    );
  }
}

export default withTranslation()(RepositoriesTable);
