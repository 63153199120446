/** @module components/RepositoryWizard/fields */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Radio,
  Row,
  Col,
} from 'antd';
import { QuestionFieldProps } from '../types';
import './styles.scss';
import { EXTRA_SPAN, GUTTER, QUESTION_SPAN } from './constants';

/**
 * Asks the user if the repository contains EC technical data.
 * @param props The props
 * @return A JSX Element
 */
function ECDataQuestionField(props: QuestionFieldProps): JSX.Element {
  const { onChange, value } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={GUTTER} className="RepositoryWizardField">
      <Col span={QUESTION_SPAN}>
        <Form.Item
          name="ec-data"
          label={t(
            'RepositoryWizard.ECDataQuestionField.question',
            'Will this repository contain United States Export Control (EC) Technical Data?',
          )}
          rules={[{
            validator: (): Promise<void> => {
              if (value !== undefined) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('RepositoryWizard.ECDataQuestionField.required', 'Required')));
            },
          }]}
          colon={false}
          valuePropName=""
        >
          <Radio.Group
            data-test-id="radio-group"
            onChange={(e): void => onChange(e.target.value)}
            value={value}
          >
            <Radio.Button value>
              {t('RepositoryWizard.ECDataQuestionField.answer.yes', 'Yes')}
            </Radio.Button>
            <Radio.Button value={false}>
              {t('RepositoryWizard.ECDataQuestionField.answer.no', 'No')}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={EXTRA_SPAN}>
        <div className="extra">
          {t(
            'RepositoryWizard.ECDataQuestionField.description',
            'Hardware, software or Technology/Technical Data which is subject to any export regulations (includes both licensable and not licensable items)',
          )}
        </div>
      </Col>
    </Row>

  );
}

export default ECDataQuestionField;
