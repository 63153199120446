/** @module store/repositoryItems */
import { Action } from 'redux';
import { Item, ItemDetails } from 'services/metadata';
import { NormalizedObjects } from '../types';

export interface State extends NormalizedObjects<Item> {
  selected: ItemDetails[];
  previewUrl: string;
  uploadUrl: string;
  trash: {
    [repositoryId: string]: Item[];
  };
}

export enum RepositoryItemsActionType {
  REPOSITORY_ITEMS_GET_REQUEST = 'REPOSITORY_ITEMS_GET_REQUEST',
  REPOSITORY_ITEMS_GET_SUCCESS = 'REPOSITORY_ITEMS_GET_SUCCESS',
  REPOSITORY_ITEMS_GET_ERROR = 'REPOSITORY_ITEMS_GET_ERROR',
  REPOSITORY_ITEMS_CREATE_REQUEST = 'REPOSITORY_ITEMS_CREATE_REQUEST',
  REPOSITORY_ITEMS_CREATE_SUCCESS = 'REPOSITORY_ITEMS_CREATE_SUCCESS',
  REPOSITORY_ITEMS_CREATE_ERROR = 'REPOSITORY_ITEMS_CREATE_ERROR',
  REPOSITORY_ITEMS_UPDATE_REQUEST = 'REPOSITORY_ITEMS_UPDATE_REQUEST',
  REPOSITORY_ITEMS_UPDATE_SUCCESS = 'REPOSITORY_ITEMS_UPDATE_SUCCESS',
  REPOSITORY_ITEMS_UPDATE_ERROR = 'REPOSITORY_ITEMS_UPDATE_ERROR',
  REPOSITORY_ITEMS_MOVE_REQUEST = 'REPOSITORY_ITEMS_MOVE_REQUEST',
  REPOSITORY_ITEMS_MOVE_SUCCESS = 'REPOSITORY_ITEMS_MOVE_SUCCESS',
  REPOSITORY_ITEMS_MOVE_ERROR = 'REPOSITORY_ITEMS_MOVE_ERROR',
  REPOSITORY_ITEMS_MOVE_SELECTED_REQUEST = 'REPOSITORY_ITEMS_MOVE_SELECTED_REQUEST',
  REPOSITORY_ITEMS_MOVE_SELECTED_SUCCESS = 'REPOSITORY_ITEMS_MOVE_SELECTED_SUCCESS',
  REPOSITORY_ITEMS_MOVE_SELECTED_ERROR = 'REPOSITORY_ITEMS_MOVE_SELECTED_ERROR',
  REPOSITORY_ITEMS_DELETE_REQUEST = 'REPOSITORY_ITEMS_DELETE_REQUEST',
  REPOSITORY_ITEMS_DELETE_SUCCESS = 'REPOSITORY_ITEMS_DELETE_SUCCESS',
  REPOSITORY_ITEMS_DELETE_ERROR = 'REPOSITORY_ITEMS_DELETE_ERROR',
  REPOSITORY_ITEMS_DELETE_SELECTED_REQUEST = 'REPOSITORY_ITEMS_DELETE_SELECTED_REQUEST',
  REPOSITORY_ITEMS_DELETE_SELECTED_SUCCESS = 'REPOSITORY_ITEMS_DELETE_SELECTED_SUCCESS',
  REPOSITORY_ITEMS_DELETE_SELECTED_ERROR = 'REPOSITORY_ITEMS_DELETE_SELECTED_ERROR',
  REPOSITORY_ITEMS_PREVIEW_GET_REQUEST = 'REPOSITORY_ITEMS_PREVIEW_GET_REQUEST',
  REPOSITORY_ITEMS_PREVIEW_GET_SUCCESS = 'REPOSITORY_ITEMS_PREVIEW_GET_SUCCESS',
  REPOSITORY_ITEMS_PREVIEW_GET_ERROR = 'REPOSITORY_ITEMS_PREVIEW_GET_ERROR',
  REPOSITORY_ITEMS_UPLOAD_URL_GET_REQUEST = 'REPOSITORY_ITEMS_UPLOAD_URL_GET_REQUEST',
  REPOSITORY_ITEMS_UPLOAD_URL_GET_SUCCESS = 'REPOSITORY_ITEMS_UPLOAD_URL_GET_SUCCESS',
  REPOSITORY_ITEMS_UPLOAD_URL_GET_ERROR = 'REPOSITORY_ITEMS_UPLOAD_URL_GET_ERROR',
  REPOSITORY_ITEMS_SET_SELECTED = 'REPOSITORY_ITEMS_SET_SELECTED',
  REPOSITORY_ITEMS_REMOVE_SELECTED = 'REPOSITORY_ITEMS_REMOVE_SELECTED',
  REPOSITORY_ITEMS_CLEAR_SELECTION = 'REPOSITORY_ITEMS_CLEAR_SELECTION',
  REPOSITORY_ITEMS_TRASH_GET_REQUEST = 'REPOSITORY_ITEMS_TRASH_GET_REQUEST',
  REPOSITORY_ITEMS_TRASH_GET_SUCCESS = 'REPOSITORY_ITEMS_TRASH_GET_SUCCESS',
  REPOSITORY_ITEMS_TRASH_GET_ERROR = 'REPOSITORY_ITEMS_TRASH_GET_ERROR',
  REPOSITORY_ITEMS_RESTORE_REQUEST = 'REPOSITORY_ITEMS_RESTORE_REQUEST',
  REPOSITORY_ITEMS_RESTORE_SUCCESS = 'REPOSITORY_ITEMS_RESTORE_SUCCESS',
  REPOSITORY_ITEMS_RESTORE_ERROR = 'REPOSITORY_ITEMS_RESTORE_ERROR',
  REPOSITORY_ITEMS_RESTORE_SELECTED_REQUEST = 'REPOSITORY_ITEMS_RESTORE_SELECTED_REQUEST',
  REPOSITORY_ITEMS_RESTORE_SELECTED_SUCCESS = 'REPOSITORY_ITEMS_RESTORE_SELECTED_SUCCESS',
  REPOSITORY_ITEMS_RESTORE_SELECTED_ERROR = 'REPOSITORY_ITEMS_RESTORE_SELECTED_ERROR',
  REPOSITORY_ITEMS_CLIPBOARD_COPY_REQUEST = 'REPOSITORY_ITEMS_CLIPBOARD_COPY_REQUEST',
  REPOSITORY_ITEMS_CLIPBOARD_COPY_SUCCESS = 'REPOSITORY_ITEMS_CLIPBOARD_COPY_SUCCESS',
  REPOSITORY_ITEMS_CLIPBOARD_COPY_ERROR = 'REPOSITORY_ITEMS_CLIPBOARD_COPY_ERROR',
  REPOSITORY_ITEMS_RENAME_REQUEST = 'REPOSITORY_ITEMS_RENAME_REQUEST',
  REPOSITORY_ITEMS_RENAME_SUCCESS = 'REPOSITORY_ITEMS_RENAME_SUCCESS',
  REPOSITORY_ITEMS_RENAME_ERROR = 'REPOSITORY_ITEMS_RENAME_ERROR',
  REPOSITORY_ITEMS_DOWNLOAD_SELECTED_REQUEST = 'REPOSITORY_ITEMS_DOWNLOAD_SELECTED_REQUEST',
  REPOSITORY_ITEMS_DOWNLOAD_SELECTED_SUCCESS = 'REPOSITORY_ITEMS_DOWNLOAD_SELECTED_SUCCESS',
  REPOSITORY_ITEMS_DOWNLOAD_SELECTED_ERROR = 'REPOSITORY_ITEMS_DOWNLOAD_SELECTED_ERROR',
}

export interface RepositoryItemsGetRequestAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_GET_REQUEST
> {
  payload: {
    id: string;
  };
}

export interface RepositoryItemsGetSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_GET_SUCCESS
> {
  payload: {
    item: Item;
    id?: string;
  };
}

export interface RepositoryItemsGetErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_GET_ERROR
> {
  payload: {
    error: Error;
    showNotification: boolean;
    id?: string;
  };
}

export type RepositoryItemsCreateRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_CREATE_REQUEST
>;

export interface RepositoryItemsCreateSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_CREATE_SUCCESS
> {
  payload: {
    itemDetails: ItemDetails;
    parentId: string;
    showNotification: boolean;
  };
}

export interface RepositoryItemsCreateErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_CREATE_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RepositoryItemsUpdateRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_UPDATE_REQUEST
>;

export interface RepositoryItemsUpdateSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_UPDATE_SUCCESS
> {
  payload: {
    id: string;
    name: string;
    oldParentId: string;
    newParentId: string;
  };
}

export interface RepositoryItemsUpdateErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_UPDATE_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RepositoryItemsMoveRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_REQUEST
>;

export interface RepositoryItemsMoveSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_SUCCESS
> {
  payload: {
    itemDetails: ItemDetails;
    parentDetails?: ItemDetails;
  };
}

export interface RepositoryItemsMoveErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_ERROR
> {
  payload: {
    error: Error;
    exists: boolean;
    itemDetails: ItemDetails;
  };
}

export type RepositoryItemsMoveSelectedRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_SELECTED_REQUEST
>;

export interface RepositoryItemsMoveSelectedSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_SELECTED_SUCCESS
> {
  payload: {
    itemDetails: ItemDetails[];
  };
}

export interface RepositoryItemsMoveSelectedErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_SELECTED_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RepositoryItemsDeleteRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_REQUEST
>;

export interface RepositoryItemsDeleteSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SUCCESS
> {
  payload: {
    itemDetails: ItemDetails;
    showNotification: boolean;
  };
}

export interface RepositoryItemsDeleteErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_ERROR
> {
  payload: {
    error: Error;
    itemDetails: ItemDetails;
    showNotification: boolean;
  };
}

export type RepositoryItemsDeleteSelectedRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SELECTED_REQUEST
>;

export interface RepositoryItemsDeleteSelectedSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SELECTED_SUCCESS
> {
  payload: {
    itemDetails: ItemDetails[];
  };
}

export interface RepositoryItemsDeleteSelectedErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SELECTED_ERROR
> {
  payload: {
    error: Error[];
    itemDetails: ItemDetails[];
  };
}

export type RepositoryItemsPreviewGetRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_PREVIEW_GET_REQUEST
>;

export interface RepositoryItemsPreviewGetSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_PREVIEW_GET_SUCCESS
> {
  payload: {
    url: string;
  };
}

export interface RepositoryItemsPreviewGetErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_PREVIEW_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RepositoryItemsUploadUrlGetRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_UPLOAD_URL_GET_REQUEST
>;

export interface RepositoryItemsUploadUrlGetSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_UPLOAD_URL_GET_SUCCESS
> {
  payload: {
    url: string;
  };
}

export interface RepositoryItemsUploadUrlGetErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_UPLOAD_URL_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export interface RepositoryItemsSetSelectedAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_SET_SELECTED
> {
  payload: {
    itemDetails: ItemDetails[];
  };
}

export interface RepositoryItemsRemoveSelectedAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_REMOVE_SELECTED
> {
  payload: {
    itemDetails: ItemDetails;
  };
}
export type RepositoryItemsClearSelectionAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_CLEAR_SELECTION
>;

export type RepositoryItemsDownloadSelectedRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_DOWNLOAD_SELECTED_REQUEST
>;

export type RepositoryItemsDownloadSelectedSuccessAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_DOWNLOAD_SELECTED_SUCCESS
>;

export interface RepositoryItemsDownloadSelectedErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_DOWNLOAD_SELECTED_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RepositoryItemsTrashGetRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_TRASH_GET_REQUEST
>;

export interface RepositoryItemsTrashGetSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_TRASH_GET_SUCCESS
> {
  payload: {
    repositoryId: string;
    items: Item[];
  };
}

export interface RepositoryItemsTrashGetErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_TRASH_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RepositoryItemsRestoreRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_REQUEST
>;

export interface RepositoryItemsRestoreSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SUCCESS
> {
  payload: {
    itemDetails: ItemDetails;
    newParentId: string;
    showNotification: boolean;
  };
}

export interface RepositoryItemsRestoreErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_ERROR
> {
  payload: {
    error: Error;
    itemDetails: ItemDetails;
    showNotification: boolean;
  };
}

export type RepositoryItemsRestoreSelectedRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SELECTED_REQUEST
>;

export interface RepositoryItemsRestoreSelectedSuccessAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SELECTED_SUCCESS
> {
  payload: {
    itemDetails: ItemDetails[];
  };
}

export interface RepositoryItemsRestoreSelectedErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SELECTED_ERROR
> {
  payload: {
    error: Error[];
    itemDetails: ItemDetails[];
  };
}

export type RepositoryItemsClipboardCopyRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_CLIPBOARD_COPY_REQUEST
>;

export type RepositoryItemsClipboardCopySuccessAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_CLIPBOARD_COPY_SUCCESS
>;

export interface RepositoryItemsClipboardCopyErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_CLIPBOARD_COPY_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RepositoryItemsRenameRequestAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_RENAME_REQUEST
>;

export type RepositoryItemsRenameSuccessAction = Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_RENAME_SUCCESS
>;

export interface RepositoryItemsRenameErrorAction extends Action<
  RepositoryItemsActionType.REPOSITORY_ITEMS_RENAME_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RepositoryItemsAction = RepositoryItemsGetRequestAction
                                  | RepositoryItemsGetSuccessAction
                                  | RepositoryItemsGetErrorAction
                                  | RepositoryItemsCreateRequestAction
                                  | RepositoryItemsCreateSuccessAction
                                  | RepositoryItemsCreateErrorAction
                                  | RepositoryItemsUpdateRequestAction
                                  | RepositoryItemsUpdateSuccessAction
                                  | RepositoryItemsUpdateErrorAction
                                  | RepositoryItemsMoveRequestAction
                                  | RepositoryItemsMoveSuccessAction
                                  | RepositoryItemsMoveErrorAction
                                  | RepositoryItemsDeleteRequestAction
                                  | RepositoryItemsDeleteSuccessAction
                                  | RepositoryItemsDeleteErrorAction
                                  | RepositoryItemsDeleteSelectedRequestAction
                                  | RepositoryItemsDeleteSelectedSuccessAction
                                  | RepositoryItemsDeleteSelectedErrorAction
                                  | RepositoryItemsPreviewGetRequestAction
                                  | RepositoryItemsPreviewGetSuccessAction
                                  | RepositoryItemsPreviewGetErrorAction
                                  | RepositoryItemsUploadUrlGetRequestAction
                                  | RepositoryItemsUploadUrlGetSuccessAction
                                  | RepositoryItemsUploadUrlGetErrorAction
                                  | RepositoryItemsMoveSelectedRequestAction
                                  | RepositoryItemsMoveSelectedSuccessAction
                                  | RepositoryItemsMoveSelectedErrorAction
                                  | RepositoryItemsSetSelectedAction
                                  | RepositoryItemsRemoveSelectedAction
                                  | RepositoryItemsClearSelectionAction
                                  | RepositoryItemsTrashGetRequestAction
                                  | RepositoryItemsTrashGetSuccessAction
                                  | RepositoryItemsTrashGetErrorAction
                                  | RepositoryItemsRestoreRequestAction
                                  | RepositoryItemsRestoreSuccessAction
                                  | RepositoryItemsRestoreErrorAction
                                  | RepositoryItemsRestoreSelectedRequestAction
                                  | RepositoryItemsRestoreSelectedSuccessAction
                                  | RepositoryItemsRestoreSelectedErrorAction
                                  | RepositoryItemsClipboardCopyRequestAction
                                  | RepositoryItemsClipboardCopySuccessAction
                                  | RepositoryItemsClipboardCopyErrorAction
                                  | RepositoryItemsRenameRequestAction
                                  | RepositoryItemsRenameSuccessAction
                                  | RepositoryItemsRenameErrorAction
                                  | RepositoryItemsDownloadSelectedRequestAction
                                  | RepositoryItemsDownloadSelectedSuccessAction
                                  | RepositoryItemsDownloadSelectedErrorAction;
