/** @module components/UploadQueue */
import { connect } from 'react-redux';
import { UploadFile, UploadFolder } from 'services/storage';
import { AppDispatch, GlobalState } from 'store/types';
import { selectFileUploads, selectFolderUploads } from 'store/uploads/selectors';
import { uploadFile, uploadFolder } from 'store/uploads/actions';
import UploadQueue from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  fileUploads: selectFileUploads(state.uploads),
  folderUploads: selectFolderUploads(state.uploads),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  uploadFile: (upload: UploadFile): void => { dispatch(uploadFile(upload)); },
  uploadFolder: (upload: UploadFolder): void => { dispatch(uploadFolder(upload)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadQueue);
