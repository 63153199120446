import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  Menu,
} from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon-filled.svg';

import { Props } from './types';
import './styles.scss';

export default function DropdownWithCheckbox(props: Props): JSX.Element {
  const [searchKeyWord, setSearchKeyword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [visiblity, setVisiblity] = useState(false);
  const { t } = useTranslation();

  const {
    options,
    onAdd,
    onChange,
    selected,
    hasAddButton,
    disabled,
    placeholder,
    addButtonText,
    characterLimit,
    showArrow,
  } = props;

  let filteredOptions = options;

  if (searchKeyWord.length && options.length) {
    filteredOptions = options.filter((option) => (
      option.title.toLocaleLowerCase().includes(searchKeyWord.toLowerCase())));
  }

  const onSelect = (value: string, checked: boolean): void => {
    onChange(value, checked);
  };

  const onSubmit = (): void => {
    if (onAdd) onAdd(searchKeyWord);
    setSearchKeyword('');
    setVisiblity(false);
  };

  const onChangeInput = (value: string): void => {
    if (characterLimit) {
      const inputs = value.split(',');
      if (inputs[inputs.length - 1].length > characterLimit) {
        setHasError(true);
        setVisiblity(false);
      } else {
        setSearchKeyword(value);
        setHasError(false);
        setVisiblity(true);
      }
    } else {
      setSearchKeyword(value);
      setHasError(false);
      setVisiblity(true);
    }
  };

  const button = searchKeyWord.length ? (
    <Button
      className="add-button"
      type="primary"
      disabled={!searchKeyWord.length || disabled}
      onClick={(): void => onSubmit()}
      data-test-id="add-button"
    >
      <AddIcon
        className="add-icon"
        data-test-id="add-icon"
        width="16"
        height="16"
      />
      <span className="add-button-text">{addButtonText}</span>
    </Button>
  )
    : <SearchIcon className="search-icon" data-test-id="search-icon" width="18" height="18" />;

  const renderDropdownArrow = (): JSX.Element | null => {
    if (showArrow) {
      if (visiblity) {
        return (<UpOutlined data-test-id="arrow-up" className="arrow" onClick={(): void => setVisiblity(false)} />);
      }
      return (<DownOutlined data-test-id="arrow-down" className="arrow" onClick={(): void => setVisiblity(true)} />);
    }
    return null;
  };

  return (
    <div className="DropdownWithCheckbox" id="DropdownWithCheckbox">
      <Dropdown
        data-test-id="dropdown"
        getPopupContainer={(): HTMLElement => document.getElementById('DropdownWithCheckbox')
          || document.body}
        overlay={(
          <Menu style={{ maxHeight: '120px', overflow: 'auto' }} className="menu">
            {filteredOptions.length ? filteredOptions.map((option) => (
              <Menu.Item key={option.value} data-test-id="menu-item">
                <Checkbox
                  data-test-id="checkbox"
                  checked={selected.includes(option.value)}
                  onChange={(e): void => onSelect(option.value, e.target.checked)}
                >
                  {option.title}
                </Checkbox>
              </Menu.Item>
            ))
              : (
                <Menu.ItemGroup
                  title={(
                    <div className="no-results" data-test-id="no-results">
                      {t('DropdownWithCheckbox.noResults', 'No Results found')}
                    </div>
                  )}
                />
              )}
          </Menu>
        )}
        trigger={['click']}
        placement="bottomCenter"
        onVisibleChange={(v): void => setVisiblity(v)}
        visible={visiblity}
      >
        <>
          <Input
            className="input"
            data-test-id="input"
            addonAfter={hasAddButton ? button : null}
            onChange={(e): void => onChangeInput(e.target.value)}
            value={searchKeyWord}
            disabled={disabled}
            placeholder={placeholder}
            onClick={(): void => setVisiblity(true)}
            onKeyDown={(e): void => (
              e.keyCode === 13 && searchKeyWord.length > 0 ? onSubmit() : undefined
            )}
            suffix={renderDropdownArrow()}
          />
          {hasError
            && (
              <div className="error" data-test-id="error">
                {t('DropdownWithCheckbox.error', 'Maximum character limit is {{characterLimit}}', { characterLimit })}
              </div>
            )}
        </>
      </Dropdown>
    </div>
  );
}
