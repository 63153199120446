/** @module components/ReassignOwnerSection */
import { Dispatch, SetStateAction } from 'react';
import { CollaboratorsState } from 'store/collaborators/types';
import {
  Collaborator,
  CollaboratorIdentity,
  Me,
  User,
} from 'services/metadata';

export interface StateProps {
  loading: boolean;
  getMultiCollaboratorLoading: boolean;
  collaborators: CollaboratorsState;
  me: Me;
  selectedUser?: User;
  searchedUsers: User[];
  searchUserLoading: boolean;
  addCollaboratorError: string | null;
  updateCollaboratorError: string | null;
  updateCollaboratorLoading: boolean;
  addCollaboratorLoading: boolean;
}

export interface OwnProps {
  repoIds: string[];
  onReassignOwner: (repoId: string, reassignType: SubmitType, newOwnerName: string) => void;
  notReassignedRepoIds: string[];
  setNotReassignedRepoIds: Dispatch<SetStateAction<string[]>>;
}

export interface DispatchProps {
  addCollaborator: (
    collaboratorIdentity: CollaboratorIdentity,
    repositoryId: string,
  ) => void;
  updateCollaborator: (
    repositoryId: string,
    collaborator: Collaborator,
    newRole: string,
  ) => void;
  getCollaborators: (repoId: string) => void;
  getCollaboratorsMultipleRepos: (repoIds: string[]) => void;
  searchUsers: (searchTerm: string) => void;
}

export enum UserType {
  COLLABORATOR = 'COLLABORATOR',
  MYSELF = 'MYSELF',
  OTHER = 'OTHER',
}

export enum SubmitType {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

export type Props = DispatchProps & StateProps & OwnProps;
