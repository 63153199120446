import 'core-js';
import 'whatwg-fetch';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import DndHtml5Backend from 'react-dnd-html5-backend';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'utilities/history';
import createStore from './store';
import App from './components/App';
import Loader from './components/Loader';
import { unregister } from './registerServiceWorker';
import './i18n';
import 'normalize.css';
import './index.scss';

const store = createStore(history);

ReactDOM.render(
  <Suspense fallback={<Loader loading />}>
    <Provider store={store}>
      <DndProvider backend={DndHtml5Backend}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </DndProvider>
    </Provider>
  </Suspense>,
  document.getElementById('root') as HTMLElement,
);
unregister();
