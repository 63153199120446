
/** @module components/SnapshotsTable */
import React from 'react';
import {
  Button, Form, Modal, Radio,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateExportSegmentsModalProps, SegmentSize } from './types';

function CreateExportSegmentsModal(props: CreateExportSegmentsModalProps): JSX.Element {
  const { t } = useTranslation();
  const {
    isModalVisible,
    handleCancelModal,
    handleCreateSegments,
    newExportData,
    setNewExportData,
  } = props;
  return (

    <Modal
      title={t('SnapshotsTable.CreateExportSegmentsModal.title', 'Create Snapshot Segments')}
      visible={isModalVisible}
      onCancel={handleCancelModal}
      footer={null}
      className="ant-modal-create"
      data-test-id="create-snapshot-export-segmens-modal"
    >
      <div className="modal-content">
        <span className="modal-description small">
          {t('SnapshotsTable.CreateExportSegmentsModal.description.partOne', 'Snapshots can be broken down into downloadable “Bite Size” segments.')}
        </span>
        <span className="modal-description small">
          {t('SnapshotsTable.CreateExportSegmentsModal.description.partTwo', 'To create a batch of downloadable segments, please select a segment size.')}
        </span>
        <Form>
          <Radio.Group
            onChange={
              (e): void => {
                setNewExportData(
                  { ...newExportData, maxSegmentSize: e.target.value },
                );
              }
                      }
            value={newExportData.maxSegmentSize}
            data-test-id="radio-group"
          >
            <Radio.Button value={SegmentSize.SIZE_50MB}>50 MB</Radio.Button>
            <Radio.Button value={SegmentSize.SIZE_100MB}>100 MB</Radio.Button>
            <Radio.Button value={SegmentSize.SIZE_250MB}>250 MB</Radio.Button>
            <Radio.Button value={SegmentSize.SIZE_500MB}>500 MB</Radio.Button>
            <Radio.Button value={SegmentSize.SIZE_1GB}>1 GB</Radio.Button>
          </Radio.Group>
        </Form>

        <span className="modal-description small">
          {t('SnapshotsTable.CreateExportSegmentsModal.description.partThree', 'Please note that this size is based on the uncompressed file size of your repository and may not be exact. Files larger than the selected segment size will not be split.')}
        </span>
        <div className="modal-buttons">
          <Button
            key="cancel"
            type="ghost"
            className="button"
            onClick={handleCancelModal}
            data-test-id="cancel"
          >
            {t('ManageSnapshotsPage.CreateSnapshotModal.button.close', 'Cancel')}
          </Button>
          <Button
            key="create"
            type="primary"
            className="button"
            onClick={handleCreateSegments}
            data-test-id="create"
          >
            {t('ManageSnapshotsPage.CreateSnapshotModal.button.create', 'Create')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CreateExportSegmentsModal;
