/** @module components/MyRepositoriesTrashPage */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { getRepositoriesTrash } from 'store/repositories/actions';
import { selectMyRepositoriesTrash } from 'store/repositories/selectors';
import MyRepositoriesTrashPage from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'REPOSITORIES_TRASH_GET')
    || selectLoading(state.loading, 'REPOSITORIES_RESTORE')
    || selectLoading(state.loading, 'REPOSITORIES_RESTORE_SELECTED'),
  refreshing: selectLoading(state.loading, 'REPOSITORIES_TRASH_GET'),
  repositories: selectMyRepositoriesTrash(state),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onRefresh: (): void => { dispatch(getRepositoriesTrash()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyRepositoriesTrashPage);
