/** @module components/RepositoryWizard/fields */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Form,
  Radio,
  Row,
  Col,
} from 'antd';
import { QuestionFieldProps } from '../types';
import { EXTRA_SPAN, GUTTER, QUESTION_SPAN } from './constants';

/**
 * Asks the user if the repository contains CDI data.
 * @param props The props
 * @return A JSX Element
 */
function CDIQuestionField(props: QuestionFieldProps): JSX.Element {
  const { onChange, value } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={GUTTER} className="RepositoryWizardField">
      <Col span={QUESTION_SPAN}>
        <Form.Item
          name="cdi"
          label={t(
            'RepositoryWizard.CDIQuestionField.question',
            'Will this repository contain Covered Defense Information (CDI) Data?',
          )}
          rules={[{
            validator: (): Promise<void> => {
              if (value !== undefined) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('RepositoryWizard.CDIQuestionField.required', 'Required')));
            },
          }]}
          colon={false}
          valuePropName=""
        >
          <Radio.Group
            onChange={(e): void => onChange(e.target.value)}
            value={value}
            data-test-id="radio-group"
          >
            <Radio.Button value>
              {t('RepositoryWizard.CDIQuestionField.answer.yes', 'Yes')}
            </Radio.Button>
            <Radio.Button value={false}>
              {t('RepositoryWizard.CDIQuestionField.answer.no', 'No')}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={EXTRA_SPAN}>
        <div className="extra">
          <Trans i18nKey="RepositoryWizard.CDIQuestionField.descriptionn">
            The following types of data are considered CDI:
            <br />
            1. Unclassified controlled technical information (UCTI) that
            is:
            <ul>
              <li>
                Export controlled license required and used for a DoD
                contract (prime or sub) or marked with a DoD
                distribution statement B-F by the DoD contracting
                officer or prime contractor or required to be delivered
                by GE under a DoD contract (prime or sub)
              </li>
              <li>
                No license required and required to be delivered by GE
                under a DoD contract (prime or sub)
              </li>
            </ul>
            2. Unclassified non-technical information that is used for a
            DoD contract (prime or sub) and required to be delivered by
            GE under a DoD contract (prime or sub)
          </Trans>
        </div>
      </Col>
    </Row>
  );
}

export default CDIQuestionField;
