/** @module components/NameField */
import { DropTargetHookSpec } from 'react-dnd';
import { Props, DragObject } from './types';

const dropOptions = (props: Props): DropTargetHookSpec<DragObject, void, undefined> => ({
  accept: 'repository-item',
  drop: (dragObject: DragObject): void => {
    const { itemDetails, updateFileParent } = props;
    const droppedItemDetails = dragObject.payload;
    if (itemDetails && droppedItemDetails) {
      const isFolder = (itemDetails.type.toLowerCase() === 'folder');
      const isSelf = (itemDetails.id === droppedItemDetails.id);
      if (isFolder && !isSelf) {
        updateFileParent(droppedItemDetails, itemDetails.id);
      }
    }
  },
});

export default dropOptions;
