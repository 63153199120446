/** @module components/SaveTimePopoverContent */

import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Props } from './types';

const SaveTimePopoverContent = (props: Props): JSX.Element => {
  const {
    repositories,
    reassignedRepos,
    setNotReassignedRepoIds,
  } = props;
  const { t } = useTranslation();

  const onSaveTime = (): void => {
    setNotReassignedRepoIds(
      repositories.filter((repo) => !reassignedRepos.has(repo.id)).map((repo) => repo.id),
    );
  };

  return (
    <div className="save-time-pop-content">
      <div className="save-time-pop-content-text">
        {t('DeleteUserSteps.step2.saveTimePopoverText', 'Would you like to reassign the rest of the repositories to their earliest Co-Owner (where possible)?')}
      </div>
      <Button
        className="link-button"
        type="link"
        onClick={onSaveTime}
      >
        {t('DeleteUserSteps.step2.saveTimePopoverButton', 'Yes')}
      </Button>
    </div>
  );
};

export default SaveTimePopoverContent;
