/** @module components/UserSearchResultsPage */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { User } from 'services/metadata';
import { selectLoading } from 'store/loading/selectors';
import { getUsers, setSelectedUser } from 'store/users/actions';
import UserSearchResultsPage from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  users: state.users.searchedUsers,
  loading: selectLoading(state.loading, 'USERS_GET')
  || selectLoading(state.loading, 'USERS_UNBLOCK')
  || selectLoading(state.loading, 'USERS_BLOCK'),
  me: state.me,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  searchUsers: (query: string): void => { dispatch(getUsers(query)); },
  setSelectedUser: (user: User): void => { dispatch(setSelectedUser(user)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchResultsPage);
