/** @module components/AssignRolesModal */
import { connect } from 'react-redux';
import { Role } from 'services/metadata';
import { selectLoading } from 'store/loading/selectors';
import {
  assignRoles,
  getAllRoles,
  removeRoles,
} from 'store/roles/actions';
import { selectAllRoles, selectUserRoles } from 'store/roles/selectors';
import { AppDispatch, GlobalState } from 'store/types';
import AssignRolesModal from './component';
import { OwnProps, DispatchProps, StateProps } from './types';

export const mapStateToProps = (state: GlobalState, ownProps: OwnProps): StateProps => ({
  allRoles: selectAllRoles(state.roles),
  userRoles: selectUserRoles(state.roles, ownProps.user.id),
  loading: selectLoading(state.loading, 'ALL_ROLES_GET')
    || selectLoading(state.loading, 'USER_ROLES_GET')
    || selectLoading(state.loading, 'ROLES_REMOVE')
    || selectLoading(state.loading, 'ROLES_ASSIGN'),
  me: state.me,
});

export const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: OwnProps,
): DispatchProps => ({
  getAllRoles: (): void => { dispatch(getAllRoles()); },
  assignRoles: (roles: Role[]): void => { dispatch(assignRoles(ownProps.user.id, roles)); },
  removeRoles: (roles: Role[]): void => { dispatch(removeRoles(ownProps.user.id, roles)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignRolesModal);
