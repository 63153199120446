/** @module utilities/admin */

import { Me } from 'services/metadata';

/**
 * Checks if the user is an admin or not
 * @param me Me object
 * @return boolean
 */
export default function isAdmin(me: Me): boolean {
  let admin = false;
  const { permissions } = me;

  if (permissions) {
    const { global, byBusiness } = permissions;

    // Right now this only checks the length of permissions.
    // When we have more permissions other then admin permissions,
    // this can be changed to check specific permissions
    if (global.length > 0 || byBusiness.length > 0) {
      admin = true;
    }
  }
  return admin;
}
