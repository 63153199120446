/** @module components/SearchForm */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from 'utilities/history';
import FilterIcon from '@material-ui/icons/FilterList';
import queryString from 'query-string';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Switch,
} from 'antd';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';
import { Props } from './types';
import './styles.scss';

/**
 * Displays an expandable search form with optional filters.
 * @param props The props
 * @return A JSX Element
 */
function SearchForm(props: Props): JSX.Element {
  const {
    filters,
    repoIds,
  } = props;
  const [input, setInput] = useState('');
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  // const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [currentRepoOnly, setCurrentRepoOnly] = useState(true);
  const [includeFileContent, setIncludeFileContent] = useState(true);
  const [showCurrentRepoOnly, setShowCurrentRepoOnly] = useState(false);
  const inputRef = useRef<Input>(null);
  const searchFormRef = useRef<HTMLDivElement>(null);
  const filterMenuRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { search } = history.location;

  useEffect(() => {
    const parsedQueryString = queryString.parse(search);
    const query = parsedQueryString.query ? parsedQueryString.query.toString() : '';
    const repoIdsInQuery = parsedQueryString.repoIds ? parsedQueryString.repoIds.toString() : null;
    const initialCurrentRepoOnly = parsedQueryString.currentRepoOnly;
    const initialIncludeFileContent = parsedQueryString.includeFileContent;
    setInput(query);
    if (initialCurrentRepoOnly && initialCurrentRepoOnly === 'true') {
      setCurrentRepoOnly(true);
    }
    if (initialIncludeFileContent && initialIncludeFileContent === 'true') {
      setIncludeFileContent(true);
    }
    if (repoIds || repoIdsInQuery) {
      setShowCurrentRepoOnly(true);
    } else {
      setShowCurrentRepoOnly(false);
    }
  }, [setCurrentRepoOnly, setIncludeFileContent, setInput, search, repoIds]);

  /**
   * Sets up a listener to detect when the user clicks off of the form.
   * If the user clicks off of the search form and filter it will close the form.
   * If the user clicks off of the filter menu and button it will close the filter.
   */
  useEffect(() => {
    const onMouseDown = (event: MouseEvent): void => {
      const clickedOffSearchForm = searchFormRef.current
        ? !searchFormRef.current.contains((event.target as Node))
        : true;
      const clickedOffFilterMenu = filterMenuRef.current
        ? !filterMenuRef.current.contains((event.target as Node))
        : true;
      const clickedOffFilterButton = filterButtonRef.current
        ? !filterButtonRef.current.contains((event.target as Node))
        : true;
      if (clickedOffSearchForm && clickedOffFilterMenu && clickedOffFilterButton) {
        setOpen(false);
      }
      if (clickedOffFilterMenu && clickedOffFilterButton) {
        setFilterOpen(false);
      }
    };
    document.addEventListener('mousedown', onMouseDown);
    return (): void => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, [searchFormRef, filterMenuRef, filterButtonRef]);

  /**
   * Called when the user selects or deselects a filter.
   * @param filter The filter
   * @param selected Whether or not the filter was selected
   */
  // const onSelectFilter = (filter: string, selected: boolean): void => {
  //   if (selected) {
  //     if (!selectedFilters.includes(filter)) {
  //       setSelectedFilters([...selectedFilters, filter]);
  //     }
  //   } else if (selectedFilters.includes(filter)) {
  //     setSelectedFilters([...selectedFilters.filter((f) => f !== filter)]);
  //   }
  // };

  /**
   * Called when the search button is pressed. If the form is collapsed
   * it will open the form and focus the input field. If the form is open
   * it will search and collapse the form.
   */
  const onSubmit = (): void => {
    const parsedQueryString = queryString.parse(history.location.search);
    let repoIdFromUrl = parsedQueryString.repoIds;
    repoIdFromUrl = repoIdFromUrl ? repoIdFromUrl.toString() : '';
    let goBackUrlFromUrl = parsedQueryString.goBackUrl;
    goBackUrlFromUrl = goBackUrlFromUrl ? goBackUrlFromUrl.toString() : null;

    if (open) {
      if (input.trim()) {
        const { pathname } = history.location;
        const goBackUrl = goBackUrlFromUrl || pathname;
        history.push(`/search?query=${encodeURIComponent(input)}&repoIds=${encodeURIComponent(repoIds && repoIds.length ? repoIds.join(',') : repoIdFromUrl)}&filters=${encodeURIComponent('')}&currentRepoOnly=${encodeURIComponent(currentRepoOnly)}&includeFileContent=${encodeURIComponent(includeFileContent)}&goBackUrl=${encodeURIComponent(goBackUrl)}`);
      }
      if (inputRef.current) {
        inputRef.current.blur();
      }
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
    setOpen(!open);
  };

  return (
    <div
      ref={searchFormRef}
      className={`SearchForm ${open ? 'open' : ''}`}
      data-test-id="search-form"
    >
      <Input.Group compact>
        <div className="search-input">
          <Input
            data-test-id="search-input"
            ref={inputRef}
            value={input}
            onChange={(e): void => setInput(e.target.value)}
            placeholder={t('SearchForm.placeholder', 'Search Repository')}
            addonAfter={filters && (
              <div className="FilterDropdown" id="FilterDropdown">
                <Dropdown
                  data-test-id="filter-dropdown"
                  className="filter-dropdown"
                  getPopupContainer={(): HTMLElement => document.getElementById('FilterDropdown') || document.body}
                  visible={filterOpen}
                  overlayClassName="filter-dropdown-overlay"
                  overlay={(
                    <div ref={filterMenuRef}>
                      <Menu selectable={false} mode="horizontal" disabledOverflow>
                        {showCurrentRepoOnly && (
                        <Menu.Item key="current-repo" className="current-repo-toggle">
                          <Switch
                            data-test-id="current-repo-toggle"
                            checked={currentRepoOnly}
                            onClick={(): void => setCurrentRepoOnly(!currentRepoOnly)}
                          />
                          <span className="toggle-text">
                            {t('SearchForm.currentRepoOnly', 'Current repo only')}
                          </span>
                        </Menu.Item>
                        )}
                        <Menu.Item key="file-content">
                          <Switch
                            checked={includeFileContent}
                            onClick={(): void => setIncludeFileContent(!includeFileContent)}
                          />
                          <span className="toggle-text">
                            {t('SearchForm.includeFileContent', 'Include file content')}
                          </span>
                        </Menu.Item>
                      </Menu>
                      {/* <Menu selectable={false}>
                        {filters.map((filter) => (
                          <Menu.Item key={filter}>
                            <Checkbox
                              onChange={(e): void => onSelectFilter(filter, e.target.checked)}
                              data-test-id="filter-menu-item"
                            >
                              {filter}
                            </Checkbox>
                          </Menu.Item>
                        ))}
                      </Menu> */}
                    </div>
                  )}
                  overlayStyle={{
                    position: 'fixed',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                    minWidth: '395px',
                  }}
                  trigger={['click']}
                  placement="bottomLeft"
                >
                  <button
                    type="button"
                    onClick={(): void => setFilterOpen(!filterOpen)}
                    className="filter-button"
                    data-test-id="filter-button"
                    ref={filterButtonRef}
                  >
                    <FilterIcon className={includeFileContent || (showCurrentRepoOnly && currentRepoOnly) ? 'filter-icon-active' : 'filter-icon'} />
                  </button>
                </Dropdown>
              </div>
            )}
            onPressEnter={onSubmit}
            allowClear
          />
        </div>
        <Button
          type="default"
          onClick={onSubmit}
          className="search-button"
          data-test-id="search-button"
        >
          <SearchIcon
            className="search-icon"
            data-test-id="search-icon"
            width={open ? 16 : 24}
            height={open ? 16 : 24}
          />
        </Button>
      </Input.Group>
    </div>
  );
}

export default SearchForm;
