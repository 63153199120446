/** @module store/error */
import { ErrorState } from './types';


// TODO: this selector can be replaced with selectErrorMessage, but this would affect the existing
// components which use selectError state
/**
 * Selects the given requests error from the given state.
 *
 * @param state The error state
 * @param requestName The name of the request
 * @return A boolean
 */
export function selectError(
  state: ErrorState,
  requestName: string,
): boolean {
  const error = state[requestName];
  if (error === null || error === undefined) {
    return false;
  }
  return true;
}

/**
 * Selects the given request's error message from the given state.
 *
 * @param state The error state
 * @param requestName The name of the request
 * @return An error message or null
 */
export function selectErrorMessage(
  state: ErrorState,
  requestName: string,
): string | null {
  const error = state[requestName];
  return error || null;
}

/**
 * Selects the errors for the given request or
 * keyword which matches with the request names in error state.
 *
 * @param state The error state
 * @param requestName The name of the request or the Keyword to match
 * @return filtered ErrorState
 */
export function selectErrorMessagesForKeyword(
  state: ErrorState,
  requestName: string,
): ErrorState {
  const errors: ErrorState = {};
  Object.keys(state).forEach((error) => {
    if (error.includes(requestName)) {
      errors[error] = state[error];
    }
  });
  return errors;
}
