/** @module utilities/clipboard */
/**
 * Copies the given text to the clipboard by adding an invisible text-area
 * to the page, setting the text to the given text, selecting the
 * text-area, and executing a document exec command. The text-area
 * is removed from the page once the operation is complete.
 *
 * @param text The text to be copied to the clipboard
 * @return true if the given text was copied to the clipboard
 */
function copy(text: string | null): boolean {
  if (text) {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    let result;
    try {
      result = document.execCommand('copy');
    } catch (error) {
      result = false;
    }
    document.body.removeChild(textArea);
    return result;
  }
  return false;
}

export default copy;
