/** @module services/search */

export enum ItemTypes {
  FILE = '1',
  FOLDER = '2',
  REPOSITORY = '3',
}
export interface SearchResult {
  id: { raw: string };
  name: { raw: string };
  type_id: { raw: ItemTypes };
  repo_id: { raw: string };
  repo_name: { raw: string };
  parent_id: { raw: string };
  parent_name: { raw: string };
  created_on: { raw: string };
  size: { raw: number };
  deleted: { raw: string };
  implicit_delete: { raw: string };
  active: { raw: string };
  creator_sso: { raw: string };
  creator_email: { raw: string };
  creator_name: { raw: string };
  creator_uuid: { raw: string };
  updater_sso: { raw: string };
  updater_email: { raw: string };
  updater_name: { raw: string };
  updater_uuid: { raw: string };
  updated_on: { raw: string };
  _meta: {
    id: string;
    engine: string;
    score: number;
  };
  full_text: { snippet: string | null };
}
