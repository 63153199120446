/** @module services/metadata */
/**
 * Permissions that a user can have to perform actions (admin)
 */

export enum GlobalPermissions {
  GLOBAL_ASSIGN_ROLE = 'GLOBAL_ASSIGN_ROLE',
  GLOBAL_UPDATE_ROLE = 'GLOBAL_UPDATE_ROLE',
  GLOBAL_FETCH_USER_REPOSITORIES = 'GLOBAL_FETCH_USER_REPOSITORIES',
  GLOBAL_LIST_REPOSITORY_COLLABORATORS = 'GLOBAL_LIST_REPOSITORY_COLLABORATORS',
  GLOBAL_INVITE_COLLABORATOR = 'GLOBAL_INVITE_COLLABORATOR',
  GLOBAL_REMOVE_COLLABORATOR = 'GLOBAL_REMOVE_COLLABORATOR',
  GLOBAL_UPDATE_COLLABORATOR = 'GLOBAL_UPDATE_COLLABORATOR',
  GLOBAL_DELETE_REPOSITORY = 'GLOBAL_DELETE_REPOSITORY',
  GLOBAL_RENAME_REPOSITORY = 'GLOBAL_RENAME_REPOSITORY',
  GLOBAL_EVICT_REPOSITORY = 'GLOBAL_EVICT_REPOSITORY',
  GLOBAL_LIST_USER_ROLES = 'GLOBAL_LIST_USER_ROLES',
  GLOBAL_LOCK_USER = 'GLOBAL_LOCK_USER',
  GLOBAL_UNLOCK_USER = 'GLOBAL_UNLOCK_USER',
  GLOBAL_DELETE_USER = 'GLOBAL_DELETE_USER',
  GLOBAL_ADD_EMAIL_DOMAIN = 'GLOBAL_ADD_EMAIL_DOMAIN',
  GLOBAL_REMOVE_EMAIL_DOMAIN = 'GLOBAL_REMOVE_EMAIL_DOMAIN',
  GLOBAL_LIST_EMAIL_DOMAINS = 'GLOBAL_LIST_EMAIL_DOMAINS',
  GLOBAL_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH = 'GLOBAL_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH',
  GLOBAL_DELETE_TAG = 'GLOBAL_DELETE_TAG',
  GLOBAL_RENAME_TAG = 'GLOBAL_RENAME_TAG',
  GLOBAL_ADD_TAG_TO_REPOSITORY = 'GLOBAL_ADD_TAG_TO_REPOSITORY',
  GLOBAL_REMOVE_TAG_FROM_REPOSITORY = 'GLOBAL_REMOVE_TAG_FROM_REPOSITORY',
  GLOBAL_CREATE_GROUP = 'GLOBAL_CREATE_GROUP',
  GLOBAL_UPDATE_GROUP = 'GLOBAL_UPDATE_GROUP',
  GLOBAL_DELETE_GROUP = 'GLOBAL_DELETE_GROUP',
  GLOBAL_LIST_GROUPS = 'GLOBAL_LIST_GROUPS',
  GLOBAL_CREATE_REPOSITORY_SNAPSHOT = 'GLOBAL_CREATE_REPOSITORY_SNAPSHOT',
  GLOBAL_LIST_REPOSITORY_SNAPSHOTS = 'GLOBAL_LIST_REPOSITORY_SNAPSHOTS',
}

export enum BusinessPermissions {
  BUSINESS_ASSIGN_ROLE = 'BUSINESS_ASSIGN_ROLE',
  BUSINESS_UPDATE_ROLE = 'BUSINESS_UPDATE_ROLE',
  BUSINESS_FETCH_USER_REPOSITORIES = 'BUSINESS_FETCH_USER_REPOSITORIES',
  BUSINESS_LIST_REPOSITORY_COLLABORATORS = 'BUSINESS_LIST_REPOSITORY_COLLABORATORS',
  BUSINESS_INVITE_COLLABORATOR = 'BUSINESS_INVITE_COLLABORATOR',
  BUSINESS_REMOVE_COLLABORATOR = 'BUSINESS_REMOVE_COLLABORATOR',
  BUSINESS_UPDATE_COLLABORATOR = 'BUSINESS_UPDATE_COLLABORATOR',
  BUSINESS_DELETE_REPOSITORY = 'BUSINESS_DELETE_REPOSITORY',
  BUSINESS_RENAME_REPOSITORY = 'BUSINESS_RENAME_REPOSITORY',
  BUSINESS_EVICT_REPOSITORY = 'BUSINESS_EVICT_REPOSITORY',
  BUSINESS_LIST_USER_ROLES = 'BUSINESS_LIST_USER_ROLES',
  BUSINESS_LOCK_USER = 'BUSINESS_LOCK_USER',
  BUSINESS_UNLOCK_USER = 'BUSINESS_UNLOCK_USER',
  BUSINESS_DELETE_USER = 'BUSINESS_DELETE_USER',
  BUSINESS_ADD_EMAIL_DOMAIN = 'BUSINESS_ADD_EMAIL_DOMAIN',
  BUSINESS_REMOVE_EMAIL_DOMAIN = 'BUSINESS_REMOVE_EMAIL_DOMAIN',
  BUSINESS_LIST_EMAIL_DOMAINS = 'BUSINESS_LIST_EMAIL_DOMAINS',
  BUSINESS_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH = 'BUSINESS_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH',
  BUSINESS_DELETE_TAG = 'BUSINESS_DELETE_TAG',
  BUSINESS_RENAME_TAG = 'BUSINESS_RENAME_TAG',
  BUSINESS_ADD_TAG_TO_REPOSITORY = 'BUSINESS_ADD_TAG_TO_REPOSITORY',
  BUSINESS_REMOVE_TAG_FROM_REPOSITORY = 'BUSINESS_REMOVE_TAG_FROM_REPOSITORY',
  BUSINESS_CREATE_GROUP = 'BUSINESS_CREATE_GROUP',
  BUSINESS_UPDATE_GROUP = 'BUSINESS_UPDATE_GROUP',
  BUSINESS_DELETE_GROUP = 'BUSINESS_DELETE_GROUP',
  BUSINESS_LIST_GROUPS = 'BUSINESS_LIST_GROUPS',
  BUSINESS_CREATE_REPOSITORY_SNAPSHOT = 'BUSINESS_CREATE_REPOSITORY_SNAPSHOT',
  BUSINESS_LIST_REPOSITORY_SNAPSHOTS = 'BUSINESS_LIST_REPOSITORY_SNAPSHOTS',
}

export enum GlobalPermissionsBundle {
  GLOBAL_MANAGE_ADMIN_ROLES = 'GLOBAL_MANAGE_ADMIN_ROLES',
  GLOBAL_FETCH_USER_REPOSITORIES = 'GLOBAL_FETCH_USER_REPOSITORIES',
  GLOBAL_VIEW_COLLABORATORS = 'GLOBAL_VIEW_COLLABORATORS',
  GLOBAL_MANAGE_COLLABORATORS = 'GLOBAL_MANAGE_COLLABORATORS',
  GLOBAL_MANAGE_REPOSITORY = 'GLOBAL_MANAGE_REPOSITORY',
  GLOBAL_BLOCK_UNBLOCK_USER = 'GLOBAL_BLOCK_UNBLOCK_USER',
  GLOBAL_DELETE_USER = 'GLOBAL_DELETE_USER',
  GLOBAL_MANAGE_EMAIL_DOMAINS = 'GLOBAL_MANAGE_EMAIL_DOMAINS',
  GLOBAL_VIEW_EMAIL_DOMAINS = 'GLOBAL_VIEW_EMAIL_DOMAINS',
  GLOBAL_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH = 'GLOBAL_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH',
  GLOBAL_MANAGE_TAGS = 'GLOBAL_MANAGE_TAGS',
  GLOBAL_MANAGE_GROUPS = 'GLOBAL_MANAGE_GROUPS',
  GLOBAL_LIST_GROUPS = 'GLOBAL_LIST_GROUPS',
  GLOBAL_MANAGE_SNAPSHOTS = 'GLOBAL_MANAGE_SNAPSHOTS',
  GLOBAL_LIST_SNAPSHOTS = 'GLOBAL_LIST_SNAPSHOTS',
}

export enum BusinessPermissionsBundle {
  BUSINESS_MANAGE_ADMIN_ROLES = 'BUSINESS_MANAGE_ADMIN_ROLES',
  BUSINESS_FETCH_USER_REPOSITORIES = 'BUSINESS_FETCH_USER_REPOSITORIES',
  BUSINESS_VIEW_COLLABORATORS = 'BUSINESS_VIEW_COLLABORATORS',
  BUSINESS_MANAGE_COLLABORATORS = 'BUSINESS_MANAGE_COLLABORATORS',
  BUSINESS_MANAGE_REPOSITORY = 'BUSINESS_MANAGE_REPOSITORY',
  BUSINESS_BLOCK_UNBLOCK_USER = 'BUSINESS_BLOCK_UNBLOCK_USER',
  BUSINESS_DELETE_USER = 'BUSINESS_DELETE_USER',
  BUSINESS_MANAGE_EMAIL_DOMAINS = 'BUSINESS_MANAGE_EMAIL_DOMAINS',
  BUSINESS_VIEW_EMAIL_DOMAINS = 'BUSINESS_VIEW_EMAIL_DOMAINS',
  BUSINESS_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH = 'BUSINESS_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH',
  BUSINESS_MANAGE_TAGS = 'BUSINESS_MANAGE_TAGS',
  BUSINESS_MANAGE_GROUPS = 'BUSINESS_MANAGE_GROUPS',
  BUSINESS_LIST_GROUPS = 'BUSINESS_LIST_GROUPS',
  BUSINESS_MANAGE_SNAPSHOTS = 'BUSINESS_MANAGE_SNAPSHOTS',
  BUSINESS_LIST_SNAPSHOTS = 'BUSINESS_LIST_SNAPSHOTS',
}

export interface ByBusiness {
  businessId: string;
  permissions: BusinessPermissions[];
}

export interface Permissions {
  global: GlobalPermissions[];
  byBusiness: ByBusiness[];
}
