/** @module utilities/test */
import { Business } from 'services/metadata';

const mockBusiness: Business = {
  id: 'businessId',
  displayName: 'Business Name',
  wizardId: '',
};

export default mockBusiness;
