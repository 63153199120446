/** @module components/ReassignOwnerSection */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import { Collaborator, CollaboratorIdentity } from 'services/metadata';
import { CollaboratorsState } from 'store/collaborators/types';
import { SizeType } from 'components/UserSearchForm/types';
import { selectCollaborators } from 'store/collaborators/selectors';
import UserSearchForm from 'components/UserSearchForm';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import Loader from 'components/Loader';
import {
  Button,
  Input,
  Radio,
  Select,
} from 'antd';
import { ReactComponent as ReassignRepoImage } from 'assets/images/Reassign-repo.svg';
import { Props, SubmitType, UserType } from './types';
import './styles.scss';

export default function ReassignOwnerSection(props: Props): JSX.Element {
  const [coOwnersList, setCoOwnersList] = useState<Collaborator[]>([]);
  const [viewersList, setViewersList] = useState<Collaborator[]>([]);
  const [editorsList, setEditorsList] = useState<Collaborator[]>([]);
  const [selectedOwner, setSelectedOwner] = useState<Collaborator>();
  const [userType, setUserType] = useState(UserType.COLLABORATOR);
  const [isUserSearched, setIsUserSearched] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [hasSubmittedType, setHasSubmittedType] = useState<SubmitType>();
  const [reassignRepoError, setReassignRepoError] = useState(false);
  const [multipleReassigning, setMultipleReassigning] = useState(false);

  const {
    repoIds,
    notReassignedRepoIds,
    setNotReassignedRepoIds,
    getCollaborators,
    getCollaboratorsMultipleRepos,
    collaborators,
    loading,
    getMultiCollaboratorLoading,
    me,
    selectedUser,
    searchUsers,
    searchedUsers,
    updateCollaborator,
    addCollaborator,
    onReassignOwner,
    searchUserLoading,
    updateCollaboratorError,
    addCollaboratorError,
    addCollaboratorLoading,
    updateCollaboratorLoading,
  } = props;

  const { t } = useTranslation();
  const { Option, OptGroup } = Select;
  const prevRepoIds = useRef<string[]>([]);

  useEffect(() => {
    if (repoIds.length) {
      // get collaborators only for new repo ids
      const differenceInRepoIds = repoIds.filter((id) => !prevRepoIds.current.includes(id));
      if (differenceInRepoIds.length > 0) {
        setReassignRepoError(false);
        if (isUserSearched && searchedUsers.length === 0) {
          setIsUserSearched(false);
        }
      }
      differenceInRepoIds.forEach((repoId) => {
        getCollaborators(repoId);
        setHasSubmittedType(undefined);
      });
      prevRepoIds.current = repoIds;
    }
  }, [repoIds, getCollaborators]);


  /**  Computing the collaborators list, and finding out common collaborators
   * if more then one repo id is provided
  */
  useEffect(() => {
    let coOwners: Collaborator[] = [];
    let viewers: Collaborator[] = [];
    let editors: Collaborator[] = [];
    if (collaborators[repoIds[0]] && selectedUser) {
      const collaboratorsList = collaborators[repoIds[0]]
        .filter((collab) => collab.user.id !== selectedUser.id);
      collaboratorsList.forEach((collab) => {
        if (collab.role === 'COOWNER') {
          coOwners.push(collab);
        } else if (collab.role === 'VIEWER') {
          viewers.push(collab);
        } else if (collab.role === 'EDITOR') {
          editors.push(collab);
        }
      });
    }

    if (repoIds.length > 1) {
      // excluding index 1 because we already covered it in about condition
      for (let i = 1; i < repoIds.length; i += 1) {
        const repo = repoIds[i];
        const collaboratorsList = collaborators[repo];
        // getting the collaborators for each role for the repo in a temp variable
        // to intersect it with pervious repo's collaborators for each role
        const tempCoOwners: Collaborator[] = [];
        const tempViewers: Collaborator[] = [];
        const tempEditors: Collaborator[] = [];
        if (collaboratorsList) {
          collaboratorsList.forEach((collab) => {
            if (collab.role === 'COOWNER') {
              tempCoOwners.push(collab);
            } else if (collab.role === 'VIEWER') {
              tempViewers.push(collab);
            } else if (collab.role === 'EDITOR') {
              tempEditors.push(collab);
            }
          });
        }
        // finding the intersection
        const commonCoOwners = coOwners.filter(
          (collab) => tempCoOwners.some((tempCollab) => collab.user.id === tempCollab.user.id),
        );

        const commonViewers = viewers.filter(
          (collab) => tempViewers.some((tempCollab) => collab.user.id === tempCollab.user.id),
        );

        const commonEditors = editors.filter(
          (collab) => tempEditors.some((tempCollab) => collab.user.id === tempCollab.user.id),
        );

        coOwners = commonCoOwners;
        viewers = commonViewers;
        editors = commonEditors;

        // if there is no intersection in any of the roles for 2 repos,
        // we don't need to iterate any further as no more intersection is possible for other repos
        if (coOwners.length === 0 && viewers.length === 0 && editors.length === 0) {
          break;
        }
      }
    }
    setCoOwnersList(coOwners);
    setViewersList(viewers);
    setEditorsList(editors);
  }, [repoIds, collaborators]);

  /**
   * Sets show Submit button based on the select user type conditions
   */
  useEffect(() => {
    setShowSubmitButton(true);
  }, [userType, searchedUsers, isUserSearched, searchUserLoading, selectedOwner]);

  useEffect(() => {
    function filterCollaboratorsOldestCoowners(): CollaboratorsState {
      return notReassignedRepoIds.reduce((acc, repoId) => {
        const filteredCollabs = { ...acc };
        if (collaborators[repoId]) {
          const notLimitedCoowners = collaborators[repoId].filter((collab) => collab.role === 'COOWNER' && !collab.limited);
          if (notLimitedCoowners.length > 0) {
            const oldestCoowner = lodash.sortBy(
              notLimitedCoowners,
              (collab) => new Date(collab.createdOn),
            )[0];
            filteredCollabs[repoId] = [oldestCoowner];
          }
        }
        return filteredCollabs;
      }, {} as CollaboratorsState);
    }

    if (notReassignedRepoIds.length > 0) {
      if (!multipleReassigning) {
        setMultipleReassigning(true);
        getCollaboratorsMultipleRepos(notReassignedRepoIds);
      } else if (!getMultiCollaboratorLoading) {
        const oldestCoowners = filterCollaboratorsOldestCoowners();
        Object.entries(oldestCoowners).forEach(([repoId, coowner]) => {
          updateCollaborator(repoId, coowner[0], 'OWNER');
          onReassignOwner(repoId, SubmitType.UPDATE, coowner[0].user.name);
        });
        setMultipleReassigning(false);
        setNotReassignedRepoIds([]);
      }
    }
  }, [notReassignedRepoIds, getMultiCollaboratorLoading]);


  const onSelectExistingCollab = (collabId: string): void => {
    const [id, type] = collabId.split('_');

    let collab: Collaborator | undefined;
    if (type === 'co-owner') {
      collab = coOwnersList.find((c) => c.id === id);
    }
    if (type === 'editor') {
      collab = editorsList.find((c) => c.id === id);
    }
    if (type === 'viewer') {
      collab = viewersList.find((c) => c.id === id);
    }
    setSelectedOwner(collab);
    setHasSubmittedType(undefined);
  };

  const onReassign = (): void => {
    if (userType === UserType.COLLABORATOR && selectedOwner) {
      repoIds.forEach((repoId) => {
        updateCollaborator(repoId, selectedOwner, 'OWNER');
        onReassignOwner(repoId, SubmitType.UPDATE, selectedOwner.user.name);
      });
      setSelectedOwner(undefined);
      setShowSubmitButton(false);
      setHasSubmittedType(SubmitType.UPDATE);
    } else {
      let email: string | null = null;
      let newRepoOwnerName = '';
      if (userType === UserType.MYSELF && me.user) {
        email = me.user.email;
        newRepoOwnerName = me.user.name;
      } else if (userType === UserType.OTHER
        && searchedUsers.length
        && isUserSearched
        && !searchUserLoading
      ) {
        email = searchedUsers[0].email;
        newRepoOwnerName = searchedUsers[0].name;
      }

      if (email && selectedUser
        && (userType === UserType.MYSELF || (searchedUsers[0].id !== selectedUser.id))) {
        repoIds.forEach((repoId) => {
          const repoCollab = selectCollaborators(collaborators, repoId);
          const isCollab = repoCollab && repoCollab.find((collab) => collab.user.email === email);
          if (isCollab) {
            // if collaboration exists, update the collaborator
            updateCollaborator(repoId, isCollab, 'OWNER');
            setHasSubmittedType(SubmitType.UPDATE);
            onReassignOwner(repoId, SubmitType.UPDATE, isCollab.user.name);
          } else {
            // add the collaborator
            const collaboratorIdentity: CollaboratorIdentity = { identity: email || '', role: 'OWNER' };
            addCollaborator(collaboratorIdentity, repoId);
            setHasSubmittedType(SubmitType.ADD);
            onReassignOwner(repoId, SubmitType.ADD, newRepoOwnerName);
          }
        });
      } else {
        setReassignRepoError(true);
      }
    }
  };

  const onSearchUser = (searchQuery: string): void => {
    setReassignRepoError(false);
    searchUsers(searchQuery);
    setIsUserSearched(true);
    setHasSubmittedType(undefined);
  };

  const onChangeUserType = (value: UserType): void => {
    setUserType(value);
    setIsUserSearched(false);
    setHasSubmittedType(undefined);
  };

  return (
    <div className="ReassignOwnerSection">
      <div className="header">
        {t('ReassignOwnerSection.header', 'Reassign Owner')}
      </div>
      <div className="content">
        {(repoIds.length === 0 || loading || multipleReassigning) && (
          <>
            <Loader loading={loading || multipleReassigning} data-test-id="loader" />
            <div className="empty-state" data-test-id="empty-state">
              <ReassignRepoImage className="image" />
              {!loading && <div className="text">{t('ReassignOwnerSection.selectRepo', 'Select a repository')}</div>}
              {multipleReassigning && <div className="text">{t('ReassignOwnerSection.ReassigningMultipleRepositories', 'Reassigning multiple repositories (if possible)')}</div>}
            </div>
          </>
        )}
        {repoIds.length > 0 && !loading && (
          <>
            <div className="user-type-buttons">
              {t('ReassignOwnerSection.chooseUserType', 'Choose a User Type')}
              <Radio.Group
                defaultValue={userType}
                buttonStyle="solid"
                size="small"
                onChange={(e): void => onChangeUserType(e.target.value)}
                data-test-id="choose-type"
              >
                <Radio.Button value={UserType.COLLABORATOR}>{t('ReassignOwnerSection.radioBtn.collab', 'Collaborator')}</Radio.Button>
                <Radio.Button value={UserType.MYSELF}>{t('ReassignOwnerSection.radioBtn.self', 'Myself')}</Radio.Button>
                <Radio.Button value={UserType.OTHER}>{t('ReassignOwnerSection.radioBtn.other', 'Other')}</Radio.Button>
              </Radio.Group>
            </div>

            {userType === UserType.COLLABORATOR && (
              <div className="collab-select-container" data-test-id="collabs-tab">
                <div className="collab-select-container" data-test-id="collabs-selector-label">
                  {repoIds.length > 1 ? t('ReassignOwnerSection.chooseCommonCollab', 'Choose a Common Collaborator') : t('ReassignOwnerSection.chooseCollab', 'Choose a Collaborator')}
                </div>
                <Select
                  style={{ width: 292 }}
                  placeholder={t('ReassignOwnerSection.selectOne', 'Select One')}
                  onSelect={onSelectExistingCollab}
                  data-test-id="select-collab"
                >
                  {coOwnersList.length && (
                    <OptGroup label="Co-Owner" key="co-owner">
                      {coOwnersList.map((collab) => (
                        <Option value={`${collab.id}_co-owner`} key={`${collab.id}_co-owner`}>{collab.user.name}</Option>
                      ))}
                    </OptGroup>
                  )}
                  {editorsList.length && (
                    <OptGroup label="Editor" key="editor">
                      {editorsList.map((collab) => (
                        <Option value={`${collab.id}_editor`} key={`${collab.id}_editor`}>{collab.user.name}</Option>
                      ))}
                    </OptGroup>
                  )}
                  {viewersList.length && (
                    <OptGroup label="Viewer" key="viewer">
                      {viewersList.map((collab) => (
                        <Option value={`${collab.id}_viewer`} key={`${collab.id}_viewer`}>{collab.user.name}</Option>
                      ))}
                    </OptGroup>
                  )}
                </Select>
                {repoIds.length > 1 && !loading && (
                  <div className="italic-text" data-test-id="choose-common-collab-explanation">
                    {t('ReassignOwnerSection.chooseCommonCollabExplanation', 'Showing only the common collaborators across the selected repositories')}
                  </div>
                )}
                {!loading && !isUserSearched && (
                  (hasSubmittedType === SubmitType.ADD && addCollaboratorError)
                  || (hasSubmittedType === SubmitType.UPDATE && updateCollaboratorError)
                ) && (
                <div className="error-card" data-test-id="error-card-collab-tab">
                  <ErrorIcon className="error-icon" />
                  <div className="error-message">
                    {hasSubmittedType === SubmitType.ADD
                      ? addCollaboratorError : updateCollaboratorError}
                  </div>
                </div>
                )}
              </div>
            )}
            {userType === UserType.MYSELF && (
              <div className="input" data-test-id="myself-tab">
                {t('ReassignOwnerSection.chooseMyself', 'Myself')}
                <Input value={me.user && me.user.name} disabled />
                {!loading && !isUserSearched && (
                  (hasSubmittedType === SubmitType.ADD && addCollaboratorError)
                  || (hasSubmittedType === SubmitType.UPDATE && updateCollaboratorError)
                ) && (
                <div className="error-card" data-test-id="error-card-myself-tab">
                  <ErrorIcon className="error-icon" />
                  <div className="error-message">
                    {hasSubmittedType === SubmitType.ADD
                      ? addCollaboratorError : updateCollaboratorError}
                  </div>
                </div>
                )}
              </div>
            )}

            {userType === UserType.OTHER && (
              <div className="input" data-test-id="other-tab">
                {t('ReassignOwnerSection.chooseOther', 'Search for a User')}
                <UserSearchForm
                  data-test-id="user-search"
                  loading={searchUserLoading}
                  searchUsers={(query): void => onSearchUser(query)}
                  size={SizeType.MEDIUM}
                />
                {!loading && ((isUserSearched
                  && !searchUserLoading
                  && searchedUsers.length > 0)) && (
                  !reassignRepoError
                    ? (
                      <div className="searched-user-card" data-test-id="searched-user-card-other-tab">
                        {searchedUsers[0].name}
                      </div>

                    )
                    : (
                      <div className="error-card" data-test-id="error-card-other-tab">
                        <ErrorIcon className="error-icon" />
                        <div className="error-message">
                          {
                              repoIds.length > 1
                                ? t('ReassignOwnerSection.userIsAlreadyOwnerMultipleRepos', 'This user is already the owner of these repositories. Please select a different user.')
                                : t('ReassignOwnerSection.userIsAlreadyOwnerSingleRepo', 'This user is already the owner of this repository. Please select a different user.')
                            }
                        </div>
                      </div>
                    )
                )}
                {!loading && ((isUserSearched
                  && !searchUserLoading
                  && (searchedUsers.length === 0)) || (
                  ((hasSubmittedType === SubmitType.ADD && addCollaboratorError)
                      || (hasSubmittedType === SubmitType.UPDATE && updateCollaboratorError))
                )) && (
                <div className="error-card" data-test-id="error-card-other-tab">
                  <ErrorIcon className="error-icon" />
                  <div className="error-message">
                    {hasSubmittedType === SubmitType.ADD
                      ? addCollaboratorError : updateCollaboratorError}
                    {searchedUsers.length === 0 ? (
                      t('ReassignOwnerSection.userNotFound', 'User not found')
                    ) : null}
                  </div>
                </div>
                )}
              </div>
            )}

            {showSubmitButton && (
              <Button
                loading={loading || addCollaboratorLoading || updateCollaboratorLoading}
                className="ant-btn-primary reassign-button"
                data-test-id="reassign-button"
                onClick={onReassign}
              >
                {t('ReassignOwnerSection.reassign', 'REASSIGN')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
