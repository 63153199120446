/** @module store/uploads */
import { AnyAction } from 'redux';
import { UploadStatus, UploadFile } from 'services/storage';
import { selectActiveFileUploads, selectInactiveFileUploads } from './selectors';
import { State, UploadsActionType } from './types';

export const DEFAULT_STATE: State = {
  files: {},
  folders: {},
};

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
function reducer(state: State = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case UploadsActionType.UPLOADS_ENQUEUE_FILE: {
      const { upload } = action.payload;
      return {
        ...state,
        files: {
          ...state.files,
          [upload.id]: upload,
        },
      };
    }
    case UploadsActionType.UPLOADS_ENQUEUE_FILES: {
      const { uploads } = action.payload;
      const newFiles: { [key: string]: UploadFile } = {};
      uploads.forEach((upload: UploadFile) => {
        newFiles[upload.id] = upload;
        if (upload.folderId && state.folders[upload.folderId].status === UploadStatus.CANCELLED) {
          newFiles[upload.id] = { ...upload, status: UploadStatus.CANCELLED };
        }
      });
      return {
        ...state,
        files: {
          ...state.files,
          ...newFiles,
        },
      };
    }
    case UploadsActionType.UPLOADS_FILE_REQUEST: {
      const { id } = action.payload;
      const upload = state.files[id];
      if (upload) {
        return {
          ...state,
          files: {
            ...state.files,
            [upload.id]: {
              ...upload,
              status: UploadStatus.ACTIVE,
            },
          },
        };
      }
      return state;
    }
    case UploadsActionType.UPLOADS_FILE_PROGRESS: {
      const { id, progress } = action.payload;
      const upload = state.files[id];
      if (upload) {
        return {
          ...state,
          files: {
            ...state.files,
            [upload.id]: {
              ...upload,
              progress,
            },
          },
        };
      }
      return state;
    }
    case UploadsActionType.UPLOADS_FILE_SUCCESS: {
      const { id } = action.payload;
      const upload = state.files[id];
      if (upload) {
        return {
          ...state,
          files: {
            ...state.files,
            [upload.id]: {
              ...upload,
              status: UploadStatus.COMPLETED,
            },
          },
        };
      }
      return state;
    }
    case UploadsActionType.UPLOADS_FILE_ERROR: {
      const { id } = action.payload;
      const upload = state.files[id];
      if (upload) {
        return {
          ...state,
          files: {
            ...state.files,
            [upload.id]: {
              ...upload,
              status: UploadStatus.ERROR,
            },
          },
        };
      }
      return state;
    }
    case UploadsActionType.UPLOADS_FILE_CANCEL: {
      const { id } = action.payload;
      const upload = state.files[id];
      if (upload) {
        return {
          ...state,
          files: {
            ...state.files,
            [upload.id]: {
              ...upload,
              status: UploadStatus.CANCELLED,
            },
          },
        };
      }
      return state;
    }
    case UploadsActionType.UPLOADS_CLEAR: {
      const newState: State = { files: {}, folders: {} };
      const activeFileUploads = selectActiveFileUploads(state);
      activeFileUploads.forEach((u) => { newState.files[u.id] = u; });

      // Active folders are folders whose files are active
      // as the folder whose status is failed (one or more file upload failed)
      // could still be processing active upload.
      const activeFilesWithFolderIds = activeFileUploads.filter((file) => file.folderId);
      const activeFolderIds = new Set(activeFilesWithFolderIds.map((file) => file.folderId));
      Object.keys(state.folders).forEach((folderId) => {
        if (activeFolderIds.has(folderId)) {
          newState.folders[folderId] = state.folders[folderId];
        }
      });

      // Do not clear failed files of active folder upload
      const inActiveFileUploads = selectInactiveFileUploads(state);
      inActiveFileUploads.forEach((u) => {
        if (u.folderId && activeFolderIds.has(u.folderId)) {
          newState.files[u.id] = u;
        }
      });

      return newState;
    }
    case UploadsActionType.UPLOADS_ENQUEUE_FOLDER: {
      const { folder } = action.payload;
      return {
        ...state,
        folders: {
          ...state.folders,
          [folder.id]: folder,
        },
      };
    }
    case UploadsActionType.UPLOADS_FOLDER_CANCEL: {
      const { id } = action.payload;
      const folder = state.folders[id];
      if (folder) {
        const activeUploads = selectActiveFileUploads(state).filter((u) => u.folderId === id);
        const newFilesState = { ...state.files };
        activeUploads.forEach((u) => {
          newFilesState[u.id] = { ...u, status: UploadStatus.CANCELLED };
        });
        return {
          ...state,
          files: {
            ...newFilesState,
          },
          folders: {
            ...state.folders,
            [folder.id]: {
              ...state.folders[folder.id],
              status: UploadStatus.CANCELLED,
            },
          },
        };
      }
      return state;
    }
    case UploadsActionType.UPLOADS_FOLDER_REQUEST: {
      const { id } = action.payload;
      const upload = state.folders[id];
      if (upload) {
        return {
          ...state,
          folders: {
            ...state.folders,
            [upload.id]: {
              ...state.folders[upload.id],
              status: UploadStatus.ACTIVE,
            },
          },
        };
      }
      return state;
    }
    case UploadsActionType.UPLOADS_FOLDER_PROGRESS: {
      const { id } = action.payload;
      const upload = state.folders[id];
      if (upload) {
        let progress = upload.progress + (1 / upload.files.length);
        // exception in case of 6 files
        if (progress >= 0.9999) progress = 1;
        return {
          ...state,
          folders: {
            ...state.folders,
            [upload.id]: {
              ...state.folders[upload.id],
              progress,
              status: progress >= 1 ? UploadStatus.COMPLETED : upload.status,
            },
          },
        };
      }
      return state;
    }
    case UploadsActionType.UPLOADS_FOLDER_ERROR: {
      const { id } = action.payload;
      const upload = state.folders[id];
      if (upload) {
        return {
          ...state,
          folders: {
            ...state.folders,
            [upload.id]: {
              ...upload,
              status: UploadStatus.ERROR,
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
}

export default reducer;
