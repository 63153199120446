/** @module store */
import { Reducer, combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import licensesReducer from './licenses/reducer';
import repositoriesReducer from './repositories/reducer';
import loadingReducer from './loading/reducer';
import errorReducer from './error/reducer';
import itemsReducer from './repositoryItems/reducer';
import notificationsReducer from './notifications/reducer';
import announcementsReducer from './announcements/reducer';
import partnersReducer from './partners/reducer';
import meReducer from './me/reducer';
import collaboratorsReducer from './collaborators/reducer';
import usersReducer from './users/reducer';
import featuresReducer from './features/reducer';
import releaseNotesReducer from './release-notes/reducer';
import searchReducer from './search/reducer';
import tagsReducer from './tags/reducer';
import businessesReducer from './businesses/reducer';
import uploadsReducer from './uploads/reducer';
import userRepositoriesReducer from './user-repositories/reducer';
import rolesReducer from './roles/reducer';
import snapshotsReducer from './snapshots/reducer';

function createReducer(history: History): Reducer {
  return combineReducers({
    licenses: licensesReducer,
    announcements: announcementsReducer,
    router: connectRouter(history),
    repositories: repositoriesReducer,
    loading: loadingReducer,
    items: itemsReducer,
    error: errorReducer,
    notifications: notificationsReducer,
    partners: partnersReducer,
    me: meReducer,
    collaborators: collaboratorsReducer,
    users: usersReducer,
    features: featuresReducer,
    releaseNotes: releaseNotesReducer,
    search: searchReducer,
    tags: tagsReducer,
    businesses: businessesReducer,
    uploads: uploadsReducer,
    userRepositories: userRepositoriesReducer,
    roles: rolesReducer,
    snapshots: snapshotsReducer,
  });
}

export default createReducer;
