/** @module utilities/test */
import { LicenseType, Repository } from 'services/metadata';
import { mockActions, mockBusiness } from '.';

const mockRepository: Repository = {
  id: '0',
  name: 'repo',
  owner: {
    id: '0',
    name: 'foo',
    email: 'foo@ge.com',
  },
  business: mockBusiness,
  dateShared: new Date().toString(),
  protections: [LicenseType.DOC],
  actions: mockActions,
  lastUpdatedOn: new Date(),
};

export default mockRepository;
