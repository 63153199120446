/** @module components/NameField */
import React, { useState } from 'react';
import {
  Form,
  Button,
  Input,
} from 'antd';
import { Link } from 'react-router-dom';
import {
  repoOrPersonNameValidationRules,
  folderNameValidationRules,
  fileValidationRules,
} from 'utilities/form/rules';
import { getName, getExtension } from 'utilities/file';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Props } from './types';
import './styles.scss';

/**
 * Displays a file, folder, or repository name. Allows the user to edit
 * the name.
 */
function NameField(props: Props): JSX.Element {
  const {
    editing,
    loading,
    text,
    onConfirm,
    onCancel,
    link,
    icon,
    onClick,
    itemDetails,
    isTitle,
  } = props;
  const isFile = itemDetails ? itemDetails.type.toLowerCase() === 'file' : false;
  const isFolder = itemDetails ? itemDetails.type.toLowerCase() === 'folder' : false;
  const calculatedInput = isFile ? getName(text) : text;
  const [input, setInput] = useState(calculatedInput);
  const calculatedOutput = isFile ? `${input}${getExtension(text)}` : input;
  const extensionLength = isFile ? getExtension(text).length : 0;

  let appliedRules = folderNameValidationRules;
  if (isFile) {
    appliedRules = fileValidationRules(extensionLength);
  } else if (!(isFile || isFolder)) {
    appliedRules = repoOrPersonNameValidationRules;
  }

  /**
   * Resets the state to contain the original props text if the component
   * is not editing or loading. Equivalent to using getDerivedStateFromProps.
   */
  if (!editing && !loading && (calculatedInput !== input)) {
    setInput(calculatedInput);
  }

  /**
   * Calls onConfirm if the text has changed, and onCancel if it has not.
   */
  function onFinish(): void {
    if (text === calculatedOutput) {
      onCancel();
    } else {
      onConfirm(calculatedOutput);
    }
  }

  return (
    <div className="NameField">
      {editing && (
        <Form
          className="form"
          onFinish={onFinish}
          data-test-id="form"
        >
          {icon}
          <Form.Item
            name="folderName"
            colon={false}
            className="name-input"
            data-test-id="name-input"
            rules={appliedRules}
            initialValue={input}
          >
            <Input
              autoFocus
              onChange={(e): void => setInput(e.target.value)}
              data-test-id="input"
              value={input}
            />
          </Form.Item>
          <div className="actions">
            <Form.Item noStyle>
              <Button
                className="action-button"
                type="link"
              >
                <CloseIcon
                  width={15}
                  height={15}
                  className="icon edit-icon"
                  onClick={onCancel}
                  data-test-id="cancel"
                />
              </Button>
            </Form.Item>
            <Form.Item noStyle>
              <Button
                className="action-button"
                type="link"
                htmlType="submit"
              >
                <CheckIcon
                  width={15}
                  height={15}
                  className="icon edit-icon"
                  data-test-id="confirm"
                />
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
      {!editing && link && (
        <Link
          to={link}
          className="name-button"
          data-test-id="link"
        >
          {icon}
          <div className={`text ${isTitle ? 'header-title' : ''}`}>{calculatedOutput}</div>
        </Link>
      )}
      {!editing && !link && (
        <Button
          type="link"
          className="name-button"
          onClick={onClick}
          data-test-id="button"
        >
          {icon}
          <div className={`text ${isTitle ? 'header-title' : ''}`}>{calculatedOutput}</div>
        </Button>
      )}
    </div>
  );
}

export default NameField;
