/** @module components/SnapshotsTable */
import { connect } from 'react-redux';
import { GlobalState, AppDispatch } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import {
  createSnapshotExport,
  downloadSnapshotExportSegment,
  getMultipleSnapshotSegments,
  getMultipleSnapshotsExports,
  getSnapshots,
} from 'store/snapshots/actions';
import {
  selectDownloadStatusesForRepository,
  selectSnapshotExportSegmentsFromStore,
  selectSnapshotExportsFromStore,
  selectSnapshots,
} from 'store/snapshots/selectors';
import { SnapshotExport } from 'services/metadata';
import SnapshotsTable from './component';
import {
  StateProps,
  DispatchProps,
  OwnProps,
} from './types';

export const mapStateToProps = (state: GlobalState, props: OwnProps): StateProps => ({
  loading: selectLoading(state.loading, 'SNAPSHOTS_GET')
  || selectLoading(state.loading, 'MULTI_SNAPSHOT_EXPORTS_GET')
  || selectLoading(state.loading, 'MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET'),
  creatingSnapshotExport: selectLoading(state.loading, 'SNAPSHOT_EXPORT_CREATE'),
  snapshots: selectSnapshots(state.snapshots, props.repository.id),
  snapshotExports: selectSnapshotExportsFromStore(state.snapshots, props.repository.id),
  snapshotExportSegments: selectSnapshotExportSegmentsFromStore(
    state.snapshots,
    props.repository.id,
  ),
  downloadStatuses: selectDownloadStatusesForRepository(state.snapshots, props.repository.id),
  me: state.me,
});

export const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps): DispatchProps => ({
  getSnapshots: (): void => { dispatch(getSnapshots(props.repository.id)); },
  getExportsMultipleSnapshots: (snapshotIds: string[]): void => {
    dispatch(getMultipleSnapshotsExports(props.repository.id, snapshotIds));
  },
  getSegmentsMultipleSnapshotsExports: (snapshotExports: Map<string, SnapshotExport[]>): void => {
    dispatch(getMultipleSnapshotSegments(props.repository.id, snapshotExports));
  },
  downloadSnapshotExportSegment: (snapshotId, exportId, segmentNumber): void => {
    dispatch(downloadSnapshotExportSegment(
      props.repository.id,
      snapshotId,
      exportId,
      segmentNumber,
    ));
  },
  createSnapshotExport: (snapshotId, maxSegmentSize): void => {
    dispatch(createSnapshotExport(
      props.repository.id,
      snapshotId,
      maxSegmentSize,
    ));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SnapshotsTable);
