/** @module components/LicenseList */
import { connect } from 'react-redux';
import { selectLicenses } from 'store/licenses/selectors';
import { getLicenses, deleteLicense } from 'store/licenses/actions';
import { selectLoading } from 'store/loading/selectors';
import { GlobalState, AppDispatch } from 'store/types';
import { OwnProps, StateProps, DispatchProps } from './types';
import LicenseList from './component';

export const mapStateToProps = (state: GlobalState, props: OwnProps): StateProps => ({
  licenses: selectLicenses(state.licenses, props.repository.id),
  loading: (
    selectLoading(state.loading, 'LICENSES_GET')
    || selectLoading(state.loading, 'LICENSES_ADD')
    || selectLoading(state.loading, 'LICENSES_DELETE')
    || selectLoading(state.loading, 'LICENSES_GET_ALL')
  ),
  refreshing: selectLoading(state.loading, 'LICENSES_GET'),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  fetchLicenses: (repositoryId: string): void => { dispatch(getLicenses(repositoryId)); },
  deleteLicense: (repositoryId: string, license: string): void => {
    dispatch(deleteLicense(repositoryId, license));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LicenseList);
