/** @module store/user-repositories */
import MetadataService, { Repository } from 'services/metadata';
import { AppDispatch } from '../types';
import {
  UserRepositoriesActionType,
  UsersRepositoriesGetRequestAction,
  UsersRepositoriesGetSuccessAction,
  UsersRepositoriesGetErrorAction,
  UsersRepositoriesDeleteRequestAction,
  UsersRepositoriesDeleteSuccessAction,
  UsersRepositoriesDeleteErrorAction,
  UsersRepositoriesRenameRequestAction,
  UsersRepositoriesRenameSuccessAction,
  UsersRepositoriesRenameErrorAction,
} from './types';

export const getUserRepositoriesRequest = (): UsersRepositoriesGetRequestAction => ({
  type: UserRepositoriesActionType.USERS_REPOSITORIES_GET_REQUEST,
});

export const getUserRepositoriesSuccess = (
  userId: string,
  repositories: Repository[],
): UsersRepositoriesGetSuccessAction => ({
  type: UserRepositoriesActionType.USERS_REPOSITORIES_GET_SUCCESS,
  payload: {
    userId,
    repositories,
  },
});

export const getUserRepositoriesError = (error: Error): UsersRepositoriesGetErrorAction => ({
  type: UserRepositoriesActionType.USERS_REPOSITORIES_GET_ERROR,
  payload: {
    error,
  },
});

export const deleteUserRepositoryRequest = (): UsersRepositoriesDeleteRequestAction => ({
  type: UserRepositoriesActionType.USERS_REPOSITORIES_DELETE_REQUEST,
});

export const deleteUserRepositorySuccess = (
  repository: Repository,
  userId: string,
): UsersRepositoriesDeleteSuccessAction => ({
  payload: {
    repository,
    userId,
    showNotification: true,
  },
  type: UserRepositoriesActionType.USERS_REPOSITORIES_DELETE_SUCCESS,
});

export const deleteUserRepositoryError = (
  error: Error,
): UsersRepositoriesDeleteErrorAction => ({
  payload: {
    error,
    showNotification: true,
  },
  type: UserRepositoriesActionType.USERS_REPOSITORIES_DELETE_ERROR,
});


export const renameUserRepositoryRequest = (): UsersRepositoriesRenameRequestAction => ({
  type: UserRepositoriesActionType.USERS_REPOSITORIES_RENAME_REQUEST,
});

export const renameUserRepositorySuccess = (
  userId: string,
  repoId: string,
  name: string,
): UsersRepositoriesRenameSuccessAction => ({
  type: UserRepositoriesActionType.USERS_REPOSITORIES_RENAME_SUCCESS,
  payload: {
    userId,
    repoId,
    name,
  },
});

export const renameUserRepositoryError = (error: Error): UsersRepositoriesRenameErrorAction => ({
  type: UserRepositoriesActionType.USERS_REPOSITORIES_RENAME_ERROR,
  payload: {
    error,
  },
});

/**
 * A thunk that gets all the repositories of a user
 * @param userId A user id
 * @return A thunk action which returns a promise containing userId and an array of repositories
 */
export function getUserRepositories(userId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getUserRepositoriesRequest());
    try {
      const repositories = await new MetadataService().getUserRepositories(userId);
      dispatch(getUserRepositoriesSuccess(userId, repositories));
    } catch (error) {
      dispatch(getUserRepositoriesError(error));
    }
  };
}

/**
 * A thunk that deletes a user's repository
 * @param repository a repository to be deleted
 * @param userId A user id
 * @return promise
 */
export function deleteUserRepository(repository: Repository, userId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(deleteUserRepositoryRequest());
    try {
      await new MetadataService().deleteRepository(repository.id);
      dispatch(deleteUserRepositorySuccess(repository, userId));
    } catch (error) {
      dispatch(deleteUserRepositoryError(error));
    }
  };
}

/**
 * A thunk that renames a user's repository
 * @param repoId id of the repository to be renamed
 * @param userId A user id
 * @param name updated name
 * @return promise
 */
export function renameUserRepository(userId: string, repoId: string, name: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(renameUserRepositoryRequest());
    try {
      await new MetadataService().updateRepository(repoId, name);
      dispatch(renameUserRepositorySuccess(userId, repoId, name));
    } catch (error) {
      dispatch(renameUserRepositoryError(error));
    }
  };
}
