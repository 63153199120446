/** @module components/DOCBanner */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE_KEY_DOC_BANNER } from 'utilities/constants';
import { LicenseType } from 'services/metadata';
import Banner from '../Banner';
import { Props } from './types';

/**
 * A banner that lets the user know they should review the TCP folder when they
 * first visit a DOC repository.
 * @param props The props
 * @return A JSX Element or null
 */
function DOCBanner(props: Props): JSX.Element | null {
  const { repository, hasAccessToRepository } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  /**
   * Sets the banner to visible if the user has not yet seen it for the
   * current repository.
   */
  useEffect(() => {
    /**
     * Returns true if the user has seen the banner for the current repository.
     * @return true if the user has seen the banner for the current repository.
     */
    const hasSeen = (): boolean => {
      const raw = localStorage.getItem(LOCAL_STORAGE_KEY_DOC_BANNER);
      if (repository && raw) {
        const seen: string[] = JSON.parse(raw);
        if (seen.includes(repository.id)) {
          return true;
        }
      }
      return false;
    };

    if (
      repository
      && repository.protections.includes(LicenseType.DOC)
      && hasAccessToRepository
      && !hasSeen()
    ) {
      setOpen(true);
    }
  }, [repository, hasAccessToRepository]);

  /**
   * Sets the local storage state to seen for the current repository.
   */
  const setSeen = (): void => {
    if (repository) {
      const raw = localStorage.getItem(LOCAL_STORAGE_KEY_DOC_BANNER);

      if (raw) {
        const seen: string[] = JSON.parse(raw);
        if (!seen.includes(repository.id)) {
          seen.push(repository.id);
          localStorage.setItem(LOCAL_STORAGE_KEY_DOC_BANNER, JSON.stringify(seen));
        }
      } else {
        localStorage.setItem(LOCAL_STORAGE_KEY_DOC_BANNER, JSON.stringify([repository.id]));
      }
    }
  };

  /**
   * Called when the banner is closed.
   */
  const onClose = (): void => {
    setOpen(false);
    setSeen();
  };

  return open ? (
    <Banner
      onClose={onClose}
      hasAlertIcon
      data-test-id="banner"
      className="DOCBanner"
    >
      <div className="banner-text-container">
        <span className="banner-text">
          {t(
            'DOCBanner.message',
            'Since this is your first time viewing this DOC protected repository, business policy asks that you take a few moments and review any documents stored in the TCP folder.',
          )}
        </span>
      </div>
    </Banner>
  ) : null;
}

export default DOCBanner;
