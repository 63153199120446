/** @module store/repositoryItems */
import { AnyAction } from 'redux';
import lodash from 'lodash';
import { RepositoriesActionType } from 'store/repositories/types';
import { sortAlphabetical } from 'utilities/sort';
import { ItemDetails } from 'services/metadata';
import { CollaboratorsActionType } from 'store/collaborators/types';
import {
  RepositoryItemsActionType,
  State,
} from './types';

export const DEFAULT_STATE: State = {
  byId: {},
  allIds: [],
  selected: [],
  previewUrl: 'about:blank',
  uploadUrl: '',
  trash: {},
};

function reducer(state = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case RepositoryItemsActionType.REPOSITORY_ITEMS_GET_SUCCESS: {
      // id is optional param used for handling unauthorized access (403 error)
      const { item, id } = action.payload;
      const { details } = item;
      if (details) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [details.id]: {
              ...item,
              lastUpdated: new Date(),
            },
          },
          allIds: [
            ...state.allIds,
            details.id,
          ],
        };
      }
      // for unauthorized access
      if (id) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [id]: {
              ...item,
              lastUpdated: new Date(),
            },
          },
          allIds: [
            ...state.allIds,
            id,
          ],
        };
      }
      return state;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_SET_SELECTED: {
      const { itemDetails } = action.payload;
      return {
        ...state,
        selected: itemDetails,
      };
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_REMOVE_SELECTED: {
      const { itemDetails } = action.payload;
      return {
        ...state,
        selected: state.selected.filter((s) => s !== itemDetails),
      };
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_CLEAR_SELECTION: {
      return {
        ...state,
        selected: [],
      };
    }
    case RepositoriesActionType.REPOSITORY_UPDATE_SUCCESS: {
      const {
        id,
        name,
      } = action.payload;
      const newState = lodash.cloneDeep(state);
      const folder = newState.byId[id];
      if (folder && folder.details) {
        folder.details.name = name;
        folder.details.updatedOn = new Date();
      }
      return newState;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_UPDATE_SUCCESS: {
      const {
        id,
        oldParentId,
        newParentId,
        name,
      } = action.payload;
      const newState = lodash.cloneDeep(state);
      if (oldParentId !== newParentId) {
        /* The operation is a move */
        // Remove the item from the selected items
        newState.selected = newState.selected.filter((s) => (s.id !== id));

        // Move the folder
        const folder = newState.byId[id];
        if (folder && folder.details) {
          folder.details.parentId = newParentId;
          folder.details.updatedOn = new Date();
        }

        // Move the child of the parent folder
        const oldParent = newState.byId[oldParentId];
        const newParent = newState.byId[newParentId];
        if (oldParent && oldParent.children) {
          // Remove the child from the old parent
          const child = oldParent.children.find((c: ItemDetails) => c.id === id);
          oldParent.children = oldParent.children.filter((c: ItemDetails) => c.id !== id);
          if (child && newParent && newParent.children) {
            // Add the child to the new parent
            child.parentId = newParentId;
            child.updatedOn = new Date();
            newParent.children.push(child);
            // Sort the children like the back-end does
            newParent.children = newParent.children.sort(
              (a, b) => sortAlphabetical(a.name, b.name),
            );
          }
        }

        // Invalidate the cache for ancestor folders
        Object.values(newState.byId).forEach((f) => {
          if (f.ancestors && f.details) {
            const isAncestor = (f.ancestors.find((a) => a.id === id) !== undefined);
            if (isAncestor) {
              delete newState.byId[f.details.id];
            }
          }
        });
      } else {
        /* The operation is a rename */
        // Rename the folder
        const folder = newState.byId[id];
        if (folder && folder.details) {
          folder.details.name = name;
          folder.details.updatedOn = new Date();
        }

        // Rename child of parent folder
        const parent = newState.byId[newParentId];
        if (parent && parent.children) {
          const child = parent.children.find((c: ItemDetails) => c.id === id);
          if (child) {
            child.name = name;
            child.updatedOn = new Date();
          }
        }

        // Rename folder ancestors
        Object.values(newState.byId).forEach((item) => {
          if (item.ancestors) {
            const ancestor = item.ancestors.find((a) => a.id === id);
            if (ancestor) {
              ancestor.name = name;
            }
          }
        });
      }
      newState.allIds = Object.keys(state.byId);
      return newState;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SUCCESS: {
      const {
        itemDetails,
        newParentId,
      } = action.payload;
      const newState = lodash.cloneDeep(state);
      // Remove the item from the repository trash
      newState.trash[itemDetails.repoId] = newState.trash[itemDetails.repoId].filter(
        (item) => item.details && (item.details.id !== itemDetails.id),
      );
      // Add it to the new location
      const newParent = newState.byId[newParentId];
      if (newParent && newParent.children) {
        newParent.children.push({
          ...itemDetails,
          updatedOn: new Date(),
          parentId: newParentId,
        });
        newParent.children = newParent.children.sort((a, b) => sortAlphabetical(a.name, b.name));
      }
      return newState;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SUCCESS: {
      const { itemDetails } = action.payload;
      const { byId } = state;
      // Remove the folder if the item deleted was a folder
      if (byId[itemDetails.id]) {
        delete byId[itemDetails.id];
      }
      // Remove the item from the parent folder
      const parent = byId[itemDetails.parentId];
      if (parent && parent.children) {
        const children = parent.children.filter((child) => child.id !== itemDetails.id);
        return {
          ...state,
          selected: state.selected.filter((s) => (s.id !== itemDetails.id)),
          byId: {
            ...byId,
            [itemDetails.parentId]: {
              ...byId[itemDetails.parentId],
              children,
            },
          },
          allIds: Object.keys(byId),
        };
        // TODO: Add the item to the repository trash
      }
      return {
        ...state,
        selected: state.selected.filter((s) => (s.id !== itemDetails.id)),
        byId,
        allIds: Object.keys(byId),
      };
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_PREVIEW_GET_REQUEST: {
      return {
        ...state,
        previewUrl: 'about:blank',
      };
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_PREVIEW_GET_SUCCESS: {
      return {
        ...state,
        previewUrl: action.payload.url,
      };
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_TRASH_GET_SUCCESS: {
      const { repositoryId, items } = action.payload;
      return {
        ...state,
        trash: {
          ...state.trash,
          [repositoryId]: items,
        },
      };
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_CREATE_SUCCESS: {
      const { itemDetails, parentId } = action.payload;
      const newState = lodash.cloneDeep(state);
      const parent = newState.byId[parentId];
      if (parent && parent.children) {
        parent.children.push(itemDetails);
        parent.children = parent.children.sort((a, b) => sortAlphabetical(a.name, b.name));
      }
      return newState;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_UPLOAD_URL_GET_SUCCESS: {
      const { url } = action.payload;
      return {
        ...state,
        uploadUrl: url,
      };
    }
    case CollaboratorsActionType.COLLABORATORS_REMOVE_SUCCESS: {
      const {
        repositoryId,
        isSelf,
      } = action.payload;
      const { byId } = state;
      if (isSelf) {
        if (byId[repositoryId]) {
          delete byId[repositoryId];
        }
        return {
          ...state,
          byId: { ...byId },
          allIds: Object.keys(byId),
        };
      }
      return state;
    }
    case RepositoriesActionType.REPOSITORY_RESTORE_SUCCESS: {
      const {
        id,
      } = action.payload;
      const { byId } = state;
      if (byId[id]) {
        delete byId[id];
      }
      return {
        ...state,
        byId: { ...byId },
        allIds: Object.keys(byId),
      };
    }
    default:
      return state;
  }
}

export default reducer;
