/** @module components/AddCollaboratorsSection */
import { ExternalCollaborator } from 'services/invitations';
import {
  Collaborator,
  CollaboratorIdentity,
  Repository,
  User,
} from 'services/metadata';

/**
 * The status of a collaborator search.
 *
 * REGISTERED = The collaborator exists and is registered
 * NEWGE = The collaborator is not registered but has a GE email
 * WHITELISTED = The collaborator is not registered but can be added
 * NOTWHITELISTED = The collaborator is not registered and cannot be added
 * UNDEFINED = The collaborator status has not been discovered yet
 * NOTFOUND
 * UNREGISTERED_SSO = The input data has SSO format, but is not registered
 */
export enum CollaboratorStatus {
  REGISTERED,
  NEWGE,
  WHITELISTED,
  NOTWHITELISTED,
  UNDEFINED,
  NOTFOUND,
  UNREGISTERED_SSO
}

export interface State {
  searchInput: string;
  searched: boolean;
}

export interface OwnProps {
  repository: Repository;
  canGetCollaborators: boolean;
  canViewCollab: boolean;
}

export interface StateProps {
  loading: boolean;
  searchLoading: boolean;
  partners: Array<string>;
  collaborators?: Collaborator[];
  users: User[];
  addCollaboratorError: boolean;
  inviteCollaboratorError: boolean;
}

export interface DispatchProps {
  searchUsers: (searchTerm: string) => void;
  addCollaborator: (
    collaboratorIdentity: CollaboratorIdentity,
    repository: Repository,
    canGetCollaborators: boolean
  ) => void;
  inviteCollaborator: (externalCollaborator: ExternalCollaborator) => void;
  getPartners: () => void;
  preregisterCollaborator: (
    repository: Repository, role: string, email: string, displayName?: string
    ) => void;
}

export type Props = OwnProps & StateProps & DispatchProps;
