/** @module components/BulkItemActionsBanner */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { selectRepository } from 'store/repositories/selectors';
import {
  downloadSelectedRepositoryItems,
  deleteSelectedRepositoryItems,
  clearAllSelectedRepositoryItem,
} from 'store/repositoryItems/actions';
import BulkItemActionsBanner from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => {
  const { selected } = state.items;
  let repository;
  if (selected.length > 0) {
    repository = selectRepository(state.repositories, selected[0].repoId);
  }
  return {
    selected: state.items.selected,
    canDownload: repository ? repository.actions.download_files : false,
    canDelete: repository ? repository.actions.delete_files : false,
    canMove: repository ? repository.actions.move_file : false,
    repositoryId: repository ? repository.id : undefined,
  };
};

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  downloadSelected: (): void => { dispatch(downloadSelectedRepositoryItems()); },
  deleteSelected: (): void => { dispatch(deleteSelectedRepositoryItems()); },
  clearSelection: (): void => { dispatch(clearAllSelectedRepositoryItem()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkItemActionsBanner);
