/** @module components/UserSearchForm */
import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Props, SizeType } from './types';

/**
 * A search form that allows admins to search for other users.
 * @param props The props
 * @return A JSX Element
 */
function UserSearchForm(props: Props): JSX.Element {
  const {
    searchUsers,
    placeholder,
    initialValue,
    loading = false,
    size = SizeType.LARGE,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Form
      form={form}
      onFinish={(values): void => searchUsers(values.query)}
      data-test-id="form"
    >
      <Form.Item
        name="query"
        rules={[{
          required: true,
          message: t('UserSearchForm.rule.queryRequired', 'Please enter an email or SSO'),
        }]}
        initialValue={initialValue}
      >
        <Input.Search
          className={loading ? 'loading-input' : ''}
          onSearch={(): void => form.submit()}
          onKeyDown={(e): void => onPressEnter(e)}
          size={size}
          enterButton
          data-test-id="query"
          loading={loading}
          placeholder={placeholder || t('UserSearchForm.placeholder', 'Search by Email or SSO')}
        />
      </Form.Item>
    </Form>
  );
}

export default UserSearchForm;
