/** @module components/UserSearchForm */
export interface Props {
  searchUsers: (query: string) => void;
  placeholder?: string;
  initialValue?: string;
  loading?: boolean;
  size?: SizeType;
}

export enum SizeType {
  SMALL= 'small',
  MEDIUM='middle',
  LARGE= 'large'
}
