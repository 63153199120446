/** @module components/ManageRepositoryForm */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { updateRepository } from 'store/repositories/actions';
import { selectLoading } from 'store/loading/selectors';
import { selectBusinesses } from 'store/businesses/selectors';
import { selectTags } from 'store/tags/selectors';
import { Tag } from 'services/metadata';
import { getCollaborators } from 'store/collaborators/actions';
import { createRepositoryTagsMapping, deleteRepositoryTagsMapping, getTags } from 'store/tags/actions';
import component from './component';
import {
  StateProps,
  DispatchProps,
} from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'REPOSITORIES_UPDATE')
    || selectLoading(state.loading, 'TAGS_CREATE')
    || selectLoading(state.loading, 'TAGS_GET')
    || selectLoading(state.loading, 'REPOSITORY_TAGS_MAPPING_CREATE')
    || selectLoading(state.loading, 'REPOSITORY_TAGS_MAPPING_DELETE'),
  tagsLoading: selectLoading(state.loading, 'TAGS_GET'),
  businesses: selectBusinesses(state.businesses),
  businessTags: selectTags(state.tags),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  updateRepository: (
    repoId: string,
    name: string,
    fullTextSearchEnabled: boolean,
    showNotification?: boolean,
  ): void => {
    dispatch(updateRepository(repoId, name, fullTextSearchEnabled, showNotification));
  },
  getBusinessTags: (businessId?: string): void => { dispatch(getTags(businessId)); },
  deleteRepositoryTagsMapping: (repoId: string, tags: Tag[], showNotification?: boolean): void => {
    dispatch(deleteRepositoryTagsMapping(repoId, tags, showNotification));
  },
  createRepositoryTagsMapping: (repoId: string, tags: Tag[], showNotification?: boolean): void => {
    dispatch(createRepositoryTagsMapping(repoId, tags, showNotification));
  },
  getCollaborators: (repoId: string): void => { dispatch(getCollaborators(repoId)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(component);
