/** @module utilities/test */
import { Collaborator } from 'services/metadata';
import mockUser from './mockUser';

const mockCollaborator: Collaborator = {
  id: 'collab1repo1',
  user: mockUser,
  status: 'ACCEPTED',
  expiration: '',
  role: 'VIEWER',
  createdOn: new Date('2023-05-29'),
};

export default mockCollaborator;
