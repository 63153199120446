/** @module store/partners */
import InvitationsService from 'services/invitations';
import { AppDispatch, GlobalState } from 'store/types';
import {
  PartnersActionType,
  PartnersGetRequestAction,
  PartnersGetSuccessAction,
  PartnersGetErrorAction,
} from './types';

export const getPartnersRequest = (): PartnersGetRequestAction => ({
  type: PartnersActionType.PARTNERS_GET_REQUEST,
});

export const getPartnersSuccess = (partners: string[]): PartnersGetSuccessAction => ({
  type: PartnersActionType.PARTNERS_GET_SUCCESS,
  partners,
});

export const getPartnersError = (error: Error): PartnersGetErrorAction => ({
  type: PartnersActionType.PARTNERS_GET_ERROR,
  payload: {
    error: error.message,
  },
});

export function getPartners() {
  return async (dispatch: AppDispatch, getState: () => GlobalState): Promise<void> => {
    const state = getState();
    if (state.partners.length === 0) {
      dispatch(getPartnersRequest());
      try {
        const partners = await new InvitationsService().getPartners();
        dispatch(getPartnersSuccess(partners));
      } catch (error) {
        dispatch(getPartnersError(error));
      }
    }
  };
}
