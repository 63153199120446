/** @module components/ManageTagsSection */

import { connect } from 'react-redux';
import { selectLoading } from 'store/loading/selectors';
import { createTag } from 'store/tags/actions';
import { AppDispatch, GlobalState } from 'store/types';
import ManageTagsSection from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  createTagLoading: selectLoading(state.loading, 'TAGS_CREATE'),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  createTag: (businessId: string, name: string): void => { dispatch(createTag(businessId, name)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageTagsSection);
