/** @module components/FileTable */
import React from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Table, TablePaginationConfig } from 'antd';
import { getHumanReadableFileSize } from 'utilities/file';
import { getAppRoutes } from 'utilities/routes';
import { ItemDetails } from 'services/metadata';
import { generatePaginationOptions } from 'utilities/antd';
import { ReactComponent as FileIcon } from 'assets/icons/document.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-lg.svg';
import { sortAlphabetical } from 'utilities/sort';
import NameField from '../NameField';
import PreviewFileModal from '../PreviewFileModal';
import RestoreFileModal from '../RestoreFileModal';
import ItemsMenu from '../ItemsMenu';
import SearchFilter from '../SearchFilter';
import { Props, State } from './types';
import './styles.scss';

class FileTable extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State): State {
    const {
      files,
      previewId,
    } = props;
    const { firstLoad } = state;
    if (firstLoad && files) {
      const previewItem = files.find((i) => i.id === previewId);
      return {
        firstLoad: false,
        modalOpen: (previewItem !== undefined),
        previewItem,
      };
    }
    return state;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      editingName: undefined,
      modalOpen: false,
      previewItem: undefined,
      firstLoad: true,
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const {
      setSelectedItems,
      files,
      selectedItems,
      pageNumber,
    } = this.props;

    if (files && prevProps.files) {
      const newFileIdsSet = new Set<string>();
      const prevFileIdsSet = new Set<string>();
      files.forEach((f) => newFileIdsSet.add(f.id));
      prevProps.files.forEach((f) => prevFileIdsSet.add(f.id));

      if (files.length !== prevProps.files.length) {
        const newSelectedItems = selectedItems.filter((item) => newFileIdsSet.has(item.id));
        // Deselect files that do not exist in the current directory
        setSelectedItems(newSelectedItems);
        this.onChangePagination({ current: pageNumber });
      } else {
        const difference = new Set(newFileIdsSet);
        prevFileIdsSet.forEach((file) => {
          if (difference.has(file)) {
            difference.delete(file);
          } else {
            difference.add(file);
          }
        });
        if (difference.size > 0) {
          // Deselect files that do not exist in the current directory
          const newSelectedItems = selectedItems.filter((item) => newFileIdsSet.has(item.id));
          setSelectedItems(newSelectedItems);
        }
      }
    }
  }

  componentWillUnmount(): void {
    const { setSelectedItems } = this.props;
    setSelectedItems([]);
  }

  onSelectChange(selectedRowKeys: React.ReactText[], selectedRows: object[]): void {
    const { setSelectedItems } = this.props;
    setSelectedItems(selectedRows);
  }

  onChangePagination(options?: TablePaginationConfig): void {
    const { onChangePage } = this.props;
    const page = (options && options.current) ? options.current : 1;
    onChangePage(page);
  }

  setEditName(item?: ItemDetails): void {
    this.setState({ editingName: item });
  }

  cancelEdit(): void {
    this.setState({ editingName: undefined });
  }

  confirmEdit(item: ItemDetails, name: string): void {
    const { updateName } = this.props;
    updateName(item.type, item.id, name, item.parentId);
    this.setState({ editingName: undefined });
  }

  updateModal(open: boolean, item?: ItemDetails): void {
    this.setState({
      modalOpen: open,
      previewItem: item,
    });
  }

  handleModalClose(): void {
    this.updateModal(false);
  }

  renderPreviewFileModal = (): JSX.Element | null => {
    const { previewItem, modalOpen } = this.state;
    const { repository } = this.props;
    if (previewItem) {
      return (
        <PreviewFileModal
          filePreviewName={previewItem.name}
          fileId={previewItem.id}
          visible={modalOpen}
          canDownload={repository.actions.download_files}
          handleClose={(): void => this.handleModalClose()}
          data-test-id="preview-modal"
        />
      );
    }
    return null;
  }

  render(): JSX.Element {
    const {
      t,
      files,
      repository,
      match,
      loading,
      selectedItems,
      trash,
      pageNumber,
      onChangePage,
    } = this.props;
    const {
      editingName,
    } = this.state;
    const rowSelection = {
      selectedRowKeys: selectedItems.map((item) => item.id),
      onChange: (
        selectedRowKeys: React.ReactText[],
        selectedRows: ItemDetails[],
      ): void => this.onSelectChange(selectedRowKeys, selectedRows),
    };
    const { Column } = Table;

    return (
      <>
        {this.renderPreviewFileModal()}
        <Table
          className="FileTable"
          rowSelection={rowSelection}
          pagination={generatePaginationOptions(
            files,
            false,
            undefined,
            pageNumber,
          )}
          onChange={(p): void => this.onChangePagination(p)}
          rowKey="id"
          size="middle"
          loading={loading}
          dataSource={files}
          data-test-id="table"
        >
          <Column
            title={t('FileTable.column.name', 'Name')}
            key="name"
            className="name-column"
            render={(item: ItemDetails): JSX.Element => (
              <NameField
                itemDetails={item}
                text={item.name}
                link={
                  !trash
                    && item.type === 'FOLDER'
                    ? getAppRoutes(match.params.basePath, match.params.repoId)
                      .goToFolder(item.id)
                    : undefined
                }
                icon={item.type === 'FILE' ? (
                  <FileIcon
                    width={10}
                    height={10}
                    className="icon icon-indicator file-icon"
                  />
                ) : (
                  <FolderIcon
                    width={10}
                    height={10}
                    className="icon icon-indicator folder-icon"
                  />
                )}
                editing={editingName === item}
                loading={loading}
                onConfirm={(name: string): void => this.confirmEdit(item, name)}
                onCancel={(): void => this.cancelEdit()}
                onClick={
                  !trash
                    && item.type === 'FILE'
                    ? (): void => {
                      this.updateModal(true, item); onChangePage(1);
                    }
                    : undefined
                }
              />
            )}
            sorter={(a: ItemDetails, b: ItemDetails): number => sortAlphabetical(a.name, b.name)}
            filterDropdown={({ setSelectedKeys, confirm, clearFilters }): JSX.Element => (
              <SearchFilter
                onSearch={(input: React.Key): void => {
                  if (setSelectedKeys) {
                    setSelectedKeys([input]);
                  }
                  if (confirm) {
                    confirm();
                  }
                }}
                onReset={(): void => {
                  if (clearFilters) {
                    clearFilters();
                  }
                }}
              />
            )}
            onFilter={(value, record): boolean => {
              if (typeof value === 'string') {
                return record.name.toLowerCase().includes(value.toLowerCase());
              }
              return false;
            }}
            data-test-id="column-name"
          />
          <Column
            title={t('FileTable.column.size', 'Size')}
            key="size"
            render={(item: ItemDetails): JSX.Element | null => {
              if (item.type.toLowerCase() !== 'file') {
                return null;
              }
              return <div>{getHumanReadableFileSize(item.size)}</div>;
            }}
            data-test-id="column-size"
          />
          <Column
            title={t('FileTable.column.createdOn', 'Created On')}
            key="createdOn"
            dataIndex="createdOn"
            render={(text: string): JSX.Element => (
              <span>{new Date(text).toLocaleString(navigator.language)}</span>
            )}
            data-test-id="column-created-on"
          />
          <Column
            title={trash ? t('FileTable.column.deletedOn', 'Deleted On') : t('FileTable.column.updatedOn', 'Updated On')}
            key="updatedOn"
            dataIndex="updatedOn"
            render={(text: string): JSX.Element => (
              <span>{new Date(text).toLocaleString(navigator.language)}</span>
            )}
            data-test-id="column-updated-on"
          />
          {
            !trash
            && (
              <Column
                title=""
                key="menu"
                render={(item: ItemDetails): JSX.Element => (
                  <ItemsMenu
                    parentId={item.parentId}
                    repositoryId={repository.id}
                    itemId={item.id}
                    onEditName={(): void => this.setEditName(item)}
                    onPreview={(): void => this.updateModal(true, item)}
                    data-test-id="menu"
                  />
                )}
                data-test-id="column-menu"
              />
            )
          }
          {
            trash
            && (
              <Column
                title={t('FileTable.column.restore', 'Restore')}
                key="restore"
                render={(item: ItemDetails): JSX.Element => (
                  <RestoreFileModal
                    repositoryId={repository.id}
                    file={item}
                    disabled={selectedItems.length > 0}
                  />
                )}
                data-test-id="column-restore"
              />
            )
          }
        </Table>
      </>
    );
  }
}

export { FileTable };
export default withTranslation()(withRouter(FileTable));
