/** @module components/IEBanner */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { setBannerClosed } from 'store/announcements/actions';
import { SetBannerClosedAction } from 'store/announcements/types';
import IEBanner from './component';
import { DispatchProps, StateProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  closedBannerIds: state.announcements.closedBannerIds,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  setBannerClosed: (bannerId: string): SetBannerClosedAction => dispatch(setBannerClosed(bannerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IEBanner);
