/** @module store/repositories */
import MetadataService, { MetadataErrorResponse, Repository } from 'services/metadata';
import { AppDispatch, GlobalState } from 'store/types';
import { cancelUploadOnDeleteRepository } from 'store/uploads/actions';
import { promiseAllSettled, wait } from 'utilities/asyncUtilities';
import { transformToMetadataErrorResponse } from 'utilities/standardizeErrors';
import { EvictionStatus } from 'services/metadata/types/EvictionStatus';
import { mockEvictionStatus } from 'utilities/test';
import {
  RepositoriesActionType,
  RepositoriesGetRequestAction,
  RepositoriesGetSuccessAction,
  RepositoriesGetErrorAction,
  RepositoryCreateRequestAction,
  RepositoryCreateSuccessAction,
  RepositoryCreateErrorAction,
  RepositoryUpdateRequestAction,
  RepositoryUpdateSuccessAction,
  RepositoryUpdateErrorAction,
  RepositoryDeleteRequestAction,
  RepositoryDeleteSuccessAction,
  RepositoryDeleteErrorAction,
  RepositoryDeleteSelectedRequestAction,
  RepositoryDeleteSelectedSuccessAction,
  RepositoryDeleteSelectedErrorAction,
  RepositoriesGetTrashRequestAction,
  RepositoriesGetTrashSuccessAction,
  RepositoriesGetTrashErrorAction,
  RepositoryRestoreSuccessAction,
  RepositoryRestoreErrorAction,
  RepositoryRestoreRequestAction,
  RepositoryRestoreSelectedSuccessAction,
  RepositoryRestoreSelectedErrorAction,
  RepositoryRestoreSelectedRequestAction,
  RepositoriesSetSelectedAction,
  RepositoryEvictRequestAction,
  RepositoryEvictSuccessAction,
  RepositoryEvictErrorAction,
  GetRepositoryEvictionStatusRequestAction,
  GetRepositoryEvictionStatusSuccessAction,
  GetRepositoryEvictionStatusErrorAction,
  GetMultiRepositoryEvictionStatusRequestAction,
  GetMultiRepositoryEvictionStatusSuccessAction,
  GetMultiRepositoryEvictionStatusErrorAction,
  RepositoriesSetSelectedToReassignAction,
} from './types';

export const getRepositoriesRequest = (): RepositoriesGetRequestAction => ({
  type: RepositoriesActionType.REPOSITORIES_GET_REQUEST,
});

export const getRepositoriesSuccess = (
  repositories: Repository[],
): RepositoriesGetSuccessAction => ({
  type: RepositoriesActionType.REPOSITORIES_GET_SUCCESS,
  payload: {
    repositories,
  },
});

export const getRepositoriesError = (error: Error): RepositoriesGetErrorAction => ({
  type: RepositoriesActionType.REPOSITORIES_GET_ERROR,
  payload: {
    error,
  },
});

export const updateRepositoryRequest = (): RepositoryUpdateRequestAction => ({
  type: RepositoriesActionType.REPOSITORY_UPDATE_REQUEST,
});

export const updateRepositorySuccess = (
  id: string,
  name: string,
  fullTextSearch?: boolean,
  showNotification?: boolean,
): RepositoryUpdateSuccessAction => ({
  type: RepositoriesActionType.REPOSITORY_UPDATE_SUCCESS,
  payload: {
    id,
    name,
    fullTextSearch,
    showNotification,
  },
});

export const updateRepositoryError = (error: Error): RepositoryUpdateErrorAction => ({
  type: RepositoriesActionType.REPOSITORY_UPDATE_ERROR,
  payload: {
    error,
  },
});

export const evictRepositoryRequest = (): RepositoryEvictRequestAction => ({
  type: RepositoriesActionType.REPOSITORY_EVICT_REQUEST,
});

export const evictRepositorySuccess = (
  id: string,
  evicted: boolean,
): RepositoryEvictSuccessAction => ({
  type: RepositoriesActionType.REPOSITORY_EVICT_SUCCESS,
  payload: {
    id,
    evicted,
  },
});

export const evictRepositoryError = (
  error: MetadataErrorResponse,
): RepositoryEvictErrorAction => ({
  type: RepositoriesActionType.REPOSITORY_EVICT_ERROR,
  payload: {
    error,
  },
});

/**
 * Creates a request action to get the eviction status of a repository.
 * @return A get repository eviction status request action.
 */
export const getRepositoryEvictionStatusRequest = (): GetRepositoryEvictionStatusRequestAction => ({
  type: RepositoriesActionType.REPOSITORY_GET_EVICTION_STATUS_REQUEST,
});

/**
 * Creates a success action for getting the eviction status of a repository.
 * @param repoId - The ID of the repository.
 * @param status - The eviction status of the repository.
 * @return A get repository eviction status success action.
 */
export const getRepositoryEvictionStatusSuccess = (
  repoId: string,
  status: EvictionStatus,
  showNotification?: boolean,
): GetRepositoryEvictionStatusSuccessAction => ({
  type: RepositoriesActionType.REPOSITORY_GET_EVICTION_STATUS_SUCCESS,
  payload: {
    repoId,
    status,
    showNotification,
  },
});

/**
 * Creates an error action for getting the eviction status of a repository.
 * @param error - The error response received when trying to get the eviction status.
 * @return A get repository eviction status error action.
 */
export const getRepositoryEvictionStatusError = (
  error: MetadataErrorResponse,
): GetRepositoryEvictionStatusErrorAction => ({
  type: RepositoriesActionType.REPOSITORY_GET_EVICTION_STATUS_ERROR,
  payload: {
    error,
  },
});

/**
 * Creates a multiple get repository eviction status request action.
 * @return A multiple get repository eviction status request action.
 */
export const getMultiRepositoryEvictionStatusRequest = (
): GetMultiRepositoryEvictionStatusRequestAction => ({
  type: RepositoriesActionType.MULTI_REPOSITORY_GET_EVICTION_STATUS_REQUEST,
});

/**
 * Creates a multiple get repository eviction status success action.
 * @return A multiple get repository eviction status success action.
 */
export const getMultiRepositoryEvictionStatusSuccess = (
): GetMultiRepositoryEvictionStatusSuccessAction => ({
  type: RepositoriesActionType.MULTI_REPOSITORY_GET_EVICTION_STATUS_SUCCESS,
});

/**
 * Creates a multiple get repository eviction status error action.
 * @param error A metadata error response
 * @return A multiple get repository eviction status error action.
 */
export const getMultiRepositoryEvictionStatusError = (
  error: MetadataErrorResponse,
): GetMultiRepositoryEvictionStatusErrorAction => ({
  type: RepositoriesActionType.MULTI_REPOSITORY_GET_EVICTION_STATUS_ERROR,
  payload: { error },
});

export const restoreRepositoryRequest = (): RepositoryRestoreRequestAction => ({
  type: RepositoriesActionType.REPOSITORY_RESTORE_REQUEST,
});

export const restoreRepositorySuccess = (
  id: string,
  name: string,
  showNotification = true,
): RepositoryRestoreSuccessAction => ({
  type: RepositoriesActionType.REPOSITORY_RESTORE_SUCCESS,
  payload: {
    id,
    name,
    showNotification,
  },
});

export const restoreRepositoryError = (
  error: Error,
  showNotification = true,
): RepositoryRestoreErrorAction => ({
  type: RepositoriesActionType.REPOSITORY_RESTORE_ERROR,
  payload: {
    error,
    showNotification,
  },
});

export const restoreSelectedRepositoriesRequest = (): RepositoryRestoreSelectedRequestAction => ({
  type: RepositoriesActionType.REPOSITORY_RESTORE_SELECTED_REQUEST,
});

export const restoreSelectedRepositoriesSuccess = (
  repositories: Repository[],
): RepositoryRestoreSelectedSuccessAction => ({
  type: RepositoriesActionType.REPOSITORY_RESTORE_SELECTED_SUCCESS,
  payload: {
    repositories,
  },
});

export const restoreSelectedRepositoriesError = (
  errors: Error[],
  repositories: Repository[],
): RepositoryRestoreSelectedErrorAction => ({
  type: RepositoriesActionType.REPOSITORY_RESTORE_SELECTED_ERROR,
  payload: {
    errors,
    repositories,
  },
});

export const createRepositoryRequest = (): RepositoryCreateRequestAction => ({
  type: RepositoriesActionType.REPOSITORY_CREATE_REQUEST,
});

export const createRepositorySuccess = (
  response: { id: string; name: string },
): RepositoryCreateSuccessAction => ({
  type: RepositoriesActionType.REPOSITORY_CREATE_SUCCESS,
  payload: {
    response,
  },
});

export const createRepositoryError = (error: Error): RepositoryCreateErrorAction => ({
  payload: {
    error,
  },
  type: RepositoriesActionType.REPOSITORY_CREATE_ERROR,
});

export const deleteRepositoryRequest = (): RepositoryDeleteRequestAction => ({
  type: RepositoriesActionType.REPOSITORY_DELETE_REQUEST,
});

export const deleteRepositorySuccess = (
  repository: Repository,
  showNotification = true,
): RepositoryDeleteSuccessAction => ({
  payload: {
    repository,
    showNotification,
  },
  type: RepositoriesActionType.REPOSITORY_DELETE_SUCCESS,
});

export const deleteRepositoryError = (
  error: Error,
  showNotification = true,
): RepositoryDeleteErrorAction => ({
  payload: {
    error,
    showNotification,
  },
  type: RepositoriesActionType.REPOSITORY_DELETE_ERROR,
});

export const deleteSelectedRepositoriesRequest = (): RepositoryDeleteSelectedRequestAction => ({
  type: RepositoriesActionType.REPOSITORY_DELETE_SELECTED_REQUEST,
});

export const deleteSelectedRepositoriesSuccess = (
  repositories: Repository[],
): RepositoryDeleteSelectedSuccessAction => ({
  payload: {
    repositories,
  },
  type: RepositoriesActionType.REPOSITORY_DELETE_SELECTED_SUCCESS,
});

export const deleteSelectedRepositoriesError = (
  errors: Error[],
  repositories: Repository[],
): RepositoryDeleteSelectedErrorAction => ({
  payload: {
    errors,
    repositories,
  },
  type: RepositoriesActionType.REPOSITORY_DELETE_SELECTED_ERROR,
});

export const getRepositoriesTrashRequest = (): RepositoriesGetTrashRequestAction => ({
  type: RepositoriesActionType.REPOSITORIES_TRASH_GET_REQUEST,
});

export const getRepositoriesTrashSuccess = (
  repositories: Repository[],
): RepositoriesGetTrashSuccessAction => ({
  type: RepositoriesActionType.REPOSITORIES_TRASH_GET_SUCCESS,
  payload: {
    repositories,
  },
});

export const setSelectedRepositories = (
  repositories: Repository[],
): RepositoriesSetSelectedAction => ({
  type: RepositoriesActionType.REPOSITORIES_SET_SELECTED,
  payload: {
    repositories,
  },
});

export const setSelectedRepositoriesToReassign = (
  repositories: Repository[],
): RepositoriesSetSelectedToReassignAction => ({
  type: RepositoriesActionType.REPOSITORIES_SET_SELECTED_TO_REASSIGN,
  payload: {
    repositories,
  },
});

export const getRepositoriesTrashError = (error: Error): RepositoriesGetTrashErrorAction => ({
  type: RepositoriesActionType.REPOSITORIES_TRASH_GET_ERROR,
  payload: {
    error,
  },
});

export function getRepositories() {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getRepositoriesRequest());
    try {
      const repositories = await new MetadataService().getRepositories();
      dispatch(getRepositoriesSuccess(repositories));
    } catch (error) {
      dispatch(getRepositoriesError(error));
    }
  };
}

export function createRepository(
  name: string,
  fedRamp: number,
  isCdi: boolean,
  isEc: boolean,
  parentBusiness: string,
  isUsOnly: boolean,
  isUkLicense: boolean,
  isLicenseAvailable: boolean,
  isUsCitizen: boolean,
  isDoc: boolean,
  tags: string[],
  fullTextSearch: boolean,
  callback?: Function,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(createRepositoryRequest());
    try {
      const response = await new MetadataService().createRepository(
        name,
        fedRamp,
        isCdi,
        isEc,
        parentBusiness,
        isUsOnly,
        isUkLicense,
        isLicenseAvailable,
        isUsCitizen,
        isDoc,
        tags,
        fullTextSearch,
      );
      await wait(5000);
      dispatch(createRepositorySuccess(response));
      if (callback) callback(response.id);
      dispatch(getRepositories());
    } catch (error) {
      dispatch(createRepositoryError(error));
    }
  };
}

/**
 * A thunk that updates name and full text search option for the given repository.
 * @param id Repository id
 * @param name Repository name
 * @param fullTextSearchEnabled Repository full text search option
 * @return A thunk action which returns a promise
 */
export function updateRepository(
  id: string,
  name: string,
  fullTextSearchEnabled?: boolean,
  showNotification?: boolean,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(updateRepositoryRequest());
    try {
      await new MetadataService().updateRepository(id, name, fullTextSearchEnabled);
      dispatch(updateRepositorySuccess(id, name, fullTextSearchEnabled, showNotification));
    } catch (error) {
      dispatch(updateRepositoryError(error as Error));
      throw error;
    }
  };
}

/**
 * A thunk that gets the eviction status for the given array of repository IDs.
 * @param repositoryIds A repository ID array
 * @return A thunk action which returns a promise
 */
export function getMultipleRepositoryEvictionStatus(
  repositoryIds: string[],
  showNotification?: boolean,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getMultiRepositoryEvictionStatusRequest());

    try {
      const promisesWithIds: { promise: Promise<EvictionStatus>; repoId: string }[] = [];
      repositoryIds.forEach((repoId) => {
        promisesWithIds.push({
          promise: new MetadataService().getRepoEvictionStatus(repoId),
          repoId,
        });
      });

      const queue: PromiseSettledResult<EvictionStatus>[] = await promiseAllSettled(
        promisesWithIds.map((p) => p.promise),
      );

      queue.forEach((result, index) => {
        const currentRepoId = promisesWithIds[index].repoId;

        if (result.status === 'fulfilled') {
          dispatch(getRepositoryEvictionStatusSuccess(
            currentRepoId,
            result.value,
            showNotification,
          ));
        } else {
          dispatch(
            getRepositoryEvictionStatusError(transformToMetadataErrorResponse(result.reason)),
          );
        }
      });
      dispatch(getMultiRepositoryEvictionStatusSuccess());
    } catch (error) {
      dispatch(getMultiRepositoryEvictionStatusError(transformToMetadataErrorResponse(error)));
    }
  };
}

/**
 * A thunk that attempts to evict multiple repositories.
 * If the eviction is successful for any, it retrieves the eviction status of those repositories.
 * @param repositoryIds - An array of repository IDs to evict.
 * @param evicted - A flag that indicates the desired eviction status for the repositories.
 * @return A thunk action which returns a promise.
 */
export function evictMultipleRepositories(
  repositoryIds: string[],
  evicted: boolean,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    const promisesWithIds: { promise: Promise<Response>; repoId: string }[] = [];
    repositoryIds.forEach((repoId) => {
      dispatch(evictRepositoryRequest());
      promisesWithIds.push({
        promise: new MetadataService().evictRepository(repoId, evicted),
        repoId,
      });
    });
    const queue: PromiseSettledResult<Response>[] = await promiseAllSettled(
      promisesWithIds.map((p) => p.promise),
    );
    const successfullyEvictedIds: string[] = [];
    queue.forEach((result, index) => {
      const currentRepoId = promisesWithIds[index].repoId;
      if (result.status === 'fulfilled') {
        successfullyEvictedIds.push(currentRepoId);
        dispatch(evictRepositorySuccess(currentRepoId, evicted));
      } else {
        dispatch(
          evictRepositoryError(transformToMetadataErrorResponse(result.reason)),
        );
      }
    });
    if (successfullyEvictedIds.length > 0) {
      successfullyEvictedIds.forEach((successfullyEvictedId) => {
        dispatch(getRepositoryEvictionStatusSuccess(successfullyEvictedId, mockEvictionStatus));
      });
    }
  };
}

/**
 * A thunk that restores the given repository from trash.
 * @param id Repository id
 * @param name Repository name
 * @return A thunk action which returns a promise
 */
export function restoreRepository(id: string, name: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(restoreRepositoryRequest());
    try {
      await new MetadataService().updateRepository(id, name);
      dispatch(restoreRepositorySuccess(id, name));
    } catch (error) {
      dispatch(restoreRepositoryError(error));
    }
  };
}

export function restoreSelectedRepositories() {
  return async (dispatch: AppDispatch, getState: () => GlobalState): Promise<void> => {
    const { selected } = getState().repositories;
    const metadataService = new MetadataService();
    const promises: Promise<void>[] = [];
    const successfulRepos: Repository[] = [];
    const failedRepos: Repository[] = [];
    const errors: Error[] = [];
    dispatch(restoreSelectedRepositoriesRequest());
    selected.forEach((repository: Repository) => {
      dispatch(restoreRepositoryRequest());
      promises.push(
        metadataService.updateRepository(repository.id, repository.name)
          .then(() => {
            dispatch(restoreRepositorySuccess(repository.id, repository.name, false));
            successfulRepos.push(repository);
          })
          .catch((error: Error) => {
            dispatch(restoreRepositoryError(error, false));
            failedRepos.push(repository);
            errors.push(error);
          }),
      );
    });
    return Promise.all(promises).then(() => {
      if (failedRepos.length === 0) {
        dispatch(restoreSelectedRepositoriesSuccess(selected));
      } else {
        dispatch(restoreSelectedRepositoriesError(errors, selected));
      }
      dispatch(setSelectedRepositories(failedRepos));
    });
  };
}

export function deleteRepository(repository: Repository, callback?: Function) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(deleteRepositoryRequest());
    await dispatch(cancelUploadOnDeleteRepository(repository.id));
    try {
      await new MetadataService().deleteRepository(repository.id);
      if (callback) callback();
      dispatch(deleteRepositorySuccess(repository));
    } catch (error) {
      dispatch(deleteRepositoryError(error));
    }
  };
}

export function deleteSelectedRepositories() {
  return async (dispatch: AppDispatch, getState: () => GlobalState): Promise<void> => {
    const { selected } = getState().repositories;
    const promises: Promise<void>[] = [];
    const successfulRepos: Repository[] = [];
    const failedRepos: Repository[] = [];
    const errors: Error[] = [];
    dispatch(deleteSelectedRepositoriesRequest());
    selected.forEach((repository: Repository) => {
      promises.push((async (): Promise<void> => {
        dispatch(deleteRepositoryRequest());
        await dispatch(cancelUploadOnDeleteRepository(repository.id));
        try {
          await new MetadataService().deleteRepository(repository.id);
          dispatch(deleteRepositorySuccess(repository, false));
          successfulRepos.push(repository);
        } catch (error) {
          dispatch(deleteRepositoryError(error, false));
          failedRepos.push(repository);
          errors.push(error);
        }
      })());
    });
    await Promise.all(promises);
    if (failedRepos.length === 0) {
      dispatch(deleteSelectedRepositoriesSuccess(selected));
    } else {
      dispatch(deleteSelectedRepositoriesError(errors, selected));
    }
    dispatch(setSelectedRepositories(failedRepos));
  };
}

export function getRepositoriesTrash() {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getRepositoriesTrashRequest());
    try {
      const repositories = await new MetadataService().getRepositoriesTrash();
      dispatch(getRepositoriesTrashSuccess(repositories));
    } catch (error) {
      dispatch(getRepositoriesTrashError(error));
    }
  };
}
