/** @module components/AddUnregisteredGECollabForm */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  Button,
  Form,
} from 'antd';
import {
  Props,
  FormData,
} from './types';
import './styles.scss';

function AddUnregisteredGECollabForm(props: Props): JSX.Element {
  const {
    onSubmit,
    userEmail,
    loading,
  } = props;
  const { t } = useTranslation();

  const handleFormSubmit = (values: FormData): void => {
    onSubmit(values.role, userEmail);
  };

  return (
    <Form
      className="AddUnregisteredGECollabForm"
      data-test-id="form"
      onFinish={handleFormSubmit}
    >
      <div data-test-id="user-email" className="user-email">{userEmail}</div>
      <Form.Item
        name="role"
        rules={[{
          required: true,
          message: t('AddUnregisteredGECollabForm.formItem.access.message', 'Please select access.'),
        }]}
        colon={false}
        className="role-select"
      >
        <Select
          placeholder={t('AddUnregisteredGECollabForm.formItem.access.placeholder', 'Select Access')}
          style={{ width: 164 }}
          data-test-id="role-select"
        >
          <Select.Option value="EDITOR">
            {t('SelectRoles.roles.editor', 'Editor').toUpperCase()}
          </Select.Option>
          <Select.Option value="VIEWER">
            {t('SelectRoles.roles.viewer', 'Viewer').toUpperCase()}
          </Select.Option>
        </Select>

      </Form.Item>

      <Form.Item className="ant-form-item--last-item ant-form-item--form-buttons">
        <Button
          htmlType="submit"
          loading={loading}
          className="ant-btn-primary add-button"
          data-test-id="add-button"
        >
          {t('AddUnregisteredGECollabForm.button', 'ADD')}
        </Button>
      </Form.Item>

    </Form>
  );
}

export default AddUnregisteredGECollabForm;
