/** @module store/uploads */
import { Action } from 'redux';
import { UploadFile, UploadFolder } from 'services/storage';

export type State = {
  files: {
    [key: string]: UploadFile;
  };
  folders: {
    [key: string]: UploadFolder;
  };
};

export enum UploadsActionType {
  UPLOADS_ENQUEUE_FILE = 'UPLOADS_ENQUEUE_FILE',
  UPLOADS_ENQUEUE_FILES = 'UPLOADS_ENQUEUE_FILES',
  UPLOADS_FILE_REQUEST = 'UPLOADS_FILE_REQUEST',
  UPLOADS_FILE_PROGRESS = 'UPLOADS_FILE_PROGRESS',
  UPLOADS_FILE_SUCCESS = 'UPLOADS_FILE_SUCCESS',
  UPLOADS_FILE_ERROR = 'UPLOADS_FILE_ERROR',
  UPLOADS_FILE_CANCEL = 'UPLOADS_FILE_CANCEL',
  UPLOADS_CLEAR = 'UPLOADS_CLEAR',
  UPLOADS_ENQUEUE_FOLDER = 'UPLOADS_ENQUEUE_FOLDER',
  UPLOADS_FOLDER_REQUEST = 'UPLOADS_FOLDER_REQUEST',
  UPLOADS_FOLDER_SUCCESS = 'UPLOADS_FOLDER_SUCCESS',
  UPLOADS_FOLDER_ERROR = 'UPLOADS_FOLDER_ERROR',
  UPLOADS_FOLDER_CANCEL = 'UPLOADS_FOLDER_CANCEL',
  UPLOADS_FOLDER_PROGRESS = 'UPLOADS_FOLDER_PROGRESS',
  UPLOADS_NAME_VALIDATE_REQUEST = 'UPLOADS_NAME_VALIDATE_REQUEST',
  UPLOADS_NAME_VALIDATE_SUCCESS = 'UPLOADS_NAME_VALIDATE_SUCCESS',
  UPLOADS_NAME_VALIDATE_ERROR = 'UPLOADS_NAME_VALIDATE_ERROR'
}

export interface UploadsEnqueueFileAction extends Action<UploadsActionType.UPLOADS_ENQUEUE_FILE> {
  payload: {
    upload: UploadFile;
  };
}

export interface UploadsEnqueueFilesAction extends Action<UploadsActionType.UPLOADS_ENQUEUE_FILES> {
  payload: {
    uploads: UploadFile[];
  };
}

export interface UploadsFileRequestAction extends Action<UploadsActionType.UPLOADS_FILE_REQUEST> {
  payload: {
    id: string;
  };
}

export interface UploadsFileProgressAction extends Action<UploadsActionType.UPLOADS_FILE_PROGRESS> {
  payload: {
    id: string;
    progress: number;
  };
}

export interface UploadsFileSuccessAction extends Action<UploadsActionType.UPLOADS_FILE_SUCCESS> {
  payload: {
    id: string;
  };
}

export interface UploadsFileErrorAction extends Action<UploadsActionType.UPLOADS_FILE_ERROR> {
  payload: {
    id: string;
    error: Error;
  };
}

export interface UploadsFileCancelAction extends Action<UploadsActionType.UPLOADS_FILE_CANCEL> {
  payload: {
    id: string;
  };
}

export type UploadsClearAction = Action<UploadsActionType.UPLOADS_CLEAR>;

export interface UploadsEnqueueFolderAction extends Action<
UploadsActionType.UPLOADS_ENQUEUE_FOLDER
> {
  payload: {
    folder: UploadFolder;
  };
}

export interface UploadsFolderRequestAction extends Action<
UploadsActionType.UPLOADS_FOLDER_REQUEST
> {
  payload: {
    id: string;
  };
}

export interface UploadFolderSuccessAction extends Action<
UploadsActionType.UPLOADS_FOLDER_SUCCESS
> {
  payload: {
    folder: UploadFolder;
  };
}

export interface UploadsFolderErrorAction extends Action<UploadsActionType.UPLOADS_FOLDER_ERROR> {
  payload: {
    id: string;
    error: Error;
    folderName?: string;
  };
}

export interface UploadsFolderCancelAction extends Action<UploadsActionType.UPLOADS_FOLDER_CANCEL> {
  payload: {
    id: string;
  };
}

export interface UploadsFolderProgressAction extends Action<
UploadsActionType.UPLOADS_FOLDER_PROGRESS
> {
  payload: {
    id: string;
  };
}

export interface UploadsNameValidateRequestAction extends Action<
UploadsActionType.UPLOADS_NAME_VALIDATE_REQUEST
> {
  payload: {
    name: string;
  };
}

export interface UploadsNameValidateErrorAction extends Action<
UploadsActionType.UPLOADS_NAME_VALIDATE_ERROR
> {
  payload: {
    name: string;
  };
}

export type UploadsAction = UploadsFileRequestAction
                          | UploadsFileProgressAction
                          | UploadsFileSuccessAction
                          | UploadsFileErrorAction
                          | UploadsFileCancelAction
                          | UploadsClearAction
                          | UploadsFolderCancelAction
                          | UploadsFolderErrorAction
                          | UploadFolderSuccessAction
                          | UploadsFolderRequestAction
                          | UploadsEnqueueFolderAction
                          | UploadsFolderProgressAction
                          | UploadsNameValidateRequestAction
                          | UploadsNameValidateErrorAction;
