/** @module components/DeleteUserPage */
import { connect } from 'react-redux';
import { selectLoading } from 'store/loading/selectors';
import { AppDispatch, GlobalState } from 'store/types';
import {
  getUsers,
  blockSelectedUser,
  deleteSelectedUser,
  setSelectedUser,
  clearDeletedUser,
} from 'store/users/actions';
import { User } from 'services/metadata';
import { getUserRepositories } from 'store/user-repositories/actions';
import { selectUserRepositories } from 'store/user-repositories/selectors';
import DeleteUserPage from './component';
import {
  StateProps,
  DispatchProps,
  OwnProps,
} from './types';

export const mapStateToProps = (state: GlobalState, ownProps: OwnProps): StateProps => ({
  repositories: selectUserRepositories(state.userRepositories, ownProps.userId),
  loading: selectLoading(state.loading, 'USERS_REPOSITORIES_GET'),
  deleteUserLoading: selectLoading(state.loading, 'USERS_SELECTED_DELETE'),
  me: state.me,
  selectedUser: state.users.selectedUser,
  searchedUsers: state.users.searchedUsers,
  deletedUserId: state.users.deletedUserId,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  searchUsers: (query: string): void => { dispatch(getUsers(query)); },
  blockUser: (userId: string): void => { dispatch(blockSelectedUser(userId)); },
  deleteUser: (userId: string): void => { dispatch(deleteSelectedUser(userId)); },
  getUserRepositories: (userId: string): void => { dispatch(getUserRepositories(userId)); },
  setSelectedUser: (user: User): void => { dispatch(setSelectedUser(user)); },
  clearDeletedUser: (): void => { dispatch(clearDeletedUser()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteUserPage);
