/* eslint-disable @typescript-eslint/camelcase */
/** @module utilities/test */
import { Actions } from 'services/metadata';

const mockActions: Actions = {
  view_repository: true,
  update_repository: true,
  download_files: true,
  upload_files: true,
  delete_files: true,
  view_collaborators: true,
  add_collaborators: true,
  remove_collaborators: true,
  update_repository_owner: true,
  view_protections: true,
  create_protections: true,
  remove_protections: true,
  remove_self_from_repository: true,
  move_file: true,
  update_file_content: true,
  manage_repository_tags: true,
  view_snapshots: true,
  create_snapshots: true,
};

export default mockActions;
