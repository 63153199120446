/** @module services/search */
/* eslint-disable @typescript-eslint/camelcase */
import { Client } from './types/Client';
import { SearchResponse } from './types/SearchResponse';

/**
 * A client that communicates with the REDshare search service using the fetch api.
 */
class FetchClient implements Client {
  prefix: string;

  /**
   * Constructs a new client with the given API
   * level.
   *
   * @param apiLevel The API level this service uses
   */
  constructor(apiLevel = '1.0') {
    this.prefix = `/search-api/searchapi/${apiLevel}`;
  }

  /**
   * Searches for the given query with the given constraints.
   * @param query The query
   * @param pageNumber The page number
   * @param pageSize The results per page
   * @param userId The user's UUID
   * @param repoIds An array of repository IDs
   * @return A Promise containing a SearchResponse
   * @throws An Error if the response is not ok
   */
  async search(
    query: string,
    pageNumber: number,
    pageSize: number,
    userId: string,
    repoIds: string[],
    isSearchAcrossRepos = false,
    includeFileContent = false,
  ): Promise<SearchResponse> {
    const uri = `${this.prefix}/search?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const body = JSON.stringify({
      query,
      user_uuid: userId,
      repoIds,
      isSearchAcrossRepos,
      advancedFilters: {
        fullText: includeFileContent ? 'ON' : 'OFF',
      },
    });
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    const result = await fetch(uri, options);
    await FetchClient.handleError(result);
    const json = await result.json();
    return json;
  }

  /**
   * Records a click on a search result.
   * @param query The query
   * @param nodeId The ID of the node that was clicked on
   * @return A Promise containing a Response
   * @throws An Error if the response is not ok
   */
  async click(query: string, nodeId: string): Promise<Response> {
    const uri = `${this.prefix}/click`;
    const body = JSON.stringify({
      query,
      nodeId,
    });
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    const response = await fetch(uri, options);
    await FetchClient.handleError(response);
    return response;
  }

  /**
   * Throws an Error if the given response is not ok.
   *
   * @param response A Response
   * @throws An Error if the response is not ok
   */
  private static async handleError(response: Response): Promise<void> {
    if (!response.ok) {
      const contentType = response.headers.get('content-type');
      if (typeof contentType === 'string' && contentType.startsWith('application/json')) {
        const json = await response.json();
        if (json.message) {
          throw new Error(json.message);
        }
      }
      throw new Error(response.statusText);
    }
  }
}

export default FetchClient;
