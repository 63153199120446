/** @module components/ManageTagsSection */
import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Row,
  Tag,
} from 'antd';
import { tagsCharacterLimit } from 'utilities/constants';
import { Option } from 'components/DropdownWithCheckbox/types';
import { useTranslation } from 'react-i18next';
import DropdownWithCheckbox from 'components/DropdownWithCheckbox';
import { Props } from './types';
import './styles.scss';

export default function ManageTagsSection(props: Props): JSX.Element {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { t } = useTranslation();
  const {
    businessTags,
    createTag,
    createTagLoading,
    businessId,
    onChangeTag,
    initialValue,
    loading,
  } = props;

  useEffect(() => {
    if (initialValue) {
      setSelectedTags(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (initialValue) {
      setSelectedTags(initialValue);
    } else {
      setSelectedTags([]);
    }
  }, [businessId, initialValue]);

  // This is a temp implementation to push starred tags to the top of the dropdown
  // TODO: make this dynamic

  const allStarredTag = ['CUI/CDI', 'PHI', 'SPII', 'PII'];
  const starredTagFound: Option[] = [];
  let options: Option[] = [];

  if (businessTags) {
    businessTags.forEach((tag) => {
      if (allStarredTag.includes(tag.name)) {
        starredTagFound.push({ title: tag.name, value: tag.name });
      } else {
        options.push({ title: tag.name, value: tag.name });
      }
    });

    options = [...starredTagFound, ...options];
  }

  /**
   * Adds and removes the tag from selected tags list
   * and calls the callback function
   * @param tagName name of the tag
   * @param checked boolean which tell if the tag is added or removed
   */
  const onChange = (tagName: string, checked: boolean): void => {
    const selectedTagsSet = new Set(selectedTags);
    if (checked) {
      selectedTagsSet.add(tagName);
    } else {
      selectedTagsSet.delete(tagName);
    }
    const selectedTagsArr = Array.from(selectedTagsSet);
    setSelectedTags(selectedTagsArr);
    onChangeTag(selectedTagsArr);
  };

  /**
   * Creates new tag
   * @param newTag names of the new tag seperated by ','
   */
  const createNewTag = (newTag: string): void => {
    const tag = newTag.trim();
    if (tag.length > 0) {
      if (!options.find((option) => option.title === tag)) {
        createTag(businessId, tag);
      }
      const selectedTagsSet = new Set(selectedTags);
      selectedTagsSet.add(tag);
      const selectedTagsArr = Array.from(selectedTagsSet);
      setSelectedTags(selectedTagsArr);
      onChangeTag(selectedTagsArr);
    }
  };

  return (
    <div className="ManageTagsSection">
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label="Choose Tags"
            colon={false}
            hasFeedback
          >
            <DropdownWithCheckbox
              data-test-id="dropdown"
              options={options}
              hasAddButton
              selected={selectedTags}
              onAdd={(newTag: string): void => createNewTag(newTag)}
              onChange={(tagName: string, checked: boolean): void => onChange(tagName, checked)}
              addButtonText={t('ManageTagsSection.addTags', 'CREATE')}
              disabled={createTagLoading || loading}
              characterLimit={tagsCharacterLimit}
            />
          </Form.Item>
        </Col>
        <Col span={16}>

          <Form.Item
            label={t('ManageTagsSection.tagsApplied', 'Tags Applied')}
            colon={false}
            hasFeedback
          >
            <div className="tags" data-test-id="tags">
              {selectedTags.map((tag) => (
                <Tag
                  data-test-id="selected-tag"
                  className={`selected-tags ${createTagLoading || loading ? 'disabled' : ''}`}
                  closable
                  key={tag}
                  onClose={(): void => onChange(tag, false)}
                >
                  <div className="tag-text">{tag}</div>
                </Tag>
              ))}
            </div>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
