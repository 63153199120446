/** @module components/RepositoryCardGrid */
import React from 'react';
import { List } from 'antd';
import RepositoryCard from 'components/RepositoryCard/component';
import { generateListPaginationOptions } from 'utilities/antd';
import { Props } from './types';

function RepositoryCardGrid(props: Props): JSX.Element {
  const {
    repositories,
    loading,
    pageNumber,
    onChange,
  } = props;

  const handlePageChange = (page: number): void => {
    if (onChange) {
      onChange(page);
    }
  };

  return (
    <div className="RepositoryCardGrid">
      <List
        loading={loading}
        data-test-id="list"
        grid={{
          gutter: 24,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 4,
        }}
        pagination={generateListPaginationOptions(
          repositories,
          repositories.length <= 12,
          repositories.length,
          pageNumber,
          handlePageChange,
        )}
        dataSource={repositories}
        renderItem={(repo, index): JSX.Element => (
          <List.Item data-test-id="list-item">
            <RepositoryCard
              repository={repo}
              cardSize="small"
              cardIndex={index}
              showMenu
              expandable={false}
            />
          </List.Item>
        )}
      />
    </div>
  );
}

export default RepositoryCardGrid;
