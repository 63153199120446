/** @module components/ManageAdminRolesPage */
import React from 'react';
import { useTranslation } from 'react-i18next';
import DashboardPage from 'components/DashboardPage';
import './styles.scss';
import ManageAdminRolesForm from 'components/ManageAdminRolesForm';
import { appPaths } from 'utilities/routes';

function ManageAdminRolesPage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <DashboardPage
      title={t('ManageAdminRolesPage.title', 'Manage Admin Roles')}
      upLink={appPaths.admin}
    >
      <div className="ManageAdminRolesPage">
        <ManageAdminRolesForm />
      </div>
    </DashboardPage>
  );
}
export default ManageAdminRolesPage;
