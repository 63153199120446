/** @module components/UploadProgress */
import React from 'react';
import { Collapse, List, Tooltip } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useTranslation } from 'react-i18next';
import { UploadStatus } from 'services/storage';
import FileRow from './FileRow';
import FolderRow from './FolderRow';
import { Props } from './types';
import './styles.scss';

export const ITEM_SIZE = 56;
export const MAX_VISIBLE_ITEMS = 4;

/**
 * Displays a list of uploads and reports their progress.
 * @param props The props
 * @return A JSX Element or null
 */
function UploadProgress(props: Props): JSX.Element | null {
  const {
    fileUploads,
    folderUploads,
    cancelFileUpload,
    clearUploads,
    cancelFolderUpload,
  } = props;
  const { t } = useTranslation();

  if (fileUploads.length === 0 && folderUploads.length === 0) {
    return null;
  }

  const files = fileUploads.filter((file) => !file.folderId);
  const itemCount = folderUploads.length + files.length;

  return (
    <Collapse
      defaultActiveKey={['1']}
      className="UploadProgress"
      data-test-id="upload-progress"
    >
      <Collapse.Panel
        header={t('UploadProgress.title', 'Uploads')}
        key="1"
        extra={(
          <Tooltip title={t('UploadProgress.clear', 'Clear transfer log')}>
            <ClearOutlined
              onClick={(event): void => {
                event.stopPropagation();
                clearUploads();
              }}
              data-test-id="clear-uploads"
            />
          </Tooltip>
        )}
        data-test-id="upload-panel"
      >
        <List split={false}>
          <FixedSizeList
            width={382}
            height={itemCount < MAX_VISIBLE_ITEMS
              ? ITEM_SIZE * itemCount
              : ITEM_SIZE * MAX_VISIBLE_ITEMS}
            itemCount={itemCount}
            itemSize={ITEM_SIZE}
            className="upload-list"
            data-test-id="upload-list"
          >
            {({ index, style }: ListChildComponentProps): JSX.Element => (
              (index > folderUploads.length - 1) ? (
                <FileRow
                  file={files[index - folderUploads.length]}
                  cancelFileUpload={cancelFileUpload}
                  style={style}
                  data-test-id="file-row"
                />
              ) : (
                <FolderRow
                  folder={folderUploads[index]}
                  cancelFolderUpload={cancelFolderUpload}
                  failedFiles={fileUploads.filter((file) => (
                    file.folderId === folderUploads[index].id
                    && file.status === UploadStatus.ERROR
                  )).length}
                  style={style}
                  data-test-id="folder-row"
                />
              ))}
          </FixedSizeList>
        </List>
      </Collapse.Panel>
    </Collapse>
  );
}

export default UploadProgress;
