/** @module components/RegistrationSplashPage */
import { connect } from 'react-redux';
import { GlobalState, AppDispatch } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { selectError } from 'store/error/selectors';
import { registerMe } from 'store/me/actions';
import RegistrationSplashPage from './component';
import {
  DispatchProps,
  StateProps,
} from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'ME_REGISTER'),
  error: selectError(state.error, 'ME_REGISTER'),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  registerUser: (): void => { dispatch(registerMe()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationSplashPage);
