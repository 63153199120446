/** @module components/RepositoriesMenu */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  Menu,
} from 'antd';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import MoreButton from '../MoreButton';
import { Props } from './types';

function RepositoriesMenu(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { trashLink } = props;
  return (
    <Dropdown
      overlay={(
        <Menu>
          <Menu.Item key="view-trash">
            <Button type="link">
              <Link to={trashLink}>
                <DeleteIcon className="icon--primary" />
                {t('RepositoriesMenu.trash', 'View Trash')}
              </Link>
            </Button>
          </Menu.Item>
        </Menu>
      )}
      trigger={['click']}
      placement="bottomLeft"
    >
      <div>
        <MoreButton size="large" />
      </div>
    </Dropdown>
  );
}

export default RepositoriesMenu;
