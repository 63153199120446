/** @module store/businesses */
import MetadataService, { Business } from 'services/metadata';
import { AppDispatch } from 'store/types';
import {
  BusinessesActionType,
  BusinessesGetRequestAction,
  BusinessesGetSuccessAction,
  BusinessesGetErrorAction,
} from './types';

export const getBusinessesRequest = (): BusinessesGetRequestAction => ({
  type: BusinessesActionType.BUSINESSES_GET_REQUEST,
});

export const getBusinessesSuccess = (businesses: Business[]): BusinessesGetSuccessAction => ({
  type: BusinessesActionType.BUSINESSES_GET_SUCCESS,
  payload: {
    businesses,
  },
});

export const getBusinessesError = (error: Error): BusinessesGetErrorAction => ({
  type: BusinessesActionType.BUSINESSES_GET_ERROR,
  payload: {
    error,
  },
});

/**
 * Gets a list of businesses supported by REDshare.
 * @return A Promise
 */
export function getBusinesses() {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getBusinessesRequest());
    try {
      const businesses = await new MetadataService().getBusinesses();
      dispatch(getBusinessesSuccess(businesses));
    } catch (error) {
      dispatch(getBusinessesError(error));
    }
  };
}
