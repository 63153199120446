/** @module components/RepositoryWizard */
import React, { useState, useEffect } from 'react';
import { Props } from './types';
import CDIQuestionField from './fields/CDIQuestionField';

/**
 * Asks the user a series of questions to determine what kind of repository
 * should be created for GECAS.
 * @param props The props
 * @return A JSX Element
 */
function GECASRepositoryWizard(props: Props): JSX.Element {
  const { onChange } = props;
  const [isCdi, setIsCdi] = useState<boolean | undefined>(undefined);
  const [isUsOnly, setIsUsOnly] = useState<boolean | undefined>(undefined);

  /**
   * Updates the parent when a field is updated.
   */
  useEffect(() => {
    /**
     * Returns true if the wizard is complete.
     * @return true if the wizard is complete
     */
    const isComplete = (): boolean => {
      // Yes, no
      if (isCdi !== undefined) {
        return true;
      }
      return false;
    };

    onChange({
      fedRamp: 0,
      isCdi: isCdi || false,
      isEc: false,
      isLicenseAvailable: false,
      isUsOnly: isUsOnly || false,
      isUkLicense: false,
      isUsCitizen: false,
      isDoc: false,
    }, isComplete());
  }, [isCdi, isUsOnly, onChange]);

  return (
    <CDIQuestionField
      onChange={(value): void => {
        setIsCdi(value);
        setIsUsOnly(value);
      }}
      value={isCdi}
      data-test-id="cdi-question"
    />
  );
}

export default GECASRepositoryWizard;
