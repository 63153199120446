/** @module store/users */
import MetadataService, { User } from 'services/metadata';
import { AppDispatch } from '../types';
import {
  UsersActionType,
  UsersGetRequestAction,
  UsersGetSuccessAction,
  UsersGetErrorAction,
  UsersCheckExistingRequestAction,
  UsersCheckExistingSuccessAction,
  UsersCheckExistingErrorAction,
  UsersSelectedBlockRequestAction,
  UsersSelectedBlockSuccessAction,
  UsersSelectedBlockErrorAction,
  UsersSelectedUnblockRequestAction,
  UsersSelectedUnblockSuccessAction,
  UsersSelectedUnblockErrorAction,
  UsersSelectedDeleteRequestAction,
  UsersSelectedDeleteSuccessAction,
  UsersSelectedDeleteErrorAction,
  UsersSetSelectedAction,
  UsersDeletedClearStoreAction,
} from './types';

export const getUsersRequest = (): UsersGetRequestAction => ({
  type: UsersActionType.USERS_GET_REQUEST,
});

export const getUsersSuccess = (users: User[]): UsersGetSuccessAction => ({
  type: UsersActionType.USERS_GET_SUCCESS,
  payload: {
    users,
  },
});

export const getUsersError = (error: Error): UsersGetErrorAction => ({
  type: UsersActionType.USERS_GET_ERROR,
  payload: {
    error,
  },
});

/**
 * Creates a check existing user request action.
 * @return A check existing user request action.
 */
export const checkExistingUserRequest = (): UsersCheckExistingRequestAction => ({
  type: UsersActionType.USERS_CHECK_EXISTING_REQUEST,
});

/**
 * Creates a check existing user success action.
 * @return A check existing user success action.
 */
export const checkExistingUserSuccess = (exists: boolean): UsersCheckExistingSuccessAction => ({
  type: UsersActionType.USERS_CHECK_EXISTING_SUCCESS,
  payload: {
    exists,
  },
});

/**
 * Creates a check existing user error action.
 * @param error An error
 * @return A check existing user error action.
 */
export const checkExistingUserError = (error: Error): UsersCheckExistingErrorAction => ({
  type: UsersActionType.USERS_CHECK_EXISTING_ERROR,
  payload: {
    error,
  },
});

/** Creates a block user request action.
 * @return A block user request action.
 */
export const blockSelectedUserRequest = (): UsersSelectedBlockRequestAction => ({
  type: UsersActionType.USERS_SELECTED_BLOCK_REQUEST,
});

/**
 * Creates a block user success action.
 * @param userId A user's id
 * @return A block user success action.
 */
export const blockSelectedUserSuccess = (userId: string): UsersSelectedBlockSuccessAction => ({
  type: UsersActionType.USERS_SELECTED_BLOCK_SUCCESS,
  payload: {
    userId,
  },
});

/** Creates a block user error action
 * @param error An error
 * @return A block user error action.
 */
export const blockSelectedUserError = (error: Error): UsersSelectedBlockErrorAction => ({
  type: UsersActionType.USERS_SELECTED_BLOCK_ERROR,
  payload: {
    error,
  },
});

/**
 * Creates an unblock user request action.
 * @return An unblock user request action.
 */
export const unblockSelectedUserRequest = (): UsersSelectedUnblockRequestAction => ({
  type: UsersActionType.USERS_SELECTED_UNBLOCK_REQUEST,
});


/**
 * Creates an unblock user success action.
 * @param userId a user's id
 * @return an unblock user success action.
 */
export const unblockSelectedUserSuccess = (userId: string): UsersSelectedUnblockSuccessAction => ({
  type: UsersActionType.USERS_SELECTED_UNBLOCK_SUCCESS,
  payload: {
    userId,
  },
});

/**
 * Creates an unblock user error action.
 * @param error An error
 * @return An unblock user error action.
 */
export const unblockSelectedUserError = (error: Error): UsersSelectedUnblockErrorAction => ({
  type: UsersActionType.USERS_SELECTED_UNBLOCK_ERROR,
  payload: {
    error,
  },
});

/**
 * Creates a delete user request action.
 * @return A delete user request action.
 */
export const deleteSelectedUserRequest = (): UsersSelectedDeleteRequestAction => ({
  type: UsersActionType.USERS_SELECTED_DELETE_REQUEST,
});

/**
 * Creates a delete user success action.
 * @param userId a user's id
 * @return a delete user success action.
 */
export const deleteSelectedUserSuccess = (userId: string): UsersSelectedDeleteSuccessAction => ({
  type: UsersActionType.USERS_SELECTED_DELETE_SUCCESS,
  payload: {
    userId,
  },
});

/**
 * Creates a delete user error action.
 * @param error An error
 * @return A delete user error action.
 */
export const deleteSelectedUserError = (error: Error): UsersSelectedDeleteErrorAction => ({
  type: UsersActionType.USERS_SELECTED_DELETE_ERROR,
  payload: {
    error,
  },
});

/**
 * Creates an action to crear deleted user from store.
 * @return A clear deleted user action.
 */
export const clearDeletedUser = (): UsersDeletedClearStoreAction => ({
  type: UsersActionType.USERS_DELETED_CLEAR_STORE_REQUEST,
});

/**
 * A thunk that searches for users.
 * @param query A query string
 * @return A thunk action which returns a promise
 */
export function getUsers(query: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getUsersRequest());
    try {
      const users = await new MetadataService().searchUsers(query);
      dispatch(getUsersSuccess(users));
    } catch (error) {
      dispatch(getUsersError(error as Error));
    }
  };
}

/**
 * A thunk that determines if a user exists already.
 * @param email A user email
 * @return A thunk action which returns a promise containing a boolean
 */
export function checkExisting(email: string) {
  return async (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(checkExistingUserRequest());
    try {
      const users = await new MetadataService().searchUsers(email);
      const exists = users.length > 0;
      dispatch(checkExistingUserSuccess(exists));
      return exists;
    } catch (error) {
      dispatch(checkExistingUserError(error as Error));
      throw error;
    }
  };
}

/** A thunk that blocks a user
 * @param userId A user's id
 * @return A promise containing the response
 */
export function blockSelectedUser(userId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(blockSelectedUserRequest());
    try {
      await new MetadataService().blockUser(userId);
      dispatch(blockSelectedUserSuccess(userId));
    } catch (error) {
      dispatch(blockSelectedUserError(error as Error));
    }
  };
}

/**
 * A thunk that unblocks a user
 * @param userId A user's id
 * @return a promise containing the response
 */
export function unblockSelectedUser(userId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(unblockSelectedUserRequest());
    try {
      await new MetadataService().unBlockUser(userId);
      dispatch(unblockSelectedUserSuccess(userId));
    } catch (error) {
      dispatch(unblockSelectedUserError(error as Error));
    }
  };
}

/**
 * A thunk that deletes a user
 * @param userId A user's id
 * @return a promise containing the response
 */
export function deleteSelectedUser(userId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(deleteSelectedUserRequest());
    try {
      await new MetadataService().deleteUser(userId);
      dispatch(deleteSelectedUserSuccess(userId));
    } catch (error) {
      dispatch(deleteSelectedUserError(error as Error));
    }
  };
}

/**
 * Sets selected user
 * @param user A user to be set as selected
 * @return A set selected user request
 */
export const setSelectedUser = (
  user: User,
): UsersSetSelectedAction => ({
  type: UsersActionType.USERS_SET_SELECTED,
  payload: {
    user,
  },
});
