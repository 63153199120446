/** @module components/DashboardPageError */
import { LicenseType } from 'services/metadata';

export enum ErrorType {
  NOT_FOUND = 'NOT_FOUND',
  INACCESSIBLE = 'INACCESSIBLE'
}

export type Props = {
  protections?: LicenseType[];
  repositoryId?: string;
  errorType: ErrorType;
}
