/** @module store/snapshots */
import MetadataService,
{
  MetadataErrorResponse,
} from 'services/metadata';
import {
  Snapshot, SnapshotExport, SnapshotExportSegment, SnapshotExportSegments,
} from 'services/metadata/types/Snapshot';
import { promiseAllSettled } from 'utilities/asyncUtilities';
import { transformToMetadataErrorResponse } from 'utilities/standardizeErrors';
import { AppDispatch } from '../types';
import {
  SnapshotsActionType,
  GetSnapshotsSuccessAction,
  GetSnapshotsRequestAction,
  GetSnapshotsErrorAction,
  SnapshotCreateRequestAction,
  SnapshotCreateSuccessAction,
  SnapshotCreateErrorAction,
  GetSnapshotExportsRequestAction,
  GetSnapshotExportsSuccessAction,
  GetSnapshotExportsErrorAction,
  GetMultiSnapshotExportsRequestAction,
  GetMultiSnapshotExportsSuccessAction,
  GetMultiSnapshotExportsErrorAction,
  GetSnapshotExportSegmentsSuccessAction,
  GetMultiSnapshotExportSegmentsRequestAction,
  GetMultiSnapshotExportSegmentsSuccessAction,
  GetMultiSnapshotExportSegmentsErrorAction,
  GetSnapshotExportSegmentsErrorAction,
  GetSnapshotExportSegmentsRequestAction,
  DownloadSnapshotExportSegmentRequestAction,
  DownloadSnapshotExportSegmentSuccessAction,
  DownloadSnapshotExportSegmentFailureAction,
  SnapshotExportCreateRequestAction,
  SnapshotExportCreateSuccessAction,
  SnapshotExportCreateErrorAction,
} from './types';

/**
 * Creates a get snapshots request action.
 * @return A get snapshots request action.
 */
export const getSnapshotsRequest = (): GetSnapshotsRequestAction => ({
  type: SnapshotsActionType.SNAPSHOTS_GET_REQUEST,
});

/**
 * Creates a get snapshots success action.
 * @param snapshots An array of snapshots
 * @param repositoryId A repository id
 * @return A get snapshots success action.
 */
export const getSnapshotsSuccess = (
  byId: { [id: string]: Snapshot },
  byRepoId: string[],
  repositoryId: string,
): GetSnapshotsSuccessAction => ({
  type: SnapshotsActionType.SNAPSHOTS_GET_SUCCESS,
  payload: {
    byId,
    byRepoId: { [repositoryId]: byRepoId },
    repositoryId,
  },
});

/**
 * Creates a get snapshots error action.
 * @param error An error
 * @return A get snapshots error action.
 */
export const getSnapshotsError = (
  error: MetadataErrorResponse,
  repositoryId: string,
): GetSnapshotsErrorAction => ({
  type: SnapshotsActionType.SNAPSHOTS_GET_ERROR,
  payload: {
    error,
    repositoryId,
  },
});

/**
 * Creates a create snapshot request action.
 * @return A create snapshot request action.
 */
export const createSnapshotRequest = (): SnapshotCreateRequestAction => ({
  type: SnapshotsActionType.SNAPSHOT_CREATE_REQUEST,
});

/**
 * Creates a create snapshot success action.
 * @param response An object containing the id of the created snapshot.
 * @return A create snapshot success action.
 */
export const createSnapshotSuccess = (
  response: { id: string },
): SnapshotCreateSuccessAction => ({
  type: SnapshotsActionType.SNAPSHOT_CREATE_SUCCESS,
  payload: {
    response,
  },
});

/**
 * Creates a create snapshot error action.
 * @param error An error object describing the failure of creating a snapshot.
 * @return A create snapshot error action.
 */
export const createSnapshotError = (error: MetadataErrorResponse): SnapshotCreateErrorAction => ({
  payload: {
    error,
  },
  type: SnapshotsActionType.SNAPSHOT_CREATE_ERROR,
});


/**
 * Creates a get snapshot exports request action.
 * @return A get snapshot exports request action.
 */
export const getSnapshotExportsRequest = (): GetSnapshotExportsRequestAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORTS_GET_REQUEST,
});

/**
 * Creates a get snapshot exports success action.
 * @param byId An object containing snapshot exports mapped by their IDs
 * @param snapshotId A snapshot ID
 * @return A get snapshot exports success action.
 */
export const getSnapshotExportsSuccess = (
  byId: { [id: string]: SnapshotExport },
  bySnapshotId: string,
): GetSnapshotExportsSuccessAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORTS_GET_SUCCESS,
  payload: { byId, bySnapshotId },
});

/**
 * Creates a get snapshot exports error action.
 * @param error An error
 * @param snapshotId A snapshot ID
 * @return A get snapshot exports error action.
 */
export const getSnapshotExportsError = (
  error: MetadataErrorResponse,
  snapshotId: string,
): GetSnapshotExportsErrorAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORTS_GET_ERROR,
  payload: { error, snapshotId },
});

/**
 * Creates a create snapshot export request action.
 * @param payload An object containing repoId, snapshotId and maxSegmentSize.
 * @return A create snapshot export request action.
 */
export const createSnapshotExportRequest = (
  payload: { repoId: string; snapshotId: string; maxSegmentSize: number },
): SnapshotExportCreateRequestAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORT_CREATE_REQUEST,
  payload,
});

/**
 * Creates a create snapshot export success action.
 * @param response An object containing the id of the created snapshot export.
 * @return A create snapshot export success action.
 */
export const createSnapshotExportSuccess = (
  response: { id: string },
): SnapshotExportCreateSuccessAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORT_CREATE_SUCCESS,
  payload: {
    response,
  },
});

/**
 * Creates a create snapshot export error action.
 * @param error An error object describing the failure of creating a snapshot export.
 * @return A create snapshot export error action.
 */
export const createSnapshotExportError = (
  error: MetadataErrorResponse,
): SnapshotExportCreateErrorAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORT_CREATE_ERROR,
  payload: {
    error,
  },
});

/**
 * Creates a multiple get snapshots exports request action.
 * @return A multiple get snapshots exports request action.
 */
export const getMultiSnapshotExportsRequest = (
): GetMultiSnapshotExportsRequestAction => ({
  type: SnapshotsActionType.MULTI_SNAPSHOT_EXPORTS_GET_REQUEST,
});

/**
 * Creates a multiple get snapshots exports success action.
 * @return A get multiple snapshots exports success action.
 */
export const getMultiSnapshotExportsSuccess = (
): GetMultiSnapshotExportsSuccessAction => ({
  type: SnapshotsActionType.MULTI_SNAPSHOT_EXPORTS_GET_SUCCESS,
});

/**
 * Creates a multiple snapshots exports error action.
 * @param error An error
 * @return A multiple snapshots exports error action.
 */
export const getMultiSnapshotExportsError = (
  error: MetadataErrorResponse,
): GetMultiSnapshotExportsErrorAction => ({
  type: SnapshotsActionType.MULTI_SNAPSHOT_EXPORTS_GET_ERROR,
  payload: {
    error,
  },
});

// Creates a get snapshot export segments request action.
// @return A get snapshot export segments request action.
export const getSnapshotExportSegmentsRequest = (
): GetSnapshotExportSegmentsRequestAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENTS_GET_REQUEST,
});

/**
 * Creates a get snapshot export segments success action.
 * @param byId An object containing snapshot export segments mapped by their IDs
 * @param byExportId An export ID
 * @return A get snapshot export segments success action.
 */
export const getSnapshotExportSegmentsSuccess = (
  byId: { [id: string]: SnapshotExportSegment },
  byExportId: string,
): GetSnapshotExportSegmentsSuccessAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENTS_GET_SUCCESS,
  payload: { byId, byExportId },
});

/**
 * Creates a get snapshot export segments error action.
 * @param error An error
 * @param exportId An export ID
 * @return A get snapshot export segments error action.
 */
export const getSnapshotExportSegmentsError = (
  error: MetadataErrorResponse,
  exportId: string,
): GetSnapshotExportSegmentsErrorAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENTS_GET_ERROR,
  payload: { error, exportId },
});

/**
 * Creates a multiple get snapshot export segments request action.
 * @return A multiple get snapshot export segments request action.
 */
export const getMultiSnapshotExportSegmentsRequest = (
): GetMultiSnapshotExportSegmentsRequestAction => ({
  type: SnapshotsActionType.MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_REQUEST,
});

/**
 * Creates a multiple get snapshot export segments success action.
 * @return A multiple get snapshot export segments success action.
 */
export const getMultiSnapshotExportSegmentsSuccess = (
): GetMultiSnapshotExportSegmentsSuccessAction => ({
  type: SnapshotsActionType.MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_SUCCESS,
});

/**
 * Creates a multiple get snapshot export segments error action.
 * @param error An error
 * @return A multiple get snapshot export segments error action.
 */
export const getMultiSnapshotExportSegmentsError = (
  error: MetadataErrorResponse,
): GetMultiSnapshotExportSegmentsErrorAction => ({
  type: SnapshotsActionType.MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_ERROR,
  payload: { error },
});

/**
 * Creates a download snapshot export segment request action.
 * @param repoId Repository ID
 * @param snapshotId Snapshot ID
 * @param exportId Export ID
 * @param segmentNumber Segment Number to be downloaded
 * @return A download snapshot export segment request action.
 */
export const downloadSnapshotExportSegmentRequest = (
  repoId: string,
  snapshotId: string,
  exportId: string,
  segmentNumber: number,
): DownloadSnapshotExportSegmentRequestAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_REQUEST,
  payload: {
    repoId, snapshotId, exportId, segmentNumber,
  },
});

/**
 * Creates a download snapshot export segment success action.
 * @param repoId Repository ID
 * @param snapshotId Snapshot ID
 * @param exportId Export ID
 * @param segmentNumber Segment Number that was downloaded
 * @param url Download URL
 * @return A download snapshot export segment success action.
 */
export const downloadSnapshotExportSegmentSuccess = (
  repoId: string,
  snapshotId: string,
  exportId: string,
  segmentNumber: number,
  url: string,
): DownloadSnapshotExportSegmentSuccessAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_SUCCESS,
  payload: {
    repoId, snapshotId, exportId, segmentNumber, url,
  },
});

/**
 * Creates a download snapshot export segment failure action.
 * @param repoId Repository ID
 * @param snapshotId Snapshot ID
 * @param exportId Export ID
 * @param segmentNumber Segment Number that failed to download
 * @param error Metadata Error Response
 * @return A download snapshot export segment failure action.
 */
export const downloadSnapshotExportSegmentFailure = (
  repoId: string,
  snapshotId: string,
  exportId: string,
  segmentNumber: number,
  error: MetadataErrorResponse,
): DownloadSnapshotExportSegmentFailureAction => ({
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_FAILURE,
  payload: {
    repoId, snapshotId, exportId, segmentNumber, error,
  },
});

/**
 * A thunk that gets snapshots for the given repository ID.
 * @param repositoryId A repository ID
 * @return A thunk action which returns a promise
 */
export function getSnapshots(repositoryId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getSnapshotsRequest());
    try {
      const snapshots = await new MetadataService().getRepositorySnapshots(repositoryId);
      const byId: { [id: string]: Snapshot } = {};
      const byRepoId: string[] = [];
      snapshots.forEach((snapshot) => {
        byId[snapshot.id] = snapshot;
        byRepoId.push(snapshot.id);
      });
      dispatch(
        getSnapshotsSuccess(byId, byRepoId, repositoryId),
      );
    } catch (error) {
      dispatch(getSnapshotsError(transformToMetadataErrorResponse(error), repositoryId));
    }
  };
}

/**
 * A thunk that gets snapshot exports for the given array of snapshots IDs.
 * @param repositoryId A repository ID
 * @param snapshotIds An array of snapshots IDs
 * @return A thunk action which returns a promise
 */
export function getMultipleSnapshotsExports(repositoryId: string, snapshotIds: string[]) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getSnapshotExportsRequest());
    dispatch(getMultiSnapshotExportsRequest());
    try {
      const promises = snapshotIds.map(
        (snapId) => new MetadataService().getRepositorySnapshotExports(repositoryId, snapId),
      );
      const queue: PromiseSettledResult<SnapshotExport[]>[] = await promiseAllSettled(promises);
      const results: {
        [key: string]: PromiseSettledResult<SnapshotExport[]>;
      } = snapshotIds.reduce((acc, curr, index) => ({
        ...acc,
        [curr]: queue[index],
      }), {});
      Object.keys(results).forEach((snapshotId) => {
        const result = results[snapshotId];
        if (result.status === 'fulfilled') {
          const byId: { [id: string]: SnapshotExport } = {};
          result.value.forEach((exp) => {
            byId[exp.exportId] = exp;
          });
          dispatch(getSnapshotExportsSuccess(byId, snapshotId));
        } else {
          dispatch(getSnapshotExportsError(
            transformToMetadataErrorResponse(result.reason), snapshotId,
          ));
        }
      });
      dispatch(getMultiSnapshotExportsSuccess());
    } catch (error) {
      dispatch(getMultiSnapshotExportsError(transformToMetadataErrorResponse(error)));
    }
  };
}

/**
 * A thunk that gets multiple snapshot export segments for the given Map of exports.
 * @param repositoryId A repository ID
 * @param exportsMap A Map containing an array of snapshot exports for each snapshot ID
 * @return A thunk action which returns a promise
 */
export function getMultipleSnapshotSegments(
  repositoryId: string,
  exportsMap: Map<string, SnapshotExport[]>,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getSnapshotExportSegmentsRequest());
    dispatch(getMultiSnapshotExportSegmentsRequest());
    try {
      const promisesWithIds: { promise: Promise<SnapshotExportSegments>; exportId: string }[] = [];
      exportsMap.forEach((exportsArray, snapshotId) => {
        exportsArray.forEach((exp) => {
          promisesWithIds.push({
            promise: new MetadataService()
              .getRepositorySnapshotExportSegments(repositoryId, snapshotId, exp.exportId),
            exportId: exp.exportId,
          });
        });
      });

      const queue: PromiseSettledResult<SnapshotExportSegments>[] = await promiseAllSettled(
        promisesWithIds.map((p) => p.promise),
      );

      queue.forEach((result, index) => {
        const currentExportId = promisesWithIds[index].exportId;

        if (result.status === 'fulfilled') {
          const byId: { [id: string]: SnapshotExportSegment } = {};
          const { segments } = result.value;
          segments.forEach((segment) => {
            byId[`${segment.segmentNumber}-${segment.exportId}`] = segment;
          });
          dispatch(getSnapshotExportSegmentsSuccess(byId, currentExportId));
        } else {
          dispatch(getSnapshotExportSegmentsError(
            transformToMetadataErrorResponse(result.reason), currentExportId,
          ));
        }
      });
      dispatch(getMultiSnapshotExportSegmentsSuccess());
    } catch (error) {
      dispatch(getMultiSnapshotExportSegmentsError(transformToMetadataErrorResponse(error)));
    }
  };
}

/**
 * A thunk that downloads a snapshot export segment by making a server request
 * and triggers download on client-side upon success.
 *
 * @param repoId Repository ID
 * @param snapshotId Snapshot ID
 * @param exportId Export ID
 * @param segmentNumber Segment Number
 * @return A thunk action which returns a promise
 */
export function downloadSnapshotExportSegment(
  repoId: string,
  snapshotId: string,
  exportId: string,
  segmentNumber: number,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(downloadSnapshotExportSegmentRequest(repoId, snapshotId, exportId, segmentNumber));
    try {
      const downloadUrl = await new MetadataService()
        .getRepositorySnapshotExportSegmentDownloadUrl(repoId, snapshotId, exportId, segmentNumber);
      dispatch(
        downloadSnapshotExportSegmentSuccess(
          repoId,
          snapshotId,
          exportId,
          segmentNumber,
          downloadUrl,
        ),
      );
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = downloadUrl;
      a.setAttribute('download', 'download');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      dispatch(
        downloadSnapshotExportSegmentFailure(
          repoId,
          snapshotId,
          exportId,
          segmentNumber,
          transformToMetadataErrorResponse(error),
        ),
      );
    }
  };
}

/**
 * A thunk that creates a new repository snapshot.
 *
 * @param repoId - The ID of the repository.
 * @param snapshotName - The name of the snapshot.
 *
 * @return A thunk action which returns a promise. The promise resolves when the snapshot has been
 * created successfully and the related success action has been dispatched, or rejects when an error
 * occurs and the related error action has been dispatched.
 */
export function createRepositorySnapshot(
  repoId: string,
  snapshotName: string,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(createSnapshotRequest());
    try {
      const response = await new MetadataService().createRepositorySnapshot(
        repoId,
        snapshotName,
      );
      dispatch(createSnapshotSuccess(response));
    } catch (error) {
      dispatch(createSnapshotError(transformToMetadataErrorResponse(error)));
    }
  };
}

/**
 * A thunk that creates a new snapshot export.
 *
 * @param repoId - The ID of the repository.
 * @param snapshotId - The ID of the snapshot.
 * @param maxSegmentSize - The maximum segment size.
 *
 * @return A thunk action which returns a promise. The promise resolves when the snapshot export
 * has been created successfully and the related success action has been dispatched, or rejects
 * when an error
 * occurs and the related error action has been dispatched.
 */
export function createSnapshotExport(
  repoId: string,
  snapshotId: string,
  maxSegmentSize: number,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(createSnapshotExportRequest({ repoId, snapshotId, maxSegmentSize }));
    try {
      const response = await new MetadataService().createRepositorySnapshotExport(
        repoId,
        snapshotId,
        maxSegmentSize,
      );
      dispatch(createSnapshotExportSuccess(response));
    } catch (error) {
      dispatch(createSnapshotExportError(transformToMetadataErrorResponse(error)));
    }
  };
}
