/** @module components/UserRolesTable */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from 'services/metadata';
import { Table } from 'antd';
import { generatePaginationOptions } from 'utilities/antd';
import { hasBusinessPermission, hasGlobalPermission } from 'utilities/admin';
import { BusinessPermissions, GlobalPermissions } from 'services/metadata/types/Permissions';
import { Props } from './types';

/**
 * A table displaying the user roles within the Admin Console
 * @param props The component props
 * @return a JSX Element
 */
function UserRolesTable(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    loading,
    me,
    userRoles,
    getUserRoles,
    user,
  } = props;

  let canGetRoles = hasGlobalPermission(me.permissions, GlobalPermissions.GLOBAL_LIST_USER_ROLES);
  // if global permissions are not present, check for business permissions
  if (!canGetRoles) {
    canGetRoles = hasBusinessPermission(
      me.permissions,
      user.business.id,
      BusinessPermissions.BUSINESS_LIST_USER_ROLES,
    );
  }

  useEffect(() => {
    if (canGetRoles) getUserRoles();
  }, [getUserRoles, canGetRoles]);

  return (
    <Table
      dataSource={[...userRoles]}
      pagination={generatePaginationOptions(userRoles)}
      loading={loading}
      rowKey="id"
      size="middle"
      className="UserRolesTable"
      data-test-id="user-roles-table"
    >
      <Table.Column
        title={t('UserRolesTable.column.name', 'ROLE NAME')}
        key="name"
        render={(role: Role): JSX.Element => (
          <span>
            {role.name}
          </span>
        )}
        data-test-id="column-name"
      />
      <Table.Column
        title={t('UserRolesTable.column.description', 'DESCRIPTION')}
        key="description"
        render={(role: Role): JSX.Element => (
          <span>
            {role.description}
          </span>
        )}
        data-test-id="column-description"
      />
    </Table>
  );
}

export default UserRolesTable;
