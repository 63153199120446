/** @module components/MoveFilesModal */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { moveSelectedRepositoryItems } from 'store/repositoryItems/actions';
import MoveFilesModal from './component';
import { DispatchProps, StateProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'REPOSITORY_ITEMS_UPDATE'),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  moveFiles: (newParentId: string): void => {
    dispatch(moveSelectedRepositoryItems(newParentId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MoveFilesModal);
