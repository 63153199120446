/** @module components/RepositoryTable */
import { connect } from 'react-redux';
import { Repository } from 'services/metadata';
import { AppDispatch, GlobalState } from 'store/types';
import { updateName } from 'store/repositoryItems/actions';
import { restoreRepository, setSelectedRepositories } from 'store/repositories/actions';
import RepositoryTable from './component';
import {
  StateProps,
  DispatchProps,
} from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  selectedRepositories: state.repositories.selected,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  updateName: (id: string, name: string): void => {
    dispatch(updateName('repository', id, name, ''));
  },
  restoreRepository: (id: string, name: string): void => {
    dispatch(restoreRepository(id, name));
  },
  setSelectedRepositories: (repositories: Repository[]): void => {
    dispatch(setSelectedRepositories(repositories));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepositoryTable);
