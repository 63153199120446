/** @module components/RepositoryWizard */
import React, { useState, useEffect } from 'react';
import { Divider } from 'antd';
import { Props } from './types';
import CDIQuestionField from './fields/CDIQuestionField';
import ECDataQuestionField from './fields/ECDataQuestionField';
import ECLicenseQuestionField from './fields/ECLicenseQuestionField';

/**
 * Asks the user a series of questions to determine what kind of repository
 * should be created.
 * @param props The props
 * @return A JSX Element
 */
function DefaultRepositoryWizard(props: Props): JSX.Element {
  const { onChange } = props;
  const [isCdi, setIsCdi] = useState<boolean | undefined>(undefined);
  const [isEc, setIsEc] = useState<boolean | undefined>(undefined);
  const [isLicenseAvailable, setIsLicenseAvailable] = useState<boolean | undefined>(undefined);
  const [isUsOnly, setIsUsOnly] = useState<boolean | undefined>(undefined);

  /**
   * Updates the parent when a field is updated.
   */
  useEffect(() => {
    /**
     * Returns true if the wizard is complete.
     * @return true if the wizard is complete
     */
    const isComplete = (): boolean => {
      // Yes yes, yes no
      if (isCdi && isLicenseAvailable !== undefined) {
        return true;
      }
      // No yes yes, no yes no
      if (isCdi === false && isEc && isLicenseAvailable !== undefined) {
        return true;
      }
      // No no
      if (isCdi === false && isEc === false) {
        return true;
      }
      return false;
    };

    onChange({
      fedRamp: 0,
      isCdi: isCdi || false,
      isEc: isEc || false,
      isLicenseAvailable: isLicenseAvailable || false,
      isUsOnly: isUsOnly || false,
      isUkLicense: false,
      isUsCitizen: false,
      isDoc: false,
    }, isComplete());
  }, [isCdi, isEc, isLicenseAvailable, isUsOnly, onChange]);

  return (
    <>
      <CDIQuestionField
        onChange={(value): void => {
          setIsCdi(value);
          if (value) {
            setIsEc(value);
          } else {
            setIsEc(undefined);
          }
          setIsLicenseAvailable(undefined);
          setIsUsOnly(undefined);
        }}
        value={isCdi}
        data-test-id="cdi-question"
      />
      {(isCdi === false) && (
        <>
          <Divider />
          <ECDataQuestionField
            onChange={(value): void => {
              setIsEc(value);
              setIsLicenseAvailable(undefined);
              setIsUsOnly(undefined);
            }}
            value={isEc}
            data-test-id="ec-data-question"
          />
        </>
      )}
      {(isCdi || (isCdi === false && isEc)) && (
        <>
          <Divider />
          <ECLicenseQuestionField
            onChange={(value): void => {
              setIsLicenseAvailable(value);
              setIsUsOnly(!value);
            }}
            value={isLicenseAvailable}
            data-test-id="ec-license-question"
          />
        </>
      )}
    </>
  );
}

export default DefaultRepositoryWizard;
