/** @module store/features */
/**
 * An object containing key-value pairs. Keys are
 * feature name/key strings and values are boolean
 */
export type FeaturesState = {
  [key: string]: boolean;
};

export enum Feature {
  ADMIN_CONSOLE = 'ADMIN_CONSOLE',
  RENEW_EXTERNAL_COLLABORATORS = 'RENEW_EXTERNAL_COLLABORATORS',
  SEARCH = 'SEARCH',
  GAS_POWER_WIZARD = 'GAS_POWER_WIZARD',
  POWER_CONVERSION_WIZARD = 'POWER_CONVERSION_WIZARD',
  LANGUAGE_PICKER = 'LANGUAGE_PICKER',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  DELETE_USER = 'DELETE_USER',
}
