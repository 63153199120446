/** @module components/UserCard */
import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import UserRolesTable from 'components/UserRolesTable';
import UserRepositoriesSection from 'components/UserRepositoriesSection';
import { ReactComponent as BlockIcon } from 'assets/icons/block-icon.svg';
import UserMenu from 'components/UserMenu';
import Loader from 'components/Loader';
import { BusinessPermissions, GlobalPermissions } from 'services/metadata/types/Permissions';
import { Props } from './types';
import './styles.scss';

function UserCard(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { user, me, loading } = props;

  let canViewRepo = false;
  let canViewRoles = false;
  const { permissions } = me;

  if (permissions) {
    if (user && user.business) {
      const userBusiness = user.business.id;
      const businessPermission = permissions.byBusiness.find(
        (p) => p.businessId === userBusiness,
      );
      // if admin has business permissions for user's business
      if (businessPermission) {
        if (businessPermission.permissions.includes(
          BusinessPermissions.BUSINESS_FETCH_USER_REPOSITORIES,
        )) {
          canViewRepo = true;
        }
        if (businessPermission.permissions.includes(
          BusinessPermissions.BUSINESS_LIST_USER_ROLES,
        )) {
          canViewRoles = true;
        }
      }
    }
    if (permissions.global.length) {
      const { global } = permissions;
      if (global.includes(GlobalPermissions.GLOBAL_FETCH_USER_REPOSITORIES)) {
        canViewRepo = true;
      }
      if (global.includes(GlobalPermissions.GLOBAL_LIST_USER_ROLES)) {
        canViewRoles = true;
      }
    }
  }

  if (!user) {
    return (
      <div className="UserCard-no-user-loading">
        <Loader loading={loading} />
      </div>
    );
  }

  const isUserBlocked = user && user.locked;
  return (
    <Card
      className={`UserCard ${isUserBlocked ? 'UserCard-blocked' : ''}`}
      data-test-id="card"
      title={(
        <div className={`title ${loading ? 'loading' : ''}`}>
          {isUserBlocked && <BlockIcon className="blocked-icon" />}
          <div className="user-name">{user.name}</div>
          <div className="loader">
            <Loader loading={loading} />
          </div>
        </div>
      )}
      extra={user && (
        <UserMenu
          data-test-id="menu"
        />
      )}
    >
      {user && (
        <div className="content">
          <div className="section-title">
            {t('UserCard.roles', 'Roles')}
          </div>
          {canViewRoles ? <UserRolesTable user={user} data-test-id="roles-table" /> : <div className="no-permission">{t('UserCard.noPermissionText', 'You do not have permissions to view this record')}</div>}
          <div className="section-title">
            {t('UserCard.repositories', 'Repositories')}
          </div>
          {canViewRepo ? <UserRepositoriesSection userId={user.id} data-test-id="repos-table" /> : <div className="no-permission">{t('UserCard.noPermissionText', 'You do not have permissions to view this record')}</div>}
        </div>
      )}
    </Card>
  );
}

export default UserCard;
