import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import i18n from '../../i18n';

function permissionsData(): Array<object> {
  return ([
    {
      key: 1,
      title: i18n.t('DefinitionsPage.USONLY.title', 'USONLY'),
      definition: i18n.t('DefinitionsPage.USONLY.definition', 'US Persons - Only users marked as US PERSON (US_PERSON_IND = Y) in GE OneIDM may access and interact with USONLY repositories.'),
    },
    {
      key: 2,
      title: i18n.t('DefinitionsPage.GENE.title', 'GENE'),
      definition: i18n.t('DefinitionsPage.GENE.definition', 'GE Nuclear Energy - Only users marked as GE Nuclear Energy (GENE = Y) in GE OneIDM may access and interact with GENE repositories.'),
    },
    {
      key: 3,
      title: i18n.t('DefinitionsPage.NLR.title', 'NLR'),
      definition: i18n.t('DefinitionsPage.NLR.definition', 'No License Required - NLR repositories have the fewest protections and allow any collaborator to access their content.'),
    },
    {
      key: 4,
      title: i18n.t('DefinitionsPage.LR.title', 'LR'),
      definition: i18n.t('DefinitionsPage.LR.definition', 'License Required- Only US Persons can own a LR repository, and any US Person can become a collaborator. Owners may attach ‘managed US Export Control License(s)’ to a LR repository, allowing a user lacking US Person status to be added as a collaborator provided the user is a member of ALL the attached licenses.'),
    },
    {
      key: 5,
      title: i18n.t('DefinitionsPage.CDI.title', 'CDI'),
      definition: i18n.t('DefinitionsPage.CDI.definition', 'Covered Defense Information - A CDI tag will never be by itself. These tags are always connected to either LR or USONLY tags. CDI tags signify that a repository will allow additional protections in the future.'),
    },
    {
      key: 6,
      title: i18n.t('DefinitionsPage.DOC.title', 'DOC'),
      definition: i18n.t('DefinitionsPage.DOC.definition', 'DOC repositories are restricted to only those individuals that meet the US DOC requirements. DOC status is maintained in IDM.'),
    },
    {
      key: 7,
      title: i18n.t('DefinitionsPage.USCitizen.title', 'USCitizen'),
      definition: i18n.t('DefinitionsPage.USCitizen.definition', 'A USCitizen repository is restricted to an approved group of United States citizens within GE Aviation.'),
    },
    {
      key: 8,
      title: i18n.t('DefinitionsPage.GPLR.title', 'GPLR'),
      definition: i18n.t('DefinitionsPage.GPLR.definition', 'Repositories with the GPLR protection type are intended for the Gas Power business and its associated LR content.'),
    },
    {
      key: 9,
      title: i18n.t('DefinitionsPage.LIMITED.title', 'LIMITED'),
      definition: i18n.t('DefinitionsPage.LIMITED.definition', 'The “Limited” protection chip only appears for the owners of a repository when the owner’s business does not match the business the repository is assigned to. Some user actions may be unavailable.'),
    },
  ]);
}

function rolesData(): Array<object> {
  return (
    [
      {
        key: 1,
        title: i18n.t('DefinitionsPage.owner.title', 'Owner'),
        definition: i18n.t('DefinitionsPage.owner.definition', 'Repository Owners are considered the primary maintainer of a repository. They may add and remove collaborators, delete and restore repositories they own, and they may preview, share, upload, download, move, delete, and restore files and folders within their repositories. While an Owner may change or hand off the ownership of their repository, a repository must have an Owner.'),
      },
      {
        key: 2,
        title: i18n.t('DefinitionsPage.coowner.title', 'CoOwner'),
        definition: i18n.t('DefinitionsPage.coowner.definition', 'Repository CoOwners have the action rights as repository Owners; the one exception being they cannot change or update the Owner status of a repository.'),
      },
      {
        key: 3,
        title: i18n.t('DefinitionsPage.editor.title', 'Editor'),
        definition: i18n.t('DefinitionsPage.editor.definition', 'Repository Editors have full access to anything at the folder & file level. They may upload, delete, restore, download, move, share, preview, etc. any folder/file within a repository they have access to. Editors may not, however, make any changes to the repository itself (including adding licenses, managing collaborators, renaming the repository, etc.).'),
      },
      {
        key: 4,
        title: i18n.t('DefinitionsPage.viewer.title', 'Viewer'),
        definition: i18n.t('DefinitionsPage.viewer.definition', 'Repository Viewers have ‘view-only’ access, meaning they may preview, share, and download files, but they cannot perform any managerial tasks (which includes making repository level changes, or renaming, moving, uploading, deleting, or restoring files and folders, etc.).'),
      },
    ]
  );
}

function externalUserData(): Array<object> {
  return (
    [
      {
        key: 1,
        title: i18n.t('DefinitionsPage.externalUser.title', 'External User'),
        definition: i18n.t('DefinitionsPage.externalUser.definition', 'External Users are considered REDshare users that are not employed at General Electric. For Export Control and security purposes, External Users may only hold Editor and Viewer collaborator roles. This means that External Users will not be able to create repositories of their own or perform any managerial tasks at the repository level.'),
      },
    ]
  );
}

function allowedActionsData(): Array<object> {
  return (
    [
      {
        key: 1,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.viewMyRepo', 'View My Repositories'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CheckIcon className="icon check-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 2,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.viewSharedRepo', 'View Shared Repositories'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CheckIcon className="icon check-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CheckIcon className="icon check-icon" />,
      },
      {
        key: 3,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.viewCollabs', 'View Collaborators'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CheckIcon className="icon check-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CheckIcon className="icon check-icon" />,
      },
      {
        key: 4,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.addCollabs', 'Add Collaborators'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CloseIcon className="icon close-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 5,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.rmCollabs', 'Remove Collaborators'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CloseIcon className="icon close-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 6,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.rmSelf', 'Remove Yourself from a Repository'),
        ownerIcon: <CloseIcon className="icon close-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CheckIcon className="icon check-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CheckIcon className="icon check-icon" />,
      },
      {
        key: 7,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.changeOwner', 'Change Repository Owner'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CloseIcon className="icon close-icon" />,
        editorIcon: <CloseIcon className="icon close-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 8,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.mngProtections', 'Manage Repository Protections'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CloseIcon className="icon close-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 9,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.createRepo', 'Create Repository'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CheckIcon className="icon check-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 10,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.mngLicenses', 'Manage Repository Licenses'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CloseIcon className="icon close-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 11,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.renameRepo', 'Rename Repository'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CloseIcon className="icon close-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 12,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.deleteRepo', 'Delete Repository'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CloseIcon className="icon close-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 13,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.viewRepoTrash', 'View Repositories Trash'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CloseIcon className="icon close-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 14,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.restoreRepo', 'Restore Repositories from Trash'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CloseIcon className="icon close-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 15,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.deleteItem', 'Delete Folder/File'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 16,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.viewItemsTrash', 'View Folder/File Trash'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 17,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.restoreItem', 'Restore Folder/File from Trash'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 18,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.moveItem', 'Move Folder/File'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 19,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.renameItem', 'Rename Folder/File'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 20,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.createFolder', 'Create Folder'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 21,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.uploadFile', 'Upload File'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
      {
        key: 22,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.shareItem', 'Share Folder/File'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CheckIcon className="icon check-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CheckIcon className="icon check-icon" />,
      },
      {
        key: 23,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.previewFile', 'Preview File'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CheckIcon className="icon check-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CheckIcon className="icon check-icon" />,
      },
      {
        key: 24,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.downloadFile', 'Download File'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CheckIcon className="icon check-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CheckIcon className="icon check-icon" />,
      },
      {
        key: 25,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.emailFile', 'Email File'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CheckIcon className="icon check-icon" />,
        viewerIcon: <CheckIcon className="icon check-icon" />,
        ecEditorIcon: <CheckIcon className="icon check-icon" />,
        ecViewerIcon: <CheckIcon className="icon check-icon" />,
      },
      {
        key: 26,
        action: i18n.t('DefinitionsPage.body.allowedActionsTable.action.manageTags', 'Manage Tags'),
        ownerIcon: <CheckIcon className="icon check-icon" />,
        coOwnerIcon: <CheckIcon className="icon check-icon" />,
        editorIcon: <CloseIcon className="icon close-icon" />,
        viewerIcon: <CloseIcon className="icon close-icon" />,
        ecEditorIcon: <CloseIcon className="icon close-icon" />,
        ecViewerIcon: <CloseIcon className="icon close-icon" />,
      },
    ]
  );
}

export {
  permissionsData,
  rolesData,
  externalUserData,
  allowedActionsData,
};
