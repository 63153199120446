/** @module utilities/routes */
import appPaths from './appPaths';
import getAppRoutes from './getAppRoutes';
import getItemLink from './getItemLink';

export * from './types';
export {
  appPaths,
  getAppRoutes,
  getItemLink,
};
