/** @module components/ManageRepositoryForm */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Form,
} from 'antd';
import RepositoryInfoSection from 'components/RepositoryInfoSection';
import ConfirmNavigationModal from 'components/ConfirmNavigationModal';
import RepositoryCard from 'components/RepositoryCard';
import ManageTagsSection from 'components/ManageTagsSection';
import ExistingCollaboratorsTable from 'components/ExistingCollaboratorsTable';
import { history } from 'utilities/history';
import { useTranslation } from 'react-i18next';
import { Props, FormData } from './types';
import './styles.scss';

function ManageRepositoryForm(props: Props): JSX.Element {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isTagChanged, setIsTagChanged] = useState(false);

  const {
    loading,
    updateRepository,
    businessTags,
    getBusinessTags,
    tagsLoading,
    repository,
    createRepositoryTagsMapping,
    deleteRepositoryTagsMapping,
    getCollaborators,
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm<FormData>();

  useEffect(() => {
    if (repository.business) {
      getBusinessTags(repository.business.id);
    }
  }, [getBusinessTags, repository.business]);

  useEffect(() => {
    getCollaborators(repository.id);
  }, [getCollaborators, repository.id]);

  /**
   * Validates the form and updates the repository.
   * @param event A form submission event
   */
  async function onFinish(formData: FormData): Promise<void> {
    const { name, fileContentSearch } = formData;
    const isFormDataChanged = name !== repository.name
      || fileContentSearch !== repository.fullTextSearch;

    if (isFormDataChanged) {
      const showNotification = true;
      updateRepository(repository.id, name, fileContentSearch, showNotification);
    }

    if (isTagChanged) {
      let deletedTags: string[] = [];
      let newTags: string[] = [];
      if (repository.tags && repository.tags.length > 0) {
        const repositoryTags = repository.tags || [];
        // filter deleted tags
        deletedTags = repositoryTags.filter((tag) => !selectedTags.includes(tag));
        // filter new tags
        newTags = selectedTags.filter((tag) => !repositoryTags.includes(tag));
      } else {
        newTags = selectedTags;
      }

      if (businessTags) {
        // we just need a single toast notification for update
        let showNotification = !isFormDataChanged;
        if (deletedTags.length > 0) {
          const deletedTagsDetails = businessTags.filter((tag) => deletedTags.includes(tag.name));
          deleteRepositoryTagsMapping(repository.id, deletedTagsDetails, showNotification);
          showNotification = false;
        }
        if (newTags.length > 0) {
          const newTagsDetails = businessTags.filter((tag) => newTags.includes(tag.name));
          createRepositoryTagsMapping(repository.id, newTagsDetails, showNotification);
        }
      }
      setIsTagChanged(false);
    }
    setIsFormChanged(false);
  }

  function onCancel(): void {
    setIsCancelled(true);
    history.push(`/my-repositories/${repository.id}`);
  }

  const onChangeFormValue = (values: FormData): void => {
    if (isFormChanged === false) {
      if (values.name !== undefined || values.parentBusiness !== undefined) {
        setIsFormChanged(true);
      }
    }
  };

  const onChangeTag = (tags: string[]): void => {
    setSelectedTags(tags);
    setIsTagChanged(true);
    if (isFormChanged === false) setIsFormChanged(true);
  };

  return (
    <Form
      form={form}
      data-test-id="form"
      onFinish={onFinish}
      layout="vertical"
      className="ManageRepositoryForm"
      onValuesChange={(changedValues, allValues): void => onChangeFormValue(allValues)}
      initialValues={{
        name: repository.name,
        parentBusiness: repository.business ? repository.business.displayName : undefined,
        fileContentSearch: repository.fullTextSearch || false,
      }}
    >
      <ConfirmNavigationModal
        data-test-id="confirm-nav-modal"
        when={isFormChanged}
        title={isCancelled ? t('ManageRepositoryForm.cancelModal.title', 'Cancel?') : t('ManageRepositoryForm.pageChangeConfirmModal.title', 'Unsaved Changes')}
        content={isCancelled ? t('ManageRepositoryForm.cancelModal.content', 'Are you sure you want to Cancel? If you Cancel now, your changes will not be saved.') : t('ManageRepositoryForm.pageChangeConfirmModal.content', 'Are you sure you want to leave this page? If you leave now, your changes will not be saved.')}
        okText={isCancelled ? t('ManageRepositoryForm.cancelModal.okText', 'YES, CANCEL') : undefined}
        cancelText={isCancelled ? t('ManageRepositoryForm.cancelModal.cancelText', 'DON\'T CANCEL') : undefined}
        afterCancel={(): void => setIsCancelled(false)}
      />
      <RepositoryCard
        repository={repository}
        showProtectionsInHeader
        showBusinessInHeader
        showManageSnapshotsButtonInHeader
        cardSize="large"
        data-test-id="repository-card"
        isNameClickable={false}
        expandable={false}
      >
        <div className="section info" data-test-id="info-section">
          <div className="section-title">{t('ManageRepositoryForm.repoInfo', 'Repository Info')}</div>
          <RepositoryInfoSection
            data-test-id="repo-info"
            loading={loading}
            businessDisabled
          />
        </div>

        <Divider />
        <div className="section" data-test-id="tags-section">
          <div className="section-title">
            {t('ManageRepositoryForm.mngTags', 'Manage Tags')}
          </div>
          <ManageTagsSection
            data-test-id="manage-tags"
            loading={tagsLoading || loading}
            businessTags={businessTags}
            businessId={repository.business ? repository.business.id : ''}
            onChangeTag={onChangeTag}
            initialValue={repository.tags}
          />
        </div>

        <Divider />
        <div className="section existing-collab" data-test-id="existing-collab-section">
          <div className="section-header">
            <div className="section-title">
              {t('ManageRepositoryForm.existingCollab', 'Existing Collaborators')}
            </div>
            <Button
              disabled={loading}
              data-test-id="mng-collab-button"
              className="primary-inverse"
              onClick={(): void => history.push(`/manage-collaborators/${repository.id}`)}
            >
              {t('ManageRepositoryForm.manageCollab', 'MANAGE COLLABORATORS')}
            </Button>
          </div>
          <ExistingCollaboratorsTable
            repository={repository}
            canRemoveCollabs={false}
            canUpdateCollabs={false}
            canUpdateRepoOwner={false}
            showRemoveColumn={false}
          />
        </div>

      </RepositoryCard>
      <div className="buttons-container">
        <Form.Item className="ant-form-item--last-item ant-form-item--form-buttons">
          <Button
            type="ghost"
            disabled={loading}
            data-test-id="cancel"
            className="cancel-button"
            onClick={onCancel}
          >
            {t('ManageRepositoryForm.cancel', 'CANCEL')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            data-test-id="submit"
            className="save-button"
          >
            {t('ManageRepositoryForm.save', 'SAVE')}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

export default ManageRepositoryForm;
