/** @module services/storage */
import { Client } from './types/Client';
import { UploadFile } from './types/Upload';

const MAX_RETRY = 5;
const WAIT_TIME = 3000;

/**
 * A client that communicates with the storage service using XHR.
 */
class XHRClient implements Client {
  url: string;

  /**
   * Constructs a new client with the given upload url.
   * @param url The url to upload items to
   */
  constructor(url: string) {
    this.url = url;
  }

  /**
   * Uploads a file with the given upload and progress function. The onProgress
   * function takes a progress parameter that is a number between 0 and 1.
   * if there is an error, the function retries the upload for MAX_RETRY times
   * @param upload An upload
   * @param onProgress A function to be called when progress occurs
   * @param onSuccess A function to be called when the request is successful
   * @return A promise containing a response
   * @throws An error if the upload failed or was aborted
   */
  uploadFile(
    upload: UploadFile,
    onProgress: (progress: number) => void,
    onSuccess: () => void,
  ): Promise<Response> {
    return new Promise((resolve, reject): void => {
      const formData = new FormData();
      const request = upload.xhr;
      let numberOfTries = 1;

      // Retry to send the request
      const retry = (event: ProgressEvent<XMLHttpRequestEventTarget> | string): void => {
        const responseText = request.responseText && JSON.parse(request.responseText);
        if (numberOfTries <= MAX_RETRY && (
          request.status === 504
          || request.status === 500
          || request.status === 502
          || (request.status === 400 && responseText.message === 'No file provided in upload')
          || request.status === 0)) {
          setTimeout(() => {
            numberOfTries += 1;
            request.open('POST', this.url);
            request.send(formData);
          }, WAIT_TIME);
        } else {
          reject(event);
        }
      };
      formData.append('file', upload.file);
      request.upload.addEventListener('progress', (progress) => {
        onProgress(progress.loaded / progress.total);
      });

      request.addEventListener('load', () => {
        if (request.status >= 400) {
          retry(request.statusText);
        } else {
          onSuccess();
          resolve(request.response);
        }
      });

      request.addEventListener('error', (event) => retry(event));
      request.addEventListener('abort', () => resolve(request.response));
      request.open('POST', this.url);
      request.send(formData);
    });
  }
}


export default XHRClient;
