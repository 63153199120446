/** @module components/ManageCollaboratorsPage */
import ManageCollaboratorsPage from './component';
import Container from './container';
import AdminManageCollaborator from './adminContainer';

export {
  ManageCollaboratorsPage,
  AdminManageCollaborator,
};
export default Container;
