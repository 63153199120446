/** @module store/notifications */
import { AnyAction } from 'redux';
import { RolesActionTypes } from 'store/roles/types';
import { Role, MetadataErrorResponse } from 'services/metadata';
import { UserRepositoriesActionType } from 'store/user-repositories/types';
import { UploadsActionType } from 'store/uploads/types';
import { RepositoryExportZipActionType } from 'store/repositoryExports/types';
import { SnapshotsActionType } from 'store/snapshots/types';
import { EvictionStatus } from 'services/metadata/types/EvictionStatus';
import { truncateFileName } from 'utilities/file';
import i18n from '../../i18n';
import { AnnouncementsActionType } from '../announcements/types';
import { UsersActionType } from '../users/types';
import { RepositoriesActionType } from '../repositories/types';
import { RepositoryItemsActionType } from '../repositoryItems/types';
import { CollaboratorsActionType } from '../collaborators/types';
import { LicenseActionType } from '../licenses/types';
import { ReleaseNotesActionType } from '../release-notes/types';
import { TagsActionType } from '../tags/types';
import { BusinessesActionType } from '../businesses/types';
import createErrorNotification from './createErrorNotification';
import {
  State,
  NotificationType,
  NotificationsActionType,
} from './types';
import { SearchActionType } from '../search/types';

export const DEFAULT_STATE: State = [];

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
export default function reducer(
  state: State = DEFAULT_STATE,
  action: AnyAction,
): State {
  switch (action.type) {
    case NotificationsActionType.NOTIFICATIONS_ADD:
      return [
        ...state,
        action.notification,
      ];
    case NotificationsActionType.NOTIFICATIONS_REMOVE_ALL:
      return [];
    case AnnouncementsActionType.ANNOUNCEMENTS_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.announcementsGetError',
            'Could not get announcements',
          ),
        ),
      ];
    }
    case UsersActionType.USERS_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t('notifications.usersGetError', 'User not found.'),
        ),
      ];
    }
    case UsersActionType.USERS_CHECK_EXISTING_SUCCESS: {
      const { exists } = action.payload;
      if (exists) {
        return [
          ...state,
          {
            type: NotificationType.WARNING,
            title: i18n.t(
              'notifications.usersCheckExistingSuccess.title',
              'User Already Exists',
            ),
            message: i18n.t(
              'notifications.usersCheckExistingSuccess.message',
              'Please add user as an INTERNAL Collaborator.',
            ),
          },
        ];
      }
      return state;
    }
    case UsersActionType.USERS_CHECK_EXISTING_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.usersCheckExistingError',
            'We ran into an issue. Please try again.',
          ),
        ),
      ];
    }
    case RepositoriesActionType.REPOSITORIES_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoriesGetError',
            'Unable to fetch repositories',
          ),
        ),
      ];
    }
    case RepositoriesActionType.REPOSITORY_CREATE_SUCCESS: {
      const { response } = action.payload;
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.repositoriesCreateSuccess.title',
            'Repository created',
          ),
          message: i18n.t(
            'notifications.repositoriesCreateSuccess.message',
            '"{{name}}" created successfully.',
            { name: response.name },
          ),
        },
      ];
    }
    case RepositoriesActionType.REPOSITORY_CREATE_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoriesCreateError',
            'Repository could not be created',
          ),
        ),
      ];
    }
    case UserRepositoriesActionType.USERS_REPOSITORIES_DELETE_SUCCESS:
    case RepositoriesActionType.REPOSITORY_DELETE_SUCCESS: {
      const { repository, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          {
            type: NotificationType.SUCCESS,
            title: i18n.t(
              'notifications.repositoriesDeleteSuccess.title',
              'Deleted repository:',
            ),
            message: i18n.t(
              'notifications.repositoriesDeleteSuccess.message',
              '"{{name}}"',
              { name: repository.name },
            ),
          },
        ];
      }
      return state;
    }
    case UserRepositoriesActionType.USERS_REPOSITORIES_DELETE_ERROR:
    case RepositoriesActionType.REPOSITORY_DELETE_ERROR: {
      const { error, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          createErrorNotification(
            error,
            i18n.t(
              'notifications.repositoriesDeleteError',
              'Failed to delete repository',
            ),
          ),
        ];
      }
      return state;
    }
    case RepositoriesActionType.REPOSITORY_DELETE_SELECTED_SUCCESS: {
      const { repositories } = action.payload;
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.repositoryDeleteSelectedSuccess.title',
            'Successfully deleted {{count}} repositories',
            { count: repositories.length },
          ),
          message: i18n.t('notifications.repositoryDeleteSelectedSuccess.message', ''),
        },
      ];
    }
    case RepositoriesActionType.REPOSITORY_DELETE_SELECTED_ERROR: {
      const { errors, repositories } = action.payload;
      return [
        ...state,
        createErrorNotification(
          errors[0],
          i18n.t(
            'notifications.repositoryDeleteSelectedError',
            'Failed to delete {{failed}} of {{total}} repositories',
            {
              failed: errors.length,
              total: repositories.length,
            },
          ),
        ),
      ];
    }
    case RepositoriesActionType.REPOSITORIES_TRASH_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoriesTrashGetError',
            'Unable to fetch repositories trash',
          ),
        ),
      ];
    }
    case RepositoriesActionType.REPOSITORY_RESTORE_SUCCESS: {
      const { name, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          {
            type: NotificationType.SUCCESS,
            title: i18n.t(
              'notifications.repositoryRestoreSuccess.title',
              'Repository restored:',
            ),
            message: i18n.t(
              'notifications.repositoryRestoreSuccess.message',
              '"{{name}}"',
              { name },
            ),
          },
        ];
      }
      return state;
    }
    case RepositoriesActionType.REPOSITORY_RESTORE_ERROR: {
      const { error, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          createErrorNotification(
            error,
            i18n.t(
              'notifications.RepositoryRestoreError',
              'Unable to restore repository',
            ),
          ),
        ];
      }
      return state;
    }
    case RepositoriesActionType.REPOSITORY_RESTORE_SELECTED_SUCCESS: {
      const { repositories } = action.payload;
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.repositoryRestoreSelectedSuccess.title',
            'Successfully restored {{count}} repositories',
            { count: repositories.length },
          ),
          message: i18n.t('notifications.repositoryRestoreSelectedSuccess.message', ''),
        },
      ];
    }
    case RepositoriesActionType.REPOSITORY_RESTORE_SELECTED_ERROR: {
      const { errors, repositories } = action.payload;
      return [
        ...state,
        createErrorNotification(
          errors[0],
          i18n.t(
            'notifications.repositoryRestoreSelectedError',
            'Failed to restore {{failed}} of {{total}} repositories',
            {
              failed: errors.length,
              total: repositories.length,
            },
          ),
        ),
      ];
    }
    case RepositoriesActionType.REPOSITORY_EVICT_SUCCESS: {
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.RepositoryEvictSuccess.title',
            'Extraction process started',
          ),
          message: '',
        },
      ];
    }
    case RepositoriesActionType.REPOSITORY_EVICT_ERROR: {
      const { error } = action.payload as { error: MetadataErrorResponse };
      return [
        ...state,
        {
          type: NotificationType.ERROR,
          title: i18n.t(
            'notifications.RepositoryEvictError.title',
            'Repository could not be extracted',
          ),
          message: i18n.t(error.description),
        },
      ];
    }
    case RepositoriesActionType.REPOSITORY_GET_EVICTION_STATUS_SUCCESS: {
      const { status, showNotification } = action.payload as {
        repoId: string;
        status: EvictionStatus;
        showNotification?: boolean;
      };
      if (status.evictionStatus.failed && showNotification) {
        return [
          ...state,
          {
            type: NotificationType.ERROR,
            title: i18n.t(
              'notifications.RepositoryEvictProcessFinishError.title',
              '"{{name}}" could not be extracted',
              { name: truncateFileName(status.evictionStatus.repository.name, 15) },
            ),
            message: i18n.t(status.evictionStatus.errors),
          },
        ];
      }
      if (status.evictionStatus.complete && showNotification) {
        return [
          ...state,
          {
            type: NotificationType.SUCCESS,
            title: i18n.t(
              'notifications.RepositoryEvictProcessFinishSuccess.title',
              'Extracted Repository',
            ),
            message: i18n.t(
              'notifications.RepositoryEvictProcessFinishSuccess.message',
              'Successfully Extracted Repository "{{name}}"',
              { name: truncateFileName(status.evictionStatus.repository.name, 12) },
            ),
          },
        ];
      }
      return state;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_GET_ERROR: {
      const { error, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          createErrorNotification(
            error,
            i18n.t(
              'notifications.repositoryItemsGetError',
              'Unable to fetch repository items',
            ),
          ),
        ];
      }
      return state;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_CREATE_SUCCESS: {
      const { itemDetails, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          {
            type: NotificationType.SUCCESS,
            title: i18n.t(
              'notifications.repositoryItemsCreateSuccess.title',
              'Created folder:',
            ),
            message: i18n.t(
              'notifications.repositoryItemsCreateSuccess.message',
              '"{{name}}"',
              { name: itemDetails.name },
            ),
          },
        ];
      }
      return state;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_CREATE_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoryItemsCreateError',
            'Failed to create folder',
          ),
        ),
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SUCCESS: {
      const { itemDetails, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          {
            type: NotificationType.SUCCESS,
            title: i18n.t(
              'notifications.repositoryItemsDeleteSuccess.title',
              'Successfully deleted:',
            ),
            message: i18n.t(
              'notifications.repositoryItemsDeleteSuccess.message',
              '"{{name}}"',
              { name: itemDetails.name },
            ),
          },
        ];
      }
      return state;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_ERROR: {
      const {
        error,
        itemDetails,
        showNotification,
      } = action.payload;
      if (showNotification) {
        return [
          ...state,
          createErrorNotification(
            error,
            i18n.t(
              'notifications.repositoryItemsDeleteError',
              'Failed to delete "{{name}}"',
              { name: itemDetails.name },
            ),
          ),
        ];
      }
      return state;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SELECTED_SUCCESS: {
      const { itemDetails } = action.payload;
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.repositoryItemsDeleteSelectedSuccess.title',
            'Successfully deleted {{count}} items',
            { count: itemDetails.length },
          ),
          message: i18n.t('notifications.repositoryItemsDeleteSelectedSuccess.message', ''),
        },
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SELECTED_ERROR: {
      const { error, itemDetails } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error[0],
          i18n.t(
            'notifications.repositoryItemsDeleteSelectedError',
            'Failed to delete {{failed}} of {{total}} items',
            {
              failed: error.length,
              total: itemDetails.length,
            },
          ),
        ),
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_UPLOAD_URL_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoryItemsUploadUrlGetError',
            'Failed to get upload URL',
          ),
        ),
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_PREVIEW_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoryItemsPreviewUrlGetError',
            'The file you are trying to view is not supported by the preview tool.',
          ),
        ),
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_CLIPBOARD_COPY_SUCCESS: {
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.repositoryItemsClipboardCopySuccess.title',
            'Successfully copied url',
          ),
          message: i18n.t('notifications.repositoryItemsClipboardCopySuccess.message', ''),
        },
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_CLIPBOARD_COPY_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoryItemsClipboardCopyError',
            'Could not copy url',
          ),
        ),
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_TRASH_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoryItemsTrashGetError',
            'Unable to fetch repository trash',
          ),
        ),
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SUCCESS: {
      const { itemDetails, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          {
            type: NotificationType.SUCCESS,
            title: i18n.t(
              'notifications.repositoryItemsRestoreSuccess.title',
              'Restored {{name}}',
              { name: itemDetails.name },
            ),
            message: i18n.t(
              'notifications.repositoryItemsRestoreSuccess.message',
              '{{name}} has been restored',
              { name: itemDetails.name },
            ),
          },
        ];
      }
      return state;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_ERROR: {
      const { error, itemDetails, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          createErrorNotification(
            error,
            i18n.t(
              'notifications.repositoryItemsRestoreError',
              'Failed to restore {{name}}',
              { name: itemDetails.name },
            ),
          ),
        ];
      }
      return state;
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_SELECTED_SUCCESS: {
      const { itemDetails } = action.payload;
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.repositoryItemsMoveSelectedSuccess.title',
            'Successfully moved {{count}} items',
            { count: itemDetails.length },
          ),
          message: i18n.t(
            'notifications.repositoryItemsMoveSelectedSuccess.message',
            '{{count}} items have been moved',
            { count: itemDetails.length },
          ),
        },
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_SELECTED_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoryItemsMoveSelectedError',
            'Failed to move items',
          ),
        ),
      ];
    }
    case UserRepositoriesActionType.USERS_REPOSITORIES_RENAME_SUCCESS:
    case RepositoryItemsActionType.REPOSITORY_ITEMS_RENAME_SUCCESS: {
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.repositoryItemsRenameSuccess.title',
            'Name updated',
          ),
          message: i18n.t(
            'notifications.repositoryItemsRenameSuccess.message',
            '',
          ),
        },
      ];
    }
    case UserRepositoriesActionType.USERS_REPOSITORIES_RENAME_ERROR:
    case RepositoryItemsActionType.REPOSITORY_ITEMS_RENAME_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoryItemsRenameError',
            'Unable to update name',
          ),
        ),
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_SUCCESS: {
      const { itemDetails, parentDetails } = action.payload;
      const type = (itemDetails.type === 'FOLDER' ? 'Folder' : 'File');
      let message = '';
      if (parentDetails) {
        message = i18n.t(
          'notifications.repositoryItemsMoveSuccess.message',
          'Moved {{name}} to {{folder}}.',
          { name: itemDetails.name, folder: parentDetails.name },
        );
      }
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.repositoryItemsMoveSuccess.title',
            '{{type}} successfully moved!',
            { type },
          ),
          message,
        },
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_ERROR: {
      const { error, itemDetails } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoryItemsMoveError.title',
            'Unable to move {{type}}',
            { type: itemDetails.type.toLowerCase() },
          ),
        ),
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SELECTED_SUCCESS: {
      const { itemDetails } = action.payload;
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.repositoryItemsRestoreSelectedSuccess.title',
            'Successfully restored {{count}} items',
            { count: itemDetails.length },
          ),
          message: i18n.t('notifications.repositoryItemsRestoreSelectedSuccess.message', ''),
        },
      ];
    }
    case RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SELECTED_ERROR: {
      const { error, itemDetails } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error[0],
          i18n.t(
            'notifications.repositoryItemsRestoreSelectedError',
            'Failed to restore {{failed}} of {{total}} items',
            {
              failed: error.length,
              total: itemDetails.length,
            },
          ),
        ),
      ];
    }
    case LicenseActionType.LICENSES_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.licensesGetError',
            'Could not fetch repository licenses.',
          ),
        ),
      ];
    }
    case LicenseActionType.LICENSES_ADD_SUCCESS: {
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.licensesAddSuccess.title',
            'License added',
          ),
          message: i18n.t(
            'notifications.licensesAddSuccess.message',
            'License successfully added.',
          ),
        },
      ];
    }
    case LicenseActionType.LICENSES_ADD_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.licensesAddError',
            'License could not be added.',
          ),
        ),
      ];
    }
    case LicenseActionType.LICENSES_DELETE_SUCCESS: {
      const { license } = action.payload;
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.licensesDeleteSuccess.title',
            'License successfully removed:',
          ),
          message: i18n.t(
            'notifications.licensesDeleteSuccess.message',
            '{{license}}',
            { license },
          ),
        },
      ];
    }
    case LicenseActionType.LICENSES_DELETE_ERROR: {
      const { error, license } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.licensesDeleteError',
            'Failed to remove license {{license}}',
            { license },
          ),
        ),
      ];
    }
    case LicenseActionType.LICENSES_GET_ALL_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.licensesGetAllError',
            'Could not fetch licenses.',
          ),
        ),
      ];
    }
    case CollaboratorsActionType.COLLABORATORS_GET_ERROR: {
      const { error, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          createErrorNotification(
            error,
            i18n.t(
              'notifications.collaboratorsGetError',
              'Could not fetch repository collaborators',
            ),
          ),
        ];
      }
      return state;
    }
    case CollaboratorsActionType.COLLABORATORS_ADD_SUCCESS: {
      const { repository, showNotification } = action.payload;
      if (repository && showNotification) {
        return [
          ...state,
          {
            type: NotificationType.SUCCESS,
            title: i18n.t(
              'notifications.collaboratorsAddSuccess.title',
              'Collaborator added',
            ),
            message: i18n.t(
              'notifications.collaboratorsAddSuccess.message',
              'Collaborator successfully added to "{{name}}".',
              { name: repository.name },
            ),
          },
        ];
      }
      return state;
    }
    case CollaboratorsActionType.COLLABORATORS_ADD_ERROR: {
      const { error, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          createErrorNotification(
            error,
            i18n.t(
              'notifications.collaboratorsAddError',
              'Collaborator could not be added',
            ),
          ),
        ];
      }
      return state;
    }
    case CollaboratorsActionType.COLLABORATORS_UPDATE_SUCCESS: {
      const { collaborator, role, showNotification } = action.payload;

      if (showNotification) {
        return [
          ...state,
          {
            type: NotificationType.SUCCESS,
            title: i18n.t(
              'notifications.collaboratorsUpdateSuccess.title',
              'New role assigned successfully',
            ),
            message: i18n.t(
              'notifications.collaboratorsUpdateSuccess.message',
              'The new role of {{role}} was successfully assigned to {{name}}.',
              { role, name: collaborator.user.name },
            ),
          },
        ];
      }
      return state;
    }
    case CollaboratorsActionType.COLLABORATORS_UPDATE_ERROR: {
      const { error, showNotification } = action.payload;
      if (showNotification) {
        return [
          ...state,
          createErrorNotification(
            error,
            i18n.t(
              'notifications.collaboratorsUpdateError',
              'Could not edit collaborator',
            ),
          ),
        ];
      } return state;
    }
    case CollaboratorsActionType.COLLABORATORS_MULTI_REASSIGN_SUCCESS: {
      const { successCount, failuresCount, newOwnerName } = action.payload;
      const notifications = [];
      if (successCount > 0) {
        const successMessage = i18n.t(
          'notifications.multiReassignSuccess.message',
          '{{count}} Repositories were reassigned to {{name}}.',
          { count: successCount, name: newOwnerName },
        );
        const successNotification = {
          type: NotificationType.SUCCESS,
          title: i18n.t('notifications.multiReassignSuccess.title', 'Reassignment Completed'),
          message: successMessage,
        };
        notifications.push(successNotification);
      }
      if (failuresCount > 0) {
        const repositoryWord = failuresCount > 1 ? 'repositories' : 'repository';
        const failureTitle = i18n.t(
          'notifications.multiReassignFailure.title',
          'Failed to reassign {{count}} {{repositoryWord}}.',
          { count: failuresCount, repositoryWord },
        );
        const failureMessage = i18n.t(
          'notifications.multiReassignFailure.message',
          'Please confirm user has the correct permissions to become an owner on the selected {{repositoryWord}}.',
          { repositoryWord },
        );
        const failureNotification = {
          type: NotificationType.ERROR,
          title: failureTitle,
          message: failureMessage,
        };
        notifications.push(failureNotification);
      }
      return [...state, ...notifications];
    }
    case CollaboratorsActionType.COLLABORATORS_REMOVE_SUCCESS: {
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.collaboratorsRemoveSuccess.title',
            'Collaborator removed',
          ),
          message: i18n.t(
            'notifications.collaboratorsRemoveSuccess.message',
            'Collaborator successfully removed.',
          ),
        },
      ];
    }
    case CollaboratorsActionType.COLLABORATORS_REMOVE_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.collaboratorsRemoveError',
            'Could not delete collaborator.',
          ),
        ),
      ];
    }
    case CollaboratorsActionType.COLLABORATORS_RENEW_SUCCESS: {
      const { collaborator } = action.payload;
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.collaboratorRenewSuccess.title',
            'Collaborator renewed successfully',
          ),
          message: i18n.t(
            'notifications.collaboratorRenewSuccess.message',
            'The collaboration was successfully renewed for {{name}}.',
            { name: collaborator.user.name },
          ),
        },
      ];
    }
    case CollaboratorsActionType.COLLABORATORS_RENEW_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.collaboratorRenewError',
            'Could not renew collaborator',
          ),
        ),
      ];
    }
    case ReleaseNotesActionType.RELEASE_NOTES_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.releaseNotesGetError',
            'Could not get release notes.',
          ),
        ),
      ];
    }
    case TagsActionType.TAGS_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.tagsGetError',
            'Could not get tags',
          ),
        ),
      ];
    }
    case TagsActionType.TAGS_CREATE_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.tagsCreateError',
            'Could not create tag',
          ),
        ),
      ];
    }
    case TagsActionType.TAGS_UPDATE_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.tagsUpdateError',
            'Could not update tag',
          ),
        ),
      ];
    }
    case BusinessesActionType.BUSINESSES_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.businessesGetError',
            'Could not get businesses',
          ),
        ),
      ];
    }
    case SearchActionType.SEARCH_GET_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.searchGetError',
            'Could not search items',
          ),
        ),
      ];
    }
    case TagsActionType.REPOSITORY_TAGS_MAPPING_CREATE_ERROR: {
      const { errors, tags } = action.payload;
      return [
        ...state,
        createErrorNotification(
          errors[0],
          i18n.t(
            'notifications.repositoryTagsMappingCreateError',
            'Failed to add {{failed}} of {{total}} tags',
            {
              failed: errors.length,
              total: tags.length,
            },
          ),
        ),
      ];
    }
    case TagsActionType.REPOSITORY_TAGS_MAPPING_DELETE_ERROR: {
      const { errors, tags } = action.payload;
      return [
        ...state,
        createErrorNotification(
          errors[0],
          i18n.t(
            'notifications.repositoryTagsMappingDeleteError',
            'Failed to remove {{failed}} of {{total}} tags',
            {
              failed: errors.length,
              total: tags.length,
            },
          ),
        ),
      ];
    }

    case RolesActionTypes.ROLE_CREATE_SUCCESS: {
      const { role } = action.payload;
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.roleCreateSuccess.title',
            'Created {{roleName}} Role Successfully',
            { roleName: role.name },
          ),
          message: i18n.t('notifications.roleCreateSuccess.message', ''),
        },
      ];
    }

    case RolesActionTypes.ROLE_UPDATE_SUCCESS: {
      const { role } = action.payload;
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.roleUpdateSuccess.title',
            'Updated {{roleName}} Role Successfully',
            { roleName: role.name },
          ),
          message: i18n.t('notifications.roleUpdateSuccess.message', ''),
        },
      ];
    }

    case RolesActionTypes.ROLE_CREATE_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.roleCreateError',
            'Failed to create Role',
          ),
        ),
      ];
    }

    case RolesActionTypes.ROLE_UPDATE_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.roleUpdateError',
            'Failed to update Role',
          ),
        ),
      ];
    }

    case RolesActionTypes.ROLES_ASSIGN_SUCCESS: {
      const { roles } = action.payload;
      const roleNames = roles.map((r: Role) => r.name).join(', ');
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.rolesAssignSuccess.title',
            'Successfully assigned {{roleNames}} roles',
            { roleNames },
          ),
          message: i18n.t('notifications.rolesAssignSuccess.message', ''),
        },
      ];
    }

    case RolesActionTypes.ROLES_REMOVE_SUCCESS: {
      const { roles } = action.payload;
      const roleNames = roles.map((r: Role) => r.name).join(', ');
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.rolesRemoveSuccess.title',
            'Successfully unassigned {{roleNames}} roles',
            { roleNames },
          ),
          message: i18n.t('notifications.rolesRemoveSuccess.message', ''),
        },
      ];
    }

    case RolesActionTypes.ROLES_ASSIGN_ERROR: {
      const { error, failed } = action.payload;
      const roleNames = failed.map((r: Role) => r.name).join(', ');

      return [
        ...state,
        createErrorNotification(
          error[0],
          i18n.t(
            'notifications.rolesAssignError',
            'Failed to assign {{roleNames}} roles',
            { roleNames },
          ),
        ),
      ];
    }

    case RolesActionTypes.ROLES_REMOVE_ERROR: {
      const { error, failed } = action.payload;
      const roleNames = failed.map((r: Role) => r.name).join(', ');

      return [
        ...state,
        createErrorNotification(
          error[0],
          i18n.t(
            'notifications.rolesRemoveError',
            'Failed to unassign {{roleNames}} roles',
            { roleNames },
          ),
        ),
      ];
    }

    case RepositoryItemsActionType.REPOSITORY_ITEMS_DOWNLOAD_SELECTED_SUCCESS: {
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.repositoryItemDownloadSelectedSuccess.title',
            'Your download will start shortly',
          ),
          message: i18n.t('notifications.repositoryItemDownloadSelectedSuccess.message', ''),
        },
      ];
    }

    case RepositoryItemsActionType.REPOSITORY_ITEMS_DOWNLOAD_SELECTED_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoryItemDownloadSelectedError',
            'Failed to download selected items',
          ),
        ),
      ];
    }

    case UploadsActionType.UPLOADS_FOLDER_ERROR: {
      const { error, folderName } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.uploadsFolderError',
            'Failed to upload {{folderName}}',
            { folderName: folderName || '' },
          ),
        ),
      ];
    }

    case RepositoriesActionType.REPOSITORY_UPDATE_SUCCESS:
    case TagsActionType.REPOSITORY_TAGS_MAPPING_DELETE_SUCCESS:
    case TagsActionType.REPOSITORY_TAGS_MAPPING_CREATE_SUCCESS: {
      const { showNotification } = action.payload;

      if (showNotification) {
        return [
          ...state,
          {
            type: NotificationType.SUCCESS,
            title: i18n.t(
              'notifications.repositoryUpdateSuccess.title',
              'Repository updated',
            ),
            message: i18n.t(
              'notifications.repositoryUpdateSuccess.message',
              '',
            ),
          },
        ];
      }
      return state;
    }

    case RepositoriesActionType.REPOSITORY_UPDATE_ERROR: {
      const { error } = action.payload;

      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.repositoryUpdateError',
            'Failed to update the repository or tags',
          ),
        ),
      ];
    }

    case UploadsActionType.UPLOADS_FILE_ERROR: {
      const { error } = action.payload;

      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.uploadsFileError',
            'File Upload Error',
          ),
        ),
      ];
    }

    case UploadsActionType.UPLOADS_NAME_VALIDATE_ERROR: {
      const { name } = action.payload;
      const formattedName = name.replace(/ /g, '\u00A0');
      return [
        ...state,
        {
          type: NotificationType.ERROR,
          title: i18n.t(
            'notifications.validateNameError.title',
            'File Upload Error',
          ),
          message: i18n.t(
            'notifications.validateNameError.message',
            'File name is invalid: "{{fileName}}"',
            { fileName: formattedName },
          ),
        },
      ];
    }

    case UsersActionType.USERS_SELECTED_BLOCK_SUCCESS: {
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.userBlockSuccess.title',
            'This user has been blocked',
          ),
          message: i18n.t('notifications.userBlockSuccess.message', ''),
        },
      ];
    }

    case UsersActionType.USERS_SELECTED_BLOCK_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.userBlockError',
            'Failed to block this user',
          ),
        ),
      ];
    }

    case UsersActionType.USERS_SELECTED_UNBLOCK_SUCCESS: {
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.userUnblockSuccess.title',
            'This user has been unblocked',
          ),
          message: i18n.t('notifications.userUnblockSuccess.message', ''),
        },
      ];
    }

    case UsersActionType.USERS_SELECTED_UNBLOCK_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.userUnblockError',
            'Failed to unblock this user',
          ),
        ),
      ];
    }

    case CollaboratorsActionType.COLLABORATORS_INVITE_SUCCESS: {
      return [
        ...state,
        {
          type: NotificationType.SUCCESS,
          title: i18n.t(
            'notifications.collabInviteSuccess.title',
            'Successfully sent email invitation to the user',
          ),
          message: i18n.t('notifications.collabInviteSuccess.message', ''),
        },
      ];
    }

    case CollaboratorsActionType.COLLABORATORS_INVITE_ERROR: {
      const { error } = action.payload;
      return [
        ...state,
        createErrorNotification(
          error,
          i18n.t(
            'notifications.collabInviteError',
            'Failed to send email invitation',
          ),
        ),
      ];
    }

    case RepositoryExportZipActionType.REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_FAILURE: {
      const { error } = action.payload as { error: MetadataErrorResponse };
      return [
        ...state,
        {
          type: NotificationType.ERROR,
          title: i18n.t(
            'notifications.repoExportDownloadZipFailure.title',
            'Failed to download repository zip file',
          ),
          message: i18n.t(error.description),
        },
      ];
    }

    case SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_FAILURE: {
      const { error } = action.payload as { error: MetadataErrorResponse };
      return [
        ...state,
        {
          type: NotificationType.ERROR,
          title: i18n.t(
            'notifications.snapshotExportSegmentDownloadFailure.title',
            'Failed to download snapshot segment',
          ),
          message: i18n.t(error.description),
        },
      ];
    }

    case SnapshotsActionType.SNAPSHOT_CREATE_ERROR: {
      const { error } = action.payload as { error: MetadataErrorResponse };
      return [
        ...state,
        {
          type: NotificationType.ERROR,
          title: i18n.t(
            'notifications.snapshotCreateError.title',
            'Failed to create repository snapshot',
          ),
          message: i18n.t(error.description),
        },
      ];
    }

    case SnapshotsActionType.SNAPSHOT_EXPORT_CREATE_ERROR: {
      const { error } = action.payload as { error: MetadataErrorResponse };
      return [
        ...state,
        {
          type: NotificationType.ERROR,
          title: i18n.t(
            'notifications.snapshotExportCreateError.title',
            'Failed to create repository snapshot segments',
          ),
          message: i18n.t(error.description),
        },
      ];
    }

    default:
      return state;
  }
}
