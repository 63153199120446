/* eslint-disable max-len */
/** @module components/LandingPage */
import React from 'react';
import BlockedIcon from '@material-ui/icons/ReportProblemOutlined';
import { ReactComponent as FolderIcon } from 'assets/icons/create-new-folder.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import {
  Button,
  Card,
  Carousel,
  Row,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import TestimonyCard from '../TestimonyCard';
import Footer from '../Footer';
import Header from '../Header/container';
import { Props } from './types';

function LandingPage(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { blocked } = props;

  // storing link to redirect after login
  if (window.location.pathname !== '/' && window.location.pathname !== '/auth/login') {
    localStorage.setItem('fromPath', `${window.location.pathname}${window.location.search}`);
  }

  return (
    <div className="LandingPage">
      <Header />
      <div className="carouselContainer">
        <Carousel
          autoplay
          autoplaySpeed={10000}
          effect="fade"
        >
          <Row className="carousel car1" justify="center">
            <Col className="carouselContent" span={12}>
              <h1 className="carouselText">
                {t('LandingPage.carousel1.title', 'A SECURE PLATFORM TO MANAGE DOCUMENT STORAGE')}
              </h1>
              <h2 className="carouselText">
                {t('LandingPage.carousel1.body', 'Store & share documents while staying compliant with license & regulation requirements.')}
              </h2>
            </Col>
          </Row>

          <Row className="carousel car2" justify="center">
            <Col className="carouselContent" span={12}>
              <h1 className="carouselText">
                {t('LandingPage.carousel2.title', 'SECURE DOCUMENT STORAGE')}
              </h1>
              <h2 className="carouselText">
                {t('LandingPage.carousel2.body', 'Assign the necessary protections & licenses to your repositories to ensure proper protocols are being followed within your team.')}
              </h2>
            </Col>
          </Row>

          <Row className="carousel car3" justify="center">
            <Col className="carouselContent" span={12}>
              <h1 className="carouselText">
                {t('LandingPage.carousel3.title', 'SHARE INTERNALLY OR EXTERNALLY')}
              </h1>
              <h2 className="carouselText">
                {t('LandingPage.carousel3.body', 'Invite users to be collaborators on your repositories. Work with external customers with secure file storage.')}
              </h2>
            </Col>
          </Row>
        </Carousel>
        <div className="cardContainer">
          {blocked ? (
            <Card className="blockedUserCard" data-test-id="blocked-user-card">
              <BlockedIcon className="blockedIcon" />
              <div>
                {t('LandingPage.blockedUserText', 'Your REDshare account has been blocked. Please contact your people leader.')}
              </div>
            </Card>
          ) : (
            <Card className="loginCard" data-test-id="login-card">
              <h2>{t('LandingPage.loginTitle', 'Login')}</h2>
              <h3>{t('LandingPage.loginGreeting', 'Thank you for using REDshare.')}</h3>
              <Button
                className="loginButton"
                href="/auth/login"
                type="primary"
              >
                {t('LandingPage.loginButton', 'LOGIN TO REDSHARE')}
              </Button>
            </Card>
          )}
        </div>
      </div>
      <div className="pageStripe">
        <h1 className="stripeText">{t('LandingPage.pageStripe', 'New Features')}</h1>
      </div>
      <div className="content">
        <Row className="featureCardContainer" justify="center" gutter={36}>
          <Col span={9}>
            <Card
              className="featureCard"
              title={(
                <div className="featureTitleContent">
                  <FolderIcon className="featureIcon" />
                  <h2 className="featureTitle">{t('LandingPage.featureCard1.title', 'Folder Upload')}</h2>
                </div>
              )}
              type="inner"
            >
              {t('LandingPage.featureCard1.body', 'The REDshare Web interface now includes an option for uploading folders and their content into a repository. Simply select Upload -> Folder to begin. For more control of bulk content, consider trying out the REDshare Desktop Application available through MyTech.')}
            </Card>
          </Col>
          <Col span={9}>
            <Card
              className="featureCard"
              title={(
                <div className="featureTitleContent">
                  <DownloadIcon className="featureIcon" />
                  <h2 className="featureTitle">{t('LandingPage.featureCard2.title', 'Bulk Download')}</h2>
                </div>
              )}
              type="inner"
            >
              {t('LandingPage.featureCard2.body', 'Did you know that you can select multiple files and download them all the same time? The REDshare Web interface allows users to check multiple files in a repository and download them in a single archive file. For more control of your bulk content, consider using the REDshare Desktop Application available through MyTech.')}
            </Card>
          </Col>
        </Row>
        <TestimonyCard />
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
