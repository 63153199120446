/** @module components/LicenseList */
import React, { useEffect } from 'react';
import { Popconfirm, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import TableRefresh from '../TableRefresh';
import { Props } from './types';
import './styles.scss';

function LicenseList(props: Props): JSX.Element {
  const {
    licenses,
    fetchLicenses,
    deleteLicense,
    repository,
    loading,
    refreshing,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (!licenses) {
      fetchLicenses(repository.id);
    }
  }, [licenses, fetchLicenses, repository]);

  return (
    <div className="LicenseList">
      <TableRefresh
        data-test-id="refresh"
        onRefresh={(): void => fetchLicenses(repository.id)}
        refreshing={refreshing}
      />
      <List
        header={<div>{t('LicenseList.Header', 'Licenses')}</div>}
        bordered
        dataSource={licenses}
        renderItem={(item: string): JSX.Element | null => (
          <List.Item
            actions={[
              repository.actions.remove_protections ? (
                <Popconfirm
                  data-test-id="pop-confirm"
                  key={item}
                  title={t('LicenseList.Popconfirm.title', 'Remove License?')}
                  onConfirm={(): void => deleteLicense(repository.id, item)}
                  onCancel={(): null => null}
                  okText={t('LicenseList.Popconfirm.okText', 'Remove')}
                  cancelText={t('LicenseList.Popconfirm.cancelText', 'Cancel')}
                >
                  <DeleteIcon
                    data-test-id="delete-icon"
                    className="delete-icon"
                  />
                </Popconfirm>
              ) : null,
            ]}
          >
            <List.Item.Meta title={item} />
          </List.Item>
        )}
        loading={loading}
        data-test-id="list"
      />
    </div>
  );
}

export default LicenseList;
