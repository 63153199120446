/** @module components/DashboardPage */
import React from 'react';
import AnnouncementsBanner from '../AnnouncementsBanner';
import IEBanner from '../IEBanner';
import DOCBanner from '../DOCBanner';
import DashboardPageHeader from '../DashboardPageHeader';
import DashboardPageFooter from '../DashboardPageFooter';
import { Props } from './types';
import './styles.scss';

function DashboardPage(props: Props): JSX.Element {
  const {
    actions,
    children,
    menu,
    title,
    upLink,
    tags,
    hasBreadcrumbs = true,
    protections,
    business,
    description,
    bannerContent,
    evictionStatus,
  } = props;
  return (
    <div className="DashboardPage">
      <IEBanner data-test-id="ie-banner" />
      <AnnouncementsBanner data-test-id="announcements-banner" bannerContent={bannerContent} />
      <DOCBanner data-test-id="doc-banner" />
      <DashboardPageHeader
        actions={actions}
        menu={menu}
        title={title}
        upLink={upLink}
        tags={tags}
        protections={protections}
        business={business}
        description={description}
        evictionStatus={evictionStatus}
      />
      <div className={hasBreadcrumbs ? 'content' : 'content-without-breadcrumbs'}>
        {children}
      </div>
      <DashboardPageFooter />
    </div>
  );
}

export default DashboardPage;
