/** @module components/ReleaseNotesPage */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import ReactMarkdown from 'react-markdown';
import { history } from 'utilities/history';
import queryString from 'query-string';
import DashboardPage from '../DashboardPage';
import Loader from '../Loader';
import { Props } from './types';
import './styles.scss';

/**
 * Displays the changelog.
 */
function ReleaseNotesPage(props: Props): JSX.Element {
  const {
    getReleaseNotes,
    releaseNotes,
    loading,
    error,
  } = props;
  const { t } = useTranslation();
  useEffect(() => {
    getReleaseNotes();
  }, [getReleaseNotes]);
  if (loading) {
    return <Loader loading data-test-id="loader" />;
  }
  const { search } = history.location;
  const parsedQueryString = queryString.parse(search);
  const goBackUrl = parsedQueryString.goBackUrl ? parsedQueryString.goBackUrl.toString() : null;
  const getUplink = (): string => (goBackUrl || '/');

  return (
    <div className="ReleaseNotesPage">
      <DashboardPage
        title={t('ReleaseNotesPage.title', 'Release Notes')}
        data-test-id="page"
        upLink={getUplink()}
      >
        {error && (
          <div className="error-message" data-test-id="error-message">
            <h2>
              {t('ReleaseNotesPage.errorMessage', 'Something went wrong')}
            </h2>
            <Button
              type="primary"
              onClick={(): void => getReleaseNotes()}
              data-test-id="retry-button"
            >
              {t('ReleaseNotesPage.retry', 'Try Again')}
            </Button>
          </div>
        )}
        {!error && (
          <div className="markdown-container">
            <ReactMarkdown
              source={releaseNotes || ''}
              renderers={{
                link: (p): JSX.Element => (
                  <a
                    href={p.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {p.children}
                  </a>
                ),
              }}
              data-test-id="release-notes"
            />
          </div>
        )}
      </DashboardPage>
    </div>
  );
}

export default ReleaseNotesPage;
