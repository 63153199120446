/** @module store/repositoryItems */
import MetadataService, { Item, ItemDetails, MetadataError } from 'services/metadata';
import { AppDispatch, GlobalState } from 'store/types';
import { updateRepository } from 'store/repositories/actions';
import { copy } from 'utilities/clipboard';
import { wait } from 'utilities/asyncUtilities';
import { cancelUploadOnDeleteRepositoryItem } from 'store/uploads/actions';
import { selectRepositoryItem } from './selectors';
import {
  RepositoryItemsActionType,
  RepositoryItemsGetRequestAction,
  RepositoryItemsGetSuccessAction,
  RepositoryItemsGetErrorAction,
  RepositoryItemsCreateRequestAction,
  RepositoryItemsCreateSuccessAction,
  RepositoryItemsCreateErrorAction,
  RepositoryItemsUpdateRequestAction,
  RepositoryItemsUpdateSuccessAction,
  RepositoryItemsUpdateErrorAction,
  RepositoryItemsDeleteRequestAction,
  RepositoryItemsDeleteSuccessAction,
  RepositoryItemsDeleteErrorAction,
  RepositoryItemsDeleteSelectedRequestAction,
  RepositoryItemsDeleteSelectedSuccessAction,
  RepositoryItemsDeleteSelectedErrorAction,
  RepositoryItemsPreviewGetRequestAction,
  RepositoryItemsPreviewGetSuccessAction,
  RepositoryItemsPreviewGetErrorAction,
  RepositoryItemsUploadUrlGetRequestAction,
  RepositoryItemsUploadUrlGetSuccessAction,
  RepositoryItemsUploadUrlGetErrorAction,
  RepositoryItemsMoveRequestAction,
  RepositoryItemsMoveSuccessAction,
  RepositoryItemsMoveErrorAction,
  RepositoryItemsMoveSelectedRequestAction,
  RepositoryItemsMoveSelectedSuccessAction,
  RepositoryItemsMoveSelectedErrorAction,
  RepositoryItemsSetSelectedAction,
  RepositoryItemsRemoveSelectedAction,
  RepositoryItemsTrashGetRequestAction,
  RepositoryItemsTrashGetSuccessAction,
  RepositoryItemsTrashGetErrorAction,
  RepositoryItemsRestoreRequestAction,
  RepositoryItemsRestoreSuccessAction,
  RepositoryItemsRestoreErrorAction,
  RepositoryItemsClipboardCopyRequestAction,
  RepositoryItemsClipboardCopySuccessAction,
  RepositoryItemsClipboardCopyErrorAction,
  RepositoryItemsRenameRequestAction,
  RepositoryItemsRenameSuccessAction,
  RepositoryItemsRenameErrorAction,
  RepositoryItemsClearSelectionAction,
  RepositoryItemsRestoreSelectedRequestAction,
  RepositoryItemsRestoreSelectedErrorAction,
  RepositoryItemsRestoreSelectedSuccessAction,
  RepositoryItemsDownloadSelectedRequestAction,
  RepositoryItemsDownloadSelectedSuccessAction,
  RepositoryItemsDownloadSelectedErrorAction,
} from './types';

export const getRepositoryItemsRequest = (id: string): RepositoryItemsGetRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_GET_REQUEST,
  payload: {
    id,
  },
});

export const getRepositoryItemsSuccess = (
  item: Item,
  id?: string,
): RepositoryItemsGetSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_GET_SUCCESS,
  payload: {
    item,
    id,
  },
});

export const getRepositoryItemsError = (
  error: Error,
  showNotification = true,
  id?: string,
): RepositoryItemsGetErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_GET_ERROR,
  payload: {
    error,
    showNotification,
    id,
  },
});

export const createRepositoryItemRequest = (): RepositoryItemsCreateRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_CREATE_REQUEST,
});

export const createRepositoryItemSuccess = (
  itemDetails: ItemDetails,
  parentId: string,
  showNotification = true,
): RepositoryItemsCreateSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_CREATE_SUCCESS,
  payload: {
    itemDetails,
    parentId,
    showNotification,
  },
});

export const createRepositoryItemError = (error: Error): RepositoryItemsCreateErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_CREATE_ERROR,
  payload: {
    error,
  },
});

export const updateRepositoryItemRequest = (): RepositoryItemsUpdateRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_UPDATE_REQUEST,
});

export const updateRepositoryItemSuccess = (
  id: string,
  name: string,
  oldParentId: string,
  newParentId: string,
): RepositoryItemsUpdateSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_UPDATE_SUCCESS,
  payload: {
    id,
    name,
    oldParentId,
    newParentId,
  },
});

export const updateRepositoryItemError = (error: Error): RepositoryItemsUpdateErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_UPDATE_ERROR,
  payload: {
    error,
  },
});

export const moveRepositoryItemRequest = (): RepositoryItemsMoveRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_REQUEST,
});

export const moveRepositoryItemSuccess = (
  itemDetails: ItemDetails,
  parentDetails?: ItemDetails,
): RepositoryItemsMoveSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_SUCCESS,
  payload: {
    itemDetails,
    parentDetails,
  },
});

export const moveRepositoryItemError = (
  error: Error,
  itemDetails: ItemDetails,
  exists: boolean,
): RepositoryItemsMoveErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_ERROR,
  payload: {
    error,
    exists,
    itemDetails,
  },
});

export const deleteRepositoryItemRequest = (): RepositoryItemsDeleteRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_REQUEST,
});

export const deleteRepositoryItemSuccess = (
  itemDetails: ItemDetails,
  showNotification = true,
): RepositoryItemsDeleteSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SUCCESS,
  payload: {
    itemDetails,
    showNotification,
  },
});

export const deleteRepositoryItemError = (
  error: Error,
  itemDetails: ItemDetails,
  showNotification = true,
): RepositoryItemsDeleteErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_ERROR,
  payload: {
    error,
    itemDetails,
    showNotification,
  },
});

export const deleteSelectedRepositoryItemsRequest = (
): RepositoryItemsDeleteSelectedRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SELECTED_REQUEST,
});

export const deleteSelectedRepositoryItemsSuccess = (
  itemDetails: ItemDetails[],
): RepositoryItemsDeleteSelectedSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SELECTED_SUCCESS,
  payload: {
    itemDetails,
  },
});

export const deleteSelectedRepositoryItemsError = (
  error: Error[],
  itemDetails: ItemDetails[],
): RepositoryItemsDeleteSelectedErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_DELETE_SELECTED_ERROR,
  payload: {
    error,
    itemDetails,
  },
});

export const getRepositoryItemPreviewRequest = (): RepositoryItemsPreviewGetRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_PREVIEW_GET_REQUEST,
});

export const getRepositoryItemPreviewSuccess = (
  url: string,
): RepositoryItemsPreviewGetSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_PREVIEW_GET_SUCCESS,
  payload: {
    url,
  },
});

export const getRepositoryItemPreviewError = (
  error: Error,
): RepositoryItemsPreviewGetErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_PREVIEW_GET_ERROR,
  payload: {
    error,
  },
});

export const getUploadUrlRequest = (): RepositoryItemsUploadUrlGetRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_UPLOAD_URL_GET_REQUEST,
});

export const getUploadUrlSuccess = (
  url: string,
): RepositoryItemsUploadUrlGetSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_UPLOAD_URL_GET_SUCCESS,
  payload: {
    url,
  },
});

export const getUploadUrlError = (
  error: Error,
): RepositoryItemsUploadUrlGetErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_UPLOAD_URL_GET_ERROR,
  payload: {
    error,
  },
});

export const setSelectedRepositoryItems = (
  itemDetails: ItemDetails[],
): RepositoryItemsSetSelectedAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_SET_SELECTED,
  payload: {
    itemDetails,
  },
});

export const downloadSelectedRepositoryItemsRequest = ():
RepositoryItemsDownloadSelectedRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_DOWNLOAD_SELECTED_REQUEST,
});

export const downloadSelectedRepositoryItemsSuccess = ():
 RepositoryItemsDownloadSelectedSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_DOWNLOAD_SELECTED_SUCCESS,
});

export const downloadSelectedRepositoryItemsError = (
  error: Error,
): RepositoryItemsDownloadSelectedErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_DOWNLOAD_SELECTED_ERROR,
  payload: {
    error,
  },
});

export const removeSelectedRepositoryItem = (
  itemDetails: ItemDetails,
): RepositoryItemsRemoveSelectedAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_REMOVE_SELECTED,
  payload: {
    itemDetails,
  },
});

export const clearAllSelectedRepositoryItem = (): RepositoryItemsClearSelectionAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_CLEAR_SELECTION,
});

export const getRepositoryTrashRequest = (): RepositoryItemsTrashGetRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_TRASH_GET_REQUEST,
});

export const getRepositoryTrashSuccess = (
  repositoryId: string,
  items: Item[],
): RepositoryItemsTrashGetSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_TRASH_GET_SUCCESS,
  payload: {
    repositoryId,
    items,
  },
});

export const getRepositoryTrashError = (
  error: Error,
): RepositoryItemsTrashGetErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_TRASH_GET_ERROR,
  payload: {
    error,
  },
});

export const restoreRepositoryItemRequest = (): RepositoryItemsRestoreRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_REQUEST,
});

export const restoreRepositoryItemSuccess = (
  itemDetails: ItemDetails,
  newParentId: string,
  showNotification = true,
): RepositoryItemsRestoreSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SUCCESS,
  payload: {
    itemDetails,
    newParentId,
    showNotification,
  },
});

export const restoreRepositoryItemError = (
  error: Error,
  itemDetails: ItemDetails,
  showNotification = true,
): RepositoryItemsRestoreErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_ERROR,
  payload: {
    error,
    itemDetails,
    showNotification,
  },
});


export const restoreSelectedRepositoryItemsRequest = ():
  RepositoryItemsRestoreSelectedRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SELECTED_REQUEST,
});

export const restoreSelectedRepositoryItemsSuccess = (
  itemDetails: ItemDetails[],
): RepositoryItemsRestoreSelectedSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SELECTED_SUCCESS,
  payload: {
    itemDetails,
  },
});

export const restoreSelectedRepositoryItemsError = (
  error: Error[],
  itemDetails: ItemDetails[],
): RepositoryItemsRestoreSelectedErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_RESTORE_SELECTED_ERROR,
  payload: {
    error,
    itemDetails,
  },
});

export const clipboardCopyRequest = (): RepositoryItemsClipboardCopyRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_CLIPBOARD_COPY_REQUEST,
});

export const clipboardCopySuccess = (
): RepositoryItemsClipboardCopySuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_CLIPBOARD_COPY_SUCCESS,
});

export const clipboardCopyError = (
  error: Error,
): RepositoryItemsClipboardCopyErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_CLIPBOARD_COPY_ERROR,
  payload: {
    error,
  },
});

export const moveSelectedRepositoryItemsRequest = (
): RepositoryItemsMoveSelectedRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_SELECTED_REQUEST,
});

export const moveSelectedRepositoryItemsSuccess = (
  itemDetails: ItemDetails[],
): RepositoryItemsMoveSelectedSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_SELECTED_SUCCESS,
  payload: {
    itemDetails,
  },
});

export const moveSelectedRepositoryItemsError = (
  error: Error,
): RepositoryItemsMoveSelectedErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_MOVE_SELECTED_ERROR,
  payload: {
    error,
  },
});

export const renameRepositoryItemRequest = (
): RepositoryItemsRenameRequestAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_RENAME_REQUEST,
});

export const renameRepositoryItemSuccess = (
): RepositoryItemsRenameSuccessAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_RENAME_SUCCESS,
});

export const renameRepositoryItemError = (
  error: Error,
): RepositoryItemsRenameErrorAction => ({
  type: RepositoryItemsActionType.REPOSITORY_ITEMS_RENAME_ERROR,
  payload: {
    error,
  },
});

export function getRepositoryItems(folderId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getRepositoryItemsRequest(folderId));
    try {
      const item = await new MetadataService().getFolder(folderId);
      dispatch(getRepositoryItemsSuccess(item, folderId));
    } catch (error) {
      const { response } = (error as MetadataError);
      // when the user does not have right permissions to access the repository
      if (response && response.status === 403) {
        dispatch(getRepositoryItemsSuccess({
          actions: {
            upload: false,
            view: false,
            move: false,
            delete: false,
            download: false,
            preview: false,
          },
        }, folderId));
      } else if (response && response.status === 404) {
        dispatch(getRepositoryItemsError(error, false, folderId));
      } else {
        dispatch(getRepositoryItemsError(error, true, folderId));
      }
    }
  };
}

export function createFolder(name: string, parentId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(createRepositoryItemRequest());
    try {
      const itemDetails = await new MetadataService().createFolder(name, parentId);
      dispatch(createRepositoryItemSuccess(itemDetails, parentId));
    } catch (error) {
      dispatch(createRepositoryItemError(error));
    }
  };
}

export function updateFile(id: string, name: string, oldParentId: string, newParentId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(updateRepositoryItemRequest());
    try {
      await new MetadataService().updateFile(id, name, newParentId);
      dispatch(updateRepositoryItemSuccess(id, name, oldParentId, newParentId));
    } catch (error) {
      dispatch(updateRepositoryItemError(error));
      throw error;
    }
  };
}

export function updateFolder(id: string, name: string, oldParentId: string, newParentId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(updateRepositoryItemRequest());
    try {
      await new MetadataService().updateFolder(id, name, newParentId);
      if (oldParentId !== newParentId) {
        await wait(1000);
      }
      dispatch(updateRepositoryItemSuccess(id, name, oldParentId, newParentId));
    } catch (error) {
      dispatch(updateRepositoryItemError(error));
      throw error;
    }
  };
}

export function updateName(type: string, id: string, name: string, parentId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(renameRepositoryItemRequest());
    try {
      switch (type.toLowerCase()) {
        case 'file':
          await dispatch(updateFile(id, name, parentId, parentId));
          break;
        case 'folder':
          await dispatch(updateFolder(id, name, parentId, parentId));
          break;
        case 'repository':
          await dispatch(updateRepository(id, name));
          break;
        default:
          throw new Error('Unknown file type');
      }
      dispatch(renameRepositoryItemSuccess());
    } catch (error) {
      dispatch(renameRepositoryItemError(error));
    }
  };
}

export function deleteRepositoryItem(item: ItemDetails, callBack?: Function) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(deleteRepositoryItemRequest());
    await dispatch(cancelUploadOnDeleteRepositoryItem(item));
    try {
      await new MetadataService().deleteItem(item.id);
      if (callBack) callBack();
      dispatch(deleteRepositoryItemSuccess(item));
      dispatch(removeSelectedRepositoryItem(item));
    } catch (error) {
      dispatch(deleteRepositoryItemError(error, item));
    }
  };
}

export function deleteSelectedRepositoryItems() {
  return async (dispatch: AppDispatch, getState: () => GlobalState): Promise<void> => {
    const { selected } = getState().items;
    const promises: Promise<void>[] = [];
    const successfulItems: ItemDetails[] = [];
    const failedItems: ItemDetails[] = [];
    const errors: Error[] = [];
    dispatch(deleteSelectedRepositoryItemsRequest());
    selected.forEach((item: ItemDetails) => {
      promises.push((async (): Promise<void> => {
        dispatch(deleteRepositoryItemRequest());
        await dispatch(cancelUploadOnDeleteRepositoryItem(item));
        try {
          await new MetadataService().deleteItem(item.id);
          dispatch(deleteRepositoryItemSuccess(item, false));
          successfulItems.push(item);
        } catch (error) {
          dispatch(deleteRepositoryItemError(error, item, false));
          failedItems.push(item);
          errors.push(error);
        }
      })());
    });
    await Promise.all(promises);
    if (failedItems.length === 0) {
      dispatch(deleteSelectedRepositoryItemsSuccess(selected));
    } else {
      dispatch(deleteSelectedRepositoryItemsError(errors, selected));
    }
    dispatch(setSelectedRepositoryItems(failedItems));
  };
}

export function getFolderUploadUrl(folderId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getUploadUrlRequest());
    try {
      const url = await new MetadataService().getFolderUploadUrl(folderId);
      dispatch(getUploadUrlSuccess(url));
    } catch (error) {
      dispatch(getUploadUrlError(error));
    }
  };
}

export function getFilePreviewUrl(fileId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getRepositoryItemPreviewRequest());
    try {
      const url = await new MetadataService().getFilePreviewUrl(fileId);
      dispatch(getRepositoryItemPreviewSuccess(url));
    } catch (error) {
      dispatch(getRepositoryItemPreviewError(error));
    }
  };
}

export function updateFileParent(
  itemDetails: ItemDetails,
  newParentId: string,
  callBack?: Function,
) {
  return async (dispatch: AppDispatch, getState: () => GlobalState): Promise<void> => {
    dispatch(moveRepositoryItemRequest());
    try {
      if (itemDetails.type === 'file') {
        await dispatch(
          updateFile(itemDetails.id, itemDetails.name, itemDetails.parentId, newParentId),
        );
      } else {
        await dispatch(
          updateFolder(itemDetails.id, itemDetails.name, itemDetails.parentId, newParentId),
        );
      }
      const folder = selectRepositoryItem(getState(), newParentId);
      dispatch(moveRepositoryItemSuccess(itemDetails, folder && folder.details));
      if (callBack) callBack();
    } catch (error) {
      const exists = (error.response && error.response.status === 409);
      dispatch(moveRepositoryItemError(error, itemDetails, exists));
    }
  };
}

export function copyTextToClipboard(text: string | null) {
  return (dispatch: AppDispatch): void => {
    dispatch(clipboardCopyRequest());
    const copySuccessful = copy(text);
    if (copySuccessful) {
      dispatch(clipboardCopySuccess());
    } else {
      dispatch(clipboardCopyError(new Error()));
    }
  };
}

export function downloadSelectedRepositoryItems() {
  return async (dispatch: AppDispatch, getState: () => GlobalState): Promise<void> => {
    dispatch(downloadSelectedRepositoryItemsRequest());
    const { selected } = getState().items;
    const fileIds = selected.map((f) => f.id);
    try {
      const uploadUrl = await new MetadataService().getZipDownloadUrl(fileIds);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = uploadUrl;
      a.setAttribute('download', 'download');
      document.body.appendChild(a);
      a.click();
      dispatch(downloadSelectedRepositoryItemsSuccess());
      dispatch(clearAllSelectedRepositoryItem());
      document.body.removeChild(a);
    } catch (e) {
      dispatch(downloadSelectedRepositoryItemsError(e));
    }
  };
}

export function getRepositoryTrash(repositoryId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getRepositoryTrashRequest());
    try {
      const items = await new MetadataService().getRepositoryTrash(repositoryId);
      dispatch(getRepositoryTrashSuccess(repositoryId, items));
    } catch (error) {
      dispatch(getRepositoryTrashError(error));
    }
  };
}

export function restoreRepositoryItem(itemDetails: ItemDetails, newParentId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(restoreRepositoryItemRequest());
    try {
      const metadataService = new MetadataService();
      if (itemDetails.type === 'file') {
        await metadataService.updateFile(itemDetails.id, itemDetails.name, newParentId);
      } else {
        await metadataService.updateFolder(itemDetails.id, itemDetails.name, newParentId);
      }
      dispatch(restoreRepositoryItemSuccess(itemDetails, newParentId));
    } catch (error) {
      dispatch(restoreRepositoryItemError(error, itemDetails));
    }
  };
}


/**
 * A Compound thunk that restores selected repository items.
 * @param newParentId folder id where the items will be restored
 * @return A thunk action which returns a promise
 */
export function restoreSelectedRepositoryItems(newParentId: string) {
  return async (dispatch: AppDispatch, getState: () => GlobalState): Promise<void> => {
    const { selected } = getState().items;
    const metadataService = new MetadataService();
    const promises: Promise<void>[] = [];
    const failedItems: ItemDetails[] = [];
    const errors: Error[] = [];
    dispatch(restoreSelectedRepositoryItemsRequest());
    selected.forEach((item: ItemDetails) => {
      dispatch(restoreRepositoryItemRequest());
      promises.push(
        (item.type.toLowerCase() === 'file')
          ? metadataService.updateFile(item.id, item.name, newParentId)
            .then(() => {
              dispatch(restoreRepositoryItemSuccess(item, newParentId, false));
            })
            .catch((error) => {
              dispatch(restoreRepositoryItemError(error, item, false));
              failedItems.push(item);
              errors.push(error);
            })
          : metadataService.updateFolder(item.id, item.name, newParentId)
            .then(() => {
              dispatch(restoreRepositoryItemSuccess(item, newParentId, false));
            })
            .catch((error) => {
              dispatch(restoreRepositoryItemError(error, item, false));
              failedItems.push(item);
              errors.push(error);
            }),
      );
    });
    return Promise.all(promises).then(() => {
      if (failedItems.length === 0) {
        dispatch(restoreSelectedRepositoryItemsSuccess(selected));
      } else {
        dispatch(restoreSelectedRepositoryItemsError(errors, selected));
      }
      dispatch(setSelectedRepositoryItems(failedItems));
    });
  };
}

export function moveSelectedRepositoryItems(newParentId: string) {
  return async (dispatch: AppDispatch, getState: () => GlobalState): Promise<void> => {
    const metadataService = new MetadataService();
    const { selected } = getState().items;
    const promises: Promise<void>[] = [];
    dispatch(moveSelectedRepositoryItemsRequest());
    selected.forEach((itemDetails) => {
      dispatch(updateRepositoryItemRequest());
      promises.push(
        (itemDetails.type === 'file')
          ? metadataService.updateFile(itemDetails.id, itemDetails.name, newParentId)
            .then(() => {
              dispatch(updateRepositoryItemSuccess(
                itemDetails.id,
                itemDetails.name,
                itemDetails.parentId,
                newParentId,
              ));
            })
            .catch((error) => {
              dispatch(updateRepositoryItemError(error));
              throw error;
            })
          : metadataService.updateFolder(itemDetails.id, itemDetails.name, newParentId)
            .then(() => {
              dispatch(updateRepositoryItemSuccess(
                itemDetails.id,
                itemDetails.name,
                itemDetails.parentId,
                newParentId,
              ));
            })
            .catch((error) => {
              dispatch(updateRepositoryItemError(error));
              throw error;
            }),
      );
    });
    return Promise.all(promises)
      .then(() => {
        dispatch(moveSelectedRepositoryItemsSuccess(selected));
        dispatch(setSelectedRepositoryItems([]));
      })
      .catch((error) => {
        dispatch(moveSelectedRepositoryItemsError(error));
        dispatch(setSelectedRepositoryItems([]));
      });
  };
}
