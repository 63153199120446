/** @module components/ConfirmNavigationModal */

import { Button, Modal } from 'antd';
import React from 'react';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutline';
import { useTranslation } from 'react-i18next';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Props } from './types';
import './styles.scss';

export default function ConfirmNavigationModal(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    when,
    title,
    content,
    okText,
    cancelText,
    afterCancel,
  } = props;

  return (
    <NavigationPrompt when={when}>
      {({ onConfirm, onCancel }): JSX.Element => (
        <Modal
          visible
          footer={null}
          className="ant-modal-confirm ConfirmNavigationModal"
          data-test-id="modal"
          closable
          onCancel={(): void => {
            onCancel();
            if (afterCancel) afterCancel();
          }}
        >
          <>
            <div className="container">
              <ErrorOutlinedIcon className="icon" />
              <div className="content">
                <div className="title">
                  {title}
                </div>
                <div className="description">
                  {content}
                </div>
              </div>
            </div>
            <div className="modal-buttons">
              <Button
                type="ghost"
                className="button"
                data-test-id="cancel-button"
                onClick={(): void => {
                  onCancel();
                  if (afterCancel) afterCancel();
                }}
              >
                {cancelText || t('ConfirmNavigationModal.cancelText', 'STAY ON PAGE')}
              </Button>
              <Button
                className="button"
                onClick={onConfirm}
                type="primary"
              >
                {okText || t('ConfirmNavigationModal.okText', 'LEAVE')}
              </Button>
            </div>
          </>
        </Modal>
      )}
    </NavigationPrompt>
  );
}
