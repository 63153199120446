/** @module store/repositories */
import { Action } from 'redux';
import { MetadataErrorResponse, Repository } from 'services/metadata';
import { EvictionStatus } from 'services/metadata/types/EvictionStatus';

export enum RepositoriesActionType {
  REPOSITORIES_GET_REQUEST= 'REPOSITORIES_GET_REQUEST',
  REPOSITORIES_GET_SUCCESS = 'REPOSITORIES_GET_SUCCESS',
  REPOSITORIES_GET_ERROR = 'REPOSITORIES_GET_ERROR',
  REPOSITORY_CREATE_REQUEST = 'REPOSITORIES_CREATE_REQUEST',
  REPOSITORY_CREATE_SUCCESS = 'REPOSITORIES_CREATE_SUCCESS',
  REPOSITORY_CREATE_ERROR = 'REPOSITORIES_CREATE_ERROR',
  REPOSITORY_UPDATE_REQUEST = 'REPOSITORIES_UPDATE_REQUEST',
  REPOSITORY_UPDATE_SUCCESS = 'REPOSITORIES_UPDATE_SUCCESS',
  REPOSITORY_UPDATE_ERROR = 'REPOSITORIES_UPDATE_ERROR',
  REPOSITORY_EVICT_REQUEST = 'REPOSITORIES_EVICT_REQUEST',
  REPOSITORY_EVICT_SUCCESS = 'REPOSITORIES_EVICT_SUCCESS',
  REPOSITORY_EVICT_ERROR = 'REPOSITORIES_EVICT_ERROR',
  REPOSITORY_GET_EVICTION_STATUS_REQUEST = 'REPOSITORY_GET_EVICTION_STATUS_REQUEST',
  REPOSITORY_GET_EVICTION_STATUS_SUCCESS = 'REPOSITORY_GET_EVICTION_STATUS_SUCCESS',
  REPOSITORY_GET_EVICTION_STATUS_ERROR = 'REPOSITORY_GET_EVICTION_STATUS_ERROR',
  MULTI_REPOSITORY_GET_EVICTION_STATUS_REQUEST = 'MULTI_REPOSITORY_GET_EVICTION_STATUS_REQUEST',
  MULTI_REPOSITORY_GET_EVICTION_STATUS_SUCCESS = 'MULTI_REPOSITORY_GET_EVICTION_STATUS_SUCCESS',
  MULTI_REPOSITORY_GET_EVICTION_STATUS_ERROR = 'MULTI_REPOSITORY_GET_EVICTION_STATUS_ERROR',
  REPOSITORY_RESTORE_REQUEST = 'REPOSITORIES_RESTORE_REQUEST',
  REPOSITORY_RESTORE_SUCCESS = 'REPOSITORIES_RESTORE_SUCCESS',
  REPOSITORY_RESTORE_ERROR = 'REPOSITORIES_RESTORE_ERROR',
  REPOSITORY_DELETE_REQUEST = 'REPOSITORIES_DELETE_REQUEST',
  REPOSITORY_DELETE_SUCCESS = 'REPOSITORIES_DELETE_SUCCESS',
  REPOSITORY_DELETE_ERROR = 'REPOSITORIES_DELETE_ERROR',
  REPOSITORY_DELETE_SELECTED_REQUEST = 'REPOSITORIES_DELETE_SELECTED_REQUEST',
  REPOSITORY_DELETE_SELECTED_SUCCESS = 'REPOSITORIES_DELETE_SELECTED_SUCCESS',
  REPOSITORY_DELETE_SELECTED_ERROR = 'REPOSITORIES_DELETE_SELECTED_ERROR',
  REPOSITORY_RESTORE_SELECTED_REQUEST = 'REPOSITORIES_RESTORE_SELECTED_REQUEST',
  REPOSITORY_RESTORE_SELECTED_SUCCESS = 'REPOSITORIES_RESTORE_SELECTED_SUCCESS',
  REPOSITORY_RESTORE_SELECTED_ERROR = 'REPOSITORIES_RESTORE_SELECTED_ERROR',
  REPOSITORIES_TRASH_GET_REQUEST = 'REPOSITORIES_TRASH_GET_REQUEST',
  REPOSITORIES_TRASH_GET_SUCCESS = 'REPOSITORIES_TRASH_GET_SUCCESS',
  REPOSITORIES_TRASH_GET_ERROR = 'REPOSITORIES_TRASH_GET_ERROR',
  REPOSITORIES_SET_SELECTED = 'REPOSITORIES_SET_SELECTED',
  REPOSITORIES_SET_SELECTED_TO_REASSIGN = 'REPOSITORIES_SET_SELECTED_TO_REASSIGN',
}

export type RepositoriesGetRequestAction = Action<
  RepositoriesActionType.REPOSITORIES_GET_REQUEST
>;

export interface RepositoriesGetSuccessAction extends Action<
  RepositoriesActionType.REPOSITORIES_GET_SUCCESS
> {
  payload: {
    repositories: Repository[];
  };
}

export interface RepositoriesGetErrorAction extends Action<
  RepositoriesActionType.REPOSITORIES_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RepositoryCreateRequestAction = Action<
  RepositoriesActionType.REPOSITORY_CREATE_REQUEST
>;

export interface RepositoryCreateSuccessAction extends Action<
  RepositoriesActionType.REPOSITORY_CREATE_SUCCESS
> {
  payload: {
    response: { id: string; name: string };
  };
}

export interface RepositoryCreateErrorAction extends Action<
  RepositoriesActionType.REPOSITORY_CREATE_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RepositoryUpdateRequestAction = Action<
  RepositoriesActionType.REPOSITORY_UPDATE_REQUEST
>;

export interface RepositoryUpdateSuccessAction extends Action<
  RepositoriesActionType.REPOSITORY_UPDATE_SUCCESS
> {
  payload: {
    id: string;
    name: string;
    fullTextSearch?: boolean;
    showNotification?: boolean;
  };
}

export interface RepositoryUpdateErrorAction extends Action<
  RepositoriesActionType.REPOSITORY_UPDATE_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RepositoryEvictRequestAction = Action<
RepositoriesActionType.REPOSITORY_EVICT_REQUEST
>;

export interface RepositoryEvictSuccessAction extends Action<
RepositoriesActionType.REPOSITORY_EVICT_SUCCESS
> {
    payload: {
        id: string;
        evicted: boolean;
    };
}

export interface RepositoryEvictErrorAction extends Action<
RepositoriesActionType.REPOSITORY_EVICT_ERROR
> {
  payload: {
    error: MetadataErrorResponse;
  };
}

export type GetRepositoryEvictionStatusRequestAction = Action<
  RepositoriesActionType.REPOSITORY_GET_EVICTION_STATUS_REQUEST
>;

export interface GetRepositoryEvictionStatusSuccessAction extends Action<
  RepositoriesActionType.REPOSITORY_GET_EVICTION_STATUS_SUCCESS
> {
  payload: {
    repoId: string;
    status: EvictionStatus;
    showNotification?: boolean;
  };
}

export interface GetRepositoryEvictionStatusErrorAction extends Action<
  RepositoriesActionType.REPOSITORY_GET_EVICTION_STATUS_ERROR
> {
  payload: {
    error: MetadataErrorResponse;
  };
}


export type GetMultiRepositoryEvictionStatusRequestAction = Action<
RepositoriesActionType.MULTI_REPOSITORY_GET_EVICTION_STATUS_REQUEST
>;

export type GetMultiRepositoryEvictionStatusSuccessAction = Action<
RepositoriesActionType.MULTI_REPOSITORY_GET_EVICTION_STATUS_SUCCESS
>;

export interface GetMultiRepositoryEvictionStatusErrorAction extends Action<
RepositoriesActionType.MULTI_REPOSITORY_GET_EVICTION_STATUS_ERROR
> {
  payload: {
    error: MetadataErrorResponse;
  };
}


export type RepositoryRestoreRequestAction = Action<
  RepositoriesActionType.REPOSITORY_RESTORE_REQUEST
>;

export interface RepositoryRestoreSuccessAction extends Action<
  RepositoriesActionType.REPOSITORY_RESTORE_SUCCESS
> {
  payload: {
    id: string;
    name: string;
    showNotification: boolean;
  };
}

export interface RepositoryRestoreErrorAction extends Action<
  RepositoriesActionType.REPOSITORY_RESTORE_ERROR
> {
  payload: {
    error: Error;
    showNotification: boolean;
  };
}

export type RepositoryRestoreSelectedRequestAction = Action<
  RepositoriesActionType.REPOSITORY_RESTORE_SELECTED_REQUEST
>;

export interface RepositoryRestoreSelectedSuccessAction extends Action<
  RepositoriesActionType.REPOSITORY_RESTORE_SELECTED_SUCCESS
> {
  payload: {
    repositories: Repository[];
  };
}

export interface RepositoryRestoreSelectedErrorAction extends Action<
  RepositoriesActionType.REPOSITORY_RESTORE_SELECTED_ERROR
> {
  payload: {
    errors: Error[];
    repositories: Repository[];
  };
}

export type RepositoryDeleteRequestAction = Action<
  RepositoriesActionType.REPOSITORY_DELETE_REQUEST
>;

export interface RepositoryDeleteSuccessAction extends Action<
  RepositoriesActionType.REPOSITORY_DELETE_SUCCESS
> {
  payload: {
    repository: Repository;
    showNotification: boolean;
  };
}

export interface RepositoryDeleteErrorAction extends Action<
  RepositoriesActionType.REPOSITORY_DELETE_ERROR
> {
  payload: {
    error: Error;
    showNotification: boolean;
  };
}

export type RepositoryDeleteSelectedRequestAction = Action<
  RepositoriesActionType.REPOSITORY_DELETE_SELECTED_REQUEST
>;

export interface RepositoryDeleteSelectedSuccessAction extends Action<
  RepositoriesActionType.REPOSITORY_DELETE_SELECTED_SUCCESS
> {
  payload: {
    repositories: Repository[];
  };
}

export interface RepositoryDeleteSelectedErrorAction extends Action<
  RepositoriesActionType.REPOSITORY_DELETE_SELECTED_ERROR
> {
  payload: {
    errors: Error[];
    repositories: Repository[];
  };
}

export type RepositoriesGetTrashRequestAction = Action<
  RepositoriesActionType.REPOSITORIES_TRASH_GET_REQUEST
>;

export interface RepositoriesGetTrashSuccessAction extends Action<
  RepositoriesActionType.REPOSITORIES_TRASH_GET_SUCCESS
> {
  payload: {
    repositories: Repository[];
  };
}

export interface RepositoriesGetTrashErrorAction extends Action<
  RepositoriesActionType.REPOSITORIES_TRASH_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export interface RepositoriesSetSelectedAction extends Action<
  RepositoriesActionType.REPOSITORIES_SET_SELECTED
> {
  payload: {
    repositories: Repository[];
  };
}

export interface RepositoriesSetSelectedToReassignAction extends Action<
  RepositoriesActionType.REPOSITORIES_SET_SELECTED_TO_REASSIGN
> {
  payload: {
    repositories: Repository[];
  };
}

export interface State {
  entries: Repository[];
  selected: Repository[];
  selectedToReassign: Repository[];
  trash: Repository[];
  lastUpdated?: Date;
  evictions: {
    statuses: { [repoId: string]: EvictionStatus };
    lastEvictedRepositoryId?: string;
    lastEvictionDate?: Date;
  };
}

export type RepositoriesAction = RepositoriesGetRequestAction
                                | RepositoriesGetSuccessAction
                                | RepositoriesGetErrorAction
                                | RepositoryCreateRequestAction
                                | RepositoryCreateSuccessAction
                                | RepositoryCreateErrorAction
                                | RepositoryDeleteRequestAction
                                | RepositoryDeleteSuccessAction
                                | RepositoryDeleteErrorAction
                                | RepositoryDeleteSelectedRequestAction
                                | RepositoryDeleteSelectedSuccessAction
                                | RepositoryDeleteSelectedErrorAction
                                | RepositoryUpdateRequestAction
                                | RepositoryUpdateSuccessAction
                                | RepositoryUpdateErrorAction
                                | RepositoryEvictRequestAction
                                | RepositoryEvictSuccessAction
                                | RepositoryEvictErrorAction
                                | GetRepositoryEvictionStatusRequestAction
                                | GetRepositoryEvictionStatusSuccessAction
                                | GetRepositoryEvictionStatusErrorAction
                                | GetMultiRepositoryEvictionStatusSuccessAction
                                | GetMultiRepositoryEvictionStatusSuccessAction
                                | GetMultiRepositoryEvictionStatusErrorAction
                                | RepositoryRestoreRequestAction
                                | RepositoryRestoreSuccessAction
                                | RepositoryRestoreErrorAction
                                | RepositoryRestoreSelectedRequestAction
                                | RepositoryRestoreSelectedSuccessAction
                                | RepositoryRestoreSelectedErrorAction
                                | RepositoriesGetTrashRequestAction
                                | RepositoriesGetTrashSuccessAction
                                | RepositoriesGetTrashErrorAction
                                | RepositoriesSetSelectedAction;
