/** @module components/Breadcrumb */
import React from 'react';
import {
  Breadcrumb as AntBreadcrumb,
  Menu,
} from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { appPaths } from 'utilities/routes';
import { truncateFileName } from 'utilities/file';
import { BreadcrumbData, Props } from './types';
import './styles.scss';

/**
 * Shows breadcrumb links.
 * @param props The props
 * @return a JSX Element
 */
function Breadcrumb(props: Props): JSX.Element | null {
  const { pathname, repository, folder } = props;
  const { t } = useTranslation();
  const basicBreadcrumbs: BreadcrumbData[] = [];
  const folderBreadcrumbs: BreadcrumbData[] = [];
  const extraFolderBreadcrubs: BreadcrumbData[] = [];
  const paths = pathname.split('/');

  /**
   * Returns the path at the given depth from the current pathname. Paths are
   * separated by a forward slash.
   *
   * Example:
   *   Depth:     0           1           2       3        4
   *   Path:  :baseUrl/my-repositories/:repoId/folder/:folderId
   *
   *   getPathAtDepth(3) = :baseUrl/my-repositories/:repoId/folder
   *
   * @param depth The depth of the path
   * @return The path at the given depth
   */
  const getPathAtDepth = (depth: number): string => paths.slice(0, depth + 1).join('/');

  /**
   * Adds a breadcrumb with the given name and path depth.
   * @param name The text to display
   * @param depth The depth of the path
   */
  const addBreadcrumb = (name: string, depth: number): void => {
    basicBreadcrumbs.push({ name, path: getPathAtDepth(depth) });
  };

  /**
   * Adds breadcrumbs for the current folder and up to two ascendent folders. If
   * the folder has more than two ascendent folders an ellipsis is added. The
   * repository does not count as a folder.
   */
  const addFolderBreadcrumbs = (): void => {
    const ancestors = folder && folder.ancestors && folder.ancestors.slice().reverse();
    if (ancestors) {
      const folderPath = getPathAtDepth(3);
      if (ancestors.length > 4) {
        for (let i = 1; i < ancestors.length - 3; i += 1) {
          extraFolderBreadcrubs.push({
            name: truncateFileName(ancestors[i].name),
            path: `${folderPath}/${ancestors[i].id}`,
          });
        }
      }
      const start = ancestors.length > 4 ? ancestors.length - 3 : 1;
      for (let i = start; i < ancestors.length; i += 1) {
        folderBreadcrumbs.push({
          name: truncateFileName(ancestors[i].name),
          path: `${folderPath}/${ancestors[i].id}`,
        });
      }
    }
  };

  /**
   * Adds manage snapshots page related paths
   */
  const addManageSnapshotPageBreadcrumbs = (): void => {
    if (repository) {
      basicBreadcrumbs.push({
        name: truncateFileName(repository.name),
        path: `${appPaths.myRepositories}/${repository.id}`,
      });
      basicBreadcrumbs.push({
        name: t('Breadcrumb.manageRepository', 'Manage Repository'),
        path: appPaths.manageRepository.replace(':repoId', repository.id),
      });
      basicBreadcrumbs.push({
        name: t('Breadcrumb.manageDataSnapshots', 'Manage Data Snapshots'),
        path: null,
      });
    }
  };

  /**
   * Adds admin console's delete user page related path
   */
  const addAdminDeleteUserPageBreadcrumbs = (): void => {
    basicBreadcrumbs.push({
      name: t('Breadcrumb.adminUserSearch', 'Search by User'),
      path: appPaths.adminUserSearch,
    });
    basicBreadcrumbs.push({
      name: t('Breadcrumb.deleteUser', 'Delete User'),
      path: null,
    });
  };

  /**
   * Adds admin console's user search related path
   */
  const addAdminUserSearchBreadcrumbs = (): void => {
    basicBreadcrumbs.push({
      name: t('Breadcrumb.adminUserSearch', 'Search by User'),
      path: pathname,
    });
  };

  /**
   * Adds admin console's manage roles related path
   */
  const addAdminRolesBreadcrumbs = (): void => {
    basicBreadcrumbs.push({
      name: t('Breadcrumb.manageAdminRoles', 'Manage Admin Roles'),
      path: pathname,
    });
  };

  /**
   * Walks the path and creates a breadcrumb for each segment that is recognized.
   * The root path is skipped because it was already added above.
   */
  for (let i = 1; i < paths.length; i += 1) {
    switch (paths[i]) {
      case 'admin':
        addBreadcrumb(t('Breadcrumb.adminConsole', 'Admin Console'), i);
        break;
      case 'definitions':
        addBreadcrumb(t('Breadcrumb.definitions', 'Definitions & FAQ'), i);
        break;
      case 'my-repositories':
        addBreadcrumb(t('Breadcrumb.myRepositories', 'My Repositories'), i);
        if (repository) {
          addBreadcrumb(truncateFileName(repository.name), i + 1);
        }
        break;
      case 'owner-reassign':
        addBreadcrumb(t('Breadcrumb.reassignRepositories', 'Reassign Repositories'), i);
        break;
      case 'shared-repositories':
        addBreadcrumb(t('Breadcrumb.sharedRepositories', 'Shared Repositories'), i);
        if (repository) {
          addBreadcrumb(truncateFileName(repository.name), i + 1);
        }
        break;
      case 'manage-snapshots':
        addManageSnapshotPageBreadcrumbs();
        break;
      case 'trash':
        addBreadcrumb(t('Breadcrumb.trash', 'Trash'), i);
        break;
      case 'release-notes':
        addBreadcrumb(t('Breadcrumb.releaseNotes', 'Release Notes'), i);
        break;
      case 'folder':
        addFolderBreadcrumbs();
        break;
      case 'user-search':
        addAdminUserSearchBreadcrumbs();
        break;
      case 'delete':
        addAdminDeleteUserPageBreadcrumbs();
        break;
      case 'roles':
        addAdminRolesBreadcrumbs();
        break;
      default:
        break;
    }
  }
  if (basicBreadcrumbs.length > 1) {
    return (
      <AntBreadcrumb separator=">" className="Breadcrumb">
        {basicBreadcrumbs.map(({ path, name }) => (
          <AntBreadcrumb.Item key={path || 'fake-link'}>
            {path ? (
              <Link
                to={path}
                className="breadcrumb"
                data-test-id="breadcrumb"
              >
                {name}
              </Link>
            ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="breadcrumb" data-test-id="breadcrumb">
                {name}
              </a>
            )}
          </AntBreadcrumb.Item>
        ))}
        {(extraFolderBreadcrubs.length > 0) && (
          <AntBreadcrumb.Item
            key="..."
            data-test-id="extra-breadcrumbs-menu"
            overlay={(
              <Menu className="Breadcrumb__extra-breadcrumbs-menu">
                {extraFolderBreadcrubs.map(({ path, name }) => (
                  <Menu.Item key={path || 'fake-link'}>
                    {path ? (
                      <Link
                        to={path}
                        className="breadcrumb"
                        data-test-id="breadcrumb"
                      >
                        {name}
                      </Link>
                    ) : (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a className="breadcrumb" data-test-id="breadcrumb">
                        {name}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu>
            )}
          >
            ...
          </AntBreadcrumb.Item>
        )}
        {(folderBreadcrumbs.length > 0) && folderBreadcrumbs.map(({ path, name }) => (
          <AntBreadcrumb.Item key={path || 'fake-link'}>
            {path ? (
              <Link
                to={path}
                className="breadcrumb"
                data-test-id="breadcrumb"
              >
                {name}
              </Link>
            ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="breadcrumb" data-test-id="breadcrumb">
                {name}
              </a>
            )}
          </AntBreadcrumb.Item>
        ))}
      </AntBreadcrumb>
    );
  }
  return (
    null
  );
}

export default Breadcrumb;
