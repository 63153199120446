/** @module components/NameField */
import { DragSourceHookSpec } from 'react-dnd';
import { Props, DragObject } from './types';

const dragOptions = (props: Props): DragSourceHookSpec<DragObject, void, undefined> => ({
  item: {
    type: 'repository-item',
    payload: props.itemDetails,
  },
  canDrag: (): boolean => (props.itemDetails !== undefined),
});

export default dragOptions;
