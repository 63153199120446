/** @module components/RepositoriesPage */
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { AppDispatch, GlobalState } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { getRepositories } from 'store/repositories/actions';
import { selectSharedRepositories, selectMyRepositories } from 'store/repositories/selectors';
import { appPaths, AppRouteParams } from 'utilities/routes';
import RepositoriesPage from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState,
  props: RouteComponentProps<AppRouteParams>): StateProps => {
  const { location } = props;
  const { search } = location;
  const parsedQueryString = queryString.parse(search);
  const calculatePageNumber = !!(parsedQueryString.calculatePageNumber
     && parsedQueryString.calculatePageNumber.toString() === 'true');
  const isMyRepositoryPage = props.match.path === appPaths.myRepositories;
  return {
    isMyRepositoryPage,
    calculatePageNumber,
    lastUpdated: state.repositories.lastUpdated,
    loading: selectLoading(state.loading, 'REPOSITORIES_GET')
    || selectLoading(state.loading, 'REPOSITORIES_UPDATE')
    || selectLoading(state.loading, 'REPOSITORY_TAGS_MAPPING_CREATE')
    || selectLoading(state.loading, 'REPOSITORY_TAGS_MAPPING_DELETE')
    || selectLoading(state.loading, 'REPOSITORIES_DELETE')
    || selectLoading(state.loading, 'REPOSITORIES_DELETE_SELECTED'),
    refreshing: selectLoading(state.loading, 'REPOSITORIES_GET'),
    repositories: isMyRepositoryPage ? selectMyRepositories(state)
      : selectSharedRepositories(state),
    me: state.me,
  };
};

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onRefresh: (): void => { dispatch(getRepositories()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepositoriesPage);
