/** @module utilities/form */
import countryCodes from './countryCodes.json';

// TODO: This file isn't used. Do we still need it?
interface CountryCodeElement {
  name: string;
  'alpha-3': string;
  'country-code': string;
}

export const blockedCountriesByAlpha3: { [key: string]: string } = {
  IRN: 'Iran',
  PRK: 'North Korea',
  SYR: 'Syria',
  CUB: 'Cuba',
  RUS: 'Russia',
  VEN: 'Venezuela',
  BLR: 'Belarus',
  IRQ: 'Iraq',
  LBY: 'Libya',
  MMR: 'Myanmar',
  SDN: 'Sudan',
  SSD: 'South Sudan',
};

export const filteredCountryCodes: CountryCodeElement[] = [];

countryCodes.data.forEach((element: CountryCodeElement) => {
  const countryCodeAlpha3 = element['alpha-3'];
  if (!blockedCountriesByAlpha3[countryCodeAlpha3]) {
    filteredCountryCodes.push(element);
  }
});

export default filteredCountryCodes;
