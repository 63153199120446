/** @module components/DashboardPageFooter */
import { connect } from 'react-redux';
import { selectFeature } from 'store/features/selectors';
import { Feature } from 'store/features/types';
import { GlobalState } from 'store/types';
import DashboardPageFooter from './component';
import { StateProps } from './types';

export const mapStateToProps = (
  state: GlobalState,
): StateProps => ({
  languagePickerEnabled: selectFeature(state.features, Feature.LANGUAGE_PICKER),
  router: state.router,
});

const mapDispatchToProps = (): {} => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageFooter);
