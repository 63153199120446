/** @module components/SharedRepositories */
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AppRouteParams } from 'utilities/routes';
import { GlobalState } from 'store/types';
import { selectRepository } from 'store/repositories/selectors';
import { selectRepositoryItem } from 'store/repositoryItems/selectors';
import Breadcrumb from './component';
import { StateProps } from './types';

export const mapStateToProps = (
  state: GlobalState,
  props: RouteComponentProps<AppRouteParams>,
): StateProps => {
  const { location } = props;
  const repoIdFromQueryString = queryString.parse(location.search).repoIds;
  const repoId = props.match.params.repoId || repoIdFromQueryString || '';
  return {
    repository: selectRepository(state.repositories, typeof repoId === 'string' ? repoId : ''),
    pathname: props.location.pathname,
    folder: selectRepositoryItem(state, props.match.params.folderId || ''),
  };
};

const mapDispatchToProps = (): {} => ({});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Breadcrumb));
