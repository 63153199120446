/** @module utilities/file */

/**
 * Returns the extension (including the dot) of the file, folder, or
 * repository without the name.
 * @param text The file, folder, or repository name
 * @return The extension without the name
 */
function getExtension(text: string): string {
  const extensionStart = text.lastIndexOf('.');
  if (extensionStart !== -1) {
    return text.slice(extensionStart, text.length);
  }
  return '';
}

export default getExtension;
