import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import XHR from 'i18next-xhr-backend';
import detector from 'i18next-browser-languagedetector';

i18n
  .use(detector)
  .use(XHR)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
i18n.on('languageChanged', () => {
  moment.updateLocale('en', {
    relativeTime: {
      future: i18n.t('moment.relativeTime.future', 'in {{time}}', { time: '%s' }),
      past: i18n.t('moment.relativeTime.past', '{{time}} ago', { time: '%s' }),
      s: i18n.t('moment.relativeTime.s', 'a few seconds'),
      ss: i18n.t('moment.relativeTime.ss', '{{seconds}} seconds', { seconds: '%d' }),
      m: i18n.t('moment.relativeTime.m', 'a minute'),
      mm: i18n.t('moment.relativeTime.mm', '{{minutes}} minutes', { minutes: '%d' }),
      h: i18n.t('moment.relativeTime.h', 'an hour'),
      hh: i18n.t('moment.relativeTime.hh', '{{hours}} hours', { hours: '%d' }),
      d: i18n.t('moment.relativeTime.d', 'a day'),
      dd: i18n.t('moment.relativeTime.dd', '{{days}} days', { days: '%d' }),
      M: i18n.t('moment.relativeTime.M', 'a month'),
      MM: i18n.t('moment.relativeTime.MM', '{{months}} months', { months: '%d' }),
      y: i18n.t('moment.relativeTime.y', 'a year'),
      yy: i18n.t('moment.relativeTime.yy', '{{years}} years', { years: '%d' }),
    },
  });
  moment.locale('en');
});

export default i18n;
