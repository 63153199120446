/** @module services/metadata */
export interface Business {
  id: string;
  displayName: string;
  wizardId: string;
}

export enum BusinessWizardId {
  AVIATION = '1',
  GECAS = '2',
  NUCLEAR = '3',
  GAS_POWER = '4',
  POWER_PORTFOLIO = '5',
  POWER_CONVERSION = '6',
  CORPORATE = '7',
  DIGITAL = '8',
  PEOPLE_OPERATIONS = '9',
  GRC = '10',
}
