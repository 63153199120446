/** @module components/BulkActionsBanner */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkmark.svg';
import { Props } from './types';
import './styles.scss';

function BulkActionsBanner(props: Props): JSX.Element {
  const { numberSelected, children, className } = props;
  const { t } = useTranslation();
  return (
    <div className={`BulkActionsBanner ${numberSelected > 0 ? 'open' : ''} ${className}`}>
      {numberSelected ? (
        <div className="number-selected">
          <CheckboxIcon className="checkbox" width={15} height={15} />
          <span className="numberSelectedText">
            {t(
              'BulkActionsBanner.numberSelected',
              '{{count}} ITEMS SELECTED',
              { count: numberSelected },
            )}
          </span>
        </div>
      ) : null}
      <div className="bulk-actions">
        {children}
      </div>
    </div>
  );
}

export default BulkActionsBanner;
