/** @module store/roles */
import { Action } from 'redux';
import { Role } from 'services/metadata/types/Role';

export enum RolesActionTypes {
  ALL_ROLES_GET_REQUEST = 'ALL_ROLES_GET_REQUEST',
  ALL_ROLES_GET_ERROR = 'ALL_ROLES_GET_ERROR',
  ALL_ROLES_GET_SUCCESS = 'ALL_ROLES_GET_SUCCESS',

  ROLE_GET_REQUEST = 'ROLE_GET_REQUEST',
  ROLE_GET_ERROR = 'ROLE_GET_ERROR',
  ROLE_GET_SUCCESS = 'ROLE_GET_SUCCESS',

  ROLE_CREATE_REQUEST = 'ROLE_CREATE_REQUEST',
  ROLE_CREATE_ERROR = 'ROLE_CREATE_ERROR',
  ROLE_CREATE_SUCCESS = 'ROLE_CREATE_SUCCESS',

  ROLE_UPDATE_REQUEST = 'ROLE_UPDATE_REQUEST',
  ROLE_UPDATE_ERROR = 'ROLE_UPDATE_ERROR',
  ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS',

  ROLE_ASSIGN_REQUEST = 'ROLE_ASSIGN_REQUEST',
  ROLE_ASSIGN_ERROR = 'ROLE_ASSIGN_ERROR',
  ROLE_ASSIGN_SUCCESS = 'ROLE_ASSIGN_SUCCESS',

  ROLE_REMOVE_REQUEST = 'ROLE_REMOVE_REQUEST',
  ROLE_REMOVE_ERROR = 'ROLE_REMOVE_ERROR',
  ROLE_REMOVE_SUCCESS = 'ROLE_REMOVE_SUCCESS',

  USER_ROLES_GET_REQUEST = 'USER_ROLES_GET_REQUEST',
  USER_ROLES_GET_ERROR = 'USER_ROLES_GET_ERROR',
  USER_ROLES_GET_SUCCESS = 'USER_ROLES_GET_SUCCESS',

  ROLES_ASSIGN_REQUEST = 'ROLES_ASSIGN_REQUEST',
  ROLES_ASSIGN_ERROR = 'ROLES_ASSIGN_ERROR',
  ROLES_ASSIGN_SUCCESS = 'ROLES_ASSIGN_SUCCESS',


  ROLES_REMOVE_REQUEST = 'ROLES_REMOVE_REQUEST',
  ROLES_REMOVE_ERROR = 'ROLES_REMOVE_ERROR',
  ROLES_REMOVE_SUCCESS = 'ROLES_REMOVE_SUCCESS',
}

export type AllRolesGetRequest = Action<RolesActionTypes.ALL_ROLES_GET_REQUEST>;

export interface AllRolesGetSuccess extends Action<
RolesActionTypes.ALL_ROLES_GET_SUCCESS
> {
  payload: {
    roles: Role[];
  };
}

export interface AllRolesGetError extends Action<
RolesActionTypes.ALL_ROLES_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RoleGetRequest = Action<RolesActionTypes.ROLE_GET_REQUEST>;

export interface RoleGetSuccess extends Action<
RolesActionTypes.ROLE_GET_SUCCESS
> {
  payload: {
    role: Role;
  };
}

export interface RoleGetError extends Action<
RolesActionTypes.ROLE_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RoleCreateRequest = Action<RolesActionTypes.ROLE_CREATE_REQUEST>;

export interface RoleCreateSuccess extends Action<
RolesActionTypes.ROLE_CREATE_SUCCESS
> {
  payload: {
    role: Role;
  };
}

export interface RoleCreateError extends Action<
RolesActionTypes.ROLE_CREATE_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RoleUpdateRequest = Action<RolesActionTypes.ROLE_UPDATE_REQUEST>;

export interface RoleUpdateSuccess extends Action<
RolesActionTypes.ROLE_UPDATE_SUCCESS
> {
  payload: {
    role: Role;
  };
}

export interface RoleUpdateError extends Action<
RolesActionTypes.ROLE_UPDATE_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RoleAssignRequest = Action<RolesActionTypes.ROLE_ASSIGN_REQUEST>;

export interface RoleAssignSuccess extends Action<
RolesActionTypes.ROLE_ASSIGN_SUCCESS
> {
  payload: {
    userId: string;
    roleId: string;
  };
}

export interface RoleAssignError extends Action<
RolesActionTypes.ROLE_ASSIGN_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RoleRemoveRequest = Action<RolesActionTypes.ROLE_REMOVE_REQUEST>;

export interface RoleRemoveSuccess extends Action<
RolesActionTypes.ROLE_REMOVE_SUCCESS
> {
  payload: {
    userId: string;
    roleId: string;
  };
}

export interface RoleRemoveError extends Action<
RolesActionTypes.ROLE_REMOVE_ERROR
> {
  payload: {
    error: Error;
  };
}

export interface UserRolesGetSuccess extends Action<
RolesActionTypes.USER_ROLES_GET_SUCCESS
> {
  payload: {
    roles: Role[];
    userId: string;
  };
}

export interface UserRolesGetError extends Action<
RolesActionTypes.USER_ROLES_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type UserRolesGetRequest = Action<RolesActionTypes.USER_ROLES_GET_REQUEST>;


export type RolesAssignRequest = Action<RolesActionTypes.ROLES_ASSIGN_REQUEST>;

export interface RolesAssignSuccess extends Action<
RolesActionTypes.ROLES_ASSIGN_SUCCESS
> {
  payload: {
    userId: string;
    roles: Role[];
  };
}

export interface RolesAssignError extends Action<
RolesActionTypes.ROLES_ASSIGN_ERROR
> {
  payload: {
    error: Error[];
    failed: Role[];
  };
}

export type RolesRemoveRequest = Action<RolesActionTypes.ROLES_REMOVE_REQUEST>;

export interface RolesRemoveSuccess extends Action<
RolesActionTypes.ROLES_REMOVE_SUCCESS
> {
  payload: {
    userId: string;
    roles: Role[];
  };
}

export interface RolesRemoveError extends Action<
RolesActionTypes.ROLES_REMOVE_ERROR
> {
  payload: {
    error: Error[];
    failed: Role[];
  };
}

export type State = {
  roles: Role[];
  usersRoles: {
    [userId: string]: Role[];
  };
};

export type SearchAction = AllRolesGetRequest
                         | AllRolesGetSuccess
                         | AllRolesGetError
                         | RoleGetRequest
                         | RoleGetSuccess
                         | RoleGetError
                         | RoleCreateRequest
                         | RoleCreateSuccess
                         | RoleCreateError
                         | RoleUpdateRequest
                         | RoleUpdateSuccess
                         | RoleUpdateError
                         | RoleAssignRequest
                         | RoleAssignSuccess
                         | RoleAssignError
                         | RoleRemoveRequest
                         | RoleRemoveSuccess
                         | RoleRemoveError
                         | RolesRemoveError
                         | RolesRemoveRequest
                         | RolesRemoveSuccess
                         | RolesAssignError
                         | RolesAssignRequest
                         | RolesAssignSuccess;
