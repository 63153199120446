/** @module utilities/sort */
/**
 * A comparator that sorts two strings alphabetically. Strings starting
 * with a capital letter are proiritized over strings starting with a
 * lowercase letter.
 *
 * @param a A string
 * @param b A string
 * @return a number (-1, 0, or 1)
 */
function sortAlphabetical(a: string, b: string): number {
  return (a < b ? -1 : 1);
}

export default sortAlphabetical;
