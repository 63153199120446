/** @module components/FolderTree */
import { connect } from 'react-redux';
import { getRepositoryItems } from 'store/repositoryItems/actions';
import { GlobalState, AppDispatch } from 'store/types';
import component from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  folders: state.items.byId,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getItems: (folderId: string): Promise<void> => dispatch(getRepositoryItems(folderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(component);
