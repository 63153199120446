/** @module utilities/test */

const mockTag = {
  id: '0',
  name: 'tag 1',
  updatedOn: new Date(),
  creator: {
    id: '0',
    name: 'someone',
    email: 'someone@ge.com',
    external: false,
    business: {
      id: '0',
      displayName: 'a business',
    },
  },
  business: {
    id: '1',
    displayName: 'Another business',
  },
};

export default mockTag;
