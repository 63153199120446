/** @module components/CreateFolderModal */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CreateFolderIcon } from 'assets/icons/create-new-folder.svg';
import ModalAction from '../ModalAction';
import TitleWithIcon from '../TitleWithIcon';
import CreateFolderForm from '../CreateFolderForm';
import DashboardPageAction from '../DashboardPageAction';
import { Props } from './types';

/**
 * A modal action that displays the create folder form.
 * @param props The component props
 * @return a JSX Element
 */
function CreateFolderModal(props: Props): JSX.Element {
  const { parentId } = props;
  const [oldParentId, setOldParentId] = useState(parentId);
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  /* Close the modal if the parentId has changed (navigation).
   * Equivalent to getDerivedStateFromProps.
   */
  if (parentId !== oldParentId) {
    setOldParentId(props.parentId);
    setModalVisible(false);
  }
  return (
    <ModalAction
      title={(
        <TitleWithIcon iconSVG={CreateFolderIcon}>
          {t('CreateFolderModal.title', 'CREATE FOLDER')}
        </TitleWithIcon>
      )}
      visible={modalVisible}
      cancelHandler={(): void => setModalVisible(false)}
      actionRender={(): JSX.Element => (
        <DashboardPageAction
          text={t('CreateFolderModal.button', 'CREATE FOLDER')}
          icon={<CreateFolderIcon width={20} height={20} />}
          onClick={(): void => setModalVisible(true)}
          data-test-id="open-button"
        />
      )}
      footer={null}
      data-test-id="modal"
    >
      <CreateFolderForm
        afterSubmit={(): void => setModalVisible(false)}
        parentId={parentId}
        data-test-id="form"
      />
    </ModalAction>
  );
}

export default CreateFolderModal;
