/** @module components/MeMenu */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Menu } from 'antd';
import { ReactComponent as AvatarIcon } from 'assets/icons/avatar.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout-lg.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { Props } from './types';
import './styles.scss';

/**
 * A menu that displays basic user data and allowed user specific actions.
 * @param props The component props
 * @return A JSX Element
 */
function MeMenu(props: Props): JSX.Element {
  const { me } = props;
  const { t } = useTranslation();
  const { user } = me;
  return (
    <div className="MeMenu">
      <AvatarIcon className="MeMenu__user-icon" width={25} height={25} />
      <Dropdown
        overlay={(
          <Menu className="MeMenu__menu">
            <Menu.ItemGroup
              key="user-details"
              title={(
                <div className="MeMenu__user-details">
                  {user && user.name}
                  <br />
                  {user && user.email}
                </div>
              )}
            />
            <Menu.Divider />
            <Menu.Item key="log-out">
              <Button type="link" href="/auth/logout" data-test-id="log-out-button">
                <LogoutIcon className="icon--primary" />
                <span className="MeMenu__button__text">
                  {t('MeMenu.logOut', 'Log Out')}
                </span>
              </Button>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
        placement="bottomLeft"
        data-test-id="dropdown"
      >
        <div>
          <div className="MeMenu__name">
            {user && user.name}
          </div>
          <ChevronDown className="MeMenu__down-icon" width={30} height={30} />
        </div>
      </Dropdown>
    </div>
  );
}

export default MeMenu;
