/** @module services/metadata */
/**
 * An extention of the Error class that contains the response.
 */
export class MetadataError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}
