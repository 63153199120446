
/** @module store/roles */
import { Role } from 'services/metadata/types/Role';
import { State } from './types';

/**
 * Select all roles.
 * @param state rolesState
 * @return Roles array
 */
export function selectAllRoles(state: State): Role[] {
  return state.roles;
}

/**
 * Select user's roles.
 * @param state rolesState
 * @param userId A user id
 * @return Roles array
 */
export function selectUserRoles(state: State, userId: string): Role[] {
  const userRole = state.usersRoles[userId];
  return userRole || [];
}
