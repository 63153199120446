/** @module components/MoveFileModal */
import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Form } from 'antd';
import { ReactComponent as MoveIcon } from 'assets/icons/move.svg';
import { truncateFileName } from 'utilities/file';
import ModalAction from '../ModalAction';
import TitleWithIcon from '../TitleWithIcon';
import FolderTree from '../FolderTree';
import { Props } from './types';
import './styles.scss';

let isMounted = false;
function MoveFileModal(props: Props): JSX.Element {
  const { t } = useTranslation();
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    isMounted = true;
    return function cleanup(): void {
      isMounted = false;
    };
  }, []);

  function closeModal(): void {
    if (isMounted) {
      setVisible(false);
    }
  }

  function onFinish(): void {
    const { updateFileParent } = props;
    updateFileParent(selectedFolderId, () => closeModal());
  }

  const {
    afterClose,
    file,
    repositoryId,
    loading,
  } = props;
  if (!repositoryId) {
    return <div data-test-id="empty" />;
  }
  return (
    <ModalAction
      title={(
        <TitleWithIcon iconSVG={MoveIcon}>
          {t(
            'MoveFileModal.MoveTitle',
            'MOVE {{file}}',
            { file: file.type.toUpperCase() },
          )}
        </TitleWithIcon>
      )}
      afterClose={afterClose}
      footer={null}
      actionRender={(): JSX.Element => (
        <Button
          type="link"
          onClick={(): void => setVisible(true)}
          data-test-id="open-button"
        >
          <MoveIcon className="icon--primary" />
          {t('MoveFileModal.icon.move', 'Move')}
        </Button>
      )}
      cancelHandler={(): void => setVisible(false)}
      visible={visible}
      data-test-id="modal"
      className="MoveFileModal"
    >
      <Form onFinish={onFinish} data-test-id="form">
        <Form.Item>
          <div className="destination-description">
            <Trans
              i18nKey="MoveFileModal.formItem.titleQuestion"
              values={{ fileName: truncateFileName(file.name) }}
            >
              Where would you like to move
              {' "'}
              <span className="file-name">
                {'{{fileName}}'}
              </span>
              {' "'}
              ?
            </Trans>
          </div>
          <Form.Item
            name="destination"
            rules={[{
              validator: (): Promise<void> => {
                if (selectedFolderId) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('MoveFileModal.destination.required', 'Please select a destination')));
              },
            }]}
            validateTrigger={['onSelect']}
          >
            <FolderTree
              repositoryId={repositoryId}
              files={[file]}
              onSelect={(id: string | null): void => setSelectedFolderId(id)}
              data-test-id="folder-tree"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item className="ant-form-item--last-item ant-form-item--form-buttons">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            data-test-id="submit-button"
          >
            {t(
              'MoveFileModal.Button.move',
              'Move {{file}}',
              { file: file.type.toLowerCase() },
            )}
          </Button>
        </Form.Item>
      </Form>
    </ModalAction>
  );
}

export default MoveFileModal;
