/** @module utilities/test */

import { EvictionStatus } from 'services/metadata/types/EvictionStatus';
import mockUser from './mockUser';
import mockRepository from './mockRepository';

const mockEvictionStatus: EvictionStatus = {
  evicted: true,
  evictionStatus: {
    repository: {
      id: mockRepository.id,
      name: mockRepository.name,
    },
    evictedBy: mockUser,
    evictedAt: new Date('2023-11-02T15:31:45.912Z'),
    complete: false,
    failed: false,
    errors: '',
    progress: 0,
  },
};
export default mockEvictionStatus;
