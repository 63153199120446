/** @module services/release-notes */
import { Client } from './types/Client';

/**
 * A client that communicates with the REDshare release-notes service using
 * the fetch api.
 */
class FetchClient implements Client {
  private prefix: string;

  /**
   * Constructs a new client.
   */
  constructor() {
    this.prefix = '/';
  }

  /**
   * Returns a Promise containing a release-notes string in markdown format.
   * @return A Promise containing a release-notes string in markdown format
   * @throws An Error if the response is not ok
   */
  async getReleaseNotes(): Promise<string> {
    const uri = `${this.prefix}CHANGELOG.md`;
    const response = await fetch(uri);
    FetchClient.handleError(response);
    const text = await response.text();
    return text;
  }

  /**
   * Throws an error if the given response is not ok.
   *
   * @param response A Response
   * @throws An Error if the response is not ok
   */
  static handleError(response: Response): void {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
  }
}

export default FetchClient;
