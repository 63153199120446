/** @module components/FileUploadArea */
import { AppDispatch, GlobalState } from 'store/types';
import { selectFileUploads } from 'store/uploads/selectors';
import { enqueueFiles, validateName } from 'store/uploads/actions';
import { connect } from 'react-redux';
import component from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  uploads: selectFileUploads(state.uploads),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  enqueueFiles: (files: File[], parentId: string, repoId: string): void => {
    dispatch(enqueueFiles(files, parentId, repoId));
  },
  validateName: (name: string): boolean => dispatch(validateName(name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(component);
