/** @module components/UploadFolderButton */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-lg.svg';
import { Props } from './types';

export default function UploadFolderButton(props: Props): JSX.Element | null {
  const {
    folderItems,
    enqueueFolder,
    folderId,
    repositoryId,
  } = props;

  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.setAttribute('webkitdirectory', '');
    }
  }, [inputRef]);

  const checkBrowserSupport = (): boolean => {
    let browerSupport = false;
    const tmpInput = document.createElement('input');
    if ('webkitdirectory' in tmpInput) {
      browerSupport = true;
    }
    return browerSupport;
  };

  const onSelectFolder = (files: FileList | null): void => {
    if (files && files.length) {
      const file: WebkitFile | null = files.item(0);
      const folderName = file && file.webkitRelativePath && file.webkitRelativePath.split('/').slice(0, -1)[0];
      const duplicateFolder = folderItems && folderItems.find((f) => f.type === 'FOLDER' && f.name === folderName);
      if (duplicateFolder) {
        message.error(t(
          'UploadFolderButton.uploadMessage.duplicate',
          '{{folderName}} already exists',
          { folderName },
        ));
      } else if (folderName) {
        enqueueFolder(folderName, Array.from(files), folderId || repositoryId, repositoryId);
      }
    }
  };

  const onClick = (): void => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  if (checkBrowserSupport()) {
    return (
      <Button
        type="link"
        data-test-id="button-folder"
        onClick={(): void => onClick()}
      >
        <FolderIcon width={15} height={15} className="icon--primary" />
        {t('UploadFolderButton.folder', 'Folder')}
        <input
          type="file"
          ref={inputRef}
          onChange={(e): void => onSelectFolder(e.target.files)}
          hidden
          data-test-id="upload-folder"
        />
      </Button>
    );
  }
  return null;
}
