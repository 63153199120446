/** @module components/DashboardPageAction */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Props } from './types';
import './styles.scss';

function DashboardPageAction(props: Props): JSX.Element {
  const {
    icon,
    link,
    onClick,
    text,
  } = props;
  return (
    <Button
      type="default"
      onClick={onClick}
      className="DashboardPageAction"
    >
      {
        link
        && (
          <Link to={link} data-test-id="link">
            {icon}
            {text}
          </Link>
        )
      }
      {
        !link
        && (
          <>
            {icon}
            {text}
          </>
        )
      }
    </Button>
  );
}

export default DashboardPageAction;
