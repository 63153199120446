/** @module components/AddLicensesForm */
import React, { useState, useEffect } from 'react';
import {
  AutoComplete,
  Button,
  Form,
  Checkbox,
} from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { Props, FormData } from './types';
import './styles.scss';

function AddLicensesForm(props: Props): JSX.Element {
  const {
    getAllLicenses,
    allLicenses,
    licenses,
    addLicense,
    repository,
    loading,
  } = props;
  const [results, setResults] = useState<string[]>([]);
  const [form] = Form.useForm<FormData>();
  const { t } = useTranslation();

  useEffect(() => {
    getAllLicenses();
  }, [getAllLicenses]);

  const handleSearch = (query: string): void => {
    // exact match from start of string
    const matches = allLicenses.filter((item) => query === item.slice(0, query.length));
    const uniqueMatches = new Set(matches);
    setResults(Array.from(uniqueMatches).slice(0, 100));
  };

  const onFinish = (values: FormData): void => {
    const { searchField } = values;
    addLicense(repository.id, searchField);
    form.resetFields();
  };

  return (
    <div className="AddLicensesForm">
      <h3>{t('AddLicensesForm.header', 'Add Licenses')}</h3>
      <Form
        form={form}
        className="license-form"
        layout="horizontal"
        onFinish={onFinish}
        data-test-id="form"
      >
        <Form.Item
          name="searchField"
          className="searchField"
          hasFeedback
          data-test-id="form-item--searchField"
          rules={[
            {
              validator: (rule, value): Promise<void> => {
                if (licenses && licenses.includes(value)) {
                  return Promise.reject(t('AddLicensesForm.form.rules.alreadyAdded', 'This license has already been added.'));
                }
                return Promise.resolve();
              },
            },
            {
              validator: (rule, value): Promise<void> => {
                if (!allLicenses.includes(value)) {
                  return Promise.reject(t('AddLicensesForm.form.rules.invalid', 'The license you searched for is invalid.'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <AutoComplete
            placeholder={t('AddLicensesForm.form.placeholder', 'Enter license here')}
            options={results.map((r) => ({ label: r, value: r }))}
            onSearch={(query: string): void => handleSearch(query)}
            data-test-id="searchField"
          />
        </Form.Item>
        <Form.Item
          name="tos"
          data-test-id="form-item--tos"
          rules={[{
            validator: (rule, value): Promise<void> => {
              if (value === true) {
                return Promise.resolve();
              }
              return Promise.reject(t('AddLicensesForm.form.rules.tosRequired', 'Required'));
            },
          }]}
          initialValue={undefined}
          valuePropName="checked"
        >
          <Checkbox data-test-id="checkbox">
            <Trans i18nKey="AddLicensesForm.checkbox.text">
              I acknowledge that I have reviewed the
              {' '}
              <a href="http://supportcentral.ge.com/products/sup_products.asp?prod_id=15129">
                Export Control License Compliance Plan
              </a>
              {' '}
              associated with the license I am attaching to this repository.
            </Trans>
          </Checkbox>
        </Form.Item>
        <Form.Item className="ant-form-item--last-item">
          <Button
            className="submitButton"
            type="primary"
            htmlType="submit"
            data-test-id="submit"
            loading={loading}
            title={t('AddLicensesForm.button', 'Add License')}
          >
            <AddIcon width={15} height={15} />
            {t('AddLicensesForm.button', 'Add License')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AddLicensesForm;
