/** @module store/businesses */
import { Business } from 'services/metadata';
import { State } from './types';

/**
 * Selects all businesses from the given state.
 * @param state The current state
 * @return An array of businesses or undefined
 */
function selectBusinesses(state: State): Business[] | undefined {
  if (state) {
    return state;
  }
  return undefined;
}

export { selectBusinesses };
