/** @module components/SnapshotsTable */
import {
  Snapshot,
  Repository,
  Me,
  SnapshotExport,
  SnapshotExportSegment,
} from 'services/metadata';
import { DownloadStatus } from 'store/snapshots/types';

export interface OwnProps {
  repository: Repository;
  creatingSnapshotName?: string;
}

export interface StateProps {
  loading: boolean;
  creatingSnapshotExport: boolean;
  downloadStatuses: Map<string, Map<number, DownloadStatus>>;
  snapshots?: Snapshot[];
  snapshotExports: Map<string, SnapshotExport[]>;
  snapshotExportSegments: Map<string, SnapshotExportSegment[ ]>;
  me: Me;
}

export interface DispatchProps {
  getSnapshots: () => void;
  getExportsMultipleSnapshots: (snapshotIds: string[]) => void;
  getSegmentsMultipleSnapshotsExports: (snapshotExports: Map<string, SnapshotExport[]>) => void;
  downloadSnapshotExportSegment: (
    snapshotId: string,
    exportId: string,
    segmentNumber: number
    ) => void;
    createSnapshotExport: (snapshotId: string, maxSegmentSize: number) => void;
}

export enum SegmentSize {
  SIZE_50MB =52428800,
  SIZE_100MB =104857600,
  SIZE_250MB =262144000,
  SIZE_500MB =524288000,
  SIZE_1GB =1262485504,
}

export interface NewExportData {
  snapshotId: string;
  maxSegmentSize: SegmentSize | null;
}

export interface CreateExportSegmentsModalProps {
  isModalVisible: boolean;
  handleCancelModal: () => void;
  handleCreateSegments: () => void;
  newExportData: NewExportData;
  setNewExportData: React.Dispatch<React.SetStateAction<NewExportData>>;
}

export type Props = OwnProps & StateProps & DispatchProps;
