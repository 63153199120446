/** @module store/release-notes */
import { AnyAction } from 'redux';
import {
  ReleaseNotesActionType,
  State,
} from './types';

export const DEFAULT_STATE: State = null;

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
function reducer(state: State = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case ReleaseNotesActionType.RELEASE_NOTES_GET_REQUEST:
      return null;
    case ReleaseNotesActionType.RELEASE_NOTES_GET_SUCCESS: {
      const { releaseNotes } = action.payload;
      return releaseNotes;
    }
    default:
      return state;
  }
}

export default reducer;
