/** @module components/UserRepositoriesSection */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { deleteUserRepository, getUserRepositories, renameUserRepository } from 'store/user-repositories/actions';
import {
  selectUserRepositories,
  selectUserRepositoriesLastUpdated,
} from 'store/user-repositories/selectors';
import { selectUser } from 'store/users/selectors';
import { getUsers } from 'store/users/actions';
import { Repository } from 'services/metadata';
import UserRepositoriesSection from './component';
import { StateProps, DispatchProps, OwnProps } from './types';

export const mapStateToProps = (state: GlobalState, ownProps: OwnProps): StateProps => ({
  repositories: selectUserRepositories(state.userRepositories, ownProps.userId),
  loading: selectLoading(state.loading, 'USERS_REPOSITORIES_GET')
    || selectLoading(state.loading, 'USERS_GET')
    || selectLoading(state.loading, 'USERS_REPOSITORIES_DELETE')
    || selectLoading(state.loading, 'USERS_REPOSITORIES_RENAME'),
  refreshing: selectLoading(state.loading, 'USERS_REPOSITORIES_GET'),
  lastUpdated: selectUserRepositoriesLastUpdated(state.userRepositories, ownProps.userId),
  user: selectUser(state.users, ownProps.userId),
  me: state.me,
});

export const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps): DispatchProps => ({
  onRefresh: (): void => { dispatch(getUserRepositories(ownProps.userId)); },
  getUserRepositories: (): void => {
    dispatch(getUserRepositories(ownProps.userId));
  },
  getUser: (): void => { dispatch(getUsers(ownProps.userId)); },
  deleteUserRepository: (repository: Repository): void => {
    dispatch((deleteUserRepository(repository, ownProps.userId)));
  },
  updateName: (repoId: string, name: string): void => {
    dispatch(renameUserRepository(ownProps.userId, repoId, name));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserRepositoriesSection);
