/** @module store/snapshots */

import { Action } from 'redux';
import { MetadataErrorResponse } from 'services/metadata';
import { Snapshot, SnapshotExport, SnapshotExportSegment } from 'services/metadata/types/Snapshot';

export enum SnapshotsActionType {
  SNAPSHOTS_GET_REQUEST = 'SNAPSHOTS_GET_REQUEST',
  SNAPSHOTS_GET_SUCCESS = 'SNAPSHOTS_GET_SUCCESS',
  SNAPSHOTS_GET_ERROR = 'SNAPSHOTS_GET_ERROR',
  SNAPSHOT_CREATE_REQUEST = 'SNAPSHOT_CREATE_REQUEST',
  SNAPSHOT_CREATE_SUCCESS = 'SNAPSHOT_CREATE_SUCCESS',
  SNAPSHOT_CREATE_ERROR = 'SNAPSHOT_CREATE_ERROR',
  SNAPSHOT_EXPORTS_GET_REQUEST = 'SNAPSHOT_EXPORTS_GET_REQUEST',
  SNAPSHOT_EXPORTS_GET_SUCCESS = 'SNAPSHOT_EXPORTS_GET_SUCCESS',
  SNAPSHOT_EXPORTS_GET_ERROR = 'SNAPSHOT_EXPORTS_GET_ERROR',
  SNAPSHOT_EXPORT_CREATE_REQUEST = 'SNAPSHOT_EXPORT_CREATE_REQUEST',
  SNAPSHOT_EXPORT_CREATE_SUCCESS = 'SNAPSHOT_EXPORT_CREATE_SUCCESS',
  SNAPSHOT_EXPORT_CREATE_ERROR = 'SNAPSHOT_EXPORT_CREATE_ERROR',
  MULTI_SNAPSHOT_EXPORTS_GET_REQUEST='MULTI_SNAPSHOT_EXPORTS_GET_REQUEST',
  MULTI_SNAPSHOT_EXPORTS_GET_SUCCESS='MULTI_SNAPSHOT_EXPORTS_GET_SUCCESS',
  MULTI_SNAPSHOT_EXPORTS_GET_ERROR='MULTI_SNAPSHOT_EXPORTS_GET_ERROR',
  SNAPSHOT_EXPORT_SEGMENTS_GET_REQUEST = 'SNAPSHOT_EXPORT_SEGMENTS_GET_REQUEST',
  SNAPSHOT_EXPORT_SEGMENTS_GET_SUCCESS = 'SNAPSHOT_EXPORT_SEGMENTS_GET_SUCCESS',
  SNAPSHOT_EXPORT_SEGMENTS_GET_ERROR = 'SNAPSHOT_EXPORT_SEGMENTS_GET_ERROR',
  MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_REQUEST = 'MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_REQUEST',
  MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_SUCCESS = 'MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_SUCCESS',
  MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_ERROR = 'MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_ERROR',
  SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_REQUEST = 'SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_REQUEST',
  SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_SUCCESS = 'SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_SUCCESS',
  SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_FAILURE = 'SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_FAILURE',
}

export type GetSnapshotsRequestAction = Action<
SnapshotsActionType.SNAPSHOTS_GET_REQUEST
>;

export interface GetSnapshotsSuccessAction extends Action<
SnapshotsActionType.SNAPSHOTS_GET_SUCCESS
> {
  payload: {
    byId: { [id: string]: Snapshot };
    byRepoId: { [repoId: string]: string[] };
    repositoryId: string;
  };
}

export interface GetSnapshotsErrorAction extends Action<
SnapshotsActionType.SNAPSHOTS_GET_ERROR
> {
  payload: {
    error: MetadataErrorResponse;
    repositoryId: string;
  };
}

export type SnapshotCreateRequestAction = Action<
SnapshotsActionType.SNAPSHOT_CREATE_REQUEST
>;

export interface SnapshotCreateSuccessAction extends Action<
SnapshotsActionType.SNAPSHOT_CREATE_SUCCESS
> {
  payload: {
    response: { id: string };
  };
}

export interface SnapshotCreateErrorAction extends Action<
SnapshotsActionType.SNAPSHOT_CREATE_ERROR
> {
  payload: {
    error: MetadataErrorResponse;
  };
}

export type GetSnapshotExportsRequestAction = Action<
SnapshotsActionType.SNAPSHOT_EXPORTS_GET_REQUEST
>;

export interface GetSnapshotExportsSuccessAction extends Action<
SnapshotsActionType.SNAPSHOT_EXPORTS_GET_SUCCESS
> {
  payload: {
    byId: { [id: string]: SnapshotExport };
    bySnapshotId: string;
  };
}

export interface GetSnapshotExportsErrorAction extends Action<
SnapshotsActionType.SNAPSHOT_EXPORTS_GET_ERROR
> {
  payload: {
    error: MetadataErrorResponse;
    snapshotId: string;
  };
}

export interface SnapshotExportCreateRequestAction extends Action<
SnapshotsActionType.SNAPSHOT_EXPORT_CREATE_REQUEST
> {
  payload: {
    repoId: string;
    snapshotId: string;
    maxSegmentSize: number;
  };
}

export interface SnapshotExportCreateSuccessAction extends Action<
SnapshotsActionType.SNAPSHOT_EXPORT_CREATE_SUCCESS
> {
  payload: {
    response: { id: string };
  };
}

export interface SnapshotExportCreateErrorAction extends Action<
SnapshotsActionType.SNAPSHOT_EXPORT_CREATE_ERROR
> {
  payload: {
    error: MetadataErrorResponse;
  };
}

export type GetMultiSnapshotExportsRequestAction = Action<
SnapshotsActionType.MULTI_SNAPSHOT_EXPORTS_GET_REQUEST
>;

export type GetMultiSnapshotExportsSuccessAction = Action<
SnapshotsActionType.MULTI_SNAPSHOT_EXPORTS_GET_SUCCESS
>;
export interface GetMultiSnapshotExportsErrorAction extends Action<
SnapshotsActionType.MULTI_SNAPSHOT_EXPORTS_GET_ERROR
> {
  payload: {
    error: MetadataErrorResponse;
  };
}

export interface GetSnapshotExportSegmentsRequestAction {
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENTS_GET_REQUEST;
}

export interface GetSnapshotExportSegmentsSuccessAction {
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENTS_GET_SUCCESS;
  payload: {
    byId: { [id: string]: SnapshotExportSegment };
    byExportId: string;
  };
}

export interface GetSnapshotExportSegmentsErrorAction {
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENTS_GET_ERROR;
  payload: {
    error: MetadataErrorResponse;
    exportId: string;
  };
}

export type GetMultiSnapshotExportSegmentsRequestAction = Action<
  SnapshotsActionType.MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_REQUEST
>;

export type GetMultiSnapshotExportSegmentsSuccessAction = Action<
  SnapshotsActionType.MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_SUCCESS
>;

export interface GetMultiSnapshotExportSegmentsErrorAction extends Action<
  SnapshotsActionType.MULTI_SNAPSHOT_EXPORT_SEGMENTS_GET_ERROR
> {
  payload: {
    error: MetadataErrorResponse;
  };
}

export interface DownloadSnapshotExportSegmentRequestAction {
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_REQUEST;
  payload: {
    repoId: string;
    snapshotId: string;
    exportId: string;
    segmentNumber: number;
  };
}

export interface DownloadSnapshotExportSegmentSuccessAction {
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_SUCCESS;
  payload: {
    repoId: string;
    snapshotId: string;
    exportId: string;
    segmentNumber: number;
    url: string;
  };
}

export interface DownloadSnapshotExportSegmentFailureAction {
  type: SnapshotsActionType.SNAPSHOT_EXPORT_SEGMENT_DOWNLOAD_FAILURE;
  payload: {
    repoId: string;
    snapshotId: string;
    exportId: string;
    segmentNumber: number;
    error: MetadataErrorResponse;
  };
}


export type SnapshotsAction =
| GetSnapshotsRequestAction
| GetSnapshotsSuccessAction
| GetSnapshotsErrorAction
| SnapshotCreateRequestAction
| SnapshotCreateSuccessAction
| SnapshotCreateErrorAction
| SnapshotExportCreateRequestAction
| SnapshotExportCreateSuccessAction
| SnapshotExportCreateErrorAction
| GetMultiSnapshotExportsRequestAction
| GetMultiSnapshotExportsSuccessAction
| GetMultiSnapshotExportsErrorAction
| GetSnapshotExportSegmentsRequestAction
| GetSnapshotExportSegmentsSuccessAction
| GetSnapshotExportSegmentsErrorAction
| GetMultiSnapshotExportSegmentsRequestAction
| GetMultiSnapshotExportSegmentsSuccessAction
| GetMultiSnapshotExportSegmentsErrorAction
| DownloadSnapshotExportSegmentRequestAction
| DownloadSnapshotExportSegmentSuccessAction
| DownloadSnapshotExportSegmentFailureAction

export type ByRepoIdType = { [repoId: string]: string[] } | undefined;
export type BySnapshotIdType = { [snapshotId: string]: string[] } | undefined;
export type ByExportIdType = { [exportId: string]: string[] } | undefined;
export interface DownloadStatus {
  isDownloading: boolean;
  succeeded?: boolean;
  error?: MetadataErrorResponse;
  downloadedAt?: string;
}

export interface State {
  snapshots: {
    byId: { [id: string]: Snapshot };
    byRepoId: ByRepoIdType;
  };
  exports: {
    byId: { [id: string]: SnapshotExport };
    bySnapshotId: BySnapshotIdType;
  };
  segments: {
    byId: { [id: string]: SnapshotExportSegment };
    byExportId: ByExportIdType;
  };
  downloadStatuses: {
    [exportId: string]: {
        [segmentNumber: number]: DownloadStatus;
    };
  };
}
