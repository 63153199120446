/** @module store/me */
import { Action } from 'redux';
import { Me } from 'services/metadata';

export enum MeActionType {
  ME_GET_REQUEST = 'ME_GET_REQUEST',
  ME_GET_SUCCESS = 'ME_GET_SUCCESS',
  ME_GET_ERROR = 'ME_GET_ERROR',
  ME_REGISTER_REQUEST = 'ME_REGISTER_REQUEST',
  ME_REGISTER_SUCCESS = 'ME_REGISTER_SUCCESS',
  ME_REGISTER_ERROR = 'ME_REGISTER_ERROR',
}

export type GetMeRequestAction = Action<MeActionType.ME_GET_REQUEST>;

export interface GetMeSuccessAction extends Action<MeActionType.ME_GET_SUCCESS> {
  payload: {
    me: Me;
  };
}

export interface GetMeErrorAction extends Action<MeActionType.ME_GET_ERROR> {
  payload: {
    error: string;
  };
}

export type RegisterMeRequestAction = Action<MeActionType.ME_REGISTER_REQUEST>;

export interface RegisterMeSuccessAction extends Action<MeActionType.ME_REGISTER_SUCCESS> {
  payload: {
    me?: Me;
  };
}

export interface RegisterMeErrorAction extends Action<MeActionType.ME_REGISTER_ERROR> {
  payload: {
    error: string;
  };
}

export type MeAction = GetMeRequestAction
                     | GetMeSuccessAction
                     | GetMeErrorAction
                     | RegisterMeRequestAction
                     | RegisterMeSuccessAction
                     | RegisterMeErrorAction;

export type MeState = Me;
