/** @module components/ButtonsField */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-amber.svg';
import { ReactComponent as NoEditIcon } from 'assets/icons/no-edit.svg';
import RepositoryMenu from '../RepositoryMenu';
import { Props } from './types';
import './styles.scss';

/**
 * Displays Buttons on last table's column
 */
function ButtonsField(props: Props): JSX.Element {
  const {
    repository,
    setEditName,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="ButtonsField">
      {repository.isLimited ? (
        <Popover
          placement="topLeft"
          content={(
            <div className="warning-pop-over">
              <div className="info-icon">
                <InfoOutlinedIcon />
              </div>
              <span>{t('ButtonsField.limited.me.popover', 'This repository belongs to a different business. Your collaboration status is limited.')}</span>
            </div>
          )}
        >
          <span className="warning-icon" data-test-id="warning-icon"><WarningIcon height="21" width="22" /></span>
        </Popover>
      ) : (
        <div className="warning-icon-placeholder" />
      )}
      {repository.isEvicted ? (
        <Popover
          placement="left"
          content={(
            <div className="no-edit-pop-over">
              <div className="info-icon">
                <InfoOutlinedIcon />
              </div>
              <span>{t('ButtonsField.no-edit.popover', 'Repository Extracted. No further changes may be made to this repository.')}</span>
            </div>
          )}
        >
          <span className="no-edit-icon" data-test-id="no-edit-icon"><NoEditIcon height="21" width="22" /></span>
        </Popover>
      ) : (
        <RepositoryMenu
          repositoryId={repository.id}
          setEditName={setEditName}
          iconSize="small"
          data-test-id="menu"
        />
      )}
    </div>
  );
}

export default ButtonsField;
