/** @module store/licenses */
import MetadataService from 'services/metadata';
import UsProtectionsService from 'services/us-protections';
import { AppDispatch } from 'store/types';
import {
  LicenseActionType,
  LicensesGetRequestAction,
  LicensesGetSuccessAction,
  LicensesGetErrorAction,
  LicensesAddRequestAction,
  LicensesAddSuccessAction,
  LicensesAddErrorAction,
  LicensesDeleteRequestAction,
  LicensesDeleteSuccessAction,
  LicensesDeleteErrorAction,
  LicensesGetAllRequestAction,
  LicensesGetAllSuccessAction,
  LicensesGetAllErrorAction,
} from './types';

export const getLicensesRequest = (): LicensesGetRequestAction => ({
  type: LicenseActionType.LICENSES_GET_REQUEST,
});

export const getLicensesSuccess = (
  repositoryId: string,
  licenses: string[],
): LicensesGetSuccessAction => ({
  type: LicenseActionType.LICENSES_GET_SUCCESS,
  payload: {
    repositoryId,
    licenses,
  },
});

export const getLicensesError = (error: Error): LicensesGetErrorAction => ({
  type: LicenseActionType.LICENSES_GET_ERROR,
  payload: {
    error,
  },
});

export const addLicenseRequest = (): LicensesAddRequestAction => ({
  type: LicenseActionType.LICENSES_ADD_REQUEST,
});

export const addLicenseSuccess = (
  repositoryId: string,
  license: string,
): LicensesAddSuccessAction => ({
  type: LicenseActionType.LICENSES_ADD_SUCCESS,
  payload: {
    repositoryId,
    license,
  },
});

export const addLicenseError = (error: Error): LicensesAddErrorAction => ({
  type: LicenseActionType.LICENSES_ADD_ERROR,
  payload: {
    error,
  },
});

export const deleteLicenseRequest = (): LicensesDeleteRequestAction => ({
  type: LicenseActionType.LICENSES_DELETE_REQUEST,
});

export const deleteLicenseSuccess = (
  repositoryId: string,
  license: string,
): LicensesDeleteSuccessAction => ({
  type: LicenseActionType.LICENSES_DELETE_SUCCESS,
  payload: {
    repositoryId,
    license,
  },
});

export const deleteLicenseError = (
  error: Error,
  repositoryId: string,
  license: string,
): LicensesDeleteErrorAction => ({
  type: LicenseActionType.LICENSES_DELETE_ERROR,
  payload: {
    error,
    repositoryId,
    license,
  },
});

export const getAllLicensesRequest = (): LicensesGetAllRequestAction => ({
  type: LicenseActionType.LICENSES_GET_ALL_REQUEST,
});

export const getAllLicensesSuccess = (licenses: string[]): LicensesGetAllSuccessAction => ({
  type: LicenseActionType.LICENSES_GET_ALL_SUCCESS,
  payload: {
    licenses,
  },
});

export const getAllLicensesError = (error: Error): LicensesGetAllErrorAction => ({
  type: LicenseActionType.LICENSES_GET_ALL_ERROR,
  payload: {
    error,
  },
});

export function getLicenses(repositoryId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getLicensesRequest());
    try {
      const licenses = await new MetadataService().getRepositoryLicenses(repositoryId);
      dispatch(getLicensesSuccess(repositoryId, licenses));
    } catch (error) {
      dispatch(getLicensesError(error));
    }
  };
}

export function addLicense(repositoryId: string, license: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(addLicenseRequest());
    try {
      await new MetadataService().addRepositoryLicenses(repositoryId, [license]);
      dispatch(addLicenseSuccess(repositoryId, license));
    } catch (error) {
      dispatch(addLicenseError(error));
    }
  };
}

export function deleteLicense(repositoryId: string, license: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(deleteLicenseRequest());
    try {
      await new MetadataService().deleteRepositoryLicenses(repositoryId, [license]);
      dispatch(deleteLicenseSuccess(repositoryId, license));
    } catch (error) {
      dispatch(deleteLicenseError(error, repositoryId, license));
    }
  };
}

export function getAllLicenses() {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getAllLicensesRequest());
    const cachedExpiration = localStorage.getItem('licensesExpiration');
    const cachedLicenses = localStorage.getItem('licenses');
    let shouldUpdate = true;
    if (cachedExpiration) {
      const now = new Date();
      const cachedExpirationDate = new Date(cachedExpiration);
      if (now < cachedExpirationDate) {
        shouldUpdate = false;
      }
    }
    if (shouldUpdate || !cachedLicenses) {
      try {
        const licenses = await new UsProtectionsService().getLicenses();
        const expiration = new Date();
        expiration.setDate(expiration.getDate() + 1);
        localStorage.setItem('licensesExpiration', expiration.toISOString());
        localStorage.setItem('licenses', JSON.stringify(licenses));
        dispatch(getAllLicensesSuccess(licenses));
      } catch (error) {
        dispatch(getAllLicensesError(error));
      }
    } else {
      const licenses = JSON.parse(cachedLicenses);
      dispatch(getAllLicensesSuccess(licenses));
    }
  };
}
