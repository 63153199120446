/** @module components/UploadMenu */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Menu } from 'antd';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as AddFilesIcon } from 'assets/icons/add.svg';
import { ReactComponent as FileIcon } from 'assets/icons/document.svg';
import UploadFolderButton from 'components/UploadFolderButton';
import { Props } from './types';
import './styles.scss';

/**
 * A menu that displays upload options.
 * @param props The component props
 * @return A JSX Element
 */
function UploadMenu(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    onSelectFileUpload,
    folderItems,
    folderId,
    repositoryId,
  } = props;

  return (
    <div className="UploadMenu">
      <Dropdown
        overlay={(
          <Menu className="menu">
            <Menu.Item
              key="file"
              data-test-id="file"
            >
              <Button
                type="link"
                data-test-id="button-file"
                onClick={(): void => onSelectFileUpload()}
              >
                <FileIcon width={15} height={15} className="icon--primary" />
                {t('UploadMenu.file', 'File')}
              </Button>
            </Menu.Item>
            <Menu.Item
              key="folder"
              data-test-id="folder"
            >
              <UploadFolderButton
                data-test-id="button-folder"
                folderItems={folderItems}
                folderId={folderId}
                repositoryId={repositoryId}
              />
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
        data-test-id="dropdown"
      >
        <div>
          <Button
            data-test-id="button-upload"
            type="primary"
            className="upload-button"
          >
            <AddFilesIcon className="add-icon" width={15} height={15} />
            {t('UploadMenu.upload', 'UPLOAD')}
            <ChevronDown className="down-icon" width={20} height={20} />
          </Button>
        </div>
      </Dropdown>
    </div>
  );
}

export default UploadMenu;
