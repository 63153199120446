/** @module components/PreviewFileModal */
import React from 'react';
import { Button, Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import { ReactComponent as REDshareLogo } from 'assets/images/redshare-primary.svg';
import DownloadFileButton from '../DownloadFileButton';
import Loader from '../Loader';
import { Props } from './types';
import './styles.scss';

class PreviewFileModal extends React.Component<Props> {
  componentDidMount(): void {
    const { fetchFilePreview } = this.props;
    fetchFilePreview();
  }

  componentDidUpdate(): void {
    const { handleClose, error } = this.props;
    if (error) {
      handleClose();
    }
  }

  render(): JSX.Element {
    const {
      fetching,
      fileId,
      filePreviewName,
      handleClose,
      visible,
      canDownload,
      previewUrl,
      t,
    } = this.props;
    return (
      <Modal
        onCancel={(): void => handleClose()}
        className="PreviewFileModal"
        width="90vw"
        centered
        visible={visible}
        footer={[
          <Button
            type="primary"
            key="close"
            onClick={(): void => handleClose()}
            data-test-id="close"
          >
            {t('PreviewFileModal.button.close', 'Close')}
          </Button>,
        ]}
        data-test-id="modal"
      >
        <div className="header">
          <div className="branding">
            <REDshareLogo className="logo" width={200} />
            <div className="tagline">
              {t('PreviewFileModal.logo.tagline', 'Regulated, Export and Defense Data Share')}
            </div>
          </div>
          <h3 className="filename">
            {filePreviewName}
          </h3>
          <div className="actions">
            {canDownload && (
              <DownloadFileButton
                item={{
                  type: 'file',
                  name: filePreviewName,
                  id: fileId,
                }}
                data-test-id="download"
              />
            )}
          </div>
        </div>
        <div className="content-wrapper">
          {fetching && <Loader loading data-test-id="loader" />}
          {!fetching && (
            <iframe
              title={t('PreviewFileModal.iFrame.title', 'File Preview')}
              className="content"
              frameBorder="0"
              src={previewUrl}
              allowFullScreen
              data-test-id="file-preview"
            />
          )}
        </div>
      </Modal>
    );
  }
}
export default withTranslation()(PreviewFileModal);
