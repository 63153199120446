/** @module components/UploadQueue */
import { useEffect } from 'react';
import { UploadStatus } from 'services/storage';
import { Props } from './types';

/**
 * Uploads files that have been enqueued.
 * @param props The props
 * @return null
 */
function UploadQueue(props: Props): null {
  const {
    concurrentUploads,
    fileUploads,
    folderUploads,
    uploadFile,
    uploadFolder,
  } = props;

  useEffect(() => {
    const activeFileUploads = fileUploads.filter((u) => u.status === UploadStatus.ACTIVE);
    if (activeFileUploads.length < concurrentUploads) {
      const enqueuedFileUpload = fileUploads.find((u) => u.status === UploadStatus.ENQUEUED);
      if (enqueuedFileUpload) {
        uploadFile(enqueuedFileUpload);
      }
    }
  }, [concurrentUploads, fileUploads, uploadFile]);

  useEffect(() => {
    const cancelledFileUploads = fileUploads.filter((u) => u.status === UploadStatus.CANCELLED);
    cancelledFileUploads.forEach((upload) => {
      if (
        upload.xhr.readyState >= XMLHttpRequest.OPENED
        && upload.xhr.readyState < XMLHttpRequest.DONE
      ) {
        upload.xhr.abort();
      }
    });
  }, [fileUploads]);

  useEffect(() => {
    const activeFolderUploads = folderUploads.filter((u) => u.status === UploadStatus.ACTIVE);
    // only one folder uploads at a time
    if (activeFolderUploads.length === 0) {
      const enqueuedFolderUpload = folderUploads.find((u) => u.status === UploadStatus.ENQUEUED);
      if (enqueuedFolderUpload) {
        uploadFolder(enqueuedFolderUpload);
      }
    }
  }, [folderUploads, uploadFolder]);

  return null;
}

export default UploadQueue;
