/** @module components/ProtectionTag */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Tooltip } from 'antd';
import { LicenseType, ProtectionType } from 'services/metadata';
import { Props } from './types';

export default function ProtectionTag(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { protection, className } = props;
  function getProtectionColor(): string {
    switch (protection) {
      case LicenseType.GENE:
        return 'purple';
      case LicenseType.NLR:
        return 'green';
      case LicenseType.US:
        return 'blue';
      case LicenseType.LR:
        return 'orange';
      case LicenseType.CDI:
        return 'magenta';
      case LicenseType.US_CITIZEN:
        return 'cyan';
      case LicenseType.DOC:
        return 'red';
      case LicenseType.GPLR:
        return 'geekblue';
      case ProtectionType.LIMITED:
        return 'gold';
      default:
        return 'grey';
    }
  }
  const protectionDetails = {
    [LicenseType.US]: t('ProtectionTag.tooltip.US', 'US Persons'),
    [LicenseType.CDI]: t('ProtectionTag.tooltip.CDI', 'Covered Defense Information'),
    [LicenseType.LR]: t('ProtectionTag.tooltip.LR', 'License Required'),
    [LicenseType.GENE]: t('ProtectionTag.tooltip.GENE', 'GE Nuclear Energy'),
    [LicenseType.US_CITIZEN]: t('ProtectionTag.tooltip.US_CITIZEN', 'US Citizen'),
    [LicenseType.NLR]: t('ProtectionTag.tooltip.NLR', 'No License Required'),
    [LicenseType.DOC]: t('ProtectionTag.tooltip.DOC', 'Department of Commerce'),
    [LicenseType.GPLR]: t('ProtectionTag.tooltip.GPLR', 'Gas Power License Required'),
    [ProtectionType.LIMITED]: t('ProtectionTag.tooltip.LIMITED', 'This repository belongs to a different business. Your collaboration status is limited.'),
  };

  return (
    <Tooltip title={protectionDetails[protection]} data-test-id="tool-tip">
      <Tag
        className={className}
        key={protection}
        color={getProtectionColor()}
        data-test-id="tag"
      >
        {protection}
      </Tag>
    </Tooltip>
  );
}
