/** @module components/MoveFileModal */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { updateFileParent } from 'store/repositoryItems/actions';
import MoveFileModal from './component';
import { DispatchProps, StateProps, OwnProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'REPOSITORY_ITEMS_UPDATE'),
});

export const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps): DispatchProps => ({
  updateFileParent(newParentId: string, callBack: Function): void {
    dispatch(updateFileParent(ownProps.file, newParentId, callBack));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MoveFileModal);
