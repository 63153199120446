/** @module utilities/file */

import { CustomCSVFile } from './types';

/**
 * Downloads a file
 * @param fileData an Object that contains the file Data, Name and Type
 * @return void
 */

const downloadFile = (fileData: CustomCSVFile): void => {
  const { data, fileName, fileType } = fileData;
  const blob = new Blob([data], { type: fileType });
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};


export default downloadFile;
