/** @module components/NewRepositoryForm */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { createRepository } from 'store/repositories/actions';
import { selectLoading } from 'store/loading/selectors';
import { selectBusinesses } from 'store/businesses/selectors';
import { selectTags } from 'store/tags/selectors';
import { getTags } from 'store/tags/actions';
import { getBusinesses } from 'store/businesses/actions';
import component from './component';
import {
  StateProps,
  DispatchProps,
} from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'REPOSITORIES_CREATE'),
  tagsLoading: selectLoading(state.loading, 'TAGS_GET'),
  businesses: selectBusinesses(state.businesses),
  businessTags: selectTags(state.tags),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  createRepository: (
    name: string,
    fedRamp: number,
    isCdi: boolean,
    isEc: boolean,
    parentBusiness: string,
    isUsOnly: boolean,
    isUkLicense: boolean,
    isLicenseAvailable: boolean,
    isUsCitizen: boolean,
    isDoc: boolean,
    tags: string[],
    fullTextSearch: boolean,
    callback?: Function,
  ): void => {
    dispatch(createRepository(
      name,
      fedRamp,
      isCdi,
      isEc,
      parentBusiness,
      isUsOnly,
      isUkLicense,
      isLicenseAvailable,
      isUsCitizen,
      isDoc,
      tags,
      fullTextSearch,
      callback,
    ));
  },
  getBusinessTags: (businessId?: string): void => { dispatch(getTags(businessId)); },
  getBusinesses: (): void => { dispatch(getBusinesses()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(component);
