/** @module store/uploads */
import { UploadFile, UploadFolder, UploadStatus } from 'services/storage';
import { State } from './types';

/**
 * Returns all file uploads as an array.
 * @param state The state
 * @return All file uploads as an array
 */
function selectFileUploads(state: State): UploadFile[] {
  return Object.values(state.files);
}

/**
 * Returns all folder uploads as an array.
 * @param state The state
 * @return All folder uploads as an array
 */
function selectFolderUploads(state: State): UploadFolder[] {
  return Object.values(state.folders);
}

/**
 * Returns all file uploads that are active or enqueued.
 * @param state The state
 * @return All file uploads that are active or enqueued
 */
function selectActiveFileUploads(state: State): UploadFile[] {
  return Object.values(state.files).filter((u) => (
    u.status === UploadStatus.ENQUEUED
    || u.status === UploadStatus.ACTIVE
  ));
}

/**
 * Returns all file uploads that are not active or enqueued.
 * @param state The state
 * @return All file uploads that are not active or enqueued
 */
function selectInactiveFileUploads(state: State): UploadFile[] {
  return Object.values(state.files).filter((u) => (
    u.status !== UploadStatus.ENQUEUED
    && u.status !== UploadStatus.ACTIVE
  ));
}

/**
 * Returns all folder uploads that are active or enqueued.
 * @param state The state
 * @return All folder uploads that are active or enqueued
 */
function selectActiveFolderUploads(state: State): UploadFolder[] {
  return Object.values(state.folders).filter((u) => (
    u.status === UploadStatus.ENQUEUED
    || u.status === UploadStatus.ACTIVE
  ));
}

export {
  selectFileUploads,
  selectActiveFileUploads,
  selectInactiveFileUploads,
  selectActiveFolderUploads,
  selectFolderUploads,
};
