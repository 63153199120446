/** @module components/DeleteUserSteps */
import { connect } from 'react-redux';
import { selectErrorMessagesForKeyword } from 'store/error/selectors';
import { selectLoadingsForKeyword } from 'store/loading/selectors';
import { GlobalState } from 'store/types';
import DeleteUserSteps from './component';
import { StateProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  errors: {
    ...selectErrorMessagesForKeyword(state.error, 'COLLABORATORS_UPDATE'),
    ...selectErrorMessagesForKeyword(state.error, 'COLLABORATORS_ADD'),
  },
  loadings: {
    ...selectLoadingsForKeyword(state.loading, 'COLLABORATORS_UPDATE'),
    ...selectLoadingsForKeyword(state.loading, 'COLLABORATORS_ADD'),
  },
});

export default connect(
  mapStateToProps,
  {},
)(DeleteUserSteps);
