/** @module components/ExistingCollaboratorsTable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Popconfirm,
  Modal,
  Table,
  Select,
  Popover,
  Button,
} from 'antd';
import { Collaborator } from 'services/metadata';
import { generatePaginationOptions } from 'utilities/antd';
import { history } from 'utilities/history';
import { appPaths } from 'utilities/routes';
import { ReactComponent as TrashCanIcon } from 'assets/icons/delete.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-amber.svg';
import { ReactComponent as RenewIcon } from 'assets/icons/reload.svg';
import { sortAlphabetical } from 'utilities/sort';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SearchFilter from 'components/SearchFilter';
import { getCountDown } from 'utilities/date';
import { exportCollaboratorsToCsv, downloadFile } from 'utilities/file';
import TableRefresh from '../TableRefresh';
import { Props } from './types';
import './styles.scss';

const ecRenewAlertDays = 7;

/**
 * A Table which lists all the collaborators associated with a repository
 * and gives the ablilty to update their access.
 * @param props The component props
 * @return a JSX Element
 */
function ExistingCollaboratorsTable(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    collaborators,
    loading,
    refreshing,
    getCollaborators,
    repository,
    me,
    removeCollaborator,
    updateCollaborator,
    renewECEnabled,
    renewCollaborator,
    canRemoveCollabs,
    canUpdateCollabs,
    canUpdateRepoOwner,
    showRemoveColumn = true,
    showExportToCsvButton = false,
  } = props;


  /**
   * Returns a string or element containing the number of days until the
   * given collaborator expires, or a dash if the given collaborator
   * is not external.
   * @param collaborator A collaborator
   * @return a string containing the number of days until the
   * given collaborator expires, or a dash if the given collaborator
   * is not external
   */
  function getCountDownAndRenewButton(
    collaborator: Collaborator,
    canRenewCollab: boolean,
  ): string | JSX.Element {
    if (collaborator.user.external) {
      let className = '';
      const countDown = parseInt(getCountDown(new Date(collaborator.expiration)).toFixed(0), 10);
      if (countDown <= 14 && countDown >= 8) className = 'red-text';
      else if (countDown < 8 && countDown > 0) className = 'bold-red-text';
      return (
        <span className={className}>
          {countDown}
          {canRenewCollab && countDown > 0 && (
            <RenewIcon
              height="18"
              width="18"
              className="renew-icon icon--primary"
              data-test-id="renew-icon"
              onClick={(): void => renewCollaborator(repository.id, collaborator)}
            />
          )}
        </span>
      );
    }
    return '';
  }

  /**
   * Called when a collaborator role has been changed.
   * @param collaborator A collaborator
   * @param newRole The new role
   */
  function onChange(collaborator: Collaborator, newRole: string): void {
    if (newRole.toUpperCase() !== collaborator.role.toUpperCase()) {
      if (newRole.toUpperCase() === 'OWNER') {
        Modal.confirm({
          title: t('ExistingCollaboratorsTable.confirmOwnerChange.title', 'Are you sure you want to change the owner of this repository?'),
          content: t('ExistingCollaboratorsTable.confirmOwnerChange.content', ''),
          onOk: () => updateCollaborator(repository.id, collaborator, newRole),
          onCancel: () => { },
        });
      } else {
        const isSelf = (me && me.user) && (me.user.id === collaborator.user.id);
        updateCollaborator(repository.id, collaborator, newRole, isSelf);
      }
    }
  }

  function isCollabExpired(collaborator: Collaborator): boolean {
    if (collaborator.user.external && renewECEnabled) {
      const remainingDaysForExpiration = getCountDown(new Date(collaborator.expiration)).toFixed(0);
      const isExpired = parseInt(remainingDaysForExpiration, 10) <= 0;
      return isExpired;
    }
    return false;
  }

  function isCollabExpiringSoon(collaborator: Collaborator): boolean {
    if (collaborator.user.external && renewECEnabled) {
      const remainingDaysForExpiration = getCountDown(new Date(collaborator.expiration)).toFixed(0);
      return parseInt(remainingDaysForExpiration, 10) <= ecRenewAlertDays
      && !isCollabExpired(collaborator);
    }
    return false;
  }

  function renderName(collaborator: Collaborator): JSX.Element {
    const isExpiringSoon = isCollabExpiringSoon(collaborator);
    const isLimited = collaborator.limited;
    let popoverText = '';

    if (isExpiringSoon) {
      popoverText += t('ExistingCollaboratorsTable.expiring.popover', 'User’s access to this repository will expire within 7 days. ');
    }

    if (isLimited) {
      const collabIsMe = me.user ? collaborator.user.id === me.user.id : false;
      const popoverMessage = collabIsMe ? t('ExistingCollaboratorsTable.limited.me.popover', 'This repository belongs to a different business. Your collaboration status is limited. ')
        : t('ExistingCollaboratorsTable.limited.collab.popover', 'This repository belongs to a different business than the collaborator. Collaboration status is limited. ');
      popoverText += popoverMessage;
    }

    return (
      <div data-test-id="collaborator-name">
        {isExpiringSoon || isLimited ? (
          <Popover
            placement="topLeft"
            content={(
              <div className="warning-pop-over">
                <div className="info-icon">
                  <InfoOutlinedIcon />
                </div>
                <span>{popoverText}</span>
              </div>
            )}
          >
            <span className="warning-icon" data-test-id="warning-icon"><WarningIcon height="21" width="22" /></span>
          </Popover>
        ) : null}
        <span className="name">{collaborator.user && collaborator.user.name}</span>
      </div>
    );
  }

  const exportToCsv = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    if (collaborators) {
      exportCollaboratorsToCsv(collaborators, repository, downloadFile, renewECEnabled);
    }
  };

  return (
    <div className="ExistingCollaboratorsTable">
      <div className="table-refresh">
        <TableRefresh
          refreshing={refreshing}
          onRefresh={(): void => getCollaborators()}
          data-test-id="refresh"
        />
        {
          showExportToCsvButton
            ? (
              <Button
                type="primary"
                className="button"
                data-test-id="export-collaborators-csv"
                onClick={exportToCsv}
              >
        Save as CSV
              </Button>
            )
            : null
        }
      </div>
      <Table
        dataSource={collaborators}
        pagination={generatePaginationOptions(collaborators)}
        className="collaborators_table"
        rowKey="id"
        size="middle"
        loading={loading}
        rowClassName={(record): string => (isCollabExpired(record) ? 'disabled' : '')}
        data-test-id="table"
      >
        <Table.Column
          title={t('ExistingCollaboratorsTable.column.name', ' NAME')}
          key="name"
          render={(collaborator: Collaborator): JSX.Element => (
            renderName(collaborator)
          )}
          sorter={(a: Collaborator, b: Collaborator): number => sortAlphabetical(
            a.user.name, b.user.name,
          )}
          filterDropdown={({ setSelectedKeys, confirm, clearFilters }): JSX.Element => (
            <SearchFilter
              onSearch={(input: React.Key): void => {
                if (setSelectedKeys) {
                  setSelectedKeys([input]);
                }
                if (confirm) {
                  confirm();
                }
              }}
              onReset={(): void => {
                if (clearFilters) {
                  clearFilters();
                }
              }}
            />
          )}
          onFilter={(value, collaborator: Collaborator): boolean => {
            if (typeof value === 'string') {
              return collaborator.user.name.toLowerCase().includes(value.toLowerCase());
            }
            return false;
          }}
          data-test-id="column-name"
        />
        <Table.Column
          title={t('ExistingCollaboratorsTable.column.access', 'ACCESS')}
          key="role"
          data-test-id="type-column"
          render={(collaborator: Collaborator): JSX.Element => (
            (collaborator.role.toUpperCase() === 'OWNER')
              || !canUpdateCollabs
              || (isCollabExpired(collaborator)) ? (
                <div className="role-name" data-test-id="role-name">{collaborator.role}</div>
              ) : (
                <Select
                  value={collaborator.role.toUpperCase()}
                  style={{ width: 164 }}
                  onChange={(value: string): void => onChange(
                    collaborator,
                    value.toString().toUpperCase(),
                  )}
                  data-test-id="select-role"
                >
                  {!collaborator.user.external
                    && canUpdateRepoOwner && !collaborator.user.preregistered && (
                      <Select.Option value="Owner" data-test-id="owner-option">
                        {t('ExistingCollaboratorsTable.type.owner.caps', 'OWNER')}
                      </Select.Option>
                  )}
                  {!collaborator.user.external && !collaborator.user.preregistered && (
                    <Select.Option value="CoOwner">
                      {t('ExistingCollaboratorsTable.type.coowner.caps', 'CO-OWNER')}
                    </Select.Option>
                  )}
                  <Select.Option value="Editor">
                    {t('ExistingCollaboratorsTable.type.editor.caps', 'EDITOR')}
                  </Select.Option>
                  <Select.Option value="Viewer">
                    {t('ExistingCollaboratorsTable.type.viewer.caps', 'VIEWER')}
                  </Select.Option>
                </Select>
              )
          )}
        />

        <Table.Column
          title={t('ExistingCollaboratorsTable.column.status', 'STATUS')}
          data-test-id="status-column"
          render={(collaborator: Collaborator): JSX.Element => {
            let status = t('ExistingCollaboratorsTable.status.active', 'Active');
            const isPreregistered = collaborator.user.preregistered;
            if (isCollabExpired(collaborator)) {
              status = t('ExistingCollaboratorsTable.status.expired', 'Expired');
            } else if (isPreregistered) {
              status = t('ExistingCollaboratorsTable.status.pending', 'Pending');
            }
            return (
              <div data-test-id="status" className="status">
                <span className={`${isPreregistered ? 'red-text' : ''}`}>{status}</span>
                {isPreregistered && (
                  <Popover content={t('ExistingCollaboratorsTable.status.tooltip', 'Registration has not been completed')} data-test-id="tooltip">
                    <InfoOutlinedIcon className="info-icon" />
                  </Popover>
                )}
              </div>
            );
          }}
        />

        {renewECEnabled && (
          <Table.Column
            title={t('ExistingCollaboratorsTable.column.daysRemaining', 'DAYS REMAINING')}
            align="center"
            render={(collaborator: Collaborator): JSX.Element => (
              <p data-test-id="days-remaining" className="days-remaining">
                {getCountDownAndRenewButton(collaborator, canUpdateCollabs)}
              </p>
            )}
          />
        )}

        {showRemoveColumn && (
          <Table.Column
            data-test-id="remove-column"
            title={t('ExistingCollaboratorsTable.column.remove', 'REMOVE')}
            className="remove-collaborator"
            align="center"
            render={(collaborator: Collaborator): JSX.Element | null => {
              const isSelf = (me && me.user) && (me.user.id === collaborator.user.id);
              const isOwner = (collaborator.role.toUpperCase() === 'OWNER');
              const enabled = (
                (canRemoveCollabs || isSelf)
                && !isOwner
              );
              if (enabled) {
                return (
                  <Popconfirm
                    placement="rightTop"
                    cancelButtonProps={{ className: 'ant-btn-ghost' }}
                    title={t(
                      'ExistingCollaboratorsTable.popConfirm.title',
                      'Remove {{name}}?',
                      { name: collaborator.user.name },
                    )}
                    onConfirm={(): void => removeCollaborator(
                      repository.id,
                      collaborator.id,
                      isSelf,
                      isSelf ? (): void => history.push(appPaths.sharedRepositories) : undefined,
                    )}
                    onCancel={(): void => { }}
                    okText={t('ExistingCollaboratorsTable.popConfirm.okText', 'Remove')}
                    okButtonProps={{ className: 'alert-button' }}
                    cancelText={t('ExistingCollaboratorsTable.popConfirm.cancelText', 'Cancel')}
                    data-test-id="pop-confirm"
                  >
                    <TrashCanIcon className="remove-collaborator-button" />
                  </Popconfirm>
                );
              }
              return null;
            }}
          />
        )}
      </Table>
    </div>
  );
}

export default ExistingCollaboratorsTable;
