/** @module components/DashboardPageHeader */
import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Popover } from 'antd';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UpIcon } from 'assets/icons/missed-call.svg';
import { ReactComponent as NoEditIcon } from 'assets/icons/no-edit.svg';
import { Props } from './types';
import Breadcrumb from '../Breadcrumb';
import './styles.scss';

function DashboardPageHeader(props: Props): JSX.Element {
  const {
    actions,
    menu,
    title,
    upLink,
    tags,
    protections,
    business,
    description,
    evictionStatus,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="DashboardPageHeader">
      <div className="top">
        {
          upLink
          && (
            <Link to={upLink}>
              <UpIcon className="up-icon" />
            </Link>
          )
        }
        <div className="title">
          {title || ' '}
          {description && (
          <div className="description" data-test-id="description">
            {description}
          </div>
          )}
        </div>
        {menu}
        {evictionStatus && (
          <Popover
            placement="right"
            content={(
              <div className="no-edit-pop-over">
                <div className="info-icon">
                  <InfoOutlinedIcon />
                </div>
                <span>
                  {
                t('DashboardPageHeader.no-edit.popover',
                  'Repository Extracted by {{userName}} on {{dateTime}}. No further changes may be made to this repository.',
                  {
                    userName: evictionStatus.evictionStatus.evictedBy.name,
                    dateTime: new Date(evictionStatus.evictionStatus.evictedAt)
                      .toLocaleString(navigator.language),
                  })
                }
                </span>
              </div>
            )}
          >
            <span className="no-edit-icon" data-test-id="no-edit-icon"><NoEditIcon height="21" width="22" /></span>
          </Popover>
        )}
      </div>
      {
        (protections || tags || business)
        && (
          <>
            <div className="repo-info-container" data-test-id="repo-info-container">
              {business && (
              <div className="business" data-test-id="business">
                <div className="heading">
                  {t('DashboardPageHeader.business', 'Business')}
                </div>
                <span data-test-id="business-name" className="business-name">
                  {business}
                </span>
              </div>
              )}
              {protections && protections.length > 0 && (
                <div className="protections" data-test-id="protections">
                  <div className="heading">
                    {t('DashboardPageHeader.protections', 'Protections')}
                  </div>
                  {protections}
                </div>
              )}
              {tags && tags.length > 0 && (
                <div className="tags" data-test-id="tags">
                  <div className="heading">
                    {t('DashboardPageHeader.tags', 'Tags')}
                  </div>
                  {tags}
                </div>
              )}
            </div>
            <Divider className="divider" data-test-id="divider" />
          </>
        )
      }
      <Breadcrumb />
      <div className="actions">
        {actions}
      </div>
    </div>
  );
}

export default DashboardPageHeader;
