export const supportDL = 'redshare.support@ge.com';
export const yammerGroupURL = 'https://www.yammer.com/ge.com/#/threads/inGroup?type=in_group&feedId=15455088&view=all';
export const reportIssueURL = 'http://sc.ge.com/*redsharefeedback';
export const tagsCharacterLimit = 25;
export const supportURL = 'http://sc.ge.com/*redsharefeedback';

// Local storage keys
export const LOCAL_STORAGE_KEY_PAGE_SIZE = 'pageSize';
export const LOCAL_STORAGE_KEY_DOC_BANNER = 'docBannerSeen';
export const LOCAL_STORAGE_KEY_FOR_VIEW = 'showGridView';
export const LOCAL_STORAGE_KEY_CHILD_FOLDER_TO_FIND_PAGE = 'childFolderToFindPage';
