/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TablePaginationConfig } from 'antd/lib/table';
import { generatePaginationOptions } from 'utilities/antd';
import { LOCAL_STORAGE_KEY_PAGE_SIZE } from 'utilities/constants';
import { Divider } from 'antd';
import { getAppRoutes } from 'utilities/routes';
import queryString from 'query-string';
import DashboardPage from 'components/DashboardPage';
import SearchResultsTable from 'components/SearchResultsTable';
import TableRefresh from '../TableRefresh';
import { Props } from './types';
import './styles.scss';

function SearchResultsPage(props: Props): JSX.Element {
  const savedPageSize = localStorage.getItem(LOCAL_STORAGE_KEY_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(
    savedPageSize ? parseInt(savedPageSize, 10) : 12,
  );
  const { t } = useTranslation();

  const {
    location,
    getRepositories,
    repositoriesExist,
    getSearchResult,
    me,
    trackClick,
    loading,
    searchResult,
    meta,
    repositories,
  } = props;

  const { search } = location;
  const parsedQueryString = queryString.parse(location.search);
  const repoIds = parsedQueryString.repoIds ? parsedQueryString.repoIds.toString() : null;
  const goBackUrl = parsedQueryString.goBackUrl ? parsedQueryString.goBackUrl.toString() : null;
  const query = parsedQueryString.query ? parsedQueryString.query.toString() : '';
  const { currentRepoOnly, includeFileContent } = parsedQueryString;
  let isSearchAcrossRepos: boolean;
  if (currentRepoOnly && currentRepoOnly === 'true') {
    isSearchAcrossRepos = false;
  } else {
    isSearchAcrossRepos = true;
  }
  let isIncludingFileContent: boolean;
  if (includeFileContent && includeFileContent === 'true') {
    isIncludingFileContent = true;
  } else {
    isIncludingFileContent = false;
  }

  const getResults = useCallback((selectedPageNumber: number, selectedPageSize: number): void => {
    const decodedRepoIds = repoIds ? repoIds.split(',') : [];
    if (me.user && me.user.id && query.length) {
      getSearchResult(
        query,
        selectedPageNumber,
        selectedPageSize,
        me.user && me.user.id,
        decodedRepoIds,
        decodedRepoIds.length === 0 || isSearchAcrossRepos,
        isIncludingFileContent,
      );
    }
  }, [getSearchResult, me.user, query, repoIds, isSearchAcrossRepos, isIncludingFileContent]);

  /**
   * Resets the page number when the location changes
   * @param nextProps The new props
   */
  useEffect(() => {
    setPageNumber(1);
    getResults(1, pageSize);
  }, [search, pageSize, getResults]);

  useEffect(() => {
    if (!repositoriesExist) getRepositories();
  }, [repositoriesExist, getRepositories]);

  const onChangePagination = (options: TablePaginationConfig): void => {
    if (options.pageSize && options.pageSize !== pageSize) {
      setPageSize(options.pageSize);
    } else {
      getResults(options.current || pageNumber, options.pageSize || pageSize);
      if (options.current) setPageNumber(options.current);
    }
  };

  /**
   * Takes in a total amount of search result records and returns a meaningful amount that should
   * be displayed in pagination; if you get a million results
   * it would be bad ux to paginate and make all results browsable
   *
   * @param totalRecords total amount of search result records returned by api
   * @param currentPageSize refers to the pagination size, current records per page
   * @returns a number of totalRecordsToDisplay, to be passed to a pagination component
   */
  const getTotalRecordsToDisplay = (
    totalRecords: number,
    currentPageSize: number,
  ): number => {
    // this is an arbitrary amount of pagination pages that is deemed to be a reasonable max
    const MAX_PAGINATION_PAGES = 100;
    // this is the current number of pagination records per page
    const RECORDS_PER_PAGINATION_PAGE = currentPageSize;
    let result;
    if (totalRecords / RECORDS_PER_PAGINATION_PAGE > MAX_PAGINATION_PAGES) {
      result = MAX_PAGINATION_PAGES * RECORDS_PER_PAGINATION_PAGE;
    } else {
      result = totalRecords;
    }
    return result;
  };

  const getUplink = (): string => (goBackUrl || getAppRoutes('', '').myRepositories);
  return (
    <div className="SearchResultsPage">
      <DashboardPage
        title={(
          <>
            {t('SearchResultsPage.title', 'Search results for')}
            {' '}
            <span className="highlight">{query}</span>
          </>
        )}
        data-test-id="page"
        hasBreadcrumbs={false}
        upLink={getUplink()}
      >
        {!loading && searchResult.length === 0 ? (
          <div className="no-results" data-test-id="no-results">
            {t('SearchResultsPage.noResults', 'Sorry but no results match your criteria, please try again.')}
            <div className="no-results-image" />
          </div>
        ) : (
          <>
            {searchResult.length > 0 && (
              <>
                <TableRefresh
                  refreshing={false}
                  onRefresh={(): void => getResults(pageNumber, pageSize)}
                  data-test-id="refresh"
                />
                <Divider className="divider" />
              </>
            )}
            <SearchResultsTable
              data-test-id="search-results-table"
              loading={loading}
              searchResult={searchResult}
              repositories={repositories}
              searchQuery={query}
              searchInRepoId={isSearchAcrossRepos ? null : repoIds}
              paginationOptions={generatePaginationOptions(
                searchResult,
                false,
                meta ? getTotalRecordsToDisplay(meta.page.total_results, pageSize) : undefined,
                pageNumber,
              )}
              onChangePagination={onChangePagination}
              trackClick={trackClick}
            />
          </>
        )}

      </DashboardPage>
    </div>
  );
}

export default SearchResultsPage;
