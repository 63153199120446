/** @module store/users */
import { User } from 'services/metadata';
import { UsersState } from './types';

/**
 * Select user.
 * @param state users state
 * @param userId id of the selected user
 * @return User object
 */
export function selectUser(state: UsersState, userId: string): User | undefined {
  return state.searchedUsers.find((user) => user.id === userId);
}
