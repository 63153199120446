/** @module components/UserRepositoryTable */
import { connect } from 'react-redux';
import { Repository } from 'services/metadata';
import { setSelectedRepositories, getMultipleRepositoryEvictionStatus } from 'store/repositories/actions';
import { selectLastEvictedRepository, selectRepositoriesEvictionStatuses } from 'store/repositories/selectors';
import { AppDispatch, GlobalState } from 'store/types';
import UserRepositoryTable from './component';
import {
  StateProps,
  DispatchProps,

} from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  selectedRepositories: state.repositories.selected,
  reposEvictionStatuses: selectRepositoriesEvictionStatuses(state),
  lastEvictedRepository: selectLastEvictedRepository(state),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getMultipleRepositoryEvictionStatus: (repositories: string[]): void => {
    const showNotification = true;
    dispatch(
      getMultipleRepositoryEvictionStatus(repositories, showNotification),
    );
  },
  setSelectedRepositories: (repositories: Repository[]): void => {
    dispatch(setSelectedRepositories(repositories));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserRepositoryTable);
