/** @module utilities/form */
import i18n from '../../i18n';

type MaxRule = {
  max: number;
  message: string;
};

type PatternRule = {
  pattern: RegExp;
  message: string;
};

type Rule = MaxRule | PatternRule;

export const maxLength255: MaxRule = {
  max: 255,
  message: i18n.t('form.rules.maxLength255', 'Maximum of 255 characters.'),
};

export const maxLength250: MaxRule = {
  max: 250,
  message: i18n.t('form.rules.maxLength250', 'Maximum of 250 characters.'),
};

export const noLeadingSpaces: PatternRule = {
  pattern: new RegExp('^\\S'),
  message: i18n.t('form.rules.noLeadingSpaces', 'No leading spaces.'),
};

export const noTrailingSpaces: PatternRule = {
  pattern: new RegExp('\\S$'),
  message: i18n.t('form.rules.noTrailingSpaces', 'No trailing spaces.'),
};

export const noLeadingPeriods: PatternRule = {
  pattern: new RegExp('^[^\\.]'),
  message: i18n.t('form.rules.noLeadingPeriods', 'No leading periods.'),
};

export const noConsecutivePeriods: PatternRule = {
  pattern: new RegExp(/^(?:(?!\.\.).)+$/),
  message: i18n.t('form.rules.noConsecutivePeriods', 'No Consecutive periods.'),
};

const NO_EMOJI_REGEX = /^(?:(?![\uD83C-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u26FF]|[\u2700-\u27BF]|[\u231A-\u231B]).)*$/;
export const validNameNoEmojis: PatternRule = {
  pattern: NO_EMOJI_REGEX,
  message: i18n.t('form.rules.validNameNoEmojis', 'Emojis are not allowed.'),
};

export const validNameCharacters: PatternRule = {
  pattern: new RegExp('^[^<>:"/\\\\|?*$@^]+$'),
  message: i18n.t('form.rules.validNameCharacters', 'Special characters are not allowed: < > : " / \\ | ? * $ @ ^'),
};

export const validItemNameCharacters: PatternRule = {
  pattern: new RegExp('^[^<>:"/\\\\|?*]+$'),
  message: i18n.t('form.rules.validNameCharacters', 'Special characters are not allowed: < > : " / \\ | ? *'),
};

export const validEmailFormat: PatternRule = {
  pattern: new RegExp(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/),
  message: i18n.t('form.rules.invalidEmail', 'Invalid email.'),
};

export const validFileNameLength: PatternRule = {
  pattern: new RegExp('^.{1,255}$'),
  message: i18n.t('form.rules.invalidFileNameLength', 'Invalid filename.'),
};

export const validFileNameChars: PatternRule = {
  pattern: new RegExp('^[^<>:"/\\\\|?*]+$'),
  message: i18n.t('form.rules.invalidFileNameChars', 'Invalid filename.'),
};

export const validFileNameWhiteSpaceBeginning: PatternRule = {
  pattern: new RegExp('^\\S.*$'),
  message: i18n.t('form.rules.invalidFileNameWhiteSpaceBeginning ', 'Invalid filename.'),
};

export const validFileNameWhiteSpaceEnding: PatternRule = {
  pattern: new RegExp('^.*\\S$'),
  message: i18n.t('form.rules.invalidFileNameWhiteSpaceEnding', 'Invalid filename.'),
};

export const validFileNamePeriod: PatternRule = {
  pattern: new RegExp('^(?![\\.]+$).+$'),
  message: i18n.t('form.rules.invalidFileNamePeriod', 'Invalid filename.'),
};

export const maxLengthForFile = (extensionLength: number): MaxRule => ({
  max: 255 - extensionLength,
  message: i18n.t('form.rules.maxLength255WithFileExtension', 'Maximum of 255 characters including file extension.'),
});

export const repoOrPersonNameValidationRules = [
  maxLength250,
  noLeadingSpaces,
  noLeadingPeriods,
  validNameNoEmojis,
  noTrailingSpaces,
  validNameCharacters,
  noConsecutivePeriods,
];

export const folderNameValidationRules = [
  maxLength255,
  noLeadingSpaces,
  noTrailingSpaces,
  validNameNoEmojis,
  noLeadingPeriods,
  validItemNameCharacters,
  noConsecutivePeriods,
];

export const fileValidationRules = (extensionLength: number): Rule[] => [
  maxLengthForFile(extensionLength),
  ...folderNameValidationRules.slice(1),
];
