/** @module components/AdminDashboard */
import { Button } from 'antd';
import UserSearchForm from 'components/UserSearchForm';
import React from 'react';
import { appPaths } from 'utilities/routes';
import { useTranslation } from 'react-i18next';
import { history } from 'utilities/history';
import { BusinessPermissions, GlobalPermissions } from 'services/metadata/types/Permissions';
import { ReactComponent as AdminConsole } from 'assets/icons/admin-console.svg';
import TitleWithIcon from '../TitleWithIcon';
import DashboardPage from '../DashboardPage';
import { Props } from './types';
import './styles.scss';

/**
 * A page that allows users to perform administrative
 * actions.
 * @return A JSX Element
 */
function AdminDashboard(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { me } = props;
  let canManageRole = false;
  if (me && me.permissions && me.permissions) {
    const globalPermission = me.permissions.global;
    const businessPermission = me.permissions.byBusiness;
    if (globalPermission
      && globalPermission.includes(GlobalPermissions.GLOBAL_UPDATE_ROLE)
    ) canManageRole = true;
    else if (businessPermission && businessPermission.some(
      (business) => business.permissions.includes(BusinessPermissions.BUSINESS_UPDATE_ROLE),
    )) canManageRole = true;
    else canManageRole = false;
  }

  const onSearch = (query: string): void => {
    history.push(`${appPaths.adminUserSearch}?queryUserSearch=${query}`);
  };

  const onManageRole = (): void => {
    history.push(appPaths.adminManageAdminRoles);
  };

  return (
    <DashboardPage
      title={(
        <TitleWithIcon iconSVG={AdminConsole}>
          {t(
            'AdminDashboard.title',
            'Admin Console',
          )}
        </TitleWithIcon>
      )}
      hasBreadcrumbs={false}
    >
      <div className="AdminDashboard">
        <div className="card-search">
          <div className="title">
            {t('AdminDashboard.search.title', 'Search by User')}
          </div>
          <div className="card-content">
            <div className="description">
              {t(
                'AdminDashboard.search.description',
                'Your Admin rights allow you to access and edit other user accounts and repositories. Use the Search bar to find a specific user.',
              )}
            </div>
            <div className="search">
              <UserSearchForm
                data-test-id="user-search-form"
                searchUsers={(query): void => onSearch(query)}
              />
            </div>
          </div>
        </div>
        <div className="card-container">
          {canManageRole && (
            <div className="card">
              <div className="title">
                {t('AdminDashboard.manageRoles.title', 'Manage Admin Roles')}
              </div>
              <div className="card-content">
                <div className="description">
                  {t('AdminDashboard.manageRoles.description', 'Create and update admin roles by managing a role’s permissions and allowed actions.')}
                </div>
                <div className="button-container">
                  <Button
                    type="primary"
                    className="button"
                    data-test-id="manage-roles"
                    onClick={onManageRole}
                  >
                    {t('AdminDashboard.manageRoles.button', 'MANAGE ADMIN ROLES')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardPage>
  );
}

export default AdminDashboard;
