/** @module components/DownloadFileButton */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import MetadataService from 'services/metadata';
import { ReactComponent as DownloadIcon } from 'assets/icons/cloud-download.svg';
import { Props } from './types';

function DownloadFile(props: Props): JSX.Element {
  const { item } = props;
  const link = new MetadataService().getFileUrl(item.id);
  const { t } = useTranslation();
  return (
    <Button type="link" href={link} download>
      <DownloadIcon className="icon--primary" />
      {t(
        'DownloadFileButton.button',
        'Download',
      )}
    </Button>
  );
}

export default DownloadFile;
