/** @module components/Footer */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { yammerGroupURL, supportURL } from 'utilities/constants';
import './styles.scss';

function Footer(): JSX.Element {
  const appVersion = process.env.REACT_APP_VERSION;
  const { t } = useTranslation();

  return (
    <footer className="Footer">
      <a
        href="/assets/terms-of-use.pdf"
        target="_blank"
        rel="noreferrer noopener"
        className="footerLink terms"
      >
        {t(
          'Footer.terms',
          'Terms of Use',
        )}
        {' '}
          |
      </a>
      <a
        href={yammerGroupURL}
        target="_blank"
        rel="noreferrer noopener"
        className="footerLink"
      >
        {t(
          'Footer.yammer',
          ' Yammer Group',
        )}
        {' '}
          |
      </a>
      <a
        href={supportURL}
        target="_blank"
        rel="noreferrer noopener"
        className="footerLink"
      >
        {t(
          'Footer.support',
          ' Issues & Support',
        )}
      </a>

      {
          appVersion && (
            <span className="appVersion">
              {t(
                'Footer.appVerison',
                'REDshare v{{version}}',
                { version: appVersion },
              )}
            </span>
          )
        }
    </footer>
  );
}

export default Footer;
