/** @module components/AddCollaboratorsSection */
import { connect } from 'react-redux';
import {
  Repository,
  CollaboratorIdentity,
} from 'services/metadata';
import { ExternalCollaborator } from 'services/invitations';
import { GlobalState, AppDispatch } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import {
  addCollaborator,
  inviteCollaborator,
  preregisterCollaborator,
} from 'store/collaborators/actions';
import {
  getUsers,
} from 'store/users/actions';
import { getPartners } from 'store/partners/actions';
import { selectCollaborators } from 'store/collaborators/selectors';
import { selectError } from 'store/error/selectors';
import AddCollaboratorsSection from './component';
import {
  StateProps,
  DispatchProps,
  OwnProps,
} from './types';

export const mapStateToProps = (state: GlobalState, props: OwnProps): StateProps => ({
  loading: selectLoading(state.loading, 'COLLABORATORS_ADD')
    || selectLoading(state.loading, 'COLLABORATORS_GET')
    || selectLoading(state.loading, 'COLLABORATORS_INVITE')
    || selectLoading(state.loading, 'COLLABORATORS_PREREGISTER'),
  searchLoading: selectLoading(state.loading, 'USERS_GET'),
  partners: state.partners,
  users: state.users.searchedUsers,
  collaborators: selectCollaborators(state.collaborators, props.repository.id),
  addCollaboratorError: selectError(state.error, 'COLLABORATORS_ADD'),
  inviteCollaboratorError: selectError(state.error, 'COLLABORATORS_INVITE'),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  searchUsers: (searchTerm: string): void => { dispatch(getUsers(searchTerm)); },
  getPartners: (): void => { dispatch(getPartners()); },

  addCollaborator: (
    collaboratorIdentity: CollaboratorIdentity,
    repository: Repository,
    canGetCollaborators: boolean,
  ): void => {
    dispatch(addCollaborator(
      collaboratorIdentity,
      repository.id,
      repository,
      canGetCollaborators,
    ));
  },

  inviteCollaborator: (externalCollaborator: ExternalCollaborator): void => {
    dispatch(inviteCollaborator(externalCollaborator));
  },

  preregisterCollaborator: (
    repository: Repository,
    role: string,
    email: string,
    displayName?: string,
  ): void => { dispatch(preregisterCollaborator(repository, role, email, displayName)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCollaboratorsSection);
