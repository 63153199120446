/** @module store/snapshots */
import { Snapshot, SnapshotExport, SnapshotExportSegment } from 'services/metadata';
import { DownloadStatus, State } from './types';

/**
 * Selects an array of snapshots with the given state
 * and repository ID.
 * @param state The snapshots state
 * @param repositoryId A repository ID
 * @return An array of snapshots or undefined
 */
export function selectSnapshots(
  state: State,
  repositoryId: string,
): Snapshot[] | undefined {
  if (!state.snapshots.byRepoId) {
    return undefined;
  }
  const snapshotIds = state.snapshots.byRepoId[repositoryId];
  if (!snapshotIds || !Array.isArray(snapshotIds)) {
    return undefined;
  }
  return snapshotIds.map((id) => state.snapshots.byId[id]);
}

/**
 * Selects a Map of snapshot exports associated with the given repository ID.
 * @param state The snapshots state
 * @param repositoryId A repository ID
 * @return A Map where the keys are snapshot IDs and the values are arrays of SnapshotExport
 */
export function selectSnapshotExportsFromStore(
  state: State,
  repositoryId: string,
): Map<string, SnapshotExport[]> {
  const snapshotMap = new Map<string, SnapshotExport[]>();
  const snapshotIds = state.snapshots.byRepoId && state.snapshots.byRepoId[repositoryId]
    ? state.snapshots.byRepoId[repositoryId]
    : [];
  snapshotIds.forEach((snapshotId) => {
    const exportIds = (state.exports.bySnapshotId && state.exports.bySnapshotId[snapshotId])
      ? state.exports.bySnapshotId[snapshotId]
      : [];
    const exports: SnapshotExport[] = exportIds
      .map(
        (id) => ((state.exports.byId && state.exports.byId[id]) ? state.exports.byId[id] : null),
      )
      .filter((e): e is SnapshotExport => e !== null);
    snapshotMap.set(snapshotId, exports);
  });

  return snapshotMap;
}

/**
 * Selects a Map of snapshot export segments associated with the given repository ID.
 * @param state The snapshots state
 * @param repositoryId A repository ID
 * @return A Map where the keys are SnapshotExports IDs and the values are arrays
 * of SnapshotExportSegment
 */
export function selectSnapshotExportSegmentsFromStore(
  state: State,
  repositoryId: string,
): Map<string, SnapshotExportSegment[]> {
  const snapshotExportSegmentMap = new Map<string, SnapshotExportSegment[]>();

  const snapshotIds = state.snapshots.byRepoId && state.snapshots.byRepoId[repositoryId]
    ? state.snapshots.byRepoId[repositoryId]
    : [];

  snapshotIds.forEach((snapshotId) => {
    const exportIds = (state.exports.bySnapshotId && state.exports.bySnapshotId[snapshotId])
      ? state.exports.bySnapshotId[snapshotId]
      : [];

    exportIds.forEach((exportId) => {
      const segments: SnapshotExportSegment[] = [];
      const segmentIds = (state.segments.byExportId && state.segments.byExportId[exportId])
        ? state.segments.byExportId[exportId]
        : [];
      segmentIds.forEach((segmentId) => {
        const segment = (state.segments.byId && state.segments.byId[segmentId])
          ? state.segments.byId[segmentId]
          : null;

        if (segment) {
          segments.push(segment);
        }
      });
      snapshotExportSegmentMap.set(exportId, segments);
    });
  });

  return snapshotExportSegmentMap;
}

/**
 * Selects the download status of a specific segment for an export.
 * @param state The Redux state
 * @param exportId The ID of the export
 * @param segmentNumber The segment number
 * @return An object containing the download status for the segment
 */
export function selectDownloadStatus(
  state: State,
  exportId: string,
  segmentNumber: number,
): DownloadStatus | null {
  const { downloadStatuses } = state;
  if (downloadStatuses && downloadStatuses[exportId]) {
    const segmentDownloadStatus = downloadStatuses[exportId][segmentNumber];
    if (segmentDownloadStatus) {
      return segmentDownloadStatus;
    }
  }
  return null;
}

/**
 * Selects the download statuses of all segments for all exports in a specific repository.
 * @param state The Redux state
 * @param repositoryId The ID of the repository
 * @return A map containing the download statuses for all segments of all exports in the repository
 */
export function selectDownloadStatusesForRepository(
  state: State,
  repositoryId: string,
): Map<string, Map<number, DownloadStatus>> {
  const downloadStatuses = new Map<string, Map<number, DownloadStatus>>();

  const snapshotExports = selectSnapshotExportsFromStore(state, repositoryId);

  snapshotExports.forEach((snapshotExportList) => {
    snapshotExportList.forEach((snapshotExport) => {
      const segmentsStatuses = new Map<number, DownloadStatus>();

      for (let i = 1; i <= snapshotExport.numberOfSegments; i += 1) {
        const status = selectDownloadStatus(state, snapshotExport.exportId, i);
        if (status) {
          segmentsStatuses.set(i, status);
        }
      }

      downloadStatuses.set(snapshotExport.exportId, segmentsStatuses);
    });
  });

  return downloadStatuses;
}
