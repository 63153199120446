/** @module components/RepositoryWizard/fields */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Checkbox, Form } from 'antd';
import { QuestionFieldProps } from '../types';

/**
 * Asks the user if they accept the rules for self-managing US persons
 * collaborator access.
 * @param props The props
 * @return A JSX Element
 */
function USPersonRulesField(props: QuestionFieldProps): JSX.Element {
  const { onChange, value } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Trans i18nKey="RepositoryWizard.USPersonRulesField.question">
        <b>Remember These 3 Key Rules:</b>
        <br />
        <br />
        (1) Only admit users who need access to the repositories content and are authorized to
        have access. Create separate repositories for projects with different user groups.
        <br />
        <br />
        (2) It is
        {' '}
        <u>your responsibility</u>
        {' '}
        to know what content foreign nationals have access to in your repository, and whether
        they are authorized to access it. Before you add someone who is not a U.S. citizen or
        permanent resident, contact your manager immediately to determine what reporting or
        authorization requirements are applicable.
        <br />
        <br />
        (3) If a foreign national has been provided access and you are unsure if the person is
        authorized under Export Control Laws, contact the Export Control Leader immediately.
        Regulators significantly credit prompt identification of violations.
        <br />
        <br />
      </Trans>
      <Form.Item
        name="us-person-rules"
        rules={[{
          required: true,
          validator: (): Promise<void> => {
            if (value === true) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(t('RepositoryWizard.USPersonRulesField.required', 'Required')));
          },
        }]}
        valuePropName=""
      >
        <Checkbox
          onChange={(e): void => onChange(e.target.checked)}
          checked={value}
          data-test-id="checkbox"
        >
          {t('RepositoryWizard.USPersonRulesField.answer', 'I agree to the above')}
        </Checkbox>
      </Form.Item>
    </div>
  );
}

export default USPersonRulesField;
