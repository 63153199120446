/** @module components/ManageCollaboratorsPage */
import { connect } from 'react-redux';
import { getCollaborators } from 'store/collaborators/actions';
import { selectLoading } from 'store/loading/selectors';
import { getRepositories } from 'store/repositories/actions';
import { selectRepository } from 'store/repositories/selectors';
import { AppDispatch, GlobalState } from 'store/types';
import Component from './component';
import {
  StateProps,
  OwnProps,
  DispatchProps,
} from './types';

export const mapStateToProps = (state: GlobalState, props: OwnProps): StateProps => ({
  repository: selectRepository(state.repositories, props.repositoryId),
  me: state.me,
  loading: selectLoading(state.loading, 'REPOSITORIES_GET'),
});

export const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps): DispatchProps => ({
  getRepositories: (): void => { dispatch(getRepositories()); },
  getCollaborators: (): void => { dispatch(getCollaborators(props.repositoryId)); },

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
