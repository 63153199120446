/** @module components/ManageAdminRolesForm */
import React, { useEffect, useState } from 'react';
import {
  AutoComplete,
  Button,
  Checkbox, Form, Input, List, Select,
} from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmNavigationModal from 'components/ConfirmNavigationModal';
import './styles.scss';
import {
  BusinessPermissions,
  GlobalPermissions,
  BusinessPermissionsBundle,
  GlobalPermissionsBundle,
} from 'services/metadata/types/Permissions';
import { Role } from 'services/metadata/types/Role';
import { sortAlphabetical } from 'utilities/sort';
import { ReactComponent as CancelIcon } from 'assets/icons/x-cancel.svg';
import { hasGlobalPermission, hasBusinessPermission } from 'utilities/admin';
import { history } from 'utilities/history';
import { appPaths } from 'utilities/routes';
import { Business } from 'services/metadata';
import RoleTypeToggle from '../RoleTypeToggle';
import { Props, FormData } from './types';
import {
  globalPermissionBundleDetails,
  businessPermissionBundleDetails,
} from './permissionsDetails';

function ManageAdminRolesForm(props: Props): JSX.Element {
  const [selectedPermissionBundles, setSelectedPermissionBundles] = useState<string[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [selectedBusiness, setSelectedBusiness] = useState<string | undefined>(undefined);
  const [showDescription, setShowDescription] = useState<string | null>(null);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isBusinessRole, setIsBusinessRole] = useState<boolean>(false);
  const {
    businesses,
    roles,
    userRoles,
    loading,
    getBusiness,
    getAllRoles,
    getUserRoles,
    createRole,
    getRole,
    updateRole,
    me,
  } = props;
  const [form] = Form.useForm<FormData>();
  const { t } = useTranslation();

  let canGetRoles = hasGlobalPermission(me.permissions, GlobalPermissions.GLOBAL_LIST_USER_ROLES);
  // if global permissions are not present, check for business permissions
  if (!canGetRoles && me.user) {
    canGetRoles = hasBusinessPermission(
      me.permissions,
      me.user.business.id,
      BusinessPermissions.BUSINESS_LIST_USER_ROLES,
    );
  }

  const getRoleBundles = (permissions: string[]): void => {
    const roleBundles: string[] = [];

    permissions.forEach((permission) => {
      Object.values(BusinessPermissionsBundle).forEach((value) => {
        const bundlePermissions = businessPermissionBundleDetails[value].permissions.map(
          (p) => p.toString(),
        );

        if (bundlePermissions.includes(permission)) {
          roleBundles.push(value);
        }
      });

      Object.values(GlobalPermissionsBundle).forEach((value) => {
        const bundlePermissions = globalPermissionBundleDetails[value].permissions.map(
          (p) => p.toString(),
        );

        if (bundlePermissions.includes(permission)) {
          roleBundles.push(value);
        }
      });
    });

    setSelectedPermissionBundles(roleBundles);
  };

  useEffect(() => {
    if (canGetRoles && me.user) getUserRoles(me.user.id);
  }, [getUserRoles, canGetRoles, me]);

  useEffect(() => {
    if (!businesses) getBusiness();
  }, [businesses, getBusiness]);

  useEffect(() => {
    getAllRoles();
  }, [getAllRoles]);

  /**
 * Updates selected role and permission once get role is successful
 */
  useEffect(() => {
    if (selectedRole) {
      const role = roles.find((r) => r.id === selectedRole.id);
      if (role && role.permissions) {
        setSelectedPermissions(role.permissions);
        setSelectedRole(role);
        getRoleBundles(role.permissions);
      }
    }
  }, [roles, selectedRole, setSelectedPermissions, setSelectedRole]);

  /**
   * This function performs some string operations on permission values to convert it into labels
   * @param value permission value
   * @returns a lable the can be displayed in the UI
   */
  const createLabel = (value: string): string => {
    const words = value.split('_');
    const label = words.map((w, index): string => {
      if (w.toUpperCase() === 'EVICT') {
        return 'Extract';
      }
      if (index !== 0) {
        return `${w[0]}${(w.substring(1).toLowerCase())}`;
      }
      return '';
    });
    return label.join(' ').trim();
  };

  const globalPermissions = Object.values(GlobalPermissionsBundle).map((value) => (
    {
      value,
      label: createLabel(value),
      description: globalPermissionBundleDetails[value].description,
      isVisible: globalPermissionBundleDetails[value].isVisible,
      permissions: globalPermissionBundleDetails[value].permissions,
    }
  ));

  const businessPermissions = Object.values(BusinessPermissionsBundle).map((value) => (
    {
      value,
      label: createLabel(value),
      description: businessPermissionBundleDetails[value].description,
      isVisible: businessPermissionBundleDetails[value].isVisible,
      permissions: businessPermissionBundleDetails[value].permissions,
    }
  ));

  const onChangePermission = (
    permissionBundle: string,
    permissions: string[],
  ): void => {
    if (selectedPermissionBundles.includes(permissionBundle)) {
      setSelectedPermissionBundles((old) => old.filter((op) => op !== permissionBundle));
    } else {
      setSelectedPermissionBundles((old) => [...old, permissionBundle]);
    }

    permissions.forEach((permission) => {
      if (selectedPermissionBundles.includes(permissionBundle)) {
        if (selectedPermissions.includes(permission)) {
          setSelectedPermissions((old) => old.filter((op) => op !== permission));
        }
      } else if (!selectedPermissions.includes(permission)) {
        setSelectedPermissions((old) => [...old, permission]);
      }
      // setting restricted value when a global permission is selected
      if (!form.getFieldsValue().restricted) {
        const isRestricted = permission.includes('GLOBAL');
        if (isRestricted) {
          form.setFieldsValue({ restricted: true });
        }
      }
    });
    setIsFormChanged(true);
  };

  const setRole = (role: Role): void => {
    // getRole gets the permission along with other details of a perticular role
    getRole(role.id);
    // set the selected role state
    setSelectedRole(role);
    // set business selected to true if a business id is present
    if (role.businessId) {
      setSelectedBusiness(role.businessId);
    }
    // set field values
    form.setFieldsValue({
      description: role.description,
      restricted: role.restricted,
      businessId: role.businessId,
    });
  };

  const onSelectRole = (roleId: string): void => {
    const role = roles.find((r) => r.id === roleId);
    if (role) {
      // check if its not already selected
      if (!selectedRole || (selectedRole.id !== roleId)) {
        setRole(role);
      }
      form.setFieldsValue({ name: role.name });
    }
  };

  const onChangeName = (value: string): void => {
    const role = roles.find((r) => r.name.toLocaleLowerCase() === value.toLocaleLowerCase());
    if (!value) {
      setSelectedRole(null);
      form.resetFields();
      setSelectedPermissions([]);
      setSelectedPermissionBundles([]);
      setSelectedBusiness(undefined);
      setIsBusinessRole(false);
    }
    // if the name is copy pasted and it is an existing role, set it as selected role
    if (role) {
      setRole(role);
    } else if (selectedRole && selectedRole.id !== value) {
      setSelectedRole(null);
      if (value.length > 0) {
        setIsFormChanged(true);
      }
    } else if (value.length > 0) {
      setIsFormChanged(true);
    }
  };

  function onFinish(formData: FormData): void {
    const {
      name,
      description,
      restricted,
    } = formData;
    if (selectedRole) {
      const updatedRole = {
        ...selectedRole,
        name,
        description,
        restricted,
        businessId: selectedBusiness as string,
        permissions: selectedPermissions,
      };
      updateRole(updatedRole);
      setIsFormChanged(false);
    } else {
      // these values are hardcoded for now
      // in future we would need a field to set these by the user
      const systemDefined = false;
      const active = true;
      createRole(
        name,
        description,
        systemDefined,
        restricted,
        active,
        selectedBusiness as string,
        selectedPermissions,
      );
      form.resetFields();
      setSelectedPermissions([]);
      setSelectedPermissionBundles([]);
      setSelectedBusiness(undefined);
      setIsBusinessRole(false);
    }
    setIsFormChanged(false);
  }

  function userHasRole(role: Role): boolean {
    return userRoles.some((userRole) => userRole.id === role.id);
  }

  function onCancel(): void {
    setIsCancelled(true);
    history.push(appPaths.admin);
  }

  const filteredRoles = form.getFieldsValue().name
    ? (roles.filter((role) => role.name.toLowerCase().includes(
      form.getFieldsValue().name.toLowerCase(),
    ))) : roles;

  // sorting the list alphabetically
  filteredRoles.sort((a, b) => sortAlphabetical(a.name, b.name));

  const showBusiness = selectedPermissions.some((p) => p.includes('BUSINESS'));

  const onUnselectRole = (): void => {
    setSelectedRole(null);
    form.resetFields();
    setSelectedPermissions([]);
    setSelectedPermissionBundles([]);
    setSelectedBusiness(undefined);
    setIsBusinessRole(false);
  };

  const renderClose = (): JSX.Element => {
    if (selectedRole) {
      return (
        <CancelIcon
          className="cancel"
          data-test-id="cancel"
          onClick={(): void => onUnselectRole()}
          width={10}
          height={10}
        />
      );
    } return <span />;
  };

  const renderPermissionCheckbox = (
    value: string,
    label: string,
    description: string,
    permissions: string[],
  ): JSX.Element => (
    <List.Item className="permission-item" key={value}>
      <Checkbox
        data-test-id={`permission-checkbox-${value}`}
        onChange={(): void => onChangePermission(value, permissions)}
        checked={selectedPermissionBundles.includes(value)}
        disabled={loading}
      >
        {label}
      </Checkbox>
      <Button
        className="description-button"
        data-test-id={`description-button-${value}`}
        type="link"
        onClick={(): void => (
          showDescription === value ? setShowDescription(null) : setShowDescription(value)
        )}
      >
        {'>'}
      </Button>
      {showDescription === value && (
      <div className="permission-description" data-test-id="permission-description">
        {description}
      </div>
      )}
    </List.Item>
  );

  const renderBusinessSelectItem = (
    business: Business,
  ): JSX.Element => (
    <Select.Option
      value={business.id}
      key={business.id}
    >
      {business.displayName}
    </Select.Option>
  );

  return (
    <div className="ManageAdminRolesForm">
      <Form
        form={form}
        data-test-id="form"
        onFinish={onFinish}
      >
        <ConfirmNavigationModal
          data-test-id="confirm-nav-modal"
          when={isFormChanged}
          title={isCancelled ? t('ManageAdminRolesForm.cancelModal.title', 'Cancel?') : t('ManageAdminRolesForm.pageChangeConfirmModal.title', 'Unsaved Changes')}
          content={isCancelled ? t('ManageAdminRolesForm.cancelModal.content', 'Are you sure you want to Cancel? If you Cancel now, your admin roles changes will not be saved.') : t('ManageAdminRolesForm.pageChangeConfirmModal.content', 'Are you sure you want to leave this page? If you leave now, your admin roles changes will not be saved.')}
          okText={isCancelled ? t('ManageAdminRolesForm.cancelModal.okText', 'YES, CANCEL') : undefined}
          cancelText={isCancelled ? t('ManageAdminRolesForm.cancelModal.cancelText', 'DON\'T CANCEL') : undefined}
          afterCancel={(): void => setIsCancelled(false)}
        />
        <Form.Item
          label={t('ManageAdminRolesForm.title', 'Role Title')}
          colon={false}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 12 }}
          shouldUpdate
          data-test-id="role-title"
        >
          {(): JSX.Element => (
            <div>
              <div className="input-description">
                {t('ManageAdminRolesForm.titleDescription', 'Enter a new role title or select a role from the drop down list.')}
              </div>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('ManageAdminRolesForm.titleRequired', 'Role title is required'),
                  },
                  {
                    validator: (): Promise<void> => {
                      if (selectedRole && selectedRole.systemDefined) {
                        return Promise.reject(new Error(t('ManageAdminRolesForm.systemDefined', 'System defined roles cannot be updated')));
                      }
                      if (selectedRole && userHasRole(selectedRole)) {
                        return Promise.reject(new Error(t('ManageAdminRolesForm.userAssignedRole', 'Roles assigned to yourself cannot be updated')));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                noStyle
              >
                <AutoComplete
                  placeholder={t('ManageAdminRolesForm.title', 'Role Title')}
                  className="select"
                  data-test-id="role-title-autocomplete"
                  disabled={loading}
                  options={filteredRoles.map((role, index) => ({ key: `${role.name}${index}`, value: role.name, label: role.name }))}
                  filterOption
                  onSelect={(value): void => (typeof value === 'string' ? onSelectRole(value) : undefined)}
                  onChange={(value): void => (typeof value === 'string' ? onChangeName(value) : undefined)}
                >
                  <Input
                    data-test-id="title-input"
                    suffix={
                      !selectedRole && form.getFieldValue('name')
                        ? <div className="input-suffix" data-test-id="new">{t('ManageAdminRolesForm.new', 'New')}</div>
                        : renderClose()
                    }
                  />
                </AutoComplete>
              </Form.Item>
            </div>
          )}
        </Form.Item>
        <Form.Item
          label={t('ManageAdminRolesForm.description', 'Role Description')}
          colon={false}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 12 }}
        >
          <div className="text-area-description">
            {t('ManageAdminRolesForm.descriptionText', 'Enter a brief description of the role.')}
            <span className="text-area-max">
              {' '}
              {t('ManageAdminRolesForm.descriptionLimit', '(200 Character Maximum)')}
            </span>
          </div>
          <Form.Item
            name="description"
            noStyle
          >
            <Input.TextArea data-test-id="description-input" maxLength={200} disabled={loading} onChange={(e): void | null => (e.target.value.length > 0 ? setIsFormChanged(true) : null)} />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="role-type-details"
          colon={false}
          label={t('ManageAdminRolesForm.mngPermissions', 'Manage Permissions')}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 24 }}
          className="manage-permissions-item"
        >
          <Form.Item
            name="roleTypeToggle"
            noStyle
          >
            <RoleTypeToggle
              value={isBusinessRole}
              onChange={setIsBusinessRole}
              data-test-id="role-type-toggle"
            />
          </Form.Item>
          {!isBusinessRole && (
            <div className="role-type-description">
              <Trans i18nKey="ManageAdminRolesForm.roleTypeText.global">
                A REDshare user with
                {' '}
                <b>GLOBAL</b>
                {' '}
                Admin Permissions will be able to effect change regardless
                {' '}
                of the repository
                {'\''}
                s or user
                {'\''}
                s business.
              </Trans>
            </div>
          )}
          {isBusinessRole && (
            <div className="role-type-description">
              <Trans i18nKey="ManageAdminRolesForm.roleTypeText.business">
                A REDshare user with
                {' '}
                <b>Business</b>
                {' '}
                Admin Permissions will be able to effect change
                {' '}
                within their specific business.
              </Trans>
            </div>
          )}
        </Form.Item>
        <Form.Item
          colon={false}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 24 }}
          className="business-toggle-item"
        >
          {isBusinessRole && (
            <>
              <div className="business-description">
                {t('ManageAdminRolesForm.businessDescription', 'Select a Business')}
              </div>
              <Select
                placeholder={t('ManageAdminRolesForm.businessPlaceholder', 'Select One')}
                className="select"
                data-test-id="select-business"
                disabled={!showBusiness}
                value={showBusiness ? selectedBusiness : undefined}
                onChange={(value): void => {
                  setSelectedBusiness(value);
                  setIsFormChanged(true);
                }}
              >
                {businesses && businesses.map((business) => (
                  renderBusinessSelectItem(business)
                ))}
              </Select>
            </>
          )}
        </Form.Item>
        <Form.Item
          name="business-dropdown-validation"
          colon={false}
          rules={[{
            validator: (): Promise<void> => {
              if (showBusiness && !selectedBusiness) {
                return Promise.reject(new Error(t('ManageAdminRolesForm.businessRequired', 'A business must be selected')));
              }
              return Promise.resolve();
            },
          }]}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 0 }}
          className="business-dropdown-validation"
        />
        <Form.Item
          name="permissions"
          colon={false}
          rules={[{
            validator: (): Promise<void> => {
              if (selectedPermissions.length > 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('ManageAdminRolesForm.permissionsRequired', 'At least one permission is required')));
            },
          }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="permissions-form-item"
        >
          <div className="permissions">
            {!isBusinessRole && (
              <List
                className="permissions-container"
                data-test-id="global-permissions-list"
                grid={{
                  column: 3,
                }}
                dataSource={globalPermissions.filter((permission) => permission.isVisible)}
                renderItem={(permission) => (
                  renderPermissionCheckbox(
                    permission.value,
                    permission.label,
                    permission.description,
                    permission.permissions,
                  )
                )}
              />
            )}
            {isBusinessRole && (
              <List
                className="permissions-container"
                data-test-id="business-permissions-list"
                grid={{
                  column: 3,
                  gutter: 1,
                }}
                dataSource={businessPermissions.filter((permission) => permission.isVisible)}
                renderItem={(permission) => (
                  renderPermissionCheckbox(
                    permission.value,
                    permission.label,
                    permission.description,
                    permission.permissions,
                  )
                )}
              />
            )}
          </div>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 15 }}>
          <div className="buttons-container">
            <Button
              type="ghost"
              disabled={loading}
              data-test-id="cancel"
              className="cancel-button"
              onClick={onCancel}
            >
              {t('ManageAdminRolesForm.cancel', 'CANCEL')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              data-test-id="submit"
              loading={loading}
            >
              {selectedRole ? t('ManageAdminRolesForm.update', 'UPDATE') : t('ManageAdminRolesForm.create', 'CREATE')}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ManageAdminRolesForm;
