/** @module components/ReassignOwnerSection */
import { connect } from 'react-redux';
import { GlobalState, AppDispatch } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import {
  addCollaborator, getCollaborators, updateCollaborator, getCollaboratorsMultipleRepos,
} from 'store/collaborators/actions';
import { selectErrorMessage } from 'store/error/selectors';
import { Collaborator, CollaboratorIdentity } from 'services/metadata';
import { getUsers } from 'store/users/actions';
import ReassignOwnerSection from './component';
import {
  DispatchProps,
  StateProps,
} from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'COLLABORATORS_GET'),
  getMultiCollaboratorLoading: selectLoading(state.loading, 'COLLABORATORS_MULTI_GET'),
  updateCollaboratorLoading: selectLoading(state.loading, 'COLLABORATORS_UPDATE'),
  addCollaboratorLoading: selectLoading(state.loading, 'COLLABORATORS_ADD'),
  searchUserLoading: selectLoading(state.loading, 'USERS_GET'),
  collaborators: state.collaborators,
  me: state.me,
  selectedUser: state.users.selectedUser,
  searchedUsers: state.users.searchedUsers,
  updateCollaboratorError: selectErrorMessage(state.error, 'COLLABORATORS_UPDATE'),
  addCollaboratorError: selectErrorMessage(state.error, 'COLLABORATORS_ADD'),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  addCollaborator: (
    collaboratorIdentity: CollaboratorIdentity,
    repoId: string,
  ): void => { dispatch(addCollaborator(collaboratorIdentity, repoId, undefined, false, false)); },
  updateCollaborator: (
    repositoryId: string,
    collaborator: Collaborator,
    newRole: string,
  ): void => {
    dispatch(updateCollaborator(
      repositoryId,
      collaborator,
      newRole,
      false,
      false,
      false,
    ));
  },
  getCollaborators: (repoId: string): void => { dispatch(getCollaborators(repoId, false)); },
  getCollaboratorsMultipleRepos: (repoIds: string[]): void => {
    dispatch(getCollaboratorsMultipleRepos(repoIds, false));
  },
  searchUsers: (searchTerm: string): void => { dispatch(getUsers(searchTerm)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReassignOwnerSection);
