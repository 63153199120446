/** @module components/AddRegisteredCollabForm */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  Button,
  Modal,
  Form,
} from 'antd';
import {
  Props,
  RegisteredUser,
  FormData,
} from './types';
import './styles.scss';

function AddRegisteredCollabForm(props: Props): JSX.Element {
  const [form] = Form.useForm();

  const {
    currentCollab,
    user,
    addCollaborator,
    repository,
    disabled,
    loading,
    canViewCollab,
  } = props;
  const { t } = useTranslation();

  const getRegisteredUser = (): RegisteredUser => ({
    ...user,
    name: user.external ? `${user.name} (External)` : user.name,
    isCurrentCollab: currentCollab !== undefined,
    role: currentCollab ? currentCollab.role : 'NONCOLLAB',
  });

  const registeredUser = getRegisteredUser();

  /**
   * Creates a collaborator identity from the given registered
   * user and the current selected role state, then
   * adds the new collaborator.
   * @param registeredUser A registered user
   * @return void
   */
  const handleFormSubmit = (values: FormData): void => {
    const collaboratorIdentity = {
      identity: registeredUser.email,
      role: values.role,
    };

    if (collaboratorIdentity.role && collaboratorIdentity.role.toUpperCase() === 'OWNER') {
      Modal.confirm({
        title: t('AddRegisteredCollabForm.confirmOwnerChange.title', 'Are you sure you want to change the owner of this repository?'),
        content: t('AddRegisteredCollabForm.confirmOwnerChange.content', ''),
        onOk: () => addCollaborator(collaboratorIdentity, repository),
        onCancel: () => {},
      });
    } else {
      addCollaborator(collaboratorIdentity, repository);
    }
  };

  return (
    <Form
      form={form}
      className="AddRegisteredCollabForm"
      data-test-id="form"
      onFinish={handleFormSubmit}
    >
      <div data-test-id="user-name" className="user-name">{registeredUser.name}</div>
      {registeredUser.isCurrentCollab && canViewCollab
        ? <div className="role-name" data-test-id="current-collab">{registeredUser.role}</div>
        : (
          <Form.Item
            name="role"
            rules={[{
              required: true,
              message: t('AddRegisteredCollabForm.formItem.access.message', 'Please select access.'),
            }]}
            colon={false}
            className="role-select"
          >
            <Select
              placeholder={t('AddRegisteredCollabForm.formItem.access.placeholder', 'Select Access')}
              style={{ width: 164 }}
              data-test-id="role-select"
              disabled={disabled}
            >
              {/* external or preregistered users can't own repositories */}
              {!registeredUser.external && !registeredUser.preregistered && (
              <Select.Option value="OWNER" data-test-id="owner-option">
                {t('SelectRoles.roles.owner', 'Owner').toUpperCase()}
              </Select.Option>
              )}
              {!registeredUser.external && !registeredUser.preregistered && (
              <Select.Option value="COOWNER" data-test-id="coowner-option">
                {t('SelectRoles.roles.coOwner', 'Co-Owner').toUpperCase()}
              </Select.Option>
              )}
              <Select.Option value="EDITOR">
                {t('SelectRoles.roles.editor', 'Editor').toUpperCase()}
              </Select.Option>
              <Select.Option value="VIEWER">
                {t('SelectRoles.roles.viewer', 'Viewer').toUpperCase()}
              </Select.Option>
            </Select>
          </Form.Item>
        ) }
      {(!registeredUser.isCurrentCollab || !canViewCollab) && (
        <Form.Item className="ant-form-item--last-item ant-form-item--form-buttons">
          <Button
            htmlType="submit"
            loading={loading}
            className="ant-btn-primary add-button"
            data-test-id="submit-button"
          >
            {t('AddRegisteredCollabForm.add', 'ADD')}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

export default AddRegisteredCollabForm;
