/** @module components/DefinitionsPage */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Table } from 'antd';
import { ReactComponent as CheckIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import queryString from 'query-string';
import { history } from 'utilities/history';
import ScrollToTop from 'components/ScrollToTop';
import {
  permissionsData,
  rolesData,
  externalUserData,
  allowedActionsData,
} from './tableData';
import Loader from '../Loader';
import { Props } from './types';
import DashboardPage from '../DashboardPage';
import './styles.scss';

function DefinitionsPage(props: Props): JSX.Element {
  const {
    loading,
  } = props;
  const { t } = useTranslation();
  const { Column, ColumnGroup } = Table;
  if (loading) {
    return <Loader loading data-test-id="loader" />;
  }
  const { search } = history.location;
  const parsedQueryString = queryString.parse(search);
  const goBackUrl = parsedQueryString.goBackUrl ? parsedQueryString.goBackUrl.toString() : null;
  const getUplink = (): string => (goBackUrl || '/');

  return (
    <DashboardPage
      data-test-id="definitions"
      title={t('DefinitionsPage.title', 'Definitions & FAQ')}
      upLink={getUplink()}
    >
      <ScrollToTop
        data-test-id="scrollToTop"
      />
      <div className="DefinitionsPage">
        <Trans i18nKey="DefinitionsPage.body.summary">
          <h3><strong>REDshare Definitions</strong></h3>
          <p className="summary">
            <strong>REDshare</strong>
            {' '}
            is a regulated, export control, document sharing software. Among other actions,
            {' '}
            REDshare allows you to upload, view, and share files with other users securely
            {' '}
            by ensuring only users with the correct access rights and collaboration statuses
            {' '}
            may interact with the your shared items.
          </p>
        </Trans>
        <div className="tables">
          <div className="permissions-block">
            <Trans i18nKey="DefinitionsPage.body.permissionTable">
              <h4>Common Repository Permission Tags:</h4>
              <Table
                dataSource={permissionsData()}
                className="permissions-table"
                pagination={false}
                showHeader={false}
                bordered
              >
                <Column dataIndex="title" key="title" />
                <Column dataIndex="definition" key="definition" />
              </Table>
            </Trans>
          </div>
          <div className="roles-block">
            <Trans i18nKey="DefinitionsPage.body.rolesTable">
              <h4>REDshare Collaboration Roles and Types:</h4>
              <Table
                dataSource={rolesData()}
                className="roles-table"
                pagination={false}
                showHeader={false}
                bordered
              >
                <Column dataIndex="title" key="title" />
                <Column dataIndex="definition" key="definition" />
              </Table>
              <Table
                dataSource={externalUserData()}
                className="external-user-table"
                pagination={false}
                showHeader={false}
                bordered
              >
                <Column dataIndex="title" key="title" />
                <Column dataIndex="definition" key="definition" />
              </Table>
            </Trans>
          </div>
          <div className="allowedActions">
            <Trans i18nKey="DefinitionsPage.body.allowedActionsTable.header">
              <h4>Allowed Actions per Collaboration Role:</h4>
              <div className="allowedActionsTableKey">
                <p className="keyLeft">
                  <CheckIcon className="icon check-icon" id="check-icon" />
                  {' '}
                  - Allowed
                </p>
                <p className="keyRight">
                  <CloseIcon className="icon close-icon" />
                  {' '}
                  - NOT Allowed
                </p>
              </div>
            </Trans>
            <Table
              dataSource={allowedActionsData()}
              className="allowedActionsTable"
              pagination={false}
              size="small"
              bordered
            >
              <Column title="User Actions" dataIndex="action" key="action" />
              <ColumnGroup title="Internal Collaborators">
                <Column title={t('DefinitionsPage.body.allowedActionsTable.columnTitle.Owner', 'Owner')} dataIndex="ownerIcon" key="ownerIcon" align="center" />
                <Column title={t('DefinitionsPage.body.allowedActionsTable.columnTitle.coOwner', 'CoOwner')} dataIndex="coOwnerIcon" key="coOwnerIcon" align="center" />
                <Column title={t('DefinitionsPage.body.allowedActionsTable.columnTitle.editor', 'Editor')} dataIndex="editorIcon" key="editorIcon" align="center" />
                <Column title={t('DefinitionsPage.body.allowedActionsTable.columnTitle.viewer', 'Viewer')} dataIndex="viewerIcon" key="viewerIcon" align="center" />
              </ColumnGroup>
              <ColumnGroup title="External Collaborators">
                <Column title={t('DefinitionsPage.body.allowedActionsTable.columnTitle.ecEditor', 'Editor')} dataIndex="ecEditorIcon" key="ecEditorIcon" align="center" />
                <Column title={t('DefinitionsPage.body.allowedActionsTable.columnTitle.ecViewer', 'Viewer')} dataIndex="ecViewerIcon" key="ecViewerIcon" align="center" />
              </ColumnGroup>
            </Table>
          </div>
        </div>
        <div className="FAQ">
          <h3><strong>{t('DefinitionsPage.body.FAQ.title', 'Frequently Asked Questions')}</strong></h3>
          <Trans i18nKey="DefinitionsPage.body.FAQ.question.insufficientAccess">
            <p>
              <strong>
                I’m getting an “Insufficient Access” Error when I try to access a repository;
                {' '}
                what could be the cause?
              </strong>
              <br />
              Possible Causes:
            </p>
            <ul>
              <li>
                You do not have the correct permissions to access the repository
                {' '}
                (example: You must be a USPerson in order to access a USONLY repository.)
              </li>
              <li>
                The repository no longer exists - Another collaborator may have deleted the
                {' '}
                repository, and your browser may need to be refreshed.
              </li>
              <li>
                <strong>NOTE:</strong>
                {' '}
                Users may create repositories that they will not have access to post creation
                {' '}
                (except to change repository’s ownership). This is done intentionally to allow
                {' '}
                users to create repositories on behalf of other users while ensuring repository
                {' '}
                protections are still maintained.
              </li>
            </ul>
          </Trans>
          <Trans i18nKey="DefinitionsPage.body.FAQ.question.externalUsers">
            <p>
              <strong>
                Can I invite users outside of GE to collaborate on repositories I own?
                {' '}
                If so, what’s the process?
              </strong>
              <br />
              Yes, users who are not employed by GE may be invited to register and use REDshare
              {' '}
              as an ‘External Collaborator’ (see definition in the REDshare Collaboration Roles
              {' '}
              table above). Steps to invite an External Collaborator to REDshare:
            </p>
            <ol>
              <li>
                In the Manage Collaborators Modal, select the “Add Collaborator” option near
                {' '}
                the top of the modal.
              </li>
              <li>
                Enter the External Collaborator’s email and click the search
                {' '}
                button or ‘enter’.
              </li>
              <li>
                If the External Collaborator’s email has already been approved and whitelisted,
                {' '}
                the External Collaborator will appear in the search results below the search box,
                {' '}
                allowing you to add them to the repository collaborators as normal. If the
                {' '}
                External Collaborator’s email has NOT already been approved and;
                {' '}
                whitelisted, then extra steps will be required:
                <ol type="a">
                  <li>
                    After searching the External Collaborator’s email, below the search box and
                    {' '}
                    text, click the “How do I get approval?” link.
                  </li>
                  <li>
                    Complete the SupportCentral “REDshare External Domain Request” Form.
                  </li>
                  <li>
                    After receiving approval, repeat the invitation process
                    {' '}
                    starting at Step 1.
                  </li>
                </ol>
              </li>
            </ol>
          </Trans>
          <Trans i18nKey="DefinitionsPage.body.FAQ.question.sizeLimit">
            <p>
              <strong>What is the current File size limit?</strong>
              <br />
              15GB
              <br />
            </p>
          </Trans>
          <Trans i18nKey="DefinitionsPage.body.FAQ.question.ECLoginTrouble">
            <p>
              <strong>
                One of my external collaborators is having trouble logging into
                {' '}
                REDshare; what could be the cause?
              </strong>
              <br />
              Possible Causes:
            </p>
            <ul>
              <li>You were trying to login with an old B2B account.</li>
              <li>Your company’s firewall rules may be conflicting with REDshare.</li>
              <li>
                You are using an unsupported or outdated browser. Be sure to use up
                {' '}
                to date browsers such as Chrome, Safari, or FireFox.
              </li>
            </ul>
          </Trans>
          <Trans i18nKey="DefinitionsPage.body.FAQ.question.lookUpPermissions">
            <p>
              <strong>
                How can I tell if I’m marked as a US PERSON or if I have any other
                {' '}
                permissions attached to my account?
              </strong>
              <br />
              You can see your US Person Indicator and GENE permissions on your
              {' '}
              OneIDM Profile page:
              {' '}
              <a target="_blank" href="https://oneidm.ge.com/faces/modules/my_profile/my_profile.xhtml" rel="noreferrer noopener">HERE</a>
              .
              <br />
              The permissions are visible in the
              {' '}
              {'"'}
              Security
              {'"'}
              {' '}
              tab as:
            </p>
            <ul>
              <li>US Person Indicator</li>
              <li>Energy Export Control</li>
            </ul>
          </Trans>
          <Trans i18nKey="DefinitionsPage.body.FAQ.question.correctPermissions">
            <p>
              <strong>
                How can I correct a missing or incorrect US Person status?
              </strong>
              <br />
              Users can request an update to their US Person Status through the
              {' '}
              <a href="http://sc.ge.com/*ECValidation" target="_blank" rel="noreferrer noopener">Citizenship Validation Request for Export Control Form</a>
              {' '}
              located in SupportCentral.
              <br />
              For
              {' '}
              <b>GE Nuclear</b>
              {' '}
              users, changes in GENE status may be requested through the
              {' '}
              <a href="http://sc.ge.com/*GEH-REDShareReq" target="_blank" rel="noreferrer noopener">GEH REDShare Access Request Form</a>
              {' '}
              located in SupportCentral.
            </p>
          </Trans>
          <Trans i18nKey="DefinitionsPage.body.FAQ.question.limitedFlag">
            <p>
              <strong>
            What should I do if one or more of my repositories has been flagged as Limited?
              </strong>
              <br />
            There are no required actions at this time, however your user actions may be limited
              {' '}
            to view & edit only. You may reassign the repository to a new Owner in the same
              {' '}
            business as the repository.
            </p>
          </Trans>
        </div>
      </div>
    </DashboardPage>
  );
}

export default DefinitionsPage;
