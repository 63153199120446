import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppRouteParams } from 'utilities/routes';
import { GlobalState } from 'store/types';
import { selectRepository, selectIsRepositoryAccessible } from 'store/repositories/selectors';
import DOCBanner from './component';
import { StateProps } from './types';

export const mapStateToProps = (
  state: GlobalState,
  props: RouteComponentProps<AppRouteParams>,
): StateProps => ({
  repository: selectRepository(state.repositories, props.match.params.repoId || ''),
  hasAccessToRepository: selectIsRepositoryAccessible(state, props.match.params.repoId || ''),
});

export const mapDispatchToProps = (): {} => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DOCBanner));
