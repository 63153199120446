/** @module components/ManageSnapshotsPage */
import { connect } from 'react-redux';
import { selectLoading } from 'store/loading/selectors';
import { getRepositories } from 'store/repositories/actions';
import { selectRepository } from 'store/repositories/selectors';
import { AppDispatch, GlobalState } from 'store/types';
import { createRepositorySnapshot, getSnapshots } from 'store/snapshots/actions';
import Component from './component';
import {
  StateProps,
  OwnProps,
  DispatchProps,
} from './types';

export const mapStateToProps = (state: GlobalState, props: OwnProps): StateProps => ({
  repository: selectRepository(state.repositories, props.repositoryId),
  loading: selectLoading(state.loading, 'REPOSITORIES_GET'),
  creatingSnapshot: selectLoading(state.loading, 'SNAPSHOT_CREATE'),
});

export const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps): DispatchProps => ({
  getRepositories: (): void => { dispatch(getRepositories()); },
  getSnapshots: (): void => { dispatch(getSnapshots(props.repositoryId)); },
  createSnapshot: (snapshotName: string): void => {
    dispatch(createRepositorySnapshot(
      props.repositoryId,
      snapshotName,
    ));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
