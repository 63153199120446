/** @module utilities/file */

/**
 * Returns the name of the file, folder, or repository without the
 * file extension.
 * @param text The file, folder, or repository name
 * @return The name without the file extension
 */
function getName(text: string): string {
  const extensionStart = text.lastIndexOf('.');
  if (extensionStart !== -1) {
    return text.slice(0, extensionStart);
  }
  return text;
}

export default getName;
