/** @module components/UserRepositoriesSection */
import React, { useEffect, useState } from 'react';
import UserRepositoryTable from 'components/UserRepositoryTable';
import TableRefresh from 'components/TableRefresh';
import { BusinessPermissions, GlobalPermissions } from 'services/metadata/types/Permissions';
import BulkRepoActionsBanner from '../BulkRepoActionsBanner';
import { Props } from './types';
import './styles.scss';

function UserRepositoriesSection(props: Props): JSX.Element {
  const {
    lastUpdated,
    onRefresh,
    refreshing,
    userId,
    getUserRepositories,
    getUser,
    repositories,
    loading,
    user,
    me,
    deleteUserRepository,
    updateName,
  } = props;

  const [containsEvicted, setContainsEvicted] = useState(false);

  useEffect(() => {
    getUserRepositories();
  }, [userId, getUserRepositories]);


  useEffect(() => {
    if (!user) getUser();
  }, [userId, getUser, user]);

  const canExtractRepos = (): boolean => {
    if (me.permissions
      && me.permissions.global.includes(GlobalPermissions.GLOBAL_EVICT_REPOSITORY)) {
      return true;
    }
    return false;
  };

  const canReassignRepos = (): boolean => {
    if (me.permissions
      && me.permissions.global.includes(GlobalPermissions.GLOBAL_UPDATE_COLLABORATOR)) {
      return true;
    }

    if (me.permissions) {
      const bundles = me.permissions.byBusiness.filter((business) => business.permissions.includes(
        BusinessPermissions.BUSINESS_UPDATE_COLLABORATOR,
      ));
      return (bundles.length > 0);
    }

    return false;
  };

  return (
    <div className="UserRepositoriesSection">
      <div className="tableRefresh">
        <TableRefresh
          lastUpdated={lastUpdated}
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
      </div>
      {user && (
        <>
          <BulkRepoActionsBanner
            showExtract={!containsEvicted && canExtractRepos()}
            showDelete={false}
            showRestore={false}
            showReassign={canReassignRepos()}
            adminReassign
            data-test-id="bulk-actions-banner"
          />
          <UserRepositoryTable
            me={me}
            repositories={repositories}
            loading={loading}
            deleteRepository={deleteUserRepository}
            updateName={updateName}
            user={user}
            setContainsEvicted={setContainsEvicted}
            data-test-id="user-repos-table"
          />
        </>
      )}
    </div>
  );
}

export default UserRepositoriesSection;
