/** @module utilities/antd */
import { TablePaginationConfig } from 'antd/lib/table';
import { LOCAL_STORAGE_KEY_PAGE_SIZE } from 'utilities/constants';

/**
 * Returns an antd pagination configuration for the given array of items.
 * @param paginationList An array of items
 * @param hideOnSinglePage Whether or not to show pagination if there is only one page
 * @return An antd pagination configuration
 */
function generatePaginationOptions<T>(
  paginationList: T[] = [],
  hideOnSinglePage = false,
  totalRecords?: number,
  current?: number,
  onChange?: (pageNumber: number) => void,
): TablePaginationConfig {
  const savedPageSize = localStorage.getItem(LOCAL_STORAGE_KEY_PAGE_SIZE);
  const paginationOptions: TablePaginationConfig = {
    position: ['bottomRight'],
    defaultPageSize: savedPageSize ? parseInt(savedPageSize, 10) : 12,
    hideOnSinglePage,
    total: totalRecords || 0,
  };

  if ((paginationList && paginationList.length > 12) || (totalRecords && totalRecords > 12)) {
    paginationOptions.showSizeChanger = true;
    paginationOptions.pageSizeOptions = ['12', '24', '36'];
  }

  if (current) {
    paginationOptions.current = current;
  }
  paginationOptions.onChange = (page, pageSize): void => {
    // When OnChange Function is received
    if (onChange) {
      onChange(page);
    }

    if (!savedPageSize || (parseInt(savedPageSize, 10) !== pageSize)) {
      if (pageSize) {
        localStorage.setItem(LOCAL_STORAGE_KEY_PAGE_SIZE, pageSize.toString());
      }
    }
  };
  return paginationOptions;
}

export default generatePaginationOptions;
