/** @module components/UploadFolderButton */
import { connect } from 'react-redux';
import { AppDispatch } from 'store/types';
import { enqueueFolder } from 'store/uploads/actions';
import UploadFolderButton from './component';
import { DispatchProps } from './types';

export const mapStateToProps = (): {} => ({});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  enqueueFolder: (name: string, files: WebkitFile[], parentId: string, repoId: string): void => {
    dispatch(enqueueFolder(name, files, parentId, repoId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFolderButton);
