/** @module store/repositoryExports */
import { AnyAction } from 'redux';
import { RepositoryExportZipActionType, RepositoryState, State } from './types';

const DEFAULT_REPOSITORY_STATE: RepositoryState = {
  isLoading: false,
  error: null,
  downloadUrl: null,
};

export const DEFAULT_STATE: State = {};

function reducer(state: State = DEFAULT_STATE, action: AnyAction): State {
  const { type, payload } = action;

  switch (type) {
    case RepositoryExportZipActionType.REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_REQUEST: {
      return {
        ...state,
        [payload.repoId]: {
          ...DEFAULT_REPOSITORY_STATE,
          isLoading: true,
        },
      };
    }
    case RepositoryExportZipActionType.REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_SUCCESS: {
      return {
        ...state,
        [payload.repoId]: {
          ...DEFAULT_REPOSITORY_STATE,
          isLoading: false,
          downloadUrl: payload.url,
        },
      };
    }
    case RepositoryExportZipActionType.REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_FAILURE: {
      return {
        ...state,
        [payload.repoId]: {
          ...DEFAULT_REPOSITORY_STATE,
          isLoading: false,
          error: payload.error,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
