/** @module services/search */
import FetchClient from './FetchClient';

export * from './types/Client';
export * from './types/SearchEngine';
export * from './types/SearchMeta';
export * from './types/SearchPage';
export * from './types/SearchResponse';
export * from './types/SearchResult';

export default FetchClient;
