/** @module components/MoreButton */
import React from 'react';
import { ReactComponent as EllipsisIcon } from 'assets/icons/more.svg';
import { Props } from './types';
import './styles.scss';

/**
 * Returns a button containing an ellipsis icon.
 * @param props The component props
 * @return A JSX Element
 */
function MoreButton(props: Props): JSX.Element {
  const { size } = props;
  let className = 'small';
  if (size === 'large') {
    className = 'large';
  }
  return (
    <div className="MoreButton">
      <EllipsisIcon
        className={className}
        data-test-id="icon"
      />
    </div>
  );
}

export default MoreButton;
