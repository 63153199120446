/** @module store/loading */
import { AnyAction } from 'redux';
import { LoadingState } from './types';

export const DEFAULT_STATE: LoadingState = {};

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
export default function reducer(
  state: LoadingState = DEFAULT_STATE,
  action: AnyAction,
): LoadingState {
  const { type, payload } = action;
  const regex = new RegExp('(.*)_(REQUEST|SUCCESS|ERROR)');
  const matches = regex.exec(type);
  if (!matches) {
    return state;
  }
  const [, requestName, requestState] = matches;
  const isLoading = (requestState === 'REQUEST');
  if (payload && payload.id) {
    const { id } = payload;
    return {
      ...state,
      [requestName]: isLoading,
      [`${requestName}_${id}`]: isLoading,
    };
  }
  return {
    ...state,
    [requestName]: isLoading,
  };
}
