/** @module store/licenses */
import { Action } from 'redux';

export enum LicenseActionType {
  LICENSES_GET_REQUEST = 'LICENSES_GET_REQUEST',
  LICENSES_GET_SUCCESS = 'LICENSES_GET_SUCCESS',
  LICENSES_GET_ERROR = 'LICENSES_GET_ERROR',

  LICENSES_ADD_REQUEST = 'LICENSES_ADD_REQUEST',
  LICENSES_ADD_SUCCESS = 'LICENSES_ADD_SUCCESS',
  LICENSES_ADD_ERROR = 'LICENSES_ADD_ERROR',

  LICENSES_DELETE_REQUEST = 'LICENSES_DELETE_REQUEST',
  LICENSES_DELETE_SUCCESS = 'LICENSES_DELETE_SUCCESS',
  LICENSES_DELETE_ERROR = 'LICENSES_DELETE_ERROR',

  LICENSES_GET_ALL_REQUEST = 'LICENSES_GET_ALL_REQUEST',
  LICENSES_GET_ALL_SUCCESS = 'LICENSES_GET_ALL_SUCCESS',
  LICENSES_GET_ALL_ERROR = 'LICENSES_GET_ALL_ERROR',
}

export type LicensesGetRequestAction = Action<LicenseActionType.LICENSES_GET_REQUEST>;

export interface LicensesGetSuccessAction extends Action<
  LicenseActionType.LICENSES_GET_SUCCESS
> {
  payload: {
    repositoryId: string;
    licenses: string[];
  };
}

export interface LicensesGetErrorAction extends Action<
  LicenseActionType.LICENSES_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type LicensesAddRequestAction = Action<LicenseActionType.LICENSES_ADD_REQUEST>;

export interface LicensesAddSuccessAction extends Action<
  LicenseActionType.LICENSES_ADD_SUCCESS
> {
  payload: {
    repositoryId: string;
    license: string;
  };
}

export interface LicensesAddErrorAction extends Action<
  LicenseActionType.LICENSES_ADD_ERROR
> {
  payload: {
    error: Error;
  };
}

export type LicensesDeleteRequestAction = Action<LicenseActionType.LICENSES_DELETE_REQUEST>;

export interface LicensesDeleteSuccessAction extends Action<
  LicenseActionType.LICENSES_DELETE_SUCCESS
  > {
    payload: {
      repositoryId: string;
      license: string;
    };
  }

export interface LicensesDeleteErrorAction extends Action<
  LicenseActionType.LICENSES_DELETE_ERROR
> {
  payload: {
    error: Error;
    repositoryId: string;
    license: string;
  };
}

export type LicensesGetAllRequestAction = Action<LicenseActionType.LICENSES_GET_ALL_REQUEST>;

export interface LicensesGetAllSuccessAction extends Action<
  LicenseActionType.LICENSES_GET_ALL_SUCCESS
> {
  payload: {
    licenses: string[];
  };
}

export interface LicensesGetAllErrorAction extends Action<
  LicenseActionType.LICENSES_GET_ALL_ERROR
> {
  payload: {
    error: Error;
  };
}

export type State = {
  all: string[];
  entries: {
    [key: string]: string[];
  };
};

export type LicensesAction = LicensesGetRequestAction
                            | LicensesGetSuccessAction
                            | LicensesGetErrorAction
                            | LicensesAddRequestAction
                            | LicensesAddSuccessAction
                            | LicensesAddErrorAction
                            | LicensesDeleteRequestAction
                            | LicensesDeleteSuccessAction
                            | LicensesDeleteErrorAction
                            | LicensesGetAllRequestAction
                            | LicensesGetAllSuccessAction
                            | LicensesGetAllErrorAction;
