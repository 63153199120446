/** @module components/ManageRepositoryPage */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardPage from 'components/DashboardPage';
import './styles.scss';
import DashboardPageError from 'components/DashboardPageError';
import { ErrorType } from 'components/DashboardPageError/types';
import { ReactComponent as RepositoryIcon } from 'assets/icons/repository.svg';
import Loader from 'components/Loader';
import ManageRepositoryForm from 'components/ManageRepositoryForm';
import { Props } from './types';

function ManageRepositoryPage(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    repository,
    getRepositories,
    loading,
    getBusinesses,
  } = props;

  useEffect(() => {
    if (!repository) getRepositories();
  }, [getRepositories, repository]);

  /**
 * Gets businesses when the component mounts.
 */
  useEffect(() => {
    getBusinesses();
  }, [getBusinesses]);

  if (loading) {
    return <Loader loading={loading} data-test-id="loader" />;
  }

  return (
    <div className="ManageRepositoryPage">
      <DashboardPage
        title={(
          <>
            <RepositoryIcon className="icon--primary icon" />
            {t('ManageRepositoryPage.title', 'Manage Repository')}
          </>
        )}
      >
        {repository
          ? <ManageRepositoryForm repository={repository} />
          : <DashboardPageError errorType={ErrorType.NOT_FOUND} data-test-id="error-page" />}
      </DashboardPage>
    </div>
  );
}
export default ManageRepositoryPage;
