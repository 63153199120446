/** @module components/CreateFolderForm */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import { folderNameValidationRules } from 'utilities/form/rules';
import { Props, FormData } from './types';

/**
 * A form that allows the user to create a folder.
 * @param props The component props
 * @return a JSX Element
 */
function CreateFolderForm(props: Props): JSX.Element {
  const {
    afterSubmit,
    loading,
    createFolder,
    parentId,
  } = props;
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [form] = Form.useForm<FormData>();

  /**
   * Applies effects when folder creation is complete.
   */
  useEffect(() => {
    if (submitted && !loading) {
      afterSubmit();
      setSubmitted(false);
      form.resetFields();
    }
  }, [afterSubmit, form, loading, submitted]);

  /**
   * Called when the form is submitted.
   * @param values The form values
   */
  const onFinish = (values: FormData): void => {
    const { folderName } = values;
    createFolder(folderName.trim(), parentId);
    setSubmitted(true);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      data-test-id="form"
      layout="vertical"
    >
      <Form.Item
        name="folderName"
        data-test-id="form-item--folder-name"
        label="Name"
        colon={false}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('CreateFolderForm.rule.requiredName', 'Please enter the name of the folder.'),
          },
          ...folderNameValidationRules,
        ]}
      >
        <Input data-test-id="name-field" />
      </Form.Item>
      <Form.Item className="ant-form-item--last-item ant-form-item--form-buttons">
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          data-test-id="submit-button"
        >
          {t('CreateFolderForm.button.create', 'Create')}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CreateFolderForm;
