/** @module store/businesses */
import { AnyAction } from 'redux';
import {
  BusinessesActionType,
  State,
} from './types';

export const DEFAULT_STATE: State = null;

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
function reducer(state: State = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case BusinessesActionType.BUSINESSES_GET_REQUEST:
      return null;
    case BusinessesActionType.BUSINESSES_GET_SUCCESS:
      return action.payload.businesses;
    default:
      return state;
  }
}

export default reducer;
