import { ExtendedMetadataError, MetadataError, MetadataErrorResponse } from 'services/metadata';

type PossibleError = ExtendedMetadataError | MetadataError | Error | unknown;
/**
 * Transforms a variety of possible errors into a standardized MetadataErrorResponse object.
 *
 * This function accepts an error object that can be an instance of `ExtendedMetadataError`,
 * `MetadataError`, or any native `Error`, and returns a standardized `MetadataErrorResponse`
 * object, allowing consistent error handling across different Thunk actions.
 *
 * @param {PossibleError} error - The error object returned from a Thunk action or other
 *  asynchronous operation.
 * @returns {MetadataErrorResponse} - A standardized object containing error details suitable for
 * Redux store updates.
 */
const transformToMetadataErrorResponse = (error: PossibleError): MetadataErrorResponse => {
  const errorData: MetadataErrorResponse = {
    code: 'UNKNOWN_ERROR',
    description: 'An unknown error occurred',
    causes: [],
  };

  if (error instanceof ExtendedMetadataError) {
    errorData.code = error.errorData.code;
    errorData.description = error.errorData.description;
    errorData.causes = error.errorData.causes;
  } else if (error instanceof MetadataError) {
    errorData.code = 'FETCH_ERROR';
    errorData.description = error.message;
  } else if (error instanceof Error) {
    errorData.code = 'GENERIC_ERROR';
    errorData.description = error.message;
  }

  return errorData;
};


export default transformToMetadataErrorResponse;
