/** @module utilities/folder */

import { FolderDetails, FolderHierarchy } from './types';

/**
 * Generate folder hierarchy from an array of files
 * which must contain webkitRelativePath
 * @param files Array of files
 * @return A folder hierarchy object
 */
const createHierarchy = (files: WebkitFile[]): FolderHierarchy => {
  const hierarchy: FolderHierarchy = {};
  files.forEach((file) => {
    const paths = file.webkitRelativePath && file.webkitRelativePath.split('/').slice(0, -1);
    let parentFolder: FolderDetails | undefined;
    // builds the hierarchy of folders.
    if (paths) {
      paths.forEach((path) => {
        if (!parentFolder) {
          if (!hierarchy[path]) {
            hierarchy[path] = {
              name: path,
              children: {},
              files: [],
            };
          }
          parentFolder = hierarchy[path];
        } else {
          if (!parentFolder.children[path]) {
            parentFolder.children[path] = {
              name: path,
              children: {},
              files: [],
            };
          }
          parentFolder = parentFolder.children[path];
        }
      });
    }
    if (parentFolder) parentFolder.files.push(file);
  });
  return hierarchy;
};

export default createHierarchy;
