/** @module components/ReassignRepositoriesPage */
import { Me, Repository, User } from 'services/metadata';
import { CollaboratorsState, ReassignmentTask } from 'store/collaborators/types';
import { ErrorState } from 'store/error/types';
import { LoadingState } from 'store/loading/types';

export interface StateProps {
  me: Me;
  searchedUsers: User[];
  getMultiCollabLoading: boolean;
  searchUserLoading: boolean;
  collaborators: CollaboratorsState;
  selectedRepositories: Repository[];
  selectedRepositoriesToReassign: Repository[];
  loadings: LoadingState;
  errors: ErrorState;
}

export interface DispatchProps {
  setSelectedRepositories: Function;
  setSelectedRepositoriesToReassign: Function;
  multiReassignCollaborators: (
    reassignments: ReassignmentTask[],
    newOwnerName: string,
  ) => void;
}

export type Props = StateProps & DispatchProps;

export enum RepoReassignmentStatus {
  IN_PROGRESS,
  FAILED,
  SUCCESSFUL,
}
