/** @module utilities/antd */

import { PaginationConfig } from 'antd/lib/pagination';
import generatePaginationOptions from './generatePaginationOptions';

/**
 * Returns an antd pagination configuration for the given array of items for a list.
 * @param paginationList An array of items
 * @param hideOnSinglePage Whether or not to show pagination if there is only one page
 * @return An antd pagination configuration for list
 */
function generateListPaginationOptions<T>(
  paginationList: T[],
  hideOnSinglePage: boolean,
  totalRecords?: number,
  current?: number,
  onChange?: (page: number) => void,
): PaginationConfig {
  const paginationOptions = generatePaginationOptions(
    paginationList,
    hideOnSinglePage,
    totalRecords,
    current,
    onChange,
  );
  const listPaginationOptions: PaginationConfig = { ...paginationOptions, position: 'bottom' };
  return listPaginationOptions;
}

export default generateListPaginationOptions;
