/** @module components/NewRepositoryForm */
import {
  Button,
  Card,
  Divider,
  Form,
  Modal,
} from 'antd';
import RepositoryInfoSection from 'components/RepositoryInfoSection';
import ConfirmNavigationModal from 'components/ConfirmNavigationModal';
import ManageTagsSection from 'components/ManageTagsSection';
import React, { useEffect, useState } from 'react';
import { history } from 'utilities/history';
import { appPaths } from 'utilities/routes';
import { useTranslation } from 'react-i18next';
import { Props, FormData } from './types';
import './styles.scss';
import {
  AviationRepositoryWizard,
  DefaultRepositoryWizard,
  GECASRepositoryWizard,
  NuclearRepositoryWizard,
  GasPowerRepositoryWizard,
  PowerConversionRepositoryWizard,
  RepositoryWizardValues,
  RepositoryWizardType,
} from '../RepositoryWizard';

const defaultWizardValues = {
  fedRamp: 0,
  isCdi: false,
  isEc: false,
  isUsOnly: false,
  isUkLicense: false,
  isLicenseAvailable: false,
  isUsCitizen: false,
  isDoc: false,
  tags: [],
};

function NewRepositoryForm(props: Props): JSX.Element {
  const [showAllSections, setShowAllSections] = useState(false);
  const [wizardValues, setWizardValues] = useState<RepositoryWizardValues>(defaultWizardValues);
  const [wizardIsComplete, setWizardIsComplete] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showAfterFinishModal, setShowAfterFinishModal] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [createdRepoID, setCreatedRepoId] = useState<string>();

  const {
    loading,
    businesses,
    createRepository,
    businessTags,
    getBusinessTags,
    tagsLoading,
    getBusinesses,
  } = props;

  const [business, setBusiness] = useState<string | undefined>(undefined);
  const [wizardKey, setWizardkey] = useState(0);

  const { t } = useTranslation();
  const [form] = Form.useForm<FormData>();

  /**
  * Gets businesses when the component mounts.
  */
  useEffect(() => {
    getBusinesses();
  }, [getBusinesses]);

  /**
 * Filter the businesses returned by the back-end based on the available
 * wizard implementations and feature toggles.
 */
  const wizardTypes: string[] = Object.values(RepositoryWizardType);
  const businessList = businesses
    ? businesses.filter((b) => wizardTypes.includes(b.wizardId))
    : [];

  function onBusinessChange(bid: string): void {
    setBusiness(bid);
    setWizardkey(wizardKey + 1);
    const { name, fileContentSearch } = form.getFieldsValue();
    form.setFieldsValue({
      name,
      parentBusiness: bid,
      fileContentSearch,
    });
    if (!showAllSections) setShowAllSections(true);
    getBusinessTags(bid);

    // set wizard complete for people ops because it has no protections fields
    const selectedBusiness = businessList.find((b) => b.id === bid);
    if (selectedBusiness && selectedBusiness.wizardId === RepositoryWizardType.PEOPLE_OPS) {
      setWizardIsComplete(true);
    }
  }


  /**
   * Saves the state of the repository wizard when it updates.
   * @param values The repository wizard values
   * @param isComplete Whether or not the wizard is complete
   */
  function onWizardChange(values: RepositoryWizardValues, isComplete: boolean): void {
    // Deep object comparison
    if (JSON.stringify(wizardValues) !== JSON.stringify(values)) {
      setWizardValues(values);
    }
    setWizardIsComplete(isComplete);
  }

  /**
   * Assigns the correct repository wizard for the selected business.
   */
  const selectedBusiness = businessList.find((b) => b.id === business);
  let selectedWizard: JSX.Element | null = null;
  if (selectedBusiness) {
    switch (selectedBusiness.wizardId) {
      case RepositoryWizardType.GE_AVIATION:
        selectedWizard = (
          <AviationRepositoryWizard
            key={wizardKey}
            onChange={onWizardChange}
            businessId={selectedBusiness.id}
            data-test-id="ge-aviation-wizard"
          />
        );
        break;

      case RepositoryWizardType.GECAS:
        selectedWizard = (
          <GECASRepositoryWizard
            key={wizardKey}
            onChange={onWizardChange}
            businessId={selectedBusiness.id}
            data-test-id="gecas-wizard"
          />
        );
        break;
      case RepositoryWizardType.GE_NUCLEAR:
        selectedWizard = (
          <NuclearRepositoryWizard
            key={wizardKey}
            onChange={onWizardChange}
            businessId={selectedBusiness.id}
            data-test-id="ge-nuclear-wizard"
          />
        );
        break;
      case RepositoryWizardType.PEOPLE_OPS:
        selectedWizard = null;
        // selectedWizard =  (
        //   <PeopleOpsRepositoryWizard
        //     key={wizardKey}
        //     onChange={onWizardChange}
        //     businessId={selectedBusiness.id}
        //     data-test-id="people-ops-wizard"
        //   />
        // );
        break;
      case RepositoryWizardType.GAS_POWER:
        selectedWizard = (
          <GasPowerRepositoryWizard
            key={wizardKey}
            onChange={onWizardChange}
            businessId={selectedBusiness.id}
            data-test-id="gas-power-wizard"
          />
        );
        break;
      case RepositoryWizardType.POWER_CONVERSION:
        selectedWizard = (
          <PowerConversionRepositoryWizard
            key={wizardKey}
            onChange={onWizardChange}
            businessId={selectedBusiness.id}
            data-test-id="power-conversion-wizard"
          />
        );
        break;
      // RepositoryWizardType.GE_CORPORATE:
      // RepositoryWizardType.GE_DIGITAL:
      // RepositoryWizardType.GRC:
      default:
        selectedWizard = (
          <DefaultRepositoryWizard
            key={wizardKey}
            onChange={onWizardChange}
            businessId={selectedBusiness.id}
            data-test-id="default-wizard"
          />
        );
        break;
    }
  }


  const afterFinish = (repoId: string): void => {
    setCreatedRepoId(repoId);
    setShowAfterFinishModal(true);
    form.resetFields();
    setWizardValues(defaultWizardValues);
  };

  /**
   * Validates the form and creates a repository.
   * @param event A form submission event
   */
  async function onFinish(formData: FormData): Promise<void> {
    const { name, fileContentSearch } = formData;

    if (wizardIsComplete) {
      if (selectedBusiness) {
        createRepository(
          name.trim(),
          wizardValues.fedRamp,
          wizardValues.isCdi,
          wizardValues.isEc,
          selectedBusiness && selectedBusiness.wizardId,
          wizardValues.isUsOnly,
          wizardValues.isUkLicense,
          wizardValues.isLicenseAvailable,
          wizardValues.isUsCitizen,
          wizardValues.isDoc,
          selectedTags,
          fileContentSearch || false,
          afterFinish,
        );
      }
      setIsFormChanged(false);
    }
  }

  function onCancel(): void {
    setIsCancelled(true);
    history.push(appPaths.myRepositories);
  }

  const onChangeFormValue = (values: FormData): void => {
    if (isFormChanged === false) {
      if (values.name !== undefined || values.parentBusiness !== undefined) {
        setIsFormChanged(true);
      }
    }
  };

  const onClickManageCollab = (): void => {
    if (createdRepoID) {
      history.push(`/manage-collaborators/${createdRepoID}`, {
        from: appPaths.newRepository,
      });
    }
  };

  const onGoToRepo = (): void => {
    if (createdRepoID) {
      history.push(`${appPaths.myRepositories}/${createdRepoID}`, {
        from: appPaths.newRepository,
      });
    }
  };

  const onGoToMyRepos = (): void => {
    if (createdRepoID) {
      history.push(`${appPaths.myRepositories}`, {
        from: appPaths.newRepository,
      });
    }
  };

  return (
    <Form
      form={form}
      data-test-id="form"
      onFinish={onFinish}
      layout="vertical"
      className="NewRepositoryForm"
      onValuesChange={(changedValues, allValues): void => onChangeFormValue(allValues)}
      initialValues={{
        fileContentSearch: false,
      }}
    >
      <ConfirmNavigationModal
        data-test-id="confirm-nav-modal"
        when={isFormChanged}
        title={isCancelled ? t('NewRepositoryForm.cancelModal.title', 'Cancel?') : t('NewRepositoryForm.pageChangeConfirmModal.title', 'Unsaved Changes')}
        content={isCancelled ? t('NewRepositoryForm.cancelModal.content', 'Are you sure you want to Cancel? If you Cancel now, your repository will not be saved.') : t('NewRepositoryForm.pageChangeConfirmModal.content', 'Are you sure you want to leave this page? If you leave now, your repository will not be saved.')}
        okText={isCancelled ? t('NewRepositoryForm.cancelModal.okText', 'YES, CANCEL') : undefined}
        cancelText={isCancelled ? t('NewRepositoryForm.cancelModal.cancelText', 'DON\'T CANCEL') : undefined}
        afterCancel={(): void => setIsCancelled(false)}
      />
      <Card className="card">
        <div className="section" data-test-id="info-section">
          <div className="section-title">{t('NewRepositoryForm.repoInfo', 'Repository Info *')}</div>
          <RepositoryInfoSection
            data-test-id="repo-info"
            onBusinessChange={onBusinessChange}
            loading={loading}
            businessDisabled={false}
          />
        </div>
        {showAllSections
          && (
            <>
              {selectedWizard !== null && (
                <>
                  <Divider />
                  <div className="section" data-test-id="protections-section">
                    <div className="section-title">{t('NewRepositoryForm.protections', 'Protections *')}</div>
                    {selectedWizard}
                  </div>
                </>
              )}
              <Divider />
              <div className="section" data-test-id="tags-section">
                <div className="section-title">
                  {t('NewRepositoryForm.mngTags', 'Manage Tags')}
                  {' '}
                  <span className="section-title-extra">{t('NewRepositoryForm.optional', 'Optional')}</span>
                </div>
                <ManageTagsSection
                  data-test-id="manage-tags"
                  loading={tagsLoading || loading}
                  businessTags={businessTags}
                  businessId={form.getFieldValue('parentBusiness')}
                  onChangeTag={(tags: string[]): void => setSelectedTags(tags)}
                />
              </div>
            </>
          )}
      </Card>
      <div className="buttons-container">
        <Form.Item className="ant-form-item--last-item ant-form-item--form-buttons">
          <Button
            type="default"
            disabled={loading}
            data-test-id="cancel"
            className="cancel-button"
            onClick={onCancel}
          >
            {t('NewRepositoryForm.cancel', 'CANCEL')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            data-test-id="submit"
            className="save-button"
          >
            {t('NewRepositoryForm.save', 'SAVE')}
          </Button>
        </Form.Item>
      </div>
      <Modal
        visible={showAfterFinishModal}
        footer={null}
        className="after-save-modal"
        data-test-id="after-save-modal"
        closable={false}
      >
        <div className="modal-content">
          <div className="modal-title">
            {t('NewRepositoryForm.afterSavePopup.title', 'Repository Created')}
          </div>
          <div className="modal-content">
            {t('NewRepositoryForm.afterSavePopup.description', 'Your Repository has been created.  Would you like to add collaborators?')}
          </div>
          <div className="modal-buttons">
            <Button
              type="ghost"
              loading={loading}
              data-test-id="return-home-button"
              className="button"
              onClick={onGoToMyRepos}
            >
              {t('NewRepositoryForm.afterSavePopup.home', 'RETURN HOME')}
            </Button>
            <Button
              type="ghost"
              loading={loading}
              data-test-id="go-to-repo-button"
              className="button"
              onClick={onGoToRepo}
            >
              {t('NewRepositoryForm.afterSavePopup.goToRepo', 'GO TO REPO')}
            </Button>
            <Button
              type="primary"
              loading={loading}
              data-test-id="add-collab-button"
              className="button"
              onClick={onClickManageCollab}
            >
              {t('NewRepositoryForm.afterSavePopup.addCollab', 'ADD COLLABORATORS')}
            </Button>
          </div>
        </div>
      </Modal>
    </Form>
  );
}

export default NewRepositoryForm;
