/** @module components/RepositoryWizard/fields/FileContentSearchToggle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Switch } from 'antd';
import { Props } from './types';
import './styles.scss';
/**
 * Asks the user if the repository needs file content searchable.
 * @return A JSX Element
 */
function FileContentSearchToggle(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { disabled } = props;
  return (
    <Form.Item
      className="FileContentSearchToggle"
      name="fileContentSearch"
      label={(
        <div className="label">
          {t(
            'RepositoryWizard.FileContentSearchToggle.question',
            'Allow File Content Search',
          )}
          <div className="description">
            {t(
              'RepositoryWizard.FileContentSearchToggle.desc',
              'Would you like the content of this repository\'s files to be available in search?',
            )}
          </div>
        </div>
)}
      colon={false}
      valuePropName="checked"
    >
      <Switch disabled={disabled} />
    </Form.Item>
  );
}

export default FileContentSearchToggle;
