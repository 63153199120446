/** @module store/release-notes */
import { Action } from 'redux';

export enum ReleaseNotesActionType {
  RELEASE_NOTES_GET_REQUEST = 'RELEASE_NOTES_GET_REQUEST',
  RELEASE_NOTES_GET_SUCCESS = 'RELEASE_NOTES_GET_SUCCESS',
  RELEASE_NOTES_GET_ERROR = 'RELEASE_NOTES_GET_ERROR',
}

export type ReleaseNotesGetRequestAction = Action<ReleaseNotesActionType.RELEASE_NOTES_GET_REQUEST>;

export interface ReleaseNotesGetSuccessAction extends Action<
ReleaseNotesActionType.RELEASE_NOTES_GET_SUCCESS
> {
  payload: {
    releaseNotes: string;
  };
}

export interface ReleaseNotesGetErrorAction extends Action<
ReleaseNotesActionType.RELEASE_NOTES_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type State = string | null;

export type ReleaseNotesAction = ReleaseNotesGetRequestAction
                            | ReleaseNotesGetSuccessAction
                            | ReleaseNotesGetErrorAction;
