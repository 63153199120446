/** @module components/RepositoryItemsTrashPage */
import { connect } from 'react-redux';
import { getRepositoryTrash } from 'store/repositoryItems/actions';
import { getRepositories } from 'store/repositories/actions';
import { selectRepositoryItemsTrash } from 'store/repositoryItems/selectors';
import { selectLoading } from 'store/loading/selectors';
import { selectError } from 'store/error/selectors';
import { selectRepository } from 'store/repositories/selectors';
import { GlobalState, AppDispatch } from 'store/types';
import RepositoryItemsTrashPage from './component';
import {
  OwnProps,
  StateProps,
  DispatchProps,
} from './types';

export const mapStateToProps = (state: GlobalState, props: OwnProps): StateProps => ({
  repository: selectRepository(state.repositories, props.repositoryId),
  files: selectRepositoryItemsTrash(state.items, props.repositoryId),
  loading: (
    selectLoading(state.loading, 'REPOSITORY_ITEMS_TRASH_GET')
    || selectLoading(state.loading, 'REPOSITORY_ITEMS_UPDATE')
  ),
  refreshing: selectLoading(state.loading, 'REPOSITORY_ITEMS_TRASH_GET'),
  error: selectError(state.error, 'REPOSITORY_ITEMS_TRASH_GET'),
});

export const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps): DispatchProps => ({
  getRepositoryTrash: (): void => {
    dispatch(getRepositoryTrash(ownProps.repositoryId));
  },
  getRepositories: (): void => { dispatch(getRepositories()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepositoryItemsTrashPage);
