/** @module utilities/date */

/**
 * Returns the current date in a specific ISO string format.
 * @return The current date formatted as 'YYYY-MM-DDTHH:MM:SSZ'.
 */
function currentDateToISOString(): string {
  return `${new Date().toISOString().split('.')[0]}Z`;
}

export default currentDateToISOString;
