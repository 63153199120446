/** @module components/NameField */
import { connect } from 'react-redux';
import { AppDispatch } from 'store/types';
import { ItemDetails } from 'services/metadata';
import { updateFileParent } from 'store/repositoryItems/actions';
import WithDnd from './WithDnd';
import { DispatchProps } from './types';

const mapStateToProps = (): {} => ({});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  updateFileParent: (itemDetails: ItemDetails, newParentId: string): void => {
    dispatch(updateFileParent(itemDetails, newParentId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDnd);
