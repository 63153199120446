/** @module components/FileUploadArea */
import React from 'react';
import {
  Upload,
  Modal,
  message,
} from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { UploadStatus } from 'services/storage';
import { ReactComponent as CloudUploadIcon } from 'assets/icons/cloud-upload.svg';
import { Props } from './types';
import './styles.scss';

function FileUploadArea(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    folderId,
    repositoryId,
    folderItems,
    enqueueFiles,
    validateName,
    uploads,
  } = props;

  function showModal(fileName: string): Promise<true | string> {
    return new Promise((resolve): void => {
      Modal.confirm({
        title: (
          <span>
            <Trans i18nKey="FileUploadArea.overwrite.title" values={{ name: fileName }}>
              Overwrite
              {' "'}
              <b>
                {'{{ name }}'}
              </b>
              {'"'}
              ?
            </Trans>
          </span>
        ),
        content: (
          <span>
            <Trans i18nKey="FileUploadArea.overwrite.content" values={{ name: fileName }}>
              The file
              {' "'}
              <b>
                {'{{ name }}'}
              </b>
              {'" '}
               already exists in this
              folder. Do you want to overwrite it?
            </Trans>
          </span>
        ),
        okText: t('FileUploadArea.overwrite.ok', 'Overwrite'),
        onOk: () => resolve(true),
        onCancel: () => resolve(Upload.LIST_IGNORE),
      });
    });
  }

  /**
   * A function that runs before the upload and checks if the file is already existing
   *  or already being uploaded
   * @param file the uploaded file
   * @returns a promise containing true if before upload check is successful
   * else move the file to ignore list
   */
  async function beforeUpload(file: UploadFile): Promise<string | true> {
    const fileNameValid = validateName(file.name);
    if (!fileNameValid) {
      return Promise.resolve(Upload.LIST_IGNORE);
    }

    const fileExists = folderItems && folderItems.find((item) => (
      item.type.toLowerCase() === 'file'
      && item.name === file.name
    )) !== undefined;

    if (fileExists) {
      return showModal(file.name);
    }

    const isDuplicate = uploads.filter((upload) => (
      upload.file.name === file.name
      && upload.status !== UploadStatus.COMPLETED
      && upload.status !== UploadStatus.CANCELLED
      && upload.status !== UploadStatus.ERROR
      && upload.parentId === (folderId || repositoryId)
    )).length > 0;

    if (isDuplicate) {
      message.info(t(
        'FileUploadArea.uploadMessage.duplicate',
        'Already uploading {{fileName}}',
        { fileName: file.name },
      ));
      return Promise.resolve(Upload.LIST_IGNORE);
    }

    return Promise.resolve(true);
  }

  async function isFolder(file: File): Promise<boolean> {
    const result = await new Promise<boolean>((resolve): void => {
      const reader = new FileReader();
      reader.onload = (): void => {
        resolve(false);
      };
      reader.onerror = (): void => {
        resolve(true);
      };
      reader.readAsArrayBuffer(file.slice(0, 1));
    });
    return result;
  }

  async function onChange(info: UploadChangeParam<UploadFile<File>>): Promise<void> {
    const { file, fileList } = info;
    const uploadTheseFiles: File[] = [];
    const promises: Promise<void>[] = [];

    // Only execute once per selection (onChange fires once per file)
    if (file.uid === fileList[fileList.length - 1].uid) {
      // Check if each file is a folder and filter the folders out
      for (let i = 0; i < fileList.length; i += 1) {
        const x: File = fileList[i].originFileObj as File;
        promises.push((async (): Promise<void> => {
          const folder = await isFolder(x);
          if (folder) {
            message.error(t(
              'FileUploadArea.uploadMessage.folder',
              'Folders are not supported',
              { fileName: x.name },
            ));
          } else {
            uploadTheseFiles.push(x);
          }
        })());
      }
      await Promise.all(promises);
      // Enqueue the remaining files
      if (uploadTheseFiles.length) {
        enqueueFiles(uploadTheseFiles, folderId || repositoryId, repositoryId);
      }
    }
  }

  return (
    <div className="FileUploadArea">
      <Upload.Dragger
        multiple
        beforeUpload={beforeUpload}
        customRequest={(): void => {}}
        className="filesUpload"
        data-test-id="upload"
        showUploadList={false}
        onChange={onChange}
        fileList={[]}
      >
        <CloudUploadIcon id="test" />
        <Trans i18nKey="FileUploadArea.instructions">
          <p className="ant-upload-text">
            Click here or Drag files to this area to upload
          </p>
          <p className="ant-upload-hint">
            Supports single file or bulk upload. Does
            {' '}
            <b>not</b>
            {' '}
            support folders.
          </p>
        </Trans>
      </Upload.Dragger>
    </div>
  );
}

export default FileUploadArea;
