/** @module components/FolderTree */
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Tree,
  Alert,
  Spin,
  TreeDataNode,
} from 'antd';
import { EventDataNode } from 'antd/lib/tree';
import { ItemDetails } from 'services/metadata';
import { Props } from './types';
import './styles.scss';

class FolderTree extends React.Component<Props> {
  componentDidMount(): void {
    const {
      folders,
      getItems,
      repositoryId,
    } = this.props;
    if (folders[repositoryId] === undefined) {
      getItems(repositoryId);
    }
  }

  /**
   * Loads the data for a tree node if it has not already been loaded.
   *
   * @param node The tree node
   * @return A promise
   */
  loadData(node: EventDataNode): Promise<void> {
    const { folders, getItems } = this.props;
    const folderId = node.key;
    if (folderId && (folders[folderId] === undefined)) {
      return getItems(folderId);
    }
    return Promise.resolve();
  }

  /**
   * Recursively renders tree nodes. Excludes the selected
   * folder and its children.
   *
   * @param item The item to be rendered
   * @param isDisabled Whether or not this node and its children are disabled
   * @return jsx containing a TreeNode
   */
  renderTreeNode(item: ItemDetails, isDisabled = false): TreeDataNode {
    const { files, folders, t } = this.props;
    const isCurrentParent = files && (files.filter((file) => (
      item.id === file.parentId
    )).length > 0);
    const isSelf = files && (files.filter((file) => (
      item.id === file.id
    )).length > 0);
    const itemById = folders[item.id];
    if (itemById && itemById.children) {
      const children = itemById.children.filter(
        (child: ItemDetails) => child.type === 'FOLDER',
      );
      return {
        key: item.id,
        title: isCurrentParent ? t(
          'FolderTree.treeNode.title.currentFolder',
          '{{name}} (Current Folder)',
          { name: item.name },
        ) : t(
          'FolderTree.treeNode.title.name',
          '{{name}}',
          { name: item.name },
        ),
        isLeaf: children.length === 0,
        disabled: isCurrentParent || isSelf || isDisabled,
        className: 'tree-node',
        children: children.map(
          (child: ItemDetails) => this.renderTreeNode(child, isSelf || isDisabled),
        ),
      };
    }
    return {
      key: item.id,
      title: isCurrentParent ? t(
        'FolderTree.treeNode.title.currentFolder',
        '{{name}} (Current Folder)',
        { name: item.name },
      ) : t(
        'FolderTree.treeNode.title.name',
        '{{name}}',
        { name: item.name },
      ),
      isLeaf: false,
      disabled: isCurrentParent || isSelf || isDisabled,
      className: 'tree-node',
    };
  }

  render(): JSX.Element {
    const {
      repositoryId,
      onSelect,
      folders,
      t,
    } = this.props;
    const item = folders[repositoryId];
    if (!item) {
      return <Spin data-test-id="loader" />;
    }
    if (item && item.details) {
      return (
        <Tree
          loadData={(node): Promise<void> => this.loadData(node)}
          onSelect={(ids): void => onSelect(ids[0] as string)}
          data-test-id="tree"
          className="FolderTree"
          treeData={[this.renderTreeNode(item.details)]}
        />
      );
    }
    return (
      <Alert
        message={t('FolderTree.message.error', 'No other directories found to move file or folder into.')}
        type="error"
        data-test-id="error-message"
      />
    );
  }
}

export default withTranslation()(FolderTree);
