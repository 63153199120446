/** @module utilities/sort */
/**
 * A comparator that sorts two dates.
 *
 * @param a A date
 * @param b A date
 * @return a number (-1, 0, or 1)
 */
function sortDate(a: Date, b: Date): number {
  return (new Date(a).getTime() < new Date(b).getTime() ? -1 : 1);
}

export default sortDate;
