/** @module store/notifications */
import { MetadataError } from 'services/metadata';
import i18n from '../../i18n';
import { Notification, NotificationType } from './types';

/**
 * Returns an error notification with the given error and title. If
 * the error is a MetadataError and the status is 401 (unauthorized) a
 * notification is returned that lets the user know to log back in.
 * @param error An Error or MetadataError
 * @param title A title
 * @return a Notification
 */
function createErrorNotification(error: Error | MetadataError, title: string): Notification {
  const { response } = (error as MetadataError);
  if (response && response.status === 401) {
    return {
      type: NotificationType.ERROR,
      title: i18n.t(
        'notifications.notLoggedIn.title',
        'Not logged in',
      ),
      message: i18n.t(
        'notifications.notLoggedIn.message',
        'Please try logging out and then logging back into the app.',
      ),
    };
  }
  return {
    type: NotificationType.ERROR,
    title,
    message: error.message,
  };
}

export default createErrorNotification;
