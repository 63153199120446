/** @module store/repositoryItems */
import { Item, ItemDetails } from 'services/metadata';
import { UploadStatus } from 'services/storage';
import { GlobalState } from 'store/types';
import { State } from './types';

export const selectRepositoryItem = (state: GlobalState, itemId: string): Item | undefined => {
  const item = state.items.byId[itemId];
  if (item) {
    // Get all active uploading folders in the selected folder
    const uploadingFolders = Object.values(state.uploads.folders)
      .filter((folder) => (folder.parentId === itemId && folder.status === UploadStatus.ACTIVE));
    // Get all names of the folders uploading (we don't yet have the ids)
    const uploadingFolderNames = new Set(uploadingFolders.map((folder) => folder.name));
    return {
      ...item,
      // Exclude children that are active folder uploads
      children: item.children
        ? item.children.filter((c) => !uploadingFolderNames.has(c.name))
        : undefined,
    };
  }
  return undefined;
};

export function selectRepositoryItemsTrash(
  state: State, repositoryId: string,
): Item[] | undefined {
  return state.trash[repositoryId];
}

/**
 * Selects a child repository item with the given id and parent id.
 * @param id The id
 * @param parentId The parent id
 * @return An item details, or undefined
 */
export function selectChildRepositoryItem(
  state: GlobalState,
  id: string,
  parentId: string,
): ItemDetails | undefined {
  let result;
  const parent = selectRepositoryItem(state, parentId);
  if (parent && parent.children) {
    result = parent.children.find((x) => x.id === id);
  }
  return result;
}
