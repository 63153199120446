/** @module components/SnapshotsTable */
import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Table,
  Popover,
  Button,
} from 'antd';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddCircle from '@material-ui/icons/AddCircle';
import { Snapshot, SnapshotExport } from 'services/metadata';
import { currentDateToISOString } from 'utilities/date';
import { wait } from 'utilities/asyncUtilities';
import { ReactComponent as ArrowIcon } from 'assets/icons/keyboard-arrow-right.svg';
import { ReactComponent as CheckGreenIcon } from 'assets/icons/check-green.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-2.svg';
import { ReactComponent as ProgressIndicatorIcon } from 'assets/icons/progress-indicator.svg';
import { DownloadStatus } from 'store/snapshots/types';
import CreateExportSegmentsModal from './CreateExportSegmentsModal';
import { NewExportData, Props, SegmentSize } from './types';
import './styles.scss';

/**
 * SnapshotsTable is a composite table component that lists snapshots, their associated exports,
 * and segments.
 * - It displays a list of snapshots associated with a repository.
 * - Each snapshot row can be expanded to show a table of its exports.
 * - Each export row can be further expanded to display its segments.
 * The table also allows you to download segments individually and provides visual feedback
 * for snapshots that are being created.
 * @param {Props} props The props for the component.
 * @returns {JSX.Element} The SnapshotsTable component.
 */
function SnapshotsTable(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    snapshots,
    loading,
    creatingSnapshotExport,
    downloadStatuses,
    me,
    creatingSnapshotName,
    snapshotExports,
    snapshotExportSegments,
    getSnapshots,
    getExportsMultipleSnapshots,
    getSegmentsMultipleSnapshotsExports,
    downloadSnapshotExportSegment,
    createSnapshotExport,
  } = props;
  const CREATING_SNAPSHOT_ID = 'creatingSnapshot';
  const MB = 1024 * 1024;
  const GB = 1024 * MB;
  const DOWNLOAD_DISABLE_DURATION = 5000;

  const [localSnapshots, setLocalSnapshots] = useState(snapshots || []);
  const [hasLoadedExports, setHasLoadedExports] = useState(false);
  const [hasLoadedSegments, setHasLoadedSegments] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newExportData, setNewExportData] = useState<NewExportData>({ snapshotId: '', maxSegmentSize: null });
  const [disableUntil, setDisableUntil] = useState<number>(0);

  useEffect(() => {
    const loadAllSnapshotExports = async (): Promise<void> => {
      if (
        snapshots
        && snapshots.length > 0
        && !hasLoadedExports
        && !loading
        && !creatingSnapshotName
      ) {
        setHasLoadedSegments(false);
        const snapshotIds = snapshots
          .filter((snapshot) => snapshot.id !== CREATING_SNAPSHOT_ID)
          .map((snapshot) => snapshot.id);
        getExportsMultipleSnapshots(snapshotIds);
        setHasLoadedExports(true);
      }
    };
    loadAllSnapshotExports();
  }, [snapshots]);

  useEffect(() => {
    if (!loading && !creatingSnapshotExport) {
      if (snapshots) {
        setLocalSnapshots(snapshots);
      } else {
        setLocalSnapshots([]);
      }
    }
    if (!loading && hasLoadedExports && snapshotExports && !hasLoadedSegments) {
      getSegmentsMultipleSnapshotsExports(snapshotExports);
      setHasLoadedSegments(true);
    }
  }, [hasLoadedExports, loading, creatingSnapshotExport]);

  useEffect(() => {
    if (creatingSnapshotName && snapshots && me.user) {
      setLocalSnapshots([
        ...snapshots,
        {
          id: CREATING_SNAPSHOT_ID,
          name: creatingSnapshotName,
          createdOn: currentDateToISOString(),
          createdBy: me.user,
          repository: { id: '', name: '' },
          type: 'S',
        },
      ]);
    } else {
      setHasLoadedExports(false);
      getSnapshots();
    }
  }, [creatingSnapshotName]);

  useEffect(() => {
    if (!creatingSnapshotExport) {
      setHasLoadedExports(false);
      getSnapshots();
    }
  }, [creatingSnapshotExport]);

  useEffect(() => {
    const enableDownload = async (): Promise<void> => {
      await wait(DOWNLOAD_DISABLE_DURATION);
      setDisableUntil(0);
    };

    if (Date.now() < disableUntil) {
      enableDownload();
    }
  }, [disableUntil]);

  const openModal = (snapshotId: string): void => {
    const maxSegmentSize = SegmentSize.SIZE_100MB;
    setNewExportData({ snapshotId, maxSegmentSize });
    setIsModalVisible(true);
  };

  const handleCancelModal = (): void => {
    setIsModalVisible(false);
  };

  const handleCreateSegments = (): void => {
    if (newExportData.maxSegmentSize) {
      const { snapshotId, maxSegmentSize } = newExportData;
      createSnapshotExport(snapshotId, maxSegmentSize);
    }
    setIsModalVisible(false);
  };

  interface ExpandProps {
    onExpand: (
      record: Snapshot | SnapshotExport,
      event: React.MouseEvent<HTMLElement | SVGSVGElement>) => void;
    expanded: boolean;
    record: Snapshot | SnapshotExport;
}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customExpandIcon: any = (expandProps: ExpandProps) => {
    const { onExpand, expanded, record } = expandProps;
    if ('id' in record && record.id === CREATING_SNAPSHOT_ID) {
      return (
        <ProgressIndicatorIcon
          className="spin"
          data-test-id="progress-indicator-icon"
        />
      );
    }
    return (
      <ArrowIcon
        onClick={(e): void => {
          onExpand(record, e);
        }}
        data-test-id="arrow-icon"
        className={expanded ? 'expand-icon rotated' : 'expand-icon'}
      />
    );
  };

  const formatFileSize = (MaxFileSize: number): string => {
    const isGreaterThan1Gb = MaxFileSize >= GB;
    const size = Math.round(MaxFileSize / (isGreaterThan1Gb ? GB : MB));
    const unit = isGreaterThan1Gb ? 'GB' : 'MB';
    return `${size}${unit}`;
  };

  const dateSorter = (
    a: Snapshot | SnapshotExport,
    b: Snapshot | SnapshotExport,
  ): number => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();

  const renderExportsTable = (SnapshotExports: SnapshotExport[]): JSX.Element => {
    if (SnapshotExports.length === 0) {
      return (
        <div className="no-data-message">
          {t('SnapshotsTable.noSnapshotExports', 'No segments have been created for this snapshot. In order to download this snapshot, please create smaller, downloadable segments using the “CREATE SEGMENTS” button.') }
        </div>
      );
    }
    const columns = [
      {
        dataIndex: 'sizeAndSegments',
        key: 'sizeAndSegments',
      },
      {
        dataIndex: 'createdOn',
        key: 'createdOn',
        sorter: dateSorter,
        defaultSortOrder: 'ascend' as const,
      },
      {
        dataIndex: 'createdBy',
        key: 'createdBy',
      },
    ];
    const ExportsDataSource = SnapshotExports.map((exportItem) => ({
      key: exportItem.exportId,
      exportId: exportItem.exportId,
      sizeAndSegments: (
        <>
          <span className="bold">
            { formatFileSize(exportItem.targetMaxSize) }
          </span>
          {` Batch (${exportItem.numberOfSegments} Segments)`}
        </>
      ),
      createdOn: new Date(exportItem.createdOn).toLocaleString(navigator.language),
      createdBy: exportItem.createdBy.name,
    })) as unknown as SnapshotExport[];
    const expandableSettings = {
      expandedRowRender: (record: SnapshotExport): JSX.Element => {
        const segmentsForThisExport = snapshotExportSegments.get(record.exportId) || [];
        const segmentDataSource = segmentsForThisExport.map((segment) => ({
          key: segment.segmentNumber,
          exportId: segment.exportId,
          segmentNumber: segment.segmentNumber,
          downloadedAt: segment.downloadedAt,
          downloadedBy: segment.downloadedBy,
        }));

        const getSegmentDownloadInfo = (
          segmentRecord: typeof segmentDataSource[number],
          segmentDownloadStatus: DownloadStatus|undefined,
        ): { name: string; date: string}|null => {
          if (segmentDownloadStatus && segmentDownloadStatus.succeeded
            && segmentDownloadStatus.downloadedAt && me.user) {
            return {
              name: me.user.name,
              date: new Date(segmentDownloadStatus.downloadedAt).toLocaleString(navigator.language),
            };
          } if (segmentRecord.downloadedAt && segmentRecord.downloadedBy) {
            return {
              name: segmentRecord.downloadedBy.name,
              date: new Date(segmentRecord.downloadedAt).toLocaleString(navigator.language),
            };
          }
          return null;
        };

        const segmentColumns = [
          {
            title: 'Segment',
            dataIndex: 'segmentNumber',
            key: 'segmentNumber',
            render: (_: unknown, segmentRecord: typeof segmentDataSource[number]): JSX.Element => {
              const exportSegmentsDownloadStatuses = downloadStatuses.get(segmentRecord.exportId);
              let segmentDownloadStatus: DownloadStatus|undefined;
              if (exportSegmentsDownloadStatuses) {
                segmentDownloadStatus = exportSegmentsDownloadStatuses
                  .get(segmentRecord.segmentNumber);
              }
              const downloadInfo = getSegmentDownloadInfo(segmentRecord, segmentDownloadStatus);
              const disabledDownload = Date.now() < disableUntil
               || (segmentDownloadStatus && segmentDownloadStatus.isDownloading);

              const handleDownloadClick = (): void => {
                if (!disabledDownload) {
                  setDisableUntil(Date.now() + DOWNLOAD_DISABLE_DURATION);
                  downloadSnapshotExportSegment(
                    SnapshotExports[0].snapshotId,
                    segmentRecord.exportId,
                    segmentRecord.segmentNumber,
                  );
                }
              };

              return (
                <div className="segment-icons">
                  {
                  downloadInfo ? (
                    <Popover
                      placement="right"
                      content={(
                        <div className="info-pop-over">
                          <div className="info-icon">
                            <InfoOutlinedIcon />
                          </div>
                          <span>
                            <Trans
                              i18nKey="SnapshotsTable.downloaded.popover"
                              values={{
                                name: downloadInfo.name,
                                date: downloadInfo.date,
                              }}
                            >
                              {' '}
                              Last downloaded by
                              {' '}
                              {'{{name}}'}
                              {' '}
                              on
                              {' '}
                              {'{{date}}'}
                            </Trans>
                          </span>
                        </div>
                               )}
                    >
                      <CheckGreenIcon height="20" width="20" data-test-id="downloaded-snapshot-icon" />
                    </Popover>
                  )
                    : (
                      <span className="check-green-icon-placeholder" />
                    )
                      }
                  <DownloadIcon
                    className={`download-icon ${disabledDownload ? 'disabled' : ''}`}
                    data-test-id="snapshot-download-icon"
                    onClick={handleDownloadClick}
                  />
                  {` Segment ${segmentRecord.segmentNumber}`}
                </div>
              );
            },
          },
        ];


        return (
          <Table
            dataSource={segmentDataSource}
            columns={segmentColumns}
            pagination={false}
            showHeader={false}
            data-test-id="snapshots-exports-segments-table"
          />
        );
      },
      expandIcon: customExpandIcon,
    };

    return (
      <Table
        dataSource={ExportsDataSource}
        columns={columns}
        pagination={false}
        showHeader={false}
        expandable={expandableSettings}
        data-test-id="snapshots-exports-table"
      />
    );
  };


  const getRowClassName = (snapshot: Snapshot): string => (
    snapshot.id === CREATING_SNAPSHOT_ID ? 'in-progress' : ''
  );

  return (
    <div className="SnapshotsTable">
      { snapshots && snapshots.length === 0 && !loading ? (
        <div className="no-data-message">
          {t('SnapshotsTable.noSnapshots', 'No snapshots have been created for this Repository. Users can generate new snapshots by clicking the “CREATE DATA SNAPSHOT” button at the top of this page.') }
        </div>
      ) : (
        <Table
          dataSource={localSnapshots}
          rowKey="id"
          size="middle"
          loading={loading || creatingSnapshotExport}
          rowClassName={getRowClassName}
          data-test-id="snapshots-table"
          pagination={false}
          expandable={{
            expandedRowRender: (record: Snapshot): JSX.Element => {
              const exportsForThisSnapshot = snapshotExports.get(record.id) || [];
              return renderExportsTable(exportsForThisSnapshot);
            },
            expandIcon: customExpandIcon,
          }}
        >
          <Table.Column
            title={t('SnapshotsTable.column.name', ' NAME')}
            className="name-column"
            key="name"
            render={(snapshot: Snapshot): string => (
              snapshot.name
            )}
            data-test-id="column-name"
          />
          <Table.Column
            title={t('SnapshotsTable.column.createdOn', ' CREATED ON')}
            className="created-on-column"
            key="created-on"
            sorter={dateSorter}
            defaultSortOrder="ascend"
            render={(snapshot: Snapshot): string => (
              new Date(snapshot.createdOn).toLocaleString(navigator.language)
            )}
            data-test-id="column-created-on"
          />
          <Table.Column
            title={t('SnapshotsTable.column.createdBy', ' CREATED BY')}
            className="created-by-column"
            key="created-by"
            render={(snapshot: Snapshot): string => (
              snapshot.createdBy.name
            )}
            data-test-id="column-created-by"
          />
          <Table.Column
            className="actions-column"
            key="actions"
            data-test-id="column-actions"
            render={(snapshot: Snapshot): JSX.Element => (
              <Button
                key="create-segments"
                type="primary"
                data-test-id="create-segments-button"
                icon={<AddCircle className="button-icon-left" />}
                onClick={(): void => openModal(snapshot.id)}
              >
                {t('SnapshotsTable.createSegments', 'CREATE SEGMENTS')}
              </Button>
            )}
          />
        </Table>
      )}
      {<CreateExportSegmentsModal
        isModalVisible={isModalVisible}
        handleCancelModal={handleCancelModal}
        handleCreateSegments={handleCreateSegments}
        newExportData={newExportData}
        setNewExportData={setNewExportData}
      />}
    </div>
  );
}

export default SnapshotsTable;
