/** @module components/IEBanner */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Banner from 'components/Banner';
import { Props } from './types';

function IEBanner(props: Props): JSX.Element | null {
  const {
    closedBannerIds,
    setBannerClosed,
  } = props;

  const { t } = useTranslation();
  const bannerId = 'IEBanner';

  const isIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent);
  const isIE = isIE11 || window.navigator.userAgent.includes('MSIE');
  const wasBannerClosed = closedBannerIds.includes(bannerId);

  const handleCloseBanner = (): void => {
    setBannerClosed(bannerId);
  };

  // Only render the banner if the browser is IE and the banner wasn't previously closed.
  if (isIE && !wasBannerClosed) {
    return (
      <Banner
        onClose={handleCloseBanner}
        hasAlertIcon
        data-test-id="ie-banner"
        className="IEBanner"
      >
        <div className="banner-text-container">
          <span className="banner-text">
            {t(
              'IEBanner.message',
              'We see that you are accessing REDshare with Internet Explorer. For the best REDshare experience, we suggest using Chrome as your browser. Some functions may not perform as expected in Internet Explorer.',
            )}
          </span>
        </div>
      </Banner>
    );
  }
  return null;
}

export default IEBanner;
