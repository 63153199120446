/** @module store/users */
import { Action } from 'redux';
import { User } from 'services/metadata';

export type UsersState = {
  searchedUsers: User[];
  selectedUser?: User;
  deletedUserId?: string;
};

export enum UsersActionType {
  USERS_GET_REQUEST = 'USERS_GET_REQUEST',
  USERS_GET_SUCCESS = 'USERS_GET_SUCCESS',
  USERS_GET_ERROR = 'USERS_GET_ERROR',
  USERS_CHECK_EXISTING_REQUEST = 'USERS_CHECK_EXISTING_REQUEST',
  USERS_CHECK_EXISTING_SUCCESS = 'USERS_CHECK_EXISTING_SUCCESS',
  USERS_CHECK_EXISTING_ERROR = 'USERS_CHECK_EXISTING_ERROR',
  USERS_SELECTED_BLOCK_REQUEST = 'USERS_SELECTED_BLOCK_REQUEST',
  USERS_SELECTED_BLOCK_SUCCESS = 'USERS_SELECTED_BLOCK_SUCCESS',
  USERS_SELECTED_BLOCK_ERROR = 'USERS_SELECTED_BLOCK_ERROR',
  USERS_SELECTED_UNBLOCK_REQUEST = 'USERS_SELECTED_UNBLOCK_REQUEST',
  USERS_SELECTED_UNBLOCK_SUCCESS = 'USERS_SELECTED_UNBLOCK_SUCCESS',
  USERS_SELECTED_UNBLOCK_ERROR = 'USERS_SELECTED_UNBLOCK_ERROR',
  USERS_SELECTED_DELETE_REQUEST = 'USERS_SELECTED_DELETE_REQUEST',
  USERS_SELECTED_DELETE_SUCCESS = 'USERS_SELECTED_DELETE_SUCCESS',
  USERS_SELECTED_DELETE_ERROR = 'USERS_SELECTED_DELETE_ERROR',
  USERS_SET_SELECTED = 'USERS_SET_SELECTED',
  USERS_DELETED_CLEAR_STORE_REQUEST = 'USERS_DELETED_CLEAR_STORE_REQUEST',
}

export type UsersGetRequestAction = Action<
  UsersActionType.USERS_GET_REQUEST
>;

export interface UsersGetSuccessAction extends Action<
  UsersActionType.USERS_GET_SUCCESS
> {
  payload: {
    users: User[];
  };
}

export interface UsersGetErrorAction extends Action<
  UsersActionType.USERS_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type UsersCheckExistingRequestAction = Action<
  UsersActionType.USERS_CHECK_EXISTING_REQUEST
>;

export interface UsersCheckExistingSuccessAction extends Action<
  UsersActionType.USERS_CHECK_EXISTING_SUCCESS
> {
  payload: {
    exists: boolean;
  };
}

export interface UsersCheckExistingErrorAction extends Action<
  UsersActionType.USERS_CHECK_EXISTING_ERROR
> {
  payload: {
    error: Error;
  };
}

export type UsersSelectedBlockRequestAction = Action<
  UsersActionType.USERS_SELECTED_BLOCK_REQUEST
>;

export interface UsersSelectedBlockSuccessAction extends Action<
  UsersActionType.USERS_SELECTED_BLOCK_SUCCESS
> {
  payload: {
    userId: string;
  };
}

export interface UsersSelectedBlockErrorAction extends Action<
  UsersActionType.USERS_SELECTED_BLOCK_ERROR
> {
  payload: {
    error: Error;
  };
}

export type UsersSelectedUnblockRequestAction = Action<
  UsersActionType.USERS_SELECTED_UNBLOCK_REQUEST
>;

export interface UsersSelectedUnblockSuccessAction extends Action<
  UsersActionType.USERS_SELECTED_UNBLOCK_SUCCESS
> {
  payload: {
    userId: string;
  };
}

export interface UsersSelectedUnblockErrorAction extends Action<
  UsersActionType.USERS_SELECTED_UNBLOCK_ERROR
> {
  payload: {
    error: Error;
  };
}

export type UsersSelectedDeleteRequestAction = Action<
  UsersActionType.USERS_SELECTED_DELETE_REQUEST
>;

export interface UsersSelectedDeleteSuccessAction extends Action<
  UsersActionType.USERS_SELECTED_DELETE_SUCCESS
> {
  payload: {
    userId: string;
  };
}

export interface UsersSelectedDeleteErrorAction extends Action<
  UsersActionType.USERS_SELECTED_DELETE_ERROR
> {
  payload: {
    error: Error;
  };
}

export interface UsersSetSelectedAction extends Action<
  UsersActionType.USERS_SET_SELECTED
> {
  payload: {
    user: User;
  };
}

export type UsersDeletedClearStoreAction = Action<
  UsersActionType.USERS_DELETED_CLEAR_STORE_REQUEST
>;


export type UsersAction = UsersGetRequestAction
                        | UsersGetSuccessAction
                        | UsersGetErrorAction
                        | UsersCheckExistingRequestAction
                        | UsersCheckExistingSuccessAction
                        | UsersCheckExistingErrorAction
                        | UsersCheckExistingErrorAction
                        | UsersSelectedBlockRequestAction
                        | UsersSelectedBlockSuccessAction
                        | UsersSelectedBlockErrorAction
                        | UsersSelectedUnblockRequestAction
                        | UsersSelectedUnblockSuccessAction
                        | UsersSelectedUnblockErrorAction
                        | UsersSelectedDeleteRequestAction
                        | UsersSelectedDeleteSuccessAction
                        | UsersSelectedDeleteErrorAction
                        | UsersDeletedClearStoreAction;
