/** @module components/AdminReassignRepositoriesPage */
import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import ErrorOutlined from '@material-ui/icons/ErrorOutlined';
import { ReactComponent as CloseIcon } from 'assets/icons/x-cancel.svg';
import { history } from 'utilities/history';
import { appPaths } from 'utilities/routes';
import { Collaborator, CollaboratorIdentity, User } from 'services/metadata';
import { selectCollaborators } from 'store/collaborators/selectors';
import { SubmitType, UserType } from 'components/ReassignOwnerSection/types';
import { ReassignmentTask, RepoReassignmentType } from 'store/collaborators/types';
import { ReassignmentData } from 'components/DeleteUserSteps/types';
import BulkRepoActionsBanner from '../BulkRepoActionsBanner';
import DashboardPage from '../DashboardPage';
import ReassignRepositoriesTable from '../ReassignRepositoriesTable';
import ChooseUserTypeForm from '../ChooseUserTypeForm';
import UserSearchForm from '../UserSearchForm';
import { Props } from './types';
import './styles.scss';

function AdminReassignRepositoriesPage(props: Props): JSX.Element {
  const [selectedOwner, setSelectedOwner] = useState<Collaborator | undefined>();
  const [userType, setUserType] = useState(UserType.COLLABORATOR);
  const [isUserSearched, setIsUserSearched] = useState(false);
  const [notReassignedRepoIds, setNotReassignedRepoIds] = useState<string[]>([]);
  const [reassignCardOpen, setReassignCardOpen] = useState(true);
  const [reassignedRepos, setReassignedRepos] = useState<Map<string, ReassignmentData>>(new Map());
  const [
    failedReassignedRepos,
    setFailedReassignedRepos,
  ] = useState<Map<string, ReassignmentData>>(new Map());

  const { t } = useTranslation();
  const {
    me,
    user,
    getMultiCollabLoading,
    loadings,
    errors,
    searchUserLoading,
    searchedUsers,
    selectedRepositories,
    selectedRepositoriesToReassign,
    setSelectedRepositories,
    setSelectedRepositoriesToReassign,
    collaborators,
    multiReassignCollaborators,
  } = props;

  useEffect(() => {
    reassignedRepos.forEach((reassignmentData, repoId) => {
      const idWithRequest = `COLLABORATORS_${reassignmentData.type}_${repoId}`;
      if (errors[idWithRequest]) {
        setFailedReassignedRepos(
          (oldFailedRepos) => new Map(oldFailedRepos.set(repoId, reassignmentData)),
        );
      } else if (failedReassignedRepos.has(repoId)) {
        // for repo that failed earlier but is successfull now
        const updatedRepos = new Map(failedReassignedRepos);
        updatedRepos.delete(repoId);
        setFailedReassignedRepos(new Map(updatedRepos));
      }
    });
  }, [errors, reassignedRepos]);

  useEffect(() => (): void => {
    setSelectedRepositories([]);
  }, []);

  const closeCard = (): void => {
    setSelectedOwner(undefined);
    setIsUserSearched(false);
    setReassignCardOpen(false);
  };

  const closeError = (): void => {
    setIsUserSearched(false);
  };

  const onSearch = (searchQuery: string): void => {
    history.push(`${appPaths.adminUserSearch}?queryUserSearch=${searchQuery}`);
  };

  const onReassignOwner = (repoId: string, type: SubmitType, newOwnerName: string): void => {
    setReassignedRepos(
      (oldReassignedRepos) => new Map(oldReassignedRepos.set(repoId, { newOwnerName, type })),
    );
  };

  const onReassign = (): void => {
    const reassignments: ReassignmentTask[] = [];
    let newOwnerName = '';
    if (userType === UserType.COLLABORATOR && selectedOwner) {
      newOwnerName = selectedOwner.user.name;
      selectedRepositoriesToReassign.forEach((repo) => {
        const repoCollab = selectCollaborators(collaborators, repo.id);
        if (repoCollab) {
          const collaborator = repoCollab
            . find((collab) => collab.user.email === selectedOwner.user.email);
          reassignments.push({
            type: RepoReassignmentType.UPDATE,
            repoId: repo.id,
            collaborator,
            newRole: 'OWNER',
          });
        }
        onReassignOwner(repo.id, SubmitType.UPDATE, newOwnerName);
      });
    } else {
      let email: string | null = null;
      if (userType === UserType.MYSELF && me.user) {
        email = me.user.email;
        newOwnerName = me.user.name;
      } else if (userType === UserType.OTHER
          && searchedUsers.length
          && isUserSearched
          && !searchUserLoading
      ) {
        email = searchedUsers[0].email;
        newOwnerName = searchedUsers[0].name;
      }
      if (email) {
        selectedRepositoriesToReassign.forEach((repo) => {
          const repoCollab = selectCollaborators(collaborators, repo.id);
          const isCollab = repoCollab && repoCollab.find((collab) => collab.user.email === email);
          if (isCollab) {
            // if collaboration exists, update the collaborator
            reassignments.push({
              type: RepoReassignmentType.UPDATE,
              repoId: repo.id,
              collaborator: isCollab,
              newRole: 'OWNER',
            });
            onReassignOwner(repo.id, SubmitType.UPDATE, newOwnerName);
          } else {
            // add the collaborator
            const collaboratorIdentity: CollaboratorIdentity = { identity: email || '', role: 'OWNER' };
            reassignments.push({
              type: RepoReassignmentType.ADD,
              repoId: repo.id,
              collaboratorIdentity,
            });
            onReassignOwner(repo.id, SubmitType.ADD, newOwnerName);
          }
        });
      }
    }
    if (reassignments.length > 0 && newOwnerName !== '') {
      multiReassignCollaborators(reassignments, newOwnerName);
    }
    setSelectedRepositoriesToReassign([]);
    closeCard();
  };

  return (
    <div className="AdminReassignRepositoriesPage">
      <DashboardPage
        title={t('AdminReassignRepositoriesPage.DashboardPage.title', 'Reassign User\'s Repositories')}
        upLink={appPaths.adminUserSearch}
        hasBreadcrumbs
        data-test-id="page"
      >
        <div className="search-container">
          <div className="search">
            <UserSearchForm
              data-test-id="user-search-form"
              searchUsers={(searchQuery): void => onSearch(searchQuery)}
              initialValue={user && user.email}
            />
          </div>
        </div>
        <Card
          className="card"
          data-test-id="card"
          title={(
            <div className="title">
              <div className="user-name">{user && user.name}</div>
            </div>
          )}
        >
          <div className="user-section">
            <ChooseUserTypeForm
              data-test-id="choose-user-type-form"
              selectedUser={user}
              withSelf
              collaborators={collaborators}
              repoIds={selectedRepositoriesToReassign.map((repo) => repo.id)}
              setSelectedOwner={setSelectedOwner}
              userType={userType}
              setUserType={setUserType}
              setReassignCardOpen={setReassignCardOpen}
              notReassignedRepoIds={notReassignedRepoIds}
              setNotReassignedRepoIds={setNotReassignedRepoIds}
              isUserSearched={isUserSearched}
              setIsUserSearched={setIsUserSearched}
            />
            <div className="info-box">
              <InfoOutlined className="info-icon" />
              <p className="info-text">{t('AdminReassignRepositoriesPage.DashboardPage.info', 'Only registered REDshare users from the same business as the selected repositories may be assigned as the repositories’ new owner. When a repository is reassigned to a new owner, your access will be changed to “Co-Owner”.')}</p>
            </div>
          </div>
          <div className="reassign-confirmation">
            {
          ((): JSX.Element | null => {
            let newOwnerUser: User | undefined;

            if (userType === UserType.COLLABORATOR && selectedOwner) {
              newOwnerUser = selectedOwner.user;
            } else if (userType === UserType.OTHER && isUserSearched
              && !searchUserLoading && searchedUsers.length) {
              [newOwnerUser] = searchedUsers;
            } else if (userType === UserType.MYSELF) {
              newOwnerUser = me.user;
            }
            // Add more conditions here for other user types like UserType.MYSELF

            if (!newOwnerUser || !reassignCardOpen) return null;

            const isReassigButtonDisabled = (): boolean => {
              if (
                me.user && me.user.id
                && newOwnerUser && newOwnerUser.id === me.user.id
                && userType !== UserType.MYSELF
              ) {
                return true;
              }
              if (selectedRepositoriesToReassign.length < 1) {
                return true;
              }
              if (selectedRepositoriesToReassign.find(
                (repo) => newOwnerUser && repo.owner.id === newOwnerUser.id,
              )) {
                return true;
              }
              return false;
            };

            return (
              <Card
                data-test-id="reassign-repo-card"
                key={newOwnerUser.id}
                className="card internal"
                title={(
                  <div className="header">
                    <div className="title">
                      <InfoOutlined className="info-icon" />
                      <span className="bold">
                        {t('AdminReassignRepositoriesPage.confirmReassignment.title', 'Confirm Repository Reassign')}
                      </span>
                    </div>
                  </div>
                )}
                extra={<CloseIcon data-test-id="close" className="close" height="14px" width="14px" onClick={closeCard} />}
              >
                <Form.Item>
                  <Trans i18nKey="AdminReassignRepositoriesPage.confirmReassignment.text" values={{ userName: userType === UserType.MYSELF ? 'yourself' : newOwnerUser.name }}>
                    <span className="message">
                      Are you sure you would like to make
                      {' '}
                      <span className="bold">{'{{userName}}'}</span>
                      {' '}
                      the new owner of the repositories selected below?
                    </span>
                  </Trans>
                  <div className="buttons">
                    <Button
                      type="ghost"
                      className="button"
                      data-test-id="cancel-button"
                      onClick={closeCard}
                    >
                      {t('AdminReassignRepositoriesPage.confirmReassignment.cancel', 'CANCEL')}
                    </Button>
                    <Button
                      htmlType="submit"
                      className="ant-btn-primary add-button"
                      disabled={isReassigButtonDisabled() || getMultiCollabLoading}
                      loading={getMultiCollabLoading}
                      data-test-id="save-button"
                      onClick={onReassign}
                    >
                      {t('AdminReassignRepositoriesPage.confirmReassignment.save', 'SAVE')}
                    </Button>
                  </div>
                </Form.Item>
              </Card>
            );
          })()
      }
          </div>
          {searchedUsers.length === 0 && isUserSearched && !searchUserLoading && (
            <div className="reassign-failure">
              <Card
                data-test-id="reassign-failure-card"
                className="card internal"
                title={(
                  <div className="header">
                    <div className="title">
                      <ErrorOutlined className="error-icon" />
                      <span className="bold">
                        {t('AdminReassignRepositoriesPage.reassignmentError.title', 'Error: Cannot Reassign to User')}
                      </span>
                    </div>
                  </div>
                )}
                extra={<CloseIcon data-test-id="close" className="close" height="14px" width="14px" onClick={closeError} />}
              >
                {t('AdminReassignRepositoriesPage.reassignmentError.message', 'Please confirm the user you chose is a registered REDshare user with the correct permissions to become an owner on the selected repositories.')}
              </Card>
            </div>
          )}
          <div className="repositories-table">
            <BulkRepoActionsBanner
              showRestore={false}
              showDelete={false}
              reassignRepositoriesMode
            />
            <ReassignRepositoriesTable
              loadings={loadings}
              reassignedRepos={reassignedRepos}
              failedReassignedRepos={failedReassignedRepos}
              errors={errors}
              repositories={selectedRepositories}
              selectedRepositoriesToReassign={selectedRepositoriesToReassign}
              setSelectedRepositoriesToReassign={setSelectedRepositoriesToReassign}
            />
          </div>
        </Card>
      </DashboardPage>
    </div>
  );
}

export default AdminReassignRepositoriesPage;
