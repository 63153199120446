/** @module store/features */
import { FeaturesState, Feature } from './types';

export const DEFAULT_STATE: FeaturesState = {
  [Feature.ADMIN_CONSOLE]: true,
  [Feature.RENEW_EXTERNAL_COLLABORATORS]: true,
  [Feature.SEARCH]: true,
  [Feature.GAS_POWER_WIZARD]: true,
  [Feature.POWER_CONVERSION_WIZARD]: true,
  [Feature.LANGUAGE_PICKER]: false,
  [Feature.GLOBAL_SEARCH]: true,
  [Feature.DELETE_USER]: true,
};

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @return A new state
 */
export default function reducer(state = DEFAULT_STATE): FeaturesState {
  return state;
}
