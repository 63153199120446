/** @module components/RepositoryInfoSection */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { selectBusinesses } from 'store/businesses/selectors';
import { getBusinesses } from 'store/businesses/actions';
import { selectFeature } from 'store/features/selectors';
import { Feature } from 'store/features/types';
import component from './component';
import {
  StateProps,
  DispatchProps,
} from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  getBusinessLoading: selectLoading(state.loading, 'BUSINESSES_GET'),
  gasPowerWizardEnabled: selectFeature(state.features, Feature.GAS_POWER_WIZARD),
  powerConversionWizardEnabled: selectFeature(state.features, Feature.POWER_CONVERSION_WIZARD),
  businesses: selectBusinesses(state.businesses),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getBusinesses: (): void => { dispatch(getBusinesses()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(component);
