/** @module components/BulkItemActionsBanner */
import React from 'react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import MetadataService from 'services/metadata';
import BulkActionsBanner from '../BulkActionsBanner';
import RestoreFileModal from '../RestoreFileModal';
import MoveFilesModal from '../MoveFilesModal';
import { Props } from './types';
import './styles.scss';

function BulkItemActionsBanner(props: Props): JSX.Element {
  const {
    canDelete,
    canDownload,
    canMove,
    selected,
    downloadSelected,
    deleteSelected,
    repositoryId,
    clearSelection,
    showDelete = true,
    showDownload = true,
    showMove = true,
    showRestore = false,
  } = props;
  const { t } = useTranslation();
  const folderSelected = selected.filter((s) => s.type.toLowerCase() === 'folder').length > 0;

  const renderSingleFileDownloadButton = (fileId: string): JSX.Element => {
    const link = new MetadataService().getFileUrl(fileId);
    return (
      <Button
        type="link"
        href={link}
        download
        className="button"
        data-test-id="single-file-download-button"
        disabled={folderSelected}
      >
        <span className={`text ${folderSelected ? 'disabled' : ''}`}>{t('BulkItemActionsBanner.download', 'DOWNLOAD')}</span>
      </Button>
    );
  };
  return (
    <BulkActionsBanner numberSelected={selected.length} className="BulkItemActionsBanner">
      {
        showDownload && canDownload && (
          selected.length === 1 ? (
            renderSingleFileDownloadButton(selected[0].id)
          ) : (
            <Button
              type="link"
              className="button"
              onClick={(): void => { downloadSelected(); }}
              data-test-id="download-button"
              disabled={folderSelected}
            >
              <span className={`text ${folderSelected ? 'disabled' : ''}`}>{t('BulkItemActionsBanner.download', 'DOWNLOAD')}</span>
            </Button>
          )
        )
      }
      {
        showMove && canMove && (
          <MoveFilesModal
            files={selected}
            repositoryId={repositoryId}
            data-test-id="move-button"
          />
        )
      }
      {
        showDelete && canDelete && (
          <Button
            type="link"
            data-test-id="delete-button"
            className="button"
            onClick={(): void => {
              Modal.confirm({
                title: t('BulkItemActionsBanner.confirmDelete.title', 'Are you sure you want to delete the selected items?'),
                content: t('BulkItemActionsBanner.confirmDelete.content', 'Deleted items may be restored from the trash.'),
                onOk: () => deleteSelected(),
                onCancel: () => { },
                cancelButtonProps: { className: 'ant-btn-ghost' },
                autoFocusButton: null,
              });
            }}
          >
            <span className="text">{t('BulkItemActionsBanner.confirmDelete.button', 'DELETE')}</span>
          </Button>
        )
      }
      {
        selected.length > 0 && showRestore && (
          <RestoreFileModal
            repositoryId={repositoryId}
            file={selected}
            data-test-id="restore-button"
            buttonText={
              <span className="text">{t('BulkItemActionsBanner.restore', 'RESTORE')}</span>
            }
          />
        )
      }
      {selected.length > 0 && (
        <Button
          type="link"
          data-test-id="cancel-button"
          className="button"
          onClick={(): void => { clearSelection(); }}
        >
          <span className="text">{t('BulkItemActionsBanner.cancel', 'CANCEL')}</span>
        </Button>
      )}
    </BulkActionsBanner>
  );
}

export default BulkItemActionsBanner;
