/** @module components/ManageCollaboratorsPage */
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { getCollaborators } from 'store/collaborators/actions';
import { selectLoading } from 'store/loading/selectors';
import { AppDispatch, GlobalState } from 'store/types';
import { getUserRepositories } from 'store/user-repositories/actions';
import { selectUserRepository } from 'store/user-repositories/selectors';
import { AppRouteParams } from 'utilities/routes';
import Component from './component';
import {
  StateProps,
  DispatchProps,
} from './types';

export const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<AppRouteParams>,
): StateProps => ({
  repository: selectUserRepository(
    state.userRepositories,
    ownProps.match.params.userId || '',
    ownProps.match.params.repoId || '',
  ),
  me: state.me,
  loading: selectLoading(state.loading, 'USERS_REPOSITORIES_GET'),
  isAdmin: true,
});

export const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: RouteComponentProps<AppRouteParams>,
): DispatchProps => ({
  getRepositories: (): void => { dispatch(getUserRepositories(ownProps.match.params.userId || '')); },
  getCollaborators: (): void => { dispatch(getCollaborators(ownProps.match.params.repoId || '')); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
