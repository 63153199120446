/** @module components/Loader */
import React from 'react';
import { Spin } from 'antd';
import { Props } from './types';
import './styles.scss';

/**
 * A centered, indeterminate spinning loader.
 * @param props The component props
 * @return A JSX element or null
 */
function Loader(props: Props): JSX.Element | null {
  const { loading } = props;
  if (loading) {
    return (
      <div className="Loader" data-test-id="loader">
        <Spin />
      </div>
    );
  }
  return null;
}

export default Loader;
