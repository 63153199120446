/* @modules components/RoleTypeToggle */
import React from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { Props } from './types';
import './styles.scss';

function RoleTypeToggle(props: Props): JSX.Element {
  const { onChange, value } = props;
  const { t } = useTranslation();

  return (
    <Radio.Group
      value={value}
      defaultValue={false}
      onChange={(e): void => onChange(e.target.value)}
      className="RoleTypeToggle"
      data-test-id="radio-group"
    >
      <Radio.Button
        value={false}
        className="radio-button first"
      >
        {t('ManageAdminRolesForm.businessRoleToggle.globalAdmin', 'GLOBAL ADMIN')}
      </Radio.Button>
      <Radio.Button
        value
        className="radio-button last"
      >
        {t('ManageAdminRolesForm.businessRoleToggle.businessAdmin', 'BUSINESS ADMIN')}
      </Radio.Button>
    </Radio.Group>
  );
}

export default RoleTypeToggle;
