/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RepositoryIcon } from 'assets/icons/repository.svg';
import { appPaths, getAppRoutes } from 'utilities/routes';
import { ReactComponent as FileIcon } from 'assets/icons/document.svg';
import FolderIcon from '@material-ui/icons/Folder';
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import ImageIcon from '@material-ui/icons/Image';
import { ItemTypes, SearchResult } from 'services/search';
import ProtectionTag from 'components/ProtectionTag';
import MetadataService, { LicenseType, ProtectionType, Repository } from 'services/metadata';
import { history } from 'utilities/history';
import PreviewFileModal from '../PreviewFileModal';
import ItemsMenu from '../ItemsMenu';
import RepositoryMenu from '../RepositoryMenu';
import { Props, PreviewItem } from './types';
import './styles.scss';

function SearchResultsTable(props: Props): JSX.Element {
  const [previewItem, setPreviewItem] = useState<PreviewItem>();
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const {
    trackClick,
    loading,
    searchResult,
    repositories,
    searchQuery,
    searchInRepoId,
    onChangePagination,
    paginationOptions,
  } = props;

  const navigateToSelection = (
    itemId: string,
    itemType: ItemTypes,
    repoId: string,
    parentId: string,
  ): void => {
    let path = '';
    switch (itemType) {
      case ItemTypes.FILE:
        path = getAppRoutes('repositories', repoId)
          .goToFolder(parentId);
        history.push(path);
        break;

      case ItemTypes.FOLDER:
        path = getAppRoutes('repositories', repoId)
          .goToFolder(itemId);
        history.push(path);
        break;

      default:
        path = getAppRoutes('repositories', repoId)
          .goToRepository(itemId);
        history.push(path);
        break;
    }
    // track click
    trackClick(searchQuery, itemId);
  };

  const renderIcon = (type: ItemTypes, name: string): JSX.Element => {
    switch (type) {
      case ItemTypes.FILE: {
        const imageExtensionsRegex = /(\.jpg|\.jpeg|\.png|\.gif|\.svg)$/i;
        if (imageExtensionsRegex.exec(name)) {
          return (
            <ImageIcon
              height={22}
              width={22}
              className="icon"
              data-test-id="image-icon"
            />
          );
        }
        return (
          <FileIcon
            height={22}
            width={22}
            className="icon"
            data-test-id="file-icon"
          />
        );
      }
      case ItemTypes.FOLDER:
        return (
          <FolderIcon
            height={22}
            width={22}
            className="icon"
            data-test-id="folder-icon"
          />
        );
      default:
        return (
          <RepositoryIcon
            height={22}
            width={22}
            className="icon"
            data-test-id="repo-icon"
          />
        );
    }
  };

  /**
   * Escapes all regex special characters in a string.
   * @param text The text
   * @return A sanitized string
   */
  const escapeRegExp = (text: string): string => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

  /**
   * Highlights the text's characters which matches with the searchQuery
   * @param text Result's name
   * @returns JSX element
   */
  const displayMatches = (text: string, className: string): JSX.Element => {
    const searchQueryWithoutQuotes = searchQuery.replace(/"/g, '');
    const parts = text.split(new RegExp(`(${escapeRegExp(searchQueryWithoutQuotes)})`, 'gi'));
    return (
      <div className={className} data-test-id={className}>
        {parts.map((part, i) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className={part.toLowerCase() === searchQueryWithoutQuotes.toLowerCase() ? 'highlight' : 'unmatched-text'}
          >
            {part}
          </span>
        ))}
      </div>
    );
  };

  const onCloseModal = (): void => {
    setModalOpen(false);
    setPreviewItem(undefined);
  };

  const renderPreviewModal = (): JSX.Element | null => {
    if (previewItem && previewItem.id) {
      return (
        <PreviewFileModal
          filePreviewName={previewItem.name}
          fileId={previewItem.id}
          visible={modalOpen}
          canDownload
          handleClose={onCloseModal}
          data-test-id="preview-modal"
        />
      );
    }
    return null;
  };

  const onPreviewFile = (id: string, name: string): void => {
    setModalOpen(true);
    setPreviewItem({ id, name });
  };

  // Update when implementing global search
  const searchInRepoDetails = searchInRepoId
    ? repositories.find((r) => r.id === searchInRepoId) : undefined;
  let repoDetails: Repository | undefined;

  return (
    <div className="SearchResultsTable">
      {renderPreviewModal()}
      <Table
        data-test-id="table"
        loading={loading}
        dataSource={searchResult}
        showHeader={false}
        pagination={paginationOptions}
        onChange={(p): void => onChangePagination(p)}
        rowKey={(r): string => r.id.raw}
        size="middle"
        columns={[
          {
            dataIndex: 'details',
            key: 'details',
            className: 'details-column',
            render: (text: string, result: SearchResult, index: number): JSX.Element => {
              const repoId = result.repo_id.raw;
              const id = result.id.raw;
              const typeId = result.type_id.raw;
              const name = result.name.raw;
              const parentId = result.parent_id.raw;
              const snippet = result.full_text && result.full_text.snippet;
              // this find will be called only once to avoid multiple find ops for the same result
              repoDetails = repositories.find((r) => r.id === result.repo_id.raw);
              const link = new MetadataService().getFileUrl(id);

              return (
                <div
                  data-test-id="details-column"
                  className="details-container"
                  key={id}
                >
                  <div data-test-id="icon">
                    {renderIcon(typeId, name)}
                  </div>

                  <div className="name-and-snippet">
                    {typeId === ItemTypes.FILE ? (
                      <Tooltip title="Download">
                        <Button type="link" href={link} download className="name-link" data-test-id="download-file">
                          {displayMatches(name, 'name')}
                        </Button>
                      </Tooltip>
                    ) : (
                      <div
                        onClick={(): void => navigateToSelection(
                          id,
                          typeId,
                          repoId,
                          parentId,
                        )}
                        onKeyDown={(): void => navigateToSelection(
                          id,
                          typeId,
                          repoId,
                          parentId,
                        )}
                        role="row"
                        tabIndex={index}
                        data-test-id="name-container"
                      >
                        {displayMatches(name, 'name')}
                      </div>
                    )}

                    {typeId !== ItemTypes.REPOSITORY && !searchInRepoId && (
                      <div className="repo-name" data-test-id="repo-name">
                        {repoDetails ? repoDetails.name : ''}
                      </div>
                    )}

                    {snippet
                      && displayMatches(snippet.replace(/(<([^>]+)>)/ig, '').replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>')
                        .replace(/&quot;/g, '"'), 'snippet')}
                  </div>
                </div>
              );
            },
          },
          {
            key: 'protection',
            className: 'protections-column',
            render: (): JSX.Element | null => {
              let repository: Repository | undefined;
              if (searchInRepoDetails) {
                repository = searchInRepoDetails;
              } else {
                repository = repoDetails;
              }
              if (repository) {
                return (
                  <div data-test-id="protections">
                    {repository.protections.map((protection: LicenseType) => (
                      <span className="protection" key={protection}>
                        <ProtectionTag
                          protection={protection}
                          key={protection}
                          data-test-id="protection-tag"
                        />
                      </span>
                    ))}
                    {repository.isLimited && (
                    <span className="protection-tag" key={ProtectionType.LIMITED}>
                      <ProtectionTag
                        protection={ProtectionType.LIMITED}
                        key={ProtectionType.LIMITED}
                        data-test-id="protection-tag-limited"
                      />
                    </span>
                    )}
                  </div>
                );
              }
              return null;
            },
          },
          {
            key: 'tags',
            className: 'tags-column',
            render: (): JSX.Element | null => {
              let tags: string[] | undefined;
              if (searchInRepoDetails && searchInRepoDetails.tags) {
                tags = searchInRepoDetails.tags;
              } else {
                tags = repoDetails ? repoDetails.tags : undefined;
              }
              if (tags) {
                return (
                  <div data-test-id="tags">
                    {tags.length === 0 && (
                      <span data-test-id="no-tags-text" className="no-tags-text">
                        {t('SearchResultsTable.noTags', 'No tags')}
                      </span>
                    )}
                    {tags.map((tag): JSX.Element | null => (
                      <span className="tag" key={tag} data-test-id="tag">
                        <TagIcon className="tag-icon" width={12} height={12} />
                        {tag}
                      </span>
                    ))}
                  </div>
                );
              }
              return null;
            },
          },
          {
            key: 'updated-on',
            render: (text: string, result: SearchResult): JSX.Element => {
              const updatedOn = result.updated_on.raw;
              return (
                <span className="updated-on" data-test-id="updated-on">
                  {t('SearchResultsTable.modifiedOn', 'Modified {{updatedOn}}',
                    {
                      updatedOn: new Date(updatedOn).toLocaleString(navigator.language, {
                        year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit',
                      }).toLocaleUpperCase(),
                    })}
                </span>
              );
            },
          },
          {
            key: 'menu',
            width: '10%',
            render: (result: SearchResult): JSX.Element => {
              if (result.type_id.raw === ItemTypes.REPOSITORY) {
                return <RepositoryMenu repositoryId={result.id.raw} data-test-id="repository-menu" />;
              }
              return (
                <ItemsMenu
                  parentId={result.parent_id.raw}
                  itemId={result.id.raw}
                  repositoryId={result.repo_id.raw}
                  onPreview={(): void => onPreviewFile(result.id.raw, result.name.raw)}
                  data-test-id="items-menu"
                  from={appPaths.searchResults}
                />
              );
            },
          },
        ]}
      />
    </div>
  );
}

export default SearchResultsTable;
