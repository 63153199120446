/** @module components/UploadProgress */
import React, { useState } from 'react';
import {
  List,
  Progress,
  Row,
  Col,
  Button,
  Tooltip,
} from 'antd';
import { UploadStatus } from 'services/storage';
import { ReactComponent as FileIcon } from 'assets/icons/document.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { FileRowProps } from './types';

/**
 * Renders a file row in the upload progress list.
 * @param props The props
 * @return A JSX Element
 */
function FileRow(props: FileRowProps): JSX.Element {
  const { file, cancelFileUpload, style } = props;
  const [hovering, setHovering] = useState(false);
  const cancelled = file.status === UploadStatus.CANCELLED;
  const completed = file.status === UploadStatus.COMPLETED;
  const error = file.status === UploadStatus.ERROR;
  return (
    <List.Item
      key={file.id}
      style={style}
      onMouseOverCapture={(): void => setHovering(true)}
      onMouseLeave={(): void => setHovering(false)}
      data-test-id="file-row"
    >
      <Row
        justify="space-between"
        align="middle"
        className="row"
        gutter={16}
      >
        <Col
          span={22}
          className="column--name"
        >
          <FileIcon
            width={10}
            height={10}
            className="icon file-icon"
          />
          <Tooltip overlay={file.file.name} className="text">
            {file.file.name}
          </Tooltip>
        </Col>
        <Col
          span={2}
          className="column--close"
        >
          <Progress
            type="circle"
            percent={
              file.progress === 1 && file.status !== UploadStatus.COMPLETED ? 99
                : Math.trunc(file.progress * 100)
              }
            status={(
              file.status === UploadStatus.CANCELLED
              || file.status === UploadStatus.ERROR
            ) ? 'exception' : undefined}
            width={32}
            showInfo={!hovering || cancelled || completed || error}
            strokeWidth={16}
            strokeLinecap="square"
            className="circular-progress"
            data-test-id="progress-bar"
          />
          {!completed && !cancelled && !error && hovering && (
            <Button
              type="link"
              onClick={(): void => cancelFileUpload(file.id)}
              className="cancel-button"
              data-test-id="cancel-button"
            >
              <CloseIcon
                width={24}
                height={24}
                className="icon"
              />
            </Button>
          )}
        </Col>
      </Row>
    </List.Item>
  );
}

export default FileRow;
