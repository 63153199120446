/** @module utilities/routes */
import { ItemDetails } from 'services/metadata';

/**
 * Returns a link to the given item.
 * @param item The item
 * @return A link to the item
 */
function getItemLink(item: ItemDetails): string {
  let url = (item.type.toUpperCase() === 'FOLDER')
    ? `${window.location.origin}/repositories/${item.repoId}/folder/${item.id}`
    : `${window.location.origin}/repositories/${item.repoId}/folder/${item.parentId}`
      .replace('my-repositories', 'repositories')
      .replace('shared-repositories', 'repositories');
  if (item.type.toUpperCase() === 'FILE') {
    url = `${url}?preview=${item.id}`;
  }
  return url;
}

export default getItemLink;
