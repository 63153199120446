/** @module utilities/admin */

import { GlobalPermissions, Permissions } from 'services/metadata/types/Permissions';

/**
 * Checks if the admin has a particular global permission
 * @param adminPermissions admin permissions object
 * @param permissionToCheck a global permission to check
 * @return a boolean which tells if the permission exists or not
 */

export default function hasGlobalPermission(
  adminPermissions: Permissions | undefined,
  permissionToCheck: GlobalPermissions,
): boolean {
  let hasPermission = false;
  if (adminPermissions) {
    const { global } = adminPermissions;
    if (global.includes(permissionToCheck)) {
      hasPermission = true;
    }
  }
  return hasPermission;
}
