/** @module components/PreviewFileModal */
import { connect } from 'react-redux';
import { getFilePreviewUrl } from 'store/repositoryItems/actions';
import { selectLoading } from 'store/loading/selectors';
import { selectError } from 'store/error/selectors';
import { GlobalState, AppDispatch } from 'store/types';
import PreviewFile from './component';
import {
  DispatchProps,
  OwnProps,
  StateProps,
} from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  fetching: selectLoading(state.loading, 'REPOSITORY_ITEMS_PREVIEW_GET'),
  error: selectError(state.error, 'REPOSITORY_ITEMS_PREVIEW_GET'),
  previewUrl: state.items.previewUrl,
});

export const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps): DispatchProps => ({
  fetchFilePreview: (): void => { dispatch(getFilePreviewUrl(ownProps.fileId)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreviewFile);
