/** @module components/FileTable */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { ItemDetails } from 'services/metadata';
import {
  setSelectedRepositoryItems,
  updateName,
} from 'store/repositoryItems/actions';
import FileTable from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  selectedItems: state.items.selected,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  setSelectedItems: (itemDetails: ItemDetails[]): void => {
    dispatch(setSelectedRepositoryItems(itemDetails));
  },
  updateName: (type: string, id: string, name: string, parentId: string): void => {
    dispatch(updateName(type, id, name, parentId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FileTable);
