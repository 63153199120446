/** @module store/repositories */
import { AnyAction } from 'redux';
import { sortAlphabetical } from 'utilities/sort';
import { CollaboratorsActionType } from 'store/collaborators/types';
import { TagsActionType } from 'store/tags/types';
import {
  RepositoriesActionType,
  RepositoryEvictSuccessAction,
  State,
} from './types';

export const DEFAULT_STATE: State = {
  entries: [],
  selected: [],
  selectedToReassign: [],
  trash: [],
  lastUpdated: undefined,
  evictions: {
    statuses: {},
  },
};

function reducer(state = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case RepositoriesActionType.REPOSITORIES_GET_SUCCESS:
      return {
        ...state,
        entries: action.payload.repositories,
        lastUpdated: new Date(),
      };
    case RepositoriesActionType.REPOSITORY_DELETE_SUCCESS: {
      const { repository } = action.payload;
      return {
        ...state,
        entries: state.entries.filter((entry) => entry.id !== repository.id),
      };
    }
    case RepositoriesActionType.REPOSITORY_UPDATE_SUCCESS: {
      const { id, name, fullTextSearch } = action.payload;
      const entry = state.entries.find((r) => r.id === id);
      if (entry) {
        const newEntries = [...state.entries.filter((r) => r.id !== id)];
        newEntries.push({
          ...entry,
          name,
          fullTextSearch: fullTextSearch !== undefined ? fullTextSearch : entry.fullTextSearch,
          lastUpdatedOn: new Date(),
        });
        newEntries.sort((a, b) => sortAlphabetical(a.name, b.name));
        return {
          ...state,
          entries: newEntries,
        };
      }
      return state;
    }
    case RepositoriesActionType.REPOSITORIES_TRASH_GET_SUCCESS: {
      const { repositories } = action.payload;
      return {
        ...state,
        trash: repositories,
      };
    }
    case RepositoriesActionType.REPOSITORY_RESTORE_SUCCESS: {
      const { id } = action.payload;
      const repository = state.trash.find((entry) => entry.id === id);
      if (repository) {
        return {
          ...state,
          entries: [
            ...state.entries,
            {
              ...repository,
              lastUpdatedOn: new Date(),
            },
          ].sort((a, b) => sortAlphabetical(a.name, b.name)),
          trash: state.trash.filter((entry) => entry.id !== id),
        };
      }
      return state;
    }
    case RepositoriesActionType.REPOSITORIES_SET_SELECTED: {
      const { repositories } = action.payload;
      return {
        ...state,
        selected: repositories,
      };
    }
    case RepositoriesActionType.REPOSITORIES_SET_SELECTED_TO_REASSIGN: {
      const { repositories } = action.payload;
      return {
        ...state,
        selectedToReassign: repositories,
      };
    }
    case CollaboratorsActionType.COLLABORATORS_REMOVE_SUCCESS: {
      const { isSelf, repositoryId } = action.payload;
      if (isSelf) {
        return {
          ...state,
          entries: state.entries.filter((entry) => entry.id !== repositoryId),
        };
      }
      return state;
    }
    case TagsActionType.REPOSITORY_TAG_MAPPING_CREATE_SUCCESS: {
      const { tag, repoId } = action.payload;
      const entry = state.entries.find((r) => r.id === repoId);
      if (entry) {
        const newEntries = [...state.entries.filter((r) => r.id !== repoId)];
        newEntries.push({
          ...entry,
          lastUpdatedOn: new Date(),
          tags: entry.tags ? [...entry.tags, tag.name] : [tag.name],
        });
        newEntries.sort((a, b) => sortAlphabetical(a.name, b.name));
        return {
          ...state,
          entries: newEntries,
        };
      }
      return state;
    }
    case TagsActionType.REPOSITORY_TAG_MAPPING_DELETE_SUCCESS: {
      const { tag, repoId } = action.payload;
      const entry = state.entries.find((r) => r.id === repoId);
      if (entry && entry.tags) {
        const newEntries = [...state.entries.filter((r) => r.id !== repoId)];
        newEntries.push({
          ...entry,
          lastUpdatedOn: new Date(),
          tags: entry.tags.filter((t) => t !== tag.name),
        });
        newEntries.sort((a, b) => sortAlphabetical(a.name, b.name));
        return {
          ...state,
          entries: newEntries,
        };
      }
      return state;
    }
    case RepositoriesActionType.REPOSITORY_GET_EVICTION_STATUS_SUCCESS: {
      const { repoId, status } = action.payload;
      return {
        ...state,
        evictions: {
          ...state.evictions,
          statuses: {
            ...state.evictions.statuses,
            [repoId]: status,
          },
        },
      };
    }
    case RepositoriesActionType.REPOSITORY_EVICT_SUCCESS: {
      const evictAction = action as RepositoryEvictSuccessAction;
      const { id } = evictAction.payload;
      return {
        ...state,
        evictions: {
          ...state.evictions,
          lastEvictedRepositoryId: id,
          lastEvictionDate: new Date(),
        },
      };
    }
    default:
      return state;
  }
}

export default reducer;
