/** @module components/UserRepositoryMenu */
import React from 'react';
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Popover,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { LicenseType } from 'services/metadata';
import { BusinessPermissions, GlobalPermissions } from 'services/metadata/types/Permissions';
import { history } from 'utilities/history';
import { hasBusinessPermission, hasGlobalPermission } from 'utilities/admin';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as NoEditIcon } from 'assets/icons/no-edit.svg';
import { EvictionState } from 'services/metadata/types/EvictionState';
import ExtractRepoModal from '../ExtractRepoModal';
import LicensesModal from '../LicensesModal';
import MoreButton from '../MoreButton';
import { Props } from './types';

/**
 * A component used in admin console to show user's repository menu
 */
function UserRepositoryMenu(props: Props): JSX.Element | null {
  const menuItems = [];
  const {
    repository,
    repoEvictionStatus,
    setEditName,
    onDeleteRepository,
    adminPermissions,
    user,
  } = props;
  const { t } = useTranslation();

  let canDelete = false;
  let canMngCollab = false;
  let canRename = false;
  let canEvict = false;

  const repoBusinessId = (repository && repository.business && repository.business.id) || '';
  canDelete = hasGlobalPermission(adminPermissions, GlobalPermissions.GLOBAL_DELETE_REPOSITORY);
  canMngCollab = hasGlobalPermission(
    adminPermissions,
    GlobalPermissions.GLOBAL_LIST_REPOSITORY_COLLABORATORS,
  ) || hasGlobalPermission(
    adminPermissions,
    GlobalPermissions.GLOBAL_INVITE_COLLABORATOR,
  );
  canRename = hasGlobalPermission(adminPermissions, GlobalPermissions.GLOBAL_RENAME_REPOSITORY);
  canEvict = hasGlobalPermission(adminPermissions, GlobalPermissions.GLOBAL_EVICT_REPOSITORY);

  const isRepoEvicted = (): boolean => !!(
    (repository && repository.evictionState === EvictionState.EVICTED)
  || (repoEvictionStatus && repoEvictionStatus.evictionStatus.complete)
  );

  if (isRepoEvicted()) {
    return (
      <Popover
        placement="left"
        data-test-id="no-edit-pop-over"
        content={(
          <div className="no-edit-pop-over">
            <div className="info-icon">
              <InfoOutlinedIcon />
            </div>
            <span>{t('UserRepositoryMenu.no-edit.popover', 'Repository Extracted. No further changes may be made to this repository.')}</span>
          </div>
        )}
      >
        <span className="no-edit-icon" data-test-id="no-edit-icon"><NoEditIcon height="21" width="22" /></span>
      </Popover>
    );
  }

  if (!canDelete) {
    canDelete = hasBusinessPermission(
      adminPermissions,
      repoBusinessId,
      BusinessPermissions.BUSINESS_DELETE_REPOSITORY,
    );
  }
  if (!canMngCollab) {
    canMngCollab = hasBusinessPermission(
      adminPermissions,
      repoBusinessId,
      BusinessPermissions.BUSINESS_LIST_REPOSITORY_COLLABORATORS,
    ) || hasBusinessPermission(
      adminPermissions,
      repoBusinessId,
      BusinessPermissions.BUSINESS_INVITE_COLLABORATOR,
    );
  }
  if (!canRename) {
    canRename = hasBusinessPermission(
      adminPermissions,
      repoBusinessId,
      BusinessPermissions.BUSINESS_RENAME_REPOSITORY,
    );
  }
  if (!canEvict) {
    canEvict = hasBusinessPermission(
      adminPermissions,
      repoBusinessId,
      BusinessPermissions.BUSINESS_EVICT_REPOSITORY,
    );
  }

  if (repository) {
    if (canMngCollab) {
      const userId = user.id;
      menuItems.push(
        <Menu.Item key="manage-collaborators">
          <Button
            type="link"
            onClick={(): void => history.push(`/admin/manage-collaborators/${userId}/${repository.id}`)}
            data-test-id="manage-collaborators-button"
          >
            {t('UserRepositoryMenu.mngCollab', 'Manage Collaborators')}
          </Button>
        </Menu.Item>,
      );
    }
    // Add permission check when there is license permission
    if (repository.protections.includes(LicenseType.LR)) {
      menuItems.push(
        <Menu.Item key="manage-licenses">
          <LicensesModal
            repository={repository}
            data-test-id="licenses-modal"
          />
        </Menu.Item>,
      );
    }
    if (canRename) {
      menuItems.push(
        <Menu.Item key="edit-name">
          <Button
            type="link"
            onClick={(): void => { setEditName(repository); }}
            data-test-id="edit-name-button"
          >
            {t('UserRepositoryMenu.rename', 'Rename')}
          </Button>
        </Menu.Item>,
      );
    }
    if (canDelete) {
      menuItems.push(
        <Menu.Item key="delete-repository">
          <Button
            type="link"
            onClick={(): void => {
              Modal.confirm({
                title: t(
                  'UserRepositoryMenu.confirmDelete.title',
                  'Are you sure you want to delete "{{name}}"?',
                  { name: repository.name },
                ),
                content: t(
                  'UserRepositoryMenu.confirmDelete.content',
                  'Deleted repository may be restored from the trash.',
                ),
                onOk: (): void => onDeleteRepository(repository),
                onCancel: () => { },
                cancelButtonProps: { className: 'ant-btn-ghost' },
                autoFocusButton: null,
              });
            }}
            data-test-id="delete-repository-button"
          >
            {t('UserRepositoryMenu.delete', 'Delete Repository')}
          </Button>
        </Menu.Item>,
      );
    }

    if (canEvict) {
      menuItems.push(
        <Menu.Item key="evict-repository">
          <ExtractRepoModal
            repository={repository}
            repoEvictionStatus={repoEvictionStatus}
            data-test-id="evict-repository-button"
          />
        </Menu.Item>,
      );
    }
  }

  return menuItems.length ? (
    <>
      <Dropdown
        overlay={<Menu>{menuItems}</Menu>}
        trigger={['click']}
        placement="bottomLeft"
        data-test-id="dropdown"
      >
        <div>
          <MoreButton />
        </div>
      </Dropdown>
    </>
  ) : null;
}

export default UserRepositoryMenu;
