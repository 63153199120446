/** @module components/RepositoryWizard/fields */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Form,
  Radio,
  Row,
} from 'antd';
import { QuestionFieldProps } from '../types';
import { EXTRA_SPAN, GUTTER, QUESTION_SPAN } from './constants';

/**
 * Asks the user if the repository requires an EC license.
 * @param props The props
 * @return A JSX Element
 */
function ECLicenseQuestionField(props: QuestionFieldProps): JSX.Element {
  const { onChange, value } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={GUTTER} className="RepositoryWizardField">
      <Col span={QUESTION_SPAN}>
        <Form.Item
          name="ec-license"
          rules={[{
            validator: (): Promise<void> => {
              if (value !== undefined) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('RepositoryWizard.ECLicenseQuestionField.required', 'Required')));
            },
          }]}
          label={t('RepositoryWizard.ECLicenseQuestionField.question', 'Will an Export Control License for Non-US Person access be needed?')}
          colon={false}
          valuePropName=""
        >
          <Radio.Group
            data-test-id="radio-group"
            onChange={(e): void => onChange(e.target.value)}
            value={value}
          >
            <Radio.Button value>{t('RepositoryWizard.ECLicenseQuestionField.answer.yes', 'Yes')}</Radio.Button>
            <Radio.Button value={false}>{t('RepositoryWizard.ECLicenseQuestionField.answer.no', 'No')}</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={EXTRA_SPAN}>
        <div className="extra">
          {t('RepositoryWizard.ECLicenseQuestionField.description', 'An explicit approval granted by a government authorizing exportation in the form of a permit/license either issued individually to a specific company or generally for public use.')}
        </div>
      </Col>
    </Row>
  );
}

export default ECLicenseQuestionField;
