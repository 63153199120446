/** @module components/ModalAction */
import React from 'react';
import { Modal, Button } from 'antd';
import { Props, State } from './types';

/**
 * A modal with an associated action button that opens it.
 *
 * NOTE: This modal uses destroyOnClose={true} by default.
 * If you need the children to retain their state between modal openings,
 * pass destroyOnClose={false} to this component.
 */
class ModalAction extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggleModal = (): void => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  handleModalOk = (): void => {
    const { okHandler } = this.props;
    if (okHandler) {
      okHandler();
      return;
    }
    this.setState({
      showModal: false,
    });
  };

  handleModalCancel = (): void => {
    const { cancelHandler } = this.props;
    if (cancelHandler) {
      cancelHandler();
      return;
    }
    this.setState({
      showModal: false,
    });
  };

  render(): JSX.Element {
    const {
      actionText,
      actionIcon,
      actionRender,
      children,
      footer,
      title,
      closable,
      maskClosable,
      visible,
      className,
      afterClose,
    } = this.props;
    const { showModal } = this.state;
    const ActionIcon = actionIcon;
    return (
      <>
        {actionRender ? (
          actionRender(this.toggleModal)
        ) : (
          <Button
            type="link"
            onClick={(): void => this.toggleModal()}
            data-test-id="button"
          >
            {ActionIcon && <ActionIcon className="icon--primary" />}
            {actionText || 'Action'}
          </Button>
        )}
        <Modal
          visible={visible || showModal}
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
          width={730}
          destroyOnClose
          title={title}
          footer={footer}
          maskClosable={maskClosable}
          closable={closable}
          data-test-id="modal"
          className={className}
          afterClose={afterClose}
        >
          {children}
        </Modal>
      </>
    );
  }
}
export default ModalAction;
