/** @module components/ShareFileButton */
import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { getItemLink } from 'utilities/routes';
import { ReactComponent as ShareIcon } from 'assets/icons/share-icon.svg';
import { Props } from './types';

function ShareFileButton(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { item, copyTextToClipboard } = props;

  return (
    <Button
      type="link"
      onClick={(): void => copyTextToClipboard(getItemLink(item))}
      data-test-id="copyURLButton"
    >
      <ShareIcon className="icon--primary" />
      {t('ShareFileButton.copyURL', 'Copy URL')}
    </Button>
  );
}

export default ShareFileButton;
