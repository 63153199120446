/** @module components/MoveFilesModal */
import React, { useState } from 'react';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MoveIcon } from 'assets/icons/move.svg';
import ModalAction from '../ModalAction';
import ModalTitle from '../TitleWithIcon';
import FolderTree from '../FolderTree';
import { Props } from './types';
import './styles.scss';

function MoveFilesModal(props: Props): JSX.Element {
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  const {
    afterClose,
    files,
    repositoryId,
    loading,
    moveFiles,
  } = props;
  const { t } = useTranslation();

  function onFinish(): void {
    moveFiles(selectedFolderId);
  }

  if (!repositoryId) {
    return <div data-test-id="empty-div" />;
  }
  return (
    <ModalAction
      title={(
        <ModalTitle iconSVG={MoveIcon}>
          {t(
            'MoveFilesModal.title',
            'MOVE {{ count }} ITEMS',
            { count: files.length },
          )}
        </ModalTitle>
      )}
      afterClose={afterClose}
      footer={null}
      actionRender={(): JSX.Element => (
        <Button
          type="link"
          onClick={(): void => setVisible(true)}
          className="button"
          data-test-id="open-button"
        >
          <span className="text">
            {t('MoveFilesModal.button.action', 'MOVE')}
          </span>
        </Button>
      )}
      cancelHandler={(): void => setVisible(false)}
      visible={visible}
      data-test-id="move-files-modal"
    >
      <Form onFinish={onFinish} data-test-id="form" className="MoveFilesModal">
        <Form.Item>
          <div className="destination-description">
            {t(
              'MoveFilesModal.question',
              'Where would you like to move these {{ count }} items?',
              { count: files.length },
            )}
          </div>
          <Form.Item
            name="destination"
            rules={[{
              validator: (): Promise<void> => {
                if (selectedFolderId) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('MoveFilesModal.destination.required', 'Please select a destination')));
              },
            }]}
            validateTrigger={['onSelect']}
          >
            <FolderTree
              repositoryId={repositoryId}
              files={files}
              onSelect={(folderId): void => setSelectedFolderId(folderId)}
              data-test-id="folder-tree"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item className="ant-form-item--last-item ant-form-item--form-buttons">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            data-test-id="submit-button"
          >
            {t(
              'MoveFilesModal.button.submit',
              'Move {{ count }} Items',
              { count: files.length },
            )}
          </Button>
        </Form.Item>
      </Form>
    </ModalAction>
  );
}

export default MoveFilesModal;
