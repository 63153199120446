/** @module store/search */
import { Action } from 'redux';
import { SearchResponse } from 'services/search';

export enum SearchActionType {
  SEARCH_GET_REQUEST = 'SEARCH_GET_REQUEST',
  SEARCH_GET_SUCCESS = 'SEARCH_GET_SUCCESS',
  SEARCH_GET_ERROR = 'SEARCH_GET_ERROR',
  SEARCH_TRACK_CLICK_REQUEST = 'SEARCH_TRACK_CLICK_REQUEST',
  SEARCH_TRACK_CLICK_SUCCESS = 'SEARCH_TRACK_CLICK_SUCCESS',
  SEARCH_TRACK_CLICK_ERROR = 'SEARCH_TRACK_CLICK_ERROR',
}

export type SearchGetRequestAction = Action<SearchActionType.SEARCH_GET_REQUEST>;

export interface SearchGetSuccessAction extends Action<
SearchActionType.SEARCH_GET_SUCCESS
> {
  payload: {
    searchResponse: SearchResponse;
  };
}

export interface SearchGetErrorAction extends Action<
SearchActionType.SEARCH_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type SearchTrackClickRequestAction = Action<SearchActionType.SEARCH_TRACK_CLICK_REQUEST>;

export type SearchTrackClickSuccessAction = Action<SearchActionType.SEARCH_TRACK_CLICK_SUCCESS>;

export interface SearchTrackClickErrorAction extends Action<
SearchActionType.SEARCH_TRACK_CLICK_ERROR
> {
  payload: {
    error: Error;
  };
}

export type State = SearchResponse | null;

export type SearchAction = SearchGetRequestAction
                         | SearchGetSuccessAction
                         | SearchGetErrorAction
                         | SearchTrackClickRequestAction
                         | SearchTrackClickSuccessAction
                         | SearchTrackClickErrorAction;
