/** @module utilities/routes */
import { AppRoutes } from './types';

/**
 *
 */
function getAppRoutes(basePath: string | undefined, repoId: string | undefined): AppRoutes {
  return {
    myRepositories: '/my-repositories',
    sharedRepositories: '/shared-repositories',
    repositories: '/repositories',
    releaseNotes: '/release-notes',
    searchResults: '/search',
    goToRepository: (repositoryId: string): string => {
      if (!basePath) {
        return '/';
      }
      return `/${basePath}/${repositoryId}`;
    },
    goToFolder: (folderId: string): string => {
      if (!basePath || !repoId) {
        return '/';
      }
      if (folderId === repoId) {
        return `/${basePath}/${folderId}`;
      }
      return `/${basePath}/${repoId}/folder/${folderId}`;
    },
  };
}

export default getAppRoutes;
