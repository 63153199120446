/** @module components/UploadProgress */
/** @module components/TagsModal */
import { connect } from 'react-redux';
import { cancelFileUpload, cancelFolderUpload, clearUploads } from 'store/uploads/actions';
import { selectFileUploads, selectFolderUploads } from 'store/uploads/selectors';
import { AppDispatch, GlobalState } from 'store/types';
import UploadProgress from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  fileUploads: selectFileUploads(state.uploads),
  folderUploads: selectFolderUploads(state.uploads),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  cancelFileUpload: (id: string): void => { dispatch(cancelFileUpload(id)); },
  clearUploads: (): void => { dispatch(clearUploads()); },
  cancelFolderUpload: (id: string): void => { dispatch(cancelFolderUpload(id)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadProgress);
