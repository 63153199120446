/** @module services/invitations */
import { API } from './types/API';

/**
 * A client that communicates with the REDShare invitations service
 * using the fetch function.
 */
class FetchClient implements API {
  private prefix: string;

  /**
   * Constructs a new client with the given API level.
   *
   * @param apiLevel The API level this client uses
   */
  constructor(apiLevel = '1.0') {
    this.prefix = `/ext-invitation/${apiLevel}`;
  }

  createInvitation(
    email: string,
    givenName: string,
    familyName: string,
    countryCode: string,
  ): Promise<Response> {
    const uri = `${this.prefix}/invitations`;
    const body = JSON.stringify({
      email,
      name: {
        given: givenName,
        family: familyName,
      },
      /* eslint-disable @typescript-eslint/camelcase */
      country_code: countryCode,
    });
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options);
  }

  /**
   * Gets an array of whitelisted domains. The json response
   * returned contains a domains key which holds the array.
   * This function strips the domains from the json and returns
   * just the domains.
   */
  getPartners(): Promise<string[]> {
    const uri = `${this.prefix}/partners`;
    return fetch(uri)
      .then((response: Response) => response.json())
      .then((json) => json.domains);
  }
}

export default FetchClient;
