/** @module components/UserMenu */
import { connect } from 'react-redux';
import { selectFeature } from 'store/features/selectors';
import { Feature } from 'store/features/types';
import { AppDispatch, GlobalState } from 'store/types';
import { blockSelectedUser, unblockSelectedUser } from 'store/users/actions';
import UserMenu from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  me: state.me,
  users: state.users.searchedUsers,
  deleteUserEnabled: selectFeature(state.features, Feature.DELETE_USER),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  blockUser: (userId: string): void => { dispatch(blockSelectedUser(userId)); },
  unblockUser: (userId: string): void => { dispatch(unblockSelectedUser(userId)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserMenu);
