/** @module store/announcements */
import MetadataService, {
  Announcement,
} from 'services/metadata';
import { AppDispatch } from 'store/types';
import {
  AnnouncementsActionType,
  AnnouncementsGetRequestAction,
  AnnouncementsGetSuccessAction,
  AnnouncementsGetErrorAction,
  SetBannerClosedAction,
} from './types';

export const getAnnouncementsRequest = (): AnnouncementsGetRequestAction => ({
  type: AnnouncementsActionType.ANNOUNCEMENTS_GET_REQUEST,
});

export const getAnnouncementsSuccess = (
  announcements: Announcement[],
): AnnouncementsGetSuccessAction => ({
  type: AnnouncementsActionType.ANNOUNCEMENTS_GET_SUCCESS,
  payload: {
    announcements,
  },
});

export const getAnnouncementsError = (error: Error): AnnouncementsGetErrorAction => ({
  type: AnnouncementsActionType.ANNOUNCEMENTS_GET_ERROR,
  payload: {
    error,
  },
});

export function getAnnouncements() {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getAnnouncementsRequest());
    try {
      const announcements = await new MetadataService().getAnnouncements();
      dispatch(getAnnouncementsSuccess(announcements));
    } catch (error) {
      dispatch(getAnnouncementsError(error));
    }
  };
}

export const setBannerClosed = (bannerId: string): SetBannerClosedAction => ({
  type: AnnouncementsActionType.ANNOUNCEMENTS_SET_BANNER_CLOSED,
  payload: {
    bannerId,
  },
});
