/** @module components/Notifier */
import { connect } from 'react-redux';
import { GlobalState, AppDispatch } from 'store/types';
import { removeAllNotifications } from 'store/notifications/actions';
import component from './component';
import { DispatchProps, StateProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  notifications: state.notifications,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  removeAllNotifications: (): void => {
    dispatch(removeAllNotifications());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(component);
