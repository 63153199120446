/** @module components/UploadProgress */
import React, { useState } from 'react';
import {
  List,
  Progress,
  Row,
  Col,
  Button,
  Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadStatus } from 'services/storage';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-lg.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { FolderRowProps } from './types';

/**
 * Renders a folder row in the upload progress list.
 * @param props The props
 * @return A JSX Element
 */
function FolderRow(props: FolderRowProps): JSX.Element {
  const {
    folder,
    style,
    cancelFolderUpload,
    failedFiles,
  } = props;
  const { t } = useTranslation();
  const [hovering, setHovering] = useState(false);
  const cancelled = folder.status === UploadStatus.CANCELLED;
  const completed = folder.progress === 1 || folder.status === UploadStatus.COMPLETED;
  return (
    <List.Item
      key={folder.id}
      style={style}
      onMouseOverCapture={(): void => setHovering(true)}
      onMouseLeave={(): void => setHovering(false)}
      data-test-id="folder-row"
    >
      <Row
        justify="space-between"
        align="middle"
        className="row"
        gutter={16}
      >
        <Col
          span={22}
          className="column--name"
        >
          <FolderIcon
            width={10}
            height={10}
            className="icon folder-icon"
            data-test-id="folder-icon"
          />
          <Tooltip overlay={folder.name} className="text">
            {folder.name}
          </Tooltip>
        </Col>
        <Col
          span={2}
          className="column--close"
        >
          <Progress
            type="circle"
            percent={Math.trunc(folder.progress * 100)}
            status={(
              folder.status === UploadStatus.CANCELLED
              || folder.status === UploadStatus.ERROR
            ) ? 'exception' : undefined}
            width={32}
            showInfo={!hovering || cancelled || completed}
            strokeWidth={16}
            strokeLinecap="square"
            className="circular-progress"
            data-test-id="progress-bar"
          />
          {!completed && !cancelled && hovering && (
            <Button
              type="link"
              onClick={(): void => cancelFolderUpload(folder.id)}
              className="cancel-button"
              data-test-id="cancel-button"
            >
              <CloseIcon
                width={24}
                height={24}
                className="icon"
              />
            </Button>
          )}
        </Col>
        {failedFiles > 0 && (
          <Col
            span={22}
            className="error-count"
            data-test-id="error-count"
          >
            {t('UploadProgress.errorCount',
              'Failed to upload {{errorCount}} out of {{totalCount}} files',
              { errorCount: failedFiles, totalCount: folder.files.length })}
          </Col>
        )}
      </Row>
    </List.Item>
  );
}

export default FolderRow;
