/** @module store/users */
import { AnyAction } from 'redux';
import { User } from 'services/metadata';
import {
  UsersActionType,
  UsersState,
} from './types';

export const DEFAULT_STATE: UsersState = {
  searchedUsers: [],
};

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
export default function reducer(
  state: UsersState = DEFAULT_STATE,
  action: AnyAction,
): UsersState {
  let user: User | undefined;
  switch (action.type) {
    case UsersActionType.USERS_GET_SUCCESS:
      return {
        ...state,
        searchedUsers: action.payload.users,
      };
    case UsersActionType.USERS_SELECTED_BLOCK_SUCCESS:
      user = state.selectedUser;
      if (user) {
        user.locked = true;
        return {
          // if the searched user is same as selected user, updated the searchedUser
          searchedUsers: state.searchedUsers[0].id === user.id ? [user] : state.searchedUsers,
          selectedUser: user,
        };
      }
      return state;

    case UsersActionType.USERS_SELECTED_UNBLOCK_SUCCESS:
      user = state.selectedUser;
      if (user) {
        user.locked = false;
        return {
          // if the searched user is same as selected user, updated the searchedUser
          searchedUsers: state.searchedUsers[0].id === user.id ? [user] : state.searchedUsers,
          selectedUser: user,
        };
      }
      return state;

    case UsersActionType.USERS_SET_SELECTED:
      return {
        ...state,
        selectedUser: action.payload.user,
      };

    case UsersActionType.USERS_SELECTED_DELETE_SUCCESS:
      return {
        ...state,
        deletedUserId: action.payload.userId,
      };

    case UsersActionType.USERS_DELETED_CLEAR_STORE_REQUEST:
      return {
        ...state,
        deletedUserId: undefined,
      };

    default:
      return state;
  }
}
