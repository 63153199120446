/** @module store/repositories */
import { Repository } from 'services/metadata';
import { GlobalState } from 'store/types';
import { EvictionStatus } from 'services/metadata/types/EvictionStatus';
import { State } from './types';

/**
 * Selects a repository from the given state with the given ID.
 *
 * @param state The repositories state
 * @param repositoryId A repository ID
 * @return A repository or undefined
 */
function selectRepository(
  state: State,
  repositoryId: string,
): Repository | undefined {
  return state.entries.find((r: Repository) => r.id === repositoryId);
}

/**
 * Selects the repositories owned by the current user.
 * @param state The global state
 * @return An array of repositories
 */
function selectMyRepositories(state: GlobalState): Repository[] {
  const id = state.me && state.me.user && state.me.user.id;
  if (id) {
    return state.repositories.entries.filter((e) => e.owner.id === id);
  }
  return [];
}

/**
 * Selects the repositories not owned by the current user.
 * @param state The global state
 * @return An array of repositories
 */
function selectSharedRepositories(state: GlobalState): Repository[] {
  const id = state.me && state.me.user && state.me.user.id;
  if (id) {
    return state.repositories.entries.filter((e) => e.owner.id !== id);
  }
  return [];
}

/**
 * Selects the trash repositories owned by the current user.
 * @param state The global state
 * @return An array of repositories
 */
function selectMyRepositoriesTrash(state: GlobalState): Repository[] {
  const id = state.me && state.me.user && state.me.user.id;
  if (id) {
    return state.repositories.trash.filter((e) => e.owner.id === id);
  }
  return [];
}

/**
 * Selects the trash repositories not owned by the current user.
 * @param state The global state
 * @return An array of repositories
 */
function selectSharedRepositoriesTrash(state: GlobalState): Repository[] {
  const id = state.me && state.me.user && state.me.user.id;
  if (id) {
    return state.repositories.trash.filter((e) => e.owner.id !== id);
  }
  return [];
}

/**
 * Selects if a repository is accessible based on the corresponding folder permissions.
 * Note that this selector operates off of the current state. Calling components must
 * fetch the repositories and folder themselves.
 * @param state The global state
 * @param repositoryId The repository id
 * @return True if the user has view permissions on this repository folder
 */
function selectIsRepositoryAccessible(state: GlobalState, repositoryId: string): boolean {
  const repository = state.repositories.entries.find((r) => r.id === repositoryId);
  const folder = state.items.byId[repositoryId];
  if (repository && folder && folder.actions.view) {
    return true;
  }
  return false;
}

/**
 * Selects the eviction statuses of repositories from the global state.
 *
 * @param state The global state
 * @return An object mapping repository IDs to their respective eviction statuses
 */
function selectRepositoriesEvictionStatuses(state: GlobalState): Record<string, EvictionStatus> {
  if (!state.repositories || !state.repositories.evictions.statuses) {
    return {};
  }
  return state.repositories.evictions.statuses;
}

/**
 * Selects the last evicted repository details from the global state.
 *
 * @param state The global state
 * @return An object containing the ID and date of the last evicted repository
 */
function selectLastEvictedRepository(state: GlobalState):
{ lastEvictedRepositoryId?: string; lastEvictionDate?: Date } {
  if (!state.repositories || !state.repositories.evictions) {
    return {};
  }
  return {
    lastEvictedRepositoryId: state.repositories.evictions.lastEvictedRepositoryId,
    lastEvictionDate: state.repositories.evictions.lastEvictionDate,
  };
}

export {
  selectRepository,
  selectMyRepositories,
  selectMyRepositoriesTrash,
  selectSharedRepositories,
  selectSharedRepositoriesTrash,
  selectIsRepositoryAccessible,
  selectRepositoriesEvictionStatuses,
  selectLastEvictedRepository,
};
