/** @module components/SearchResultsPage */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { search, trackClick } from 'store/search/actions';
import { selectLoading } from 'store/loading/selectors';
import { getRepositories } from 'store/repositories/actions';
import { DispatchProps, StateProps } from './types';
import SearchResultsPage from './component';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  me: state.me,
  loading: selectLoading(state.loading, 'SEARCH_GET')
    || selectLoading(state.loading, 'REPOSITORIES_GET'),
  searchResult: state.search ? state.search.results : [],
  meta: state.search && state.search.meta,
  repositoriesExist: state.repositories.lastUpdated !== undefined,
  repositories: state.repositories.entries,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getSearchResult: (
    query: string,
    pageNumber: number,
    pageSize: number,
    userId: string,
    repoIds: string[],
    isSearchAcrossRepos?: boolean,
    includeFileContent?: boolean,
  ): void => {
    dispatch(search(
      query, pageNumber, pageSize, userId, repoIds, isSearchAcrossRepos, includeFileContent,
    ));
  },
  trackClick: (query, nodeId): void => { dispatch(trackClick(query, nodeId)); },
  getRepositories: (): void => { dispatch(getRepositories()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchResultsPage);
