/** @module components/NewRepositoryPage */
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddBox from '@material-ui/icons/AddBox';
import DashboardPage from 'components/DashboardPage';
import './styles.scss';
import NewRepositoryForm from 'components/NewRepositoryForm';

function NewRepositoryPage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="NewRepositoryPage">
      <DashboardPage
        title={(
          <div className="title-container">
            <AddBox className="icon--primary icon" />
            { t('NewRepositoryPage.title', 'New Repository') }
          </div>
      )}
        hasBreadcrumbs={false}
      >
        <NewRepositoryForm />
      </DashboardPage>
    </div>
  );
}

export default NewRepositoryPage;
