/** @module components/TestimonyCard */
import { UserOutlined } from '@ant-design/icons';
import {
  Row, Col, Card, Avatar, Carousel,
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

function TestimonyCard(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="TestimonyCard">
      <Row justify="center">
        <Col className="testimonyCardContainer" span={18}>
          <Card className="testimonyCard">
            <Avatar
              className="avatar"
              size={64}
              icon={<UserOutlined />}
            />
            <Carousel
              autoplay
              autoplaySpeed={10000}
              dots={false}
            >
              <div>
                <h2 className="testimony">{t('TestimonyCard.testimony1', '"It\'s made our job easier"')}</h2>
                <p className="testimonyBody">{t('TestimonyCard.testimonyBody1', 'working with documents that have design specs + test data (i.e. sharing documents with the test company ("Standard Aero"))')}</p>
                <p className="testimonyName">{t('TestimonyCard.testimonyName1', 'Senior Staff Engineer - Performance Engineering')}</p>
              </div>

              <div>
                <h2 className="testimony">{t('TestimonyCard.testimony2', '"Easy to set up... good distribution and sharing"')}</h2>
                <p className="testimonyBody">{t('TestimonyCard.testimonyBody2', 'supports military aircraft (Chile, Singapore); manage services support contract or maintenance and repair')}</p>
                <p className="testimonyName">{t('TestimonyCard.testimonyName2', 'Senior Contract Performance Manager')}</p>
              </div>

              <div>
                <h2 className="testimony">{t('TestimonyCard.testimony3', '"Surprised at how quickly new folder can be set up"')}</h2>
                <p className="testimonyBody">{t('TestimonyCard.testimonyBody3', 'Handle lots of data... LR, US-citizen only, and/or classified')}</p>
                <p className="testimonyName">{t('TestimonyCard.testimonyName3', 'Engineering Operations Management')}</p>
              </div>
            </Carousel>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TestimonyCard;
