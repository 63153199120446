/** @module components/TitleWithIcon */
import React from 'react';
import { Props } from './types';
import './styles.scss';

function TitleWithIcon(props: Props): JSX.Element {
  const {
    children,
    iconSVG,
  } = props;
  const Icon = iconSVG;
  return (
    <div className="TitleWithIcon">
      <div className="icon-container">
        {Icon && <Icon className="title-icon icon--primary" />}
      </div>
      <div className="title-text">{children}</div>
    </div>
  );
}

export default TitleWithIcon;
