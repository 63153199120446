import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import ECDataQuestionField from './fields/ECDataQuestionField';
import CDIQuestionField from './fields/CDIQuestionField';
import ITARQuestionField from './fields/ITARQuestionField';
import ECLicenseQuestionField from './fields/ECLicenseQuestionField';
import { Props } from './types';

/**
 * Asks the user a series of questions to determine what kind of repository
 * should be created for GE Gas Power.
 * @param props The props
 * @return A JSX Element
 */
function GasPowerRepositoryWizard(props: Props): JSX.Element {
  const { onChange } = props;
  const { t } = useTranslation();
  const [isEc, setIsEc] = useState<boolean | undefined>(undefined);
  const [isDoc, setIsDoc] = useState<boolean | undefined>(undefined);
  const [isLicenseAvailable, setIsLicenseAvailable] = useState<boolean | undefined>(undefined);
  const [isCdi, setIsCdi] = useState<boolean | undefined>(undefined);
  const [isUsOnly, setIsUsOnly] = useState<boolean | undefined>(undefined);

  /**
   * Updates the parent when a field is updated.
   */
  useEffect(() => {
    /**
     * Returns true if the wizard is complete.
     * @return true if the wizard is complete
     */
    const isComplete = (): boolean => {
      // Tags are optional
      // Yes no
      if (isEc && isDoc) {
        return true;
      }
      // Yes yes yes yes, yes yes yes no, yes yes no yes, yes yes no no
      if (isEc && !isDoc && isCdi !== undefined && isLicenseAvailable !== undefined) {
        return true;
      }
      return false;
    };

    onChange({
      fedRamp: 0,
      isCdi: isCdi || false,
      isEc: isEc || false,
      isLicenseAvailable: isLicenseAvailable || false,
      isUsOnly: isUsOnly || false,
      isUkLicense: false,
      isUsCitizen: false,
      isDoc: isDoc || false,
    }, isComplete());
  }, [isEc, isDoc, isLicenseAvailable, isCdi, isUsOnly, onChange]);

  return (
    <>
      <ECDataQuestionField
        value={isEc}
        onChange={(value): void => {
          setIsEc(value);
          setIsDoc(undefined);
          setIsCdi(undefined);
          setIsUsOnly(undefined);
          setIsLicenseAvailable(undefined);
        }}
        data-test-id="ec-data-question"
      />
      {isEc === false && (
        <>
          <Divider />
          <b data-test-id="ec-data-only">
            {t(
              'RepositoryWizard.GasPowerRepositoryWizard.ecContentOnly',
              'REDshare is intended for use by Power Portfolio businesses with export control content. For all other content, use Box.',
            )}
          </b>
        </>
      )}
      {isEc && (
        <>
          <Divider />
          <ITARQuestionField
            value={(isDoc === undefined) ? undefined : !isDoc}
            onChange={(value): void => {
              setIsDoc(!value);
              setIsCdi(undefined);
              if (value === false) {
                setIsUsOnly(false);
              } else {
                setIsUsOnly(undefined);
              }
              setIsLicenseAvailable(undefined);
            }}
            data-test-id="itar-question"
          />
        </>
      )}
      {isEc && isDoc === false && (
        <>
          <Divider />
          <CDIQuestionField
            value={isCdi}
            onChange={(value): void => {
              setIsCdi(value);
              setIsUsOnly(undefined);
              setIsLicenseAvailable(undefined);
            }}
            data-test-id="cdi-question"
          />
        </>
      )}
      {isEc && !isDoc && isCdi !== undefined && (
        <>
          <Divider />
          <ECLicenseQuestionField
            value={isLicenseAvailable}
            onChange={(value): void => {
              setIsLicenseAvailable(value);
              setIsUsOnly(!value);
            }}
            data-test-id="ec-license-question"
          />
        </>
      )}
    </>
  );
}

export default GasPowerRepositoryWizard;
