/** @module utilities/file */
export const MAX_LENGTH = 20;

/**
 * Truncates the given string to the given length and adds an
 * ellipsis to the end.
 * @param str A string
 * @param length An integer
 * @return A truncated string of the given length, plus ellipsis
 */
const truncateFileName = (str: string, length = MAX_LENGTH): string => {
  if (str.length > length) {
    const truncatedString = str.slice(0, length);
    return `${truncatedString}...`;
  }
  return str;
};

export default truncateFileName;
