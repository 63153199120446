/** @module components/ManageAdminRolesForm */
import { connect } from 'react-redux';
import { Role } from 'services/metadata/types/Role';
import { getBusinesses } from 'store/businesses/actions';
import { selectLoading } from 'store/loading/selectors';
import {
  createRole, getAllRoles, getRole, getUserRoles, updateRole,
} from 'store/roles/actions';
import { selectAllRoles, selectUserRoles } from 'store/roles/selectors';
import { AppDispatch, GlobalState } from 'store/types';
import { BusinessWizardId } from 'services/metadata';
import ManageAdminRolesForm from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'ALL_ROLES_GET')
  || selectLoading(state.loading, 'ROLE_UPDATE')
  || selectLoading(state.loading, 'ROLE_GET')
  || selectLoading(state.loading, 'ROLE_CREATE'),
  roles: selectAllRoles(state.roles),
  userRoles: selectUserRoles(state.roles, state.me.user ? state.me.user.id : ''),
  businesses: state.businesses
    ? state.businesses.filter((business) => business.wizardId !== BusinessWizardId.POWER_PORTFOLIO)
    : null,
  me: state.me,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getAllRoles: (): void => { dispatch(getAllRoles()); },
  getRole: (roleId: string): void => { dispatch(getRole(roleId)); },
  getUserRoles: (userId: string): void => { dispatch(getUserRoles(userId)); },
  createRole: (
    name: string,
    description: string,
    systemDefined: boolean,
    restricted: boolean,
    active: boolean,
    businessId: string,
    permissions: string[],
  ): void => {
    dispatch(
      createRole(name, description, systemDefined, restricted, active, businessId, permissions),
    );
  },
  getBusiness: (): void => { dispatch(getBusinesses()); },
  updateRole: (role: Role): void => { dispatch(updateRole(role)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdminRolesForm);
