/** @module components/ErrorSplashPage */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { appPaths } from 'utilities/routes';
import { supportDL } from 'utilities/constants';
import SplashPage from '../SplashPage';
import { ErrorType, Props } from './types';

/**
 * Displays error message
 * @param props The props
 * @return A JSX Element
 */
export default function ErrorSplashPage(props: Props): JSX.Element {
  const { errorType } = props;
  const { t } = useTranslation();
  let title: string;
  let message: string | JSX.Element;
  let referenceError: string;
  if (errorType === ErrorType.ALREADY_EXIST) {
    title = t('ErrorSplashPage.title.alreadyExist', 'Account already exists');
    message = (
      <Trans i18nKey="ErrorSplashPage.message.alreadyExist">
        This email address is already registered to an account on REDshare.
        Please click here to
        {' '}
        <a href={appPaths.logout} className="link">log out</a>
         , then re-log in with the correct user account.
      </Trans>
    );
    referenceError = t('ErrorSplashPage.referenceError.Conflict409', 'CONFLICT 409');
  } else {
    title = t('ErrorSplashPage.referenceError.blank', '');
    message = t('ErrorSplashPage.referenceError.blank', '');
    referenceError = t('ErrorSplashPage.referenceError.blank', '');
  }

  return (
    <SplashPage className="ErrorSplashPage">
      <h1 data-test-id="title">
        {title}
      </h1>
      <h4 data-test-id="message">
        {message}
      </h4>
      <h4>
        <Trans i18nKey="ErrorSplashPage.message.support1">
          If you need additional help, please contact
          {' '}
          <a href={`mailto:${supportDL}`} className="link">REDshare support</a>
          . Please reference error
          {' '}
        </Trans>
        { referenceError }
        <Trans i18nKey="ErrorSplashPage.message.support2">
          , and include the email address you are trying to register with.
        </Trans>
      </h4>
    </SplashPage>
  );
}
