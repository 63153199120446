/** @module components/SearchFilter */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Button } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import { Props } from './types';
import './styles.scss';

/**
 * A search filter popup that can be used to filter
 * a table.
 * @param props The component props
 * @return A JSX Element
 */
function SearchFilter(props: Props): JSX.Element {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const { onSearch, onReset } = props;
  return (
    <div className="SearchFilter">
      <Input.Search
        className="input"
        placeholder={t('SearchFilter.placeholder', 'Filter')}
        value={input}
        onChange={(e): void => setInput(e.target.value)}
        enterButton={<FilterFilled />}
        onSearch={(): void => onSearch(input)}
        data-test-id="input"
      />
      <Button
        type="link"
        className="reset"
        onClick={(): void => {
          setInput('');
          onReset();
        }}
        size="small"
        data-test-id="reset"
      >
        {t('SearchFilter.reset', 'Reset')}
      </Button>
    </div>
  );
}

export default SearchFilter;
