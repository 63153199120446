/** @module store/tags */
import { Action } from 'redux';
import { Tag } from 'services/metadata';

export type State = Tag[] | null;

export enum TagsActionType {
  TAGS_GET_REQUEST = 'TAGS_GET_REQUEST',
  TAGS_GET_SUCCESS = 'TAGS_GET_SUCCESS',
  TAGS_GET_ERROR = 'TAGS_GET_ERROR',
  TAGS_CREATE_REQUEST = 'TAGS_CREATE_REQUEST',
  TAGS_CREATE_SUCCESS = 'TAGS_CREATE_SUCCESS',
  TAGS_CREATE_ERROR = 'TAGS_CREATE_ERROR',
  TAGS_UPDATE_SUCCESS = 'TAGS_UPDATE_SUCCESS',
  TAGS_UPDATE_REQUEST = 'TAGS_UPDATE_REQUEST',
  TAGS_UPDATE_ERROR = 'TAGS_UPDATE_ERROR',
  REPOSITORY_TAG_MAPPING_CREATE_REQUEST = 'REPOSITORY_TAG_MAPPING_CREATE_REQUEST',
  REPOSITORY_TAG_MAPPING_CREATE_SUCCESS = 'REPOSITORY_TAG_MAPPING_CREATE_SUCCESS',
  REPOSITORY_TAG_MAPPING_CREATE_ERROR = 'REPOSITORY_TAG_MAPPING_CREATE_ERROR',
  REPOSITORY_TAGS_MAPPING_CREATE_REQUEST = 'REPOSITORY_TAGS_MAPPING_CREATE_REQUEST',
  REPOSITORY_TAGS_MAPPING_CREATE_SUCCESS = 'REPOSITORY_TAGS_MAPPING_CREATE_SUCCESS',
  REPOSITORY_TAGS_MAPPING_CREATE_ERROR = 'REPOSITORY_TAGS_MAPPING_CREATE_ERROR',
  REPOSITORY_TAG_MAPPING_DELETE_REQUEST = 'REPOSITORY_TAG_MAPPING_DELETE_REQUEST',
  REPOSITORY_TAG_MAPPING_DELETE_SUCCESS = 'REPOSITORY_TAG_MAPPING_DELETE_SUCCESS',
  REPOSITORY_TAG_MAPPING_DELETE_ERROR = 'REPOSITORY_TAG_MAPPING_DELETE_ERROR',
  REPOSITORY_TAGS_MAPPING_DELETE_REQUEST = 'REPOSITORY_TAGS_MAPPING_DELETE_REQUEST',
  REPOSITORY_TAGS_MAPPING_DELETE_SUCCESS = 'REPOSITORY_TAGS_MAPPING_DELETE_SUCCESS',
  REPOSITORY_TAGS_MAPPING_DELETE_ERROR = 'REPOSITORY_TAGS_MAPPING_DELETE_ERROR',
  MULTIPLE_TAGS_CREATE_REQUEST='MULTIPLE_TAGS_CREATE_REQUEST',
  MULTIPLE_TAGS_CREATE_ERROR='MULTIPLE_TAGS_CREATE_ERROR',
  MULTIPLE_TAGS_CREATE_SUCCESS='MULTIPLE_TAGS_CREATE_SUCCESS',
}

export interface TagsGetRequestAction extends Action<TagsActionType.TAGS_GET_REQUEST> {
  payload: {
    businessId?: string;
  };
}

export interface TagsGetSuccessAction extends Action<TagsActionType.TAGS_GET_SUCCESS> {
  payload: {
    businessId?: string;
    tags: Tag[];
  };
}

export interface TagsGetErrorAction extends Action<TagsActionType.TAGS_GET_ERROR> {
  payload: {
    error: Error;
  };
}

export interface TagsCreateRequestAction extends Action<TagsActionType.TAGS_CREATE_REQUEST> {
  payload: {
    businessId: string;
    name: string;
  };
}

export interface TagsCreateSuccessAction extends Action<TagsActionType.TAGS_CREATE_SUCCESS> {
  payload: {
    tag: Tag;
  };
}

export interface TagsCreateErrorAction extends Action<TagsActionType.TAGS_CREATE_ERROR> {
  payload: {
    error: Error;
  };
}

export interface TagsUpdateRequestAction extends Action<TagsActionType.TAGS_UPDATE_REQUEST> {
  payload: {
    id: string;
    name: string;
  };
}

export interface TagsUpdateSuccessAction extends Action<TagsActionType.TAGS_UPDATE_SUCCESS> {
  payload: {
    id: string;
    name: string;
  };
}

export interface TagsUpdateErrorAction extends Action<TagsActionType.TAGS_UPDATE_ERROR> {
  payload: {
    error: Error;
  };
}

export type RepositoryTagMappingCreateRequestAction = Action<
TagsActionType.REPOSITORY_TAG_MAPPING_CREATE_REQUEST
>;

export interface RepositoryTagMappingCreateErrorAction extends Action<
TagsActionType.REPOSITORY_TAG_MAPPING_CREATE_ERROR
> {
  payload: {
    error: Error;
  };
}

export interface RepositoryTagMappingCreateSuccessAction extends Action<
TagsActionType.REPOSITORY_TAG_MAPPING_CREATE_SUCCESS
> {
  payload: {
    tag: Tag;
    repoId: string;
  };
}

export type RepositoryTagsMappingCreateRequestAction = Action<
TagsActionType.REPOSITORY_TAGS_MAPPING_CREATE_REQUEST
>;
export interface RepositoryTagsMappingCreateErrorAction extends Action<
TagsActionType.REPOSITORY_TAGS_MAPPING_CREATE_ERROR
> {
  payload: {
    errors: Error[];
    tags: Tag[];
  };
}

export interface RepositoryTagsMappingCreateSuccessAction extends Action<
TagsActionType.REPOSITORY_TAGS_MAPPING_CREATE_SUCCESS
> {
  payload: {
    showNotification?: boolean;
  };
}

export type RepositoryTagMappingDeleteRequestAction = Action<
TagsActionType.REPOSITORY_TAG_MAPPING_DELETE_REQUEST
>;

export interface RepositoryTagMappingDeleteErrorAction extends Action<
TagsActionType.REPOSITORY_TAG_MAPPING_DELETE_ERROR
> {
  payload: {
    error: Error;
  };
}

export interface RepositoryTagMappingDeleteSuccessAction extends Action<
TagsActionType.REPOSITORY_TAG_MAPPING_DELETE_SUCCESS
> {
  payload: {
    tag: Tag;
    repoId: string;
  };
}

export type RepositoryTagsMappingDeleteRequestAction = Action<
TagsActionType.REPOSITORY_TAGS_MAPPING_DELETE_REQUEST
>;

export interface RepositoryTagsMappingDeleteErrorAction extends Action<
TagsActionType.REPOSITORY_TAGS_MAPPING_DELETE_ERROR
> {
  payload: {
    errors: Error[];
    tags: Tag[];
  };
}

// export type RepositoryTagsMappingDeleteSuccessAction = Action<
// TagsActionType.REPOSITORY_TAGS_MAPPING_DELETE_SUCCESS
// >
export interface RepositoryTagsMappingDeleteSuccessAction extends Action<
TagsActionType.REPOSITORY_TAGS_MAPPING_DELETE_SUCCESS
> {
  payload: {
    showNotification?: boolean;
  };
}


export type MultipleTagsCreateSuccess = Action<TagsActionType.MULTIPLE_TAGS_CREATE_SUCCESS>;
export interface MultipleTagsCreateError extends Action<
TagsActionType.MULTIPLE_TAGS_CREATE_ERROR
> {
  payload: {
    errors: Error[];
    tags: string[];
  };
}

export type MultipleTagsCreateRequest = Action<TagsActionType.MULTIPLE_TAGS_CREATE_REQUEST>;

export type TagsAction = TagsGetRequestAction
                       | TagsGetSuccessAction
                       | TagsGetErrorAction
                       | TagsCreateRequestAction
                       | TagsCreateSuccessAction
                       | TagsCreateErrorAction
                       | TagsUpdateRequestAction
                       | TagsUpdateSuccessAction
                       | TagsUpdateErrorAction
                       | RepositoryTagMappingCreateRequestAction
                       | RepositoryTagMappingCreateSuccessAction
                       | RepositoryTagMappingCreateErrorAction
                       | RepositoryTagsMappingCreateRequestAction
                       | RepositoryTagsMappingCreateSuccessAction
                       | RepositoryTagsMappingCreateErrorAction
                       | RepositoryTagMappingDeleteRequestAction
                       | RepositoryTagMappingDeleteSuccessAction
                       | RepositoryTagMappingDeleteErrorAction
                       | RepositoryTagsMappingDeleteRequestAction
                       | RepositoryTagsMappingDeleteSuccessAction
                       | RepositoryTagsMappingDeleteErrorAction;
