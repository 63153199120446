/** @module components/ItemsMenu */
import React from 'react';
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Popover,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { getShareLink } from 'utilities/email';
import { appPaths, getAppRoutes, getItemLink } from 'utilities/routes';
import { ReactComponent as RenameIcon } from 'assets/icons/rename.svg';
import { ReactComponent as PreviewIcon } from 'assets/icons/preview.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as MailIcon } from 'assets/icons/envelope.svg';
import FolderIcon from '@material-ui/icons/Folder';
import { history } from 'utilities/history';
import MoveFileModal from '../MoveFileModal';
import DownloadFileButton from '../DownloadFileButton';
import ShareFileButton from '../ShareFileButton';
import MoreButton from '../MoreButton';
import Loader from '../Loader';
import { Props } from './types';
import './styles.scss';

function ItemsMenu(props: Props): JSX.Element {
  const { t } = useTranslation();

  const menuItems = [];
  const {
    itemId,
    parentId,
    repository,
    item,
    me,
    onEditName,
    onPreview,
    deleteRepositoryItem,
    afterDelete,
    showTooltip = true,
    placement,
    iconSize = 'small',
    getRepositoryItems,
    loading,
    from,
  } = props;

  if (repository && item) {
    const canUpload = repository.actions.upload_files;
    const canDownloadFile = item.type.toLowerCase() === 'file' && repository.actions.download_files;
    const canDelete = repository.actions.delete_files;

    menuItems.push(
      <Menu.Item
        key="share"
        data-test-id="menuItem-share"
      >
        <ShareFileButton item={item} />
      </Menu.Item>,
    );
    if (from && from === appPaths.searchResults && item.type.toLowerCase() === 'file') {
      menuItems.push(
        <Menu.Item
          key="goto-folder"
          data-test-id="goto-folder"
        >
          <Button
            data-test-id="goto-folder-button"
            onClick={(): void => history.push(getAppRoutes('repositories', repository.id).goToFolder(parentId))}
            type="link"
          >
            <FolderIcon fontSize="small" className="icon--primary" />
            {t('ItemsMenu.menuItems.gotoFolder', 'Open Containing Folder')}
          </Button>
        </Menu.Item>,
      );
    }

    if (canUpload) {
      menuItems.push(
        <Menu.Item
          key="move"
          data-test-id="menuItem-move"
        >
          <MoveFileModal
            file={item}
            repositoryId={repository.id}
          />
        </Menu.Item>,
      );
      if (onEditName) {
        menuItems.push(
          <Menu.Item
            key="rename"
            data-test-id="menuItem-rename"
          >
            <Button
              type="link"
              data-test-id="button-rename"
              onClick={(): void => onEditName()}
            >
              <RenameIcon className="icon--primary" />
              {t('ItemsMenu.menuItems.Rename', 'Rename')}
            </Button>
          </Menu.Item>,
        );
      }
    }
    if (canDownloadFile) {
      menuItems.push(
        <Menu.Item
          key="download"
          className="downloadFileIcon"
          data-test-id="menuItem-download"
        >
          <DownloadFileButton item={item} />
        </Menu.Item>,
      );
      if (onPreview) {
        menuItems.push(
          <Menu.Item
            key="preview"
            data-test-id="menuItem-preview"
          >
            <Button
              type="link"
              data-test-id="button-preview"
              onClick={(): void => onPreview()}
            >
              <PreviewIcon className="icon--primary" />
              {t('ItemsMenu.menuItems.preview', 'Preview')}
            </Button>
          </Menu.Item>,
        );
      }

      menuItems.push(
        <Menu.Item
          key="email"
          data-test-id="menuItem-email"
        >
          <Button
            type="link"
            data-test-id="button-email"
            href={getShareLink(
              (me.user && me.user.name) || 'Someone',
              getItemLink(item),
            )}
          >
            <MailIcon className="icon--primary" />
            {t('ItemsMenu.menuItems.email', 'Email')}
          </Button>
        </Menu.Item>,
      );
    }
    if (canDelete) {
      menuItems.push(
        <Menu.Item
          key="delete"
          data-test-id="menuItem-delete"
        >
          <Button
            type="link"
            data-test-id="button-delete"
            onClick={(): void => {
              Modal.confirm({
                title: t(
                  'ItemsMenu.delete.confirm.title',
                  'Are you sure you want to delete "{{itemName}}"?',
                  { itemName: item.name },
                ),
                content: t('ItemsMenu.delete.confirm.content', 'Deleted items may be restored from the trash.'),
                onOk: () => deleteRepositoryItem(item, afterDelete),
                onCancel: () => { },
                cancelButtonProps: { className: 'ant-btn-ghost' },
                autoFocusButton: null,
              });
            }}
          >
            <DeleteIcon className="icon--primary" />
            {t('ItemsMenu.menuItems.delete', 'Delete')}
          </Button>
        </Menu.Item>,
      );
    }
  }
  return (
    <Dropdown
      overlay={(
        <Menu className="ItemsMenu">
          {loading ? (
            <Menu.Item className="loader" key="loading">
              <Loader loading data-test-id="loader" />
            </Menu.Item>
          ) : menuItems}
        </Menu>
      )}
      trigger={['click']}
      placement={placement || 'bottomRight'}
      data-test-id="dropdown-menuItems"
      onVisibleChange={(visible: boolean): void => {
        if (!item && visible && !loading) {
          getRepositoryItems(parentId || itemId);
        }
      }}
    >
      {showTooltip ? (
        <Popover content={t('ItemsMenu.tooltip', 'Manage item')} data-test-id="tooltip">
          <div>
            <MoreButton size="small" />
          </div>
        </Popover>
      ) : (
        <div>
          <MoreButton size={iconSize} />
        </div>
      )}
    </Dropdown>
  );
}

export default ItemsMenu;
