/** @module store/me */
import MetadataService, { Me, RegistrationState } from 'services/metadata';
import { wait } from 'utilities/asyncUtilities';
import { AppDispatch } from '../types';
import {
  GetMeRequestAction,
  GetMeSuccessAction,
  GetMeErrorAction,
  MeActionType,
  RegisterMeRequestAction,
  RegisterMeSuccessAction,
  RegisterMeErrorAction,
} from './types';

/**
 * Creates a get me request action.
 * @return A get me request action.
 */
export const getMeRequest = (): GetMeRequestAction => ({
  type: MeActionType.ME_GET_REQUEST,
});

/**
 * Creates a get me success action.
 * @param me The current user
 * @return A get me success action.
 */
export const getMeSuccess = (me: Me): GetMeSuccessAction => ({
  type: MeActionType.ME_GET_SUCCESS,
  payload: {
    me,
  },
});

/**
 * Creates a get me error action.
 * @param error An error
 * @return A get me error action.
 */
export const getMeError = (error: Error): GetMeErrorAction => ({
  type: MeActionType.ME_GET_ERROR,
  payload: {
    error: error.message,
  },
});

/**
 * Creates a register me request action.
 * @return A register me request action.
 */
export const registerMeRequest = (): RegisterMeRequestAction => ({
  type: MeActionType.ME_REGISTER_REQUEST,
});

/**
 * Creates a register me success action.
 * @return A register me success action.
 */
export const registerMeSuccess = (me?: Me): RegisterMeSuccessAction => ({
  type: MeActionType.ME_REGISTER_SUCCESS,
  payload: {
    me,
  },
});

/**
 * Creates a register me error action.
 * @param error An error
 * @return A register me error action.
 */
export const registerMeError = (error: Error): RegisterMeErrorAction => ({
  type: MeActionType.ME_REGISTER_ERROR,
  payload: {
    error: error.message,
  },
});

/**
 * A thunk that gets me.
 * @return A thunk action which returns a promise
 */
export function getMe() {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getMeRequest());
    try {
      const me = await new MetadataService().getMe();
      dispatch(getMeSuccess(me));
    } catch (error) {
      dispatch(getMeError(error));
    }
  };
}

/**
 * A thunk that registers me and then gets me.
 * @return A thunk action which returns a promise
 */
export function registerMe() {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(registerMeRequest());
    try {
      const result = await new MetadataService().registerMe();
      await wait(3000);
      dispatch(registerMeSuccess(result));
      if (result && result.registrationState !== RegistrationState.ALREADY_REGISTERED) {
        dispatch(getMe());
      }
    } catch (error) {
      dispatch(registerMeError(error));
      throw error;
    }
  };
}
