/** @module utilities/date */

/**
   * Returns the number of days from now until the given date.
   * @param date A date
   * @return The number of days from now until the given date.
   */
function getCountDown(date: Date): number {
  const expirationDate = date.getTime();
  const currentDate = Date.now();
  let daysRemaining = (expirationDate - currentDate) / (60 * 60 * 24 * 1000);
  if (daysRemaining < 0) {
    daysRemaining = 0;
  }
  return daysRemaining;
}

export default getCountDown;
