/** @module components/App */
import { connect } from 'react-redux';
import { getMe } from 'store/me/actions';
import { selectLoading } from 'store/loading/selectors';
import { GlobalState, AppDispatch } from 'store/types';
import App from './component';
import { StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  me: state.me,
  fetching: selectLoading(state.loading, 'ME_GET'),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getMe: (): void => { dispatch(getMe()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
