/** @module store/collaborators */
import { Action } from 'redux';
import { Collaborator, CollaboratorIdentity, Repository } from 'services/metadata';

export enum CollaboratorsActionType {
  COLLABORATORS_GET_REQUEST = 'COLLABORATORS_GET_REQUEST',
  COLLABORATORS_GET_SUCCESS = 'COLLABORATORS_GET_SUCCESS',
  COLLABORATORS_GET_ERROR = 'COLLABORATORS_GET_ERROR',
  COLLABORATORS_MULTI_GET_REQUEST = 'COLLABORATORS_MULTI_GET_REQUEST',
  COLLABORATORS_MULTI_GET_SUCCESS = 'COLLABORATORS_MULTI_GET_SUCCESS',
  COLLABORATORS_MULTI_GET_ERROR = 'COLLABORATORS_MULTI_GET_ERROR',
  COLLABORATORS_ADD_REQUEST = 'COLLABORATORS_ADD_REQUEST',
  COLLABORATORS_ADD_SUCCESS = 'COLLABORATORS_ADD_SUCCESS',
  COLLABORATORS_ADD_ERROR = 'COLLABORATORS_ADD_ERROR',
  COLLABORATORS_REMOVE_REQUEST = 'COLLABORATORS_REMOVE_REQUEST',
  COLLABORATORS_REMOVE_SUCCESS = 'COLLABORATORS_REMOVE_SUCCESS',
  COLLABORATORS_REMOVE_ERROR = 'COLLABORATORS_REMOVE_ERROR',
  COLLABORATORS_UPDATE_REQUEST = 'COLLABORATORS_UPDATE_REQUEST',
  COLLABORATORS_UPDATE_SUCCESS = 'COLLABORATORS_UPDATE_SUCCESS',
  COLLABORATORS_UPDATE_ERROR = 'COLLABORATORS_UPDATE_ERROR',
  COLLABORATORS_MULTI_REASSIGN_REQUEST = 'COLLABORATORS_MULTI_REASSIGN_REQUEST',
  COLLABORATORS_MULTI_REASSIGN_SUCCESS = 'COLLABORATORS_MULTI_REASSIGN_SUCCESS',
  COLLABORATORS_MULTI_REASSIGN_FAILURE = 'COLLABORATORS_MULTI_REASSIGN_FAILURE',
  COLLABORATORS_INVITE_REQUEST = 'COLLABORATORS_INVITE_REQUEST',
  COLLABORATORS_INVITE_SUCCESS = 'COLLABORATORS_INVITE_SUCCESS',
  COLLABORATORS_INVITE_ERROR = 'COLLABORATORS_INVITE_ERROR',
  COLLABORATORS_RENEW_REQUEST = 'COLLABORATORS_RENEW_REQUEST',
  COLLABORATORS_RENEW_SUCCESS = 'COLLABORATORS_RENEW_SUCCESS',
  COLLABORATORS_RENEW_ERROR = 'COLLABORATORS_RENEW_ERROR',
  COLLABORATORS_PREREGISTER_REQUEST = 'COLLABORATORS_PREREGISTER_REQUEST',
  COLLABORATORS_PREREGISTER_SUCCESS = 'COLLABORATORS_PREREGISTER_SUCCESS',
  COLLABORATORS_PREREGISTER_ERROR = 'COLLABORATORS_PREREGISTER_ERROR',
}

export type GetCollaboratorsRequestAction = Action<
CollaboratorsActionType.COLLABORATORS_GET_REQUEST
>;

export interface GetCollaboratorsSuccessAction extends Action<
  CollaboratorsActionType.COLLABORATORS_GET_SUCCESS
> {
  payload: {
    collaborators: Collaborator[];
    repositoryId: string;
  };
}

export interface GetCollaboratorsErrorAction extends Action<
  CollaboratorsActionType.COLLABORATORS_GET_ERROR
> {
  payload: {
    error: Error;
    showNotification?: boolean;
  };
}

export type GetMultiCollaboratorsRequestAction = Action<
CollaboratorsActionType.COLLABORATORS_MULTI_GET_REQUEST
>;

export type GetMultiCollaboratorsSuccessAction = Action<
CollaboratorsActionType.COLLABORATORS_MULTI_GET_SUCCESS
>;
export interface GetMultiCollaboratorsErrorAction extends Action<
CollaboratorsActionType.COLLABORATORS_MULTI_GET_ERROR
> {
  payload: {
    error: Error;
    showNotification?: boolean;
  };
}

export interface AddCollaboratorRequestAction extends Action<
CollaboratorsActionType.COLLABORATORS_ADD_REQUEST
> {
  payload: {
    id?: string;
  };
}

export interface AddCollaboratorSuccessAction extends Action<
  CollaboratorsActionType.COLLABORATORS_ADD_SUCCESS
> {
  payload: {
    repository?: Repository;
    id?: string;
    showNotification?: boolean;
    multiRequestId?: string;
  };
}

export interface AddCollaboratorErrorAction extends Action<
  CollaboratorsActionType.COLLABORATORS_ADD_ERROR
> {
  payload: {
    error: Error;
    id?: string;
    showNotification?: boolean;
  };
}

export type RemoveCollaboratorRequestAction = Action<
  CollaboratorsActionType.COLLABORATORS_REMOVE_REQUEST
>;

export interface RemoveCollaboratorSuccessAction extends Action<
  CollaboratorsActionType.COLLABORATORS_REMOVE_SUCCESS
> {
  payload: {
    repositoryId: string;
    collaboratorId: string;
    isSelf: boolean;
  };
}

export interface RemoveCollaboratorErrorAction extends Action<
  CollaboratorsActionType.COLLABORATORS_REMOVE_ERROR
> {
  payload: {
    error: Error;
  };
}

export interface UpdateCollaboratorRequestAction extends Action<
  CollaboratorsActionType.COLLABORATORS_UPDATE_REQUEST
> {
  payload: {
    id?: string;
  };
}
export interface UpdateCollaboratorSuccessAction extends Action<
  CollaboratorsActionType.COLLABORATORS_UPDATE_SUCCESS
> {
  payload: {
    repositoryId: string;
    collaborator: Collaborator;
    role: string;
    id?: string;
    showNotification?: boolean;
    multiRequestId?: string;
  };
}

export interface UpdateCollaboratorErrorAction extends Action<
  CollaboratorsActionType.COLLABORATORS_UPDATE_ERROR
> {
  payload: {
    error: Error;
    id?: string;
    showNotification?: boolean;
  };
}

export interface MultiReassignCollaboratorRequestAction extends Action<
CollaboratorsActionType.COLLABORATORS_MULTI_REASSIGN_REQUEST
>{
  payload: {
    multiRequestId: string;
  };
}

export interface MultiReassignCollaboratorSuccessAction extends Action<
CollaboratorsActionType.COLLABORATORS_MULTI_REASSIGN_SUCCESS>
{
  payload: {
    multiRequestId: string;
    newOwnerName: string;
    successCount: number;
    failuresCount: number;
  };
}

export interface MultiReassignCollaboratorFailureAction extends Action<
CollaboratorsActionType.COLLABORATORS_MULTI_REASSIGN_FAILURE
> {
  payload: {
    multiRequestId: string;
    error: Error;
  };
}

export type InviteCollaboratorRequestAction = Action<
  CollaboratorsActionType.COLLABORATORS_INVITE_REQUEST
>;

export type InviteCollaboratorSuccessAction = Action<
  CollaboratorsActionType.COLLABORATORS_INVITE_SUCCESS
>;

export interface InviteCollaboratorErrorAction extends Action<
  CollaboratorsActionType.COLLABORATORS_INVITE_ERROR
> {
  payload: {
    error: Error;
  };
}

export type RenewCollaboratorRequestAction = Action<
  CollaboratorsActionType.COLLABORATORS_RENEW_REQUEST
>;

export interface RenewCollaboratorSuccessAction extends Action<
  CollaboratorsActionType.COLLABORATORS_RENEW_SUCCESS
> {
  payload: {
    repositoryId: string;
    collaborator: Collaborator;
  };
}

export interface RenewCollaboratorErrorAction extends Action<
  CollaboratorsActionType.COLLABORATORS_RENEW_ERROR
> {
  payload: {
    error: Error;
  };
}

export type PreregisterCollaboratorRequestAction = Action<
  CollaboratorsActionType.COLLABORATORS_PREREGISTER_REQUEST
>;

export type PreregisterCollaboratorSuccessAction = Action<
  CollaboratorsActionType.COLLABORATORS_PREREGISTER_SUCCESS
>;

export interface PreregisterCollaboratorErrorAction extends Action<
  CollaboratorsActionType.COLLABORATORS_PREREGISTER_ERROR
> {
  payload: {
    error: Error;
  };
}
export enum RepoReassignmentType{
  UPDATE='update',
  ADD='add'
}

export type ReassignmentTask = {
  type: RepoReassignmentType.ADD | RepoReassignmentType.UPDATE;
  repoId: string;
  collaboratorIdentity?: CollaboratorIdentity; // For add
  collaborator?: Collaborator; // For update
  newRole?: string; // For update
};

export type CollaboratorsAction = GetCollaboratorsRequestAction
                                | GetCollaboratorsSuccessAction
                                | GetCollaboratorsErrorAction
                                | AddCollaboratorRequestAction
                                | AddCollaboratorSuccessAction
                                | AddCollaboratorErrorAction
                                | RemoveCollaboratorRequestAction
                                | RemoveCollaboratorSuccessAction
                                | RemoveCollaboratorErrorAction
                                | UpdateCollaboratorRequestAction
                                | UpdateCollaboratorSuccessAction
                                | UpdateCollaboratorErrorAction
                                | InviteCollaboratorRequestAction
                                | InviteCollaboratorSuccessAction
                                | InviteCollaboratorErrorAction
                                | RenewCollaboratorRequestAction
                                | RenewCollaboratorSuccessAction
                                | RenewCollaboratorErrorAction
                                | PreregisterCollaboratorRequestAction
                                | PreregisterCollaboratorSuccessAction
                                | PreregisterCollaboratorErrorAction;

export const MULTI_REASSIGNMENT_KEY = Symbol('multiReassignmentProgress');

export type CollaboratorsState = {
  [repositoryId: string]: Collaborator[];
} & {
  [MULTI_REASSIGNMENT_KEY]?: {
    [multiRequestId: string]: {
      successCount: number;
    };
  };
};
