/** @module components/NameField */
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import NameField from './component';
import dragOptions from './dragOptions';
import dropOptions from './dropOptions';
import { Props } from './types';

function WithDnd(props: Props): JSX.Element {
  const [, drag] = useDrag(dragOptions(props));
  const [, drop] = useDrop(dropOptions(props));
  const {
    itemDetails,
    link,
    icon,
    text,
    editing,
    loading,
    onClick,
    onCancel,
    onConfirm,
    updateFileParent,
    isTitle,
    canDragAndDrop = true,
  } = props;
  return (
    <div ref={canDragAndDrop ? drop : null}>
      <div ref={canDragAndDrop ? drag : null}>
        <NameField
          itemDetails={itemDetails}
          link={link}
          icon={icon}
          text={text}
          editing={editing}
          loading={loading}
          onClick={onClick}
          onCancel={onCancel}
          onConfirm={onConfirm}
          updateFileParent={updateFileParent}
          isTitle={isTitle}
        />
      </div>
    </div>
  );
}

export default WithDnd;
