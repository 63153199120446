/** @module components/ExistingCollaboratorsTable */
import { connect } from 'react-redux';
import { GlobalState, AppDispatch } from 'store/types';
import { Collaborator } from 'services/metadata';
import { selectLoading } from 'store/loading/selectors';
import {
  getCollaborators,
  updateCollaborator,
  removeCollaborator,
  renewCollaborator,
} from 'store/collaborators/actions';
import { selectCollaborators } from 'store/collaborators/selectors';
import { selectFeature } from 'store/features/selectors';
import { Feature } from 'store/features/types';
import ExistingCollaboratorsTable from './component';
import {
  StateProps,
  DispatchProps,
  OwnProps,
} from './types';

export const mapStateToProps = (state: GlobalState, props: OwnProps): StateProps => ({
  loading: selectLoading(state.loading, 'COLLABORATORS_GET')
    || selectLoading(state.loading, 'COLLABORATORS_UPDATE')
    || selectLoading(state.loading, 'COLLABORATORS_REMOVE')
    || selectLoading(state.loading, 'COLLABORATORS_RENEW'),
  refreshing: selectLoading(state.loading, 'COLLABORATORS_GET'),
  collaborators: selectCollaborators(state.collaborators, props.repository.id),
  renewECEnabled: selectFeature(state.features, Feature.RENEW_EXTERNAL_COLLABORATORS),
  me: state.me,
});

export const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps): DispatchProps => ({
  updateCollaborator: (
    repositoryId: string,
    collaborator: Collaborator,
    newRole: string,
    isSelf: boolean,
  ): void => { dispatch(updateCollaborator(repositoryId, collaborator, newRole, isSelf)); },
  getCollaborators: (): void => { dispatch(getCollaborators(props.repository.id)); },
  removeCollaborator: (
    repositoryId: string,
    collaboratorId: string,
    isSelf: boolean,
    callback?: () => void,
  ): void => { dispatch(removeCollaborator(repositoryId, collaboratorId, isSelf, callback)); },
  renewCollaborator: (
    repositoryId: string,
    collaborator: Collaborator,
  ): void => { dispatch(renewCollaborator(repositoryId, collaborator)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExistingCollaboratorsTable);
