/** @module components/ChooseUserTypeForm */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collaborator } from 'services/metadata';
import { Input, Radio, Select } from 'antd';
import UserSearchForm from 'components/UserSearchForm';
import { SizeType } from 'components/UserSearchForm/types';
import { UserType } from 'components/ReassignOwnerSection/types';
import { Props } from './types';
import './styles.scss';

function ChooseUserTypeForm(props: Props): JSX.Element {
  const [coOwnersList, setCoOwnersList] = useState<Collaborator[]>([]);
  const [viewersList, setViewersList] = useState<Collaborator[]>([]);
  const [editorsList, setEditorsList] = useState<Collaborator[]>([]);
  const [multipleReassigning, setMultipleReassigning] = useState(false);

  const {
    me,
    selectedUser,
    withSelf,
    repoIds,
    notReassignedRepoIds,
    setNotReassignedRepoIds,
    setSelectedOwner,
    getCollaboratorsMultipleRepos,
    collaborators,
    loading,
    getMultiCollaboratorLoading,
    searchedUsers,
    searchUsers,
    searchUserLoading,
    userType,
    setUserType,
    setReassignCardOpen,
    isUserSearched,
    setIsUserSearched,
  } = props;

  const { t } = useTranslation();
  const { Option, OptGroup } = Select;
  const prevRepoIds = useRef<string[]>([]);


  useEffect(() => {
    if (repoIds.length) {
    // get collaborators only for new repo ids
      const differenceInRepoIds = repoIds.filter((id) => !prevRepoIds.current.includes(id));
      if (differenceInRepoIds.length > 0) {
        if (isUserSearched && searchedUsers.length === 0) {
          setIsUserSearched(false);
        }
        getCollaboratorsMultipleRepos(differenceInRepoIds);
        prevRepoIds.current = repoIds;
      }
    }
  }, [repoIds, getCollaboratorsMultipleRepos]);

  /**  Computing the collaborators list, and finding out common collaborators
   * if more then one repo id is provided
  */
  useEffect(() => {
    let coOwners: Collaborator[] = [];
    let viewers: Collaborator[] = [];
    let editors: Collaborator[] = [];
    if (collaborators[repoIds[0]] && selectedUser) {
      const collaboratorsList = collaborators[repoIds[0]]
        .filter((collab) => collab.user.id !== selectedUser.id);
      collaboratorsList.forEach((collab) => {
        if (collab.role === 'COOWNER') {
          coOwners.push(collab);
        } else if (collab.role === 'VIEWER') {
          viewers.push(collab);
        } else if (collab.role === 'EDITOR') {
          editors.push(collab);
        }
      });
    }

    if (repoIds.length > 1) {
      // excluding index 1 because we already covered it in about condition
      for (let i = 1; i < repoIds.length; i += 1) {
        const repo = repoIds[i];
        const collaboratorsList = collaborators[repo];
        // getting the collaborators for each role for the repo in a temp variable
        // to intersect it with pervious repo's collaborators for each role
        const tempCoOwners: Collaborator[] = [];
        const tempViewers: Collaborator[] = [];
        const tempEditors: Collaborator[] = [];
        if (collaboratorsList) {
          collaboratorsList.forEach((collab) => {
            if (collab.role === 'COOWNER') {
              tempCoOwners.push(collab);
            } else if (collab.role === 'VIEWER') {
              tempViewers.push(collab);
            } else if (collab.role === 'EDITOR') {
              tempEditors.push(collab);
            }
          });
        }
        // finding the intersection
        const commonCoOwners = coOwners.filter(
          (collab) => tempCoOwners.some((tempCollab) => collab.user.id === tempCollab.user.id),
        );

        const commonViewers = viewers.filter(
          (collab) => tempViewers.some((tempCollab) => collab.user.id === tempCollab.user.id),
        );

        const commonEditors = editors.filter(
          (collab) => tempEditors.some((tempCollab) => collab.user.id === tempCollab.user.id),
        );

        coOwners = commonCoOwners;
        viewers = commonViewers;
        editors = commonEditors;

        // if there is no intersection in any of the roles for 2 repos,
        // we don't need to iterate any further as no more intersection is possible for other repos
        if (coOwners.length === 0 && viewers.length === 0 && editors.length === 0) {
          break;
        }
      }
    }
    setCoOwnersList(coOwners);
    setViewersList(viewers);
    setEditorsList(editors);
  }, [repoIds, collaborators]);

  useEffect(() => {
    if (notReassignedRepoIds.length > 0) {
      if (!multipleReassigning) {
        setMultipleReassigning(true);
        getCollaboratorsMultipleRepos(notReassignedRepoIds);
      } else if (!getMultiCollaboratorLoading) {
        setMultipleReassigning(false);
        setNotReassignedRepoIds([]);
      }
    }
  }, [notReassignedRepoIds, getMultiCollaboratorLoading]);

  const onSelectExistingCollab = (collabId: string): void => {
    const [id, type] = collabId.split('_');

    let collab: Collaborator | undefined;
    if (type === 'co-owner') {
      collab = coOwnersList.find((c) => c.id === id);
    }
    if (type === 'editor') {
      collab = editorsList.find((c) => c.id === id);
    }
    if (type === 'viewer') {
      collab = viewersList.find((c) => c.id === id);
    }
    setSelectedOwner(collab);
  };

  const onSearchUser = (searchQuery: string): void => {
    searchUsers(searchQuery);
    setIsUserSearched(true);
  };

  const onChangeUserType = (value: UserType): void => {
    setUserType(value);
    if (setReassignCardOpen) {
      setReassignCardOpen(true);
    }
    setIsUserSearched(false);
  };

  return (
    <div className="ChooseUserTypeForm">
      <div className="user-type-buttons">
        <div>
          {t('ChooseUserTypeForm.chooseUserType', 'Choose a User Type')}
        </div>
        <Radio.Group
          defaultValue={userType}
          buttonStyle="solid"
          size="small"
          onChange={(e): void => onChangeUserType(e.target.value)}
          data-test-id="choose-type"
        >
          <Radio.Button className="radio-button" value={UserType.COLLABORATOR}>{t('ChooseUserTypeForm.radioBtn.collab', 'Collaborator')}</Radio.Button>
          <Radio.Button className="radio-button" value={UserType.OTHER}>{t('ChooseUserTypeForm.radioBtn.other', 'Other')}</Radio.Button>
          {withSelf && (
            <Radio.Button className="radio-button" value={UserType.MYSELF}>{t('ChooseUserTypeForm.radioBtn.myself', 'Myself')}</Radio.Button>
          )}
        </Radio.Group>
      </div>
      {userType === UserType.COLLABORATOR && (
        <div className="collab-select-container" data-test-id="collabs-tab">
          <div className="collab-select-container" data-test-id="collabs-selector-label">
            {repoIds.length > 1 ? t('ChooseUserTypeForm.chooseCommonCollab', 'Choose a Common Collaborator') : t('ChooseUserTypeForm.chooseCollab', 'Choose a Collaborator')}
          </div>
          <Select
            style={{ width: 292 }}
            placeholder={t('ChooseUserTypeForm.selectOne', 'Select One')}
            onSelect={onSelectExistingCollab}
            data-test-id="select-collab"
          >
            {coOwnersList.length && (
              <OptGroup label="Co-Owner" key="co-owner">
                {coOwnersList.map((collab) => (
                  <Option value={`${collab.id}_co-owner`} key={`${collab.id}_co-owner`}>{collab.user.name}</Option>
                ))}
              </OptGroup>
            )}
            {editorsList.length && (
              <OptGroup label="Editor" key="editor">
                {editorsList.map((collab) => (
                  <Option value={`${collab.id}_editor`} key={`${collab.id}_editor`}>{collab.user.name}</Option>
                ))}
              </OptGroup>
            )}
            {viewersList.length && (
              <OptGroup label="Viewer" key="viewer">
                {viewersList.map((collab) => (
                  <Option value={`${collab.id}_viewer`} key={`${collab.id}_viewer`}>{collab.user.name}</Option>
                ))}
              </OptGroup>
            )}
          </Select>
          {repoIds.length > 1 && !loading && (
            <div className="italic-text" data-test-id="choose-common-collab-explanation">
              {t('ChooseUserTypeForm.chooseCommonCollabExplanation', 'Showing only the common collaborators across the selected repositories')}
            </div>
          )}
        </div>
      )}

      {userType === UserType.OTHER && (
        <div className="input" data-test-id="other-tab">
          <div className="instructions">
            {t('ChooseUserTypeForm.instructions.other', 'Please enter the complete email or SSO of the REDshare user you would like to choose as the new Owner of the repositories selected below.')}
          </div>
          <UserSearchForm
            data-test-id="user-search"
            loading={searchUserLoading}
            searchUsers={(query): void => onSearchUser(query)}
            size={SizeType.MEDIUM}
          />
        </div>
      )}

      {userType === UserType.MYSELF && (
        <div className="myself" data-test-id="myself-tab">
          <Input
            data-test-id="myself-input"
            disabled
            value={me.user ? me.user.name : ''}
          />
        </div>
      )}
    </div>
  );
}

export default ChooseUserTypeForm;
