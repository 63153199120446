/** @module store/tags */
import { Tag } from 'services/metadata';
import { State } from './types';

/**
 * Selects the tags from the given state with the given business id.
 * @param state The current state
 * @param businessId The business id
 * @return An array of tags, or undefined
 */
function selectTags(state: State, businessId?: string): Tag[] | undefined {
  if (state) {
    if (businessId) {
      return state.filter((tag) => tag.business.id === businessId);
    }
    return state;
  }
  return undefined;
}

export { selectTags };
