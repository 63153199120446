/** @module utilities/asyncUtilities/wait */

/**
 * Returns a promise that resolves after the given number of milliseconds.
 * @param duration The duration in milliseconds
 * @return A promise that resolves after the given number of milliseconds
 */
function wait(duration: number): Promise<void> {
  return new Promise((resolve): void => {
    setTimeout(() => resolve(), duration);
  });
}

export default wait;
