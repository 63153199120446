/** @module store/release-notes */
import ReleaseNotesService from 'services/release-notes';
import { AppDispatch } from 'store/types';
import {
  ReleaseNotesActionType,
  ReleaseNotesGetRequestAction,
  ReleaseNotesGetSuccessAction,
  ReleaseNotesGetErrorAction,
} from './types';

export const getReleaseNotesRequest = (): ReleaseNotesGetRequestAction => ({
  type: ReleaseNotesActionType.RELEASE_NOTES_GET_REQUEST,
});

export const getReleaseNotesSuccess = (releaseNotes: string): ReleaseNotesGetSuccessAction => ({
  type: ReleaseNotesActionType.RELEASE_NOTES_GET_SUCCESS,
  payload: {
    releaseNotes,
  },
});

export const getReleaseNotesError = (error: Error): ReleaseNotesGetErrorAction => ({
  type: ReleaseNotesActionType.RELEASE_NOTES_GET_ERROR,
  payload: {
    error,
  },
});

export const getReleaseNotes = () => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(getReleaseNotesRequest());
  try {
    const releaseNotes = await new ReleaseNotesService().getReleaseNotes();
    dispatch(getReleaseNotesSuccess(releaseNotes));
  } catch (error) {
    dispatch(getReleaseNotesError(error));
  }
};
