/** @module components/RepositoryMenu */
import React from 'react';
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Placement } from 'utilities/antd';
import { LicenseType } from 'services/metadata';
import { history } from 'utilities/history';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg';
import LicensesModal from '../LicensesModal';
import MoreButton from '../MoreButton';
import { Props } from './types';
import './styles.scss';

function RepositoryMenu(props: Props): JSX.Element | null {
  const menuItems = [];
  const {
    repository,
    deleteRepository,
    downloadZip,
    afterDelete,
    trashLink,
    iconSize,
    collabWarning,
    placement = Placement.BOTTOM_LEFT,
  } = props;
  const { t } = useTranslation();
  if (repository) {
    if (repository.actions.view_collaborators) {
      menuItems.push(
        <Menu.Item key="manage-collaborators">
          <Button
            type="link"
            onClick={(): void => history.push(`/manage-collaborators/${repository.id}`)}
            data-test-id="manage-collaborators-button"
          >
            {t('RepositoryMenu.mngCollab', 'Manage Collaborators')}
          </Button>
        </Menu.Item>,
      );
    }
    if (repository.actions.update_repository) {
      menuItems.push(
        <Menu.Item key="manage-repo">
          <Button
            type="link"
            onClick={(): void => history.push(`/manage-repository/${repository.id}`)}
            data-test-id="manage-repo-button"
          >
            {t('RepositoryMenu.mngRepo', 'Manage Repository')}
          </Button>
        </Menu.Item>,
      );
    }
    if (
      repository.actions.view_protections
      && repository.protections.includes(LicenseType.LR)
    ) {
      menuItems.push(
        <Menu.Item key="manage-licenses">
          <LicensesModal
            repository={repository}
            data-test-id="licenses-modal"
          />
        </Menu.Item>,
      );
    }
    if (repository.actions.update_repository) {
      menuItems.push(
        <Menu.Item key="delete-repository">
          <Button
            type="link"
            onClick={(): void => {
              Modal.confirm({
                title: t(
                  'RepositoryMenu.confirmDelete.title',
                  'Are you sure you want to delete "{{name}}"?',
                  { name: repository.name },
                ),
                content: t(
                  'RepositoryMenu.confirmDelete.content',
                  'Deleted repository may be restored from the trash.',
                ),
                onOk: (): void => deleteRepository(repository, afterDelete),
                onCancel: () => { },
                cancelButtonProps: { className: 'ant-btn-ghost' },
                autoFocusButton: null,
              });
            }}
            data-test-id="delete-repository-button"
          >
            {t('RepositoryMenu.delete', 'Delete Repository')}
          </Button>
        </Menu.Item>,
      );
    }
    if (repository.actions.download_files) {
      menuItems.push(
        <Menu.Item key="delete-repository">
          <Button
            type="link"
            onClick={(): void => {
              downloadZip(repository.id);
              Modal.confirm({
                title: t(
                  'RepositoryMenu.zipDownloadPopUp.title',
                  'Download Repository',
                ),
                content: t(
                  'RepositoryMenu.zipDownloadPopUp.content',
                  'Your repository download has been initiated. This action could take a few minutes depending on the size of the repository and your internet speed.',
                ),
                onOk: () => { },
                cancelButtonProps: { style: { display: 'none' } },
                autoFocusButton: 'ok',
              });
            }}
            data-test-id="zip-download-repo-button"
          >
            {t('RepositoryMenu.zipDownload', 'Download Repository')}
          </Button>
        </Menu.Item>,
      );
    }
    if (trashLink && repository.actions.delete_files) {
      menuItems.push(
        <Menu.Item key="view-trash">
          <Button type="link" data-test-id="view-trash-button">
            <Link to={trashLink}>
              {t('RepositoryMenu.viewTrash', 'View Trash')}
            </Link>
          </Button>
        </Menu.Item>,
      );
    }
  }


  return menuItems.length ? (
    <>
      <Dropdown
        overlay={<Menu>{menuItems}</Menu>}
        trigger={['click']}
        placement={placement}
        data-test-id="dropdown"
        className="RepositoryMenu"
      >
        <div>
          <MoreButton size={iconSize} />
        </div>
      </Dropdown>
      {collabWarning && (
        <Tooltip
          title={collabWarning}
          data-test-id="collab-warning"
        >
          <WarningIcon className="warningIcon" />
        </Tooltip>
      )}
    </>
  ) : null;
}

export default RepositoryMenu;
