/** @module utilities/file */

/**
 * Returns a human readable file size from the given number of bytes.
 * @param size The size of the file in bytes
 * @return A human readable file size
 */
function getHumanReadableFileSize(size: number): string {
  if (size === 0) {
    return '0 B';
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const convertedSize = Number.parseFloat((size / 1024 ** i).toFixed(2)) * 1;
  const measurement = ['B', 'KB', 'MB', 'GB', 'TB'][i];
  return `${convertedSize} ${measurement}`;
}

export default getHumanReadableFileSize;
