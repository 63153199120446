/** @module store/roles */
import { AnyAction } from 'redux';
import { RolesActionTypes, State } from './types';

export const DEFAULT_STATE: State = {
  roles: [],
  usersRoles: {},
};

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */

export default function reducer(state: State = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case RolesActionTypes.ALL_ROLES_GET_SUCCESS: {
      const { roles } = action.payload;
      return {
        ...state,
        roles,
      };
    }
    case RolesActionTypes.ROLE_CREATE_SUCCESS: {
      const { role } = action.payload;
      return {
        ...state,
        roles: [...state.roles, role],
      };
    }
    case RolesActionTypes.ROLE_UPDATE_SUCCESS: {
      const { role } = action.payload;
      const roles = state.roles.filter((r) => r.id !== role.id);
      const updatedRoles = [...roles, role];
      return {
        ...state,
        roles: updatedRoles,
      };
    }
    case RolesActionTypes.ROLE_ASSIGN_SUCCESS: {
      const { userId, roleId } = action.payload;
      const userRoles = state.usersRoles[userId];
      const role = state.roles.find((r) => r.id === roleId);
      if (userRoles && role) {
        userRoles.push(role);
      }
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          [userId]: userRoles || [role],
        },
      };
    }
    case RolesActionTypes.ROLE_REMOVE_SUCCESS: {
      const { userId, roleId } = action.payload;
      const user = state.usersRoles[userId];
      const updatedUserRoles = user.filter((role) => role.id !== roleId);
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          [userId]: updatedUserRoles,
        },
      };
    }
    case RolesActionTypes.USER_ROLES_GET_SUCCESS: {
      const { userId, roles } = action.payload;

      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          [userId]: roles,
        },
      };
    }
    case RolesActionTypes.ROLE_GET_SUCCESS: {
      const { role } = action.payload;
      const updatedRoles = state.roles.filter((r) => r.id !== role.id);
      return {
        ...state,
        roles: [...updatedRoles, role],
      };
    }
    default:
      return state;
  }
}
