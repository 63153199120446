/** @module store/licenses */
import { AnyAction } from 'redux';
import {
  State,
  LicenseActionType,
} from './types';

export const DEFAULT_STATE: State = {
  all: [],
  entries: {},
};

function reducer(state: State = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case LicenseActionType.LICENSES_GET_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.payload.repositoryId]: action.payload.licenses,
        },
      };
    case LicenseActionType.LICENSES_GET_ALL_SUCCESS:
      return {
        ...state,
        all: action.payload.licenses,
      };
    case LicenseActionType.LICENSES_ADD_SUCCESS: {
      const { repositoryId, license } = action.payload;
      let newLicenses = [...state.entries[repositoryId], license];
      newLicenses = newLicenses.sort((l1: string, l2: string) => (l1 < l2 ? -1 : 1));
      return {
        ...state,
        entries: {
          ...state.entries,
          [repositoryId]: newLicenses,
        },
      };
    }
    case LicenseActionType.LICENSES_DELETE_SUCCESS: {
      const { repositoryId, license } = action.payload;
      return {
        ...state,
        entries: {
          ...state.entries,
          [repositoryId]: state.entries[repositoryId].filter((l) => l !== license),
        },
      };
    }
    default:
      return state;
  }
}

export default reducer;
