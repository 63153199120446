/** @module components/RepositoryItemsTrashPage */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { Redirect } from 'react-router-dom';
import {
  ItemDetails,
  LicenseType,
  ProtectionType,
  Repository,
} from 'services/metadata';
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import { appPaths } from 'utilities/routes';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { history } from 'utilities/history';
import DashboardPage from '../DashboardPage';
import DashboardPageAction from '../DashboardPageAction';
import FileTable from '../FileTable';
import TableRefresh from '../TableRefresh';
import RepositoryMenu from '../RepositoryMenu';
import ProtectionTag from '../ProtectionTag';
import './styles.scss';
import BulkItemActionsBanner from '../BulkItemActionsBanner';
import { Props } from './types';

class RepositoryItemsTrashPage extends React.Component<Props> {
  componentDidMount(): void {
    const {
      getRepositoryTrash,
      getRepositories,
      repository,
    } = this.props;
    if (!repository) {
      getRepositories();
    }
    // TODO: Only fetch if the folder is undefined (requires removal of redirect below)
    getRepositoryTrash();
  }

  componentDidUpdate(): void {
    const {
      error,
      files,
      loading,
      getRepositoryTrash,
    } = this.props;
    if (
      files === undefined
      && !loading
      && !error
    ) {
      getRepositoryTrash();
    }
  }

  getUpLink(): string {
    const {
      basePath,
      repositoryId,
    } = this.props;
    return `/${basePath}/${repositoryId}`;
  }

  getProtections = (repository: Repository): JSX.Element[] => {
    const protections = repository.protections.map((protection: LicenseType) => (
      <span className="tag" key={protection}>
        <ProtectionTag
          protection={protection}
          key={protection}
          data-test-id="protection"
        />
      </span>
    ));

    if (repository.isLimited) {
      protections.push(
        <span className="tag" key={ProtectionType.LIMITED}>
          <ProtectionTag
            protection={ProtectionType.LIMITED}
            key={ProtectionType.LIMITED}
            data-test-id="protection-limited"
          />
        </span>,
      );
    }

    return protections;
  }

  getTags = (repository: Repository): JSX.Element[] => {
    const tags = repository.tags ? repository.tags.map((tag: string) => (
      <span className="tag-container" key={tag}>
        <div key={tag} data-test-id="tag" className="tag">
          <TagIcon className="tag-icon" />
          {tag}
        </div>
      </span>
    )) : [];
    return tags;
  }

  render(): JSX.Element {
    const {
      loading,
      refreshing,
      files,
      getRepositoryTrash,
      repository,
      error,
      t,
    } = this.props;
    if (error) {
      // TODO: Remove redirect. Requires Dashboard to know if the folder exists.
      return <Redirect to={appPaths.root} data-test-id="redirect" />;
    }
    if (!repository || !files) {
      return (
        <div className="App__loader" data-test-id="loader">
          <Spin spinning />
        </div>
      );
    }
    const fileDetails: ItemDetails[] = [];
    if (files) {
      files.forEach((file) => {
        if (file.details) {
          fileDetails.push(file.details);
        }
      });
    }
    return (
      <div className="RepositoryItemsTrashPage">
        <DashboardPage
          title={repository
            ? t('RepositoryItemsTrashPage.title.repoName', '{{repoName}} Trash', { repoName: repository.name })
            // TODO: Unreachable code. Remove from here and translation keys.
            : t('RepositoryItemsTrashPage.title.trash', 'Trash')}
          actions={[
            <DashboardPageAction
              text={t('RepositoryItemsTrashPage.DashboardPageAction.text', 'EXIT TRASH')}
              icon={<CloseIcon width={15} height={15} />}
              link={this.getUpLink()}
              key="exit-trash"
            />,
          ]}
          upLink={this.getUpLink()}
          tags={this.getTags(repository)}
          protections={this.getProtections(repository)}
          menu={(<RepositoryMenu repositoryId={repository.id} afterDelete={(): void => history.push(appPaths.myRepositories)} iconSize="large" />)}
          data-test-id="page"
        >
          <>
            <TableRefresh
              refreshing={refreshing}
              onRefresh={(): void => getRepositoryTrash()}
              data-test-id="refresh"
            />
            <BulkItemActionsBanner
              showRestore
              showDelete={false}
              showDownload={false}
              showMove={false}
            />
            <FileTable
              files={fileDetails}
              // TODO: Unused prop in FileTable
              onRefresh={(): void => getRepositoryTrash()}
              repository={repository}
              loading={loading}
              trash
              onChangePage={() => {}}
            />
          </>
        </DashboardPage>
      </div>
    );
  }
}

export default withTranslation()(RepositoryItemsTrashPage);
