/** @module utilities/timer */

import { useEffect, useRef } from 'react';

/**
 * Custom hook for an interval timer that can be reset.
 *
 * @param callback - The function to be executed on each interval.
 * @param resetTrigger - A boolean that triggers a reset of the interval when changed.
 * @param setResetTimer - Function to reset the resetTrigger state in the calling component.
 * @param intervalDuration - Interval duration in milliseconds. Defaults to 10 seconds.
 *
 * The hook uses two useEffects:
 * 1. The first useEffect sets up the interval and clears it on unmount.
 * 2. The second useEffect listens for changes in resetTrigger. When resetTrigger is true,
 *    it resets the interval and sets resetTrigger to false using setResetTimer.
 */
const useResettableTimer = (
  callback: () => void,
  resetTrigger: boolean,
  setResetTimer: React.Dispatch<React.SetStateAction<boolean>>,
  intervalDuration = 10000,
): React.MutableRefObject<NodeJS.Timeout | null> => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    intervalRef.current = setInterval(callback, intervalDuration);
    return (): void => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (resetTrigger) {
      setResetTimer(false);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(callback, intervalDuration);
    }
  }, [resetTrigger, callback, intervalDuration, setResetTimer]);

  return intervalRef;
};

export default useResettableTimer;
