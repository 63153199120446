/** @module components/NotFoundSplashPage */
import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import SplashPage from '../SplashPage';

/**
 * Displays a 404 page.
 * @param props The props
 * @return A JSX Element
 */
function NotFoundSplashPage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <SplashPage>
      <h1>
        {t('NotFoundSplashPage.title', 'Page Not Found')}
      </h1>
      <Empty description="" />
      <h4>
        {t('NotFoundSplashPage.message', 'We couldn\'t find the page you\'re looking for.')}
      </h4>
    </SplashPage>
  );
}

export default NotFoundSplashPage;
