/** @module components/AddCollaboratorsSection */
import React, { useState, useEffect, useCallback } from 'react';
import { Input, Card, Checkbox } from 'antd';
import { CollaboratorIdentity, Repository } from 'services/metadata';
import { ExternalCollaborator } from 'services/invitations';
import AddRegisteredCollabForm from 'components/AddRegisteredCollabForm';
import AddUnregisteredExtCollabForm from 'components/AddUnregisteredExtCollabForm';
import AddUnregisteredGECollabForm from 'components/AddUnregisteredGECollabForm';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/x-cancel.svg';
import { CollaboratorStatus, Props } from './types';
import './styles.scss';

/**
 * Allows the user to add collaborators to a repository.
 */
function AddCollaboratorsSection(props: Props): JSX.Element {
  const [searchedInput, setSearchedInput] = useState('');
  const [inputValue, setInputValue] = useState('');
  // ^^ These two different states to handle input value is required to avoid unnecessary rerenders
  // due to getUserStatus callback function
  const [inputFocused, setInputFocused] = useState(false);
  const [searched, setSearched] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);
  const [userStatus, setUserStatus] = useState<CollaboratorStatus>(CollaboratorStatus.UNDEFINED);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { t } = useTranslation();
  const {
    collaborators,
    users,
    repository,
    canGetCollaborators,
    inviteCollaborator,
    addCollaborator,
    loading,
    searchUsers,
    partners,
    searchLoading,
    getPartners,
    addCollaboratorError,
    inviteCollaboratorError,
    preregisterCollaborator,
    canViewCollab,
  } = props;

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearched(false);
    setInputValue(e.target.value);
  };
  /**
   * Searches for collaborators, then updates the component
   * state.
   *
   * @param searchTerm string A search term
   */

  const onSearch = (searchTerm: string): void => {
    const trimmedSearchTerm = searchTerm.trim();
    if (!trimmedSearchTerm && trimmedSearchTerm.length === 0) {
      return;
    }
    searchUsers(trimmedSearchTerm);
    setSearchedInput(trimmedSearchTerm);
    setSearched(true);
  };
  /**
   * Returns the collaborator status
   *
   * @return CollaboratorStatus The collaborator status
   */
  const getUserStatus = useCallback((): CollaboratorStatus => {
    // check if registered
    if (users.length > 0) {
      return CollaboratorStatus.REGISTERED;
    }
    // check if unregistered GE email
    if (searchedInput.includes('@ge.com')) {
      return CollaboratorStatus.NEWGE;
    }
    // Check if unregistered SSO
    if ((!Number.isNaN(Number(searchedInput)) && searchedInput.length === 9)) {
      return CollaboratorStatus.UNREGISTERED_SSO;
    }
    // check if domain is whitelisted or not
    if (searchedInput.includes('@')) {
      const domain = searchedInput.split('@')[1];
      if (partners.some((partner) => partner.toLowerCase() === domain.toLowerCase())) {
        return CollaboratorStatus.WHITELISTED;
      }
      return CollaboratorStatus.NOTWHITELISTED;
    }
    if (searchedInput.length && !loading) {
      return CollaboratorStatus.NOTFOUND;
    }
    return CollaboratorStatus.UNDEFINED;
  }, [users, searchedInput, partners, loading]);

  /**
   * Updates the user's collaboration status state
   * when there is a change in users, searched input, partners, and loading
   */
  useEffect(() => {
    const status = getUserStatus();
    setUserStatus(status);
  }, [getUserStatus]);

  const closeCard = (): void => {
    setSearched(false);
    setSearchedInput('');
    setInputValue('');
  };

  const OnAddCollaborator = (collabIdentity: CollaboratorIdentity, repo: Repository): void => {
    addCollaborator(collabIdentity, repo, canGetCollaborators);
    setFormSubmitted(true);
  };

  const onAddUnregisteredCollaborator = (collab: ExternalCollaborator): void => {
    inviteCollaborator(collab);
    setFormSubmitted(true);
  };

  const onPreregisterCollaborator = (
    role: string,
    email: string,
    displayName?: string,
  ): void => {
    preregisterCollaborator(repository, role, email, displayName);
    setFormSubmitted(true);
  };

  /**
   * closes the card if there are no errors
   */
  useEffect(() => {
    if (formSubmitted && !inviteCollaboratorError && !addCollaboratorError && !loading) {
      setSearched(false);
      setInputValue('');
      setSearchedInput('');
    }
  }, [loading, inviteCollaboratorError, formSubmitted, addCollaboratorError]);
  return (
    <div className="AddCollaboratorsSection">
      <div className="search-section">
        <div className="search-bar">
          {t('AddCollaboratorsSection.search.title', 'Search by Email or SSO')}
          <Input.Search
            loading={searchLoading}
            disabled={searchLoading || loading}
            enterButton
            onSearch={onSearch}
            onChange={onChange}
            value={inputValue}
            data-test-id="input"
            className={inputFocused ? 'focused-input' : 'input'}
            onFocus={(): void => setInputFocused(true)}
            onBlur={(): void => setInputFocused(false)}
          />
        </div>
        <div className="instructions" data-test-id="instructions">
          <Trans i18nKey="AddCollaboratorsSection.search.instructions">
            Please enter a complete email or SSO in order to add a collaborator to this repository.
            {' '}
            <br />
            If you would like to invite a new user, please enter their full email in the search box
             above to get started.
          </Trans>
        </div>
      </div>
      {searched && !searchLoading && (
      <div className="results">

        {userStatus === CollaboratorStatus.REGISTERED && (
          // Registered User External and Internal
          users.map((user) => {
            const currentCollab = collaborators
            && collaborators.find((c) => c.user.email === user.email);
            return (
              <Card
                data-test-id="registered-card"
                key={user.id}
                className={`card ${user.external ? 'external' : 'internal'}`}
                title={(
                  <div className="header">
                    <div className="title">
                      {user.external && t('AddCollaboratorsSection.external', 'External User') }
                      { user.preregistered ? t('AddCollaboratorsSection.newGEPendingRegistration.title', 'Pending Registration User')
                        : t('AddCollaboratorsSection.registered', 'Registered User') }
                    </div>
                    {!currentCollab && (
                    <div className="description" data-test-id="registered-card-desc">
                      {user.external && t('AddCollaboratorsSection.externalRegistered.externalDesc', 'You are about to add a non-GE user as a collaborator on this {{protections}} Repository.  You are responsible for verifying that this user meets the export control requirements for accessing this data.',
                        {
                          protections: repository.protections.join(', '),
                        })}
                      { user.preregistered ? (
                        <Trans i18nKey="AddCollaboratorsSection.newGEPendingRegistration.desc">
                          <span className="text-blue">NOTE:</span>
                          {' '}
                          This user has been added to REDshare in a
                          {' '}
                          <span className="text-red">PENDING</span>
                          {' '}
                          status and sent an email invitation to complete registration.
                        </Trans>
                      ) : t('AddCollaboratorsSection.externalRegistered.internalDesc', 'This user has already registered with REDshare. You may select their access and add them to the repository.') }
                    </div>
                    )}
                    {user.external && !currentCollab && (
                    <Checkbox
                      onChange={(e): void => setAcknowledged(e.target.checked)}
                      className="checkbox"
                      data-test-id="registered-card-checkbox"
                    >
                      {t('AddCollaboratorsSection.externalRegistered.checkbox', 'I verify that this user meets the export control requirements and can be added as a collaborator to this repository.')}
                    </Checkbox>
                    )}
                  </div>
                    )}
                extra={<CloseIcon data-test-id="close" className="close" height="14px" width="14px" onClick={closeCard} />}
              >
                <AddRegisteredCollabForm
                  data-test-id="addRegisteredCollabForm"
                  addCollaborator={OnAddCollaborator}
                  repository={repository}
                  user={user}
                  disabled={user.external ? !acknowledged : false}
                  loading={loading}
                  currentCollab={currentCollab}
                  canViewCollab={canViewCollab}
                />
              </Card>
            );
          })
        )}

        {userStatus === CollaboratorStatus.WHITELISTED && (
        // Unregistered external users with whitelisted domain
        <Card
          data-test-id="whitelisted-card"
          className="card external"
          title={(
            <div className="header">
              <div className="title">{t('AddCollaboratorsSection.whitelisted.title', 'External User - Not Registered')}</div>
              <div className="description">
                {t('AddCollaboratorsSection.whitelisted.desc', 'The user you have searched for is not registered with REDshare. Please complete this form to send an email invitation to this user. Once the user has completed the External Collaboration form and registered on REDshare, you will be able to add them as a collaborator.')}
              </div>
            </div>
                )}
          extra={<CloseIcon className="close" height="14px" width="14px" onClick={closeCard} />}
        >
          <AddUnregisteredExtCollabForm
            data-test-id="addUnregisteredExtCollabForm"
            onSubmit={onAddUnregisteredCollaborator}
            email={searchedInput}
            loading={loading}
          />
        </Card>
        )}

        {userStatus === CollaboratorStatus.NEWGE && (
        // Uregistered GE users
        <Card
          data-test-id="new-ge-card"
          className="card internal"
          title={(
            <div className="header">
              <div className="title">{t('AddCollaboratorsSection.newGE.title', 'Unregistered User')}</div>
              <div className="description">
                <Trans i18nKey="AddCollaboratorsSection.newGE.desc">
                  <span className="text-blue">NOTE:</span>
                  {' '}
                  This user has not registered with REDshare.
                  This user will be added in a
                  {' '}
                  <span className="text-red">PENDING</span>
                  {' '}
                  status and receive an email invitation to register for REDshare.
                   Status will be updated upon registration.
                </Trans>
              </div>
            </div>
                )}
          extra={<CloseIcon className="close" height="14px" width="14px" onClick={closeCard} />}
        >
          <AddUnregisteredGECollabForm
            data-test-id="new-ge-form"
            loading={loading}
            userEmail={searchedInput}
            onSubmit={onPreregisterCollaborator}
          />
        </Card>
        )}

        {userStatus === CollaboratorStatus.NOTWHITELISTED && (
          // For domain not whiteflisted
          <Card
            className="card internal"
            data-test-id="not-whitelisted-card"
            title={(
              <div className="header">
                <div className="title">{t('AddCollaboratorsSection.notWhitelisted.title', 'Domain Not Approved')}</div>
                <div className="description">
                  {t('AddCollaboratorsSection.notWhitelisted.desc', 'The address you entered has not been approved as an accepted domain with REDshare. In order to add this user as a collaborator in REDshare, you will have to submit the domain for approval.')}
                </div>
              </div>
                )}
            extra={<CloseIcon className="close" height="14px" width="14px" onClick={closeCard} />}
          >
            <a href="http://sc.ge.com/*REDshareExternalCollab">
              {t('AddCollaboratorsSection.notWhitelisted.link', 'Domain Approval Request')}
            </a>
          </Card>
        )}

        {(userStatus === CollaboratorStatus.NOTFOUND
        || userStatus === CollaboratorStatus.UNREGISTERED_SSO)
        && (
        // Not found or unregistered SSO
        <Card
          className="card card-without-body error no-body"
          data-test-id="not-found-card"
        >
          <div>
            <CloseIcon className="close" height="14px" width="14px" onClick={closeCard} />
            <div className="header">
              <div className="title">{userStatus === CollaboratorStatus.NOTFOUND ? t('AddCollaboratorsSection.notFound.title', 'Not found') : t('AddCollaboratorsSection.unregisteredSso.title', 'SSO is not registered on REDshare')}</div>
              <div className="description">
                {userStatus === CollaboratorStatus.NOTFOUND ? t('AddCollaboratorsSection.notFound.desc', 'We were unable to find the user you searched for.')
                  : t('AddCollaboratorsSection.unregisteredSso.desc', 'We were unable to find the user you searched for. The entered data did not match any registered SSO. To invite a user to register in REDshare, please search using their email address.')}
              </div>
            </div>
          </div>
        </Card>
        )}
      </div>
      )}
    </div>
  );
}

export default AddCollaboratorsSection;
