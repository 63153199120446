/** @module components/UserRolesTable */
import { connect } from 'react-redux';
import { selectLoading } from 'store/loading/selectors';
import { getUserRoles } from 'store/roles/actions';
import { selectUserRoles } from 'store/roles/selectors';
import { AppDispatch, GlobalState } from 'store/types';
import UserRolesTable from './component';
import { OwnProps, DispatchProps, StateProps } from './types';

export const mapStateToProps = (state: GlobalState, ownProps: OwnProps): StateProps => ({
  userRoles: selectUserRoles(state.roles, ownProps.user.id),
  loading: selectLoading(state.loading, 'USER_ROLES_GET')
  || selectLoading(state.loading, 'ROLES_REMOVE')
  || selectLoading(state.loading, 'ROLES_ASSIGN'),
  me: state.me,
});

export const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: OwnProps,
): DispatchProps => ({
  getUserRoles: (): void => { dispatch(getUserRoles(ownProps.user.id)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserRolesTable);
