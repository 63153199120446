/** @module components/ReassignRepositoriesPage */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { setSelectedRepositories, setSelectedRepositoriesToReassign } from 'store/repositories/actions';
import { Repository } from 'services/metadata';
import { multiReassignCollaborators } from 'store/collaborators/actions';
import { selectLoading, selectLoadingsForKeyword } from 'store/loading/selectors';
import { ReassignmentTask } from 'store/collaborators/types';
import { selectErrorMessagesForKeyword } from 'store/error/selectors';
import { StateProps, DispatchProps } from './types';
import ReassignRepositoriesPage from './component';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  me: state.me,
  searchedUsers: state.users.searchedUsers,
  getMultiCollabLoading: selectLoading(state.loading, 'COLLABORATORS_MULTI_GET'),
  searchUserLoading: selectLoading(state.loading, 'USERS_GET'),
  collaborators: state.collaborators,
  selectedRepositories: state.repositories.selected,
  selectedRepositoriesToReassign: state.repositories.selectedToReassign,
  loadings: {
    ...selectLoadingsForKeyword(state.loading, 'COLLABORATORS_UPDATE'),
    ...selectLoadingsForKeyword(state.loading, 'COLLABORATORS_ADD'),
  },
  errors: {
    ...selectErrorMessagesForKeyword(state.error, 'COLLABORATORS_UPDATE'),
    ...selectErrorMessagesForKeyword(state.error, 'COLLABORATORS_ADD'),
  },
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  setSelectedRepositories: (repositories: Repository[]): void => {
    dispatch(setSelectedRepositories(repositories));
  },
  setSelectedRepositoriesToReassign: (repositories: Repository[]): void => {
    dispatch(setSelectedRepositoriesToReassign(repositories));
  },
  multiReassignCollaborators: (
    reassignments: ReassignmentTask[],
    newOwnerName: string,
  ): void => {
    dispatch(multiReassignCollaborators(reassignments, newOwnerName));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReassignRepositoriesPage);
