/** @module components/RegistrationSplashPage */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from 'antd';
import SplashPage from '../SplashPage';
import { Props } from './types';
import './styles.scss';

/**
 * Displays a registration page.
 * @param props The props
 * @return A JSX Element
 */
function RegistrationSplashPage(props: Props): JSX.Element {
  const { error, loading, registerUser } = props;
  const [accepted, setAccepted] = useState(false);
  const { t } = useTranslation();
  return (
    <SplashPage className="RegistrationSplashPage">
      <h1>
        {t('RegistrationSplashPage.card.mainContent', 'Welcome to REDshare')}
      </h1>
      <div className="terms">
        <div className="conditions">
          <object
            aria-label="Terms of Use"
            width="100%"
            height="100%"
            data="assets/terms-of-use.pdf"
          />
        </div>
        <Checkbox
          className="terms-checkbox"
          onChange={(e): void => setAccepted(e.target.checked)}
          data-test-id="terms-checkbox"
        >
          {t('RegistrationSplashPage.card.terms', 'Accept EULA Terms & Conditions.')}
        </Checkbox>
      </div>

      <Button
        className="register-button"
        type="primary"
        disabled={!accepted || loading}
        size="large"
        onClick={registerUser}
        data-test-id="submit-button"
      >
        {error ? t('RegistrationSplashPage.button.tryAgain', 'Try Again') : t('RegistrationSplashPage.button.register', 'Register')}
      </Button>

      {error && (
        <div
          className="error-message"
          data-test-id="error-message"
        >
          {t(
            'RegistrationSplashPage.error.message',
            'There was an error registering you for the Export Control Data Sharing Tool. Please try again.',
          )}
        </div>
      )}
    </SplashPage>
  );
}

export default RegistrationSplashPage;
