/** @module components/RestoreFileModal */
import { connect } from 'react-redux';
import { ItemDetails } from 'services/metadata';
import { AppDispatch, GlobalState } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { restoreRepositoryItem, restoreSelectedRepositoryItems } from 'store/repositoryItems/actions';
import RestoreFileModal from './component';
import { DispatchProps, StateProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'REPOSITORY_ITEMS_RESTORE')
    || selectLoading(state.loading, 'REPOSITORY_ITEMS_RESTORE_SELECTED'),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  restoreRepositoryItem: (itemDetails: ItemDetails, newParentId: string): void => {
    dispatch(restoreRepositoryItem(itemDetails, newParentId));
  },
  restoreSelectedRepositoryItems: (newParentId: string): void => {
    dispatch(restoreSelectedRepositoryItems(newParentId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestoreFileModal);
