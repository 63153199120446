/** @module store/users-repositories */
import { Repository } from 'services/metadata';
import { State } from './types';

/**
 * Select user's all repositories.
 * @param state userRepositoriesState
 * @param userId id of the selected user
 * @return Repositories array
 */
export function selectUserRepositories(state: State, userId: string): Repository[] {
  return ((state[userId] && state[userId].entries) || []);
}

/**
 * Select a perticular repository of a user.
 * @param state userRepositoriesState
 * @param userId id of the selected user
 * @param repoId id of the repository
 * @return a repository
 */
export function selectUserRepository(
  state: State,
  userId: string,
  repoId: string,
): Repository | undefined {
  const userRepos = state[userId] && state[userId].entries;
  if (userRepos) {
    const repo = userRepos.find((r) => r.id === repoId);
    return repo;
  }
  return undefined;
}

/**
 * Select user's repositories last updated.
 * @param state userRepositoriesState
 * @param userId id of the selected user
 * @return Date
 */
export function selectUserRepositoriesLastUpdated(state: State, userId: string): Date | undefined {
  return (state[userId] && state[userId].lastUpdated);
}
