/** @module store/repositoryExports */
import { AppDispatch } from 'store/types';
import MetadataService, { MetadataErrorResponse } from 'services/metadata';
import { transformToMetadataErrorResponse } from 'utilities/standardizeErrors';
import {
  RepositoryExportZipActionType,
  RepositoryZipDownloadSelectedRequestAction,
  RepositoryZipDownloadSelectedSuccessAction,
  RepositoryZipDownloadSelectedFailureAction,
} from './types';

export const downloadSelectedRepositoryZipRequest = (
  repoId: string,
): RepositoryZipDownloadSelectedRequestAction => ({
  type: RepositoryExportZipActionType.REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_REQUEST,
  payload: { repoId },
});

export const downloadSelectedRepositoryZipSuccess = (
  repoId: string, url: string,
): RepositoryZipDownloadSelectedSuccessAction => ({
  type: RepositoryExportZipActionType.REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_SUCCESS,
  payload: { repoId, url },
});

export const downloadSelectedRepositoryZipFailure = (
  repoId: string, error: MetadataErrorResponse,
): RepositoryZipDownloadSelectedFailureAction => ({
  type: RepositoryExportZipActionType.REPOSITORY_EXPORTS_DOWNLOAD_ZIP_SELECTED_FAILURE,
  payload: { repoId, error },
});

export function downloadSelectedRepositoryZip(repoId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(downloadSelectedRepositoryZipRequest(repoId));
    try {
      const downloadUrl = await new MetadataService().getRepoZipDownloadUrl(repoId);
      dispatch(downloadSelectedRepositoryZipSuccess(repoId, downloadUrl));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = downloadUrl;
      a.setAttribute('download', 'download');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      dispatch(
        downloadSelectedRepositoryZipFailure(
          repoId,
          transformToMetadataErrorResponse(error),
        ),
      );
    }
  };
}
