/** @module components/AdminDashboard */
import { connect } from 'react-redux';
import { GlobalState } from 'store/types';
import Component from './component';
import { StateProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  me: state.me,
});


export default connect(
  mapStateToProps,
  {},
)(Component);
