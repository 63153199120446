/** @module components/TableRefresh */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button } from 'antd';
import { Props } from './types';
import './styles.scss';

function TableRefresh(props: Props): JSX.Element {
  const DEFAULT_REFRESH_TIME = 1000 * 60 * 60;
  const TEXT_REFRESH_TIME = 1000;
  const {
    lastUpdated,
    buttonText,
    onRefresh,
    updateIntervalMs,
    refreshing,
  } = props;
  const [timeFromNow, setTimeFromNow] = useState(moment(lastUpdated).fromNow());
  const { t } = useTranslation();

  /**
   * Update the from now text when last updated changes.
   */
  useEffect(() => {
    setTimeFromNow(moment(lastUpdated).fromNow());
  }, [setTimeFromNow, lastUpdated]);

  /**
   * Update the from now text when the text refresh
   * interval has passed.
   */
  useEffect(() => {
    const id = window.setInterval(() => {
      setTimeFromNow(moment(lastUpdated).fromNow());
    }, TEXT_REFRESH_TIME);
    return (): void => { window.clearInterval(id); };
  }, [setTimeFromNow, lastUpdated, TEXT_REFRESH_TIME]);

  /**
   * Refresh when the update interval has passed.
   */
  useEffect(() => {
    const id = window.setInterval(() => {
      onRefresh();
    }, updateIntervalMs || DEFAULT_REFRESH_TIME);
    return (): void => { window.clearInterval(id); };
  }, [onRefresh, updateIntervalMs, DEFAULT_REFRESH_TIME]);

  return (
    <div className="tableRefresh">
      <div className="lastUpdated">
        <span>
          {t(
            'TableRefresh.lastUpdated',
            'Last updated {{timeFromNow}}. ',
            { timeFromNow },
          )}
        </span>
        <Button
          type="link"
          className="refresh"
          onClick={(): void => onRefresh()}
          data-test-id="refresh-button"
        >
          {
            refreshing
              ? t('TableRefresh.refreshing', 'Refreshing...')
              : buttonText || t('TableRefresh.refresh', 'Refresh')
          }
        </Button>
      </div>
    </div>
  );
}

export default TableRefresh;
