/** @module store/collaborators */
import * as uuid from 'uuid';
import MetadataService, {
  Collaborator,
  CollaboratorIdentity,
  Repository,
} from 'services/metadata';
import InvitationsService, {
  ExternalCollaborator,
} from 'services/invitations';
import { getRepositories } from 'store/repositories/actions';
import { wait, promiseAllSettled } from 'utilities/asyncUtilities';
import { AppDispatch, GlobalState } from '../types';
import {
  AddCollaboratorErrorAction,
  AddCollaboratorRequestAction,
  AddCollaboratorSuccessAction,
  CollaboratorsActionType,
  GetCollaboratorsRequestAction,
  GetCollaboratorsSuccessAction,
  GetCollaboratorsErrorAction,
  GetMultiCollaboratorsRequestAction,
  GetMultiCollaboratorsSuccessAction,
  GetMultiCollaboratorsErrorAction,
  InviteCollaboratorRequestAction,
  InviteCollaboratorSuccessAction,
  InviteCollaboratorErrorAction,
  RemoveCollaboratorRequestAction,
  RemoveCollaboratorSuccessAction,
  RemoveCollaboratorErrorAction,
  UpdateCollaboratorRequestAction,
  UpdateCollaboratorSuccessAction,
  UpdateCollaboratorErrorAction,
  RenewCollaboratorRequestAction,
  RenewCollaboratorSuccessAction,
  RenewCollaboratorErrorAction,
  PreregisterCollaboratorRequestAction,
  PreregisterCollaboratorSuccessAction,
  PreregisterCollaboratorErrorAction,
  MultiReassignCollaboratorRequestAction,
  MultiReassignCollaboratorSuccessAction,
  MultiReassignCollaboratorFailureAction,
  ReassignmentTask,
  RepoReassignmentType,
  MULTI_REASSIGNMENT_KEY,
  CollaboratorsState,
} from './types';

/**
 * Creates a get collaborators request action.
 * @return A get collaborators request action.
 */
export const getCollaboratorsRequest = (): GetCollaboratorsRequestAction => ({
  type: CollaboratorsActionType.COLLABORATORS_GET_REQUEST,
});

/**
 * Creates a get collaborators success action.
 * @param collaborators An array of collaborators
 * @param repositoryId A repository id
 * @return A get collaborators success action.
 */
export const getCollaboratorsSuccess = (
  collaborators: Collaborator[],
  repositoryId: string,
): GetCollaboratorsSuccessAction => ({
  type: CollaboratorsActionType.COLLABORATORS_GET_SUCCESS,
  payload: {
    collaborators,
    repositoryId,
  },
});

/**
 * Creates a get collaborators error action.
 * @param error An error
 * @return A get collaborators error action.
 */
export const getCollaboratorsError = (
  error: Error,
  showNotification = true,
): GetCollaboratorsErrorAction => ({
  type: CollaboratorsActionType.COLLABORATORS_GET_ERROR,
  payload: {
    error,
    showNotification,
  },
});

/**
 * Creates a multiple get collaborators request action.
 * @return A multiple get collaborators request action.
 */
export const getMultiCollaboratorsRequest = (): GetMultiCollaboratorsRequestAction => ({
  type: CollaboratorsActionType.COLLABORATORS_MULTI_GET_REQUEST,
});

/**
 * Creates a multiple get collaborators success action.
 * @return A get multiple collaborators success action.
 */
export const getMultiCollaboratorsSuccess = (): GetMultiCollaboratorsSuccessAction => ({
  type: CollaboratorsActionType.COLLABORATORS_MULTI_GET_SUCCESS,
});

/**
 * Creates a multiple get collaborators error action.
 * @param error An error
 * @return A multiple get collaborators error action.
 */
export const getMultiCollaboratorsError = (
  error: Error,
  showNotification = true,
): GetMultiCollaboratorsErrorAction => ({
  type: CollaboratorsActionType.COLLABORATORS_MULTI_GET_ERROR,
  payload: {
    error,
    showNotification,
  },
});

/**
 * Creates an add collaborator request action.
 * @return An add collaborator request action.
 */
export const addCollaboratorRequest = (repoId: string): AddCollaboratorRequestAction => ({
  type: CollaboratorsActionType.COLLABORATORS_ADD_REQUEST,
  payload: {
    id: repoId,
  },
});

/**
 * Creates an add collaborator success action.
 * @return An add collaborator success action.
 */
export const addCollaboratorSuccess = (
  repository?: Repository,
  id?: string,
  showNotification = true,
  multiRequestId?: string,
): AddCollaboratorSuccessAction => ({
  type: CollaboratorsActionType.COLLABORATORS_ADD_SUCCESS,
  payload: {
    repository,
    id,
    showNotification,
    multiRequestId,
  },
});

/**
 * Creates an add collaborator error action.
 * @param error An error
 * @return An add collaborator error action.
 */
export const addCollaboratorError = (
  error: Error,
  repositoryId?: string,
  showNotification = true,
): AddCollaboratorErrorAction => ({
  type: CollaboratorsActionType.COLLABORATORS_ADD_ERROR,
  payload: {
    error,
    id: repositoryId,
    showNotification,
  },
});

/**
 * Creates an update collaborator request action.
 * @return An update collaborator request action.
 */
export const updateCollaboratorRequest = (repoId?: string): UpdateCollaboratorRequestAction => ({
  type: CollaboratorsActionType.COLLABORATORS_UPDATE_REQUEST,
  payload: {
    id: repoId,
  },
});

/**
 * Creates an update collaborator success action.
 * @param repositoryId A repository id
 * @param collaboratorId A collaborator id
 * @param role A new collaborator role
 * @return An update collaborator success action.
 */
export const updateCollaboratorSuccess = (
  repositoryId: string,
  collaborator: Collaborator,
  role: string,
  showNotification = true,
  multiRequestId?: string,
): UpdateCollaboratorSuccessAction => ({
  type: CollaboratorsActionType.COLLABORATORS_UPDATE_SUCCESS,
  payload: {
    repositoryId,
    collaborator,
    role,
    id: repositoryId,
    showNotification,
    multiRequestId,
  },
});

/**
 * Creates an update collaborator error action.
 * @param error An error
 * @return An update collaborator error action.
 */
export const updateCollaboratorError = (
  error: Error,
  repoId?: string,
  showNotification = true,
): UpdateCollaboratorErrorAction => ({
  type: CollaboratorsActionType.COLLABORATORS_UPDATE_ERROR,
  payload: {
    error,
    id: repoId,
    showNotification,
  },
});

/**
 * Creates a request action for multi-reassigning collaborators, marking the
 * beginning of the process.
 * @param multiRequestId The unique identifier for the multi-reassignment process.
 * @return An action object to denote the start of the multi-reassignment process.
 */
export const multiReassignCollaboratorsRequest = (
  multiRequestId: string,
): MultiReassignCollaboratorRequestAction => ({
  type: CollaboratorsActionType.COLLABORATORS_MULTI_REASSIGN_REQUEST,
  payload: {
    multiRequestId,
  },
});

/**
 * Creates a success action for multi-reassigning collaborators, indicating the successful
 * completion of the process.
 * @param multiRequestId The unique identifier for the multi-reassignment process.
 * @param newOwnerName The name of the new owner to whom the repositories have been reassigned.
 * @param successCount The number of collaborators successfully reassigned.
 * @return An action object to indicate the successful reassignment of collaborators.
 */
export const multiReassignCollaboratorSuccess = (
  multiRequestId: string,
  newOwnerName: string,
  successCount: number,
  failuresCount: number,
): MultiReassignCollaboratorSuccessAction => ({
  type: CollaboratorsActionType.COLLABORATORS_MULTI_REASSIGN_SUCCESS,
  payload: {
    multiRequestId,
    newOwnerName,
    successCount,
    failuresCount,
  },
});

/**
 * Creates a failure action for multi-reassigning collaborators, indicating that an error
 * occurred during the process.
 * @param multiRequestId The unique identifier for the multi-reassignment process.
 * @param error The error encountered during the reassignment process.
 * @return An action object to indicate a failure in the reassignment process due to an error.
 */
export const multiReassignCollaboratorFailure = (
  multiRequestId: string,
  error: Error,
): MultiReassignCollaboratorFailureAction => ({
  type: CollaboratorsActionType.COLLABORATORS_MULTI_REASSIGN_FAILURE,
  payload: {
    multiRequestId,
    error,
  },
});

/**
 * Creates a remove collaborator request action.
 * @return A remove collaborator request action.
 */
export const removeCollaboratorRequest = (): RemoveCollaboratorRequestAction => ({
  type: CollaboratorsActionType.COLLABORATORS_REMOVE_REQUEST,
});

/**
 * Creates a remove collaborator success action.
 * @param repositoryId A repository ID
 * @param collaboratorId A collaborator ID
 * @return A remove collaborator success action.
 */
export const removeCollaboratorSuccess = (
  repositoryId: string,
  collaboratorId: string,
  isSelf: boolean,
): RemoveCollaboratorSuccessAction => ({
  type: CollaboratorsActionType.COLLABORATORS_REMOVE_SUCCESS,
  payload: {
    repositoryId,
    collaboratorId,
    isSelf,
  },
});

/**
 * Creates a remove collaborator error action.
 * @param error An error
 * @return A remove collaborator error action.
 */
export const removeCollaboratorError = (error: Error): RemoveCollaboratorErrorAction => ({
  type: CollaboratorsActionType.COLLABORATORS_REMOVE_ERROR,
  payload: {
    error,
  },
});

/**
 * Creates an invite collaborator request action.
 * @return An invite collaborator request action.
 */
export const inviteCollaboratorRequest = (): InviteCollaboratorRequestAction => ({
  type: CollaboratorsActionType.COLLABORATORS_INVITE_REQUEST,
});

/**
 * Creates an invite collaborator success action.
 * @return An invite collaborator success action.
 */
export const inviteCollaboratorSuccess = (): InviteCollaboratorSuccessAction => ({
  type: CollaboratorsActionType.COLLABORATORS_INVITE_SUCCESS,
});

/**
 * Creates an invite collaborator error action.
 * @param error An error
 * @return An invite collaborator error action.
 */
export const inviteCollaboratorError = (error: Error): InviteCollaboratorErrorAction => ({
  type: CollaboratorsActionType.COLLABORATORS_INVITE_ERROR,
  payload: {
    error,
  },
});

/**
 * Creates a renew collaborator request action.
 * @return A renew collaborator request action.
 */
export const renewCollaboratorRequest = (): RenewCollaboratorRequestAction => ({
  type: CollaboratorsActionType.COLLABORATORS_RENEW_REQUEST,
});

/**
 * Creates a renew collaborator success action.
 * @param repositoryId A repository id
 * @param collaborator A collaborator
 * @return An update collaborator success action.
 */
export const renewCollaboratorSuccess = (
  repositoryId: string,
  collaborator: Collaborator,
): RenewCollaboratorSuccessAction => ({
  type: CollaboratorsActionType.COLLABORATORS_RENEW_SUCCESS,
  payload: {
    repositoryId,
    collaborator,
  },
});

/**
 * Creates a renew collaborator error action.
 * @param error An error
 * @return A renew collaborator error action.
 */
export const renewCollaboratorError = (error: Error): RenewCollaboratorErrorAction => ({
  type: CollaboratorsActionType.COLLABORATORS_RENEW_ERROR,
  payload: {
    error,
  },
});

/**
 * Creates a new preregister collaborator request action.
 * @return A preregister collaborator request action.
 */
export const preregisterCollaboratorRequest = (): PreregisterCollaboratorRequestAction => ({
  type: CollaboratorsActionType.COLLABORATORS_PREREGISTER_REQUEST,
});

/**
 * Creates a new preregister collaborator success action.
 * @return A preregister collaborator success action.
 */
export const preregisterCollaboratorSuccess = (): PreregisterCollaboratorSuccessAction => ({
  type: CollaboratorsActionType.COLLABORATORS_PREREGISTER_SUCCESS,
});

/**
 * Creates a preregister collaborator error action.
 * @param error An error
 * @return A preregister collaborator error action.
 */
export const preregisterCollaboratorError = (error: Error): PreregisterCollaboratorErrorAction => ({
  type: CollaboratorsActionType.COLLABORATORS_PREREGISTER_ERROR,
  payload: {
    error,
  },
});

/**
 * A thunk that gets collaborators for the given repository ID.
 * @param repositoryId A repository ID
 * @return A thunk action which returns a promise
 */
export function getCollaborators(repositoryId: string, showNotification?: boolean) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getCollaboratorsRequest());
    try {
      const collaborators = await new MetadataService().getCollaborators(repositoryId);
      dispatch(getCollaboratorsSuccess(collaborators, repositoryId));
    } catch (error) {
      dispatch(getCollaboratorsError((error as Error), showNotification));
    }
  };
}


/**
 * A thunk that gets collaborators for the given array of repositories IDs.
 * @param repositoryIds A repository ID array
 * @return A thunk action which returns a promise
 */
export function getCollaboratorsMultipleRepos(repositoryIds: string[], showNotification?: boolean) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getCollaboratorsRequest());
    dispatch(getMultiCollaboratorsRequest()); // dispatch general request
    try {
      const promises = repositoryIds.map(
        (repoId) => new MetadataService().getCollaborators(repoId),
      );
      const queue: PromiseSettledResult<Collaborator[]>[] = await promiseAllSettled(promises);
      const results: {
         [key: string]:
         PromiseSettledResult<Collaborator[]>;
         } = repositoryIds.reduce((acc, curr, index) => ({
           ...acc,
           [curr]: queue[index],
         }), {});
      Object.keys(results).forEach((res) => {
        const result = results[res];
        if (result.status === 'fulfilled') {
          dispatch(getCollaboratorsSuccess(result.value, res));
        } else {
          dispatch(getCollaboratorsError(result.reason as Error, showNotification));
        }
      });
      dispatch(getMultiCollaboratorsSuccess());
    } catch (error) {
      dispatch(getMultiCollaboratorsError(error as Error, showNotification));
    }
  };
}

/**
 * A thunk that adds a collaborator to the given repository.
 * @param collaboratorIdentity A collaborator identity
 * @param repoId A repository id
 * @param repository optional repository object for success notification
 * @param canGetCollaborators optional flag to fetch the collaborators on success, default is true
 * @return A thunk action which returns a promise
 */
export function addCollaborator(
  collaboratorIdentity: CollaboratorIdentity,
  repoId: string,
  repository?: Repository,
  canGetCollaborators = true,
  getRepos = true,
  multiRequestId?: string,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { identity, role } = collaboratorIdentity;
    dispatch(addCollaboratorRequest(repoId));
    const showNotification = !!repository;

    try {
      const collab = await new MetadataService('2.0').addCollaborator(identity, role, repoId);
      await wait(3000);
      // Edge case: when the user does not have view repo collab permission and
      // the addCollab api is hit instead of updateCollab, BE does not update the new role or
      // throw an error, the only way for us to know the collab already exists with a different role
      // is to check the role we sent to the API and the role returned in response
      if (collab.role !== role) {
        // throw error whe collab already exists with a different role
        const error = new Error('Collaborator already exists with a different role.');
        dispatch(addCollaboratorError(error));
      } else {
        // don't show notifications if repository does not exists
        dispatch(addCollaboratorSuccess(repository, repoId, showNotification, multiRequestId));
        if (canGetCollaborators) {
          dispatch(getCollaborators(repoId));
        }
        if (role === 'OWNER' && getRepos) dispatch(getRepositories());
      }
    } catch (error) {
      dispatch(addCollaboratorError((error as Error), repoId, showNotification));
    }
  };
}

/**
 * A thunk that updates a collaborator.
 * @param repositoryId A repository id
 * @param newRole The collaborator's new role
 * @param collaborator A collaborator
 * @return A thunk action which returns a promise
 */
export function updateCollaborator(
  repositoryId: string,
  collaborator: Collaborator,
  newRole: string,
  isSelf?: boolean,
  getRepos = true,
  showNotification?: boolean,
  multiRequestId?: string,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(updateCollaboratorRequest(repositoryId));
    try {
      await new MetadataService().updateCollaborator(collaborator.id, { role: newRole });
      dispatch(
        updateCollaboratorSuccess(
          repositoryId,
          collaborator,
          newRole,
          showNotification,
          multiRequestId,
        ),
      );
      if ((newRole === 'OWNER' || isSelf) && getRepos) dispatch(getRepositories());
    } catch (error) {
      dispatch(updateCollaboratorError(error as Error, repositoryId, showNotification));
    }
  };
}

/**
 * Executes a series of reassignment tasks for collaborators, handling both additions and updates.
 * This function orchestrates the multi-reassignment process by dispatching request, success, or
 * failure actions based on the outcome of all reassignments.
 *
 * @param reassignments An array of reassignment tasks. Each task specifies the action (add or
 * update) along with the necessary details for the reassignment.
 * @param newOwnerName The name of the new owner to whom some repositories might be reassigned.
 * This is used in the success action payload.
 * @return A thunk action that asynchronously performs all reassignment tasks. It dispatches a
 * success action if all reassignments are settled (regardless of individual outcomes), or a
 * failure action if a systemic error occurs that prevents the reassignments from being attempted.
 */

export const multiReassignCollaborators = (
  reassignments: ReassignmentTask[],
  newOwnerName: string,
) => async (dispatch: AppDispatch, getState: () => GlobalState): Promise<void> => {
  const multiRequestId = uuid.v4();
  dispatch(multiReassignCollaboratorsRequest(multiRequestId));
  try {
    await promiseAllSettled(
      reassignments.map((task) => {
        if (task.type === RepoReassignmentType.ADD && task.collaboratorIdentity) {
          return dispatch(
            addCollaborator(
              task.collaboratorIdentity,
              task.repoId,
              undefined,
              false,
              false,
              multiRequestId,
            ),
          );
        }
        if (task.type === RepoReassignmentType.UPDATE && task.collaborator
          && task.newRole !== undefined) {
          return dispatch(
            updateCollaborator(
              task.repoId,
              task.collaborator,
              task.newRole,
              false,
              false,
              false,
              multiRequestId,
            ),
          );
        }
        return Promise.reject(new Error(`Invalid task type or missing parameters: ${JSON.stringify(task)}`));
      }),
    );
    const state = getState();
    const collaboratorsState: CollaboratorsState = state.collaborators;
    const reassignmentProgress = collaboratorsState[MULTI_REASSIGNMENT_KEY] || {};
    const reassignmentDetails = reassignmentProgress[multiRequestId] || { successCount: 0 };
    const { successCount } = reassignmentDetails;
    const failuresCount = reassignments.length - successCount;
    dispatch(
      multiReassignCollaboratorSuccess(multiRequestId, newOwnerName, successCount, failuresCount),
    );
  } catch (error) {
    dispatch(multiReassignCollaboratorFailure(multiRequestId, error instanceof Error ? error : new Error('An unknown error occurred during multi-reassignment.')));
  }
};

/**
 * A thunk that removes a collaborator.
 * @param repositoryId A repository ID
 * @param collaboratorId A collaborator ID
 * @param isSelf Wheter or not the collaborator removed was the user
 * @param callback A callback to be called on success
 * @return A thunk action which returns a promise
 */
export function removeCollaborator(
  repositoryId: string,
  collaboratorId: string,
  isSelf: boolean,
  callback?: () => void,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(removeCollaboratorRequest());
    try {
      await new MetadataService('2.0').deleteCollaborator(collaboratorId);
      dispatch(removeCollaboratorSuccess(repositoryId, collaboratorId, isSelf));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(removeCollaboratorError(error));
    }
  };
}

/**
 * A thunk that invites an external collaborator.
 * @param externalCollaborator An external collaborator
 * @return A thunk action which returns a promise containing a response
 */
export function inviteCollaborator(externalCollaborator: ExternalCollaborator) {
  return async (dispatch: AppDispatch): Promise<Response> => {
    dispatch(inviteCollaboratorRequest());
    try {
      const response = await new InvitationsService().createInvitation(
        externalCollaborator.email,
        externalCollaborator.name.given,
        externalCollaborator.name.family,
        externalCollaborator.country_code,
      );
      dispatch(inviteCollaboratorSuccess());
      return response;
    } catch (error) {
      dispatch(inviteCollaboratorError(error));
      throw error;
    }
  };
}

/**
 * A thunk that renews an external collaborator.
 * @param collaborator An external collaborator
 * @return A thunk action which returns a promise containing a response
 */
export function renewCollaborator(repositoryId: string, collaborator: Collaborator) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(renewCollaboratorRequest());
    try {
      await new MetadataService().updateCollaborator(
        collaborator.id,
        { extendExternalExpiration: true },
      );
      dispatch(renewCollaboratorSuccess(repositoryId, collaborator));
    } catch (error) {
      dispatch(renewCollaboratorError(error));
    }
  };
}

/**
 * A thunk that preregisters and adds a non-existent collaborator.
 * @param repository A repository
 * @param role A collaborator role
 * @param email An email
 * @param displayName The new collaborators display name
 * @return A thunk action which returns a promise
 */
export function preregisterCollaborator(
  repository: Repository,
  role: string,
  email: string,
  displayName?: string,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(preregisterCollaboratorRequest());
    try {
      await new MetadataService().preregisterUser(email, displayName);
      await dispatch(addCollaborator({ identity: email, role }, repository.id, repository));
      dispatch(preregisterCollaboratorSuccess());
    } catch (error) {
      dispatch(preregisterCollaboratorError(error));
    }
  };
}
