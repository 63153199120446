/** @module components/ManageSnapshotsPage */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Form,
  Input,
} from 'antd';
import AddCircle from '@material-ui/icons/AddCircle';
import DashboardPage from 'components/DashboardPage';
import RepositoryCard from 'components/RepositoryCard';
import DashboardPageError from 'components/DashboardPageError';
import SnapshotsTable from 'components/SnapshotsTable';
import Loader from 'components/Loader';
import { appPaths } from 'utilities/routes';
import { ErrorType } from 'components/DashboardPageError/types';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import './styles.scss';
import { Props } from './types';

function ManageSnapshotsPage(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    repository,
    getRepositories,
    loading,
    getSnapshots,
    creatingSnapshot,
    createSnapshot,
  } = props;

  useEffect(() => {
    if (!repository) getRepositories();
  }, [getRepositories, repository]);

  useEffect(() => {
    getSnapshots();
  }, [getSnapshots]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [snapshotName, setSnapshotName] = useState('');

  const showModal = (): void => {
    setSnapshotName('');
    setIsModalVisible(true);
  };

  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  const handleCreate = (): void => {
    createSnapshot(snapshotName);
    setIsModalVisible(false);
  };


  if (loading) {
    return <Loader loading={loading} data-test-id="loader" />;
  }

  const getUpLink = (): string => (repository ? appPaths.manageRepository.replace(':repoId', repository.id) : '');

  const actions: JSX.Element[] = [];
  if (repository && repository.actions.create_snapshots) {
    actions.push(
      <Button
        key="create-data-snapshot"
        onClick={showModal}
        type="primary"
        data-test-id="create-data-snapshot-button"
        icon={<AddCircle className="button-icon-left" />}
      >
        {t('ManageSnapshotsPage.createDataSnapshot', 'CREATE DATA SNAPSHOT')}
      </Button>,
    );
  }

  return (
    <div className="ManageSnapshotsPage">
      <DashboardPage
        title={(
          <>
            <CameraIcon className="icon--primary icon" />
            {t('ManageSnapshotsPage.title', 'Manage Data Snapshots')}
          </>
        )}
        upLink={getUpLink()}
        hasBreadcrumbs
        actions={actions}
      >
        {repository
          ? (
            <div className="card">
              <RepositoryCard
                showBusinessBelowHeader
                showProtectionsBelowHeader
                showTagsBelowHeader
                showOwnerBelowHeader
                repository={repository}
                cardSize="large"
                data-test-id="repository-card"
                isNameClickable={false}
                expandable={false}
              >
                <div className="manage-data-snapshot-section" data-test-id="manage-data-snapshot">
                  <SnapshotsTable
                    repository={repository}
                    creatingSnapshotName={creatingSnapshot
                      ? (snapshotName || repository.name)
                      : undefined}
                  />
                </div>
              </RepositoryCard>
            </div>
          )
          : <DashboardPageError errorType={ErrorType.NOT_FOUND} data-test-id="error-page" />}
      </DashboardPage>
      <Modal
        title={t('ManageSnapshotsPage.CreateSnapshotModal.title', 'Create Data Snapshot')}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="ant-modal-create"
        data-test-id="create-data-snapshot-modal"
        key={isModalVisible.toString()}
      >
        <div className="modal-content">
          <span className="modal-description medium">
            {t('ManageSnapshotsPage.CreateSnapshotModal.description', 'You can capture the current state and content of this repository through a snapshot.')}
          </span>
          <Form className="margin-top-extra">
            <Form.Item label="Snapshot Name" name="snapshotName">
              <Input
                placeholder="Enter Snapshot Name"
                value={snapshotName}
                onChange={(e): void => setSnapshotName(e.target.value)}
              />
            </Form.Item>
          </Form>
          <div className="modal-buttons">
            <Button
              key="cancel"
              type="ghost"
              className="button"
              onClick={handleCancel}
              data-test-id="cancel"
            >
              {t('ManageSnapshotsPage.CreateSnapshotModal.button.close', 'Cancel')}
            </Button>
            <Button
              key="create"
              type="primary"
              className="button"
              onClick={handleCreate}
              data-test-id="create"
            >
              {t('ManageSnapshotsPage.CreateSnapshotModal.button.create', 'Create')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default ManageSnapshotsPage;
