/** @module services/metadata */

import { MetadataError } from './MetadataError';

/**
 * Represents an error that was returned by the
 * metadata service.
 */
export interface MetadataErrorResponse {
  code: string;
  description: string;
  causes: string[];
}

/**
 * An extension of the MetadataError class that contains parsed error data.
 */
export class ExtendedMetadataError extends MetadataError {
  errorData: MetadataErrorResponse;

  constructor(message: string, response: Response, errorData: MetadataErrorResponse) {
    super(message, response);
    this.errorData = errorData;
  }
}
