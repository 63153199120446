/** @module components/Drawer */
import { connect } from 'react-redux';
import { GlobalState } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { selectMyRepositories, selectSharedRepositories } from 'store/repositories/selectors';
import { StateProps } from './types';
import Drawer from './component';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'USERS_REPOSITORIES_GET'),
  myRepositories: selectMyRepositories(state),
  sharedRepositories: selectSharedRepositories(state),
});

export default connect(
  mapStateToProps,
  {},
)(Drawer);
