/** @module components/RepositoryItemsPage */
import React from 'react';
import { history } from 'utilities/history';
import { withTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import {
  LicenseType,
  ItemDetails,
  Repository,
  ProtectionType,
} from 'services/metadata';
import { getAppRoutes } from 'utilities/routes';
import ItemsMenu from 'components/ItemsMenu';
import Loader from 'components/Loader';
import { Placement } from 'utilities/antd';
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import { ErrorType } from 'components/DashboardPageError/types';
import UploadMenu from 'components/UploadMenu/component';
import { LOCAL_STORAGE_KEY_CHILD_FOLDER_TO_FIND_PAGE, LOCAL_STORAGE_KEY_PAGE_SIZE } from 'utilities/constants';
import DashboardPage from '../DashboardPage';
import CreateFolderModal from '../CreateFolderModal';
import FileTable from '../FileTable';
import TableRefresh from '../TableRefresh';
import RepositoryMenu from '../RepositoryMenu';
import ProtectionTag from '../ProtectionTag';
import FileUploadArea from '../FileUploadArea';
import { Props, State, AlertType } from './types';
import DashboardPageError from '../DashboardPageError';
import './styles.scss';
import BulkItemActionsBanner from '../BulkItemActionsBanner';
import NameField from '../NameField';

class RepositoryItemsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      uploadVisible: false,
      editingName: undefined,
      bannerVisible: false,
      alertType: undefined,
      calculatedPageNumber: undefined,
    };
    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount(): void {
    const {
      getItems,
      getRepositories,
      repository,
      folder,
      renewECEnabled,
      expiringCollaborators,
      collaborators,
      getCollaborators,
    } = this.props;
    if (!repository) {
      getRepositories();
    }
    if (!collaborators) {
      getCollaborators();
    }
    if (!folder || (folder && folder.actions && folder.actions.view === false)) {
      getItems();
    }
    if (renewECEnabled
      && expiringCollaborators) {
      this.getECAlertType();
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const {
      getCollaboratorsError,
      getRepositoryItemsError,
      folder,
      loading,
      loaderForEvictionStatus,
      getItems,
      collaborators,
      getCollaborators,
      expiringCollaborators,
      renewECEnabled,
      repository,
      folderId,
      refreshing,
      repositoryEvictionStatus,
      getRepositoryEvictionStatus,
    } = this.props;
    if (
      (folder === undefined
        && !loading
        && !refreshing
        && !getRepositoryItemsError) || (prevProps.folderId !== folderId)
    ) {
      getItems();
    }
    if (folder
      && repository
      && !collaborators
      && !loading
      && !refreshing
      && !getCollaboratorsError
    ) {
      getCollaborators();
    }

    if (repository
      && repository.isEvicted
      && !repositoryEvictionStatus
      && !loading
      && !loaderForEvictionStatus
      && !refreshing) {
      getRepositoryEvictionStatus();
    }

    if (folder
      && repository
      && collaborators
      && !loading
      && !refreshing
      && !getCollaboratorsError
    ) {
      const { calculatedPageNumber } = this.state;
      // Calculate page Number to show inside folder
      if (folderId && (!calculatedPageNumber || calculatedPageNumber.folderId !== folderId)) {
        this.calculatePageToShow();
      }
      // Calculate page Number to show inside repository
      if (!folderId && repository.id
        && (!calculatedPageNumber || calculatedPageNumber.folderId !== repository.id)) {
        this.calculatePageToShow();
      }
    }

    if (renewECEnabled) {
      if ((repository !== prevProps.repository)
        || (expiringCollaborators !== prevProps.expiringCollaborators)
        || (expiringCollaborators
          && prevProps.expiringCollaborators
          && expiringCollaborators.length !== prevProps.expiringCollaborators.length)) {
        this.getECAlertType();
      }
    }
  }

  onCloseBanner(): void {
    // saving the banner acknowledgement expiry time to current time + 24 hrs
    const expirationTime = new Date().getTime() + (3600 * 1000 * 24);
    localStorage.setItem('ecExpirationAcknowledgement', expirationTime.toString());
    this.setState({
      bannerVisible: false,
    });
  }

  onChangePage(page: number): void {
    const { folder } = this.props;
    const { calculatedPageNumber } = this.state;
    if (folder && folder.details) {
      if (!calculatedPageNumber) {
        localStorage.setItem(LOCAL_STORAGE_KEY_CHILD_FOLDER_TO_FIND_PAGE, folder.details.id);
      }
      this.setState({
        calculatedPageNumber:
         { folderId: folder.details.id, pageNumber: page },
      });
    }
  }

  getUpLink(): string {
    const {
      basePath,
      folder,
      repositoryId,
    } = this.props;
    if (
      folder
      && folder.details
      && repositoryId !== folder.details.id
    ) {
      const parentFolderId = folder.details.parentId || repositoryId;
      return getAppRoutes(basePath, repositoryId).goToFolder(parentFolderId);
    }
    const queryString = '?calculatePageNumber=true';
    return `/${basePath}${queryString}`;
  }

  setEditName(item?: ItemDetails): void {
    this.setState({ editingName: item });
  }

  getECAlertType(): void {
    const {
      repository,
      me,
      expiringCollaborators,
      renewECEnabled,
    } = this.props;
    if (renewECEnabled && expiringCollaborators && repository) {
      const userId = (me && me.user) ? me.user.id : undefined;
      const isOwner = (repository.owner.id === userId);
      const isSelfExpired = expiringCollaborators.filter(
        (c) => (c.user.id === userId),
      ).length > 0;
      const isCollabExpired = (expiringCollaborators.length > 0);
      // check if the ec expiration banner acknowledgement is expired
      const acknowledgementExpiryDate = localStorage.getItem('ecExpirationAcknowledgement');
      const isAcknowledged = acknowledgementExpiryDate
        ? parseInt(acknowledgementExpiryDate, 10) > new Date().getTime() : false;
      // when the logged in user is an expiring collab
      if (!isOwner && isSelfExpired) {
        this.setState({
          alertType: AlertType.COLLABORATOR,
          bannerVisible: !isAcknowledged,
        });
      } else if (isOwner && isCollabExpired) {
        // when the logged in user is the repo owner
        this.setState({
          alertType: AlertType.OWNER,
          bannerVisible: !isAcknowledged,
        });
      } else {
        this.setState({
          alertType: undefined,
          bannerVisible: false,
        });
      }
    }
  }

  getTags = (repository: Repository): JSX.Element[] => {
    const tags = repository.tags ? repository.tags.map((tag: string) => (
      <span className="tag-container" key={tag}>
        <div key={tag} data-test-id="tag" className="tag">
          <TagIcon className="tag-icon" />
          {tag}
        </div>
      </span>
    )) : [];
    return tags;
  }

  getProtections = (repository: Repository): JSX.Element[] => {
    const protections = repository.protections.map((protection: LicenseType) => (
      <span className="protection-tag" key={protection}>
        <ProtectionTag
          protection={protection}
          key={protection}
          data-test-id="protection"
        />
      </span>
    ));

    if (repository.isLimited) {
      protections.push(
        <span className="protection-tag" key={ProtectionType.LIMITED}>
          <ProtectionTag
            protection={ProtectionType.LIMITED}
            key={ProtectionType.LIMITED}
            data-test-id="protection-tag-limited"
          />
        </span>,
      );
    }

    return protections;
  }

  calculatePageToShow(): void {
    const { folder } = this.props;

    if (folder && folder.details && folder.children) {
      const { calculatedPageNumber } = this.state;
      let pageToShow = 1;
      const folderToShow = localStorage.getItem(LOCAL_STORAGE_KEY_CHILD_FOLDER_TO_FIND_PAGE);
      if (!folderToShow) {
        localStorage.setItem(LOCAL_STORAGE_KEY_CHILD_FOLDER_TO_FIND_PAGE, folder.details.id);
      } else {
        const folderToShowIndex = folder.children.findIndex((child) => child.id === folderToShow);
        if (folderToShowIndex !== -1) {
          const savedPageSize = localStorage.getItem(LOCAL_STORAGE_KEY_PAGE_SIZE);
          const pageSize = savedPageSize ? parseInt(savedPageSize, 10) : 12;
          pageToShow = Math.ceil((folderToShowIndex + 1) / pageSize);
          localStorage.setItem(LOCAL_STORAGE_KEY_CHILD_FOLDER_TO_FIND_PAGE, folder.details.id);
        } else if (folderToShow !== folder.details.id) {
          localStorage.setItem(LOCAL_STORAGE_KEY_CHILD_FOLDER_TO_FIND_PAGE, folder.details.id);
        } else if (calculatedPageNumber && calculatedPageNumber.folderId === folder.details.id) {
          pageToShow = calculatedPageNumber.pageNumber;
        }
      }
      this.setState({
        calculatedPageNumber:
      { folderId: folder.details.id, pageNumber: pageToShow },
      });
    }
  }

  toggleFileUpload(): void {
    const { uploadVisible } = this.state;
    this.setState({ uploadVisible: !uploadVisible });
  }

  cancelEdit(): void {
    this.setState({ editingName: undefined });
  }

  confirmEdit(item: ItemDetails, name: string): void {
    const { updateName } = this.props;
    if (item.id === item.repoId) updateName('repository', item.id, name, '');
    else updateName(item.type, item.id, name, item.parentId);
    this.setState({ editingName: undefined });
  }

  renderEditableTitle(item: ItemDetails): JSX.Element {
    const { editingName } = this.state;
    const {
      loading,
    } = this.props;
    return (
      <div className={editingName === item ? 'name-field-editing' : ''}>
        <NameField
          itemDetails={item}
          canDragAndDrop={false}
          text={item.name}
          editing={editingName === item}
          loading={loading}
          onConfirm={(name: string): void => this.confirmEdit(item, name)}
          onCancel={(): void => this.cancelEdit()}
          isTitle
          data-test-id="name-field"
        />
      </div>
    );
  }

  renderMenu(): JSX.Element | undefined {
    const {
      basePath,
      folder,
      repository,
      loaderForRename,
      t,
    } = this.props;

    const { alertType } = this.state;

    if (loaderForRename) {
      return (
        <div className="rename-loader" data-test-id="rename-loader">
          <Loader loading={loaderForRename} />
        </div>
      );
    }
    if (repository && folder) {
      if (folder.details && folder.details.id !== repository.id) {
        return (
          <ItemsMenu
            itemId={folder.details.id}
            parentId={folder.details.parentId}
            repositoryId={repository.id}
            showTooltip={false}
            afterDelete={(): void => history.push(this.getUpLink())}
            placement={Placement.BOTTOM_LEFT}
            data-test-id="items-menu"
            onEditName={(): void => this.setEditName(folder.details)}
            iconSize="large"
          />
        );
      }
      let collabWarning;

      if (alertType === AlertType.COLLABORATOR) {
        collabWarning = t(
          'RepositoryItemsPage.collabWarning.user',
          'Your access to this repository will be expiring soon. Contact the Owner of this repository to request renewed access.',
        );
      } else if (alertType === AlertType.OWNER) {
        collabWarning = t(
          'RepositoryItemsPage.collabWarning.owner',
          'An external collaborator will lose access to this repository in less than 7 days.',
        );
      }
      return (
        <RepositoryMenu
          repositoryId={repository.id}
          afterDelete={(): void => history.push(this.getUpLink())}
          trashLink={`/${basePath}/${repository.id}/trash`}
          iconSize="large"
          data-test-id="repo-menu"
          setEditName={(): void => this.setEditName(folder.details)}
          collabWarning={collabWarning}
        />
      );
    }
    return undefined;
  }

  render(): JSX.Element {
    const {
      loading,
      refreshing,
      folder,
      folderId,
      getItems,
      repository,
      getRepositoryItemsError,
      previewId,
      repositoryEvictionStatus,
      t,
    } = this.props;

    const {
      uploadVisible,
      bannerVisible,
      alertType,
      calculatedPageNumber,
    } = this.state;

    const pageNumber = calculatedPageNumber ? calculatedPageNumber.pageNumber : 1;
    if (getRepositoryItemsError && !loading && !refreshing) {
      return (
        <DashboardPage
          data-test-id="dashboard-page"
          title=""
          upLink={this.getUpLink()}
          hasBreadcrumbs={repository !== undefined}
        >
          <DashboardPageError
            data-test-id="not-found-page"
            errorType={ErrorType.NOT_FOUND}
            repositoryId={repository && repository.id}
          />
        </DashboardPage>
      );
    }
    if (folder && !folder.details && folder.actions.view === false) {
      // backend responds with 403
      if (repository) {
        return (
          <div className="RepositoryItemsPage">
            <DashboardPage
              data-test-id="dashboard-page"
              title={repository ? repository.name : ''}
              upLink={this.getUpLink()}
              tags={repository && this.getTags(repository)}
              protections={repository && this.getProtections(repository)}
              business={repository.business && repository.business.displayName}
              evictionStatus={repositoryEvictionStatus}
            >
              <DashboardPageError
                data-test-id="inaccessible-repo-page"
                protections={repository.protections}
                errorType={ErrorType.INACCESSIBLE}
              />
            </DashboardPage>
          </div>
        );
      }
      return (
        <DashboardPage
          data-test-id="dashboard-page"
          title=""
          upLink={this.getUpLink()}
          hasBreadcrumbs={false}
        >
          <DashboardPageError
            data-test-id="not-found-page"
            errorType={ErrorType.NOT_FOUND}
          />
        </DashboardPage>
      );
    }

    if (!repository || !folder) {
      return (
        <div className="App__loader" data-test-id="loader">
          <Spin spinning />
        </div>
      );
    }

    const bannerIdPrefix = 'collabBanner-';
    const bannerIdSuffix = alertType === AlertType.OWNER ? 'owner' : 'user';

    const bannerContent = bannerVisible ? {
      bannerId: `${repository.id}-${bannerIdPrefix}${bannerIdSuffix}`,
      content: (
        <>
          {alertType === AlertType.OWNER ? (
            <>
              {t(
                'RepositoryItemsPage.collabBanner.owner1',
                'This repository has external collaborators that will expire in less than 7 days. Click ',
              )}
              <Button
                type="link"
                onClick={(): void => history.push(`/manage-collaborators/${repository.id}`)}
                data-test-id="manage-collaborators-button"
                className="manage-collab-btn"
              >
                {t('RepositoryItemsPage.collabBanner.mngCollab', 'Manage Collaborators')}
              </Button>
              {t(
                'RepositoryItemsPage.collabBanner.owner2',
                ' to renew their status.',
              )}
            </>
          ) : (
            t(
              'RepositoryItemsPage.collabBanner.user',
              'You will lose access to this repository in less than 7 days. Please contact the repository owner to renew your access.',
            )
          )}
        </>
      ),
    } : undefined;

    return (
      <div className="RepositoryItemsPage">
        <DashboardPage
          data-test-id="dashboard-page"
          title={folder.details ? this.renderEditableTitle(folder.details) : ''}
          actions={
            repository.actions.upload_files
              ? [
                <CreateFolderModal
                  parentId={folderId || repository.id}
                  key="create-folder"
                  data-test-id="create-folder"
                />,
                <UploadMenu
                  key="upload"
                  data-test-id="upload"
                  onSelectFileUpload={(): void => this.toggleFileUpload()}
                  folderItems={folder && folder.children}
                  folderId={folderId}
                  repositoryId={repository.id}
                />,
              ]
              : []
          }
          upLink={this.getUpLink()}
          tags={this.getTags(repository)}
          protections={this.getProtections(repository)}
          business={repository.business && repository.business.displayName}
          menu={this.renderMenu()}
          bannerContent={bannerContent}
          evictionStatus={repositoryEvictionStatus}
        >
          <>
            {
              repository.actions.upload_files
              && uploadVisible
              && (
                <FileUploadArea
                  folderId={folderId}
                  repositoryId={repository.id}
                  folderItems={folder && folder.children}
                  data-test-id="upload-area"
                />
              )
            }
            <TableRefresh
              lastUpdated={folder ? folder.lastUpdated : undefined}
              refreshing={refreshing}
              onRefresh={getItems}
            />
            <BulkItemActionsBanner />
            <FileTable
              files={folder && folder.children}
              onRefresh={getItems}
              repository={repository}
              loading={loading || refreshing}
              previewId={previewId}
              pageNumber={pageNumber}
              onChangePage={this.onChangePage}
              data-test-id="file-table"
            />
          </>
        </DashboardPage>
      </div>
    );
  }
}

export default withTranslation()(RepositoryItemsPage);
