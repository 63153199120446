/** @module components/RepositoryWizard/fields */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Form,
  Radio,
  Row,
} from 'antd';
import { QuestionFieldProps } from '../types';
import { GUTTER, QUESTION_SPAN } from './constants';

/**
 * Asks the user if the repository contains data related to a military project.
 * @param props The props
 * @return A JSX Element
 */
function ITARQuestionField(props: QuestionFieldProps): JSX.Element {
  const { onChange, value } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={GUTTER} className="RepositoryWizardField">
      <Col span={QUESTION_SPAN}>
        <Form.Item
          name="itar"
          rules={[{
            validator: (): Promise<void> => {
              if (value !== undefined) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('RepositoryWizard.ITARQuestionField.required', 'Required')));
            },
          }]}
          label={t(
            'RepositoryWizard.ITARQuestionField.question',
            'Will this repository contain technical data related to a military (ITAR) project?',
          )}
          colon={false}
          valuePropName=""
        >
          <Radio.Group
            onChange={(e): void => onChange(e.target.value)}
            value={value}
            data-test-id="radio-group"
          >
            <Radio.Button value>
              {t('RepositoryWizard.ITARQuestionField.answer.yes', 'Yes')}
            </Radio.Button>
            <Radio.Button value={false}>
              {t('RepositoryWizard.ITARQuestionField.answer.no', 'No')}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  );
}

export default ITARQuestionField;
