/** @module store/me */
import { AnyAction } from 'redux';
import {
  RegistrationState,
} from 'services/metadata/types/RegistrationState';
import {
  MeActionType,
  MeState,
} from './types';

export const DEFAULT_STATE: MeState = {
  user: undefined,
  actions: undefined,
  registrationState: RegistrationState.INITIAL,
};

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
export default function reducer(
  state: MeState = DEFAULT_STATE,
  action: AnyAction,
): MeState {
  switch (action.type) {
    case MeActionType.ME_GET_SUCCESS: {
      const { me } = action.payload;
      return me;
    }

    case MeActionType.ME_REGISTER_SUCCESS: {
      const { me } = action.payload;
      if (me && me.registrationState) {
        return me;
      }
      return state;
    }

    default:
      return state;
  }
}
