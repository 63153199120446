/** @module components/UserSearchResultsPage */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import UserCard from 'components/UserCard';
import { history } from 'utilities/history';
import { appPaths } from 'utilities/routes';
import queryString from 'query-string';
import DashboardPage from '../DashboardPage';
import UserSearchForm from '../UserSearchForm';
import { Props } from './types';
import './styles.scss';

function UserSearchResultsPage(props: Props): JSX.Element {
  const {
    searchUsers,
    users,
    loading,
    me,
    setSelectedUser,
  } = props;
  const { t } = useTranslation();
  // TODO: replace empty functions when functionality is implemented
  const { queryUserSearch } = queryString.parse(window.location.search);

  useEffect(() => {
    if (queryUserSearch && typeof queryUserSearch === 'string' && queryUserSearch.length) {
      searchUsers(queryUserSearch);
    }
  }, [queryUserSearch, searchUsers]);

  const onSearch = (searchQuery: string): void => {
    history.push(`${appPaths.adminUserSearch}?queryUserSearch=${searchQuery}`);
  };

  /**
 * Sets the searched user as the selected user
 */
  useEffect(() => {
    if (users.length) {
      setSelectedUser(users[0]);
    }
  }, [setSelectedUser, users]);

  // Assuming that users would always return only one user inside an array because right now we
  // support unique email ids only. Incase this logic changes,
  // we would have to change this here and user UserTable again
  const user = users[0];

  return (
    <DashboardPage
      title={t('UserSearchResultsPage.title', 'Search by User')}
      upLink={appPaths.admin}
    >
      <div className="UserSearchResultsPage">
        <div className="search-container">
          <div className="search">
            <UserSearchForm
              data-test-id="user-search-form"
              searchUsers={(searchQuery): void => onSearch(searchQuery)}
              initialValue={typeof queryUserSearch === 'string' ? queryUserSearch : undefined}
            />
          </div>
        </div>
        {users.length === 0 && !loading ? (
          <div className="no-results" data-test-id="no-results">
            {t('UserSearchResultsPage.noResults', 'Sorry but no results match your criteria, please try again.')}
            <div className="no-results-image" />
          </div>
        ) : <UserCard user={user} me={me} loading={loading} data-test-id="user-card" />}
      </div>
    </DashboardPage>
  );
}

export default UserSearchResultsPage;
