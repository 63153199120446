/* eslint-disable @typescript-eslint/no-unused-vars */
/** @module utilities/test */
import { mockAbstractView } from '.';

const mockMouseEvent: React.MouseEvent<HTMLElement, MouseEvent> = {
  altKey: false,
  button: 0,
  buttons: 0,
  clientX: 0,
  clientY: 0,
  ctrlKey: false,
  getModifierState(_key: string): boolean {
    throw new Error('Function not implemented.');
  },
  metaKey: false,
  movementX: 0,
  movementY: 0,
  pageX: 0,
  pageY: 0,
  relatedTarget: null,
  screenX: 0,
  screenY: 0,
  shiftKey: false,
  detail: 0,
  view: mockAbstractView,
  nativeEvent: new MouseEvent('click'),
  currentTarget: document.createElement('div'),
  target: document.createElement('div'),
  bubbles: false,
  cancelable: false,
  defaultPrevented: false,
  eventPhase: 0,
  isTrusted: false,
  preventDefault(): void {
    throw new Error('Function not implemented.');
  },
  isDefaultPrevented(): boolean {
    throw new Error('Function not implemented.');
  },
  stopPropagation(): void {
    throw new Error('Function not implemented.');
  },
  isPropagationStopped(): boolean {
    throw new Error('Function not implemented.');
  },
  persist(): void {
    throw new Error('Function not implemented.');
  },
  timeStamp: 0,
  type: '',
};

export default mockMouseEvent;
