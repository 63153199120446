/** @module components/RepositoryItemsPage */
import { WithTranslation } from 'react-i18next';
import {
  Item,
  Repository,
  Me,
  ItemDetails,
  Collaborator,
} from 'services/metadata';
import { EvictionStatus } from 'services/metadata/types/EvictionStatus';

export enum AlertType {
  OWNER = 'owner',
  COLLABORATOR = 'collaborator',
}
export interface State {
  uploadVisible: boolean;
  editingName?: ItemDetails;
  bannerVisible: boolean;
  alertType: AlertType | undefined;
  calculatedPageNumber?: CalculatedPageNumber;
}

export interface CalculatedPageNumber {
  pageNumber: number;
  folderId: string;
}

export interface OwnProps {
  repositoryId: string;
  basePath: string;
  folderId?: string;
  previewId?: string;
}

export interface StateProps {
  me: Me;
  getCollaboratorsError: boolean;
  getRepositoryItemsError: boolean;
  folder?: Item;
  loading: boolean;
  refreshing: boolean;
  repository: Repository | undefined;
  loaderForRename: boolean;
  loaderForEvictionStatus: boolean;
  collaborators?: Collaborator[];
  expiringCollaborators?: Collaborator[];
  renewECEnabled: boolean;
  repositoryEvictionStatus?: EvictionStatus;
}

export interface DispatchProps {
  getItems: Function;
  getRepositories: Function;
  getCollaborators: Function;
  updateName: Function;
  getRepositoryEvictionStatus: () => void;
}

export type ContainerProps = OwnProps & StateProps & DispatchProps;
export type Props = OwnProps & StateProps & DispatchProps & WithTranslation;
