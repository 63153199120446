/** @module store/error */
import { AnyAction } from 'redux';
import i18n from '../../i18n';
import { ErrorState } from './types';

export const DEFAULT_STATE: ErrorState = {};

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
export default function reducer(state = DEFAULT_STATE, action: AnyAction): ErrorState {
  const { type, payload } = action;
  const regex = new RegExp('(.*)_(REQUEST|ERROR)');
  const matches = regex.exec(type);
  if (matches) {
    const [, requestName, requestState] = matches;
    const isError = (requestState === 'ERROR');
    // if error message is not passed in the payload, set it to default error message
    const defaultErrorMessage = i18n.t('errors.DefaultErrorMessage', 'Something went wrong');
    const errorMessage: string = (payload && payload.error && payload.error.message)
      || defaultErrorMessage;
    if (payload && payload.id) {
      const { id } = payload;
      return {
        ...state,
        [requestName]: isError ? errorMessage : null,
        [`${requestName}_${id}`]: isError ? errorMessage : null,
      };
    }
    return {
      ...state,
      [requestName]: isError ? errorMessage : null,
    };
  }
  return state;
}
