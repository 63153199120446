/** @module components/RepositoryWizard */
export interface RepositoryWizardValues {
  fedRamp: number;
  isCdi: boolean;
  isEc: boolean;
  isUsOnly: boolean;
  isUkLicense: boolean;
  isLicenseAvailable: boolean;
  isUsCitizen: boolean;
  isDoc: boolean;
}

export interface Props {
  onChange: (values: RepositoryWizardValues, complete: boolean) => void;
  businessId: string;
}

export interface QuestionFieldProps {
  onChange: (value: boolean) => void;
  value: boolean | undefined;
}

/**
 * A list of implemented repository wizards and their corresponding IDs.
 * DO NOT ADD BUSINESSES HERE UNTIL THEIR WIZARDS ARE IMPLEMENTED.
 * https://dwt-jira.atlassian.net/wiki/spaces/RSD/pages/816316476/Business+Mappings
 */
export enum RepositoryWizardType {
  GE_AVIATION = '1',
  GECAS = '2',
  GE_NUCLEAR = '3',
  GAS_POWER = '4',
  // POWER_PORTFOLIO = '5',
  POWER_CONVERSION = '6',
  GE_CORPORATE = '7',
  GE_DIGITAL = '8',
  PEOPLE_OPS = '9',
  GRC = '10',
}
