/** @module utilities/email */
import i18n from '../../i18n';

/**
 * Returns a mailto link that contains an email for sharing a file on REDshare.
 * @param userName The name of the current user
 * @param deepLink The REDshare link being shared
 * @return a mailto link
 */
function getShareLink(userName: string, deepLink: string): string {
  const emailSub = i18n.t(
    'emailTemplate.emailSub',
    '{{user}} is sharing a REDshare link with you',
    { user: userName },
  );
  const emailBody = i18n.t(
    'emailTemplate.emailBody',
    '{{user}} has shared a link with you from REDshare. Follow this link to view:',
    { user: userName },
  );
  const emailFooter = i18n.t(
    'emailTemplate.emailFooter',
    'Learn more about REDshare for GE by visiting the Yammer group: {{link}}.',
    { link: 'http://sc.ge.com/*redshare' },
  );
  return `mailto:?subject=${emailSub}&body=${emailBody} ${deepLink}. ${emailFooter}`;
}

export default getShareLink;
