/** @module store/notifications */
import { Action } from 'redux';

export enum NotificationType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum NotificationsActionType {
  NOTIFICATIONS_ADD = 'NOTIFICATIONS_ADD',
  NOTIFICATIONS_REMOVE_ALL = 'NOTIFICATIONS_REMOVE_ALL',
}

export interface NotificationsAddAction extends Action<
NotificationsActionType.NOTIFICATIONS_ADD
> {
  notification: Notification;
}

export type NotificationsRemoveAllAction = Action<NotificationsActionType.NOTIFICATIONS_REMOVE_ALL>;

export interface Notification {
  type: NotificationType;
  title: string;
  message: string;
}

export type State = Notification[];

export type NotificationsAction = NotificationsAddAction
                                | NotificationsRemoveAllAction;
