/** @module components/RepositoryMenu */
import { connect } from 'react-redux';
import { Repository } from 'services/metadata';
import { AppDispatch, GlobalState } from 'store/types';
import { deleteRepository } from 'store/repositories/actions';
import { selectRepository } from 'store/repositories/selectors';
import { downloadSelectedRepositoryZip } from 'store/repositoryExports/actions';
import RepositoryMenu from './component';
import { OwnProps, StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState, ownProps: OwnProps): StateProps => ({
  repository: selectRepository(state.repositories, ownProps.repositoryId),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  deleteRepository: (repository: Repository, callback?: () => void): void => {
    dispatch(deleteRepository(repository, callback));
  },
  downloadZip: (repoId: string): void => {
    dispatch(downloadSelectedRepositoryZip(repoId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepositoryMenu);
