/** @module components/UserRepositoryTable */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Popover } from 'antd';
import { Key } from 'antd/lib/table/interface';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { LicenseType, ProtectionType, Repository } from 'services/metadata';
import { ReactComponent as RepositoryIcon } from 'assets/icons/repository.svg';
import { ReactComponent as EvictionFailureIcon } from 'assets/icons/failure.svg';
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-amber.svg';
import { ReactComponent as ProgressIndicatorIcon } from 'assets/icons/progress-indicator.svg';
import { generatePaginationOptions } from 'utilities/antd';
import { sortAlphabetical, sortDate } from 'utilities/sort';
import { useResettableTimer } from 'utilities/timer';
import ProtectionTag from 'components/ProtectionTag';
import NameField from 'components/NameField';
import SearchFilter from 'components/SearchFilter';
import UserRepositoryMenu from 'components/UserRepositoryMenu';
import { EvictionState } from 'services/metadata/types/EvictionState';
import { EvictionStatus } from 'services/metadata/types/EvictionStatus';
import { mockEvictionStatus } from 'utilities/test';
import { Props } from './types';
import './styles.scss';

/**
 * A table displaying the user search results within the AdminPanel
 * @param props The component props
 * @return a JSX Element
 */
function UserRepositoryTable(props: Props): JSX.Element {
  const [editingName, setEditingName] = useState<Repository>();
  const { t } = useTranslation();
  const {
    loading,
    repositories,
    me,
    deleteRepository,
    updateName,
    user,
    reposEvictionStatuses,
    getMultipleRepositoryEvictionStatus,
    selectedRepositories,
    setSelectedRepositories,
    lastEvictedRepository,
    setContainsEvicted,
  } = props;

  const reposEvictionStatusesRef = useRef(reposEvictionStatuses);
  const [resetTimer, setResetTimer] = useState(false);
  const INTERVAL_DURATION = 15000;

  useEffect(() => {
    let initialReposEvictionStatuses: Record<string, EvictionStatus> = {};
    if (repositories.length > 0) {
      initialReposEvictionStatuses = repositories
        .filter((repo) => repo.evictionState === EvictionState.IN_PROGRESS)
        .reduce<Record<string, EvictionStatus>>((acc, repo) => {
          acc[repo.id] = mockEvictionStatus;
          return acc;
        }, {});
    }
    reposEvictionStatusesRef.current = {
      ...initialReposEvictionStatuses,
      ...reposEvictionStatuses,
    };
  }, [repositories, reposEvictionStatuses]);

  useEffect(() => {
    setContainsEvicted(false);
    const evicted = selectedRepositories.find((repo) => (
      repo.evictionState === EvictionState.EVICTED
      || repo.evictionState === EvictionState.IN_PROGRESS
    ));
    if (evicted) setContainsEvicted(true);
  }, [selectedRepositories]);

  useEffect(() => {
    setResetTimer(true);
  }, [lastEvictedRepository.lastEvictedRepositoryId]);

  const isRepoEvictionInProgress = (
    repositoryId: string,
    evictionStatuses?: Record<string, EvictionStatus>,
  ): boolean => {
    let evictionStatus = reposEvictionStatuses[repositoryId];
    if (evictionStatuses) {
      evictionStatus = evictionStatuses[repositoryId];
    }
    if (evictionStatus && evictionStatus.evictionStatus
      && !evictionStatus.evictionStatus.complete
      && !evictionStatus.evictionStatus.failed) {
      return true;
    }

    const repository = repositories.find((repo) => repo.id === repositoryId);
    if (repository
        && repository.evictionState
         && repository.evictionState === EvictionState.IN_PROGRESS) {
      return true;
    }
    return false;
  };

  const isRepoEvictionFailure = (repositoryId: string): boolean => {
    if (isRepoEvictionInProgress(repositoryId)) {
      return false;
    }
    const repository = repositories.find((repo) => repo.id === repositoryId);
    if (repository
      && repository.evictionState
       && repository.evictionState === EvictionState.FAILED) {
      return true;
    }
    const evictionStatus = reposEvictionStatuses[repositoryId];
    return evictionStatus
      && evictionStatus.evictionStatus
      && evictionStatus.evictionStatus.failed;
  };

  const getInProgressEvictionStatuses = (): void => {
    const currentReposEvictionStatuses = reposEvictionStatusesRef.current;
    const reposInEvictionInProgress = Object.keys(currentReposEvictionStatuses)
      .filter((repoId) => isRepoEvictionInProgress(repoId, currentReposEvictionStatuses));
    if (reposInEvictionInProgress.length > 0) {
      getMultipleRepositoryEvictionStatus(reposInEvictionInProgress);
    }
  };

  useResettableTimer(
    getInProgressEvictionStatuses,
    resetTimer,
    setResetTimer,
    INTERVAL_DURATION,
  );

  const showTags = (repos: Repository[]): boolean => {
    const repoContainingTags = repos.filter(
      (repository: Repository) => repository.tags && repository.tags.length > 0,
    );
    return repoContainingTags && repoContainingTags.length > 0;
  };

  const filterProtections = (): { text: string; value: string }[] => {
    const protections = new Set<string>();
    repositories.forEach((repository) => {
      repository.protections.forEach((protection) => {
        protections.add(protection);
      });
      if (repository.isLimited) {
        protections.add(ProtectionType.LIMITED);
      }
    });
    return Array.from(protections).map((protection) => ({
      text: protection,
      value: protection,
    }));
  };

  const filterTags = (): { text: string; value: string }[] => {
    const tags = new Set<string>();
    repositories.forEach((repository) => {
      if (repository.tags) {
        repository.tags.forEach((tag) => {
          tags.add(tag);
        });
      }
    });
    return Array.from(tags).map((tag) => ({
      text: tag,
      value: tag,
    }));
  };

  const confirmEdit = (id: string, name: string): void => {
    updateName(id, name);
    setEditingName(undefined);
  };

  const selectedRowKeys = selectedRepositories.map((repo) => repo.id);

  const onSelectChange = (newSelectedRowKeys: Key[]): void => {
    const selectedRepos = repositories.filter((repo) => newSelectedRowKeys.includes(repo.id));
    setSelectedRepositories(selectedRepos);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table
      dataSource={repositories}
      rowSelection={rowSelection}
      pagination={generatePaginationOptions(repositories)}
      loading={loading}
      rowKey="id"
      size="middle"
      className="UserRepositoryTable"
      rowClassName={(repository: Repository): string => (isRepoEvictionInProgress(repository.id) ? 'in-progress' : '')}
      data-test-id="user-repository-table"
    >
      <Table.Column
        title={t('UserRepositoryTable.column.name', 'Name')}
        dataIndex="name"
        className="name-column"
        render={(name: string, repository: Repository): JSX.Element => (
          <NameField
            text={name}
            icon={
              isRepoEvictionFailure(repository.id) ? (
                <Popover
                  placement="topLeft"
                  content={(
                    <div className="no-edit-pop-over">
                      <div className="info-icon">
                        <InfoOutlinedIcon />
                      </div>
                      <span>{t('NameField.EvictionFailureIcon.popover', 'Repository Extraction Failed.')}</span>
                    </div>
                )}
                  data-test-id="eviction-failure-popover"
                >
                  <EvictionFailureIcon
                    width={10}
                    height={10}
                    className="icon icon-indicator repository-icon eviction-failure-icon"
                    data-test-id="eviction-failure-icon"
                  />
                </Popover>
              ) : (
                <RepositoryIcon
                  width={10}
                  height={10}
                  className="icon icon-indicator repository-icon"
                />
              )
            }
            editing={editingName === repository}
            loading={loading}
            onConfirm={(n: string): void => confirmEdit(repository.id, n)}
            onCancel={(): void => setEditingName(undefined)}
          />
        )}
        sorter={(a: Repository, b: Repository): number => sortAlphabetical(a.name, b.name)}
        filterDropdown={({ setSelectedKeys, confirm, clearFilters }): JSX.Element => (
          <SearchFilter
            onSearch={(input): void => {
              if (setSelectedKeys) {
                setSelectedKeys([input]);
              }
              if (confirm) {
                confirm();
              }
            }}
            onReset={(): void => {
              if (clearFilters) {
                clearFilters();
              }
            }}
          />
        )}
        onFilter={(value, record): boolean => {
          if (typeof value === 'string') {
            return record.name.toLowerCase().includes(value.toLowerCase());
          }
          return false;
        }}
        data-test-id="column-name"
      />
      <Table.Column
        title={t('UserRepositoryTable.column.protections', 'Protections')}
        dataIndex="protections"
        className="protections-column"
        render={(protections: LicenseType[], repository: Repository): JSX.Element => (
          <span>
            {protections.map((protection: LicenseType) => (
              <span className="protection-tag" key={protection}>
                <ProtectionTag
                  protection={protection}
                  key={protection}
                  data-test-id="protection-tag"
                />
              </span>
            ))}
            {repository.isLimited && (
              <span className="protection-tag" key={ProtectionType.LIMITED}>
                <ProtectionTag
                  protection={ProtectionType.LIMITED}
                  key={ProtectionType.LIMITED}
                  data-test-id="protection-tag-limited"
                />
              </span>
            )}
          </span>
        )}
        filters={filterProtections()}
        filterMultiple
        onFilter={(value, record: Repository): boolean => {
          if (typeof value === 'string') {
            return record.protections.includes(value as LicenseType)
            || !!(value === ProtectionType.LIMITED && record.isLimited);
          }
          return false;
        }}
        data-test-id="column-protections"
      />
      {showTags(repositories) && (
        <Table.Column
          title={t('UserRepositoryTable.column.tags', 'Tags')}
          dataIndex="tags"
          className="tags-column"
          width="20%"
          render={(tags: string[]): JSX.Element => (
            <span>
              {tags && tags.map((tag: string) => (
                <span className="tag-container" key={tag}>
                  <div key={tag} data-test-id="tag" className="tag">
                    <TagIcon className="tag-icon" />
                    {tag}
                  </div>
                </span>
              ))}
            </span>
          )}
          filters={filterTags()}
          filterMultiple
          onFilter={(value, record: Repository): boolean => {
            if (typeof value === 'string') {
              return record.tags ? record.tags.includes(value) : false;
            }
            return false;
          }}
          data-test-id="column-tags"
        />
      )}
      <Table.Column
        title={t('UserRepositoryTable.column.updatedOn', 'Updated On')}
        key="updatedOn"
        className="updated-on-column"
        render={(repository: Repository): JSX.Element => (
          <span>
            {new Date(repository.lastUpdatedOn).toLocaleString(navigator.language)}
          </span>
        )}
        sorter={(a: Repository, b: Repository): number => (
          sortDate(a.lastUpdatedOn, b.lastUpdatedOn)
        )}
        data-test-id="column-updated-on"
      />
      <Table.Column
        title=""
        key="menu"
        render={(repository: Repository): JSX.Element => (
          <div className="user-buttons-field">
            {repository.isLimited ? (
              <Popover
                placement="topLeft"
                content={(
                  <div className="warning-pop-over">
                    <div className="info-icon">
                      <InfoOutlinedIcon />
                    </div>
                    <span>{t('ButtonsField.limited.me.popover', 'This repository belongs to a different business. Your collaboration status is limited.')}</span>
                  </div>
          )}
              >
                <span className="warning-icon" data-test-id="warning-icon"><WarningIcon height="21" width="22" /></span>
              </Popover>
            ) : (
              <div className="warning-icon-placeholder" />
            )}
            {isRepoEvictionInProgress(repository.id) ? (
              <ProgressIndicatorIcon
                className="spin"
                data-test-id="progress-indicator-icon"
              />
            ) : (
              <UserRepositoryMenu
                repository={repository}
                repoEvictionStatus={reposEvictionStatuses[repository.id]}
                data-test-id="menu"
                adminPermissions={me.permissions}
                onDeleteRepository={deleteRepository}
                setEditName={(item: Repository): void => setEditingName(item)}
                user={user}
              />
            )}
          </div>
        )}
        data-test-id="column-menu"
      />
    </Table>
  );
}

export default UserRepositoryTable;
