/** @module store/tags */
import { AnyAction } from 'redux';
import { State, TagsActionType } from './types';

export const DEFAULT_STATE: State = null;

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
function reducer(state: State = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case TagsActionType.TAGS_GET_REQUEST:
      return null;
    case TagsActionType.TAGS_GET_SUCCESS:
      return action.payload.tags;
    case TagsActionType.TAGS_CREATE_SUCCESS: {
      const { tag } = action.payload;
      if (state) {
        return [...state, tag];
      }
      return [tag];
    }
    case TagsActionType.TAGS_UPDATE_SUCCESS: {
      const { id, name } = action.payload;
      const tag = state && state.find((t) => t.id === id);
      if (state && tag) {
        return [
          ...state.filter((t) => t.id !== id),
          {
            ...tag,
            name,
          },
        ];
      }
      return state;
    }
    default:
      return state;
  }
}

export default reducer;
