/** @module store/collaborators */
import { Collaborator } from 'services/metadata';
import { CollaboratorsState } from './types';

/**
 * Selects an array of collaborators with the given state
 * and repository ID.
 * @param state The collaborators state
 * @param repositoryId A repository ID
 * @return An array of collaborators or undefined
 */
export function selectCollaborators(
  state: CollaboratorsState,
  repositoryId: string,
): Collaborator[] | undefined {
  return state[repositoryId];
}

/**
 * Selects an array of expiring collaborators for the given repository id and
 * days remaining.
 * @param state The collaborators state
 * @param repositoryId A repository ID
 * @param days The max number of days a collaborator should have to be considered expiring
 * @return An array of collaborators or undefined
 */
export function selectExpiringCollaborators(
  state: CollaboratorsState,
  repositoryId: string,
  days: number,
): Collaborator[] | undefined {
  const collaborators = state[repositoryId];
  let result;
  if (collaborators) {
    result = [];
    collaborators.forEach((collaborator) => {
      if (collaborator.expiration !== null) {
        const expirationDate = new Date(collaborator.expiration).getTime();
        const currentDate = Date.now();
        const daysRemaining = (expirationDate - currentDate) / (60 * 60 * 24 * 1000);
        if (daysRemaining <= days && daysRemaining > 0) {
          result.push(collaborator);
        }
      }
    });
  }
  return result;
}
