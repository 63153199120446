/** @module components/AnnouncementsBanner */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { getAnnouncements, setBannerClosed } from 'store/announcements/actions';
import { StateProps, DispatchProps } from './types';
import AnnouncementsBanner from './component';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  announcements: state.announcements.announcements,
  closedBannerIds: state.announcements.closedBannerIds,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getAnnouncements: (): void => {
    dispatch(getAnnouncements());
  },
  setBannerClosed: (bannerId: string): void => {
    dispatch(setBannerClosed(bannerId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementsBanner);
