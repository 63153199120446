/** @module utilities/admin */
import { BusinessPermissions, Permissions } from 'services/metadata/types/Permissions';

/**
 * Checks if the admin has a particular business permission for user's business
 * @param adminPermissions admin permissions object
 * @param usersBusinessId user's business ID
 * @param permissionToCheck a global permission to check
 * @return a boolean which tells if the permission exists or not
 */
export default function hasBusinessPermission(
  adminPermissions: Permissions | undefined,
  usersBusinessId: string,
  permissionToCheck: BusinessPermissions,
): boolean {
  let hasPermission = false;
  if (adminPermissions) {
    const { byBusiness } = adminPermissions;
    const businessPermissions = byBusiness.find(
      (p) => p.businessId === usersBusinessId,
    );

    if (businessPermissions) {
      if (businessPermissions.permissions.includes(permissionToCheck)) {
        hasPermission = true;
      }
    }
  }
  return hasPermission;
}
