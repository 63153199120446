/** @module components/App */
import React from 'react';
import { Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import { RegistrationState } from 'services/metadata';
import { appPaths } from 'utilities/routes';
import ErrorSplashPage from 'components/ErrorSplashPage';
import { ErrorType } from 'components/ErrorSplashPage/types';
import RegistrationSplashPage from '../RegistrationSplashPage';
import LandingPage from '../LandingPage';
import Dashboard from '../Dashboard';
import NotFoundSplashPage from '../NotFoundSplashPage';
import Loader from '../Loader';
import { Props } from './types';
import 'styles/overrides/index.scss';
import 'index.scss';
import './styles.scss';

class App extends React.PureComponent<Props> {
  componentDidMount(): void {
    const { getMe } = this.props;
    getMe();
  }

  render(): JSX.Element {
    const { fetching, me } = this.props;
    if (fetching) {
      return <Loader loading data-test-id="loader" />;
    }

    return (
      <div className="App">
        <Layout className="App__layout-container">
          <Switch>
            <Route
              exact
              path={[...Object.values(appPaths)]}
              render={(): JSX.Element => {
                let registrationState;
                if (me && me.registrationState) {
                  registrationState = me.registrationState;
                }
                switch (registrationState) {
                  case RegistrationState.REGISTERED:
                    return <Dashboard data-test-id="dashboard" />;
                  case RegistrationState.UNREGISTERED:
                  case RegistrationState.PREREGISTERED:
                    return <RegistrationSplashPage data-test-id="registration" />;
                  case RegistrationState.BLOCKED:
                    return <LandingPage blocked data-test-id="blocked-landing-page" />;
                  case RegistrationState.UNAUTHORIZED:
                    return <Redirect to="/auth/logout" data-test-id="redirect-unauthorized" />;
                  case RegistrationState.LOGGEDOUT:
                    return <LandingPage data-test-id="login" />;
                  case RegistrationState.ALREADY_REGISTERED:
                    return <ErrorSplashPage errorType={ErrorType.ALREADY_EXIST} data-test-id="error" />;
                  default:
                    return <LandingPage data-test-id="login" />;
                }
              }}
              data-test-id="route-app-paths"
            />
            <Route
              path="*"
              render={(): JSX.Element => <NotFoundSplashPage data-test-id="not-found" />}
              data-test-id="route-wildcard"
            />
          </Switch>
        </Layout>
      </div>
    );
  }
}

export default App;
