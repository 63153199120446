/** @module utilities/file */

import { Collaborator, Repository } from 'services/metadata';
import { getCountDown } from 'utilities/date';
import { CustomCSVFile } from './types';

/**
 * Generates a collaborators csv file
 * @param collaborators an array of collaborators
 * @param repository a repository
 * @return void
 */


function isCollabExpired(collaborator: Collaborator, renewECEnabled: boolean): boolean {
  if (collaborator.user.external && renewECEnabled) {
    const remainingDaysForExpiration = getCountDown(new Date(collaborator.expiration)).toFixed(0);
    const isExpired = parseInt(remainingDaysForExpiration, 10) <= 0;
    return isExpired;
  }
  return false;
}

function getCollaboratorStatus(collaborator: Collaborator, renewECEnabled: boolean): string {
  const { status, user } = collaborator;
  const { preregistered } = user;
  let returnedStatus = status;
  if (isCollabExpired(collaborator, renewECEnabled)) {
    returnedStatus = 'EXPIRED';
  } else if (preregistered) {
    returnedStatus = 'PENDING';
  }
  return returnedStatus;
}


function exportCollaboratorsToCsv(
  collaborators: Collaborator[],
  repository: Repository,
  downloadFileFunction: (fileData: CustomCSVFile) => void,
  renewECEnabled: boolean,
): void {
  if (collaborators) {
    // Headers for each column
    const headers = ['Email Address,Name,Collaboration Role,Collaboration Status,Internal or External,Preregistered,Expiration Date,Limited'];

    // Convert collaborators data to a csv
    const usersCsv = collaborators.reduce<string[]>((acc, collaborator) => {
      const {
        user, expiration, role, limited,
      } = collaborator;
      acc.push([
        user.email,
        `"${user.name}"`,
        role,
        getCollaboratorStatus(collaborator, renewECEnabled),
        `${user.external ? 'External' : 'Internal'}`,
        user.preregistered,
        expiration,
        `${limited ? 'true' : 'false'}`,
      ]
        .join(','));
      return acc;
    }, []);

    const maxCharFileName = 204;
    const fileNameEnding = '-collaborators';
    const maxCharRepoName = maxCharFileName - fileNameEnding.length;

    downloadFileFunction({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: `${repository.name.slice(0, maxCharRepoName)}${fileNameEnding}.csv`,
      fileType: 'text/csv',
    });
  }
}


export { exportCollaboratorsToCsv, getCollaboratorStatus };
