/** @module components/BulkItemActionsBanner */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import {
  deleteSelectedRepositories,
  setSelectedRepositories,
  setSelectedRepositoriesToReassign,
  restoreSelectedRepositories,
} from 'store/repositories/actions';
import BulkRepoActionsBanner from './component';
import { StateProps, DispatchProps, OwnProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  selected: state.repositories.selected,
  selectedToReassign: state.repositories.selectedToReassign,
});

export const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps): DispatchProps => ({
  deleteSelected: (): void => { dispatch(deleteSelectedRepositories()); },
  restoreSelected: (): void => { dispatch(restoreSelectedRepositories()); },
  clearSelection: (): void => {
    if (ownProps.reassignRepositoriesMode) {
      dispatch(setSelectedRepositoriesToReassign([]));
    } else { dispatch(setSelectedRepositories([])); }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkRepoActionsBanner);
