/** @module components/DashboardPageError */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, Avatar, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { appPaths } from 'utilities/routes';
import { supportDL } from 'utilities/constants';
import { ReactComponent as FolderWarningIcon } from 'assets/icons/repo-alert.svg';
import { ReactComponent as LeftArrow } from 'assets/icons/chevron-left.svg';
import { ErrorType, Props } from './types';
import './styles.scss';

const { Meta } = Card;

function DashboardPageError(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { protections, errorType, repositoryId } = props;
  let title;
  let message: string | JSX.Element;
  const protectionText = protections && protections.join(', ');

  /**
   * An Error page for dashboard
   * @param props The component props
   * @return A JSX Element
   */
  function renderSupportLink(): JSX.Element {
    return (
      <a
        href={`mailto:${supportDL}`}
        target="_blank"
        rel="noreferrer noopener"
        className="link"
      >
        {t('DashboardPageError.support.messageLink', 'REDshare Account Management')}
      </a>
    );
  }

  switch (errorType) {
    case ErrorType.INACCESSIBLE:
      title = t('DashboardPageError.inaccessible.title', 'Inaccessible Repository');
      message = (
        <Trans i18nKey="DashboardPageError.inaccessible.message" values={{ protectionText }}>
          This repository is protected by
          {' '}
          <span className="protections">{{ protectionText }}</span>
          {' '}
          permissions. Only users with matching permissions or
           licenses may view and interact with items in this repository.
        </Trans>
      );
      break;

    case ErrorType.NOT_FOUND:
      title = t('DashboardPageError.notFound.title', 'Not Found');
      message = t('DashboardPageError.notFound.message', 'The repository or folder you are trying to access does not exist.');
      break;
    default:
      title = '';
      message = '';
      break;
  }
  return (
    <div className="DashboardPageError">
      <Card className="card">
        <Meta
          avatar={<Avatar className="folderIcon" icon={<FolderWarningIcon />} size="large" />}
          title={<div className="title">{title}</div>}
        />
        <Divider className="divider" />
        <p>{message}</p>
        <p>
          <Trans i18nKey="DashboardPageError.support.message">
            If you believe you are viewing this message in error,
            please request assistance from:
            {' '}
          </Trans>
          {renderSupportLink()}
        </p>
        {repositoryId ? (
          <Link to={`${appPaths.myRepositories}/${repositoryId}`}>
            <div className="backButton">
              <LeftArrow className="leftArrow" />
              {t('DashboardPageError.returnToRepo', ' Return to Repository')}
            </div>
          </Link>
        ) : (
          <Link to={appPaths.root}>
            <div className="backButton">
              <LeftArrow className="leftArrow" />
              {t('DashboardPageError.returnToMyRepo', ' Return to My Repositories')}
            </div>
          </Link>
        )}
      </Card>
    </div>
  );
}

export default DashboardPageError;
