/** @module store/users-repositories */
import { AnyAction } from 'redux';
import { sortAlphabetical } from 'utilities/sort';
import {
  UserRepositoriesActionType,
  State,
} from './types';

export const DEFAULT_STATE: State = {};

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
export default function reducer(
  state: State = DEFAULT_STATE,
  action: AnyAction,
): State {
  switch (action.type) {
    case UserRepositoriesActionType.USERS_REPOSITORIES_GET_SUCCESS: {
      const { userId, repositories } = action.payload;
      return {
        ...state,
        [userId]: {
          entries: repositories,
          lastUpdated: new Date(),
        },
      };
    }
    case UserRepositoriesActionType.USERS_REPOSITORIES_DELETE_SUCCESS: {
      const { repository, userId } = action.payload;
      const { entries } = state[userId];
      return {
        ...state,
        [userId]: {
          entries: entries.filter((entry) => entry.id !== repository.id),
          lastUpdated: new Date(),
        },
      };
    }

    case UserRepositoriesActionType.USERS_REPOSITORIES_RENAME_SUCCESS: {
      const { userId, repoId, name } = action.payload;
      const { entries } = state[userId];
      const updatedRepo = entries.find((r) => r.id === repoId);
      const newEntries = entries.filter((entry) => entry.id !== repoId);
      if (updatedRepo) {
        updatedRepo.name = name;
        newEntries.push(updatedRepo);
        newEntries.sort((a, b) => sortAlphabetical(a.name, b.name));
        return {
          ...state,
          [userId]: {
            entries: newEntries,
            lastUpdated: new Date(),
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
}
