/** @module components/DashboardPageFooter */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { supportURL, yammerGroupURL } from 'utilities/constants';
import { appPaths } from 'utilities/routes';
import LanguagePicker from 'components/LanguagePicker';
import { Props } from './types';
import './styles.scss';

function DashboardPageFooter(props: Props): JSX.Element {
  const appVersion = process.env.REACT_APP_VERSION;
  const { t } = useTranslation();
  const { languagePickerEnabled, router } = props;
  const { location } = router;
  const goBackUrl = location ? location.pathname : '';

  return (
    <Layout.Footer className="DashboardPageFooter">
      <a
        href="/assets/terms-of-use.pdf"
        target="_blank"
        rel="noreferrer noopener"
      >
        {t(
          'DashboardPageFooter.terms',
          'Terms of Use',
        )}
        {' '}
        |
      </a>
      <a
        href={yammerGroupURL}
        target="_blank"
        rel="noreferrer noopener"
      >
        {t(
          'DashboardPageFooter.yammer',
          'Yammer Group ',
        )}
        {' '}
        |
      </a>
      <Link
        to={`${appPaths.definitions}?goBackUrl=${encodeURIComponent(goBackUrl)}`}
        data-test-id="definitions"
      >
        {t(
          'DashboardPageFooter.definitions',
          'Definitions & FAQ',
        )}
        {' '}
        |
      </Link>
      <a
        href={supportURL}
        target="_blank"
        rel="noreferrer noopener"
      >
        {t(
          'DashboardPageFooter.support',
          'Issues & Support',
        )}
        {' '}
        |
      </a>
      <Link
        to={`${appPaths.releaseNotes}?goBackUrl=${encodeURIComponent(goBackUrl)}`}
        data-test-id="release-notes"
      >
        {t(
          'DashboardPageFooter.releaseNotes',
          'Release Notes',
        )}
      </Link>

      {
        languagePickerEnabled && (
          <>
            {' '}
            |
            {' '}
            <LanguagePicker data-test-id="language-picker" />
          </>
        )
      }

      {
        appVersion && (
          <span className="app-version">
            {t(
              'DashboardPageFooter.appVerison',
              'REDshare v{{version}}',
              { version: appVersion },
            )}
          </span>
        )
      }
    </Layout.Footer>
  );
}

export default DashboardPageFooter;
