/** @module components/DeleteUserSteps */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Popover,
  Steps,
  Tooltip,
} from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import LoadingIcon from '@material-ui/icons/Loop';
import EditIcon from '@material-ui/icons/Edit';
import ReassignOwnerSection from 'components/ReassignOwnerSection';
import { SubmitType } from 'components/ReassignOwnerSection/types';
import RepositoryCard from 'components/RepositoryCard';
import SaveTimePopoverContent from 'components/SaveTimePopoverContent';
import { ReactComponent as ReassignedReposImage } from 'assets/images/Reassigned-repos.svg';
import { Props, ReassignmentData } from './types';
import './styles.scss';

export default function DeleteUserSteps(props: Props): JSX.Element {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedRepoIds, setSelectRepoIds] = useState<Set<string>>(new Set());
  const [reassignedRepos, setReassignedRepos] = useState<Map<string, ReassignmentData>>(new Map());
  const [
    failedReassignedRepos,
    setFailedReassignedRepos,
  ] = useState<Map<string, ReassignmentData>>(new Map());
  const [notReassignedRepoIds, setNotReassignedRepoIds] = useState<string[]>([]);
  const {
    errors, loadings, repositories, setAllReposReassigned,
  } = props;
  const { Step } = Steps;
  const { t } = useTranslation();

  useEffect(() => {
    reassignedRepos.forEach((reassignmentData, repoId) => {
      const idWithRequest = `COLLABORATORS_${reassignmentData.type}_${repoId}`;
      if (errors[idWithRequest]) {
        setFailedReassignedRepos(
          (oldFailedRepos) => new Map(oldFailedRepos.set(repoId, reassignmentData)),
        );
      } else if (failedReassignedRepos.has(repoId)) {
        // for repo that failed earlier but is successfull now

        const updatedRepos = new Map(failedReassignedRepos);
        updatedRepos.delete(repoId);
        setFailedReassignedRepos(new Map(updatedRepos));
      }
    });
  }, [errors, reassignedRepos]);
  // failedReassignedRepos is not in the dependancy array to avoid infinite useEffect triggers

  const onChangeStep = (newStep: number): void => {
    if (newStep < currentStep) {
      setCurrentStep(newStep);
    }
  };

  const onSelectRepository = (isSelected: boolean, id: string): void => {
    const updatedSelectedRepoIds = new Set(selectedRepoIds);
    if (isSelected) {
      updatedSelectedRepoIds.add(id);
    } else {
      updatedSelectedRepoIds.delete(id);
    }
    setSelectRepoIds(updatedSelectedRepoIds);
  };

  const onSelectAllRepo = (isSelected: boolean): void => {
    if (isSelected) {
      const updatedSelectedRepoIds = new Set(repositories.map((repo) => repo.id));
      setSelectRepoIds(updatedSelectedRepoIds);
    } else {
      const updatedSelectedRepoIds = new Set<string>();
      setSelectRepoIds(updatedSelectedRepoIds);
    }
  };

  const onReassignOwner = (repoId: string, type: SubmitType, newOwnerName: string): void => {
    setReassignedRepos(
      (oldReassignedRepos) => new Map(oldReassignedRepos.set(repoId, { newOwnerName, type })),
    );
  };

  const [step1, step2, step3] = [0, 1, 2];

  const renderExtraIcon = (id: string): JSX.Element | undefined => {
    const idWithAddRequest = `COLLABORATORS_ADD_${id}`;
    const idWithUpdateRequest = `COLLABORATORS_UPDATE_${id}`;
    // loading
    if (loadings[idWithAddRequest] || loadings[idWithUpdateRequest]) {
      return <LoadingIcon className="loading-icon" data-test-id="loading-icon" />;
    }

    // error in updating collaborator
    if (failedReassignedRepos.has(id)) {
      const reassignmentData = failedReassignedRepos.get(id) || { type: '' };
      const idWithRequest = reassignmentData.type === SubmitType.ADD
        ? idWithAddRequest : idWithUpdateRequest;
      const message = errors[idWithRequest];
      return (
        <Tooltip title={message}>
          <ErrorIcon className="error-icon" data-test-id="error-icon" />
        </Tooltip>
      );
    }

    if (reassignedRepos.has(id)) {
      return <SuccessIcon className="success-icon" data-test-id="success-icon" />;
    }

    return undefined;
  };

  const onViewReassignedRepos = (): void => {
    setCurrentStep(step3);
    setAllReposReassigned(true);
  };

  const onEditReassignedRepo = (repoId: string): void => {
    setAllReposReassigned(false);
    setCurrentStep(step2);
    onSelectAllRepo(false);
    setSelectRepoIds(new Set([repoId]));
  };

  const successfullyReassignedRepos = reassignedRepos.size - failedReassignedRepos.size;
  return (
    <Steps
      direction="vertical"
      current={currentStep}
      className="DeleteUserSteps"
      onChange={(step): void => onChangeStep(step)}
      data-test-id="steps"
    >
      <Step
        data-test-id="step-1"
        title={t('DeleteUserSteps.step1.title', 'Ready')}
        icon={currentStep !== step1 && (
          <div className="ant-steps-item-icon">
            <span className="ant-steps-icon">1</span>
          </div>
        )}
        description={currentStep === step1 ? (
          <div className="description" data-test-id="step-1-description">
            <Trans i18nKey="DeleteUserSteps.step1.description" values={{ repoCount: repositories.length }}>
              This user is an Owner of repositories! There are
              {' '}
              <span className="bold-text">
                {'{{ repoCount }}'}
                {' '}
                repositories that need to be reassigned
              </span>
              {' '}
              to a different REDshare user.
              <div className="spacer" />
              This user will be automatically removed as a collaborator
              from all of their other repositories.
            </Trans>
            <div className="spacer" />
            <Button
              data-test-id="start-button"
              className="button"
              type="primary"
              onClick={(): void => setCurrentStep(step2)}
            >
              {t('DeleteUserSteps.startButton', 'START')}
            </Button>
          </div>
        ) : <div className="spacer" data-test-id="empty-space" />}
      />

      <Step
        data-test-id="step-2"
        title={t('DeleteUserSteps.step2.title', 'Reassign')}
        disabled={currentStep < step2}
        icon={currentStep !== step2 && (
          <div className="ant-steps-item-icon">
            <span className="ant-steps-icon">2</span>
          </div>
        )}
        description={currentStep === step2 ? (
          <div className="description" data-test-id="step-2-description">
            <div className="step-2-content">
              <div className="left-content">
                <div className="text">
                  {t('DeleteUserSteps.step2.description', 'For each repository, reassign a new Owner.')}
                </div>
                <div className="card-header">
                  <Checkbox
                    className="check-box"
                    onChange={(e): void => onSelectAllRepo(e.target.checked)}
                    data-test-id="select-all-repo"
                  />
                  <span className="bold-text">{t('DeleteUserSteps.step2.selectRepo', 'SELECT REPOSITORIES')}</span>
                </div>
                <Card
                  className="repo-list"
                  data-test-id="repo-list"
                >
                  {repositories.map((repo, index) => (
                    <div
                      className="repo-card"
                      key={repo.id}
                    >
                      <RepositoryCard
                        repository={repo}
                        data-test-id={`repo-card-${repo.id}`}
                        expandable
                        cardSize="small"
                        cardIndex={index}
                        showSelectCheckbox
                        onSelect={(value): void => onSelectRepository(value, repo.id)}
                        isNameClickable={false}
                        isSelected={selectedRepoIds.has(repo.id)}
                        extraIcon={renderExtraIcon(repo.id)}
                      />
                    </div>
                  ))}
                </Card>
                <div className="button-with-text">
                  {!loadings.COLLABORATORS_ADD
                    && !loadings.COLLABORATORS_UPDATE
                    && successfullyReassignedRepos === repositories.length
                    && (
                      <Button
                        data-test-id="continue-button"
                        className="button"
                        type="primary"
                        onClick={onViewReassignedRepos}
                      >
                        {t('DeleteUserSteps.step2.button', 'CONTINUE')}
                      </Button>
                    )}
                  <span className="italic-text" data-test-id="repo-count">
                    {!loadings.COLLABORATORS_ADD && !loadings.COLLABORATORS_UPDATE && t(
                      'DeleteUserSteps.step2.buttonDescription',
                      '{{reassignedRepos}} of {{totalRepos}} repositories reassigned',
                      {
                        reassignedRepos: successfullyReassignedRepos,
                        totalRepos: repositories.length,
                      },
                    )}
                  </span>
                </div>
              </div>
              <div className="right-content">
                <div className="save-time">
                  <Popover
                    placement="topRight"
                    title={t('DeleteUserSteps.step2.saveTimePopoverTitle', 'Default to Co-Owners')}
                    content={(
                      <SaveTimePopoverContent
                        repositories={repositories}
                        reassignedRepos={reassignedRepos}
                        setNotReassignedRepoIds={setNotReassignedRepoIds}
                      />
)}
                    trigger="click"
                  >
                    <Button
                      className="link-button"
                      type="link"
                    >
                      {t('DeleteUserSteps.step2.saveTimeButton', 'Save time?')}
                    </Button>
                  </Popover>
                </div>
                <ReassignOwnerSection
                  data-test-id="reassign-owner-section"
                  repoIds={Array.from(selectedRepoIds)}
                  onReassignOwner={onReassignOwner}
                  notReassignedRepoIds={notReassignedRepoIds}
                  setNotReassignedRepoIds={setNotReassignedRepoIds}
                />
              </div>
            </div>
          </div>
        ) : <div className="spacer" data-test-id="empty-space" />}
      />

      <Step
        data-test-id="step-3"
        title={t('DeleteUserSteps.step3.title', 'Review')}
        disabled={currentStep < step3}
        icon={currentStep !== step3 && (
          <div className="ant-steps-item-icon">
            <span className="ant-steps-icon">3</span>
          </div>
        )}
        description={currentStep === step3 ? (
          <div className="description" data-test-id="step-3-description">
            <div className="text">
              {t('DeleteUserSteps.step3.description', 'Before deleting, review the new Owners that were assigned to this user’s repositories.')}
            </div>
            <div className="step-3-content">
              <div className="left-content">
                <div className="card-header">
                  <span className="bold-text">{t('DeleteUserSteps.step3.reassignedRepositories', 'Reassigned Repositories')}</span>
                </div>
                <Card
                  className="repo-list"
                  data-test-id="reassigned-repo-list"
                >
                  {repositories.map((repo, index) => (
                    <div
                      className="repo-card"
                      key={repo.id}
                    >
                      <RepositoryCard
                        repository={repo}
                        data-test-id={`reassigned-repo-card-${repo.id}`}
                        expandable={false}
                        cardSize="small"
                        cardIndex={index}
                        isNameClickable={false}
                        isSelected={false}
                        repoReassignmentData={reassignedRepos.get(repo.id)}
                        extraIcon={<EditIcon />}
                        onClick={(): void => onEditReassignedRepo(repo.id)}
                      />
                    </div>
                  ))}
                </Card>
              </div>
              <div className="right-content">
                <ReassignedReposImage className="image" />
              </div>
            </div>
          </div>

        ) : <div className="spacer" data-test-id="empty-space" />}
      />

    </Steps>
  );
}
