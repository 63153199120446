/** @module store */
import { applyMiddleware, createStore as reduxCreateStore, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { History } from 'history';
import createReducer from './reducer';

function createStore(history: History): Store {
  return reduxCreateStore(
    createReducer(history),
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
      ),
    ),
  );
}

export default createStore;
