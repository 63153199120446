/** @module store/announcements */
import { AnyAction } from 'redux';
import {
  AnnouncementsActionType,
  State,
} from './types';

export const DEFAULT_STATE: State = {
  announcements: [],
  closedBannerIds: [],
};

function reducer(state: State = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case AnnouncementsActionType.ANNOUNCEMENTS_GET_SUCCESS:
      return {
        ...state,
        announcements: action.payload.announcements,
      };
    case AnnouncementsActionType.ANNOUNCEMENTS_SET_BANNER_CLOSED:
      return {
        ...state,
        closedBannerIds: [...state.closedBannerIds, action.payload.bannerId],
      };
    default:
      return state;
  }
}

export default reducer;
