/** @module components/RepositoriesPage */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import AddCircle from '@material-ui/icons/AddCircle';
import { history } from 'utilities/history';
import { appPaths } from 'utilities/routes';
import { LOCAL_STORAGE_KEY_CHILD_FOLDER_TO_FIND_PAGE, LOCAL_STORAGE_KEY_FOR_VIEW, LOCAL_STORAGE_KEY_PAGE_SIZE } from 'utilities/constants';
import DashboardPage from '../DashboardPage';
import RepositoriesMenu from '../RepositoriesMenu';
import RepositoryTable from '../RepositoryTable';
import BulkRepoActionsBanner from '../BulkRepoActionsBanner';
import RepositoryCardGrid from '../RepositoryCardGrid';
import GridListToggle from '../GridListToggle';
import TableRefresh from '../TableRefresh';
import { Props } from './types';

function RepositoriesPage(props: Props): JSX.Element {
  const {
    lastUpdated,
    loading,
    refreshing,
    onRefresh,
    repositories,
    isMyRepositoryPage,
    me,
    calculatePageNumber,
  } = props;
  const [showGrid, setShowGrid] = useState(localStorage.getItem(LOCAL_STORAGE_KEY_FOR_VIEW) !== 'false');
  const [pagenumber, setPagenumber] = useState<number>(1);
  const { t } = useTranslation();

  function isExternal(): boolean {
    if (me.user) {
      return me.user.external;
    }
    return true;
  }

  useEffect(() => {
    if (!calculatePageNumber) {
      localStorage.removeItem(LOCAL_STORAGE_KEY_CHILD_FOLDER_TO_FIND_PAGE);
      setPagenumber(1);
    }
  }, [calculatePageNumber]);

  // Executed only when component unmounts,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  useEffect(() => () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY_CHILD_FOLDER_TO_FIND_PAGE);
  }, []);

  /**
  * Calls onRefresh when the component mounts.
  */
  useEffect(() => {
    if (!lastUpdated) {
      onRefresh();
    }
  }, [onRefresh, lastUpdated]);

  const calculatePageToShow = (): void => {
    let pageToShow = 1;
    const repositoryToShow = localStorage.getItem(LOCAL_STORAGE_KEY_CHILD_FOLDER_TO_FIND_PAGE);
    if (repositoryToShow) {
      const repositoryToShowIndex = repositories.findIndex((repo) => repo.id === repositoryToShow);
      if (repositoryToShowIndex !== -1) {
        const savedPageSize = localStorage.getItem(LOCAL_STORAGE_KEY_PAGE_SIZE);
        const pageSize = savedPageSize ? parseInt(savedPageSize, 10) : 12;
        pageToShow = Math.ceil((repositoryToShowIndex + 1) / pageSize);
      }
      if (pagenumber !== pageToShow) {
        setPagenumber(pageToShow);
      }
    }
  };

  useEffect(() => {
    if (repositories.length > 0) {
      calculatePageToShow();
    }
  }, [repositories]);

  const pageTitle = isMyRepositoryPage
    ? t('RepositoriesPage.dashboard.myTitle', 'My Repositories')
    : t('RepositoriesPage.dashboard.sharedTitle', 'Shared Repositories');

  const actions: JSX.Element[] = [];
  if (isMyRepositoryPage && me.actions && me.actions.create_repository) {
    actions.push(
      <Button
        key="create-repository"
        onClick={(): void => history.push(appPaths.newRepository)}
        type="primary"
        data-test-id="create-repository-button"
        icon={<AddCircle className="button-icon-left" />}
      >
        {t('RepositoriesPage.createRepo', 'CREATE REPOSITORY')}
      </Button>,
    );
  }

  const onToggleShowGrid = (value: boolean): void => {
    setShowGrid(value);
    localStorage.setItem(LOCAL_STORAGE_KEY_FOR_VIEW, value.toString());
  };

  return (
    <DashboardPage
      title={pageTitle}
      menu={!isExternal() ? (
        <RepositoriesMenu
          trashLink={`/${isMyRepositoryPage ? 'my' : 'shared'}-repositories/trash`}
          data-test-id="trash-link"
        />
      ) : undefined}
      actions={actions}
      hasBreadcrumbs={false}
      data-test-id="dashboard-page"
    >
      <GridListToggle
        value={showGrid}
        onChange={onToggleShowGrid}
        data-test-id="grid-list-toggle"
      />
      <TableRefresh
        onRefresh={onRefresh}
        refreshing={refreshing}
        lastUpdated={lastUpdated}
      />
      <BulkRepoActionsBanner showRestore={false} showReassign={isMyRepositoryPage} />
      {showGrid ? (
        <RepositoryCardGrid
          repositories={repositories}
          loading={loading}
          data-test-id="grid-view"
          onChange={setPagenumber}
          pageNumber={pagenumber}
        />
      )
        : (
          <RepositoryTable
            repositories={repositories}
            loading={loading}
            data-test-id="list-view"
            ownerColumn={!isMyRepositoryPage}
            onChange={setPagenumber}
            pageNumber={pagenumber}
          />
        )}
    </DashboardPage>
  );
}

export default RepositoriesPage;
