/** @module components/RepositoryWizard */
import React, { useState, useEffect } from 'react';
import { Divider } from 'antd';
import { Props } from './types';
import USPersonQuestionField from './fields/USPersonQuestionField';
import USPersonRulesField from './fields/USPersonRulesField';

/**
 * Asks the user a series of questions to determine what kind of repository
 * should be created for GE Nuclear.
 * @param props The props
 * @return A JSX Element
 */
function NuclearRepositoryWizard(props: Props): JSX.Element {
  const { onChange } = props;
  const [isUsOnly, setIsUsOnly] = useState<boolean | undefined>(undefined);
  const [acceptedRules, setAcceptedRules] = useState<boolean | undefined>(undefined);

  /**
   * Updates the parent when a field is updated.
   */
  useEffect(() => {
    /**
     * Returns true if the wizard is complete.
     * @return true if the wizard is complete
     */
    const isComplete = (): boolean => {
      // Yes
      if (isUsOnly) {
        return true;
      }
      // No yes
      if (isUsOnly === false && acceptedRules) {
        return true;
      }
      return false;
    };

    onChange({
      fedRamp: 0,
      isCdi: false,
      isEc: true,
      isLicenseAvailable: false,
      isUsOnly: isUsOnly || false,
      isUkLicense: false,
      isUsCitizen: false,
      isDoc: false,
    }, isComplete());
  }, [isUsOnly, acceptedRules, onChange]);

  return (
    <>
      <USPersonQuestionField
        onChange={(value): void => {
          setIsUsOnly(value);
          setAcceptedRules(undefined);
        }}
        value={isUsOnly}
        data-test-id="us-person-question"
      />
      {isUsOnly === false && (
        <>
          <Divider />
          <USPersonRulesField
            onChange={setAcceptedRules}
            value={acceptedRules}
            data-test-id="us-person-rules-question"
          />
        </>
      )}
    </>
  );
}

export default NuclearRepositoryWizard;
