/** @module components/BulkRepoActionsBanner */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { history } from 'utilities/history';
import { appPaths } from 'utilities/routes';
import BulkActionsBanner from '../BulkActionsBanner';
import ExtractRepoModal from '../ExtractRepoModal';
import { Props } from './types';
import './styles.scss';

function BulkRepoActionsBanner(props: Props): JSX.Element {
  const {
    deleteSelected,
    restoreSelected,
    selected,
    selectedToReassign,
    showDelete = true,
    showRestore = true,
    showExtract = false,
    showReassign = false,
    reassignRepositoriesMode = false,
    adminReassign,
    clearSelection,
  } = props;
  const selectedRepositories = reassignRepositoriesMode ? selectedToReassign : selected;
  const { t } = useTranslation();

  const onReassignClick = (): void => {
    localStorage.setItem('maintainSelectedRepositories', 'true');
    if (adminReassign) {
      history.push(appPaths.adminReassignRepositories);
    } else {
      history.push(appPaths.reassignRepositories);
    }
  };

  return (
    <BulkActionsBanner numberSelected={selectedRepositories.length} className="BulkRepoActionsBanner">
      {selectedRepositories.length > 0 && showReassign && (
      <Button
        type="link"
        data-test-id="reassign-button"
        className="button"
        onClick={onReassignClick}
      >
        <span className="text">{t('BulkRepoActionsBanner.confirmReassign.button', 'REASSIGN')}</span>
      </Button>
      )}
      {selectedRepositories.length > 0 && showDelete && (
        <Button
          type="link"
          data-test-id="delete-button"
          className="button"
          onClick={(): void => {
            Modal.confirm({
              title: t('BulkRepoActionsBanner.confirmDelete.title', 'Are you sure you want to delete the selected repositories?'),
              content: t('BulkRepoActionsBanner.confirmDelete.content', 'Deleted repositories may be restored from the trash.'),
              onOk: deleteSelected,
              onCancel: () => { },
              cancelButtonProps: { className: 'ant-btn-ghost' },
              autoFocusButton: null,
            });
          }}
        >
          <span className="text">{t('BulkRepoActionsBanner.confirmDelete.button', 'DELETE')}</span>
        </Button>
      )}
      {selectedRepositories.length > 0 && showRestore && (
        <Button
          type="link"
          onClick={restoreSelected}
          data-test-id="restore-button"
          className="button"
        >
          <span className="text">{t('BulkRepoActionsBanner.restore', 'RESTORE')}</span>
        </Button>
      )}
      { selectedRepositories.length > 0 && showExtract && (
      <ExtractRepoModal
        data-test-id="extract-button"
      />
      )}
      {selectedRepositories.length > 0 && (
        <Button
          type="link"
          data-test-id="cancel-button"
          className="button"
          onClick={clearSelection}
        >
          <span className="text">{t('BulkRepoActionsBanner.cancel', 'CANCEL')}</span>
        </Button>
      )}
    </BulkActionsBanner>
  );
}

export default BulkRepoActionsBanner;
