/** @module components/MyRepositoriesTrashPage */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import DashboardPageAction from '../DashboardPageAction';
import DashboardPage from '../DashboardPage';
import RepositoryTable from '../RepositoryTable';
import TableRefresh from '../TableRefresh';
import BulkRepoActionsBanner from '../BulkRepoActionsBanner';
import { Props } from './types';

class MyRepositoriesTrashPage extends React.Component<Props> {
  componentDidMount(): void {
    const { onRefresh, lastUpdated } = this.props;
    if (!lastUpdated) {
      onRefresh();
    }
  }

  render(): JSX.Element {
    const {
      lastUpdated,
      loading,
      onRefresh,
      refreshing,
      repositories,
      t,
    } = this.props;
    return (
      <DashboardPage
        title={t('MyRepositoriesTrashPage.DashboardPage.title', 'My Repositories Trash')}
        actions={[
          <DashboardPageAction
            text={t('MyRepositoriesTrashPage.DashboardPageAction.text', 'EXIT TRASH')}
            icon={<CloseIcon width={15} height={15} />}
            link="/my-repositories"
            key="exit-trash"
          />,
        ]}
      >
        <TableRefresh
          lastUpdated={lastUpdated}
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
        <BulkRepoActionsBanner showDelete={false} />
        <RepositoryTable
          repositories={repositories}
          loading={loading}
          trash
        />
      </DashboardPage>
    );
  }
}

export default withTranslation()(MyRepositoriesTrashPage);
