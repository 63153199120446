/** @module store/partners */
import { Action } from 'redux';

export enum PartnersActionType {
  PARTNERS_GET_REQUEST = 'PARTNERS_GET_REQUEST',
  PARTNERS_GET_SUCCESS = 'PARTNERS_GET_SUCCESS',
  PARTNERS_GET_ERROR = 'PARTNERS_GET_ERROR',
}

export type PartnersGetRequestAction = Action<PartnersActionType.PARTNERS_GET_REQUEST>;

export interface PartnersGetSuccessAction extends Action<
PartnersActionType.PARTNERS_GET_SUCCESS
> {
  partners: string[];
}

export interface PartnersGetErrorAction extends Action<
PartnersActionType.PARTNERS_GET_ERROR
> {
  payload: {
    error: string;
  };
}

export type State = Array<string>;

export type PartnersAction = PartnersGetRequestAction
                            | PartnersGetSuccessAction
                            | PartnersGetErrorAction;
