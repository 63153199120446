/** @module components/LicensesModal */
import React from 'react';
import { useTranslation } from 'react-i18next';
import LicenseList from '../LicenseList';
import AddLicensesForm from '../AddLicensesForm';
import ModalAction from '../ModalAction';
import TitleWithIcon from '../TitleWithIcon';
import { Props } from './types';
import './styles.scss';

function LicensesModal(props: Props): JSX.Element | null {
  const { repository } = props;
  const { t } = useTranslation();
  if (!repository) {
    return null;
  }
  return (
    <ModalAction
      className="LicensesModal"
      actionText="Manage Licenses"
      footer={null}
      title={(
        <TitleWithIcon>
          <h4>
            {t(
              'LicensesModal.title', 'Manage Licenses in',
            )}
            {' '}
            <span className="repo-name">
              {repository.name}
            </span>
          </h4>
        </TitleWithIcon>
      )}
    >
      {repository.actions.create_protections
        && <AddLicensesForm repository={repository} />}
      <LicenseList repository={repository} />
    </ModalAction>
  );
}

export default LicensesModal;
