/** @module components/Header */
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppRouteParams } from 'utilities/routes';
import { GlobalState } from 'store/types';
import { selectFeature } from 'store/features/selectors';
import { Feature } from 'store/features/types';
import { selectRepository } from 'store/repositories/selectors';
import Header from './component';
import { StateProps } from './types';

export const mapStateToProps = (
  state: GlobalState,
  props: RouteComponentProps<AppRouteParams>,
): StateProps => ({
  searchEnabled: selectFeature(state.features, Feature.SEARCH),
  repository: selectRepository(state.repositories, props.match.params.repoId || ''),
  me: state.me,
  globalSearchEnabled: selectFeature(state.features, Feature.GLOBAL_SEARCH),
});

export const mapDispatchToProps = (): {} => ({});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header));
