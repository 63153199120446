/* eslint-disable @typescript-eslint/no-unused-vars */
/** @module utilities/test */
import { AbstractView } from 'react';

const mockAbstractView: AbstractView = {
  styleMedia: {
    type: '',
    matchMedium(_mediaquery: string): boolean {
      throw new Error('Function not implemented.');
    },
  },
  document: new Document(),
};

export default mockAbstractView;
