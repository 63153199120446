/** @module components/ReleaseNotesPage */
import { connect } from 'react-redux';
import { GlobalState, AppDispatch } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { selectError } from 'store/error/selectors';
import { getReleaseNotes } from 'store/release-notes/actions';
import ReleaseNotesPage from './component';
import {
  DispatchProps,
  StateProps,
} from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'RELEASE_NOTES_GET'),
  error: selectError(state.error, 'RELEASE_NOTES_GET'),
  releaseNotes: state.releaseNotes,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getReleaseNotes: (): void => { dispatch(getReleaseNotes()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReleaseNotesPage);
