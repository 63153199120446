/** @module utilities/routes */

const appPaths = {
  root: '/',
  auth: '/auth',
  login: '/auth/login',
  logout: '/auth/logout',
  myRepositories: '/my-repositories',
  sharedRepositories: '/shared-repositories',
  repositories: '/repositories',
  myRepositoriesTrash: '/my-repositories/trash',
  sharedRepositoriesTrash: '/shared-repositories/trash',
  reassignRepositories: '/my-repositories/owner-reassign',
  repository: '/:basePath/:repoId',
  repositoryTrash: '/:basePath/:repoId/trash',
  folder: '/:basePath/:repoId/folder/:folderId',
  repoRestoreRequestForm: 'http://sc.ge.com/*reporestore',
  definitions: '/definitions',
  releaseNotes: '/release-notes',
  searchResults: '/search',
  manageCollaborators: '/manage-collaborators/:repoId',
  newRepository: '/new-repository',
  manageRepository: '/manage-repository/:repoId',
  manageSnapshots: '/manage-repository/:repoId/manage-snapshots',
  // Admin paths
  admin: '/admin',
  adminUserSearch: '/admin/user-search',
  adminReassignRepositories: '/admin/user-search/owner-reassign',
  adminManageAdminRoles: '/admin/roles',
  adminDeleteUser: '/admin/:userId/delete',
  adminManageCollaborators: '/admin/manage-collaborators/:userId/:repoId',
  adminReassignCollabPage: '/admin/:userId/reassign',
  // direct file download route
  directFileDownload: '/metadata/1.0/files/:id/content',
};

export default appPaths;
