/** @module utilities/test */
import { User } from 'services/metadata';

const mockUser: User = {
  id: '0',
  name: '',
  email: '',
  external: false,
  business: {
    id: '',
    displayName: '',
    wizardId: '',
  },
  preregistered: false,
  locked: false,
};

export default mockUser;
