/** @module store/businesses */
import { Action } from 'redux';
import { Business } from 'services/metadata';

export enum BusinessesActionType {
  BUSINESSES_GET_REQUEST = 'BUSINESSES_GET_REQUEST',
  BUSINESSES_GET_SUCCESS = 'BUSINESSES_GET_SUCCESS',
  BUSINESSES_GET_ERROR = 'BUSINESSES_GET_ERROR',
}

export type BusinessesGetRequestAction = Action<BusinessesActionType.BUSINESSES_GET_REQUEST>;

export interface BusinessesGetSuccessAction extends Action<
BusinessesActionType.BUSINESSES_GET_SUCCESS
> {
  payload: {
    businesses: Business[];
  };
}

export interface BusinessesGetErrorAction extends Action<
BusinessesActionType.BUSINESSES_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type State = Business[] | null;

export type BusinessesAction = BusinessesGetRequestAction
                             | BusinessesGetSuccessAction
                             | BusinessesGetErrorAction;
