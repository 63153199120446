/** @module store/partners */
import { AnyAction } from 'redux';
import {
  PartnersActionType,
  State,
} from './types';

export const DEFAULT_STATE: State = [];

function reducer(state: State = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case PartnersActionType.PARTNERS_GET_SUCCESS:
      return action.partners;
    default:
      return state;
  }
}

export default reducer;
