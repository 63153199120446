/** @module services/metadata */
/**
 * Represents the current user's registration state.
 */
export enum RegistrationState {
  BLOCKED = 'BLOCKED',
  INITIAL = 'INITIAL',
  LOGGEDOUT = 'LOGGEDOUT',
  REGISTERED = 'REGISTERED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNREGISTERED = 'UNREGISTERED',
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
  PREREGISTERED = 'PREREGISTERED',
}
