/** @module components/AddUnregisteredExtCollabForm */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Input,
  Button,
  Select,
  Checkbox,
} from 'antd';
import { ExternalCollaborator } from 'services/invitations';
import {
  validEmailFormat,
  repoOrPersonNameValidationRules,
} from 'utilities/form/rules';
import filteredCountryCodes from 'utilities/form/validCountryCodes';
import { Props, FormData } from './types';
import './styles.scss';

/**
 * A form to invite and add unregistered External collaborators to a repository
 */
function AddUnregisteredExtCollabForm(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    email,
    loading,
    onSubmit,
  } = props;

  function onFinish(values: FormData): void {
    /* eslint-disable @typescript-eslint/camelcase */
    const externalCollaborator: ExternalCollaborator = {
      email: values.email,
      country_code: values.countryCode,
      name: {
        given: values.firstName.trim(),
        family: values.lastName.trim(),
      },
    };
    onSubmit(externalCollaborator);
  }

  const { Option } = Select;

  return (
    <div className="AddUnregisteredExtCollabForm">
      <div className="form-title">{t('AddUnregisteredExtCollabForm.title', 'Registration Invitation')}</div>
      <Form
        onFinish={onFinish}
        layout="vertical"
        data-test-id="form"
      >
        <div className="form-vertical">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('AddUnregisteredExtCollabForm.formItem.email.message', 'Please enter the email address.'),
              },
              validEmailFormat,
            ]}
            label={t('AddUnregisteredExtCollabForm.formItem.email.label', 'Email Address')}
            colon={false}
            hasFeedback
            className="form-item"
            initialValue={email}
          >
            <Input
              disabled
              className="field"
              data-test-id="email-field"
            />
          </Form.Item>
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: t('AddUnregisteredExtCollabForm.formItem.firstName.message', 'Please enter the first name.'),
              },
              ...repoOrPersonNameValidationRules,
            ]}
            label={t('AddUnregisteredExtCollabForm.formItem.firstName.label', 'First Name')}
            colon={false}
            className="form-item"
            hasFeedback
          >
            <Input
              placeholder={t('AddUnregisteredExtCollabForm.formItem.firstName.label', 'First Name')}
              data-test-id="fistName-field"
              className="field"
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: t('AddUnregisteredExtCollabForm.formItem.lastName.message', 'Please enter the last name.'),
              },
              ...repoOrPersonNameValidationRules,
            ]}
            label={t('AddUnregisteredExtCollabForm.formItem.lastName.label', 'Last Name')}
            colon={false}
            className="form-item"
            hasFeedback
          >
            <Input
              placeholder={t('AddUnregisteredExtCollabForm.formItem.lastName.label', 'Last Name')}
              data-test-id="lastName-field"
              className="field"
            />
          </Form.Item>
          <Form.Item
            name="countryCode"
            rules={[{
              required: true,
              message: t('AddUnregisteredExtCollabForm.formItem.country.message', 'Please select country.'),
            }]}
            label={t('AddUnregisteredExtCollabForm.formItem.country.label', 'Country')}
            colon={false}
            className="form-item"
          >
            <Select
              showSearch
              optionFilterProp="children"
              className="field"
              data-test-id="countryCode-field"
              filterOption
              placeholder={t('AddUnregisteredExtCollabForm.formItem.country.placeholder', 'Select a country')}
            >
              {filteredCountryCodes.map(
                (element: {
                  name: string;
                  'alpha-3': string;
                  'country-code': string;
                }): JSX.Element => <Option value={element['alpha-3']} key={element['alpha-3']}>{element.name}</Option>,
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name="role"
            rules={[{
              required: true,
              message: t('AddUnregisteredExtCollabForm.formItem.role.message', 'Please select access'),
            }]}
            label={t('AddUnregisteredExtCollabForm.formItem.role.label', 'Access')}
            colon={false}
            className="form-item"
          >
            <Select
              className="field"
              placeholder="Select One"
              data-test-id="role-select"
            >
              <Select.Option value="EDITOR">
                {t('SelectRoles.roles.editor', 'Editor').toUpperCase()}
              </Select.Option>
              <Select.Option value="VIEWER">
                {t('SelectRoles.roles.viewer', 'Viewer').toUpperCase()}
              </Select.Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          name="isAcknowledged"
          rules={[{
            required: true,
            message: t('AddUnregisteredExtCollabForm.formItem.checkbox.message', 'Required'),
            transform: (value): void => (value || undefined),
            type: 'boolean',
          }]}
          className="checkbox-field"
          valuePropName="checked"
          data-test-id="acknowledge-checkbox"
        >
          <Checkbox data-test-id="acknowledgement-checkbox">
            <span className="checkbox-text">
              {t('AddUnregisteredExtCollabForm.formItem.checkbox.label', 'I verify that this user meets the export control requirements and can be added as a collaborator to this repository.')}
            </span>
          </Checkbox>
        </Form.Item>
        <Form.Item className="ant-form-item--last-item ant-form-item--form-buttons">
          <Button
            htmlType="submit"
            loading={loading}
            className="ant-btn-primary add-button"
            data-test-id="submit-button"
          >
            {t('AddUnregisteredExtCollabForm.button.Add', 'ADD')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AddUnregisteredExtCollabForm;
