/** @module components/ShareFileButton */
import { connect } from 'react-redux';
import { AppDispatch } from 'store/types';
import { copyTextToClipboard } from 'store/repositoryItems/actions';
import component from './component';
import { DispatchProps } from './types';

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  copyTextToClipboard: (text: string | null): void => {
    dispatch(copyTextToClipboard(text));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(component);
