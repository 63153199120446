/** @module components/ManageRepositoryPage */
import { connect } from 'react-redux';
import { getBusinesses } from 'store/businesses/actions';
import { selectLoading } from 'store/loading/selectors';
import { getRepositories } from 'store/repositories/actions';
import { selectRepository } from 'store/repositories/selectors';
import { AppDispatch, GlobalState } from 'store/types';
import Component from './component';
import {
  StateProps,
  OwnProps,
  DispatchProps,
} from './types';

export const mapStateToProps = (state: GlobalState, props: OwnProps): StateProps => ({
  repository: selectRepository(state.repositories, props.repositoryId),
  loading: selectLoading(state.loading, 'REPOSITORIES_GET'),
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getRepositories: (): void => { dispatch(getRepositories()); },
  getBusinesses: (): void => { dispatch(getBusinesses()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
