/** @module components/Header */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GELogo } from 'assets/images/ge-monogram-white.svg';
import { ReactComponent as RedshareLogo } from 'assets/images/redshare-knock-out.svg';
import { appPaths } from 'utilities/routes';
import MeMenu from '../MeMenu';
import SearchForm from '../SearchForm';
import { Props } from './types';
import './styles.scss';

function Header(props: Props): JSX.Element {
  const {
    searchEnabled,
    repository,
    me,
    location,
    globalSearchEnabled,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <header className="Header">
        <div className="branding">
          <GELogo />
          <Link to="/" className="link">
            <RedshareLogo width={200} height={56} />
            <div className="tagline">
              {t('Header.logo.tagline', 'Regulated, Export and Defense Data Share')}
            </div>
          </Link>
        </div>
        <div className="toolbar">
          {/* Renders global search  */}
          {globalSearchEnabled && searchEnabled && me.user && (<SearchForm repoIds={repository ? [repository.id] : undefined} filters={[]} data-test-id="search-form" />)}
          {/* Renders search only inside repo if global search is not enabled */}
          {!globalSearchEnabled
            && searchEnabled && (repository || location.pathname === appPaths.searchResults) && (
            <SearchForm
              repoIds={repository ? [repository.id] : []}
              data-test-id="search-form"
            />
          )}

          {me.user && (
            <div className="dropdown">
              <MeMenu
                data-test-id="user-menu"
              />
            </div>
          )}
        </div>
      </header>
    </>
  );
}

export default Header;
