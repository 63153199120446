/** @module services/metadata */
import FetchClient from './FetchClient';

export * from './types/Announcement';
export * from './types/Collaborator';
export * from './types/CollaboratorIdentity';
export * from './types/Item';
export * from './types/ItemDetails';
export * from './types/LicenseType';
export * from './types/Me';
export * from './types/MetadataError';
export * from './types/MetadataErrorResponse';
export * from './types/RegistrationState';
export * from './types/Repository';
export * from './types/RepositoryOwner';
export * from './types/User';
export * from './types/UserActions';
export * from './types/Tag';
export * from './types/Business';
export * from './types/Actions';
export * from './types/Role';
export * from './types/Snapshot';

export default FetchClient;
