/** @module components/UserMenu */
import React from 'react';
import {
  Button,
  Dropdown,
  Menu,
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { history } from 'utilities/history';
import { BusinessPermissions, GlobalPermissions } from 'services/metadata/types/Permissions';
import AssignRolesModal from 'components/AssignRolesModal';
import MoreButton from '../MoreButton';
import { Props } from './types';
import './styles.scss';

function UserMenu(props: Props): JSX.Element | null {
  const menuItems = [];
  const {
    users,
    blockUser,
    unblockUser,
    me,
    deleteUserEnabled,
  } = props;

  const user = users[0];
  const { t } = useTranslation();

  let canAssignRole = false;
  let canBlockUser = false;
  let canUnblockUser = false;
  let canDeleteUser = false;
  let canReassignUser = false;

  const { permissions } = me;
  if (permissions) {
    if (user && user.business) {
      const userBusiness = user.business.id;
      const businessPermission = permissions.byBusiness.find(
        (p) => p.businessId === userBusiness,
      );
      // if admin has business permissions for user's business
      if (businessPermission) {
        if (businessPermission.permissions.includes(
          BusinessPermissions.BUSINESS_ASSIGN_ROLE,
        )) {
          canAssignRole = true;
        }
        if (businessPermission.permissions.includes(
          BusinessPermissions.BUSINESS_LOCK_USER,
        )) {
          canBlockUser = true;
        }
        if (businessPermission.permissions.includes(
          BusinessPermissions.BUSINESS_UNLOCK_USER,
        )) {
          canUnblockUser = true;
        }
        if (businessPermission.permissions.includes(
          BusinessPermissions.BUSINESS_DELETE_USER,
        )) {
          canDeleteUser = true;
        }
        if (businessPermission.permissions.includes(
          BusinessPermissions.BUSINESS_UPDATE_COLLABORATOR,
        )) {
          canReassignUser = true;
        }
      }
    }
    if (permissions.global.length) {
      const { global } = permissions;
      if (global.includes(GlobalPermissions.GLOBAL_ASSIGN_ROLE)) {
        canAssignRole = true;
      }
      if (global.includes(GlobalPermissions.GLOBAL_LOCK_USER)) {
        canBlockUser = true;
      }
      if (global.includes(GlobalPermissions.GLOBAL_UNLOCK_USER)) {
        canUnblockUser = true;
      }
      if (global.includes(GlobalPermissions.GLOBAL_DELETE_USER)) {
        canDeleteUser = true;
      }
      if (global.includes(GlobalPermissions.GLOBAL_UPDATE_COLLABORATOR)) {
        canReassignUser = true;
      }
    }
  }

  if (user.external) {
    canAssignRole = false;
  }

  menuItems.push(
    <Menu.Item key="menu-title" className="user-menu-title">
      {t('UserMenu.userActions', 'User Actions')}
    </Menu.Item>,
  );

  if (canAssignRole && me.user && me.user.id !== user.id) {
    menuItems.push(
      <Menu.Item key="assign-role" data-test-id="assign-role-button">
        <AssignRolesModal user={user} />
      </Menu.Item>,
    );
  }

  if (!user.locked && canBlockUser && me.user && me.user.id !== user.id) {
    menuItems.push(
      <Menu.Item key="block-user">
        <Button
          type="link"
          onClick={(): void => {
            Modal.confirm({
              title: t(
                'UserMenu.confirmBlockUser.title',
                'Block User',
              ),
              content: t('UserMenu.confirmBlockUser.content', 'Are you sure you want to block this user? This will prevent the user from logging in, but not delete their content. If you change your mind, you can unblock them from the actions menu.'),
              onOk: (): void => blockUser(user.id),
              onCancel: () => { },
              okText: t('UserMenu.confirmBlockUser.button', 'BLOCK USER'),
              cancelText: t('UserMenu.confirmBlockUser.cancelButton', 'CANCEL'),
              cancelButtonProps: { className: 'ant-btn-ghost' },
              closable: true,
            });
          }}
          data-test-id="block-user-button"
        >
          {t('UserMenu.blockUser', 'Block User')}
        </Button>
      </Menu.Item>,
    );
  }
  if (user.locked && canUnblockUser && me.user && me.user.id !== user.id) {
    menuItems.push(
      <Menu.Item key="unblock-user">
        <Button
          type="link"
          onClick={(): void => {
            Modal.confirm({
              title: t(
                'UserMenu.confirmUnblockUser.title',
                'Unblock User',
              ),
              content: t('UserMenu.confirmUnblockUser.content', 'Are you sure you want to unblock this user?'),
              onOk: (): void => unblockUser(user.id),
              onCancel: () => { },
              okText: t('UserMenu.confirmUnblockUser.okButton', 'UNBLOCK USER'),
              cancelText: t('UserMenu.confirmUnblockUser.cancelButton', 'CANCEL'),
              cancelButtonProps: { className: 'ant-btn-ghost' },
              autoFocusButton: null,
              closable: true,
            });
          }}
          data-test-id="unblock-user-button"
        >
          {t('UserMenu.unblockUser', 'Unblock User')}
        </Button>
      </Menu.Item>,
    );
  }
  if (canDeleteUser && deleteUserEnabled && me.user && me.user.id !== user.id) {
    menuItems.push(
      <Menu.Item key="delete-user">
        <Button
          type="link"
          onClick={(): void => history.push(`/admin/${user.id}/delete`)}
          data-test-id="delete-user-button"
        >
          {t('UserMenu.deleteUser', 'Delete User')}
        </Button>
      </Menu.Item>,
    );
  }
  if (canReassignUser && me.user && me.user.id !== user.id) {
    menuItems.push(
      <Menu.Item key="reassign-repositories">
        <Button
          type="link"
          onClick={(): void => history.push('/admin/user-search/owner-reassign')}
          data-test-id="reassign-repositories-button"
        >
          {t('UserMenu.reassignRepositories', 'Reassign Repositories')}
        </Button>
      </Menu.Item>,
    );
  }

  if (menuItems.length > 1) {
    return (
      <>
        <Dropdown
          overlay={(
            <Menu>
              {menuItems}
            </Menu>
          )}
          trigger={['click']}
          placement="bottomLeft"
          data-test-id="dropdown"
          overlayClassName="UserMenu"
        >
          <div>
            <MoreButton />
          </div>
        </Dropdown>
      </>
    );
  }
  return null;
}

export default UserMenu;
