/** @module components/Notifier */
import React from 'react';
import { notification as createNotification } from 'antd';
import { Props } from './types';

export default class Notifier extends React.Component<Props> {
  shouldComponentUpdate(newProps: Props): boolean {
    const { notifications } = newProps;
    if (notifications.length > 0) {
      return true;
    }
    return false;
  }

  componentDidUpdate(): void {
    const { notifications, removeAllNotifications } = this.props;
    notifications.forEach((notification) => {
      createNotification[notification.type]({
        message: notification.title,
        description: notification.message,
        top: 104,
      });
    });
    removeAllNotifications();
  }

  render(): null {
    return null;
  }
}
