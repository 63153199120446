/** @module store/announcements */
import { Action } from 'redux';
import { Announcement } from 'services/metadata';

export enum AnnouncementsActionType {
  ANNOUNCEMENTS_GET_REQUEST = 'ANNOUNCEMENTS_GET_REQUEST',
  ANNOUNCEMENTS_GET_SUCCESS = 'ANNOUNCEMENTS_GET_SUCCESS',
  ANNOUNCEMENTS_GET_ERROR = 'ANNOUNCEMENTS_GET_ERROR',
  ANNOUNCEMENTS_SET_BANNER_CLOSED = 'ANNOUNCEMENTS_SET_BANNER_CLOSED',
}

export type AnnouncementsGetRequestAction = Action<
AnnouncementsActionType.ANNOUNCEMENTS_GET_REQUEST
>;

export interface AnnouncementsGetSuccessAction extends Action<
AnnouncementsActionType.ANNOUNCEMENTS_GET_SUCCESS
> {
  payload: {
    announcements: Announcement[];
  };
}

export interface AnnouncementsGetErrorAction extends Action<
AnnouncementsActionType.ANNOUNCEMENTS_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export interface SetBannerClosedAction extends Action<
AnnouncementsActionType.ANNOUNCEMENTS_SET_BANNER_CLOSED
> {
  payload: {
    bannerId: string;
  };
}

export interface State {
  announcements: Announcement[];
  closedBannerIds: string[];
}

export type AnnouncementsAction = AnnouncementsGetRequestAction
                                | AnnouncementsGetSuccessAction
                                | AnnouncementsGetErrorAction
                                | SetBannerClosedAction;
