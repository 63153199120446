/** @module components/ExtractRepoModal */
import { connect } from 'react-redux';
import { AppDispatch, GlobalState } from 'store/types';
import { evictMultipleRepositories, setSelectedRepositories } from 'store/repositories/actions';
import ExtractRepoModal from './component';
import { DispatchProps, StateProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  selected: state.repositories.selected,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  clearSelection: (): void => { dispatch(setSelectedRepositories([])); },
  evictRepositories: (repositories: string[]): void => {
    dispatch(evictMultipleRepositories(repositories, true));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExtractRepoModal);
