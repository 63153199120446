/** @module store/error */
import { FeaturesState, Feature } from './types';

/**
 * Selects the given feature's flag from the given state.
 *
 * @param state The feature state
 * @param featureKey The name/key of the feature
 * @return A boolean
 */
export function selectFeature(
  state: FeaturesState,
  featureKey: Feature,
): boolean {
  const feature = state[featureKey];
  if (feature === undefined) {
    return false;
  }
  return feature;
}
