/** @module services/us-protections */
import { API } from './types/API';

/**
 * A client that communicates with the GE US Protections service
 * using the fetch function.
 */
class FetchClient implements API {
  private prefix: string;

  /**
   * Constructs a new client with the given API level.
   *
   * @param apiLevel The API level this service uses
   */
  constructor(apiLevel = '1.0') {
    this.prefix = `/us-protections/${apiLevel}`;
  }

  getLicenses(): Promise<string[]> {
    const url = `${this.prefix}/licenses`;
    return fetch(url)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  /**
   * Throws an error if the given response is not ok.
   *
   * @param response A Response
   * @return The given response
   * @throws An Error if the response is not ok
   */
  private static async handleError(response: Response): Promise<Response> {
    if (!response.ok) {
      const contentType = response.headers.get('content-type');
      if (typeof contentType === 'string' && contentType.startsWith('application/json')) {
        const json = await response.json();
        throw new Error(json.description);
      }
      throw new Error(response.statusText);
    }
    return response;
  }
}

export default FetchClient;
