/** @module components/AddLicensesForm */
import { connect } from 'react-redux';
import { selectLicenses } from 'store/licenses/selectors';
import {
  getLicenses,
  addLicense,
  getAllLicenses,
} from 'store/licenses/actions';
import { AppDispatch, GlobalState } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import Component from './component';
import { OwnProps, StateProps, DispatchProps } from './types';

export const mapStateToProps = (state: GlobalState, props: OwnProps): StateProps => ({
  allLicenses: state.licenses.all,
  licenses: selectLicenses(state.licenses, props.repository.id),
  loading: selectLoading(state.loading, 'LICENSES_ADD'),
});

export const mapDispatchToProps = (
  dispatch: AppDispatch,
  props: OwnProps,
): DispatchProps => ({
  getLicenses: (): void => { dispatch(getLicenses(props.repository.id)); },
  addLicense: (repositoryId: string, license: string): void => {
    dispatch(addLicense(repositoryId, license));
  },
  getAllLicenses: (): void => { dispatch(getAllLicenses()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
