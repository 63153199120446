/** @module components/ExtractRepoModal */
import React, { useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EvictionFailureIcon } from 'assets/icons/failure.svg';
import { EvictionState } from 'services/metadata/types/EvictionState';
import ModalAction from '../ModalAction';
import { Props } from './types';
import './styles.scss';

function ExtractRepoModal(props: Props): JSX.Element {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const {
    repository,
    repoEvictionStatus,
    selected,
    evictRepositories,
    clearSelection,
  } = props;

  const handleClose = (): void => {
    setVisible(false);
  };

  const handleRepoEviction = (): void => {
    if (repository) {
      evictRepositories([repository.id]);
      handleClose();
    } else if (selected.length > 0) {
      const repositoryIds = selected.map((repo) => repo.id);
      evictRepositories(repositoryIds);
      clearSelection();
      handleClose();
    }
  };

  const descriptionPartOne = repository || selected.length === 1
    ? t('ExtractRepoModal.description.partOne.single', 'Are you sure you would like to extract this repository from REDshare?')
    : t('ExtractRepoModal.description.partOne.bulk', 'Are you sure you would like to extract {{count}} repositories from REDshare?', { count: selected.length });

  const isRepoEvictionFailure = (): boolean => !!(
    (repository && repository.evictionState === EvictionState.FAILED)
    || (repoEvictionStatus && repoEvictionStatus.evictionStatus.failed)
  );

  return (
    <ModalAction
      title={t('ExtractRepoModal.title', 'Extract Repository')}
      actionText={t('ExtractRepoModal.actionText', 'Extract Repository')}
      className="ant-modal-create"
      footer={null}
      data-test-id="extract-repo-modal"
      visible={visible}
      cancelHandler={handleClose}
      actionRender={(): JSX.Element => (
        <Button
          type="link"
          onClick={(): void => setVisible(true)}
          data-test-id="open-button"
          className="open-button"
        >
          {
           (isRepoEvictionFailure()) ? (
             <EvictionFailureIcon
               className="icon--primary failed"
               data-test-id="eviction-failure-icon"
             />
           ) : null
          }
          <span className="text">{t('ExtractRepoModal.button.extract', 'EXTRACT REPOSITORY')}</span>
        </Button>
      )}
    >
      <div className="modal-content">
        <span className="modal-description">
          { descriptionPartOne }
        </span>
        <span className="modal-description bold">
          {t('ExtractRepoModal.description.partTwo', 'This action cannot be undone.')}
        </span>
        <span className="modal-description">
          {t('ExtractRepoModal.description.partThree', 'Repositories that have been extracted will be marked as “Read Only”. User actions will be limited.')}
        </span>
        <span className="modal-description medium margin-top-extra">
          {t('ExtractRepoModal.description.partFour', 'Please be aware the extraction process may take some time. Once the extraction is triggered, you may leave this page and continue to use REDshare as usual.')}
        </span>
        <div className="modal-buttons">
          <Button
            key="cancel"
            type="ghost"
            className="button"
            onClick={handleClose}
            data-test-id="cancel"
          >
            {t('ExtractRepoModal.button.close', 'Cancel')}
          </Button>
          <Button
            key="create"
            type="primary"
            className="button"
            onClick={handleRepoEviction}
            data-test-id="create"
          >
            {t('ExtractRepoModal.button.okay', 'Okay')}
          </Button>
        </div>
      </div>
    </ModalAction>
  );
}

export default ExtractRepoModal;
