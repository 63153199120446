/** @module components/RepositoryItemsPage */
import { connect } from 'react-redux';
import { getRepositoryItems, updateName } from 'store/repositoryItems/actions';
import { selectRepositoryItem } from 'store/repositoryItems/selectors';
import { selectLoading } from 'store/loading/selectors';
import { selectError } from 'store/error/selectors';
import {
  selectCollaborators,
  selectExpiringCollaborators,
} from 'store/collaborators/selectors';
import { getCollaborators } from 'store/collaborators/actions';
import { selectRepositoriesEvictionStatuses, selectRepository } from 'store/repositories/selectors';
import { selectFeature } from 'store/features/selectors';
import { Feature } from 'store/features/types';
import { getMultipleRepositoryEvictionStatus, getRepositories } from 'store/repositories/actions';
import { GlobalState, AppDispatch } from 'store/types';
import RepositoryItemsPage from './component';
import {
  OwnProps,
  StateProps,
  DispatchProps,
} from './types';

export const mapStateToProps = (state: GlobalState, props: OwnProps): StateProps => ({
  me: state.me,
  repository: selectRepository(state.repositories, props.repositoryId),
  folder: selectRepositoryItem(state, props.folderId || props.repositoryId),
  loading: (
    selectLoading(state.loading, 'REPOSITORY_ITEMS_UPDATE')
    || selectLoading(state.loading, 'REPOSITORY_ITEMS_DELETE')
    || selectLoading(state.loading, 'REPOSITORY_ITEMS_DELETE_SELECTED')
    || selectLoading(state.loading, 'COLLABORATORS_GET')
    || selectLoading(state.loading, 'REPOSITORIES_DELETE')
    || selectLoading(state.loading, 'REPOSITORIES_GET')
    || selectLoading(state.loading, 'REPOSITORY_ITEMS_DOWNLOAD_SELECTED')
  ),
  loaderForRename: selectLoading(state.loading, 'REPOSITORY_ITEMS_RENAME'),
  loaderForEvictionStatus: selectLoading(state.loading, 'MULTI_REPOSITORY_GET_EVICTION_STATUS'),
  refreshing: selectLoading(state.loading, `REPOSITORY_ITEMS_GET_${props.folderId || props.repositoryId}`),
  getRepositoryItemsError: selectError(state.error, 'REPOSITORY_ITEMS_GET'),
  getCollaboratorsError: selectError(state.error, 'COLLABORATORS_GET'),
  collaborators: selectCollaborators(state.collaborators, props.repositoryId),
  expiringCollaborators: selectExpiringCollaborators(state.collaborators, props.repositoryId, 7),
  renewECEnabled: selectFeature(state.features, Feature.RENEW_EXTERNAL_COLLABORATORS),
  repositoryEvictionStatus: selectRepositoriesEvictionStatuses(state)[props.repositoryId],
});

export const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps): DispatchProps => ({
  getItems: (): void => {
    const folderId = ownProps.folderId || ownProps.repositoryId;
    dispatch(getRepositoryItems(folderId));
  },
  getRepositories: (): void => { dispatch(getRepositories()); },
  getCollaborators: (): void => { dispatch(getCollaborators(ownProps.repositoryId)); },
  updateName: (type: string, id: string, name: string, parentId: string): void => {
    dispatch(updateName(type, id, name, parentId));
  },
  getRepositoryEvictionStatus: (): void => {
    dispatch(
      getMultipleRepositoryEvictionStatus([ownProps.repositoryId]),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepositoryItemsPage);
