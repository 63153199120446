/** @module components/RepositoryInfoSection */
import {
  Col,
  Form,
  Input,
  Row,
  Select,
} from 'antd';
import { FileContentSearchToggle, RepositoryWizardType } from 'components/RepositoryWizard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { repoOrPersonNameValidationRules } from 'utilities/form/rules';
import { sortAlphabetical } from 'utilities/sort';
import { Props } from './types';
import './styles.scss';

function RepositoryInfoSection(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    gasPowerWizardEnabled,
    powerConversionWizardEnabled,
    businesses,
    getBusinessLoading,
    onBusinessChange,
    loading,
    businessDisabled,
  } = props;

  /**
     *
   * Filter the businesses returned by the back-end based on the available
   * wizard implementations and feature toggles.
   */
  const wizardTypes: string[] = Object.values(RepositoryWizardType);
  const businessList = businesses
    ? businesses.filter((b) => {
      if (b.wizardId === RepositoryWizardType.GAS_POWER && !gasPowerWizardEnabled) {
        return false;
      }
      if (b.wizardId === RepositoryWizardType.POWER_CONVERSION && !powerConversionWizardEnabled) {
        return false;
      }
      return wizardTypes.includes(b.wizardId);
    })
    : [];

  return (
    <div className="RepositoryInfoSection">
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="name"
            className="horizontal-row"
            label={t('RepositoryInfoSection.repoName', 'Repository name')}
            colon={false}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 16 }}
            hasFeedback
            rules={[
              {
                required: true,
                message: t('RepositoryInfoSection.rule.nameRequired', 'Please enter the name of the repository.'),
              },
              ...repoOrPersonNameValidationRules,
            ]}
          >
            <Input
              type="text"
              data-test-id="repository-name"
              disabled={loading}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <FileContentSearchToggle
            data-test-id="file-content-search"
            disabled={loading}
          />
        </Col>

        <Col span={12}>
          <Form.Item
            name="parentBusiness"
            className="horizontal-row"
            label={t('RepositoryInfoSection.field.parentBusiness', 'Parent business')}
            colon={false}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 16 }}
            hasFeedback
            rules={[
              {
                required: !businessDisabled,
                message:
                  t('RepositoryInfoSection.rule.parentBusinessRequired', 'Please select the parent business for this repository.'),
              },
            ]}
          >
            <Select
              onChange={onBusinessChange}
              placeholder={t('RepositoryInfoSection.placeholder.parentBusiness', 'Select One')}
              loading={getBusinessLoading}
              data-test-id="select-business"
              disabled={loading || businessDisabled}
            >
              {businessList
                .sort((a, b) => sortAlphabetical(
                  a.displayName.toLowerCase(),
                  b.displayName.toLowerCase(),
                ))
                .map((b) => (
                  <Select.Option key={b.id} value={b.id}>
                    {b.displayName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export default RepositoryInfoSection;
